<template>
    <section class="tpui-container">
        <div class="row">
            <div class="col-md-7">
                <div class="grey-box p-3 pt-4">
                    <div class="row">
                        <div v-for="(rating, ratingNo) in ratings" :key="ratingNo" class="col-md-12">
                            <div class="row">
                                <div class="col-sm-2 text-right">
                                    {{ ratingNo > 1? `${ratingNo} Stars` :  `${ratingNo} Star` }}
                                </div>
                                <div class="col-sm-8">
                                    <progress class="uk-progress" :value="rating" max="1000"></progress>
                                </div>
                                <div class="col-sm-2 text-center total-star">{{ rating }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <!-- <div class="grey-box p-4 rating-count"> -->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="grey-box p-4 text-center">
                                <div class="average">
                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                </div>
                                <p class="uppercase text-center">Average</p>
                                <div class="uppercase text-center total-rating">
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <span>{{ average }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="grey-box p-4 text-center">
                                <div class="average">
                                    <i class="fa fa-comment-o" aria-hidden="true"></i>
                                </div>
                                <p class="uppercase text-center">Total Reviews</p>
                                <div class="uppercase text-center total-rating">
                                    <span>{{ total_reviews }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
        </div>
    </section>
</template>

<script>
export default {
    
    mounted() {
        this.ratings = {
                '5':200,
                '4':100,
                '3':220,
                '2':200,
                '1':20
            }
        
        this.average = 4.5

        this.total_reviews = 2000
        // this.getCategories()
    },
    data() {
        return {
            average: 0,
            total_reviews: 0,
            categories: [],
            ratings: [],
            filter: {
                category: 'All'
            },
            categoryDefault: {
                id: 0,
                mission_catetory_id: 0,
                title: 'All',
                keyword: 'All'
            }
        }
    },
    methods: {
        getCategories() {

            axios
                .get('services')
                .then((response) => {

                    if(response.data.success) {
                        this.categories =  response.data.data
                        this.changeCat(this.categoryDefault)
                    }else{
                        this.$helpers.notification(response.data.success, response.data.message)
                    }

                })
                .catch((error) => {
                    this.$helpers.notification(false, error.message)
                })
        },

        changeCat(catObj) {

            this.filter.category = catObj.keyword
            console.log(catObj.id)

            this.getRatingByCat(catObj.id)
            
        },
        getRatingByCat(catId = 0) {

            const api = catId > 0? `reviews/${catId}` : `reviews`
            axios
                .get(api)
                .then((response) => {
                    if(response.data.success) {

                        this.ratings = response.data.data.data
                        this.average = response.data.data.average
                        this.total_reviews = response.data.data.total_reviews

                    }else{
                        this.$helpers.notification(response.data.success, response.data.message)
                    }
                })
                .catch((error) => {
                    this.$helpers.notification(false, error.message)
                })
        }

    }
}
</script>

<style scoped>
.categories li a {
    color: rgba(167, 168, 176, 0.7);
}
.categories li a:hover, 
.categories li.active a:hover,
.categories li.active a{
    color: #0B63F8;
    text-decoration: underline
}
.uk-progress {
    height: 25px;
    border-radius: 5px;
}
.rating-count {
    background: #EBF0F7;
    box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.1);
}
.average {
    background: #0B63F8;
    border-radius: 6px;
    padding: 30px;
    display: inline-flex;
    margin: auto;
    margin-bottom: 23px;
}
.average i {
    color: #fff;
    font-size: 35px;
}
.total-rating i {
    color: #F7BA1E;
    font-size: 15px;
    margin-top: -10px;
}
.total-rating span {
    font-weight: 700;
    font-size: 30px;
    line-height: 29px;
    text-align: right;
    text-transform: uppercase;
    color: #0B63F8;
}
.total-star {
    color: #0B63F8;
}
</style>