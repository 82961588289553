<template>
    <div class="p-5">
          <!----- Header Title ----->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h3 class="page-title mb-1 mt-0">
                        {{ this.$route.meta.title }}
                    </h3>
                </div>
                <div class="breadcrumb-container">
                    <ul class="uk-breadcrumb">
                        <li><span>Admin</span></li>
                        <li><a href="/reservation">Reservation</a></li>
                        <li><a href="">{{ this.$route.meta.title }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 relative"></div>
        </div>
      <!-- New Reservation -->
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
            <section class="grey-box register" v-if="!hasSeenCongrats">
                <div class="register-stepper">
                    <div class="step step-done"><span class="step-number">1</span></div>
                    <div class="step step-done"><span class="step-number">2</span></div>
                    <div class="step step-active"><span class="step-number">3</span></div>
                    <div class="step"><span class="step-number">4</span></div>
                    <div class="step"><span class="step-number">5</span></div>
                    <div class="step"><span class="step-number">6</span></div>
                </div>

                <transition name="slide-fade">
                    <section>
                        <form class="form" action="#">
                            <p class="text-uppercase text-sm mb-2">Driver Information</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required/>
                                        <label>Driver's Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required/>
                                        <label>Additional Drivers (Optional)</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <textarea class="form-control" required></textarea>
                                        <label>Additional Drivers (Optional)</label>
                                    </div>
                                </div>
                            </div>
                            <p class="text-uppercase text-sm mt-4 mb-2">Discount</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required />
                                        <label>Coupon Code</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required/>
                                        <label>Custom Discount Value</label>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group cta-step mt-4">
                                <div class="cta prev">
                                    <p class="cta-color">
                                        <span class="link_wrap">
                                            <router-link :to="'/book/2'">
                                                <a class="btn prev-btn link_text"><span class="arrow-prev" style="padding-right: 5px;"></span>Previous</a>
                                            </router-link>
                                        </span>
                                    </p>
                                </div>
                                <div class="cta">
                                    <p class="cta-color">
                                        <span class="text"></span>
                                        <span class="link_wrap">
                                            <router-link :to="'/book/4'">
                                                <a class="link_text btn next-btn" style="padding-right: 30px!important;">Next</a>
                                            </router-link>
                                            <span class="arrow-next"></span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </section>
                </transition>

            </section>
        </div>
      </div>
    </div>
    
  </template>
  
  
  
  <script>
    import router from '../../routes';

    export default {
        el: "#app",
        data: () => {
            return {
            steps: {},
            paymentopt: 'offline',
            step: 1,
            customer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            hasSeenCongrats: false,
            tempCustomer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            };
        },
    methods: {
        redirectReservation() { // 
            router.push({path: '/reservation/'})
        },

        prev() {
        this.step--;
        },

        next() {
        this.step++;
        },

        newBooking: function () {
            this.hasSeenCongrats = true;
        },
    }

};
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>