<template>
  <div class="edit-data container-fluid mb-5">
    <div class="row mb-5">
      <div class="col-md-12 mb-3">
        <a href="star-bodyguard-companies.html" class="back-btn"
          ><i class="fal fa-chevron-left mr-2" aria-hidden="true"></i>Back</a
        >
      </div>
      <div class="col-lg-12">
        <div class="grey-box">
          <form>
            <div class="row">
              <div class="col-6 mb-3"></div>
              <div class="col-6 mb-3 text-right">
                <button type="submit" class="submit-btn border-0">Save</button>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-lg-4 mb-5 mb-lg-0">
                <div class="profile-img position-relative">
                  <img :src="getAvatarImage(freelanceDetails.avatar)" />
                  <a href="#" class="edit-btn">
                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" name="profile" class="file-upload" />
                    <i class="fal fa-plus" aria-hidden="true"></i
                  ></a>
                </div>
              </div>
              <div class="col-xl-9 col-lg-8">
                <div class="row">
                  <div class="col-lg-6 col-md-6 mb-3">
                    <label>First Name</label>
                    <input type="text" v-model="freelanceDetails.first_name" name="fname" />
                  </div>
                  <div class="col-lg-6 col-md-6 mb-3">
                    <label>Middle Name</label>
                    <input type="text" v-model="freelanceDetails.middle_name" name="lname" />
                  </div>
                  <div class="col-lg-6 col-md-6 mb-3">
                    <label>Last Name</label>
                    <input type="text" v-model="freelanceDetails.last_name" name="lname" />
                  </div>
                  <div class="col-lg-6 col-md-6 mb-3">
                    <label>Nationality</label>
                    <input type="text" v-model="freelanceDetails.nationality" name="nationality" />
                  </div>
                  <div class="col-lg-6 col-md-6 mb-3">
                    <label>Phone number</label>
                    <input type="text" v-model="freelanceDetails.phone_number" name="phonenumber" />
                  </div>
				  <div class="col-lg-6 col-md-6 mb-3">
                    <label>Type</label>
                    <input type="text" v-model="freelanceDetails.type" name="type" />
                  </div>
					<div class="col-lg-6 col-md-6 mb-3">
                <label>Email</label>
                <input type="text" v-model="freelanceDetails.email" name="email" />
              </div>
            
                  <div class="col-lg-6 col-md-6 mb-3">
                    <label>Gender</label>
                    <div class="d-flex align-items-center border-bottom">
                      <div class="form-check-inline mr-4">
                        <input type="radio" id="male" value="male" v-model="freelanceDetails.gender" name="Gender" />
                        <label for="male" class="pl-0">Male</label>
                      </div>
                      <div class="form-check-inline">
                        <input type="radio" id="female" value="female" v-model="freelanceDetails.gender"  name="Gender" />
                        <label for="female" class="pl-0">Female</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <form>
      <div class="row mb-5">
        <div class="col-6 mb-3">
          <h5 class="mb-0">Address</h5>
        </div>
        <div class="col-6 mb-3 text-right">
          <button type="submit" class="submit-btn border-0">Save</button>
        </div>
        <div class="col-lg-12">
          <div class="grey-box">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label>Address</label>
                <input type="text" v-model="freelanceDetails.address" name="address" />
              </div>
              <div class="col-md-6 mb-3">
                <label>District</label>
                <input type="text" v-model="freelanceDetails.district" name="district" />
              </div>
			  <div class="col-md-6 mb-3">
                <label>Sub District</label>
                <input type="text" v-model="freelanceDetails.sub_district" name="sub_district" />
              </div>
			  <div class="col-md-6 mb-3">
                <label>Province</label>
                <input type="text" v-model="freelanceDetails.province" name="province" />
              </div>
            </div>
            <div class="row">
              
              <div class="col-md-6 mb-3">
                <label>Zip Code</label>
                <input type="text" v-model="freelanceDetails.zip_code" name="zipcode" />
              </div>
            </div>
            <div class="row">
              
            </div>
          </div>
        </div>
      </div>
    </form>
    <form>
      <div class="row mb-5">
        <div class="col-6 mb-3">
          <h5 class="mb-0">Physical</h5>
        </div>
        <div class="col-6 mb-3 text-right">
          <button type="submit" class="submit-btn border-0">Save</button>
        </div>
        <div class="col-lg-12">
          <div class="grey-box">
            <div class="row">
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Height</label>
                <select name="height">
                  <option value="199CM" selected>199CM</option>
                  <option value="200CM">200CM</option>
                  <option value="300CM">300CM</option>
                  <option value="320CM">320CM</option>
                  <option value="400CM">400CM</option>
                </select>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Weight</label>
                <select name="weight">
                  <option value="50KG" selected>50KG</option>
                  <option value="65KG">65KG</option>
                  <option value="70KG">70KG</option>
                  <option value="75KG">75KG</option>
                  <option value="more than 80kg">More Than 80KG</option>
                </select>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Body type</label>
                <select name="bodytype">
                  <option value="Slim" selected>Slim</option>
                  <option value="Average">Average</option>
                  <option value="Fit Body">Fit Body</option>
                  <option value="Athetic">Athetic</option>
                  <option value="Overweight">Overweight</option>
                </select>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Tattoos</label>
                <div class="d-flex align-items-center border-bottom">
                  <div class="form-check-inline mr-4">
                    <input type="radio" id="Active" name="Status" />
                    <label for="Active" class="pl-0">Yes</label>
                  </div>
                  <div class="form-check-inline">
                    <input type="radio" id="Inactive" name="Status" />
                    <label for="Inactive" class="pl-0">No</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Blood Sickness</label>
                <div class="d-flex align-items-center border-bottom">
                  <div class="form-check-inline mr-4">
                    <input type="radio" id="byes" name="Blood" value="yes" />
                    <label for="byes" class="pl-0">Yes</label>
                  </div>
                  <div class="form-check-inline">
                    <input type="radio" id="bno" name="Blood" value="No" />
                    <label for="bno" class="pl-0">No</label>
                  </div>
                </div>
              </div>
              <div class="col-12 describebox" id="byes">
                <label>Describe your Sickness issue</label>
                <textarea rows="4"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form>
      <div class="row mb-5">
        <div class="col-6 mb-3">
          <h5 class="mb-0">Skills</h5>
        </div>
        <div class="col-6 mb-3 text-right">
          <button type="submit" class="submit-btn border-0">Save</button>
        </div>
        <div class="col-lg-12">
          <div class="grey-box">
            <div class="row">
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Combat Skills</label>
                <div class="d-flex align-items-center border-bottom">
                  <div class="form-check-inline mr-4">
                    <input type="radio" id="cyes" name="Combat Skills" />
                    <label for="cyes" class="pl-0">Yes</label>
                  </div>
                  <div class="form-check-inline">
                    <input type="radio" id="cno" name="Combat Skills" />
                    <label for="cno" class="pl-0">No</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Armed</label>
                <div class="d-flex align-items-center border-bottom">
                  <div class="form-check-inline mr-4">
                    <input type="radio" id="ayes" name="Armed" />
                    <label for="ayes" class="pl-0">Yes</label>
                  </div>
                  <div class="form-check-inline">
                    <input type="radio" id="ano" name="Armed" />
                    <label for="ano" class="pl-0">No</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Profile</label>
                <div class="d-flex align-items-center border-bottom">
                  <div class="form-check-inline mr-4">
                    <input type="checkbox" id="plow" name="profile" />
                    <label for="plow" class="pl-0">Low</label>
                  </div>
                  <div class="form-check-inline">
                    <input type="checkbox" id="phigh" name="profile" />
                    <label for="phigh" class="pl-0">High</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Driver license Car</label>
                <div class="d-flex align-items-center border-bottom">
                  <div class="form-check-inline mr-4">
                    <input type="radio" id="clow" name="Driver Car" />
                    <label for="clow" class="pl-0">Yes</label>
                  </div>
                  <div class="form-check-inline">
                    <input type="radio" id="chigh" name="Driver Car" />
                    <label for="chigh" class="pl-0">No</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Driver license Motorbike</label>
                <div class="d-flex align-items-center border-bottom">
                  <div class="form-check-inline mr-4">
                    <input type="radio" id="mlow" name="Driver Motorbike" />
                    <label for="mlow" class="pl-0">Yes</label>
                  </div>
                  <div class="form-check-inline">
                    <input type="radio" id="mhigh" name="Driver Motorbike" />
                    <label for="mhigh" class="pl-0">No</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <label>Speaks</label>
                <input type="text" name="speaks" data-role="tagsinput" value="English,Hindi" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form>
      <div class="row mb-5">
        <div class="col-6 mb-3">
          <h5 class="mb-0">Experience</h5>
        </div>
        <div class="col-6 mb-3 text-right">
          <button type="submit" class="submit-btn border-0">Save</button>
        </div>
        <div class="col-lg-12">
          <div class="grey-box">
            <div class="row">
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Years of experience</label>
                <input type="text" name="address" />
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Work(ed) for Army</label>
                <div class="d-flex align-items-center border-bottom">
                  <div class="form-check-inline mr-4">
                    <input type="radio" id="wyes" value="yes" name="Worked Army" />
                    <label for="wyes" class="pl-0">Yes</label>
                  </div>
                  <div class="form-check-inline">
                    <input type="radio" id="wno" value="No" name="Worked Army" />
                    <label for="wno" class="pl-0">No</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Work(ed) for Police</label>
                <div class="d-flex align-items-center border-bottom">
                  <div class="form-check-inline mr-4">
                    <input type="radio" id="pyes" value="Yes" name="Armed" />
                    <label for="pyes" class="pl-0">Yes</label>
                  </div>
                  <div class="form-check-inline">
                    <input type="radio" id="pno" value="No" name="Armed" />
                    <label for="pno" class="pl-0">No</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 mb-3">
                <label>Able to work </label>
                <div class="d-flex align-items-center border-bottom">
                  <div class="form-check-inline mr-4">
                    <input type="checkbox" id="alone" value="alone" name="Able Work" />
                    <label for="alone" class="pl-0">Alone</label>
                  </div>
                  <div class="form-check-inline">
                    <input type="checkbox" id="group" value="in group" name="Able Work" />
                    <label for="group" class="pl-0">In Group</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form>
      <div class="row mb-5">
        <div class="col-lg-6 mb-5 mb-lg-0">
          <div class="row">
            <div class="col-md-6 col-8 mb-3">
              <h5 class="mb-0">Family</h5>
            </div>
            <div class="col-md-6 col-4 mb-3 text-right">
              <button type="submit" class="submit-btn border-0">Save</button>
            </div>
            <div class="col-lg-12">
              <div class="grey-box">
                <div class="row">
                  <div class="col-lg-6 col-md-6 mb-3">
                    <label>Married</label>
                    <div class="d-flex align-items-center border-bottom">
                      <div class="form-check-inline mr-4">
                        <input type="radio" id="myes" v-model="freelanceDetails.relationship" value="married" name="Married" />
                        <label for="myes" class="pl-0">Yes</label>
                      </div>
                      <div class="form-check-inline">
                        <input type="radio" id="mno" v-model="freelanceDetails.relationship" value="single" name="Married" />
                        <label for="mno" class="pl-0">No</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 mb-3">
                    <label>Children</label>
                    <div class="d-flex align-items-center border-bottom">
                      <div class="form-check-inline mr-4">
                        <input type="radio" id="childyes" value="Yes" name="Children" />
                        <label for="childyes" class="pl-0">Yes</label>
                      </div>
                      <div class="form-check-inline">
                        <input type="radio" id="childno" value="No" name="Children" />
                        <label for="childno" class="pl-0">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-md-6 col-8 mb-3">
              <h5 class="mb-0">In case of emergency:</h5>
            </div>
            <div class="col-md-6 col-4 mb-3 text-right">
              <button type="submit" class="submit-btn border-0">Save</button>
            </div>
            <div class="col-lg-12">
              <div class="grey-box">
                <div class="row">
                  <div class="col-lg-6 col-md-6 mb-3">
                    <label>Name</label>
                    <input type="text" name="name" />
                  </div>
                  <div class="col-lg-6 col-md-6 mb-3">
                    <label>Relation</label>
                    <input type="text" name="name" />
                  </div>
                  <div class="col-lg-6 col-md-6 mb-3">
                    <label>Phone number</label>
                    <input type="text" name="name" />
                  </div>
                  <div class="col-lg-6 col-md-6 mb-3">
                    <label>Email</label>
                    <input type="text" name="email" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
export default {
  mounted() {
    //document.title = "Edit Case " + this.$route.params.id

    this.$route.meta.title = this.companyName
    if (this.isValidId(this.$route.params.id)) {
      this.getFreelanceDetails()
    } else {
      this.$router.push('/404')
    }
  },
  metaInfo() {
    return {
      title: () => {
        return 'My title with params ' + this.$route.params.id
      },
    }
  },
  data() {
    return {
      freelanceDetails: {},
      file: '',
    }
  },
  methods: {
    isRequired(key) {
      if (this.requiredfileds.hasOwnProperty(key)) {
        return '*'
      } else {
        return ''
      }
    },
    /*
        Submits the file to the server
      */
    createCompany() {
      this.$toast.loading({
        message: 'Loading...',
        forbidClick: true,
      })
      /*
                Initialize the form data
            */

      let formData = new FormData()

      /*
                Add the form data we need to submit
            */

      formData.append('file', this.file)

      Object.entries(this.freelanceDetails).forEach(([key, value]) => {
        formData.append(key, value)
      })
      console.log(formData)

      /*
          Make the request to the POST /single-file URL
        */
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies'
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .post(api, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${admintoken}`,
          },
        })
        .then((response) => {
          console.log(response.data)
          if (response.data.code == 200) {
            console.log(response.data)
            this.$router.push('/companies')
          } else {
            this.errorMessage = response.data.message
            this.$dialog.alert({
              title: 'Failed',
              message: this.errorMessage,
              showCancelButton: false,
              confirmButtonText: 'OK',
            })
          }
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getAvatarImage(url) {
      return url ? url : 'https://dev-tp.sgp1.digitaloceanspaces.com/upload-files/5l28zWTqxbfmQrJqjYv7nMdEzsWIlrC4ycGY8vFn.png'
    },
    getFreelanceDetails() {
      const company_id = atob(this.$route.params.id)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/freelances/' + company_id
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.freelanceDetails = response.data.data
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },

    isValidId(str) {
      try {
        return btoa(atob(str)) == str
      } catch (err) {
        return false
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0]
      this.freelanceDetails.avatar = URL.createObjectURL(this.file)
      console.log(this.url)
    },
  },
}
</script>

<style scoped>
</style>