var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticStyle:{"background":"none","border":"none !important"}},[_c('div',{staticClass:"row pl-0 pr-0"},[_vm._m(0),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table data-table table-round table-hover"},[_vm._m(1),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-left"},[(_vm.status = 'warning')?_c('span',{staticClass:"circle-icon warning"},[_c('i',{staticClass:"fal fa-clock"})]):_vm._e()]),_vm._m(2),_vm._m(3),_vm._m(4)]),_vm._m(5),_vm._m(6)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 mt-3 pt-3"},[_c('h6',{staticClass:"uk-text-uppercase pl-1 uk-text-bold"},[_c('i',{staticClass:"fas fa-file-alt mr-2"}),_vm._v(" Previous Invoice")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-left",attrs:{"width":"75%"}},[_vm._v("Upload dated")]),_c('th',{staticClass:"text-left",attrs:{"width":"15%"}},[_vm._v("File")]),_c('th',{staticClass:"blankhead",attrs:{"width":"100px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"time",staticStyle:{"font-size":"12px"}},[_c('i',{staticClass:"fal fa-calendar mr-1 relative",staticStyle:{"top":"1px"},attrs:{"aria-hidden":"true"}}),_vm._v(" 14 AUG 2022 "),_c('span',{staticClass:"end-time ml-1",staticStyle:{"font-size":"11px"}},[_vm._v(" 20:03 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-left"},[_c('p',{staticClass:"pt-0 pb-0 mt-0 mb-0"},[_vm._v("Passport.jpg")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right"},[_c('span',{attrs:{"uk-lightbox":""}},[_c('a',{staticClass:"view-btn clickable only-icon pl-2 pr-2",attrs:{"href":"https://www.itwsf.com/Portals/0/Holographic-Passport-Film.jpg?ver=1Mjc1ciIZ-jzbuNI7QL1Ow%3D%3D","data-caption":"Passport.jpg"}})]),_c('a',{staticClass:"edit-btn pl-2 pr-2",attrs:{"data-toggle":"collapse","data-target":"#edit_invoice_file_1"}},[_c('i',{staticClass:"fal fa-edit"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"p-0",attrs:{"colspan":"8"}},[_c('div',{staticClass:"collapse",attrs:{"id":"edit_invoice_file_1"}},[_c('form',[_c('div',{staticClass:"p-4 bg-light",staticStyle:{"border":"solid 1px #e9e9e9","padding-bottom":"20px !important"}},[_c('div',{staticClass:"row p-0 pb-0 mt-0 mb-0 view-ui"},[_c('div',{staticClass:"col-md-12 p-0 pb-0 mt-0 mb-0"},[_c('div',{staticClass:"js-upload uk-placeholder uk-text-center mt-0 mb-0"},[_c('span',{staticClass:"mr-2 mt-1",attrs:{"uk-icon":"icon: cloud-upload"}}),_c('span',{staticClass:"uk-text-middle"},[_vm._v("Upload or Drag & Drop here")]),_c('div',{attrs:{"uk-form-custom":""}},[_c('input',{attrs:{"type":"file","multiple":""}})])]),_c('progress',{staticClass:"uk-progress",attrs:{"id":"js-progressbar","value":"0","max":"100","hidden":""}})])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td'),_c('td',{attrs:{"colspan":"7"}},[_c('a',{staticClass:"save-btn sendmail-btn border-0 mt-0 text-center border-radius-default uk-text-primary uk-text-uppercase",staticStyle:{"height":"auto","width":"auto !important","text-align":"left !important"},attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-plus mr-1",staticStyle:{"font-size":"12px","display":"block"},attrs:{"aria-hidden":"true"}}),_vm._v(" Add more ")])])])
}]

export { render, staticRenderFns }