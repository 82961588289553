<template>
  <div>
    <!----- Header Title ----->
    <div class="row content-header company">
      <div class="col-md-6">
        <div>
          <h3 class="page-title mb-1">
            {{ this.$route.meta.title }}
          </h3>
        </div>
        <div class="breadcrumb-container">
          <ul class="uk-breadcrumb">
            <li><span>Admin</span></li>
            <li><a href="">{{ this.$route.meta.title }}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 relative"></div>
    </div>

    <div class="row">
      <div class="col-md-9 mb-3 uk-animation-slide-bottom-small">
        <ul class="filter d-md-inline-flex d-none">
          <li :class="isActiveClassService('')" @click="filterService('')">
            <a>All</a>
          </li>
          <li :class="isActiveClassService('Bodyguard')" @click="filterService('Bodyguard')">
            <a>Bodyguard</a>
          </li>
          <li :class="isActiveClassService('Security')" @click="filterService('Security')">
            <a>Security</a>
          </li>
          <li :class="isActiveClassService('Private Investigator')" @click="filterService('Private Investigator')">
            <a>Private Investigator</a>
          </li>
          <li :class="isActiveClassService('Education')" @click="filterService('Education')">
            <a>Education</a>
          </li>
        </ul>
        <ul class="filter d-md-inline-flex d-none ml-2">
          <li :class="isActiveClassType('')" @click="filterType('')" v-on:click="content_type('')">
            <a>All</a>
          </li>
          <li :class="isActiveClassType('Companies')" @click="filterType('Companies')" v-on:click="content_type('company')">
            <a>Companies</a>
          </li>
          <li :class="isActiveClassType('Freelancers')" @click="filterType('Freelancers')" v-on:click="content_type('freelancer')">
            <a>Freelancers</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3 relative uk-animation-slide-bottom-small">
      </div>
    </div>

    <div class="row mt-4 mb-2 relative">
      <div class="col-md-12 col-12 text-right">
        <a href="#" uk-toggle="target: #addchoice" class="outline-btn pt-1" style="height: 40px;">
          <i class="fas fa-plus mr-2"></i>Add
        </a>
        <Search @filter="filter"></Search>

      </div>
    </div> 

    <div class="table-responsive mt-1">
      <table class="table data-table table-round table-hover uk-animation-slide-bottom-small">
        <thead>
          <tr>
                <th></th>
                <th>
                  Company Name <Sort column="name_en" @change="sortcol" />
                </th>
                <th>
                  Location <Sort column="location" @change="sortcol" />
                </th>
                <th width="15%">
                  Service Area
                </th>
                <th class="text-right" width="10%">
                  Missions
                </th>
                <!-- <th class="text-right" width="10%">
                  Ratings
                </th>  -->
                <th class="text-center" width="13%">
                  Status
                </th> 
                <th class="blankhead" width="5%"></th>
          </tr>
        </thead>
        <tbody>
              <tr v-for="(list, index) in companyList" :key="index">
                <td class="text-center"> 
                  <img :src="list.logo" class="uk-border-circle" width="55px" height="55px"> 
                </td>
                <td>
                  <router-link :to="'company/details/' + list.id">
                    {{ list.name_en }}
                  </router-link>
                </td>
                <td>{{ list.province }}</td>
                <td>
                  <span class="uk-label mr-1">{{ $options.categories[list.agency_type] }}</span>
                </td>
                <td class="text-right">{{ list.total_missions }}</td>
                <!-- <td class="text-right">
                  <span class="rating"> <i class="fas fa-star"></i> 5.0 </span>
                </td> -->
                <td class="text-center">
                  <label class="switch">
                    <input type="checkbox" v-model="list.active" @change="toggle(list.id, list.active)" />
                    <span class="slider round"></span>
                  </label>
                </td>
                <td class="text-right">
                  <router-link :to="'company/edit/' + list.id">
                    <a class="edit-btn"><i class="fal fa-edit"></i></a>
                  </router-link>
                  <a href="#" class="del-btn" v-on:click="del_method(list.id, list.name_en)"><i class="fal fa-trash-alt"></i></a>
                </td>
              </tr>
            </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="desc-list mt-4 mb-3">
          <ul>
            <li> <span class="uk-label mr-1">VI</span>  VIP Service </li>
            <li> <span class="uk-label mr-1">BG</span>  Bodyguard Service </li>
            <li> <span class="uk-label mr-1">SG</span>  Security Service </li>
            <li> <span class="uk-label mr-1">PI</span>  Private Investigate </li>
            <li> <span class="uk-label mr-1">ED</span>  Education </li>
            <li> <span class="uk-label mr-1">CA</span>  Car Rental Service </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card-footer pb-0" v-if="companyList.length > 0">
            <Pagination 
            @getCurrentPagination="getCurrentPagination" 
            :totalPages="paginData.totalPages"
            :page="paginData.page" />
        </div>
      </div>
    </div>

    <!-- Add Modal -->
    <div id="addchoice" uk-modal>
      <div class="uk-modal-dialog uk-modal-body text-center">
        <button class="uk-modal-close-outside" type="button" uk-close></button>
          <h5>What type of service provider are you adding?</h5>
          <hr>
          <a href="/add/company/" class="uk-button uk-button-primary mr-3" type="button">Agency</a>
          <a href="/add/freelancer/" class="uk-button uk-button-primary" type="button">Freelancer</a>
      </div>
    </div>

  </div>
</template>



<script>
const categories = {
  bodyguard: 'BG',
  security_company: 'SG',
  private_investigation: 'PI',
  vip_service: 'VI',
  car_rental: 'CA',
}

export default {
  categories: categories,
  mounted() {
    this.getCompanyList(this.page, this.limit, 'id', 'asc',this.companyType);
    $(function() {
			$("#datetime_picker").datepicker();
		});
  },
  data() {
    return {
      params: {
        sort: { column: 'id', type: 'desc' },
      },
      paginData: {
          page: 1,
          totalPages: 0,
          limit: this.$helpers.pageLimit()
      },
      companyType:'',
      filter_service: '',
      filter_type: '',
      sort: 'id',
      orderby: 'asc',
      companyList: [],
    }
  },
  methods: {
    content_type: function (content_type) {
      
    },
    del_method: function (id, name) {
      UIkit.modal.confirm('Do you want to delete company name: ' + name + " ? ").then(function () {
        console.log('Confirmed.')
      }, function () {
        console.log('Rejected.')
      });
    },
    filterType(filter){
        this.filter_type = filter
    },
    isActiveClassType(filter) {
      if (filter == this.filter_type) {
        return 'active'
      } else {
        return ''
      }
    },
    filterService(filter) {
      this.filter_service = filter
      this.getCompanyList(this.page, this.limit, this.sort, this.orderby, this.filter_service)
    },
    isActiveClassService(filter) {
      if (filter == this.filter_service) {
        return 'active'
      } else {
        return ''
      }
    },
    getCurrentPagination(currentPage) {
        this.paginData.page = currentPage
        this.getCompanyList()
    },
    getCompanyList(page, limit, col, sorttype, type) {
      this.page = page;
      let pagevalue = '' + page;
      if (pagevalue == 'undefined') {
        return
      }
      
      const api = 'admin/agencies'
      axios
        .get(api,{
          params:{
            page,
            limit,
            active:1,
            sort_column: col,
            sort_type: sorttype,
            agency_type: type
          }
        })
        .then((response) => {
          if(response.data.success) {
            this.companyList = response.data.data.records
            this.paginData.totalPages = response.data.data.total_pages
          }else{
            this.$helpers.notification(response.data.success, response.data.message)
          }
        })
        .catch((error) => {
          this.$helpers.notification(false, error.message)
          console.error('There was an error!', error)
        })
    },
    sortcol(sort) {
      this.params.sort = sort
      this.sort = sort.column
      this.orderby = sort.type
      this.getCompanyList(this.page, this.limit, sort.column, sort.type,this.companyType)
      console.log(sort.column, sort.type)
    },
    toggle(id, active) {
      const apiUrl = 'admin/agencies/' + id + '/general'
			axios
        .post(apiUrl, {active: active})
        .then((response) => {

            if(response.data.success) {
              
            }else{
                this.requiredfileds = response.data
            }
            
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error('There was an error!', error)
        })
    }
    
  },
}
</script>