<template>
    <li>
        <a class="uk-accordion-title" href="#"> 
            <span uk-icon="info" class="mr-2 relative" style="top: -2px;"></span> Company Information
        </a>
        <div class="uk-accordion-content">
            <div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="row align-items-center">
                            <div class="col-lg-12">
                                <div class="grey-box mb-3">
                                    <h6 class="p-2 mt-2 mb-3 uk-text-bold"> 
                                        <i class="fas fa-map-marker-alt mr-2"></i> Contact information to the company 
                                    </h6>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="company_owner_name" class="form-control" v-model="company.company_owner_name" required />
                                                <label>Owner</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="registration_id" class="form-control" v-model="company.registration_id" required />
                                                <label>Company Registration Number</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="tax_id" class="form-control" v-model="company.tax_id" required />
                                                <label>Tax ID</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <select id="founded_year" name="founded_year" v-model="company.founded_year" required>
                                                <option selected> -- Select -- </option>
                                                <option value="2022"> 2022 </option>
                                                <option value="2021"> 2021 </option>
                                                <option value="2020"> 2020 </option>
                                                </select>
                                                <label for="founded_year">Founded Year</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group vue-tel-input-wrapper">
                                                <template>
                                                <vue-tel-input name="phone" v-model="company.phone"></vue-tel-input>
                                                <label>Phone Number</label>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3 mb-md-0">
                                            <div>Can Operate other areas?</div>
                                            <div class="form-group">
                                                <div class="row">
													<div class="col-md-6 mb-5">
														<label class="form_radio">
                                                            <input type="radio" name="can_go_other_area" id="can_go_other_area_yes" v-model="canGoOtherArea" v-bind:value="areaActive" :checked="canGoOtherArea == areaActive" @click="areaActiveFun"  />
                                                            <span class="design"></span>
                                                            <span class="text">Yes</span>
														</label>
													</div>
													<div class="col-md-6 mb-5">
														<label class="form_radio">
                                                            <input type="radio" name="can_go_other_area" id="can_go_other_area_no" v-model="canGoOtherArea" v-bind:value="areaInActive" :checked="canGoOtherArea == areaInActive" @click="areaActiveFun" />
                                                            <span class="design"></span>
                                                            <span class="text">No</span>
														</label>
													</div>
												</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="login_phone_code" class="form-control" v-model="company.login_phone_code" autofocus required />
                                                <label>Phone Code</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="login_phone" class="form-control" v-model="company.login_phone" autofocus required />
                                                <label>Phone Number / Login</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3 mb-md-0">
                                            <div class="form-group">
                                                <input type="text" name="currency" class="form-control" v-model="company.currency" required />
                                                <label>Currency</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                            <input
                                                type="url"
                                                name="company_website"
                                                class="form-control"
                                                v-model="company.company_website"
                                                required
                                            />
                                            <label>Webpage</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <input type="text" name="address" class="form-control" v-model="company.address" required />
                                                <label>Address </label>
                                                <!-- <label style="color: red">{{ isRequired('address') }} </label> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="country" class="form-control" v-model="company.country" required />
                                                <label>Country </label>
                                                <!-- <label style="color: red">{{ isRequired('country') }} </label> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="province" class="form-control" v-model="company.province" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="district" class="form-control" v-model="company.district" required />
                                                <label for="district">District</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="sub_district" class="form-control" v-model="company.sub_district" required />
                                                <label>Subdistrict</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="zip_code" class="form-control" v-model="company.zip_code"  />
                                                <label>Zipcode</label>
                                                <!-- <label style="color: red">{{ isRequired('zip_code') }} </label> -->
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h6 style="font-weight: 600; color: #111;"> <span uk-icon="upload"></span> Upload DBD </h6>
                                            <div class="js-upload uk-placeholder uk-text-center mr-2">
                                                <div class="mb-2 mt-2">
                                                    <span class="uk-link btn-upload"> Upload </span>
                                                </div>
                                                <span uk-icon="icon: cloud-upload" class="mr-2"></span>
                                                <span class="uk-text-middle">  Drag & Drop the DBD file here (PNG, JPG, PDF) </span>
                                                <div uk-form-custom>
                                                    <input
                                                        type="file"
                                                        accept=""
                                                        name="dbd_doc"
                                                        class="file-upload"
                                                        id="file"
                                                        ref="dbdDoc"
                                                        v-on:change="dbdFileUpload()"
                                                    />
                                                    <img v-if="dbdDocUrl" :src="dbdDocUrl" />
                                                </div>
                                            </div>
                                            <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-12">
                        <div class="grey-box">
                            <h6 class="p-2 mt-2 mb-3 uk-text-bold"> <i class="fas fa-address-book mr-2"></i> Contact Person </h6>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="contact_person" class="form-control" v-model="company.contact_person" required />
                                        <label>Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input
                                        type="email"
                                        name="contact_person_email"
                                        class="form-control"
                                        v-model="company.contact_person_email"
                                        required
                                        />
                                        <label>Email</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input
                                        type="text"
                                        name="contact_person_position"
                                        class="form-control"
                                        v-model="company.contact_person_position"
                                        required
                                        />
                                        <label>Position</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group vue-tel-input-wrapper">
                                        <template>
                                            <vue-tel-input name="contact_person_number" v-model="company.contact_person_number"></vue-tel-input>
                                            <label>Number</label>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </li>
</template>

<script>

  export default {
    props: {
        company: {
            currency: String,
            registration_id: String,
            tax_id: String,
            founded_year: Number,
            can_go_other_area: Boolean,
            login_phone_code:String,
            login_phone: String,

            country: String,
            address: String,
            province: String,
            district: String,
            zip_code: Number,
            sub_district: String,
            dbd_doc: null,

            contact_person: String,
            contact_person_email: String,
            contact_person_position: String,
            contact_person_number: String,

            company_owner_name: String,
            company_website: String,
            company_lat: null,
            company_lng: null,
        }
    },
    data() {
      return {
        requiredfileds: {},
        
        canGoOtherArea: 1,
        areaActive: 1,
        areaInActive: 0,

        dbdDocUrl: ''
      }
    },
    mounted() {
        this.canGoOtherArea = this.company.can_go_other_area? this.areaActive : this.areaInActive
        this.dbdDocUrl = this.company.dbd_doc? this.company.dbd_doc : this.dbdDocUrl

    },
    methods: {
        areaActiveFun() {
            this.company.can_go_other_area = !this.company.can_go_other_area
            this.canGoOtherArea = this.company.can_go_other_area? this.areaActive : this.areaInActive
        },
        dbdFileUpload() {
            this.company.dbd_doc = this.$refs.dbdDoc.files[0]
            this.dbdDocUrl = URL.createObjectURL(this.company.dbd_doc)
        }
    }
  }

</script>