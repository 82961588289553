import { render, staticRenderFns } from "./AddCustomer.vue?vue&type=template&id=1835d482&scoped=true&"
import script from "./AddCustomer.vue?vue&type=script&lang=js&"
export * from "./AddCustomer.vue?vue&type=script&lang=js&"
import style0 from "./AddCustomer.vue?vue&type=style&index=0&id=1835d482&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1835d482",
  null
  
)

export default component.exports