var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content mt-0"},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center"},[_c('div',{staticClass:"col-xl-4 col-lg-5 col-md-7 mx-auto"},[_c('div',{staticClass:"card z-index-0 mb-4"},[_c('div',{staticClass:"card-body pt-5"},[_c('form',{attrs:{"role":"form"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('p',{staticClass:"text-sm mt-0 mb-0"},[_vm._v(" Already have an account? "),_c('router-link',{attrs:{"to":'/login/'}},[_c('a',{staticClass:"text-dark font-weight-bolder"},[_vm._v("Sign in")])])],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg",staticStyle:{"background-image":"url('https://rent.615autorental.com/assets/img/slider-2.jpeg')","background-position":"center"}},[_c('span',{staticClass:"mask bg-gradient-dark opacity-6"}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-5 text-center mx-auto"},[_c('h1',{staticClass:"text-white mb-0 mt-3"},[_vm._v("Welcome")]),_c('p',{staticClass:"text-lead text-white mt-0 mb-0"},[_vm._v("Start your booking system with 615 Auto Rental")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",attrs:{"aria-label":"Email"}},[_c('div',{},[_c('input',{staticClass:"form-control form-control-default invalid",attrs:{"type":"email","placeholder":"Email","isrequired":"false"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",attrs:{"aria-label":"Company"}},[_c('div',{},[_c('input',{staticClass:"form-control form-control-default invalid",attrs:{"type":"text","placeholder":"Company","isrequired":"false"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",attrs:{"aria-label":"Phone Number"}},[_c('div',{},[_c('input',{staticClass:"form-control form-control-default invalid",attrs:{"type":"text","placeholder":"Phone Number","isrequired":"false"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",attrs:{"aria-label":"Website"}},[_c('div',{},[_c('input',{staticClass:"form-control form-control-default invalid",attrs:{"type":"text","placeholder":"Website","isrequired":"false"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",attrs:{"aria-label":"Password"}},[_c('div',{},[_c('input',{staticClass:"form-control form-control-default invalid",attrs:{"type":"password","placeholder":"Password","isrequired":"false"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check signup"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","checked":""}}),_c('label',{staticClass:"custom-control-label"},[_c('label',{staticClass:"form-check-label",attrs:{"for":"flexCheckDefault"}},[_vm._v(" I agree the "),_c('a',{staticClass:"text-dark font-weight-bolder",attrs:{"href":"javascript:;"}},[_vm._v("Terms and Conditions")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check signup"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox"}}),_c('label',{staticClass:"custom-control-label"},[_c('label',{staticClass:"form-check-label",attrs:{"for":"flexCheckDefault"}},[_vm._v(" I agree the "),_c('a',{staticClass:"text-dark font-weight-bolder",attrs:{"href":"javascript:;"}},[_vm._v("Privacy Policy")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn mb-0 bg-gradient-dark text-white btn-md w-100 null my-4"},[_vm._v("Sign up")])])
}]

export { render, staticRenderFns }