<template>
    <!------- Service Provider ------->

    <div class="row align-items-center mb-4 service-provider">
        <div class="col-lg-12">
            <div class="grey-box p-3 pt-4">
                <div class="row">
                    <div class="col-md-6 text-left">
                        <h5 class="mt-1 mb-4 uk-text-bold"> 
                            <i class="fas fa-building mr-2"></i> Service Provider
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 relative pb-5">
                        <div class="profile-img position-relative text-center">
                            <img :src="agency.logo" v-bind:alt="agency.name_en" uk-cover>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <form id="client">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" name="company" v-model="agency.name" class="form-control" readonly>
                                        <label>Company/Freelancer Name</label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" name="contactperson" v-model="agency.contact_person" class="form-control" readonly>
                                        <label>Contact Person</label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group vue-tel-input-wrapper">
                                        <template>
                                            <vue-tel-input :value="agency.contact_person_number">
                                            </vue-tel-input>
                                            <label>Contact Number</label>
                                        </template>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" name="contactperson" class="form-control" v-model="agency.registration_id" readonly>
                                        <label>ID Number</label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" name="contactperson" class="form-control" v-model="agency.tax_id" readonly>
                                        <label>VAT Number</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-4 pb-4">
                                <div class="col-md-12">
                                    <h6 class="uk-text-uppercase"> <b>Address</b> </h6>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <input type="text" name="address" v-model="agency.address" class="form-control" readonly>
                                        <label>Address</label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" v-model="agency.country" class="form-control" readonly>
                                        <label>Country</label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" v-model="agency.sub_district" class="form-control" readonly>
                                        <label>Sub District</label>
                                    </div>
                                </div>
                                <div class="col-md-4 pr-2">
                                    <div class="form-group">
                                        <input type="text" v-model="agency.district" class="form-control" readonly>
                                        <label>District</label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" v-model="agency.province" class="form-control" readonly>
                                        <label>Province</label>
                                    </div>
                                </div>
                                <div class="col-md-12 text-right">
                                    <a :href="`/company/edit/${agency.id}`" target="_blank" id="client" class="edit-btns p-3 mr-2">
                                        <i class="fas fa-pen mr-2"></i> Edit 
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        agency: {
            id: Number,
            logo: String,
            name: String,
            contact_person: String,
            contact_person_number: String,
            registration_id: String,
            tax_id: String,
            address: String,
            sub_district: String,
            district: String,
            province: String,
            country: String,
        },
    },
    data() {
        return {
           
        }
    },
}
</script>

<style scoped>
.service-provider .grey-box input {
    background: #F0F2F5 !important;
}

.service-provider .grey-box input,
.service-provider .grey-box .vue-tel-input,
.service-provider .grey-box .vue-tel-input-wrapper {
    background: #F0F2F5 !important;
    border: solid 1px #e4e6ef !important;
}
.service-provider .grey-box .vue-tel-input-wrapper {
    background: none !important;
    border: 0px !important;
}
</style>