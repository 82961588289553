<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-3">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
        </div>
        <div class="col-md-6 relative"></div>
      </div>
      
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-map-pin mr-2"></i> Billing Address</h5>
              </div>
            </div>
            <div class="view-ui mt-4 mb-2">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>Company Name</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="email" class="form-control" value="" autocomplete="off" required>
                    <label>Email Address</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>Phone Number</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>Street</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>City</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>State</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>Zip</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                </button>
            </div>
          </div>
        </div>
        <div class="row w-100 m-0">
            <div class="col-md-9">
                <div class="grey-box mt-3 p-3 pt-4">
                    <div class="row">
                        <div class="col-md-6 text-left">
                            <h5 class="mt-1 mb-2 uk-text-bold">Subscription</h5>
                        </div>
                    </div>
                    <div class="choose-plan-box">
                        <div class="uk-float-left">
                            <ul class="filter d-md-inline-flex ">
                                <li class="dayIndex active" >
                                    <a href="#">Monthly</a>
                                </li>
                                <li class="dayIndex" >
                                    <a href="#">Yearly</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <ul class="dashboard-page billingdash">
                        <li class="one-value default blue">
                            <div class="icon">
                                <i class="far fa-male"></i>
                            </div>
                            <div class="result">
                                <div class="uk-text-center">
                                    <div class="uk-width-1-1 main">
                                        <span class="title">
                                            <span class="primary-title"> Basic </span>
                                            <span class="sub-title"> UP TO 10 VEHICLES </span>
                                            <span class="sub-title"> UP TO 1 BRANCH </span>
                                        </span>
                                        <span class="value count border border-radius-default"> $120 </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="one-value default blue">
                            <div class="icon">
                                <i class="far fa-female"></i>
                            </div>
                            <div class="result">
                                <div class="uk-text-center">
                                    <div class="uk-width-1-1 main">
                                        <span class="title">
                                            <span class="primary-title"> Standard </span>
                                            <span class="sub-title"> UP TO 25 VEHICLES </span>
                                            <span class="sub-title"> UP TO 1 BRANCH </span>
                                        </span>
                                        <span class="value count border border-radius-default"> $150 </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="one-value default blue">
                            <div class="icon">
                                <i class="fab fa-uncharted"></i>
                            </div>
                            <div class="result">
                                <div class="uk-text-center">
                                    <div class="uk-width-1-1 main">
                                        <span class="title">
                                            <span class="primary-title"> Pro </span>
                                            <span class="sub-title"> UP TO 50 VEHICLES </span>
                                            <span class="sub-title"> UP TO 1 BRANCH </span>
                                        </span>
                                        <span class="value count border border-radius-default"> $250 </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="row">
                        <div class="col-md-6 text-left">
                            <h5 class="mt-1 mb-2 uk-text-bold">Support Plan</h5>
                        </div>
                    </div>
                    <ul class="dashboard-page billingdash">
                        <li class="one-value default blue">
                            <div class="icon">
                                <i class="far fa-male"></i>
                            </div>
                            <div class="result">
                                <div class="uk-text-center">
                                    <div class="uk-width-1-1 main">
                                        <span class="title">
                                            <span class="primary-title"> Basic </span>
                                            <span class="sub-title"> 5 SUPPORT CREDITS </span>
                                        </span>
                                        <span class="value count border border-radius-default"> $500 </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="one-value default blue">
                            <div class="icon">
                                <i class="far fa-female"></i>
                            </div>
                            <div class="result">
                                <div class="uk-text-center">
                                    <div class="uk-width-1-1 main">
                                        <span class="title">
                                            <span class="primary-title"> Standard </span>
                                            <span class="sub-title"> 10 SUPPORT CREDITS </span>
                                        </span>
                                        <span class="value count border border-radius-default"> $900 </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="one-value default blue">
                            <div class="icon">
                                <i class="fab fa-uncharted"></i>
                            </div>
                            <div class="result">
                                <div class="uk-text-center">
                                    <div class="uk-width-1-1 main">
                                        <span class="title">
                                            <span class="primary-title"> Pro </span>
                                            <span class="sub-title"> 20 SUPPORT CREDITS </span>
                                        </span>
                                        <span class="value count border border-radius-default"> $1,600 </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="row">
                        <div class="col-md-6 text-left">
                            <h5 class="mt-1 mb-2 uk-text-bold">Payment</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="grey-box p-3 pt-4 mt-3">
                    <div class="row">
                        <div class="col-md-6 text-left">
                            <h5 class="mt-1 mb-2 uk-text-bold">Enterprise Subscription</h5>
                        </div>
                    </div>
                    <div class="view-ui mt-4 mb-2">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" value="" autocomplete="off" required>
                                    <label>Number of vehicles</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <h6 class="mt-3 mb-2">LICENSE COST</h6>
                                <p class="text-primary mt-0"><b>$90</b> per month or <b>$990</b> per year</p>
                            </div>
                            <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                                <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                                <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    
  </template>
  
  
  
  <script>

export default {
    props: {
        compartnerl: {
            rental_agreement_doc: null,
        }
    },
    data() {
        return {
            requiredfileds: {},
            legalUrl: ''
        }
    },
    mounted() {
        console.log(this.compartnerl)
        this.legalUrl = this.compartnerl.rental_agreement_doc? this.rental_agreement_doc : this.legalUrl
    },
    methods: {
        rentalAgreementDocUpload() {
            this.compartnerl.rental_agreement_doc = this.$refs.legalAgreementDoc.files[0]
            this.legalUrl = URL.createObjectURL(this.compartnerl.rental_agreement_doc)
        },
        updateLegal() {
            let form = this.$refs.updateLegal;
            let formData = new FormData(form);

            const apiUrl = 'admin/agencies/' + this.$route.params.id + '/legal_agreement'
            axios
            .post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            )
            .then((response) => {
                if(response.data.success) {
                    this.success()
                }else{
                    this.requiredfileds = response.data
                }
            })
            .catch((error) => {
            this.errorMessage = error.message;
            console.error('There was an error!', error)
            })
        },
        downloadPdf(url, title = 'Legal Agreement') {
            axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            })
            .then((response) => {
                this.forceFileDownload(response, title)
            })
            .catch(() => console.log('error occured'))
        },
        forceFileDownload(response, title) {
            console.log(title)
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },
    }
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>