<template>
    <div>
        <a v-for="(staff, staffKey) in showProfiles" :key="staffKey" href="" :class="(staffKey == 3)? 'avatar-more' : 'avatar' " v-bind:uk-tooltip="staff.full_name">
            <img
            :src="staff.avatar"
            class="uk-border-circle"
            width="50px"
            height="50px"
            />
            <span v-show="staffKey == 3" class="left-total"> +{{ showProfiles.length - 3 }} </span>
        </a>
        <div v-show="popupProfiles.length > 0" class="border-radius-default p-2" uk-dropdown>
            <ul class="uk-nav uk-dropdown-nav">
                <li v-for="(staff1, staffKey1) in popupProfiles" :key="staffKey1" >
                    <a href="#">
                    <img
                        :src="staff1.avatar"
                        class="uk-border-circle"
                        width="50px"
                        height="50px"
                    />
                    {{ staff1.full_name }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        profiles: Array
    },
    data() {
        return {
            showProfiles: [],
            popupProfiles: []
        }
    },
    mounted() {

        if(this.profiles.length > 0){
            this.showProfiles = this.profiles.splice(0, 4);
            console.log(this.showProfiles.length)
            this.popupProfiles = this.profiles.splice(-3);
        }
    }
}
</script>