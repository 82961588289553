<template>
    <div>
        <!----- Header Title ----->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h3 class="page-title mb-1">
                        {{ this.$route.meta.title }}
                    </h3>
                </div>
                <div class="breadcrumb-container">
                    <ul class="uk-breadcrumb">
                        <li><span>Admin</span></li>
                        <li><a href="/reservation">Reservation</a></li>
                        <li><a href="">{{ this.$route.meta.title }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 relative"></div>
        </div>
        <!-- New Reservation -->
        <div class="row align-items-center mb-4">
            <div class="col-lg-12">
                <section class="grey-box register" v-if="!hasSeenCongrats">
                    <div class="register-stepper">
                        <div class="step step-done"><span class="step-number">1</span></div>
                        <div class="step step-done"><span class="step-number">2</span></div>
                        <div class="step step-done"><span class="step-number">3</span></div>
                        <div class="step step-done"><span class="step-number">4</span></div>
                        <div class="step step-done"><span class="step-number">5</span></div>
                        <div class="step step-active"><span class="step-number">6</span></div>
                    </div>

                    <transition>
                        <section>
                            <div class="form">
                                <div class="row">
                                    <div class="col-md-8">

                                        <p class="text-uppercase text-sm mb-2">Process payment</p>
                                        <hr class="horizontal dark mt-0" />
                                        <div class="row">
                                            <!-- <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        v-model="reservation.total_price" value="$150" readonly />
                                                    <label>Amount</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" v-model="reservation.notes"
                                                        value="Payment for Reservation #54" readonly />
                                                    <label>Description</label>
                                                </div>
                                            </div> -->
                                            <div class="col-md-12">
                                                <div class="form-group mb-3">
                                                    <label class="form_radio float-left mr-3" style="height: 10px;">
                                                        <input type="radio" name="paymenttypeopt"
                                                            id="paymentType_offline" v-model="radioButton"
                                                            value="offline" />
                                                        <span class="design"></span>
                                                        <span class="text">Offline</span>
                                                    </label>
                                                    <!-- <label class="form_radio"
                                                        style="height: 10px;margin-left: 20px!important;">
                                                        <input type="radio" name="paymenttypeopt"
                                                            id="paymentType_online" v-model="radioButton"
                                                            value="online" />
                                                        <span class="design"></span>
                                                        <span class="text">Online</span>
                                                    </label> -->
                                                </div>
                                            </div>
                                        </div>
                                        <!-- OFFLINE PAYMENT -->
                                        <div class="row" id="offline_box">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <select class="form-control" v-model="payment_type">

                                                        <option value="Cash">Cash</option>
                                                        <option value="Card">Credit Card </option>
                                                        <option value="Bank">Bank Transfer</option>

                                                    </select>
                                                    <label>Payment Type</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6" hidden>
                                                <div class="form-group">
                                                    <input type="text" v-model="payment_method" class="form-control"
                                                        required />
                                                    <label>Payment Method</label>
                                                </div>
                                            </div>
                                            <!-- Realtime -->
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="date" v-model="payment_date" class="form-control"
                                                        required />
                                                    <label>Date</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div id="credit_card_box">
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <select class="form-control" v-model="card_expiry_date">
                                                                    <option value="1">January</option>
                                                                    <option value="2">February</option>
                                                                    <option value="3">March</option>
                                                                    <option value="4">April</option>
                                                                    <option value="5">May</option>
                                                                    <option value="6">June</option>
                                                                    <option value="7">July</option>
                                                                    <option value="8">August</option>
                                                                    <option value="9">September</option>
                                                                    <option value="10">October</option>
                                                                    <option value="11">November</option>
                                                                    <option value="12">December</option>
                                                                </select>
                                                                <label>Exp. month</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <select class="form-control" v-model="card_expiry_year">
                                                                    <option value="2024">2024</option>
                                                                    <option value="2025">2025</option>
                                                                    <option value="2026">2026</option>
                                                                    <option value="2027">2027</option>
                                                                    <option value="2028">2028</option>
                                                                    <option value="2029">2029</option>
                                                                    <option value="2030">2030</option>
                                                                    <option value="2031">2031</option>
                                                                    <option value="2032">2032</option>
                                                                    <option value="2033">2033</option>
                                                                    <option value="2034">2034</option>
                                                                    <option value="2035">2035</option>
                                                                    <option value="2036">2036</option>
                                                                    <option value="2037">2037</option>

                                                                </select>
                                                                <label>Exp. year</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <select class="form-control" v-model="card_type">
                                                                    <option value="Visa">Visa</option>
                                                                    <option value="Master Card">Master Card</option>
                                                                    <option value="Discover">Discover</option>
                                                                </select>
                                                                <label>Brand</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control"
                                                                    v-model="card_last_digit" required />
                                                                <label>Last 4 digits</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" v-model="amountpaid"
                                                        required />
                                                    <label>Amount</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <select type="text" class="form-control" v-model="payment_status">

                                                        <option value="approved">Approved</option>
                                                        <option value="denied">Denied</option>
                                                    </select>
                                                    <label>Payment Status</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group mb-3">
                                                    <div class="row w-100">
                                                        <div class="col-md-7">
                                                            <label>Send Confirmation Email</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <label class="form_radio float-left mr-3"
                                                                style="height: 20px;">
                                                                <input type="radio" value="yes" name="sendemailOpt"
                                                                    v-model="send_email" id="send_yes">
                                                                <span class="design"></span>
                                                                <span class="text">Yes</span>
                                                            </label>
                                                            <label class="form_radio" style="height: 20px;">
                                                                <input type="radio" value="no" name="sendemailOpt"
                                                                    v-model="send_email" id="send_no">
                                                                <span class="design"></span>
                                                                <span class="text">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <button @click="savepayment()" class="btn btn-sm btn-primary"
                                                    style="width: 200px;">Save</button>
                                            </div>
                                        </div>

                                        <!-- ONLINE PAYMENT -->
                                        <!-- <div class="row mt-3" id="online_box" v-if="radioButton=='online'">
                                        <div class="col-md-12 mb-3">
                                            <div class="form-group mb-3">
                                                <label class="form_radio float-left mr-3" style="height: 10px;">
                                                    <input type="radio" name="paymenttypeopt" id="paymentType_offline" />
                                                    <span class="design"></span>
                                                    <span class="text">
                                                        <img src="/assets/img/pay_pal.png" style="width: 130px;" />
                                                    </span>
                                                </label>
                                                <label class="form_radio" style="height: 10px;margin-left: 20px!important;">
                                                    <input type="radio" name="paymenttypeopt" id="paymentType_online" />
                                                    <span class="design"></span>
                                                    <span class="text">
                                                        <img src="/assets/img/stripe_sca.png" style="height: 40px;" />
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="email" class="form-control" required/>
                                                <label>Email Address</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="date" class="form-control" required/>
                                                <label>Due Date</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <client-only>
                                                <VueEditor />
                                            </client-only>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <button class="btn btn-sm btn-primary" style="width:200px">Send Request</button>
                                        </div>
                                    </div> -->
                                    </div>
                                    <div class="col-md-4">
                                        <p class="text-uppercase text-sm mb-2">Summary</p>
                                        <hr class="horizontal dark mt-0" />
                                        <table class="table table-bordered">
                                            <tr>
                                                <td>
                                                    <h6 class="text-sm mb-0 ">Vehicle</h6>
                                                </td>
                                                <td class="text-right">
                                                    <p class="text-sm mb-0">{{ reservation.total_days }}x days</p>
                                                </td>
                                                <td class="text-right">
                                                    <p class="text-sm mb-0">${{ reservation.total_price }}</p>
                                                </td>
                                            </tr>
                                            <!-- <tr>
                                                <td>
                                                    <h6 class="text-sm mb-0">CDW</h6>
                                                </td>
                                                <td colspan="2" class="text-right">
                                                    <p class="text-sm mb-0">$8</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 class="text-sm mb-0">Insurance</h6>
                                                </td>
                                                <td colspan="2" class="text-right">
                                                    <p class="text-sm mb-0">$11</p>
                                                </td>
                                            </tr> -->
                                            <!-- <tr>
                                                <td>
                                                    <h6 class="text-sm mb-0">Discount</h6>
                                                </td>
                                                <td class="text-right">
                                                    <p class="text-sm mb-0">s6xwOH</p>
                                                </td>
                                                <td class="text-right">
                                                    <p class="text-sm mb-0">$2</p>
                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td>
                                                    <h6 class="text-sm mb-0">TOTAL</h6>
                                                </td>
                                                <td colspan="2" class="text-right">
                                                    <h4 class="mr-0 mb-0">${{ reservation.total_price }}</h4>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                </div>

                                <div class="form-group cta-step mt-4">
                                    <div class="cta prev">
                                        <p class="cta-color">
                                            <span class="link_wrap">
                                                <router-link :to="'/new/reservation/5'">
                                                    <a class="btn prev-btn link_text"><span class="arrow-prev"
                                                            style="padding-right: 5px;"></span>Previous</a>
                                                </router-link>
                                            </span>
                                        </p>
                                    </div>
                                    <div class="cta">
                                        <p class="cta-color">
                                            <span class="text"></span>
                                            <span class="link_wrap">
                                                <input type="button" @click="completeReservation" value="Reserve"
                                                    class="link_text btn next-btn" />
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </transition>
                </section>
                <!-- <section class="congrats register text-center mt-5" v-if="hasSeenCongrats">
                <div class="register-icon">
                    <img class="register-icon-item" src="../../../public/assets/img/success-goal.png" alt="vue logo">

                </div>
                <h2 class="congrats-title">Success!</h2>
                <p class="congrats-subtitle">
                    You have successfully made a reservation!
                </p>
                <button @click="redirectReservation()" class="btn btn-primary">Go Back</button>
            </section> -->
            </div>
        </div>


        <!-- Add ** in div -->
        <!-- Create success Modal -->
        <div id="createmodal" uk-modal>
            <div class="uk-modal-dialog uk-modal-body p-2 text-center">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div class="row align-items-center ">
                    <div class="col-md-12 mt-4">
                        <div class="register-icon mt-0 mb-0">
                            <img class="register-icon-item" src="../../../public/assets/img/success-goal.png"
                                alt="vue logo">

                        </div>
                        <h2 class="congrats-title mt-0">Success!</h2>
                        <p class="congrats-subtitle">
                            You have successfully made a payment!
                        </p>
                    </div>
                </div>
                <a href="#" class="uk-button uk-button-default mb-4" type="button">Go Back</a>
            </div>
        </div>

        <div id="createmodal2" uk-modal>
            <div class="uk-modal-dialog uk-modal-body p-2 text-center">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div class="row align-items-center ">
                    <div class="col-md-12 mt-4">
                        <div class="register-icon mt-0 mb-0">
                            <img class="register-icon-item" src="../../../public/assets/img/success-goal.png"
                                alt="vue logo">

                        </div>
                        <h2 class="congrats-title mt-0">Success!</h2>
                        <p class="congrats-subtitle">
                            You have successfully complete reservation!
                        </p>
                    </div>
                </div>
                <a @click="backtoMain()" class="uk-button uk-button-default mb-4" type="button">Go Back</a>
            </div>
        </div>

        <!-- Add **uk-toggle="target: #updatemodal" in div -->
        <!-- Create success Modal -->
        <div id="updatemodal" uk-modal>
            <div class="uk-modal-dialog uk-modal-body p-2 text-center">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div class="row align-items-center mb-4">
                    <div class="col-md-12 mt-4">
                        <h4>Update reservation successful!</h4>
                    </div>
                </div>
                <a href="#" class="uk-button uk-button-default mr-3 mb-4" type="button">Okay</a>
            </div>
        </div>
    </div>

</template>



<script>
import router from '../../routes';

export default {
    el: "#app",
    data: () => {
        return {
            steps: {},
            paymentopt: 'offline',
            step: 1,
            payment_status: 'denied',
            radioButton: 'offline',
            payment_type: "Cash",
            payment_method: 'Cash',
            customer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            card_expiry_date: '1',
            card_expiry_year: '2024',
            card_type: 'Visa',
            card_last_digit: '',
            reservation: {},
            hasSeenCongrats: false,
            tempCustomer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            send_email: 'no',
            amountpai: 0
        };
    },
    mounted() {
        this.getReservationDetails()
    },
    methods: {
        backtoMain(){
            location.href='/reservation'
        },
        completeReservation() {

            let reservationData = {
                "is_complete": 1,

            }
            const admintoken = localStorage.getItem('auth_token_default')
            const reservation_id = localStorage.getItem("reservation_id")
            if (reservation_id) {
                axios.put(`https://api.615autorental.com/api/admin/reservation/` + reservation_id, reservationData, {
                    headers: {
                        'Authorization': `Bearer ${admintoken}`,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {

                        UIkit.modal('#createmodal2').show();
                        console.log('Customer updated successfully:', response.data);
                        // handle success
                    })
                    .catch(error => {
                        alert("Please complete form")
                        console.error('Error updating customer:', error);
                        // handle error
                    });
            } else {
                location.href = "/new/reservation/1"
            }
        },
        savepayment() {
            const reservation_id = localStorage.getItem("reservation_id")
            let paymentData = {
                "reservation_id": reservation_id,
                "customer_id": this.reservation.customer.id,
                "payment_type": this.payment_type,
                "payment_method": this.payment_method,
                "date": this.payment_date,
                "amount": this.amountpaid,
                "payment_status": this.payment_status,
                "total_price": this.reservation.total_price,
                "total_revenue": this.reservation.total_revenue,
                "total_paid": this.reservation.total_paid,
                "outstanding_balance": this.reservation.total_balance,
                "status": "Active",
                "total_days": this.reservation.total_days,
                "card_type": this.card_type,
                "card_date": this.card_expiry_date,
                "card_year": this.card_expiry_year,
                "card_lastdigit": this.card_last_digit
            }
            const admintoken = localStorage.getItem('auth_token_default')


            axios.post(`https://api.615autorental.com/api/admin/payment`, paymentData, {
                headers: {
                    'Authorization': `Bearer ${admintoken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {

                    UIkit.modal('#createmodal').show();
                    console.log('Customer updated successfully:', response.data);
                    // handle success
                })
                .catch(error => {
                    alert("Please complete form")
                    console.error('Error updating customer:', error);
                    // handle error
                });


        },
        getReservationDetails() {
            const reservation_id = localStorage.getItem("reservation_id")
            const api = 'https://api.615autorental.com/api/admin/reservation/' + reservation_id + '/details'
            const admintoken = localStorage.getItem('auth_token_default')
            const headers = {
                'Authorization': `Bearer ${admintoken}`,
                'Content-Type': 'application/json' // You can adjust content type as needed
            };
            axios
                .get(api, { headers }, {
                    params: {
                        page: 1,
                        limit: 30
                    }
                })
                .then((response) => {
                    this.reservation = response.data.data

                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        redirectReservation() { // 
            router.push({ path: '/reservation/' })
        },

        prev() {
            this.step--;
        },

        next() {
            this.step++;
        },

        newBooking: function () {
            this.hasSeenCongrats = true;
        },
    }

};
</script>

<style scoped>
.card-footer {
    text-align: right;
    position: relative;
    background: #fff;
}

.pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
}
</style>