<template>
  <div>
    <!----- Header Title ----->
    <div class="row">
      <div class="col-md-6">
        <div>
          <h3 class="page-title mb-1">
            {{ this.$route.meta.title }}
          </h3>
        </div>
        <div class="breadcrumb-container">
          <ul class="uk-breadcrumb">
            <li><span>Admin</span></li>
            <li><a href="">{{ this.$route.meta.title }}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 relative"></div>
    </div>
    <div class="row mt-2">
      <div class="col-md-8">
      </div>
      <div class="col-md-4 relative">
        <div class="uk-position-bottom-right pr-3">
          <div class="mb-3 text-right">
            <router-link :to="'/new/reservation/'">
              <a uk-toggle="target: #addexternalcharge" class="outline-btn pt-1" style="padding: 5px 10px;">
                <i class="fas fa-plus mr-2"></i>Add External Charge
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table data-table table-round table-hover">
        <thead>
          <tr>
            <th>
              ID
              <Sort column="id" />
            </th>
            <th>
              Label
              <Sort column="name_en" />
            </th>
            <th>
              Charge Amount
            </th>
            <th>
              Charge Date
            </th>
            <th>
              Reservation
            </th>
            <th>
              Vehicle
            </th>
            <th>
              Status
            </th>
            <th>
              Driver
            </th>
            <th>
              Notes
            </th>
            <th class="blankhead"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in externalChargesList" :key="index">
            <td>{{ list.id }}</td>
            <td>{{ list.label }}</td>
            <td>{{ list.amount }}</td>
            <td>{{ list.charge_datetime }}</td>
            <td>#{{ list.reservation_id }} </td>
            <td>{{ list.vehicle.model }}</td>
            <td><span class="badge badge-success">{{ list.status }}</span></td>
            <td>{{ list.driver }}</td>
            <td>{{ list.notes }}</td>
          </tr>



        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- PAGINATION -->
    <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
      <jw-pagination :items="pages" @changePage="onChangePage" :pageSize="limit" :styles="customStyles"
        :labels="customLabels"></jw-pagination>
    </div>

    <!-- Add Modal -->
    <div id="addexternalcharge" uk-modal>
      <div class="uk-modal-dialog uk-modal-body p-2 text-center">
        <button class="uk-modal-close-outside" type="button" uk-close></button>
        <div class="row align-items-center mb-4">
          <div class="col-lg-12">
            <div class="grey-box">
              <div class="view-ui mt-4 mb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="chargelabel" value="" autocomplete="off"
                        required>
                      <label>Label</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <select class="form-control" v-model="reservationID" required>
                        <option v-for="(reservation, index) in reservationList" :key="index" :value="reservation.id">
                            {{ reservation.id }} {{ showCustomerName(reservation.customer) }} <!-- Assuming you have a 'label' property in your reservation object -->
                        </option>
                    </select>
                      <label>Reservation</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <select class="form-control" v-model="vehicleId" required>
                        <option value="1">Test Vehicle</option>
                        <option value="2">Test Vehicle</option>
                       
                      </select>
                      <label>Vehicles</label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="amount" value="" autocomplete="off" required>
                      <label>Charge Amount</label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="date" class="form-control" value="" v-model="chargeDate" autocomplete="off" required>
                      <label>Charge Date</label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="time" class="form-control" v-model="chargeTime" value="" autocomplete="off" required>
                      <label>Charge Time</label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <textarea class="form-control" v-model="notes" required></textarea>
                      <label>Notes</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <select class="form-control" v-model="status" required>
                        <option value="Waiting Payment">Waiting Payment</option>
                        <option value="Payment Request Sent">Payment Request Sent</option>
                        <option value="Paid">Paid</option>
                        <option value="In Dispute">In Dispute</option>
                      </select>
                      <label>Status</label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <h6 style="font-weight: 600; color: #111;margin-top: 10px;"> <span uk-icon="upload"></span> Upload
                      Document </h6>
                    <div class="js-upload uk-placeholder uk-text-center mr-2">
                      <div class="mb-2 mt-2">
                        <span class="uk-link btn-upload"> Upload </span>
                      </div>
                      <span uk-icon="icon: cloud-upload" class="mr-2"></span>
                      <span class="uk-text-middle"> Drag & Drop file </span>
                      <div uk-form-custom>
                        <input type="file" name="externalfile_doc" class="file-upload" id="file" ref="legalAgreementDoc"
                          v-on:change="legalAgreementDocUpload()" />
                        <img v-if="legalUrl" :src="legalUrl" />

                      </div>
                    </div>
                    <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                  </div>
                  <div class="clearfix"></div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <button @click="save()" id="client" class="save-btn border-0 ml-2 mr-2"> 
          <i class="fal fa-check-circle mr-2"></i> Save
        </button>
      </div>
    </div>
  </div>

</template>



<script>

export default {
  props: {
    externalchargesl: {
      legal_agreement_text: String,
      externalfile_doc: null,
    }
  },
  data() {
    return {
      requiredfileds: {},
      legalUrl: '',
      externalChargesList: [],
      chargelabel: '',
      reservationID: 0,
      vehicleId: "1",
      amount: 0,
      chargeDate: '',
      chargeTime: '',
      notes: '',
      status: 'Waiting Payment',
      attachments: []
    }
  },
  mounted() {
    this.getReservationList()
    this.getExternalCharges()
    console.log(this.externalchargesl)
    this.legalUrl = this.externalchargesl.externalfile_doc ? this.externalfile_doc : this.legalUrl
  },
  methods: {
    showCustomerName(customer){
      return customer.fname + ' ' + customer.lname
    },
    legalAgreementDocUpload() {
      this.externalchargesl.externalfile_doc = this.$refs.legalAgreementDoc.files[0]
      this.legalUrl = URL.createObjectURL(this.externalchargesl.externalfile_doc)
    },
    save(){
      if(this.reservationID == 0){
        alert("Please select reservation")
      }else{
        const updatedCustomerData = {
          "reservation_id": this.reservationID,
          "label": this.chargelabel,
          "driver":"Not Set",
          "charge_datetime": this.chargeDate + ' ' + this.chargeTime ,
          "amount": this.amount,
          "notes": this.notes,
          "status": this.status
      };
			const admintoken = localStorage.getItem('auth_token_default')
			axios.post(`https://api.615autorental.com/api/admin/external_charges`, updatedCustomerData, {
					headers: {
					'Authorization': `Bearer ${admintoken}`,
					'Content-Type': 'application/json'
					}
				})
				.then(response => {
					console.log('Customer updated successfully:', response.data);
          location.reload()
					// handle success
				})
				.catch(error => {
					console.error('Error updating customer:', error);
					// handle error
				});
      }

			// Assuming you have the updated customer data stored in a variable called updatedCustomerData
			
			
		},
    getReservationList() {
      const api = 'https://api.615autorental.com/api/admin/reservation'
      const admintoken = localStorage.getItem('auth_token_default')
      const headers = {
        'Authorization': `Bearer ${admintoken}`,
        'Content-Type': 'application/json' // You can adjust content type as needed
      };
      axios
        .get(api, { headers }, {
          params: {
            page: 1,
            limit: 30
          }
        })
        .then((response) => {
          this.reservationList = response.data.data
          if( this.reservationList.length > 0)
            this.reservationID = this.reservationList[0].id
        })
        .catch((error) => {
          console.error('There was an error!', error.message)
        })
    },
    getExternalCharges() {
      const api = 'https://api.615autorental.com/api/admin/external_charges'
      const admintoken = localStorage.getItem('auth_token_default')
      const headers = {
        'Authorization': `Bearer ${admintoken}`,
        'Content-Type': 'application/json' // You can adjust content type as needed
      };
      axios
        .get(api, { headers }, {
          params: {
            page: 1,
            limit: 30
          }
        })
        .then((response) => {
          this.externalChargesList = response.data.data
        })
        .catch((error) => {
          console.error('There was an error!', error.message)
        })
    }

  }
}
</script>

<style scoped>
.card-footer {
  text-align: right;
  position: relative;
  background: #fff;
}

.pagination {
  top: 20px;
  position: absolute;
  right: 0px;
  align-content: flex-end;
  text-align: end;
}
</style>