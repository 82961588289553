<template>
  	<div v-if="ready">
		<!----- Header Title ----->
		<section class="row">
			<div class="col-md-6">
				<div>
					<h3 class="page-title mb-1">
							{{ this.$route.meta.title }}
					</h3>
				</div>
				<div class="breadcrumb-container">
					<ul class="uk-breadcrumb">
						<li><span>Admin</span></li>
						<li><a href="/missions">Missions</a></li>
						<li><a href="">{{ this.$route.meta.title }}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-md-6 relative"></div>
		</section>
		<section class="tpui-container">
				<div class="row view-ui">
					<div class="col-md-12">
						<div class="grey-box p-3 pt-4 mb-3">
							<div class="row">
								<div class="col-md-6 text-left">
									<h5 class="mt-2 pb-2 mb-4 uk-text-bold"> <i class="fas fa-location mr-2"></i> Mission </h5>
								</div>
								<div class="col-md-6 text-right">
									<div>
										<a class="view-btn" uk-toggle="target: #modal-mission"> More Details</a>
									</div>
								</div>
							</div>
							<div class="row">
									<div class="col-md-3 pb-4">
										<div>
											<h6 class="title mb-3"> Start Time </h6>
											<span class="uk-label" v-if="missionDetails.start_time != null" style="background-color: #1c3c9d; font-size: 11px;">{{missionDetails.formatted_start_time}}</span>
											<p class="result m-0 mb-4" v-if="missionDetails.start_time == null"> - </p>
										</div>
									</div>
									<div class="col-md-3 pb-4">
										<div>
											<h6 class="title mb-3"> End Time </h6>
											<span class="uk-label" v-if="missionDetails.end_time != null" style="background-color: #1c3c9d; font-size: 11px;">{{missionDetails.formatted_end_time}}</span>
											<p class="result m-0 mb-4" v-if="missionDetails.end_time == null"> - </p>
										</div>
									</div>
									<div class="col-md-3 pb-4">
										<div>
											<h6 class="title mb-3"> Danger Level </h6>
											<span class="uk-badge" v-if="missionDetails.danger_level != null">{{missionDetails.danger_level}}</span>
											<p class="result m-0 mb-4" v-if="missionDetails.danger_level == null"> - </p>
										</div>
									</div>
									<div class="col-md-3 pb-4">
										<div>
											<h6 class="title mb-3"> Status </h6>
											<div class="status text-left" v-if="missionDetails.status != null">
													<span v-if="missionDetails.status == 'on_bidding'" class="pending">{{missionDetails.status}}</span>
													<span v-if="missionDetails.status == 'agency_rejected'" class="cancelled">{{missionDetails.status}}</span>
													<span v-if="missionDetails.status == 'client_rejected'" class="cancelled">{{missionDetails.status}}</span>
													<span v-if="missionDetails.status == 'agency_accepted'" class="approved">{{missionDetails.status}}</span>
													<span v-if="missionDetails.status == 'client_accepted'" class="approved">{{missionDetails.status}}</span>
													<span v-if="missionDetails.status == 'started'" class="pending">{{missionDetails.status}}</span>
													<span v-if="missionDetails.status == 'failed'" class="cancelled">{{missionDetails.status}}</span>
													<span v-if="missionDetails.status == 'finished'" class="approved">{{missionDetails.status}}</span>
											</div>
											<p class="result m-0 mb-4" v-if="missionDetails.status == null"> - </p>
										</div>
									</div>
									<div class="col-md-3">
										<div>
											<h6 class="title mb-3"> Person Type </h6>
											<p class="result m-0 mb-4" v-if="missionDetails.person_type != null"> {{missionDetails.person_type}} </p>
											<p class="result m-0 mb-4" v-if="missionDetails.person_type == null"> - </p>
										</div>
									</div>
									<div class="col-md-3">
										<div>
											<h6 class="title mb-3"> Mission Type </h6>
											<p class="result m-0 mb-4" v-if="missionDetails.mission_type != null"> {{missionDetails.mission_type}} </p>
											<p class="result m-0 mb-4" v-if="missionDetails.mission_type == null"> - </p>
										</div>
									</div>
									<div class="col-md-3">
										<div>
											<h6 class="title mb-3"> Created Date </h6>
											<p class="result m-0 mb-4" v-if="missionDetails.created_at != null"> {{missionDetails.created_at}} </p>
											<p class="result m-0 mb-4" v-if="missionDetails.created_at == null"> - </p>
										</div>
									</div>
									<div class="col-md-3">
										<div>
											<h6 class="title mb-3"> Last Updated </h6>
											<p class="result m-0 mb-4" v-if="missionDetails.last_updated_at != null"> {{missionDetails.last_updated_at}} </p>
											<p class="result m-0 mb-4" v-if="missionDetails.last_updated_at == null"> - </p>
										</div>
									</div>
									<div class="col-md-6">
										<div>
											<h6 class="title mb-3"> Description </h6>
											<p class="result m-0 mb-4" v-if="missionDetails.description != null"> {{missionDetails.description}} </p> 
											<p class="result m-0 mb-4" v-if="missionDetails.description == null"> - </p>
										</div>
									</div>
									<div class="col-md-6">
										<div>
											<h6 class="title mb-3"> Reason </h6>
											<p class="result m-0 mb-4" v-if="missionDetails.reason != null"> {{missionDetails.reason}} </p> 
											<p class="result m-0 mb-4" v-if="missionDetails.reason == null"> - </p>
										</div>
									</div>
									
								</div>
								<!----  Agency Modal  ---->
								<div id="modal-mission" class="uk-modal-container" uk-modal>
									<div class="uk-modal-dialog">
										<button class="uk-modal-close-default" type="button" uk-close></button>
										<div class="uk-modal-body">
											<div class="row view-ui">
												<div class="col-md-12">
													<ul uk-accordion>
														<li class="uk-open">
															<a class="uk-accordion-title" href="#">Location</a>
															<div class="uk-accordion-content">
																<div class="row">
																	<div class="col-md-6">
																		<div>
																			<h6 class="title mb-3"> Address</h6>
																			<p class="result m-0 mb-4" v-if="missionDetails.address != null"> {{missionDetails.address}} </p> 
																			<p class="result m-0 mb-4" v-if="missionDetails.address == null"> - </p>
																		</div>
																	</div>
																	<div class="col-md-6">
																		<div>
																			<h6 class="title mb-3"> Sub District</h6>
																			<p class="result m-0 mb-4" v-if="missionDetails.sub_district != null"> {{missionDetails.sub_district}} </p> 
																			<p class="result m-0 mb-4" v-if="missionDetails.sub_district == null"> - </p>
																		</div>
																	</div>
																	<div class="col-md-6">
																		<div>
																			<h6 class="title mb-3"> District</h6>
																			<p class="result m-0 mb-4" v-if="missionDetails.district != null"> {{missionDetails.district}} </p> 
																			<p class="result m-0 mb-4" v-if="missionDetails.district == null"> - </p>
																		</div>
																	</div>
																	<div class="col-md-6">
																		<div>
																			<h6 class="title mb-3"> Province </h6>
																			<p class="result m-0 mb-4" v-if="missionDetails.province != null"> {{missionDetails.province}} </p> 
																			<p class="result m-0 mb-4" v-if="missionDetails.province == null"> - </p>
																		</div>
																	</div>
																	<div class="col-md-6">
																		<div>
																			<h6 class="title mb-3"> Country </h6>
																			<p class="result m-0 mb-4" v-if="missionDetails.country != null"> {{missionDetails.country}} </p> 
																			<p class="result m-0 mb-4" v-if="missionDetails.country == null"> - </p>
																		</div>
																	</div>
																	<div class="col-md-6">
																		<div>
																			<h6 class="title mb-3"> Zip Code </h6>
																			<p class="result m-0 mb-4" v-if="missionDetails.zip_code != null"> {{missionDetails.zip_code}} </p> 
																			<p class="result m-0 mb-4" v-if="missionDetails.zip_code == null"> - </p>
																		</div>
																	</div>
																	<div class="col-md-12">
																		<div class="gmap-iframe ratio ratio-4x3 ratio-sm-21x9" lc-helper="gmap-embed">
																				<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3875.6647443463908!2d100.5592540929188!3d13.73873605017498!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x524dad67eb707a4b!2sThe%20Trendy%20Office%20Building!5e0!3m2!1sen!2sth!4v1646302645610!5m2!1sen!2sth" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
																			</div>
																	</div>
																</div>
															</div>
														</li>
														<li>
															<a class="uk-accordion-title" href="#">Commission</a>
															<div class="uk-accordion-content">
																<div class="p-3">
																	<div class="row">
																				<div class="col-md-12">
																					<div class="row pb-2" style="border-bottom: dotted 1px #cacaca;">
																						<div class="col-md-6 text-left">
																							<h6 class="m-0">Sub Total</h6>
																						</div>
																						<div class="col-md-6 text-right">
																							<p class="result m-0"> {{missionDetails.formatted_sub_total_price}} </p>
																						</div>
																					</div>
																				</div>
																				<div class="col-md-12">
																					<div class="row pt-2 pb-2" style="border-bottom: dotted 1px #cacaca;">
																						<div class="col-md-6 text-left">
																							<h6 class="m-0">Discount</h6>
																						</div>
																						<div class="col-md-6 text-right">
																							<p class="result m-0"> {{missionDetails.formatted_discount}} </p>
																						</div>
																					</div>
																				</div>
																				<div class="col-md-12">
																					<div class="row pt-2 pb-2" style="border-bottom: dotted 1px #cacaca;">
																						<div class="col-md-6 text-left">
																							<h6 class="m-0">Vat</h6>
																						</div>
																						<div class="col-md-6 text-right">
																							<p class="result m-0"> {{missionDetails.formatted_vat_price}}</p>
																						</div>
																					</div>
																				</div>
																				<div class="col-md-12">
																					<div class="row pt-2 pb-2" style="border-bottom: solid 1px #cacaca;">
																						<div class="col-md-6 text-left">
																							<h6 class="m-0">Total</h6>
																						</div>
																						<div class="col-md-6 text-right">
																							<p class="result m-0"> {{missionDetails.formatted_total_price}}</p>
																						</div>
																					</div>
																				</div>
																				<div class="col-md-12">
																					<div class="row pt-2 pb-2" style="border-bottom: dotted 1px #cacaca;">
																						<div class="col-md-6 text-left">
																							<h6 class="m-0">Commission</h6>
																						</div>
																						<div class="col-md-6 text-right">
																							<p class="result m-0"> {{missionDetails.formatted_commission}}</p>
																						</div>
																					</div>
																				</div>
																	</div>
																</div>
															</div>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
						</div>
					</div>
					<div class="col-md-12">
						<div class="grey-box p-3 pt-4 mb-3">
							<div class="row">
								<div class="col-md-6 text-left">
									<h5 class="mt-2 pb-2 mb-4 uk-text-bold"> <i class="fas fa-building mr-2"></i> Agency </h5>
								</div>
								<div class="col-md-6 text-right">
									<div>
										<a class="view-btn" uk-toggle="target: #modal-agency"> More Details</a>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
										<div class="profile-img position-relative text-center">
											<img :src="missionDetails.agency.logo">
										</div>
								</div>
								<div class="col-md-9">
									<div class="row">
										<div class="col-md-6">
											<div>
												<h6 class="title mb-3"> Company Name (English)</h6>
												<p class="result m-0 mb-4" v-if="missionDetails.agency.name_en != null"> {{missionDetails.agency.name_en}} </p>
												<p class="result m-0 mb-4" v-if="missionDetails.agency.name_en == null"> - </p>
											</div>
										</div>
										<div class="col-md-6">
											<div>
												<h6 class="title mb-3"> Company Name (Thai)</h6>
												<p class="result m-0 mb-4" v-if="missionDetails.agency.name_local != null"> {{missionDetails.agency.name_local}} </p>
												<p class="result m-0 mb-4" v-if="missionDetails.agency.name_local == null"> - </p>
											</div>
										</div>
										<div class="col-md-6">
											<div>
												<h6 class="title mb-3"> Address </h6>
												<p class="result m-0 mb-4"> {{missionDetails.agency.address}} </p>
											</div>
										</div>
										<div class="col-md-2">
											<div>
												<h6 class="title mb-3"> District </h6>
												<p class="result m-0 mb-4"> {{missionDetails.agency.district}} </p>
											</div>
										</div>
										<div class="col-md-6">
											<div>
												<h6 class="title mb-3"> Province/ State </h6>
												<p class="result m-0 mb-4"> {{missionDetails.agency.province}} </p>
											</div>
										</div>
										<div class="col-md-6">
											<div>
												<h6 class="title mb-3"> Zip Code </h6>
												<p class="result m-0 mb-4"> {{missionDetails.agency.zip_code}} </p>
											</div>
										</div>
									</div>
								</div>
								<!----  Agency Modal  ---->
								<div id="modal-agency" class="uk-modal-container" uk-modal>
									<div class="uk-modal-dialog">
										<button class="uk-modal-close-default" type="button" uk-close></button>
										<div class="uk-modal-body">
											<h5 class="mt-2 pb-2 mb-4 uk-text-bold"> <i class="fas fa-building mr-2"></i> Agency Information </h5>
											<div class="row view-ui">
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Company Name (English)</h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.name_en != null"> {{missionDetails.agency.name_en}} </p>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.name_en == null"> - </p>
													</div>
												</div>
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Company Name (Thai)</h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.name_local != null"> {{missionDetails.agency.name_local}} </p>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.name_local == null"> - </p>
													</div>
												</div>
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Description (English)</h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.description_en != null"> {{missionDetails.agency.description_en}} </p> 
														<p class="result m-0 mb-4" v-if="missionDetails.agency.description_en == null"> - </p>
													</div>
												</div>
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Description (Thai)</h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.description_local != null"> {{missionDetails.agency.description_local}} </p> 
														<p class="result m-0 mb-4" v-if="missionDetails.agency.description_local == null"> - </p>
													</div>
												</div>
												<div class="col-md-3">
													<div>
														<h6 class="title mb-3"> Tax ID </h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.tax_id != null"> {{missionDetails.agency.tax_id}} </p> 
														<p class="result m-0 mb-4" v-if="missionDetails.agency.tax_id == null"> - </p>
													</div>
												</div>
												<div class="col-md-3">
													<div>
														<h6 class="title mb-3"> Registration ID </h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.registration_id != null"> {{missionDetails.agency.registration_id}} </p> 
														<p class="result m-0 mb-4" v-if="missionDetails.agency.registration_id == null"> - </p>
													</div>
												</div>
												<div class="col-md-3">
													<div>
														<h6 class="title mb-3"> Founded Year </h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.founded_year != null"> {{missionDetails.agency.founded_year}} </p> 
														<p class="result m-0 mb-4" v-if="missionDetails.agency.founded_year == null"> - </p>
													</div>
												</div>
												<div class="col-md-3">
													<div>
														<h6 class="title mb-3"> Commission </h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.commission != null"> {{missionDetails.agency.commission}} </p> 
														<p class="result m-0 mb-4" v-if="missionDetails.agency.commission == null"> - </p>
													</div>
												</div>
											</div>
											<div class="col-md-12"><hr></div>
											<h5 class="mt-2 pb-2 mb-4 uk-text-bold"> <i class="fas fa-building mr-2"></i> Address </h5>
											<div class="row view-ui">
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Address </h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.address != null"> {{missionDetails.agency.address}} </p> 
														<p class="result m-0 mb-4" v-if="missionDetails.agency.address == null"> - </p>
													</div>
												</div>
												<div class="col-md-3">
													<div>
														<h6 class="title mb-3"> Sub District </h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.sub_district != null"> {{missionDetails.agency.sub_district}} </p>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.sub_district == null"> - </p>
													</div>
												</div>
												<div class="col-md-3">
													<div>
														<h6 class="title mb-3"> District</h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.district != null"> {{missionDetails.agency.district}} </p>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.district == null"> - </p>
													</div>
												</div>
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Province</h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.province != null"> {{missionDetails.agency.province}} </p> 
														<p class="result m-0 mb-4" v-if="missionDetails.agency.province == null"> - </p>
													</div>
												</div>
												<div class="col-md-3">
													<div>
														<h6 class="title mb-3"> Country</h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.country != null"> {{missionDetails.agency.country}} </p> 
														<p class="result m-0 mb-4" v-if="missionDetails.agency.country == null"> - </p>
													</div>
												</div>
												<div class="col-md-3">
													<div>
														<h6 class="title mb-3"> Zip Code</h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.zip_code != null"> {{missionDetails.agency.zip_code}} </p> 
														<p class="result m-0 mb-4" v-if="missionDetails.agency.zip_code == null"> - </p>
													</div>
												</div>
												<div class="col-md-12 p-0">
													<ul uk-accordion>
														<li>
															<a class="uk-accordion-title" href="#">Map</a>
															<div class="uk-accordion-content">
																<div class="gmap-iframe ratio ratio-4x3 ratio-sm-21x9" lc-helper="gmap-embed">
																	<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3875.6647443463908!2d100.5592540929188!3d13.73873605017498!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x524dad67eb707a4b!2sThe%20Trendy%20Office%20Building!5e0!3m2!1sen!2sth!4v1646302645610!5m2!1sen!2sth" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
																</div>
															</div>
														</li>
													</ul>
												</div>
											</div>
											<div class="col-md-12"><hr></div>
											<h5 class="mt-2 pb-2 mb-4 uk-text-bold"> <i class="fas fa-building mr-2"></i> Bank Information </h5>
											<div class="row view-ui">
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Bank Name </h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.bank_name != null"> {{missionDetails.agency.bank_name}} </p> 
														<p class="result m-0 mb-4" v-if="missionDetails.agency.bank_name == null"> - </p>
													</div>
												</div>
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Account Name </h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.bank_owner_name != null"> {{missionDetails.agency.bank_owner_name}} </p>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.bank_owner_name == null"> - </p>
													</div>
												</div>
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Account Number</h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.bank_account_no != null"> {{missionDetails.agency.bank_account_no}} </p>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.bank_account_no == null"> - </p>
													</div>
												</div>
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Branch Code</h6>
														<p class="result m-0 mb-4" v-if="missionDetails.agency.bank_branch_code != null"> {{missionDetails.agency.bank_branch_code}} </p> 
														<p class="result m-0 mb-4" v-if="missionDetails.agency.bank_branch_code == null"> - </p>
													</div>
												</div>
											</div>
											<div class="col-md-12"><hr></div>
											<h5 class="mt-2 pb-2 mb-4 uk-text-bold"> <i class="fas fa-building mr-2"></i> Contact Information </h5>
											<div class="row view-ui">
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Contact Person </h6>
														<p class="result m-0 mb-4"> {{missionDetails.agency.contact_person}} </p>
													</div>
												</div>
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Position </h6>
														<p class="result m-0 mb-4"> {{missionDetails.agency.contact_person_position}} </p>
													</div>
												</div>
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Phone </h6>
														<p class="result m-0 mb-4"> {{missionDetails.agency.contact_person_number}} </p>
													</div>
												</div>
												<div class="col-md-6">
													<div>
														<h6 class="title mb-3"> Email </h6>
														<p class="result m-0 mb-4"> {{missionDetails.agency.contact_person_email}} </p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>	
						</div>
					</div>
				</div>
				<div class="row view-ui">
					<div class="col-lg-12">
						<div class="grey-box p-3 pt-4">
							<div class="row mb-2">
								<div class="col-md-6 text-left">
									<h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-building mr-2"></i> Objects to protect </h5>
								</div>
								<div class="col-md-6 text-right">
									
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<form id="protect">
										<div class="row">
											<div class="col-md-3">
												<div>
													<h6 class="title mb-3"> First Name </h6>
													<p class="result m-0 mb-4"> {{objectToProtect.first_name}} </p>
												</div>
											</div>
											<div class="col-md-3">
												<div>
													<h6 class="title mb-3"> Last Name </h6>
													<p class="result m-0 mb-4"> {{objectToProtect.last_name}} </p>
												</div>
											</div>
											<div class="col-md-3">
												<div>
													<h6 class="title mb-3"> Speak Language </h6>
													<p class="result m-0 mb-4"> {{objectToProtect.spokenlang}} </p>
												</div>
											</div>
											<div class="col-md-3">
												<div>
													<h6 class="title mb-3"> Date of Birth </h6>
													<p class="result m-0 mb-4"> {{objectToProtect.dob}} </p>
												</div>
											</div>
											<div class="col-md-3">
												<div>
													<h6 class="title mb-3"> Occupation </h6>
													<p class="result m-0 mb-4"> {{objectToProtect.occupation}} </p>
												</div>
											</div>
											<div class="col-md-3">
												<div>
													<h6 class="title mb-3"> Email </h6>
													<p class="result m-0 mb-4"> {{objectToProtect.email}} </p>
												</div>
											</div>
											<div class="col-md-3">
												<div>
													<h6 class="title mb-3"> Phone </h6>
													<p class="result m-0 mb-4"> {{objectToProtect.phone}} </p>
												</div>
											</div>
										</div>
									</form>	
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row view-ui mt-3">
					<div class="col-md-4">
						<div class="grey-box p-3 pt-4">
							<div class="row mb-2">
								<div class="col-md-6 text-left">
									<h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-building mr-2"></i> Hired Staff </h5>
								</div>
								<div class="col-md-6 text-right">
									
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<div uk-slider>
										<div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">
											<ul class="uk-slider-items uk-child-width-1-1@s uk-grid">
												<li v-for="(list, index) in missionDetails.hired_staff" :key="index">
													<div class="uk-card uk-card-default">
														<div class="uk-card-media-top uk-text-center p-3 w-100">
															<img :src="list.avatar" class="uk-border-circle" width="200px" height="auto">
														</div>
														<div class="uk-card-body p-3">
															<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																<div class="col-md-5 text-left">
																	<h6 class="title mt-1"> Profile </h6>
																</div>
																<div class="col-md-7 text-right status">
																	<span class="uk-label" v-if="list.profile != null" style="background-color: #1c3c9d; color:#fff; font-size: 11px;">{{list.profile}}</span>
																	<p class="result m-0 mb-2" v-if="list.profile == null"> - </p>
																</div>
															</div>
															<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																<div class="col-md-5 text-left">
																	<h6 class="title mt-1"> Name </h6>
																</div>
																<div class="col-md-7 text-right">
																	<p class="result m-0 mb-2" v-if="list.full_name != null"> {{list.full_name}} </p> 
																	<p class="result m-0 mb-2" v-if="list.full_name == null"> - </p>
																</div>
															</div>
															<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																<div class="col-md-5 text-left">
																	<h6 class="title mt-1"> Nick name </h6>
																</div>
																<div class="col-md-7 text-right">
																	<p class="result m-0 mb-2" v-if="list.nick_name != null"> {{list.nick_name}} </p> 
																	<p class="result m-0 mb-2" v-if="list.nick_name == null"> - </p>
																</div>
															</div>
															<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																<div class="col-md-5 text-left">
																	<h6 class="title mt-1"> Phone Number</h6>
																</div>
																<div class="col-md-7 text-right">
																	<p class="result m-0 mb-2" v-if="list.phone_number != null"> {{list.phone_number}} </p> 
																	<p class="result m-0 mb-2" v-if="list.phone_number == null"> - </p>
																</div>
															</div>
															<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																<div class="col-md-5 text-left">
																	<h6 class="title mt-1"> Email </h6>
																</div>
																<div class="col-md-7 text-right">
																	<p class="result m-0 mb-2" v-if="list.email != null"> {{list.email}} </p> 
																	<p class="result m-0 mb-2" v-if="list.email == null"> - </p>
																</div>
															</div>
															<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																<div class="col-md-5 text-left">
																	<h6 class="title mt-1"> Nationality </h6>
																</div>
																<div class="col-md-7 text-right">
																	<p class="result m-0 mb-2" v-if="list.nationality != null"> {{list.nationality}} </p> 
																	<p class="result m-0 mb-2" v-if="list.nationality == null"> - </p>
																</div>
															</div>
															<div class="row m-0 p-0 mt-2">
																<div class="col-md-12 text-center">
																	<a class="view-btn" uk-toggle="target: #modal-staff-1"> More Details </a>
																</div>
															</div>
															
																<div id="modal-staff-1" uk-modal>
																	<div class="uk-modal-dialog">
																		<button class="uk-modal-close-default" type="button" uk-close></button>
																		<div class="uk-modal-body">
																			<div class="uk-card-media-top uk-text-center p-3 w-100">
																				<img :src="list.avatar" class="uk-border-circle" width="200px" height="auto">
																			</div>
																			<h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-building mr-2"></i> Staff Info </h5>
																			<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																				<div class="col-md-5 text-left">
																					<h6 class="title mt-1">  Profile </h6>
																				</div>
																				<div class="col-md-7 text-right status">
																					<span class="uk-label" v-if="list.profile != null" style="background-color: #1c3c9d; color:#fff; font-size: 11px;">{{list.profile}}</span>
																					<p class="result m-0 mb-2" v-if="list.profile == null"> - </p>
																				</div>
																			</div>
																			<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																				<div class="col-md-5 text-left">
																					<h6 class="title mt-1">  Name </h6>
																				</div>
																				<div class="col-md-7 text-right">
																					<p class="result m-0 mb-2" v-if="list.full_name != null"> {{list.full_name}} </p> 
																					<p class="result m-0 mb-2" v-if="list.full_name == null"> - </p>
																				</div>
																			</div>
																			<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																				<div class="col-md-5 text-left">
																					<h6 class="title mt-1">  Nick name </h6>
																				</div>
																				<div class="col-md-7 text-right">
																					<p class="result m-0 mb-2" v-if="list.nick_name != null"> {{list.nick_name}} </p> 
																					<p class="result m-0 mb-2" v-if="list.nick_name == null"> - </p>
																				</div>
																			</div>
																			<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																				<div class="col-md-5 text-left">
																					<h6 class="title mt-1"> Phone Number</h6>
																				</div>
																				<div class="col-md-7 text-right">
																					<p class="result m-0 mb-2" v-if="list.phone_number != null"> {{list.phone_number}} </p> 
																					<p class="result m-0 mb-2" v-if="list.phone_number == null"> - </p>
																				</div>
																			</div>
																			<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																				<div class="col-md-5 text-left">
																					<h6 class="title mt-1"> Email </h6>
																				</div>
																				<div class="col-md-7 text-right">
																					<p class="result m-0 mb-2" v-if="list.email != null"> {{list.email}} </p> 
																					<p class="result m-0 mb-2" v-if="list.email == null"> - </p>
																				</div>
																			</div>
																			<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																				<div class="col-md-5 text-left">
																					<h6 class="title mt-1"> Nationality </h6>
																				</div>
																				<div class="col-md-7 text-right">
																					<p class="result m-0 mb-2" v-if="list.nationality != null"> {{list.nationality}} </p> 
																					<p class="result m-0 mb-2" v-if="list.nationality == null"> - </p>
																				</div>
																			</div>
																			<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																					<div class="col-md-5 text-left">
																						<h6 class="title mt-1"> Relationship </h6>
																					</div>
																					<div class="col-md-7 text-right">
																						<p class="result m-0 mb-2" v-if="list.relationship != null"> {{list.relationship}} </p> 
																						<p class="result m-0 mb-2" v-if="list.relationship == null"> - </p>
																					</div>
																				</div>
																				<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																					<div class="col-md-5 text-left">
																						<h6 class="title mt-1"> Gender </h6>
																					</div>
																					<div class="col-md-7 text-right">
																						<p class="result m-0 mb-2" v-if="list.gender != null"> {{list.gender}} </p> 
																						<p class="result m-0 mb-2" v-if="list.gender == null"> - </p>
																					</div>
																				</div>
																				<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																					<div class="col-md-5 text-left">
																						<h6 class="title mt-1"> Type </h6>
																					</div>
																					<div class="col-md-7 text-right">
																						<p class="result m-0 mb-2" v-if="list.type != null"> {{list.type}} </p> 
																						<p class="result m-0 mb-2" v-if="list.type == null"> - </p>
																					</div>
																				</div>
																				<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																					<div class="col-md-5 text-left">
																						<h6 class="title mt-1"> Date of Birth </h6>
																					</div>
																					<div class="col-md-7 text-right">
																						<p class="result m-0 mb-2" v-if="list.dob != null"> {{list.dob}} </p> 
																						<p class="result m-0 mb-2" v-if="list.dob == null"> - </p>
																					</div>
																				</div>
																				<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																					<div class="col-md-5 text-left">
																						<h6 class="title mt-1"> Weight </h6>
																					</div>
																					<div class="col-md-7 text-right">
																						<p class="result m-0 mb-2" v-if="list.weight != null"> {{list.weight}} </p> 
																						<p class="result m-0 mb-2" v-if="list.weight == null"> - </p>
																					</div>
																				</div>
																				<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																					<div class="col-md-5 text-left">
																						<h6 class="title mt-1"> Height </h6>
																					</div>
																					<div class="col-md-7 text-right">
																						<p class="result m-0 mb-2" v-if="list.height != null"> {{list.height}} </p> 
																						<p class="result m-0 mb-2" v-if="list.height == null"> - </p>
																					</div>
																				</div>
																				<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																					<div class="col-md-5 text-left">
																						<h6 class="title mt-1"> Passport </h6>
																					</div>
																					<div class="col-md-7 text-right">
																						<p class="result m-0 mb-2" v-if="list.passport != null"> {{list.passport}} </p> 
																						<p class="result m-0 mb-2" v-if="list.passport == null"> - </p>
																					</div>
																				</div>
																				<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																					<div class="col-md-5 text-left">
																						<h6 class="title mt-1"> National ID Card </h6>
																					</div>
																					<div class="col-md-7 text-right">
																						<p class="result m-0 mb-2" v-if="list.national_id_card != null"> {{list.national_id_card}} </p> 
																						<p class="result m-0 mb-2" v-if="list.national_id_card == null"> - </p>
																					</div>
																				</div>
																				<h5 class="mt-4 mb-4 uk-text-bold"> <i class="fas fa-building mr-2"></i> Contact Person </h5>
																				<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																					<div class="col-md-5 text-left">
																						<h6 class="title mt-1"> Contact Name </h6>
																					</div>
																					<div class="col-md-7 text-right">
																						<p class="result m-0 mb-2" v-if="list.emergency_contact_name != null"> {{list.emergency_contact_name}} </p> 
																						<p class="result m-0 mb-2" v-if="list.emergency_contact_name == null"> - </p>
																					</div>
																				</div>
																				<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																					<div class="col-md-5 text-left">
																						<h6 class="title mt-1"> Contact Email </h6>
																					</div>
																					<div class="col-md-7 text-right">
																						<p class="result m-0 mb-2" v-if="list.emergency_contact_email != null"> {{list.emergency_contact_email}} </p> 
																						<p class="result m-0 mb-2" v-if="list.emergency_contact_email == null"> - </p>
																					</div>
																				</div>
																				<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																					<div class="col-md-5 text-left">
																						<h6 class="title mt-1"> Contact Phone </h6>
																					</div>
																					<div class="col-md-7 text-right">
																						<p class="result m-0 mb-2" v-if="list.emergency_contact_phone != null"> {{list.emergency_contact_phone}} </p> 
																						<p class="result m-0 mb-2" v-if="list.emergency_contact_phone == null"> - </p>
																					</div>
																				</div>
																				<div class="row m-0 p-0 mt-2" style="border-bottom: dotted 1px #cacaca;">
																					<div class="col-md-5 text-left">
																						<h6 class="title mt-1"> Relationship </h6>
																					</div>
																					<div class="col-md-7 text-right">
																						<p class="result m-0 mb-2" v-if="list.emergency_contact_relationship != null"> {{list.emergency_contact_relationship}} </p> 
																						<p class="result m-0 mb-2" v-if="list.emergency_contact_relationship == null"> - </p>
																					</div>
																				</div>
																		</div>
																	</div>
																
															</div>
														</div>
													</div>
												</li>
											</ul>
										</div>
										<ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-8">
						<div class="grey-box p-3 pt-4">
							<div class="row mb-2">
								<div class="col-md-6 text-left">
									<h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-building mr-2"></i> Transportation </h5>
								</div>
								<div class="col-md-6 text-right">
									
								</div>
							</div>
							<div class="row mb-2">
								<div class="col-md-4">
									<div>
										<h6 class="title mb-3"> Transport With Driver </h6>
										<p class="result m-0 mb-4"> Yes </p>
									</div>
								</div>
								<div class="col-md-4">
									<div>
										<h6 class="title mb-3"> Vehicle </h6>
										<p class="result m-0 mb-4"> Mercedez benz D200 </p>
									</div>
								</div>
								<div class="col-md-4">
									<div>
										<h6 class="title mb-3"> Police Escot </h6>
										<p class="result m-0 mb-4"> Yes </p>
									</div>
								</div>
								<div class="col-md-4">
									<div>
										<h6 class="title mb-3"> Date </h6>
										<p class="result m-0 mb-4"> 12-02-2021 09:23 PM </p>
									</div>
								</div>
								<div class="col-md-4">
									<div>
										<h6 class="title mb-3"> From </h6>
										<p class="result m-0 mb-4"> A-22 Indraheights, Nr. Pratham Road, Pattaya , Thailand </p>
									</div>
								</div>
								<div class="col-md-4">
									<div>
										<h6 class="title mb-3"> To </h6>
										<p class="result m-0 mb-4"> A-22 Indraheights, Nr. Pratham Road, Pattaya , Thailand </p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
		</section>
	</div>
</template>

<script>
export default {
  mounted() {
	console.log(this.$route.params.id)
    if (this.isValidId(this.$route.params.id)) {
     	this.getMissionDetails()
    }
	else {
      	this.$router.push('/404')
    }
  },
  data() {
    return {
      params: {
        sort: { column: 'id', type: 'desc' },
      },
	  ready:false,
	  missionDetails:{},
	  objectToProtect:{},
	  accomodations:{},
    
    }
  },
  methods: {
	  getMissionDetails() {
      const mission_id = atob(this.$route.params.id)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/missions/' + mission_id
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.missionDetails = response.data.data
		  this.objectToProtect = this.missionDetails.objects_to_protect[0]
		  this.accomodations = this.missionDetails.accomodations[0]
		  this.objectToProtect.spokenlang = this.objectToProtect.spoken_languages.join()
		  this.ready = true
		 console.log(this.missionDetails.objects_to_protect[0].spoken_languages)
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    isValidId(str) {
      try {
        return btoa(atob(str)) == str
      } catch (err) {
        return false
      }
    },
  },
}
</script>

<style scoped>

</style>