<template>
    <div class="row align-items-center mb-4 mt-4">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-md-12 text-left">
                    <h5 class="mt-1 mb-2 uk-text-bold">
                        Your Courses / Classes
                    </h5>
                </div>

                <div class="col-sm-12 mt-2">
                    <div v-for="(vImg, imgIndex) in images" :key="imgIndex" class="gallery-img mb-3 position-relative text-center" style="left:0 !important">
                        <div class="img-wrapper" >
                            <img :src="vImg.path" alt="Vehicle Image" />
                            <span class="edit-btn">
                                <!-- <input 
                                    type="file" 
                                    name="images[]" 
                                    class="file-upload" 
                                    id="file" 
                                    v-on:change="handleFileUpload($event.target.files, dIndex1, imgIndex)" >
                                <i class="fal fa-pen upload-button"></i> Change -->
                            </span>
                        </div>
                        <span class="delete-btn" @click="deleteImage(dIndex1, imgIndex)"></span>
                    </div>
                    <div class="gallery-img mb-3 position-relative text-center" style="left:0 !important">
                        <div class="img-wrapper" style="border: 1px solid #1363f8;">
                            <span class="edit-btn" style="height:65%; background:none">
                                <input 
                                    name="images[]"
                                    type="file" 
                                    class="file-upload" 
                                    id="file" 
                                    v-on:change="handleFileUpload($event.target.files, dIndex1)" >
                                <i class="fal fa-plus upload-button" style="color:#1363f8"></i>
                            </span>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            images: [{path:"/assets/img/logo.png"}]
        }
    }
}
</script>