<template>
    <div class="row align-items-center mb-4">
        <div class="col-lg-12">
            <div class="grey-box p-3 pt-4">
                <div class="row ">
                    <div class="col-md-6 text-left">
                        <h5 class="mt-1 mb-2 uk-text-bold">
                            <i class="fas fa-chart-bar mr-2"></i> List of Instructors
                        </h5>
                    </div>
                    <div class="col-md-6 text-right">
                        
                        <AddBtn :url="`/company/1/services/education/instructor/1`" />
                    </div>
                    <div class="col-lg-12">
                        <div class="table-responsive grey-box p-0">
                            <table class="table data-table table-round table-hover uk-animation-slide-bottom-small">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Gender, Age</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Service Area</th>
                                        <th scope="col">Number of class</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" class="blankhead" width="150px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(instructor, inIndex) in instructorLists" >
                                        <template>
                                            <tr :key="inIndex" ref="vehicleForm">
                                                <td>
                                                    <img :src="`/assets/img/logo.png`" class="uk-border-circle" width="55px" height="55px"> 
                                                </td>
                                                <td>
                                                    {{instructor.name}}
                                                </td>
                                                <td>
                                                    {{instructor.gender}}, {{instructor.dob|ages}}
                                                </td>
                                                <td>
                                                    {{instructor.location}}
                                                </td>
                                                <td>
                                                    <span class="uk-label mr-1"> {{ instructor.service_area }} </span>
                                                </td>
                                                <td>
                                                    {{ instructor.number_of_class }} Classes
                                                </td>
                                                <td class="text-center relative">
                                                    <label class="switch">
                                                        <input type="checkbox" v-model="instructorLists[inIndex].active" :checked="instructor.active" />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </td>
                                                
                                                <td class="text-right">
                                                    <a v-if="instructor.id != undefined" data-toggle="collapse"
                                                        v-bind:data-target="`#profile_${instructor.id}`"  
                                                        class="view-btn clickable mb-2" 
                                                        type="button">
                                                    </a>

                                                    <button v-else type="button" @click="submitInstructor(instructor.id, inIndex)" class="save-btn ml-2 mr-2" style="border:0px">
                                                        <i class="fas fa-save mr-2"></i> save
                                                    </button>
                                                    
                                                    <a @click="deleteGuard(instructor.id, inIndex)" href="#" class="del-btn mb-2">
                                                        <i class="fal fa-trash-alt"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr :key="'profile'+inIndex" :id="`profile_${instructor.id}`" class="collapse">
                                                <td colspan="8" class="p-3" style="border: 2px solid #dee2e6;border-radius: 10px;">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <InstructorQuickView />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="card-footer pb-0" v-show="vehicleList.length > 0">
                            <Pagination 
                            @getCurrentPagination="getCurrentPagination" 
                            :totalPages="paginData.totalPages"
                            :page="paginData.page" />
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InstructorQuickView from "./InstructorQuickView.vue"
export default {

    components: {
        InstructorQuickView
    },
    mounted() {

        this.instructorLists = [{ 
                id:1, 
                name: 'Mfd',
                gender: 'male',
                dob: '1991-11-10',
                location: 'Adress no.33',
                service_area: 'SG',
                number_of_class: 3,
                active: true,
                profile: '/assets/img/logo.png'
            }]

        // this.getGuardList()
    },
    data() {
        return {
            sortParams: {
                column: 'id',
                type: 'asc',
            },

            paginData: {
                page: 1,
                totalPages: 0,
                limit: this.$helpers.pageLimit()
            },

            filters: {
                keyword: '',
            },

            addNewGuard: false,

            instructorLists: [],
        }
    },
    methods: {
    
        filter(keyword, type = 'keyword') {
            this.filters[type] = keyword
            this.getGuardList()
        },
        sort(params) {
            this.sortParams = {
                column: params.column,
                type: params.type,
            }
            this.getGuardList()
        },
        // getCurrentPagination(currentPage) {
        //     this.paginData.page = currentPage
        //     this.getGuardList()
        // },
        getGuardList() {
            axios.get('guards', {
                    params: {
                        page: this.paginData.page,
                        limit: this.paginData.limit,
                        sort_column: this.sortParams.column,
                        sort_type: this.sortParams.type,
                        keyword: this.filters.keyword,
                    }
                })
                .then((response) => {
                    if(response.data.success) {

                        this.instructorLists = response.data.data
                        // this.paginData.totalPages = response.data.data.total_pages
                    }else{
                        this.$helpers.notification(response.data.success, response.data.message)
                    }
                })
                .catch((error) => {
                    this.$helpers.notification(response.data.success, error.message)
                    console.error('There was an error!', error)
                })
        },
        del_method: function (id, name) {
            UIkit.modal.confirm('Do you want to delete vihicle: ' + name + " ? ").then(
                function () {
                    axios.delete(`vehicles/${id}`)
                    .then(response => {
                        this.getGuardList()
                    })
                    .catch((error) => {
                        this.$helpers.notification(false, error.message)
                        console.error('There was an error!', error)
                    })
                },
            )
        },
        // vehicleActive(index) {
        //     this.instructorLists[index].active = !this.instructorLists[index].active
        //     console.log(this.instructorLists[index].active)

        // },
        addGuard() {

            this.addNewGuard = true

            // this.instructorLists.push({ 
            //     id:1, 
            //     name: 'Mfd',
            //     gender: 'male',
            //     location: 'Adress no.33',
            //     service_area: 'SG',
            //     working_days: 3,
            //     active: true,
            //     profile: '/assets/img/logo.png'
            // })
        },
        submitInstructor(vehicleId, index) {
       
            if(this.instructorLists[index].prices.length > 0) {
                this.updatePrice(vehicleId, index)
            }else{
                this.updateGuard(vehicleId, index)
            }
            
        },
        changeStatus(vehicleId, index) {
            this.updateGuard(vehicleId, index)
        },
        updateGuard(vehicleId, index) {
            const api = (vehicleId == undefined)? 'vehicles' :  'vehicles/' + vehicleId
            axios
                .post(api, this.getFormData(index), {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.$helpers.notification(true, 'Success!')
                        this.getGuardList()
                    }else{
                        // this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    this.$helpers.notification(false, error.message)

                    console.error('There was an error!', error.message)
                })
        },
        getFormData(index) {
            let formData = new FormData();

            let data = this.instructorLists[index]
            formData.append('name_en', data.name_en)
            formData.append('vehicle_code', data.vehicle_code)
            formData.append('model', data.model)
            formData.append('brand', data.brand)
            formData.append('plate_number', data.plate_number)
            formData.append('total_seat', parseInt(data.total_seat))
            // formData.append('has_siren',false)
            // formData.append('is_police_vehicle', false)
            formData.append('active', data.active? 1 : 0)
            
            let images = this.instructorLists[index].images
            if(images.length > 0){
                return this.getImg(images, formData)
            }
            
            return formData
        },
        getImg(images, formData) {

            let oldIds = []
            images.forEach(function(img){
                if(img.id){
                    oldIds.push(img.id)
                }
                if(img.file){
                    formData.append('images[]', img.file)
                }
            })
            
            if(oldIds.length > 0) {
                formData.append('old_images_id', oldIds.join(','))
            }
            return formData
        },
        deleteGuard(vehicleId, index) {

            if(vehicleId == undefined) {
                this.instructorLists.splice(index, 1);
                this.$helpers.notification(true, 'Deleted!')
            }else{

                const api = 'vehicles/' + vehicleId
                axios
                    .delete(api)
                    .then((response) => {
                        if(response.data.success) {
                            this.$helpers.notification(false, 'Deleted!')
                            this.getGuardList()
                        }
                    })
                    .catch((error) => {
                        this.$helpers.notification(false, error.message)
                        console.error('There was an error!', error.message)
                    })
            }

        },
        addPrice(vehicleIndex) {
            console.log(this.instructorLists[vehicleIndex.split('price')[1]].prices)
            this.instructorLists[vehicleIndex.split('price')[1]].prices.push({})
        },
        updatePrice(vehicleId, vehicleIndex) {
                console.log(this.instructorLists[vehicleIndex].prices)
                const api = `vehicles/${vehicleId}/prices`
                axios
                    .post(api, { prices: this.instructorLists[vehicleIndex].prices })
                    .then((response) => {
                        
                        this.updateGuard(vehicleId, vehicleIndex)
                    })
                    .catch((error) => {
                        console.error('There was an error!', error.message)
                    })
        },
        deleteVPrice(priceIndex, vehicleIndex) {
            let vIndex = vehicleIndex.split('price')[1]
            let vehicle = this.instructorLists[vIndex]
            let prices = this.instructorLists[vIndex].prices
            let priceId = prices[priceIndex].id
                prices.splice(priceIndex, 1); 

            if( priceId != undefined){
                this.updatePrice(instructor.id, vIndex)
            }
                this.$helpers.notification(false, 'Deleted!')

        },
        handleFileUpload(file, vIndex) {
            this.instructorLists[vIndex].images.push({id:null, path:URL.createObjectURL(file[0]), file: file[0]})
        },
        deleteImage(vIndex, imgIndex) {
            this.instructorLists[vIndex].images.splice(imgIndex, 1)
        }
    },
}
</script>
<style scoped>
</style>