<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative"></div>
      </div>
      <div class="table-responsive">
        <table class="table data-table table-round table-hover">
          <thead>
            <tr>
              <th>
                    ID
                    <Sort column="id" />
                  </th>
                  <th>
                    Reservation
                    <Sort column="name_en" />
                  </th>
                  <th>
                    Customer
                  </th>
                  <th>
                    Payment Type
                  </th>
                  <th>
                    Payment Method
                  </th>
                  <th>
                    Date
                  </th>
                  <th>
                    Amount
                  </th>
                  <th>
                    Payment Status
                  </th>
                  <th>
                    Total Price
                  </th>
                  <th>
                    Total Revenue
                  </th>
                  <th>
                    Total Paid
                  </th>
                  <th>
                    Outstanding Balance
                  </th>
                  <th>
                    Status
                  </th>
                  <th>
                    Total Days
                  </th>
                  <th class="blankhead"></th>
            </tr>
          </thead>
          <tbody>
                <tr v-for="(list, index) in paymentList" :key="index">
                  <td>{{ list.id }}</td>
                  <td>{{ list.reservation.id }}</td>
                  <td> {{ list.customer.fname }} {{ list.customer.lname }}</td>
                  <td>{{ list.payment_type }}</td>
                  <td>{{ list.payment_method }}</td>
                  <td>{{ list.date }}</td>
                  <td class="text-right">{{ list.amount }}</td>
                  <td><span class="badge badge-success">{{ list.payment_status }}</span></td>
                  <td class="text-right">${{ list.total_price }}</td>
                  <td class="text-right">${{ list.total_revenue }}</td>
                  <td class="text-right">${{ list.total_paid }}</td>
                  <td class="text-right">${{ list.outstanding_balance }}</td>
                  <td><span class="badge badge-warning">{{ list.status }}</span></td>
                  <td>{{ list.total_days }}</td>
                  <td class="text-right">
                    <a href="#" class="edit-btn"><i class="fal fa-envelope"></i></a>
                  </td>
                </tr>
              </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- Pagination -->
      <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
            <jw-pagination
              :items="pages"
              @changePage="onChangePage"
              :pageSize="limit"
              :styles="customStyles"
              :labels="customLabels"
            ></jw-pagination>
          </div>
    </div>
    
  </template>
  
  
  
  <script>

export default {
  props: {
          companyDetail: {
              id: Number,
              bank_account_no: String,
              bank_owner_name: String,
              bank_name: String,
              bank_branch_code: String,
              bank_book: String,
  
          }
      },
      data() {
          return {
            paymentList: []
          }
      },
      mounted() {
        this.getpaymentList()
      },
      
      methods: {
        getpaymentList() {
        const api = 'https://api.615autorental.com/api/admin/payment'
          const admintoken = localStorage.getItem('auth_token_default')
          const headers = {
            'Authorization': `Bearer ${admintoken}`,
          'Content-Type': 'application/json' // You can adjust content type as needed
        };
        axios
            .get(api,{ headers },{
              params: {
                page:1,
                limit: 30
              }
            })
            .then((response) => {
                this.paymentList = response.data.data
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
        } ,
        encode(id) {
          return btoa(id)
        },
      }
}
</script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>