<template>
    <div>
        <!----- Header Title ----->
        <section class="row">
        <Header />
        </section>

        <!----- * TABS * ----->
        <TabList />
        
        <section class="tpui-container">
            <div class="row align-items-center mb-4">
                <div class="col-lg-12">
                    <div class="grey-box p-3 pt-4">
                        <Data />
                        <SecurityGuardPriceList :companyDetail="companyDetail" />
                        <TransportationPrice />
                        <SecurityDog />
                    </div>
                </div>
            </div>
        </section>

        <Gallery />
        <SecurityGuards />
        <MissionList />
        <!-- <Gallery />
        <AgentList :companyDetail="companyDetail" />
         -->
    </div>
</template>

<script>
import TabList from "../Edit/TabList.vue"
import Data from "./Data.vue";
import SecurityGuardPriceList from "./SecurityGuardPriceList.vue";
import TransportationPrice from "./TransportationPrice.vue";
import SecurityDog from "./SecurityDog.vue";
import Gallery from "./Gallery.vue";
import SecurityGuards from "./SecurityGuards.vue";
import MissionList from "./MissionList.vue";

export default {
    components: {
        TabList,
        Data,
        SecurityGuardPriceList,
        TransportationPrice,
        SecurityDog,
        Gallery,
        SecurityGuards,
        MissionList
    },
    data() {
        return {
            companyDetail: {}
        }
    },
    mounted() {
    },
    methods: {

    },

}
</script>
