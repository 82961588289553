<template>
    <div>
      <div class="row mb-4" style="margin-top: 70px;">
        <div class="col-md-12">
            <div class="grey-box p-3 pt-4">
                <div class="car-map-box">

                </div>
            </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table data-table table-round table-hover">
          <thead>
            <tr>
              <th>
                    Reservation
                    <Sort column="id" />
                  </th>
                  <th>
                    Vehicle
                    <Sort column="name_en" />
                  </th>
                  <th>
                    Vin
                    <Sort column="name_en" />
                  </th>
                  <th>
                    Alert Type
                  </th>
                  <th>
                    Reported At
                  </th>
            </tr>
          </thead>
          <tbody>
                <tr>
                  <td>0001</td>
                  <td>Mercedes Benz 300 D</td>
                  <td>7LTYBZOIS7MRY32EA</td>
                  <td>Coolant Temperature is Above Threshold</td>
                  <td>06-02-2024 13:33</td>
                </tr>
                <tr>
                  <td>0001</td>
                  <td>Mercedes Benz 300 D</td>
                  <td>7LTYBZOIS7MRY32EA</td>
                  <td>Coolant Temperature is Above Threshold</td>
                  <td>06-02-2024 13:33</td>
                </tr>
                <tr>
                  <td>0001</td>
                  <td>Mercedes Benz 300 D</td>
                  <td>7LTYBZOIS7MRY32EA</td>
                  <td>Coolant Temperature is Above Threshold</td>
                  <td>06-02-2024 13:33</td>
                </tr>
                <tr>
                  <td>0001</td>
                  <td>Mercedes Benz 300 D</td>
                  <td>7LTYBZOIS7MRY32EA</td>
                  <td>Coolant Temperature is Above Threshold</td>
                  <td>06-02-2024 13:33</td>
                </tr>
                <tr>
                  <td>0001</td>
                  <td>Mercedes Benz 300 D</td>
                  <td>7LTYBZOIS7MRY32EA</td>
                  <td>Coolant Temperature is Above Threshold</td>
                  <td>06-02-2024 13:33</td>
                </tr>
              </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>
      <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
            <jw-pagination
              :items="pages"
              @changePage="onChangePage"
              :pageSize="limit"
              :styles="customStyles"
              :labels="customLabels"
            ></jw-pagination>
          </div>
    </div>
    
  </template>
  
  
  
  <script>

  export default {
    
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>