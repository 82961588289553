<template>
    <section class="tpui-container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-12 text-left">
                        <h5 class="mt-1 mb-3 uk-text-bold">
                            Profile
                           
                            <router-link :to="`/company/1/services/education/instructor/1`" class="fal edit-btn p-2">
                                <i class="fas fa-edit"></i>
                            </router-link>
                        </h5>
                    </div>
                </div>
                <div class="view-ui mt-2">
                    <div class="row">
                        <div class="col-md-3 relative text-center">
                            <img :src="`/assets/img/profile.png`" alt="Profile" class="uk-border-circle" style="width:130px; height:130px">
                        </div>
                        <div class="col-md-9">
                            <div class="p-3">
                                <div class="row p-0 pb-0">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" v-model="profile.first_name" readonly>
                                            <label>First name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" v-model="profile.last_name" readonly>
                                            <label>Last name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <select class="form-control" v-model="profile.gender" readonly disabled>
                                                <option value="male"> Male </option>
                                                <option value="female"> Female </option>
                                            </select>
                                            <label>Gender</label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group vue-tel-input-wrapper">
                                            <template>
                                                <vue-tel-input v-model="profile.phone_number" disabled></vue-tel-input>
                                                <label>Contact Number</label>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" v-model="profile.dob" readonly>
                                                    <label> Date of Birth</label>
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" value="9" readonly>
                                                    <label> Age</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" value="Thai" readonly>
                                            <label>Spoken Language</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label style="top: 0;left: 0;position: relative;">Class</label>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group mt-2 mb-0">
                                <input type="text" value="Muay Thai" class="form-control" readonly>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group mt-2 mb-0">
                                <input type="text" value="Boxing" class="form-control" readonly>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    props: {
        addNewGuard: Boolean

    },
	mounted() {
        this.newGuard = this.addNewGuard
		// this.getProfileInfo()
	},

	data() {
		return {
            profile: {
                first_name: 'nnna',
                last_name: 'sdfdsf',
                gender: 'female',
                dob: '1991-10-10',
                phone_number: '34232233',
                spoken_languages: 'th, en'
                

            },
            newGuard: false
			
		}
	},

	methods: {
        getProfileInfo() {

        }
	
	}
}

</script>
