<template>
    <div class="col-md-12 pt-3">

        <div class="row">
            <div class="col-md-6">
                <div class="dashboard home animate relative p-2 mb-2">
                    <div class="row">
                        <div class="col-md-5">
                            <h6 class="mt-3 mb-4 uk-text-bold"> New Customer </h6>
                        </div>
                        <div class="col-md-7 text-right">
                            <div class="descript pt-3">
                                <ul>
                                    <li class="ml-3 uk-inline"> 
                                        <i class="fad fa-circle mr-1" style="color: #1363f8;"></i> New Customers
                                    </li>
                                    <li class="ml-3 uk-inline"> 
                                        <i class="fad fa-circle mr-1" style="color: #f7ba1e;"></i> New Sign Up
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-md-12">
                            <Bar
                                    :chart-options="chartSetting.options"
                                    :chart-data="chartSetting.chartData"
                                    :chart-id="chartSetting.chartId"
                                    :dataset-id-key="chartSetting.datasetId"
                                    :width="chartSetting.chartWidth"
                                    :height="chartSetting.chartHeight"
                                />

                        </div>
                    </div>
                </div>
        
            </div>
            <div class="col-md-6">
                <div class="dashboard home animate relative p-2 mb-2 pb-4">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 class="mt-3 mb-4 uk-text-bold"> Customer </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                        </div>
                        <div class="col-md-8 text-center">
                            <div class="uk-position-center mt-0 uk-text-center">
                                <span class="uk-text-large uk-display-block"> 
                                    Total 
                                </span>
                                <span class="uk-text-bold uk-text-primary uk-display-block" style="font-size: 40px; line-height: 40px;">
                                    {{totalCustomer}} 
                                </span>
                            </div>
                            <Doughnut
                                :chart-options="donutChartSetting.options"
                                :chart-data="donutChartSetting.chartData"
                                :chart-id="donutChartSetting.chartId"
                                :dataset-id-key="donutChartSetting.datasetId"
                            />
                            <!-- <canvas id="customer-chart" style="transform: scale(0.6, 0.6); margin-top: -120px; margin-bottom: -120px;"></canvas> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="dashboard home animate relative p-2 mb-2 pb-4 mt-4">
            <div class="row"> -->
                <!-- <div class="col-md-12">
                    <div class="descript pt-3 pl-5">
                        <ul class="row relative" style="width: 400px; margin: auto !important;">
                            <li v-for="(customer, customerIndex) in customerByServices" 
                                :key="customerIndex"
                                class="ml-3 uk-inline col-md-6 col-sm-12" > 
                                <i class="fad fa-circle mr-1" v-bind:style="{ 'color': customer.color }"></i> 
                                {{customer.service}} 
                            </li>
                        </ul>
                    </div>
                </div> -->
            <!-- </div>
        </div> -->
        
    </div>
</template>
<script>


export default {
    components:{
        
    },
    mounted() {
        this.getNewUsers()
        this.getCustomerByService()
    },
    data() {
        return {
            customerByServices: [],
            totalCustomer: 0,
            chartSetting: {
                chartWidth: 400,
                chartHeight: 400,
                chartId: 'bar-chart',
                datasetId: 'label',
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    }
                },
                chartData: {},
            },
            donutChartSetting: {
                chartId: 'doughnut-chart',
                datasetId: 'label',
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    borderWidth: 0,
                    borderAlign: 'inner',
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom',
                            labels: {
                                usePointStyle: true,
                                pointStyle: 'circle'

                            }
                        },
                    }
                },
                chartData: {},
            },
        }
    },
    methods: {
        getNewUsers() {
            const api = 'admin/dashboard/registration_count'
            axios
                .get(api)
                .then((response) => {
                    if(response.data.success) {

                        let newSignuplabels = []
                        let newSignupCount = []
                        let data = response.data.data
                        for (var i = 0; i < data.length; i++) {
                            newSignuplabels.push(data[i].month_in_word)
                            newSignupCount.push(data[i].count)
                        }

                        this.chartSetting.chartData = {
                            labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
                            // labels: newSignuplabels,
                            datasets: [ 
                                { 
                                    label: 'New Customers',
                                    backgroundColor: '#0B63F8',
                                    data: newSignupCount
                                },
                                { 
                                    label: 'New Sign Up',
                                    backgroundColor: '#F7BA1E',
                                    data: newSignupCount
                                }
                            ]
                        }
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error)
                })
        },
        getCustomerByService() {
            const api = 'admin/dashboard/customer_per_service'
            axios
                .get(api, { 
                    params: { 
                    // start_date: 
                    } 
                })
                .then((response) => {
                    let customerLabel = []
                    let customerData = []
                    let labelColor = []
                    let data = response.data.data
                    let index = 0;
                    let doughnutColor = ['#d4e6f2', '#7fb3d5', '#2a80ba', '#1e628c', '#0c4070', '#122230']
                    for (const [key, value] of Object.entries(data)) {
                        labelColor.push(doughnutColor[index])
                        customerLabel.push(key)
                        customerData.push(value)
                        this.totalCustomer += value
                        this.customerByServices.push({ service: key, color: doughnutColor[index] })
                        index++;
                    }
                    /* ------- ChartJS > Customer ------ */
                    this.donutChartSetting.chartData = {
                        labels: customerLabel,
                        datasets: [ 
                            { 
                                label: 'Data 1',
                                backgroundColor: ['#D4E6F2', '#7EB3D5', '#2A80B9', '#2A80B9', '#0C4070', '#122230'],
                                data: customerData
                            }
                        ]
                    }
                })
                .catch((error) => {
                    this.$helpers.notification(false, error.message)
                    console.error('There was an error!', error)
                })
        },
    }
}
</script>