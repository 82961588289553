<template>
    <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4 pb-4">
            <!-- <PriceData></PriceData> -->
            <BodyguardPriceList :companyDetail="companyDetail"></BodyguardPriceList>
            <VehiclePrice :companyDetail="companyDetail"></VehiclePrice>
            <EscortPrice></EscortPrice>
          </div>
        </div>
    </div>
</template>


<script>
import PriceData from "./PriceData.vue";
import BodyguardPriceList from "./BodyguardPriceList.vue";
import VehiclePrice from "./VehiclePrice.vue";
import EscortPrice from "./EscortPrice.vue";

export default {
  components: {
    PriceData,
    BodyguardPriceList,
    VehiclePrice,
    EscortPrice
  },
  props: {
    companyDetail: Object
  }
}
</script>