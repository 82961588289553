<template>
  <div class="edit-data container-fluid mb-5">
				<div class="row mb-5">
					<div class="col-md-12 mb-3">
						<a href="star-bodyguard-companies.html" class="back-btn"><i class="fal fa-chevron-left mr-2" aria-hidden="true"></i>Back</a>
					</div>
					<div class="col-lg-12">
						<div class="grey-box">
							<form>
								<div class="row">
									<div class="col-6 mb-3">
									</div>
									<div class="col-6 mb-3 text-right">
										<button type="submit" class="submit-btn border-0">Save</button>
									</div>
								</div>
								<div class="row">
									<div class="col-xl-3 col-lg-4 mb-5 mb-lg-0">
										<div class="profile-img position-relative">
											<img src="https://dev-tp.sgp1.digitaloceanspaces.com/upload-files/5l28zWTqxbfmQrJqjYv7nMdEzsWIlrC4ycGY8vFn.png">
											<a href="#" class="edit-btn">
													<input type="file" name="profile" class="file-upload">
											
												<i class="fal fa-plus" aria-hidden="true"></i></a>
											
										</div>
									</div>
									<div class="col-xl-9 col-lg-8">
										  <div class="row">
												<div class="col-lg-6 col-md-6 mb-3">
													<label>First Name</label>
													<input type="text" name="fname">
												</div>
												<div class="col-lg-6 col-md-6 mb-3">
													<label>Last  Name</label>
													<input type="text" name="lname">
												</div>
												<div class="col-lg-6 col-md-6 mb-3">
													<label>Nationality</label>
													<input type="text" name="nationality">
												</div>
												<div class="col-lg-6 col-md-6 mb-3">
													<label>Passport valid to</label>
													<input type="text" name="passport">
												</div>
												<div class="col-lg-6 col-md-6 mb-3">
													<label>Passport valid to</label>
													<input type="text" name="passport">
												</div>
												<div class="col-lg-6 col-md-6 mb-3">
													<label>Live in Thailand since</label>
													<input type="text" name="Live">
												</div>
												<div class="col-lg-12 col-md-6 mb-3">
													<label>Gender</label>
													<div class="d-flex align-items-center border-bottom">
														<div class="form-check-inline mr-4">
															<input type="radio" id="male" name="Gender">
															<label for="male" class="pl-0">Male</label>
														</div>
														<div class="form-check-inline">
															<input type="radio" id="female" name="Gender">
															<label for="female" class="pl-0">Female</label>
														</div>
													</div>
												</div>
										  </div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<form>
					<div class="row mb-5">
						<div class="col-6 mb-3">
							<h5 class="mb-0">Address</h5>
						</div>
						<div class="col-6 mb-3 text-right">
							<button type="submit" class="submit-btn border-0">Save</button>
						</div>
						<div class="col-lg-12">
							<div class="grey-box">
							  <div class="row">
									<div class="col-md-6 mb-3">
										<label>Address</label>
										<input type="text" name="address">
									</div>
									<div class="col-md-6 mb-3">
										<label>City</label>
										<input type="text" name="city">
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 mb-3">
										<label>Province</label>
										<input type="text" name="province">
									</div>
									<div class="col-md-6 mb-3">
										<label>Zip Code</label>
										<input type="text" name="zipcode">
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 mb-3 mb-md-0">
										<label>Email</label>
										<input type="text" name="email">
									</div>
									<div class="col-md-6 mb-3 mb-md-0">
										<label>Phone number</label>
										<input type="text" name="phoneno">
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
				<form>	
					<div class="row mb-5">
						<div class="col-6 mb-3">
							<h5 class="mb-0">Physical</h5>
						</div>
						<div class="col-6 mb-3 text-right">
							<button type="submit" class="submit-btn border-0">Save</button>
						</div>
						<div class="col-lg-12">
							<div class="grey-box">
							  <div class="row">
									<div class="col-lg-3 col-md-6 mb-3">
										<label>Height</label>
										<select name="height">
											<option value="199CM" selected>199CM</option>
											<option value="200CM">200CM</option>
											<option value="300CM">300CM</option>
											<option value="320CM">320CM</option>
											<option value="400CM">400CM</option>
										</select>
									</div>
									<div class="col-lg-3 col-md-6 mb-3">
										<label>Weight</label>
										<select name="weight">
											<option value="50KG" selected>50KG</option>
											<option value="65KG">65KG</option>
											<option value="70KG">70KG</option>
											<option value="75KG">75KG</option>
											<option value="more than 80kg">More Than 80KG</option>
										</select>
									</div>
									<div class="col-lg-3 col-md-6 mb-3">
										<label>Body type</label>
										<select name="bodytype">
											<option value="Slim" selected>Slim</option>
											<option value="Average">Average</option>
											<option value="Fit Body">Fit Body</option>
											<option value="Athetic">Athetic</option>
											<option value="Overweight">Overweight</option>
										</select>
									</div>
									<div class="col-lg-3 col-md-6 mb-3">
										<label>Tattoos</label>
										<div class="d-flex align-items-center border-bottom">
											<div class="form-check-inline mr-4">
												<input type="radio" id="Active" name="Status">
												<label for="Active" class="pl-0">Yes</label>
											</div>
											<div class="form-check-inline">
												<input type="radio" id="Inactive" name="Status">
												<label for="Inactive" class="pl-0">No</label>
											</div>
										</div>
									</div>
									<div class="col-lg-3 col-md-6 mb-3">
										<label>Blood Sickness</label>
										<div class="d-flex align-items-center border-bottom">
											<div class="form-check-inline mr-4">
												<input type="radio" id="byes" name="Blood" value="yes">
												<label for="byes" class="pl-0">Yes</label>
											</div>
											<div class="form-check-inline">
												<input type="radio" id="bno" name="Blood" value="No">
												<label for="bno" class="pl-0">No</label>
											</div>
										</div>
									</div>
									<div class="col-12 describebox" id="byes">
										<label>Describe your Sickness issue</label>
										<textarea rows="4"></textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
				<form>
					<div class="row mb-5">
						<div class="col-6 mb-3">
							<h5 class="mb-0">Skills</h5>
						</div>
						<div class="col-6 mb-3 text-right">
							<button type="submit" class="submit-btn border-0">Save</button>
						</div>
						<div class="col-lg-12">
							<div class="grey-box">
								<div class="row">
										<div class="col-lg-3 col-md-6 mb-3">
											<label>Combat Skills</label>
											<div class="d-flex align-items-center border-bottom">
												<div class="form-check-inline mr-4">
													<input type="radio" id="cyes" name="Combat Skills">
													<label for="cyes" class="pl-0">Yes</label>
												</div>
												<div class="form-check-inline">
													<input type="radio" id="cno" name="Combat Skills">
													<label for="cno" class="pl-0">No</label>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6 mb-3">
											<label>Armed</label>
											<div class="d-flex align-items-center border-bottom">
												<div class="form-check-inline mr-4">
													<input type="radio" id="ayes" name="Armed">
													<label for="ayes" class="pl-0">Yes</label>
												</div>
												<div class="form-check-inline">
													<input type="radio" id="ano" name="Armed">
													<label for="ano" class="pl-0">No</label>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6 mb-3">
											<label>Profile</label>
											<div class="d-flex align-items-center border-bottom">
												<div class="form-check-inline mr-4">
													<input type="checkbox" id="plow" name="profile">
													<label for="plow" class="pl-0">Low</label>
												</div>
												<div class="form-check-inline">
													<input type="checkbox" id="phigh" name="profile">
													<label for="phigh" class="pl-0">High</label>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6 mb-3">
											<label>Driver license Car</label>
											<div class="d-flex align-items-center border-bottom">
												<div class="form-check-inline mr-4">
													<input type="radio" id="clow" name="Driver Car">
													<label for="clow" class="pl-0">Yes</label>
												</div>
												<div class="form-check-inline">
													<input type="radio" id="chigh" name="Driver Car">
													<label for="chigh" class="pl-0">No</label>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6 mb-3">
											<label>Driver license Motorbike</label>
											<div class="d-flex align-items-center border-bottom">
												<div class="form-check-inline mr-4">
													<input type="radio" id="mlow" name="Driver Motorbike">
													<label for="mlow" class="pl-0">Yes</label>
												</div>
												<div class="form-check-inline">
													<input type="radio" id="mhigh" name="Driver Motorbike">
													<label for="mhigh" class="pl-0">No</label>
												</div>
											</div>
										</div>
										<div class="col-lg-6 col-md-6 mb-3">
											<label>Speaks</label>
											<input type="text" name="speaks" data-role="tagsinput" value="English,Hindi">
										</div>
										
								</div>
							</div>
						</div>
					</div>
				</form>
				<form>
					<div class="row mb-5">
						<div class="col-6 mb-3">	
							<h5 class="mb-0">Experience</h5>
						</div>
						<div class="col-6 mb-3 text-right">
							<button type="submit" class="submit-btn border-0">Save</button>
						</div>
						<div class="col-lg-12">
							<div class="grey-box">
								<div class="row">
										<div class="col-lg-3 col-md-6 mb-3">
											<label>Years of experience</label>
											<input type="text" name="address">
										</div>
										<div class="col-lg-3 col-md-6 mb-3">
											<label>Work(ed) for Army</label>
											<div class="d-flex align-items-center border-bottom">
												<div class="form-check-inline mr-4">
													<input type="radio" id="wyes" value="yes" name="Worked Army">
													<label for="wyes" class="pl-0">Yes</label>
												</div>
												<div class="form-check-inline">
													<input type="radio" id="wno" value="No" name="Worked Army">
													<label for="wno" class="pl-0">No</label>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6 mb-3">
											<label>Work(ed) for Police</label>
											<div class="d-flex align-items-center border-bottom">
												<div class="form-check-inline mr-4">
													<input type="radio" id="pyes" value="Yes" name="Armed">
													<label for="pyes" class="pl-0">Yes</label>
												</div>
												<div class="form-check-inline">
													<input type="radio" id="pno" value="No" name="Armed">
													<label for="pno" class="pl-0">No</label>
												</div>
											</div>
										</div>
										<div class="col-lg-3 col-md-6 mb-3">
											<label>Able to work </label>
											<div class="d-flex align-items-center border-bottom">
												<div class="form-check-inline mr-4">
													<input type="checkbox" id="alone" value="alone" name="Able Work">
													<label for="alone" class="pl-0">Alone</label>
												</div>
												<div class="form-check-inline">
													<input type="checkbox" id="group" value="in group" name="Able Work">
													<label for="group" class="pl-0">In Group</label>
												</div>
											</div>
										</div>
								</div>
							</div>
						</div>
					</div>
				</form>
				<form>
					<div class="row mb-5">
						<div class="col-lg-6 mb-5 mb-lg-0">
							<div class="row">
								<div class="col-md-6 col-8 mb-3">	
									<h5 class="mb-0">Family</h5>
								</div>
								<div class="col-md-6 col-4 mb-3 text-right">
									<button type="submit" class="submit-btn border-0">Save</button>
								</div>
								<div class="col-lg-12">
									<div class="grey-box">
									  <div class="row">
											<div class="col-lg-6 col-md-6 mb-3">
												<label>Married</label>
												<div class="d-flex align-items-center border-bottom">
													<div class="form-check-inline mr-4">
														<input type="radio" id="myes" value="yes" name="Married">
														<label for="myes" class="pl-0">Yes</label>
													</div>
													<div class="form-check-inline">
														<input type="radio" id="mno" value="No" name="Married">
														<label for="mno" class="pl-0">No</label>
													</div>
												</div>
											</div>
											<div class="col-lg-6 col-md-6 mb-3">
												<label>Children</label>
												<div class="d-flex align-items-center border-bottom">
													<div class="form-check-inline mr-4">
														<input type="radio" id="childyes" value="Yes" name="Children">
														<label for="childyes" class="pl-0">Yes</label>
													</div>
													<div class="form-check-inline">
														<input type="radio" id="childno" value="No" name="Children">
														<label for="childno" class="pl-0">No</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row">
								<div class="col-md-6 col-8 mb-3">	
									<h5 class="mb-0">In case of emergency:</h5>
								</div>
								<div class="col-md-6 col-4 mb-3 text-right">
									<button type="submit" class="submit-btn border-0">Save</button>
								</div>
								<div class="col-lg-12">
									<div class="grey-box">
									  <div class="row">
											<div class="col-lg-6 col-md-6 mb-3">
												<label>Name</label>
												<input type="text" name="name">
											</div>
											<div class="col-lg-6 col-md-6 mb-3">
												<label>Relation</label>
												<input type="text" name="name">
											</div>
											<div class="col-lg-6 col-md-6 mb-3">
												<label>Phone number</label>
												<input type="text" name="name">
											</div>
											<div class="col-lg-6 col-md-6 mb-3">
												<label>Email</label>
												<input type="text" name="email">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
  
</template>



<script>

export default {
  mounted() {
    this.getCompanyList(this.page, this.limit, 'id', 'asc',this.companyType)
  },
  data() {
    return {
      params: {
        sort: { column: 'id', type: 'desc' },
      },
     
      companyType:'',
      sort: 'id',
      orderby: 'asc',
      companyList: [],
    }
  },
  methods: {
    filterType(type){
        this.companyType = type
        this.getCompanyList(this.page, this.limit, this.sort, this.orderby,this.companyType)
    },
	getCurrentPagination(currentPage) {
        this.paginData.page = currentPage
        this.getCompanyList()
    },
    getCompanyList(page, limit, col, sorttype,type) {
      this.page = page;
      let pagevalue = '' + page;
      if (pagevalue == 'undefined') {
        return
      }
      const api =
        'admin/agencies?page=' +
        page +
        '&limit=' +
        limit +
        '&keyword=&active=1&sort_column=' +
        col +
        '&sort_type=' +
        sorttype + '&agency_type=' + type
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
		.then((response) => {
          if(response.data.success) {
            this.companyList = response.data.data.records
            this.paginData.totalPages = response.data.data.total_pages
          }else{
            this.$helpers.notification(response.data.success, response.data.message)
          }
        })
        .catch((error) => {
          this.$helpers.notification(false, error.message)
          console.error('There was an error!', error)
        })
    },
    sortcol(sort) {
      this.params.sort = sort
      this.sort = sort.column
      this.orderby = sort.type
      this.getCompanyList(this.page, this.limit, sort.column, sort.type,this.companyType)
      console.log(sort.column, sort.type)
    },
    encode(id) {
      return btoa(id)
    },
  },
}
</script>
