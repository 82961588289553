<template>
    <div class="switches-container w-200 uk-position-right" style="margin-right: 10px;">
        <input type="radio" id="active" v-model="profileActive" v-bind:value="activeVal" @click="changeActiveUser"  />
        <input type="radio" id="inactive" v-model="profileActive" v-bind:value="inactiveVal" @click="changeActiveUser" />
        <label for="active" id="lbl_active"> Active </label>
        <label for="inactive" id="lbl_inactive"> Inactive </label>
        <div class="switch-wrapper">
            <div class="switch">
                <div id="lbl_active"> Active </div>
                <div id="lbl_inactive"> Inactive </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        active: Boolean
    },
    data() {
        return {
            activeUser: true,
            profileActive:'',
			activeVal:'active',
			inactiveVal:'inactive',
        }
    },
    mounted() {
        this.profileActive = this.active? this.activeVal : this.inactiveVal
    },
    methods: {

        changeActiveUser() {
            this.activeUser = !this.activeUser
            this.profileActive = this.active? this.activeVal : this.inactiveVal
            this.$emit('activeChange', this.activeUser);
        },
    }

    
}
</script>