<template>
    <div class="col-md-5 mt-0">
        <div class="dashboard home animate relative p-2 mb-2 pb-4 mt-2">
            <div class="p-1">
                <div class="row">
                    <div class="col-md-6 col-sm-6 text-left">
                        <ul class="uk-inline" style="display: inline-flex !important;" uk-tab>
                            <li class="uk-active">
                                <a href="#" class="no-bullet uk-text-uppercase" @click="locationType('mission')"> 
                                    Mission Location
                                </a>
                            </li>
                            <li>
                                <a href="#" class="no-bullet uk-text-uppercase" @click="locationType('agent')"> 
                                    Agent Location 
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6 col-sm-6 text-right">
                        <div class="mr-3">
                            <ul class="uk-inline" style="display: inline-flex !important;" uk-tab>
                                <li class="uk-active">
                                    <a href="#" class="no-bullet uk-text-uppercase" @click="filterType('All')"> All </a>
                                </li>
                                <li>
                                    <a href="#" class="no-bullet uk-text-uppercase" @click="filterType('BG')"> BG </a>
                                </li>
                                <li>
                                    <a href="#" class="no-bullet uk-text-uppercase" @click="filterType('SG')"> SG </a>
                                </li>
                                <li>
                                    <a href="#" class="no-bullet uk-text-uppercase" @click="filterType('PI')"> PI </a>
                                </li>
                                <li>
                                    <a href="#" class="no-bullet uk-text-uppercase" @click="filterType('VI')"> VI </a>
                                </li>
                                <li>
                                    <a href="#" class="no-bullet uk-text-uppercase" @click="filterType('ED')"> ED </a>
                                </li>
                                <li>
                                    <a href="#" class="no-bullet uk-text-uppercase" @click="filterType('CA')"> CA </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 text-center pt-3 pb-3">
                        <div id="vmap" class="relative"></div>
                    </div>
                    <div class="col-md-8 text-center pt-5 pb-3">
                        <div class="row pt-5">
                            <div class="col-md-12">
                                <div class="value-box uk-text-left relative p-0 m-0 mt-2 pl-3 pb-2">
                                    <span class="title uk-text-uppercase">
                                        <i class="fad fa-location mr-1"></i> 
                                        Selected Location
                                    </span>
                                    <span class="value" id="vmap-selected-location"> 
                                        Chanthaburi 
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="value-box relative p-0 m-0 mt-2 pl-4">
                                    <span class="title uk-text-uppercase"> 
                                        <i class="fad fa-circle mr-1" style="color: #d4e6f2;"></i>
                                        Bodyguard 
                                    </span>
                                    <span class="value uk-float-left"> 1% </span>
                                    <span class="uk-float-left ml-2 mt-2" style="color: blue;">
                                        <small> (2 People) </small>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="value-box relative p-0 m-0 mt-2 pl-4">
                                    <span class="title uk-text-uppercase"> 
                                        <i class="fad fa-circle mr-1" style="color: #7eb3d5;"></i>
                                        Security Guard 
                                    </span>
                                    <span class="value uk-float-left"> 1% </span>
                                    <span class="uk-float-left ml-2 mt-2" style="color: blue;">
                                        <small> (2 People) </small>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="value-box relative p-0 m-0 mt-2 pl-4">
                                    <span class="title uk-text-uppercase"> 
                                        <i class="fad fa-circle mr-1" style="color: #2a80b9;"></i>
                                        Private Invertigate 
                                    </span>
                                    <span class="value uk-float-left"> 1% </span>
                                    <span class="uk-float-left ml-2 mt-2" style="color: blue;">
                                        <small> (2 People) </small>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="value-box relative p-0 m-0 mt-2 pl-4">
                                    <span class="title uk-text-uppercase"> 
                                        <i class="fad fa-circle mr-1" style="color: #1e618c;"></i>
                                        Education 
                                    </span>
                                    <span class="value uk-float-left"> 1% </span>
                                    <span class="uk-float-left ml-2 mt-2" style="color: blue;">
                                        <small> (2 People) </small>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="value-box relative p-0 m-0 mt-2 pl-4">
                                    <span class="title uk-text-uppercase"> 
                                        <i class="fad fa-circle mr-1" style="color: #0c4070;"></i>
                                        Car Rental 
                                    </span>
                                    <span class="value uk-float-left"> 1% </span>
                                    <span class="uk-float-left ml-2 mt-2" style="color: blue;">
                                        <small> (2 People) </small>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="value-box relative p-0 m-0 mt-2 pl-4">
                                    <span class="title uk-text-uppercase"> 
                                        <i class="fad fa-circle mr-1" style="color: #122230;"></i>
                                        VIP Service 
                                    </span>
                                    <span class="value uk-float-left"> 1% </span>
                                    <span class="uk-float-left ml-2 mt-2" style="color: blue;">
                                        <small> (2 People) </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="clearfix">
                            <hr>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="value-box relative p-0 m-0 mt-2 pl-1 pb-2">
                                    <span class="title uk-text-uppercase"> 
                                        <i class="fa-duotone fa-location-crosshairs mr-1"></i> Top Location 
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!-- <div v-for="(mission, missionIndex) in missionLocation" :key="missionIndex" v-show="missionIndex<12" class="col-md-3">
                                <div class="value-box relative p-0 m-0 mt-2 text-center">
                                    <span class="value uk-block"> {{mission.count}} </span>
                                    <span class="title uk-text-uppercase uk-block"> {{mission.province}} </span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="text-center mt-4">
                    <hr style="margin-top: -10px;">
                    <!-- <a href="#" class="uk-text-primary"> See all {{missionLocation.length}} Provinces </a> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        $(document).ready(function () {
            $('#vmap').vectorMap({
                map: 'thai_en',
                backgroundColor: 'transparent',
                color: '#999999',
                hoverColor: '#1565f8',
                borderWidth: 0.1,
                borderOpacity: 1,
                selectedColor: '#1363f8',
                enableZoom: false,
                showTooltip: true,
                scaleColors: ['#C8EEFF', '#006491'],
                onRegionClick: function (element, code, region) {
                    var message = 'You clicked "' + region + '" which has the code: ' + code.toUpperCase();
                    alert(message)
                    $('#vmap-selected-location').text(region);
                }
            });
        });
    },
    methods:{

        locationType(type) {
            alert(type)
        },
        filterType(type) {
            alert(type)
        }
       
    }
}
</script>