<template>
  <div>
    <!----- Header Title ----->
    <section class="row">
      <Header />
    </section>

    <!----- * TABS * ----->
    <TabList />
    
    <section class="tpui-container">
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
            <div class="grey-box p-3 pt-4">
                <!--  Profile -->
                <Profile v-if="instructorDetail.id" :instructorDetail="instructorDetail" />

                <!---- Contact Person ---->
                <Contact v-if="instructorDetail.id" :instructorDetail="instructorDetail" />

                <Skill />

                <Achievement />

            </div>
        </div>
      </div>
    </section>
    
  </div>
</template>

<script>
import TabList from '../../Edit/TabList.vue';
import Profile from "./Profile.vue";
import Contact from "./Contact.vue";
import Skill from "./Skill.vue";
import Achievement from "./Achievement.vue";

export default {
    components: {
        TabList,
        Profile,
        Contact,
        Skill,
        Achievement
       
    },
    data() {
        return {
            instructorDetail: {
                id:1
            },
        }
    },
    mounted() {
        this.getInstructorDetail()
    },
    methods: {
        getInstructorDetail() {
            const api = 'admin/agencies/' + this.$route.params.id
            axios
            .get(api)
            .then((response) => {
                if(!response.data.success) {
                this.$router.push('/404')
                }
                this.instructorDetail = response.data.data
                this.owners = response.data.data.owners
                console.log(this.instructorDetail)
                this.title()
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
        },

    },

}
</script>
