<template>
  <div uk-scrollspy="cls: uk-animation-slide-bottom-small; target: .animate; delay: 200; repeat: false">
    <!----- Chart ----->
    <Chart></Chart>

    <Booking></Booking>
    <div class="row">

      <!---------- Registration : New Customer --------->
      <Customer></Customer>

      <!---------- Mission and Agent location --------->

  
    </div>
  </div>
</template>

<script>

import Chart from "./Utilization.vue"
import Booking from "./Booking.vue"
import Customer from "./_Customer.vue"

export default {
  components: {
    Chart,
    Booking,
    Customer,
  }
}

</script>