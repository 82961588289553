<template>
    <router-link :to="url"  class="outline-btn">
        <i class="fas fa-plus mr-2"></i>Add
    </router-link>
</template>

<script>
export default {
    props: {
        url: String
    }
}
</script>
<style scoped>
.outline-btn {
    height: 40px;
}
</style>