<template>
    <div class="p-5">
          <!----- Header Title ----->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h3 class="page-title mb-1 mt-0">
                        {{ this.$route.meta.title }}
                    </h3>
                </div>
                <div class="breadcrumb-container">
                    <ul class="uk-breadcrumb">
                        <li><span>Admin</span></li>
                        <li><a href="/reservation">Reservation</a></li>
                        <li><a href="">{{ this.$route.meta.title }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 relative"></div>
        </div>
      <!-- New Reservation -->
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
            <section class="grey-box register" v-if="!hasSeenCongrats">
                <div class="register-stepper">
                    <div class="step step-done"><span class="step-number">1</span></div>
                    <div class="step step-done"><span class="step-number">2</span></div>
                    <div class="step step-done"><span class="step-number">3</span></div>
                    <div class="step step-done"><span class="step-number">4</span></div>
                    <div class="step step-active"><span class="step-number">5</span></div>
                    <div class="step"><span class="step-number">6</span></div>
                </div>

                <transition name="slide-fade">
                    <section>
                        <form class="form" action="#">
                            <div class="row mt-5">
                                <div class="col-md-7">
                                    <p class="text-uppercase text-sm mb-2">Selected Vehicle</p>
                                    <hr class="horizontal dark mt-0" />
                                    <div class="d-flex px-2 py-1">
                                        <div class="mr-4">
                                            <img
                                                src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                class="availablecars me-3"
                                                alt="user1"
                                            />
                                        </div>
                                        <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                        <ul class="car-list">
                                            <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                            <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                            <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                            <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <p class="text-uppercase text-sm mb-2">Summary</p>
                                    <hr class="horizontal dark mt-0" />
                                    <table class="table table-bordered">
                                        <tr>
                                            <td><h6 class="text-sm mb-0 ">Vehicle</h6></td>
                                            <td class="text-right"><p class="text-sm mb-0">3x days</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">CDW</h6></td>
                                            <td colspan="2" class="text-right"><p class="text-sm mb-0">$8</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">Insurance</h6></td>
                                            <td colspan="2" class="text-right"><p class="text-sm mb-0">$11</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">Discount</h6></td>
                                            <td class="text-right"><p class="text-sm mb-0">s6xwOH</p></td>
                                            <td class="text-right"><p class="text-sm mb-0">$2</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">TOTAL</h6></td>
                                            <td colspan="2" class="text-right"><h4 class="mr-0 mb-0">$35.00</h4></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <p class="text-uppercase text-sm mt-4 mb-2">Date & Time</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-3">
                                    <p class="label-detail">Pick-up Date</p>
                                    <p class="mt-0">29-01-2024</p>
                                </div>
                                <div class="col-md-3">
                                    <p class="label-detail">Pick-up Time</p>
                                    <p class="mt-0">08:00</p>
                                </div>
                                <div class="col-md-3">
                                    <p class="label-detail">Return Date</p>
                                    <p class="mt-0">29-01-2024</p>
                                </div>
                                <div class="col-md-3">
                                    <p class="label-detail">Return Time</p>
                                    <p class="mt-0">08:00</p>
                                </div>
                                <div class="col-md-3">
                                    <p class="label-detail">Pick-up Location</p>
                                    <p class="mt-0">29-01-2024</p>
                                </div>
                                <div class="col-md-3">
                                    <p class="label-detail">Return Location</p>
                                    <p class="mt-0">08:00</p>
                                </div>
                            </div>
                            <p class="text-uppercase text-sm mt-4 mb-2">Customer Information</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="label-detail">Name</p>
                                    <p class="mt-0">Hanah Salazar</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Email Address</p>
                                    <p class="mt-0">barry.johnson@wisoky.com</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Phone Number</p>
                                    <p class="mt-0">+15406069319</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Street</p>
                                    <p class="mt-0">7691 Jody Villages Suite 931</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">City</p>
                                    <p class="mt-0">Spinkamouth</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">State</p>
                                    <p class="mt-0">Oklahoma</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Zip</p>
                                    <p class="mt-0">13275-0026</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Country</p>
                                    <p class="mt-0">Wallis & Futuna</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Birthday</p>
                                    <p class="mt-0">27-01-1994</p>
                                </div>
                            </div>
                            <p class="text-uppercase text-sm mt-4 mb-2">Driver's Information</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="label-detail">Driver's Name</p>
                                    <p class="mt-0">Weys Agnes</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">DL Number</p>
                                    <p class="mt-0">7273</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Expiration Date</p>
                                    <p class="mt-0">13-03-2024</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Files Upload</p>
                                    <p class="mt-0">No Images</p>
                                </div>
                            </div>
                            
                            <div class="form-group cta-step mt-4">
                                <div class="cta prev">
                                    <p class="cta-color">
                                        <span class="link_wrap">
                                            <router-link :to="'/book/4'">
                                                <a class="btn prev-btn link_text"><span class="arrow-prev" style="padding-right: 5px;"></span>Previous</a>
                                            </router-link>
                                        </span>
                                    </p>
                                </div>
                                <div class="cta">
                                    <p class="cta-color">
                                        <span class="text"></span>
                                        <span class="link_wrap">
                                            <router-link :to="'/book/6'">
                                                <a class="link_text btn next-btn">Confirm</a>
                                            </router-link>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </section>
                </transition>
            </section>
        </div>
      </div>
    </div>
    
  </template>
  
  
  
  <script>
    import router from '../../routes';

    export default {
        el: "#app",
        data: () => {
            return {
            steps: {},
            paymentopt: 'offline',
            step: 1,
            customer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            hasSeenCongrats: false,
            tempCustomer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            };
        },
    methods: {
        redirectReservation() { // 
            router.push({path: '/reservation/'})
        },

        prev() {
        this.step--;
        },

        next() {
        this.step++;
        },

        newBooking: function () {
            this.hasSeenCongrats = true;
        },
    }

};
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>