<template>
    <nav class="service-tabs">
        <ul class="nav nav-fill">
            <li v-for="(list, index) in tablist" :key="'a' + index">
                <router-link class="nav-item nav-link" :to="list.path" tag="a" v-if="list.checked == true">
                {{ list.name }}
                </router-link>

                <dd class="nav-item nav-link" v-else>
                {{ list.name }}
                </dd>
                
                <label class="switch" v-if="list.name != 'General'">
                    <input type="checkbox" v-model="list.checked"/>
                    <span class="slider round"></span>
                </label>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            tablist: this.$helpers.serviceTabs(this.$route.params.id),
        }
    },
}
</script>