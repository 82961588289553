<template>
    <div>
        <!----- Header Title ----->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h3 class="page-title mb-1">
                        {{ this.$route.meta.title }}
                    </h3>
                </div>
                <div class="breadcrumb-container">
                    <ul class="uk-breadcrumb">
                        <li><span>Admin</span></li>
                        <li><a href="/reservation">Reservation</a></li>
                        <li><a href="">{{ this.$route.meta.title }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 relative"></div>
        </div>
        <!-- New Reservation -->
        <div class="row align-items-center mb-4">
            <div class="col-lg-12">
                <section class="grey-box register" v-if="!hasSeenCongrats">
                    <div class="register-stepper">
                        <div class="step step-done"><span class="step-number">1</span></div>
                        <div class="step step-done"><span class="step-number">2</span></div>
                        <div class="step step-done"><span class="step-number">3</span></div>
                        <div class="step step-done"><span class="step-number">4</span></div>
                        <div class="step step-active"><span class="step-number">5</span></div>
                        <div class="step"><span class="step-number">6</span></div>
                    </div>

                    <transition>
                        <section>
                            <form class="form" action="#" v-if="readyShow">
                                <div class="row mt-5">
                                    <div class="col-md-7">
                                        <p class="text-uppercase text-sm mb-2">Selected Vehicle</p>
                                        <hr class="horizontal dark mt-0" />
                                        <div class="d-flex px-2 py-1">
                                            <div class="mr-4">
                                                <img :src="reservation.vehicle.url_path"
                                                    class="availablecars me-3" alt="user1" />
                                            </div>
                                            <div class="d-flex flex-column justify-content-center">
                                                <h6 class="mb-0 text-sm">{{ reservation.vehicle.model }}  {{ reservation.vehicle.make }} {{ reservation.vehicle.class }}</h6>
                                                <ul class="car-list">
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car"
                                                            aria-hidden="true"></i> {{ reservation.vehicle.transmission }}</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o"
                                                            aria-hidden="true"></i> {{ reservation.vehicle.aircondition }}</li>
                                                    <li class="text-xs text-secondary mb-0"><i
                                                            class="fa fa-play-circle-o" aria-hidden="true"></i> {{ reservation.vehicle.media }}</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o"
                                                            aria-hidden="true"></i>{{ reservation.vehicle.doors }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="text-uppercase text-sm mb-2">Summary</p>
                                        <hr class="horizontal dark mt-0" />
                                        <table class="table table-bordered">
                                            <tr>
                                                <td>
                                                    <h6 class="text-sm mb-0 ">Vehicle</h6>
                                                </td>
                                                <td class="text-right">
                                                    <p class="text-sm mb-0">{{ reservation.total_days }}x days</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 class="text-sm mb-0">CDW</h6>
                                                </td>
                                                <td colspan="2" class="text-right">
                                                    <p class="text-sm mb-0">${{reservation.cwd}}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 class="text-sm mb-0">Insurance</h6>
                                                </td>
                                                <td colspan="2" class="text-right">
                                                    <p class="text-sm mb-0">$0</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 class="text-sm mb-0">Discount</h6>
                                                </td>
                                                <td class="text-right">
                                                    <p class="text-sm mb-0">{{reservation.coupon_code}}</p>
                                                </td>
                                                <td class="text-right">
                                                    <p class="text-sm mb-0">${{reservation.discount_value}}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h6 class="text-sm mb-0">TOTAL</h6>
                                                </td>
                                                <td colspan="2" class="text-right">
                                                    <h4 class="mr-0 mb-0">${{reservation.total_price}}</h4>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <p class="text-uppercase text-sm mt-4 mb-2">Date & Time</p>
                                <hr class="horizontal dark mt-0" />
                                <div class="row">
                                    <div class="col-md-3">
                                        <p class="label-detail">Pick-up Date</p>
                                        <p class="mt-0">{{showDate(reservation.pickup_datetime)}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="label-detail">Pick-up Time</p>
                                        <p class="mt-0">{{showTime(reservation.pickup_datetime)}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="label-detail">Return Date</p>
                                        <p class="mt-0">{{showDate(reservation.return_datetime)}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="label-detail">Return Time</p>
                                        <p class="mt-0">{{showTime(reservation.return_datetime)}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="label-detail">Pick-up Location</p>
                                        <p class="mt-0">{{reservation.pickup_location}}</p>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="label-detail">Return Location</p>
                                        <p class="mt-0">{{reservation.return_location}}</p>
                                    </div>
                                </div>
                                <p class="text-uppercase text-sm mt-4 mb-2">Customer Information</p>
                                <hr class="horizontal dark mt-0" />
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="label-detail">Name</p>
                                        <p class="mt-0">{{reservation.customer.fname}} {{reservation.customer.lname}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="label-detail">Email Address</p>
                                        <p class="mt-0">{{reservation.customer.email}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="label-detail">Phone Number</p>
                                        <p class="mt-0">{{reservation.customer.phone_number}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="label-detail">Street</p>
                                        <p class="mt-0">{{reservation.customer.street}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="label-detail">City</p>
                                        <p class="mt-0">{{reservation.customer.city}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="label-detail">State</p>
                                        <p class="mt-0">{{reservation.customer.state}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="label-detail">Zip</p>
                                        <p class="mt-0">{{reservation.customer.zipcode}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="label-detail">Country</p>
                                        <p class="mt-0">{{reservation.customer.country}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="label-detail">Birthday</p>
                                        <p class="mt-0">{{reservation.customer.birth_date}}</p>
                                    </div>
                                </div>
                                <p class="text-uppercase text-sm mt-4 mb-2">Driver's Information</p>
                                <hr class="horizontal dark mt-0" />
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="label-detail">Driver's Name</p>
                                        <p class="mt-0">{{reservation.customer.fname}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="label-detail">DL Number</p>
                                        <p class="mt-0">{{reservation.customer.license}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="label-detail">Expiration Date</p>
                                        <p class="mt-0">{{reservation.customer.license_exp_date}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="label-detail">Files Upload</p>
                                        <p class="mt-0">No Images</p>
                                    </div>
                                </div>

                                <div class="form-group cta-step mt-4">
                                    <div class="cta prev">
                                        <p class="cta-color">
                                            <span class="link_wrap">
                                                <router-link :to="'/new/reservation/4'">
                                                    <a class="btn prev-btn link_text"><span class="arrow-prev"
                                                            style="padding-right: 5px;"></span>Previous</a>
                                                </router-link>
                                            </span>
                                        </p>
                                    </div>
                                    <div class="cta">
                                        <p class="cta-color">
                                            <span class="text"></span>
                                            <span class="link_wrap">
                                                <a  @click="next()" class="link_text btn next-btn">Confirm</a>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </section>
                    </transition>
                </section>
            </div>
        </div>


        <!-- Add **uk-toggle="target: #createmodal" in div -->
        <!-- Create success Modal -->
        <div id="createmodal" uk-modal>
            <div class="uk-modal-dialog uk-modal-body p-2 text-center">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div class="row align-items-center mb-4">
                    <div class="col-md-12 mt-4">
                        <h4>Create reservation successful!</h4>
                    </div>
                </div>
                <a href="/reservation" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
            </div>
        </div>

        <!-- Add **uk-toggle="target: #updatemodal" in div -->
        <!-- Create success Modal -->
        <div id="updatemodal" uk-modal>
            <div class="uk-modal-dialog uk-modal-body p-2 text-center">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div class="row align-items-center mb-4">
                    <div class="col-md-12 mt-4">
                        <h4>Update reservation successful!</h4>
                    </div>
                </div>
                <a href="#" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
            </div>
        </div>
    </div>

</template>



<script>
import router from '../../routes';

export default {
    el: "#app",
    data: () => {
        return {
            readyShow:false,
            steps: {},
            paymentopt: 'offline',
            step: 1,
            hasSeenCongrats: false,
            reservation:{},
        };
    },
    mounted() {
        this.getReservationDetails()
    },
    methods: {
        next() {
            if (Object.prototype.hasOwnProperty.call(this.$route.params, "id")) {
                location.href = "/reservation/6/edit/" + this.$route.params.id
            } else {
                location.href = "/new/reservation/6"
            }
        },
        completeReservation(){

            let reservationData = {
                "is_complete": 1,

            }
            const admintoken = localStorage.getItem('auth_token_default')
            const reservation_id = localStorage.getItem("reservation_id")
            if (reservation_id) {
                axios.put(`https://api.615autorental.com/api/admin/reservation/` + reservation_id, reservationData, {
                    headers: {
                        'Authorization': `Bearer ${admintoken}`,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                       
                        UIkit.modal('#createmodal').show();
                        console.log('Customer updated successfully:', response.data);
                        // handle success
                    })
                    .catch(error => {
                        alert("Please complete form")
                        console.error('Error updating customer:', error);
                        // handle error
                    });
            } else {
                location.href = "/new/reservation/1"
            }
        },
        getReservationDetails(){
            const reservation_id = localStorage.getItem("reservation_id")
            const api = 'https://api.615autorental.com/api/admin/reservation/'+reservation_id+'/details'
            const admintoken = localStorage.getItem('auth_token_default')
            const headers = {
                'Authorization': `Bearer ${admintoken}`,
                'Content-Type': 'application/json' // You can adjust content type as needed
            };
            axios
                .get(api, { headers }, {
                params: {
                    page: 1,
                    limit: 30
                }
                })
                .then((response) => {
                this.reservation = response.data.data
                console.log(this.reservation)
                this.readyShow = true
                })
                .catch((error) => {
                console.error('There was an error!', error.message)
                })
        },
        redirectReservation() { // 
            router.push({ path: '/reservation/' })
        },

        showDate(datetime) {
            return datetime.substring(0,10)
        },
        showTime(datetime){
            return datetime.substring(10)
        },
      

        newBooking: function () {
            this.hasSeenCongrats = true;
        },
    }

};
</script>

<style scoped>
.card-footer {
    text-align: right;
    position: relative;
    background: #fff;
}

.pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
}
</style>