<template>
    <!------- OBJECT TO PROTECT ------>
    <div class="row align-items-center mb-4 objects-to-protect">
        <div class="col-lg-12">
            <div class="grey-box p-3 pt-4">
                <div class="row mb-2">
                    <div class="col-md-6 text-left">
                        <h5 class="mt-1 mb-3 uk-text-bold"> 
                            <i class="fas fa-shield-alt mr-2"></i> Objects to protect 
                        </h5>
                    </div>
                </div>
                <div class="p-3" v-for="(object, objectIndex) in objects" :key="objectIndex">
                    <form @submit.prevent="updateObject(object)" ref="updateObject">
                        <div class="row bg-lightblue pt-4" style="border-radius: 20px;" >
                            <div class="col-md-3 relative pb-5" >
                                <div class="profile-img position-relative text-center mb-5">
                                    <span class="edit-btn">
                                        <input
                                        type="file"
                                        name="photo"
                                        class="file-upload"
                                        @change="handleFileUpload($event.target.files, objectIndex)"
                                        />
                                        <i class="fal fa-pen upload-button"></i>
                                    </span>
                                    
                                    <img :src="objects[objectIndex].url" uk-cover/>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <form id="protect">
                                    <div class="row">
                                        <div class="col-md-12 mb-1 pt-2">
                                            <h6 class="uk-text-uppercase uk-text-bold mb-3"> {{ object.full_name }} </h6>
                                            <hr class="mb-2 mt-0">
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" name="first_name" v-model="objects[objectIndex].first_name" class="form-control" required>
                                                <label>Name</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" name="last_name" v-model="objects[objectIndex].last_name" class="form-control" required>
                                                <label>LastName</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="email" name="email" v-model="objects[objectIndex].email" class="form-control" required>
                                                <label>Email</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <input type="text" name="occupation" v-model="objects[objectIndex].occupation" class="form-control" required>
                                                <label>Occupation</label>
                                            </div>
                                        </div>
                                        <div class="col-md-3 pr-0">
                                            <div class="form-group">
                                                <input type="date" name="dob" v-model="objects[objectIndex].dob" class="form-control dob-datepicker" required>
                                                <label> Date of Birth</label>
                                            </div>
                                        </div>
                                        <div class="col-md-1 pl-0">
                                            <div class="form-group" >
                                                <input type="text" :value="objects[objectIndex].dob|ages" class="form-control" readonly>
                                                <label>Age</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group vue-tel-input-wrapper">
                                                <template>
                                                <vue-tel-input name="phone" v-model="objects[objectIndex].phone"></vue-tel-input>
                                                <label>Contact Number</label>
                                                </template>
                                            </div>
                                        </div>
                                        <div class="clearfix mt-3"></div>
                                        <div class="col-md-4 pt-3">
                                            <h6 class="mb-0">Physical Issues</h6>
                                            <div class="row">
                                                <div class="col-md-5 mb-4">
                                                    <label class="form_radio">
                                                        <input type="radio" id="physical_issues_yes" :value="true" v-model="objects[objectIndex].has_physical_issue" name="has_physical_issue">
                                                        <span class="design"></span>
                                                        <span class="text">Yes</span>
                                                    </label>
                                                </div>
                                                <div class="col-md-5 mb-4">
                                                    <label class="form_radio">
                                                        <input type="radio" id="physical_issues_no" :value="false" v-model="objects[objectIndex].has_physical_issue" name="has_physical_issue">
                                                        <span class="design"></span>
                                                        <span class="text">No</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group" v-show="object.has_physical_issue">
                                                <input type="text" name="physical_issue_info" class="form-control" v-model="objects[objectIndex].physical_issue_info">
                                                <label> Description </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4 pt-3">
                                            <h6 class="mb-0">Health Issues</h6>
                                            <div class="row">
                                                <div class="col-md-5 mb-4">
                                                    <label class="form_radio">
                                                        <input type="radio" id="health_issues_yes" :value="true" v-model="objects[objectIndex].has_health_issue" name="has_health_issue">
                                                        <span class="design"></span>
                                                        <span class="text">Yes</span>
                                                    </label>
                                                </div>
                                                <div class="col-md-5 mb-4">
                                                    <label class="form_radio">
                                                        <input type="radio" id="health_issues_no" :value="false" v-model="objects[objectIndex].has_health_issue" name="has_health_issue">
                                                        <span class="design"></span>
                                                        <span class="text">No</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group" v-show="object.has_health_issue">
                                                <input type="text" name="health_issue_info" class="form-control" v-model="objects[objectIndex].health_issue_info">
                                                <label> Description </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4 pt-3">
                                            <h6 class="mb-0">Medical Needs</h6>
                                            <div class="row">
                                                <div class="col-md-5 mb-4">
                                                    <label class="form_radio">
                                                        <input type="radio" id="medical_needs_y" :value="true" v-model="objects[objectIndex].medicine" name="medicine">
                                                        <span class="design"></span>
                                                        <span class="text">Yes</span>
                                                    </label>
                                                </div>
                                                <div class="col-md-5 mb-4">
                                                    <label class="form_radio">
                                                        <input type="radio" id="medical_needs_n" :value="false" v-model="objects[objectIndex].medicine" name="medicine">
                                                        <span class="design"></span>
                                                        <span class="text">No</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group" v-show="object.medicine">
                                                <input type="text" name="medicine_info" v-model="objects[objectIndex].medicine_info" class="form-control" >
                                                <label> Description </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4 pt-3">
                                            <h6 class="mb-0">Blood Sickness</h6>
                                            <div class="row">
                                                <div class="col-md-5 mb-4">
                                                    <label class="form_radio">
                                                        <input type="radio" id="blood_sickness_y" :value="true" v-model="objects[objectIndex].blood_sickness" name="blood_sickness">
                                                        <span class="design"></span>
                                                        <span class="text">Yes</span>
                                                    </label>
                                                </div>
                                                <div class="col-md-5 mb-4">
                                                    <label class="form_radio">
                                                        <input type="radio" id="blood_sickness_n" :value="false" v-model="objects[objectIndex].blood_sickness" name="blood_sickness">
                                                        <span class="design"></span>
                                                        <span class="text">No</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group" v-show="object.blood_sickness">
                                                <input type="text" name="blood_sickness_info" class="form-control" v-model="objects[objectIndex].blood_sickness_info">
                                                <label> Description </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-12">
                                            <hr />
                                        </div>
                                        <div class="col-md-12 text-right mt-0 mb-4">
                                            <button href="#" class="cancel-btn border-0"> 
                                                <i class="fas fa-minus-circle mr-2"></i> Cancel
                                            </button>
                                            <button type="submit" id="client" class="save-btn border-0 ml-2 mr-2"> 
                                                <i class="fas fa-save mr-2"></i> Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        objects_to_protect: Array
    },
    data() {
        return {
           objects: [],
           photoUrl: []
        }
    },
	mounted() {
        this.objects = this.objects_to_protect
    },
    methods: {
        updateObject(object) {
			const api = 'admin/missions/' + this.$route.params.id + '/objects/' + object.id

			let form = this.$refs.updateObject;
            let formData = new FormData(form);

			axios.post(api, formData)
				.then(response => {
					var notyf = new Notyf();
					notyf.confirm('Updated!');
				})
				.catch(error => {
					this.errorMessage = error.message
					console.error('There was an error!', error)
				})
		},
        handleFileUpload(file, index) {
            this.objects[index].url = URL.createObjectURL(file[0])
            console.log(this.objects)
        },
    }
}
</script>

<style scoped>

.objects-to-protect .grey-box input,
.objects-to-protect .grey-box .vue-tel-input,
.objects-to-protect .grey-box .vue-tel-input-wrapper {
    background: #F0F2F5 !important;
    border: solid 1px #e4e6ef !important;
}
.objects-to-protect .grey-box .vue-tel-input-wrapper {
    background: none !important;
    border: 0px !important;
}
.objects-to-protect hr {
    border-top: 1px solid rgba(181, 181, 181, 0.2) !important;
}

</style>