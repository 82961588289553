<template>
    <div>
        <!----- Header Title ----->
        <section class="row">
        <Header />
        </section>

        <!----- * TABS * ----->
        <TabList />
        
        <section class="tpui-container">
            <div class="row align-items-center ">
                <div class="col-md-12 mb-3">
                    <div class="grey-box p-3 pt-4">
                        <div class="col-md-12 text-left">
                            <h5 class="mt-1 mb-2 uk-text-bold">
                                <i class="fa fa-bar-chart mr-2"></i> Car Rental Detail
                            </h5>
                        </div>
                        <ul uk-accordion>
                            <li class="uk-open with-border mb-3">
                                <a class="uk-accordion-title with-bg" href="#"> 
                                    <i class="fas fa-file-spreadsheet mr-2"></i>
                                    General information
                                </a>
                                <div class="uk-accordion-content p-3">
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Type</label>
                                                <p>Car rental with driver</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <hr />
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="form-group">
                                                <label>Duration</label>
                                                <p>25 AUG 2022 <span class="start-time ml-1" style="font-size: 11px;">10:25</span> - 30 AUG 2022</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 text-right ">
                                            <p>฿10,500 — ฿15,000</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <hr />
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Location</label>
                                                <p>Trendy Gym (10/31) 10 Soi Sukhumvit 13, Khlong Toei Nuea Watthana, Bangkok 10110, Thailand</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">Meeting location</label>
                                            <p>BTS Thonglor</p>
                                        </div>
                                        <div class="col-sm-6">
                                            <label for="">Destination 1</label>
                                            <p>Hotel Grand, Bangkok</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="uk-open with-border mb-3">
                                <a class="uk-accordion-title with-bg" href="#"> 
                                    <i class="fas fa-file-spreadsheet mr-2"></i>
                                    About Client
                                </a>
                                <div class="uk-accordion-content p-3">
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>No. of Client</label>
                                                <p>2 People</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row p-3">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <hr />
                                                </div>
                                                <div class="col-md-3 ">
                                                    <div class="form-group">
                                                        <label>Name</label>
                                                        <p>MR. Yongchaiyuth Pimolkittikool</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 ">
                                                    <div class="form-group">
                                                        <label>Phone number</label>
                                                        <p>+66 854 - 5586</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 ">
                                                    <div class="form-group">
                                                        <label>Nationality</label>
                                                        <p>Thailand</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 ">
                                                    <div class="form-group">
                                                        <label>Age</label>
                                                        <p>36</p>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="uk-open with-border">
                                <a class="uk-accordion-title with-bg" href="#"> 
                                    <i class="fas fa-file-spreadsheet mr-2"></i>
                                    Vehicle
                                </a>
                                <div class="uk-accordion-content p-3">
                                    <div class="grey-box p-4">
                                        <div class="row">
                                            <div class="col-md-3 car-image">
                                                <div class="rounded-img">
                                                    <img src="/assets/img/profile.png" alt="" class="uk-border-rounded">
                                                </div>
                                            </div>
                                            <div class="col-md-9 ">
                                                <div class="row">
                                                    <div class="col-md-12 mt-3 mb-2">
                                                        <div class="label">
                                                            <span class="uk-label mr-3">With Chauffeur</span>
                                                            <span class="uk-label mr-3">4 Seaters</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4 ">
                                                        <label for="">Brand:</label>
                                                        <p>lamborghini</p>
                                                    </div>
                                                    <div class="col-md-4 ">
                                                        <label for="">Model:</label>
                                                        <p>Gallardo</p>
                                                    </div>
                                                    <div class="col-md-4 ">
                                                        <label for="">Year:</label>
                                                        <p>2022</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6" style="color: #E55356">
                    <i class="fa fa-ban" aria-hidden="true"></i>
                    Abort mission
                </div>
                <div class="col-sm-6 text-right">
                    <a href="#" class="edit-btn chart btn-height-normal">
                        <i class="fa fa-comment-o mr-2" aria-hidden="true"></i>
                        Chart
                    </a>
                </div>
            </div>
        </section>
      
    </div>
</template>

<script >
import TabList from "../Edit/TabList.vue"

export default {
    components: {
        TabList,
    },
    data() {
        return {
           
        }
    },
    mounted() {

    },
    methods: {
      
    },

}
</script>


<style  scoped>
.grey-box label{
    color: #a5a5a5 !important;
    left: 0;
    position: relative;
}
.grey-box p{
    margin-top: 10px;
    color: #212529 !important;
}
.total-amount h5 {
    font-weight: 700;
    color: #0B63F8 !important;
}
.edit-btn.chart {
    background: #FEC02F;
    color: #212529 !important;
}

.car-image {
    border-right: 1px solid rgba(181, 181, 181, 0.2);
}

.car-image img {
    width: 100%;
}
</style>
