<template>
  <div class="container-fluid mb-5">
    <!----- Header Title ----->
    <div class="row content-header">
      <div class="col-md-6">
        <Header />
      </div>
      <div class="col-md-6 relative uk-animation-slide-bottom-small">
        <AddBtn :url="`add/staff`" />
        <div class="pb-3 pl-2 pt-5 mt-3"  style="float: right;">
          <Search @filter="filter"></Search>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table data-table table-round table-hover uk-animation-slide-bottom-small">
            <thead>
              <tr>
                <th scope="col" width="100px"></th>
                <th scope="col">Name <Sort column="name_en" @change="sort"></Sort></th>
                <th scope="col">Email</th>
                <th scope="col">Mobile No.</th>
                <th scope="col">Date of Birth</th>
                <th scope="col">Gender</th>
                <th scope="col">Role <Sort column="role_name" @change="sort"></Sort></th>
                <th scope="col">Position</th>
                <th scope="col" class="blankhead" width="150px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(admin, adminIndex) in adminList" :key="adminIndex">
                <td><img :src="admin.avatar ? admin.avatar : '/assets/img/non-profile.jpeg' " class="uk-border-circle" width="50px" height="50px"> </td>
                <td><router-link :to="'staff/details/' + admin.id"> {{ admin.first_name + ' ' + admin.last_name }} </router-link></td>
                <td> {{admin.email}} </td>
                <td> {{admin.phone | phoneformat}} </td>
                <td> {{admin.dob}} </td>
                <td> {{admin.gender}} </td>
                <td> {{admin.role.role_name}} </td>
                <td> {{admin.position}} </td>

                <td class="text-right">
                  <div class="action-btn d-flex justify-content-end">
                    <router-link :to="'staff/edit/' + admin.id">
                      <a class="edit-btn"><i class="fal fa-edit"></i></a>
                    </router-link>
                    <!-- <a href="equipment-single.html" class="view-btn">View</a> -->
                    <a href="#" class="del-btn" v-on:click="del_method(admin.id, admin.first_name + ' ' + admin.last_name)"><i class="fal fa-trash-alt"></i></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer pb-0" v-if="adminList.length > 0">
            <Pagination 
            @getCurrentPagination="getCurrentPagination" 
            :totalPages="paginData.totalPages"
            :page="paginData.page" />
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>

export default {
  mounted() {
    this.getAdminList()
  },
  data() {
    return {
      sortParams: {
          column: 'id',
          type: 'desc',
      },
      
      paginData: {
          page: 1,
          totalPages: 0,
          limit: this.$helpers.pageLimit()
      },
      
      filters: {
        keyword: '',
      },

      adminList: [],
    }
  },
  methods: {
    del_method(id, name) {
      var isDelete = true;
      UIkit.modal.confirm('Do you want to delete user: ' + name + " ? ").then(function () {
      }, function () {
      });
    },
    
    filter(keyword, type = 'keyword') {
        this.filters[type] = keyword
        this.getPaginateList()
    },
    sort(params) {
        this.sortParams = {
            column: params.column,
            type: params.type,
        }
        this.getPaginateList()
    },
    getCurrentPagination(currentPage) {
        this.paginData.page = currentPage
        this.getAdminList()
    },
    getAdminList() {
      var notyf = new Notyf();
      const api = 'admin/admins'
      axios
        .get(api, {
            params: {
                page: this.paginData.page,
                limit: this.paginData.limit,
                sort_column: this.sortParams.column,
                sort_type: this.sortParams.type,
                keyword: this.filters.keyword,
            }
        })
        .then((response) => {
          if(response.data.success) {
            this.adminList = response.data.data.records
            this.paginData.totalPages = response.data.data.total_pages
          }else{
            this.$helpers.notification(response.data.success, response.data.message)
          }
        })
        .catch((error) => {
          this.$helpers.notification(false, error.message)
          console.error('There was an error!', error)
        })
    },
  },
}
</script>