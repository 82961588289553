<template>
    <section class="tpui-container">
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box pt-3">
            <div class="col-md-12 pl-1 pr-1">
              <div class="pt-2">
                <div class="row">
                  <div class="col-md-6 text-left">
                    <h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-map-marker-alt mr-2"></i> Company Address</h5>
                  </div>
                  <div class="col-md-6 text-right"></div>
                </div>
                <div class="row">
                  <div class="col-md-12 pb-3 pt-1">
                    <div class="table-responsive">
                      <table class="table data-table table-round table-hover">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Address</th>
                            <th>Province</th>
                            <th>District</th>
                            <th>Zipcode</th>
                            <th>Country</th>
                            <th class="blankhead" width="100px"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">
                              <i uk-icon="home"></i>
                            </td>
                            <td> {{companyDetail.address}} </td>
                            <td> {{companyDetail.province}} </td>
                            <td> {{companyDetail.district}} </td>
                            <td> {{companyDetail.zip_code}} </td>
                            <td> {{companyDetail.country}} </td>
                            <td class="text-right">
                              <a class="view-btn clickable" data-toggle="collapse" data-target="#latest_address_1">Map View</a>
                              <!-- <a class="edit-btn" href="#modal-edit-address" uk-toggle><i class="fal fa-edit"></i></a> -->
                              <!-- <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a> -->
                            </td>
                          </tr>
                          <tr v-if="companyDetail.address">
                            <td colspan="9" class="p-0">
                              <div id="latest_address_1" class="collapse">
                                <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important">
                                  <CompanyAddress :addressObj="companyDetail" v-on:submitLocation="updateAddress($event)"></CompanyAddress>
                                </div>

                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import CompanyAddress from "../../GoogleMap.vue";

export default {
    components: {
        CompanyAddress
    },
    props: {
        companyDetail: Object
    },

    methods: {
        updateAddress(addressFormData) {
			console.log('parent component')
            console.log(addressFormData)
            
            let address = {
                lat: addressFormData.lat,
                lng: addressFormData.lng,
                country : addressFormData.country,
                address : addressFormData.address,
                province : addressFormData.province,
                sub_district : addressFormData.sub_district,
                district : addressFormData.district,
                zip_code : addressFormData.zip_code,
                company_country : addressFormData.country,
                company_address : addressFormData.address,
                company_province : addressFormData.province,
                company_sub_district : addressFormData.sub_district,
                company_district : addressFormData.district,
                company_zip_code : addressFormData.zip_code,
                website: addressFormData.website,

                contact_number: '663222222',
                contact_person: this.companyDetail.contact_person,
                name_en: this.companyDetail.name_en,
                registration_id: this.companyDetail.registration_id,
                tax_id: this.companyDetail.tax_id

            }

            const apiUrl = 'admin/agencies/' + this.$route.params.id + '/company'
            axios
                .post(apiUrl, address)
                .then((response) => {

                    if(response.data.success) {
                    this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                    
                })
                .catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error)
                })
        },
    }
    
}
</script>