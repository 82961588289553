<template>
    <form @submit.prevent="updateProfile">
        <div class="row mt-5">
            <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold">
                    <i class="fas fa-chart-bar mr-2"></i> 
                    Your Profile
                </h5>
            </div>

            <div class="col-md-12 text-left pl-5 pr-5">
                <div class="row">
                    <div class="col-md-12">
                        <h6>Preferred Cases Area</h6>
                    </div>
                    <div class="col-md-2 text-left mb-5 ">
                        <label for="">
                            <input type="checkbox" value="Cheating" class="checkbox mr-2" />
                            Cheating
                        </label>
                    </div>
                    <div class="col-md-2 text-left mb-5">
                        <label for="">
                            <input type="checkbox" value="Missing people" class="checkbox mr-2" />
                            Missing people
                        </label>
                    </div>
                    <div class="col-md-2 text-left mb-5">
                        <label for="">
                            <input type="checkbox" value="Hostage" class="checkbox mr-2" />
                            Hostage
                        </label>
                    </div>
                    <div class="col-md-2 text-left mb-5">
                        <label for="">
                            <input type="checkbox" value="Kidnapping" class="checkbox mr-2" />
                            Kidnapping
                        </label>
                    </div>
                    <div class="col-md-2 text-left mb-2">
                        <label for="">
                            <input type="checkbox" value="Fraud" class="checkbox mr-2" />
                            Fraud
                        </label>
                    </div>
                    <div class="col-md-2 text-left mb-2">
                        <label for="">
                            <input type="checkbox" value="Background Checks" class="checkbox mr-2" />
                            Background Checks
                        </label>
                    </div>
                    <div class="col-md-2 text-left mb-2">
                        <label for="">
                            <input type="checkbox" value="Theft" class="checkbox mr-2" />
                            Theft
                        </label>
                    </div>
                    <div class="col-md-4 text-left mb-2">
                        <label for="">
                            <input type="checkbox" value="Other" class="checkbox mr-2" />
                            Other
                        </label><br />
                        <input type="text" placeholder="Please Specify" class="form-control mt-4">

                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    
}
</script>

<style scoped>
    h6 {
        font-size: 10.5px;
        text-transform: inherit;
        color: #3d3f4e;
        transition: all 0.4s;
        font-weight: 500 !important;
        letter-spacing: -0.01em !important;
        text-transform: uppercase;
        line-height: 18px;
    }
</style>