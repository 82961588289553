<template>
    <form @submit.prevent="submitLocation" >
        <div class="row google-map">
            <div class="col-sm-12 mb-4">
                <div v-if="center.lat" class="gmap-iframe ratio ratio-4x3 ratio-sm-21x9" lc-helper="gmap-embed">
                    <gmap-map
                        :zoom="16"    
                        :center="center"
                        style="width:100%;  height: 600px;"
                    >
                        <gmap-marker
                            v-for="(m, index) in locationMarkers"
                            :key="index"
                            :position="m.position"
                            @click="center=m.position"
                        ></gmap-marker>
                    </gmap-map>
                </div>
            </div>
            <div class="col-md-12">
                <div class="uk-margin">
                    <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon" uk-icon="icon: location"></span>
                        <gmap-autocomplete @place_changed="addLocationMarker" placeholder="" class="uk-input" required></gmap-autocomplete>
                       
                        <label class="ml-5"> Address </label>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group select-box">
                    <input type="text" name="country" v-model="formData.country" class="form-control" required>
                    <label>Country</label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group select-box">
                    <input type="text" name="province" v-model="formData.city" class="form-control" required>
                    <label>City</label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <input type="text" name="zip_code" v-model="formData.zipcode" class="form-control" required>
                    <label> Zip code </label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <input type="text" name="apartment" v-model="formData.state" class="form-control" required>
                    <label> State </label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <input type="text" name="room" v-model="formData.street" class="form-control" required>
                    <label> Street </label>
                </div>
            </div>
            <div class="col-md-3 text-right">
                <div role="group" aria-label="Basic example" class="btn-group mt-2">
                    <a href="#" class="cancel-btn"> <i class="fas fa-minus-circle mr-2"></i> Cancel </a>
                    <button type="submit" id="client" class="save-btn ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
                </div>
            </div>
        </div>
    </form>
</template>
 
<script>
export default {
    props: {
        addressDetail: Object
    },
    data() {
        return {
            formData: {
                id: 0,
                lat: 13.740611969793052,
                lng: 100.55782715562214,
                full_address: '',
                street: '',
                state: '',
                city: '',
                country: '',
                zipcode: null,
            },
            center: { 
                lat: 13.740611969793052,
                lng: 100.55782715562214,
            },
            locationMarkers: []
        };
    },
    mounted() {
        
        // this.$root.$on('customerAddress', (address) => {
            this.setFormData(this.addressDetail)
        // });
    },
    methods: {
        setFormData(address) {
            console.log(address)
            this.formData = address
            this.setMapLocaiton()
        },
        setMapLocaiton() {
            // navigator.geolocation.getCurrentPosition(res => {
                // console.log(res.coords.latitude)
            this.center.lat = parseFloat(this.formData.lat)
            this.center.lng = parseFloat(this.formData.lng)
            // });
            this.locationMarkers = []
            this.locationMarkers.push({ position: this.center })

            console.log(this.center)
            console.log(this.locationMarkers)
        },
        addLocationMarker(location) {
            console.log(location)
            if (location) {
                this.center = {
                    lat: location.geometry.location.lat(),
                    lng: location.geometry.location.lng()
                };
                this.locationMarkers = []
                this.locationMarkers.push({ position: this.center })
                this.formData.full_address = location.formatted_address
                this.formData.lat = location.geometry.location.lat()
                this.formData.lng = location.geometry.location.lng()
                location.address_components.forEach(address => {
                    if(address.types.includes('country')) {
                        this.formData.country = address.long_name
                    }
                    else if(address.types.includes('postal_code')) {
                        this.formData.zipcode = address.long_name
                    }
                    else if(address.types.includes('locality')) {
                        this.formData.city = address.long_name
                    }
                    else if(address.types.includes('administrative_area_level_1') || address.types.includes('sublocality_level_1')) {
                        this.formData.state = address.long_name
                    }
                    else if(address.types.includes('administrative_area_level_2') || address.types.includes('sublocality_level_2')) {
                        this.formData.street = address.long_name
                    }
                });
            }
        },
        submitLocation() {
            this.$emit('submitLocation', this.formData)
        },
        
    }
};
</script>

<style scoped>
    .google-map .save-btn{
        border: 0px;
    }
</style>