import { render, staticRenderFns } from "./_TotalResult.vue?vue&type=template&id=eddb55d2&"
import script from "./_TotalResult.vue?vue&type=script&lang=js&"
export * from "./_TotalResult.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports