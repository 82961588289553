<template>
    <div class="breadcrumb-container">
        <ul class="uk-breadcrumb">
            <li v-for="(crumb, crumbIndex) in this.$route.meta.breadcrumbs" :key="crumbIndex">
                <a :href="crumb.url" :class="{ disabled: isLast(crumbIndex) }" >{{ crumb.text }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    methods: {
        isLast(index) {
            return index === this.$route.meta.breadcrumbs.length - 1;
        },
        // selected(crumb) {
        //     this.$emit('selected', crumb)
        // }
    }
}
</script>