<template>
  <div class="row align-items-center mb-4">
    <div class="col-lg-12">
      <div class="grey-box p-3 pt-4">
        <div class="row">
          <div class="col-md-6 text-left">
            <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-clock mr-2"></i> Mission History</h5>
          </div>
        </div>
        <div class="view-ui mt-4 mb-2">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table data-table table-round table-hover">
                  <thead>
                    <tr>
                      <th scope="col" class="text-left" width="20%">Client</th>
                      <th scope="col" class="text-left" width="15%">Started Date</th>
                      <th scope="col" class="text-left" width="10%">End Date</th>
                      <th scope="col" class="text-left" width="10%">Location</th>
                      <th scope="col" class="text-left" width="auto">Agent on Mission</th>
                      <th scope="col" class="text-center" width="10%">Status</th>
                      <th scope="col" class="blankhead" width="80px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(mission, missionKey) in missionLists" :key="missionKey">
                      <td class="text-left">
                          {{ mission.customer }}
                      </td>
                      <td class="text-left time" style="font-size: 12px">
                        <i class="fal fa-calendar mr-1 relative" aria-hidden="true" style="top: 1px"></i> {{ mission.start_time }}
                        <!-- <span data-v-c4c058b8="" class="end-time ml-1" style="font-size: 11px"> 10:03 </span> -->
                      </td>
                      <td class="text-left"><i class="fal fa-calendar mr-1 relative" aria-hidden="true" style="top: 1px"></i>{{ mission.end_time}}</td>
                      <td class="text-left">{{ mission.address }}</td>
                      <td class="text-left avatar-list">
                          <AgentProfile :profiles="mission.hired_staff"></AgentProfile>
                      </td>
                      <td class="text-center status">
                        <span :class="mission.status">{{ mission.status }}</span>
                      </td>
                      <td class="text-right">
                        <div class="action-btn d-flex justify-content-end">
                          <router-link :to="'/mission/details/' + mission.id" class="view-btn">
                          </router-link>
                          <router-link :to="'/mission/edit/' + mission.id" class="edit-btn">
                            <i class="fal fa-edit"></i>
                          </router-link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import AgentProfile from "./MissionAgentProfile.vue"
export default {
    components: {
      AgentProfile
    },
    mounted() {
        this.getMissionList()
    },
    data() {
        return {
            missionLists: [],
        }
    },
    methods: {
      getMissionList() {

        const api = 'admin/missions';
        axios
            .get(api,{
              params: {
                page:1,
                limit: 30,
                sort_column: 'id',
                sort_type: 'desc',
                status: 'history',
                agency_id: this.$route.params.id
              }
            })
            .then((response) => {
                this.missionLists = response.data.data.records
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
      },
    },
}
</script>