<template>
    <div class="col-md-12 pt-3 pr-0 pl-0">
        <div class="row">
            <div class="col-md-12 pt-2 pb-3">
                <div class="chart-wrapper animate p-4">
                    <div class="row">
                        <div class="col-md-12 relative text-right">
                            <h6 class="uk-position-top-left mt-3 ml-4 uk-text-bold"> Utilization (All Vehicles) </h6>
                            <div class="uk-float-right mr-4">
                                <ul class="uk-inline" uk-tab>
                                    <li class="uk-active">
                                    <a href="#" class="no-bullet uk-text-uppercase" @click="chartMissionType('Total')"> Total </a>
                                    </li>
                                    <li>
                                    <a href="#" class="no-bullet uk-text-uppercase" @click="chartMissionType('Commission')">
                                        Lorem Ipsum </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-12 mt-4 mb-3 chart">
                            <div class="chart">
                                <canvas id="mission-chart" height="400"></canvas>
                            </div>
                            <div class="descript pt-4">
                                <ul class="uk-inline">
                                    <li class="ml-3"> <i class="fad fa-circle mr-1" style="color: #d4e6f2;"></i> Lorem Ipsum </li>
                                    <li class="ml-3"> <i class="fad fa-circle mr-1" style="color: #7eb3d5;"></i> Lorem Ipsum </li>
                                    <li class="ml-3"> <i class="fad fa-circle mr-1" style="color: #2a80b9;"></i> Lorem Ipsum
                                    </li>
                                    <li class="ml-3"> <i class="fad fa-circle mr-1" style="color: #1e618c;"></i> Lorem Ipsum </li>
                                    <li class="ml-3"> <i class="fad fa-circle mr-1" style="color: #0c4070;"></i> Lorem Ipsum </li>
                                    <li class="ml-3"> <i class="fad fa-circle mr-1" style="color: #122230;"></i> Lorem Ipsum </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  mounted() {
    this.getDevices()
    /* ------- ChartJS > Mission ------ */

    new Chart(document.getElementById('mission-chart'), {
      type: 'bar',
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Bodyguard',
            backgroundColor: ['#d4e6f2'],
            data: [200, 300, 250, 500, 400, 450, 200, 250, 300, 400, 500, 200],
          },
          {
            label: 'Security Guard',
            backgroundColor: ['#7fb3d5'],
            data: [200, 300, 250, 500, 400, 450, 200, 250, 300, 400, 500, 200],
          },
          {
            label: 'Private Invertigate',
            backgroundColor: ['#2a80ba'],
            data: [200, 300, 250, 500, 400, 450, 200, 250, 300, 400, 500, 200],
          },
          {
            label: 'Education',
            backgroundColor: ['#1e628c'],
            data: [200, 300, 250, 500, 400, 450, 200, 250, 300, 400, 500, 200],
          },
          {
            label: 'Car Rental',
            backgroundColor: ['#0c4070'],
            data: [200, 300, 250, 500, 400, 450, 200, 250, 300, 400, 500, 200],
          },
          {
            label: 'VIP Service',
            backgroundColor: ['#122230'],
            data: [200, 300, 250, 500, 400, 450, 200, 250, 300, 400, 500, 200],
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        },
        legend: {
          display: false
        },
        plugins: {
          legend: {
            display: false
          }
        },
        showTooltips: false,
        title: {
          display: false,
          text: '',
        },
        inflateAmount: 'auto',
        barPercentage: 0.5,
        maintainAspectRatio: false,
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            from: 1,
            to: 0,
            loop: true
          }
        },
      },
    });

  },
  data(){
    return {
        deviceRegistration:{}
    }
  },
  methods: {
    getDevices() {
      const api = 'admin/dashboard/get_register_count'
      axios
        .get(api)
        .then((response) => {
          this.deviceRegistration = response.data.data
          console.log(response.data.data)
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
  }
}
</script>

<style scoped>
.dashboard.custom-bg-one {
    background: linear-gradient(269.78deg, #13234C -8.13%, #2E5278 64.96%);
}
</style>