<template>
    <div class="row align-items-center ">
        <div class="col-md-12">

            <!-- Schedule Class -->
          
            <div class="row">
                <div class="col-md-12 text-left">
                    <h5 class="mt-1 mb-2 uk-text-bold">
                        Schdule Your Class
                    </h5>
                    <hr />
                </div>
                <div class="col-sm-3 mb-2">
                    <div class="form-group mb-2">
                        <select name="" class="form-control">
                            <option value="" selected>20+</option>
                            <option value="">30+</option>
                        </select>
                        <label for="">Maximum number of attendees</label>
                    </div>
                </div>
            </div>
            
            <!-- date range -->
          
            <div class="row mt-2">
                <div class="col-md-12">
                    <h6 class="uk-text-uppercase">Schdule Course Date range</h6>
                </div>
                <div class="col-sm-3">
                    <div class="form-group mb-2">
                        <input type="date" class="form-control">
                        <label for="">From</label>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group mb-2">
                        <input type="date" class="form-control">
                        <label for="">Till</label>
                    </div>
                </div>
            </div>

            <!-- date range -->
        
            <div class="row p-2 mt-2">
                <div class="col-sm-12">
                    <p class="uk-text-uppercase">
                        Schdule Course Date range
                    </p>
                </div>
                <div class="col-sm-3">
                    <div class="form-group mb-2">
                        <label class="form_radio">
                            <input type="radio" value="Schedule" checked >
                            <span class="design"></span>
                            <span class="text">Schedule</span>
                        </label>
                    </div>
                </div>
                <div class="col-sm-9">
                    <div class="form-group mb-2">
                        <label class="form_radio">
                            <input type="radio" value="Schedule" >
                            <span class="design"></span>
                            <span class="text">Flexible (Allow client to custom schdule from their ends)</span>
                        </label>
                    </div>
                </div>

                <!-- if scheduled, show schedule list and form -->

                <div class="col-md-12">
                    <ScheduledList />
                </div>
            </div>

            <!-- Frequency -->

            <div class="row p-2 mt-2">
                <div class="col-md-12">
                    <p>Frequency</p>
                </div>
                <div class="col-sm-3">
                    <div class="form-group mb-4">
                        <label class="form_radio">
                            <input type="radio" value="Schedule" checked >
                                <span class="design"></span>
                                <span class="text">Sessions</span>
                        </label>
                    </div>
                    <div class="form-group mb-2">
                        <select name="" id="" class="form-control">
                            <option value="">20</option>
                        </select>
                        <label for="">No. of session</label>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group mb-2">
                        <label class="form_radio">
                            <input type="radio" value="Schedule" >
                                <span class="design"></span>
                                <span class="text">period of</span>
                        </label>
                    </div>
                </div>

                <!-- Prices -->
                <!-- If Selected Sessions -->
                <div class="col-md-12 mt-3">
                    <h5 class="mt-1 mb-2 uk-text-bold">Prices</h5>
                    <hr />
                </div>
                <div class="col-md-12">
                    <SessionPrices />
                </div>
            </div>



            <!-- Special option -->

            <div class="row p-2 special-option">
                <div class="col-md-12">
                    <p class="uk-text-uppercase uk-text-small">Special option</p>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-sm-6 col-md-9">
                            <p class="uk-text-uppercase mb-0">
                                Remote / home training
                            </p>
                        </div>
                        <div class="col-sm-6 col-md-3 text-right">
                            <label class="switch">
                                <input type="checkbox" checked />
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group mt-2">
                                <input type="text" value="2342423" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-sm-6 col-md-9">
                            <p class="uk-text-uppercase mb-0">
                                Rent Equipment
                            </p>
                        </div>
                        <div class="col-sm-6 col-md-3 text-right">
                            <label class="switch">
                                <input type="checkbox"  />
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group mt-2">
                                <input type="date" class="form-control">
                                <label for="">From</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-md-12">
            <hr />
        </div>
        <div class="col-md-6">
            <div style="display: inline-flex;float: left;">Activate</div>
            <label class="switch" style="position: relative;top: 1px;">
                <input type="checkbox" checked/>
                <span class="slider round"></span>
            </label>
        </div>
        <div class="col-md-6 text-right">
            <a href="#" class="cancel-btn"> <i class="fas fa-minus-circle mr-2"></i> Cancel </a>
            <button type="submit" id="client" class="save-btn ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
        </div>
        
    </div>
</template>
<script>

import ScheduledList from "./ScheduledList.vue";
import SessionPrices from "./SessionPrices.vue";
export default {
    components: {
        ScheduledList,
        SessionPrices
    }
}
</script>
<style scoped>
.special-option .switch {
    width: 35px;
    height: 18px;
    top: 1px;
}

.special-option .switch .slider:before {
    height: 15px;
    width: 15px;
    left: 0;
}
                    
.special-option .switch input:checked + .slider:before {
    left: -8px;
}
</style>