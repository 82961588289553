<template>
    <div class="col-md-7 pt-3">
        <div class="row">
            <div class="col-md-12 pt-2 pb-3">
                <div class="dashboard home animate relative custom-bg-one p-3 mb-3">
                    <div class="row">
                        <div class="col-md-8 text-left">
                            <h6 class="mt-3 mb-3 text-white"><i class="fal fa-globe mr-2"></i> Website </h6>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="pr-1 pl-1">
                                        <div class="value-box relative border-radius-default bg-white">
                                            <span class="icon">
                                                <i class="fas fa-user"></i>
                                            </span>
                                            <div class="content text-right">
                                                <span class="title uk-text-uppercase"> Visits </span>
                                                <span class="value"> 115 </span>
                                                <span class="uk-position-bottom-right mr-2 mb-1" style="color: green;">
                                                    <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>20%</small>
                                                </span>

                                                <!-------
                                                    
                                                    IF > UP
                                                    <span class="uk-position-bottom-right mr-2 mb-1" style="color: green;">
                                                    <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>20%</small>
                                                    </span>

                                                    IF > DOWN
                                                    <span class="uk-position-bottom-right mr-2 mb-1" style="color: red;">
                                                    <i uk-icon="icon: chevron-down; ratio: 0.7;" class="mr-1"></i><small>20%</small>
                                                    </span>


                                                    ------>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="pr-1 pl-1">
                                        <div class="value-box relative border-radius-default bg-white">
                                            <span class="icon">
                                                <i class="fad fa-user-tag"></i>
                                            </span>
                                            <div class="content text-right">
                                                <span class="title uk-text-uppercase"> Unique Visitors </span>
                                                <span class="value"> 50 </span>
                                                <span class="uk-position-bottom-right mr-2 mb-1" style="color: green;">
                                                    <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>20%</small>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="pr-1 pl-1">
                                        <div class="value-box relative border-radius-default bg-white">
                                            <span class="icon">
                                                <i class="fad fa-clock"></i>
                                            </span>
                                            <div class="content text-right">
                                                <span class="title uk-text-uppercase"> Average Visit Ouration </span>
                                                <span class="value"> 4m 34s </span>
                                                <span class="uk-position-bottom-right mr-2 mb-1" style="color: green;">
                                                    <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>20%</small>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="pr-1 pl-1">
                                        <div class="value-box relative border-radius-default bg-white">
                                            <span class="icon">
                                                <i class="fad fa-monitor-heart-rate"></i>
                                            </span>
                                            <div class="content text-right">
                                                <span class="title uk-text-uppercase"> Bounce Rates </span>
                                                <span class="value"> 22.54% </span>
                                                <span class="uk-position-bottom-right mr-2 mb-1" style="color: green;">
                                                    <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>20%</small>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="pr-1 pl-1">
                                        <div class="value-box relative border-radius-default bg-white">
                                            <span class="icon" style="background-color: #fd7173;">
                                                <i class="fad fa-user-minus"></i>
                                            </span>
                                            <div class="content text-right">
                                                <span class="title uk-text-uppercase"> Deleted Account </span>
                                                <span class="value"> 1 </span>
                                                <span class="uk-position-bottom-right mr-2 mb-1" style="color: green;">
                                                    <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>100%</small>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-12 text-left">
                                    <h6 class="mt-3 mb-3 text-white"><i class="fas fa-pen-alt mr-2"></i> Registration </h6>
                                </div>
                                <div class="col-md-12 border-radius-default pt-1 pb-1 pl-3 pr-2"
                                    style="background-color: rgba(255,255,255,0.8)">
                                    <div class="pt-1">
                                        <div class="value-box relative border-radius-default bg-white">
                                            <span class="icon">
                                                <i class="fal fa-browser"></i>
                                            </span>
                                            <div class="content text-right">
                                                <span class="title uk-text-uppercase"> Website </span>
                                                <span class="value"> {{deviceRegistration.web}} </span>
                                                <span class="uk-position-bottom-right mr-2 mb-1" style="color: green;">
                                                    <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>100%</small>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="value-box relative border-radius-default bg-white">
                                            <span class="icon">
                                                <i class="fa fa-apple"></i>
                                            </span>
                                            <div class="content text-right">
                                                <span class="title uk-text-uppercase"> IOS </span>
                                                <span class="value"> {{deviceRegistration.ios}} </span>
                                                <span class="uk-position-bottom-right mr-2 mb-1" style="color: green;">
                                                    <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>100%</small>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="value-box relative border-radius-default bg-white">
                                            <span class="icon">
                                                <i class="fa fa-android"></i>
                                            </span>
                                            <div class="content text-right">
                                                <span class="title uk-text-uppercase"> Android </span>
                                                <span class="value"> {{deviceRegistration.android}} </span>
                                                <span class="uk-position-bottom-right mr-2 mb-1" style="color: red;">
                                                    <i uk-icon="icon: chevron-down; ratio: 0.7;" class="mr-1"></i><small>100%</small>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row p-1 pt-2 pb-2 relative">
                                            <div class="col-md-6 col-sm-12">
                                                <span class="uk-inline uk-text-normal uk-text-bold uk-text-uppercase pl-2 mt-1" style="font-size: 14px;">
                                                    Total
                                                </span>
                                            </div>
                                            <div class="col-md-6 col-sm-12 uk-text-right pt-0">
                                                <span class="uk-inline mr-1 mb-2" style="color: red;">
                                                    <i uk-icon="icon: chevron-down; ratio: 0.7;" class="mr-1"></i><small>100%</small>
                                                </span>
                                                <span class="uk-text-normal uk-text-bold uk-text-primary mr-3" style="font-size: 20px;"> 
                                                    1
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">

                            <div class="row">
                                <div class="col-md-8 text-left">
                                    <h6 class="mt-3 mb-3 text-white"><i class="fas fa-mobile mr-2"></i> Mobile </h6>
                                </div>
                            </div>
                            <div class="row border-radius-default" style="background-color: rgba(255,255,255,0.8); padding: 5px 10px !important;">
                                <div class="col-md-12">
                                    <h6 class=" mb-0 pb-0 pt-0 mt-1" style="font-size: 11px;">Install</h6>
                                </div>
                                <div class="col-md-6 pt-0 pb-0 pl-3">
                                    <div class="value-box relative border-radius-default bg-white">
                                    <span class="icon">
                                        <i class="fa fa-apple"></i>
                                    </span>
                                    <div class="content text-right">
                                        <span class="title uk-text-uppercase"> IOS </span>
                                        <span class="value"> 1 </span>
                                        <span class="uk-position-bottom-right mr-2 mb-1" style="color: green;">
                                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>100%</small>
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6 pt-0 pb-0 pl-3">
                                    <div class="value-box relative border-radius-default bg-white">
                                    <span class="icon">
                                        <i class="fa fa-android"></i>
                                    </span>
                                    <div class="content text-right">
                                        <span class="title uk-text-uppercase"> Android </span>
                                        <span class="value"> 1 </span>
                                        <span class="uk-position-bottom-right mr-2 mb-1" style="color: green;">
                                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>100%</small>
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row relative mb-2 mt-0 pt-0">
                                    <div class="col-md-6 col-sm-12">
                                        <span class="uk-inline uk-text-normal uk-text-bold uk-text-uppercase pl-0 mt-1"
                                        style="font-size: 14px;">Total</span>
                                    </div>
                                    <div class="col-md-6 col-sm-12 uk-text-right pt-0">
                                        <span class="uk-inline mr-1 mb-2" style="color: red;">
                                        <i uk-icon="icon: chevron-down; ratio: 0.7;" class="mr-1"></i><small>100%</small>
                                        </span>
                                        <span class="uk-text-normal uk-text-bold uk-text-primary mr-0" style="font-size: 20px;"> 1 </span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix mt-3"></div>
                            <div class="row border-radius-default"
                            style="background-color: rgba(255,255,255,0.8); padding: 5px 10px !important;">
                                <div class="col-md-12">
                                    <h6 class=" mb-0 pb-0 pt-0 mt-1" style="font-size: 11px;">Uninstall</h6>
                                </div>
                                <div class="col-md-6 pt-0 pb-0 pl-3">
                                    <div class="value-box relative border-radius-default bg-white">
                                    <span class="icon">
                                        <i class="fa fa-apple"></i>
                                    </span>
                                    <div class="content text-right">
                                        <span class="title uk-text-uppercase"> IOS </span>
                                        <span class="value"> 1 </span>
                                        <span class="uk-position-bottom-right mr-2 mb-1" style="color: green;">
                                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>100%</small>
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6 pt-0 pb-0 pl-3">
                                    <div class="value-box relative border-radius-default bg-white">
                                    <span class="icon">
                                        <i class="fa fa-android"></i>
                                    </span>
                                    <div class="content text-right">
                                        <span class="title uk-text-uppercase"> Android </span>
                                        <span class="value"> 1 </span>
                                        <span class="uk-position-bottom-right mr-2 mb-1" style="color: green;">
                                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>100%</small>
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row relative mb-2 mt-0 pt-0">
                                        <div class="col-md-6 col-sm-12">
                                            <span class="uk-inline uk-text-normal uk-text-bold uk-text-uppercase pl-0 mt-1"
                                            style="font-size: 14px;">Total</span>
                                        </div>
                                        <div class="col-md-6 col-sm-12 uk-text-right pt-0">
                                            <span class="uk-inline mr-1 mb-2" style="color: red;">
                                                <i uk-icon="icon: chevron-down; ratio: 0.7;" class="mr-1"></i><small>100%</small>
                                            </span>
                                            <span class="uk-text-normal uk-text-bold uk-text-primary mr-0" style="font-size: 20px;"> 1 </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
       
                <div class="chart-wrapper animate p-4">
                    <div class="row">
                        <div class="col-md-12 relative text-right">
                            <h6 class="uk-position-top-left mt-3 ml-4 uk-text-bold"> Current On-going mission </h6>
                            <div class="uk-float-right mr-4">
                                <ul class="uk-inline" uk-tab>
                                    <li class="uk-active">
                                    <a href="#" class="no-bullet uk-text-uppercase" @click="chartMissionType('Total')"> Total </a>
                                    </li>
                                    <li>
                                    <a href="#" class="no-bullet uk-text-uppercase" @click="chartMissionType('Commission')">
                                        Commission </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-12 mt-4 mb-3 chart">
                            <div class="chart">
                                <canvas id="mission-chart" height="400"></canvas>
                            </div>
                            <div class="descript pt-4">
                                <ul class="uk-inline">
                                    <li class="ml-3"> <i class="fad fa-circle mr-1" style="color: #d4e6f2;"></i> Bodyguard </li>
                                    <li class="ml-3"> <i class="fad fa-circle mr-1" style="color: #7eb3d5;"></i> Security Guard </li>
                                    <li class="ml-3"> <i class="fad fa-circle mr-1" style="color: #2a80b9;"></i> Private Invertigate
                                    </li>
                                    <li class="ml-3"> <i class="fad fa-circle mr-1" style="color: #1e618c;"></i> Education </li>
                                    <li class="ml-3"> <i class="fad fa-circle mr-1" style="color: #0c4070;"></i> Car Rental </li>
                                    <li class="ml-3"> <i class="fad fa-circle mr-1" style="color: #122230;"></i> VIP Service </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
  mounted() {
    this.getDevices()
    /* ------- ChartJS > Mission ------ */

    new Chart(document.getElementById('mission-chart'), {
      type: 'bar',
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Bodyguard',
            backgroundColor: ['#d4e6f2'],
            data: [200, 300, 250, 500, 400, 450, 200, 250, 300, 400, 500, 200],
          },
          {
            label: 'Security Guard',
            backgroundColor: ['#7fb3d5'],
            data: [200, 300, 250, 500, 400, 450, 200, 250, 300, 400, 500, 200],
          },
          {
            label: 'Private Invertigate',
            backgroundColor: ['#2a80ba'],
            data: [200, 300, 250, 500, 400, 450, 200, 250, 300, 400, 500, 200],
          },
          {
            label: 'Education',
            backgroundColor: ['#1e628c'],
            data: [200, 300, 250, 500, 400, 450, 200, 250, 300, 400, 500, 200],
          },
          {
            label: 'Car Rental',
            backgroundColor: ['#0c4070'],
            data: [200, 300, 250, 500, 400, 450, 200, 250, 300, 400, 500, 200],
          },
          {
            label: 'VIP Service',
            backgroundColor: ['#122230'],
            data: [200, 300, 250, 500, 400, 450, 200, 250, 300, 400, 500, 200],
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        },
        legend: {
          display: false
        },
        plugins: {
          legend: {
            display: false
          }
        },
        showTooltips: false,
        title: {
          display: false,
          text: '',
        },
        inflateAmount: 'auto',
        barPercentage: 0.5,
        maintainAspectRatio: false,
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            from: 1,
            to: 0,
            loop: true
          }
        },
      },
    });

  },
  data(){
    return {
        deviceRegistration:{}
    }
  },
  methods: {
    getDevices() {
      const api = 'admin/dashboard/get_register_count'
      axios
        .get(api)
        .then((response) => {
          this.deviceRegistration = response.data.data
          console.log(response.data.data)
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
  }
}
</script>

<style scoped>
.dashboard.custom-bg-one {
    background: linear-gradient(269.78deg, #13234C -8.13%, #2E5278 64.96%);
}
</style>