<template>
  <div>
    <!----- Header Title ----->
    <section class="row">
      <Header />
    </section>

    <!----- * TABS * ----->
    <TabList />
    
    <section class="tpui-container">
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            

            <!-- Company Profile -->
            <EditProfile v-if="companyDetail.id" :companyDetail="companyDetail" />

            <!---- Contact Person ---->
            <EditContactPerson v-if="companyDetail.id" :companyDetail="companyDetail" />
          </div>
        </div>
      </div>
    </section>

    <EditCompanyAddress v-if="companyDetail.id" :companyDetail="companyDetail" />
    <EditBank v-if="companyDetail.id" :companyDetail="companyDetail" />
    <EditDBD />
    <EditLegal :company="companyDetail" />
    <EditActivityLog />
    
  </div>
</template>

<script>
import TabList from "./TabList.vue"
import EditProfile from "./EditProfile.vue";
import EditContactPerson from "./EditContactPerson.vue";
import EditCompanyAddress from "./EditCompanyAddress.vue"
import EditBank from "./EditBank.vue"
import EditDBD from "./EditDBD.vue"
import EditLegal from "./EditLegal.vue"
import EditActivityLog from "./EditActivityLog.vue"

export default {
    components: {
        TabList,
        EditProfile,
        EditContactPerson,
        EditCompanyAddress,
        EditBank,
        EditDBD,
        EditLegal,
        EditActivityLog
    },
    data() {
        return {
            companyDetail: {
            },
            owners: [],
            id: this.$route.params.id,
        }
    },
    mounted() {


        this.getCompanyDetail()

        $(function () {
            $('#servicearea').bsMultiSelect({
            containerClass: 'dashboardcode-bsmultiselect-large',
            })
        });

        $('#skill').bsMultiSelect({
            containerClass: 'dashboardcode-bsmultiselect-large',
        });

        // Upload
        var bar = document.getElementById('js-progressbar');

        UIkit.upload('.js-upload', {

            url: '',
            multiple: true,

            beforeSend: function () {
                console.log('beforeSend', arguments);
            },
            beforeAll: function () {
                console.log('beforeAll', arguments);
            },
            load: function () {
                console.log('load', arguments);
            },
            error: function () {
                console.log('error', arguments);
            },
            complete: function () {
                console.log('complete', arguments);
            },

            loadStart: function (e) {
                console.log('loadStart', arguments);

                bar.removeAttribute('hidden');
                bar.max = e.total;
                bar.value = e.loaded;
            },

            progress: function (e) {
                console.log('progress', arguments);

                bar.max = e.total;
                bar.value = e.loaded;
            },

            loadEnd: function (e) {
                console.log('loadEnd', arguments);

                bar.max = e.total;
                bar.value = e.loaded;
            },

            completeAll: function () {
                console.log('completeAll', arguments);

                setTimeout(function () {
                    bar.setAttribute('hidden', 'hidden');
                }, 1000);

                alert('Upload Completed');
            }

        });

    },
    methods: {
        isRequired(key) {
            if (this.requiredfileds.hasOwnProperty(key)) {
            return '*'
            } else {
            return ''
            }
        },
        getCompanyDetail() {
            const api = 'admin/agencies/' + this.$route.params.id
            axios
            .get(api)
            .then((response) => {
                if(!response.data.success) {
                this.$router.push('/404')
                }
                this.companyDetail = response.data.data
                this.owners = response.data.data.owners
                console.log(this.companyDetail)
                this.title()
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
        },
        title() {
            this.$route.meta.title = this.companyDetail.name_en
        },
        formatTHB(num) {
            return '฿' + Number(num).toLocaleString()
        },
        updateAddress(addressFormData) {
                console.log('parent component')
            console.log(addressFormData)
            
            let address = {
            lat: addressFormData.lat,
            lng: addressFormData.lng,
            country : addressFormData.country,
            address : addressFormData.address,
            province : addressFormData.province,
            sub_district : addressFormData.sub_district,
            district : addressFormData.district,
            zip_code : addressFormData.zip_code,
            company_country : addressFormData.country,
            company_address : addressFormData.address,
            company_province : addressFormData.province,
            company_sub_district : addressFormData.sub_district,
            company_district : addressFormData.district,
            company_zip_code : addressFormData.zip_code,
            website: addressFormData.website,

            contact_number: '663222222',
            contact_person: this.companyDetail.contact_person,
            name_en: this.companyDetail.name_en,
            registration_id: this.companyDetail.registration_id,
            tax_id: this.companyDetail.tax_id

            }

                const apiUrl = 'admin/agencies/' + this.$route.params.id + '/company'
                axios
            .post(apiUrl, address)
            .then((response) => {

                if(response.data.success) {
                    this.success()
                }else{
                    this.requiredfileds = response.data
                }
                
            })
            .catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error)
            })
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },

    },

}
</script>
