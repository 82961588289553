<template>
    <div>
        <!----- Header Title ----->
        <section class="row">
        <Header />
        </section>

        <!----- * TABS * ----->
        <TabList />
        
        <section class="tpui-container">
            <div class="row align-items-center mb-4">
                <div class="col-lg-12">
                    <div class="grey-box p-3 pt-4">
                        <Rating />
                        <Form />
                       
                    </div>
                </div>
            </div>
        </section>
      
    </div>
</template>

<script >
import TabList from "../../Edit/TabList.vue"
import Rating from "./Rating.vue";
import Form from "./Form.vue";

export default {
    components: {
        TabList,
        Rating,
        Form,
       
    },
    data() {
        return {
           
        }
    },
    mounted() {

        // this.getClassDetail()
    },
    methods: {
        getClassDetail() {
            const api = 'admin/agencies/' + this.$route.params.id
            axios
            .get(api)
            .then((response) => {
                if(!response.data.success) {
                this.$router.push('/404')
                }
                this.companyDetail = response.data.data
                this.owners = response.data.data.owners
                console.log(this.companyDetail)
                this.title()
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
        },
    },

}
</script>
