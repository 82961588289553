<template>
    <form @submit.prevent="submitLocation" v-if="formData.address" >
        <div class="row google-map">
            <div class="col-sm-12 mb-4">
                <div v-if="center.lat" class="gmap-iframe ratio ratio-4x3 ratio-sm-21x9" lc-helper="gmap-embed">
                    <gmap-map
                        :zoom="16"    
                        :center="center"
                        style="width:100%;  height: 600px;"
                    >
                        <gmap-marker
                            v-for="(m, index) in locationMarkers"
                            :key="index"
                            :position="m.position"
                            @click="center=m.position"
                        ></gmap-marker>
                    </gmap-map>
                </div>
            </div>
            <div class="col-md-12">
                <div class="uk-margin">
                    <div class="uk-inline uk-width-1-1">
                        <span class="uk-form-icon" uk-icon="icon: location"></span>
                        <gmap-autocomplete @place_changed="addLocationMarker" placeholder="" class="uk-input" required></gmap-autocomplete>
                        <!-- <input type="hidden" v-model="formData.address"> -->
                        <label class="ml-5"> Address </label>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group select-box">
                    <input type="text" name="country" v-model="formData.country" class="form-control" required>
                    <label>Country</label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group select-box">
                    <input type="text" name="province" v-model="formData.province" class="form-control" required>
                    <label>Province</label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <input type="text" name="district" v-model="formData.district" class="form-control" required>
                    <label>District</label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <input type="text" name="sub_district" v-model="formData.sub_district" class="form-control">
                    <label>Sub District</label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <input type="text" name="zip_code" v-model="formData.zip_code" class="form-control" required>
                    <label> Zip code </label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <input type="url" name="website" v-model="formData.website" class="form-control" required>
                    <label> Website </label>
                </div>
            </div>
            <div class="col-md-6 text-right">
                <div role="group" aria-label="Basic example" class="btn-group mt-2">
                    <a href="#" class="cancel-btn"> <i class="fas fa-minus-circle mr-2"></i> Cancel </a>
                    <button type="submit" id="client" class="save-btn ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
                </div>
            </div>
        </div>
    </form>
</template>
 
<script>
export default {
    props: {
        addressObj: {
            lat: Number,
            lng: Number,
            address: String,
            sub_district: String,
            district: String,
            province: String,
            country: String,
            zip_code: Number,
            parent_company: {
                lat: Number,
                lng: Number,
                address: String,
                sub_district: String,
                district: String,
                province: String,
                country: String,
                zip_code: Number,
            }
        }
    },
    data() {
        return {
            formData: {
                lat: 0,
                lng: 0,
                address: '',
                sub_district: '',
                district: '',
                province: '',
                country: '',
                zip_code: null,
                website: '',

            },
            center: { 
                lat: 0,
                lng: 0,
            },
            locationMarkers: []
        };
    },
    mounted() {
        console.log(this.addressObj)
        this.setFormData()
    },
    methods: {
        setFormData() {

            this.formData = {
                lat: this.addressObj.parent_company? this.addressObj.parent_company.lat : this.addressObj.lat,
                lng: this.addressObj.parent_company? this.addressObj.parent_company.lng : this.addressObj.lng,
                address: this.addressObj.parent_company? this.addressObj.parent_company.address : this.addressObj.address,
                sub_district: this.addressObj.parent_company? this.addressObj.parent_company.sub_district : this.addressObj.sub_district,
                district: this.addressObj.parent_company? this.addressObj.parent_company.district : this.addressObj.district,
                province: this.addressObj.parent_company? this.addressObj.parent_company.province : this.addressObj.province,
                country: this.addressObj.parent_company? this.addressObj.parent_company.country : this.addressObj.country,
                zip_code: this.addressObj.parent_company? this.addressObj.parent_company.zip_code : this.addressObj.zip_code,
                website: this.addressObj.parent_company? this.addressObj.parent_company.website : ''
            }
            this.setMapLocaiton()
        },
        setMapLocaiton() {
            // navigator.geolocation.getCurrentPosition(res => {
                // console.log(res.coords.latitude)
            this.center.lat = parseFloat(this.formData.lat)
            this.center.lng = parseFloat(this.formData.lng)
              
            // });

            this.locationMarkers = []
            this.locationMarkers.push({ position: this.center })

            console.log(this.center)
            console.log(this.locationMarkers)
        },
        addLocationMarker(location) {
            console.log(location)
            this.formData = {}
            if (location) {
                this.center = {
                    lat: location.geometry.location.lat(),
                    lng: location.geometry.location.lng()
                };
                this.locationMarkers = []
                this.locationMarkers.push({ position: this.center })
                this.formData.address = location.formatted_address
                this.formData.lat = location.geometry.location.lat()
                this.formData.lng = location.geometry.location.lng()
                this.formData.website = location.website
                location.address_components.forEach(address => {
                    if(address.types.includes('country')) {
                        this.formData.country = address.long_name
                    }
                    else if(address.types.includes('postal_code')) {
                        this.formData.zip_code = address.long_name
                    }
                    else if(address.types.includes('locality')) {
                        this.formData.province = address.long_name
                    }
                    else if(address.types.includes('administrative_area_level_1') || address.types.includes('sublocality_level_1')) {
                        this.formData.district = address.long_name
                    }
                    else if(address.types.includes('administrative_area_level_2') || address.types.includes('sublocality_level_2')) {
                        this.formData.sub_district = address.long_name
                    }
                });
            }
        },

        submitLocation() {
            console.log('google map submit location')
            this.$emit('submitLocation', this.formData)
        }
    }
};
</script>

<style scoped>
    .google-map .save-btn{
        border: 0px;
    }
</style>