<template>
    <div class="mt-4">
        <div class="col-md-12 mt-2 mb-3">
            <hr />
            <h6 class="uk-text-uppercase pl-1 uk-text-bold">
                <i class="fas fa-user mr-2"></i> Ultimate Beneficial Owner & Other
            </h6>
        </div>
        <div class="col-md-12 mb-3">
            <div class="table-responsive">
                <table class="table data-table table-round table-hover table-status">
                    <thead>
                        <tr>
                            <th width="15%" class="text-left">Name</th>
                            <th width="15%" class="text-left">Nationality</th>
                            <th width="15%" class="text-left">Position</th>
                            <th width="15%" class="text-left">Percentage of Share</th>
                            <th width="15%" class="text-left">Beneficial Partner</th>
                            <th width="15%" class="text-left">Passport / ID Card Photo</th>
                            <th class="blankhead" width="100px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(owner, ownerIndex) in ownerLists" :key="ownerIndex" :class="tableClass(ownerIndex)">
                            <td class="text-left">
                                <div class="form-group mb-0 pb-0">
                                    <input type="text" v-model="ownerLists[ownerIndex].name" class="form-control input-tbl-style" required />
                                </div>
                            </td>
                            <td class="text-left">
                                <input type="text" name="nationality" v-model="ownerLists[ownerIndex].nationality" class="form-control input-tbl-style" required />
                            </td>
                            <td class="text-left">
                                <input type="text" name="position" v-model="ownerLists[ownerIndex].position" class="form-control input-tbl-style" required />
                            </td>
                            <td class="text-left">
                                <input type="text" name="percentage_of_share" v-model="ownerLists[ownerIndex].percentage_of_share" class="form-control input-tbl-style" required />
                            </td>
                            <td class="text-left">
                                <div class="form-group select-dropdown uk-inline uk-float-left select-tbl-style">
                                    <select name="is_benificial_partner" 
                                        v-model="ownerLists[ownerIndex].is_benificial_partner" 
                                        class="form-control pt-0 mr-2 benificial_partner"
                                        required="required" >
                                        <option value="1" >Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="profile-img position-relative text-center mb-2">
                                    <span class="edit-btn">
                                        <input
                                        type="file"
                                        name="id_card"
                                        class="file-upload"
                                        @change="uploadPassport($event.target.files, ownerIndex)"
                                        required
                                        />
                                        <i class="fal fa-pen upload-button"></i>
                                    </span>
                                    <img :src="owner.id? owner.id_card : url" />
                                </div>
                                <p v-if="!owner.id_card && !url" class="pt-0 pb-0 mt-0 mb-0 uk-text-uppercase uk-text-danger">Not Upload</p>
                            </td>
                            <td class="text-right">
                                <span v-if="owner.id_card" uk-lightbox>
                                    <a :href="owner.id_card" class="view-btn clickable only-icon pl-2 pr-2"></a>
                                </span>
                            
                                <a @click="updateOwner(owner.id, ownerIndex)" class="save-btn ml-2 mt-2" >
                                    <i class="fal fa-save"></i>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="7">
                            <a @click="addMore"
                                href="#"
                                class="
                                save-btn
                                sendmail-btn
                                border-0
                                mt-0
                                text-center
                                border-radius-default
                                uk-text-primary uk-text-uppercase
                                "
                                style="height: auto; width: auto !important; text-align: left !important" >
                                <i class="fas fa-plus mr-1" aria-hidden="true" style="font-size: 12px; display: block"></i>
                                Add more
                            </a>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</template>


<script>
    export default {

        props: {
            companyDetail: Object,
            owners: Array
        },
        data() {
            return {
                ownerLists: [],
                requiredfileds:[],
                url:''
            }
        },
        mounted() {

            this.ownerLists = this.owners
        },
        methods: {
        
            updateOwner(ownerId, index) {
                this.loading()
                const api = ownerId? 'admin/agencies/owner/' + ownerId : 'admin/agencies/' + this.companyDetail.id +'/owner'
                axios
                    .post(api, this.getFormData(index))
                    .then((response) => {
                        console.log(response.data)
                        if(response.data.success) {
                            this.success()
                        }else{
                            this.requiredfileds = response.data
                        }
                    })
                    .catch((error) => {
                        console.error('There was an error!', error.message)
                    })
            },
            getFormData(index) {

                let owner = this.ownerLists[index]
                let formData = new FormData();

                formData.append('name', owner.name)
                formData.append('nationality', owner.nationality)
                formData.append('position', owner.position)
                formData.append('percentage_of_share', owner.percentage_of_share)
                formData.append('is_benificial_partner', owner.is_benificial_partner)
                formData.append('id_card', owner.id_card)
                formData.append('is_key_profile', owner.is_key_profile)

                return formData
            },
            loading() {
                this.$toast.loading({
                    message: 'Loading...',
                    forbidClick: true,
                })
            },
            uploadPassport(file, index) {
                this.ownerLists[index].id_card = file[0]
                this.url = URL.createObjectURL(file[0])
            },
            addMore() {
                this.ownerLists.push({is_benificial_partner:1})
            },
            tableClass(index) {
                let searchIndex = index%3

                if(index == 0) {
                    return 'warning'
                }
                else if(index > 0 && searchIndex == 0 ) {
                    return 'warning'
                }
                else if(index > 0 && searchIndex == 1 ) {
                    return 'success'
                }
                else {
                    return 'danger'
                }
            },
            success() {
                var notyf = new Notyf();
                    notyf.confirm('Successfully Updated!');
            },
        }

    }
</script>

<style scoped>
    .profile-img {
        height: 50px;
        width: 50px;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
    }
    .edit-btn .fa-pen{
        font-size: 10px;
    }
    .save-btn {
        height: 30px;
        padding: 5px 10px !important;
    }
    .benificial_partner {
        font-size: 12px; 
        min-width: 150px
    }
</style>