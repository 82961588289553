<template>
    <div class="table-responsive">
        <table class="table data-table table-round table-hover">
            <thead>
                <tr>
                    <th scope="col" width="10%">Billing ID</th>
                    <th scope="col" width="20%" colspan="2">TO</th>
                    <th scope="col" width="10%" class="text-center">Status</th>
                    <th scope="col" width="10%" class="text-right">AMOUNT</th>
                    <th scope="col" width="15%" class="text-right">DATE/TIME</th>
                    <th scope="col" width="auto">Remark</th>
                    <th scope="col" width="12%" class="text-right"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(payment, paymentIndex) in payments" :key="paymentIndex">
                    <td class="text-left pl-3 pr-3"> 
                        {{ payment.billing_id }}
                    </td>
                    <td class="text-left mr-2" style="max-width: 60px;"> 
                        <img :src="payment.agency_logo" class="uk-border-circle" width="50px" height="50px"> 
                    </td>
                    <td class="text-left pl-2 pr-3">
                        <a href="#"> 
                            {{ payment.agency_name }} 
                        </a>
                    </td>
                    <td class="text-center pl-2 pr-3 status">  
                        <span :class="payment.payment_status">
                            {{ payment.payment_status }}
                        </span>
                    </td>
                    <td class="text-right pl-3 pr-2"> 
                        {{ payment.total + ' ' + payment.currency }} 
                    </td>
                    <td class="time text-right pl-3 pr-3" style="font-size: 12px;">
                        <i class="fal fa-calendar mr-1 relative" style="top: 1px;"></i> 
                        {{ payment.created_at | splitDate }}
                        <span class="end-time ml-1" style="font-size: 11px;"> 
                            {{ payment.created_at | splitTime }}
                        </span>
                    </td>
                    <td class="text-left pl-2 pr-3 status">  
                        {{ payment.remark }}
                    </td>
                    <td class="text-right">
                        <a @click="downloadPdf(paymentIndex)" class="download-btn clickable uk-text-uppercase display-inline-flex mr-1" href="#">
                            <i class="fas fa-arrow-to-bottom mr-2"></i> Download PDF
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>      
</template>

<script>
export default {
    props: {
        payments: Array
    },
    data() {
        return {
            paymentLists: [],
        }
    },
    mounted() {
        this.paymentLists = this.payments
    },
    methods: {
        downloadPdf(index) {

        },
        redirectList() {
            this.$router.push('/customer');
        },
    }
}
</script>