<template>
  <li>
    <a class="uk-accordion-title" href="#"> 
      <span uk-icon="info" class="mr-2 relative" style="top: -2px;"></span> Bodyguards Information
    </a>
    <div class="uk-accordion-content">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="grey-box">
            <h6 class="p-2 mt-2 mb-3 uk-text-bold"> 
              <i class="fas fa-user-tie mr-2"></i> Bodyguards Information 
            </h6>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group icon-field">
                  <i class="fas fa-user-tie"></i>
                  <input type="text" name="size_male_staff" class="form-control" v-model="company.size_male_staff" required />
                  <label> Number of male Bodyguards</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group icon-field">
                  <i class="fas fa-female" style="font-size: 17px;"></i>
                  <input type="text" name="size_female_staff" class="form-control" v-model="company.size_female_staff" required />
                  <label> Number of Female Bodyguards</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group icon-field">
                  <i class="far fa-globe-americas"></i>
                  <input type="text" name="size_foreigner_staff" class="form-control" v-model="company.size_foreigner_staff" required />
                  <label>Number of Foreinger Bodyguards</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group icon-field">
                  <span class="img"><img :src="'/assets/img/th_flag.png'" class="mr-3" alt="Dash Ico" /> </span>
                  <input type="text" name="size_local_staff" class="form-control" v-model="company.size_local_staff" required />
                  <label>Number of Thai Bodyguards</label>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col-md-6">
                <div class="form-group icon-field">
                  <span class="img"><img :src="'/assets/img/th_flag.png'" class="mr-3" alt="Dash Ico" /> </span>
                  <input type="text" name="size_armed_staff" class="form-control" v-model="company.size_armed_staff" required />
                  <label>Number of Armed Staff</label>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group icon-field">
                  <span class="img"><img :src="'/assets/img/th_flag.png'" class="mr-3" alt="Dash Ico" /> </span>
                  <input type="text" name="no_of_security_dog" class="form-control" v-model="company.no_of_security_dog" required />
                  <label>Number of Security Dog</label>
                </div>
              </div>

              <!-- <div class="col-md-6 mb-3 mb-md-0">
                <div>Armed Bodyguards</div>
                <div class="form-group">
                    <div class="row">
                  <div class="col-md-6 mb-5">
                    <label class="form_radio">
                      <input type="radio" name="size_armed_staff" id="size_armed_staff_yes" v-model="sizeArmedStaff" v-bind:value="armedStaffActive" :checked="sizeArmedStaff == armedStaffActive"  >
                        <span class="design"></span>
                        <span class="text">Yes</span>
                    </label>
                  </div>
                  <div class="col-md-6 mb-5">
                    <label class="form_radio">
                      <input type="radio" name="size_armed_staff" id="size_armed_staff_no" v-model="sizeArmedStaff" v-bind:value="armedStaffInActive" :checked="sizeArmedStaff == armedStaffInActive"  >
                      <span class="design"></span>
                      <span class="text">No</span>
                    </label>
                  </div>
                </div>
              </div> -->

              <!-- <div class="col-lg-6 col-md-6">
                <h6>Armed Bodyguards</h6>
                <div>
                  <div class="switches-container mt-2">
                    <input type="radio" id="question_1_yes" name="question_1[type]" value="active" checked="checked" onclick="companyType()" required />
                    <input type="radio" id="question_1_no" name="question_1[type]" value="inactive" onclick="companyType()" required />
                    <label for="question_1_yes" id="question_1_yes"> Yes </label>
                    <label for="question_1_no" id="question_1_no"> No </label>
                    <div class="switch-wrapper">
                      <div class="switch">
                        <div id="question_1_yes"> Yes </div>
                        <div id="question_1_no"> No </div>
                      </div>
                    </div>
                  </div> -->
                <!-- </div>
              </div> -->
              <div class="col-md-6 mb-3 mb-md-0">
                <div>Trained for Group</div>
                <div class="form-group">
                    <div class="row">
                  <div class="col-md-6 mb-5">
                    <label class="form_radio">
                      <input type="radio" name="trained_for_group" id="trained_for_group_yes" v-model="trainedForGroup" v-bind:value="groupActive" :checked="trainedForGroup == groupActive" @click="groupActiveFun"  >
                        <span class="design"></span>
                        <span class="text">Yes</span>
                    </label>
                  </div>
                  <div class="col-md-6 mb-5">
                    <label class="form_radio">
                      <input type="radio" name="trained_for_group" id="trained_for_group_no" v-model="trainedForGroup" v-bind:value="groupInActive" :checked="trainedForGroup == groupInActive" @click="groupActiveFun"  >
                      <span class="design"></span>
                      <span class="text">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>

  export default {
    props: {
        company: {
          size_male_staff: Number,
          size_female_staff : Number,
          size_foreigner_staff: Number,
          size_local_staff: Number,
          size_armed_staff: Number,
          no_of_security_dog: Number,
          trained_for_group: Boolean,
          // question_1: Array,
          // question_2: Array,
        }
    },
    data() {
      return {
        requiredfileds: {},

        trainedForGroup: 1,
        groupActive: 1,
        groupInActive : 0,
      }
    },

    mounted() {
        this.trainedForGroup = this.company.trained_for_group? this.groupActive : this.groupInActive
    },
    methods: {
       
        groupActiveFun() {
            this.company.trained_for_group = !this.company.trained_for_group
            this.trainedForGroup = this.company.trained_for_group? this.groupActive : this.groupInActive
        },
       
    }

  }
</script>