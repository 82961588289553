<template>
  <div>
    <!-- Header Title -->
    <div class="row">
      <div class="col-md-6">
        <div>
          <h3 class="page-title mb-1">
            {{ this.$route.meta.title }}
          </h3>
        </div>
        <div class="breadcrumb-container">
          <ul class="uk-breadcrumb">
            <li><span>Admin</span></li>
            <li><a href="/vehicles">Vehicle</a></li>
            <li><a href="">{{ this.$route.meta.title }}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 relative"></div>
    </div>

    <div class="row align-items-center mb-4">
      <div class="col-lg-12">
        <div class="grey-box p-3 pt-4">
          <form method="post">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-calendar mr-2"></i> Vehicle Overview</h5>
              </div>
            </div>
            <div class="view-ui mt-4 mb-2">
              <div class="row">
                <div class="col-md-6">
                  <h6 style="font-weight: 600; color: #111;margin-top: 10px;"> <span uk-icon="upload"></span> Upload
                    the vehicle photo </h6>
                  <div class="js-upload uk-placeholder uk-text-center mr-2">
                    <div class="mb-2 mt-2">
                      <span class="uk-link btn-upload"> Upload </span>
                    </div>
                    <span uk-icon="icon: cloud-upload" class="mr-2"></span>
                    <span class="uk-text-middle"> Drag & Drop file </span>
                    <div uk-form-custom>
                      <span class="edit-btn">
                        <input type="file" class="file-upload" id="file" ref="file" v-on:change="handleFileUpload()" />
                        <i class="fal fa-pen upload-button"></i> Change
                      </span>
                      <img :src="vehicle_photo ? vehicle_photo : ''" alt="Profile" uk-cover>
                    </div>
                  </div>
                  <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                </div>
                <div class="col-md-6">
                  <!-- URL PATH BUT HIDDEN -->
                  <div class="form-group" hidden>
                    <input type="text" class="form-control" value="" required>
                    <label>URL Path</label>
                  </div>
                </div>
                <!-- Add other vehicle overview fields -->
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="vin" class="form-control" value="" required>
                    <label>Vin</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="license_plate" class="form-control" value="" autocomplete="off" required>
                    <label>License Plate</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="make" class="form-control" value="" autocomplete="off" required>
                    <label>Vehicle Make</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="model" class="form-control" value="" autocomplete="off" required>
                    <label>Vehicle Model</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="car_class" class="form-control" value="" autocomplete="off" required>
                    <label>Vehicle Class</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="year_model" class="form-control" value="" autocomplete="off" required>
                    <label>Year</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="color" class="form-control" value="" autocomplete="off" required>
                    <label>Color</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <select class="form-control" v-model="doors" autocomplete="off" required>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                    <label>Doors</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="media" class="form-control" value="" autocomplete="off" required>
                    <label>Media</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="price" class="form-control" value="" autocomplete="off" required>
                    <label>Price</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="date" v-model="available_date" class="form-control" value="" autocomplete="off" required>
                    <label>Available Date</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="date" v-model="available_until" class="form-control" value="" autocomplete="off"
                      required>
                    <label>Available Until</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="odometer" class="form-control" value="" autocomplete="off" required>
                    <label>Odometer</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="fuel_level" class="form-control" value="" autocomplete="off" required>
                    <label>Fuel Level</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <p class="mb-0 uk-text-uppercase">Is available?</p>
                  <div class="row pb-5 pl-1">
                    <div class="col-md-3">
                      <label class="form_radio mt-0">
                        <input type="radio" v-model="is_available" value="1" name="is_availableOpt">
                        <span class="design"></span>
                        <span class="text">Yes</span>
                      </label>
                    </div>
                    <div class="col-md-3">
                      <label class="form_radio mt-0">
                        <input type="radio" v-model="is_available" value="0" name="is_availableOpt">
                        <span class="design"></span>
                        <span class="text">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <p class="mb-0 uk-text-uppercase">Air Conditioned?</p>
                  <div class="row pb-5 pl-1">
                    <div class="col-md-3">
                      <label class="form_radio mt-0">
                        <input type="radio" value="yes" name="airconditionedOpt">
                        <span class="design"></span>
                        <span class="text">Yes</span>
                      </label>
                    </div>
                    <div class="col-md-3">
                      <label class="form_radio mt-0">
                        <input type="radio" value="no" name="airconditionedOpt">
                        <span class="design"></span>
                        <span class="text">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="fleet" class="form-control" value="" autocomplete="off" required>
                    <label>Fleet</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <select class="form-control" v-model="status" required>
                      <option value="Available">Available</option>
                      <option value="Dirty">Dirty</option>
                      <option value="Returned">Returned</option>
                      <option value="Out of Service">Out of Service</option>
                      <option value="On Sale">On Sale</option>
                      <option value="Sold">Sold</option>
                      <option value="Complementary">Complementary</option>
                      <option value="New">New</option>
                      <option value="Reserved">Reserved</option>
                      <option value="Stolen">Stolen</option>
                    </select>
                    <label>Status</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="current_renter" class="form-control" value="" autocomplete="off" required>
                    <label>Current Renter</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="current_location" class="form-control" value="" autocomplete="off"
                      required>
                    <label>Current Location</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" v-model="loc_lat" autocomplete="off" required>
                    <label>Location Latitude</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" v-model="loc_long" value="" autocomplete="off" required>
                    <label>Location Longtitude</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" v-model="transmission" class="form-control" value="" autocomplete="off" required>
                    <label>Transmission</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- Fleet -->
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-car mr-2"></i> Fleet</h5>
              </div>
            </div>
            <div class="row pb-4 pt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" v-model="hardware_id" class="form-control" value="" autocomplete="off" required>
                  <label>Hardware ID</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" v-model="coolant_temperature_alarm_threshold" class="form-control" value=""
                    autocomplete="off" required>
                  <label>Threshold to Trigger Coolant Temperature Alarm</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" v-model="low_battery_alarm_threshold" class="form-control" value=""
                    autocomplete="off" required>
                  <label>Min Battery Voltage to Trigger Low Battery Alarm</label>
                </div>
              </div>
            </div>
            <!-- Depreciation Input -->
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-car mr-2"></i> Depreciation Input</h5>
              </div>
            </div>
            <div class="row pb-4 pt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" v-model="purchase_price" class="form-control" value="" autocomplete="off" required>
                  <label>Purchase Price</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" v-model="expected_rest_value" class="form-control" value="" autocomplete="off"
                    required>
                  <label>Expected Rest Value</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" v-model="odometer_at_purchase" class="form-control" value="" autocomplete="off"
                    required>
                  <label>Odometer at Purchase</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="date" v-model="purchase_date" class="form-control" value="" autocomplete="off" required>
                  <label>Purchase Date</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" v-model="depreciation_time_months" class="form-control" value="" autocomplete="off"
                    required>
                  <label>Depreciation Time (In Months)</label>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="col-md-12 text-right pt-0 mt-0 mb-4">
              <button href="#" id="client" class="save-btn border-0 ml-2 mr-2" @click="createVehicle">
                <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Create success Modal -->
    <div id="createmodal" uk-modal>
      <div class="uk-modal-dialog uk-modal-body p-2 text-center">
        <button class="uk-modal-close-outside" type="button" uk-close></button>
        <div class="row align-items-center mb-4">
          <div class="col-md-12 mt-4">
            <h4>Create customer successful!</h4>
          </div>
        </div>
        <a @click="closeModal" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
  
    return {
      file: null,
      vehicle_photo: null,
      vin: '',
      color: '',
      year_model: '',
      url_path: '',
      license_plate: '',
      current_renter: '',
      model: '',
      make: '',
      car_class: '',
      location: '',
      loc_lat: '',
      price: 0,
      loc_long: '',
      is_available: 1,
      hardware_id: '',
      status: 1,
      available_date: '',
      available_until: '',
      odometer: '',
      fuel_level: '',
      current_location: '',
      fleet: '',
      doors: 1,
      media: '',
      transmission: 'Manual',
      coolant_temperature_alarm_threshold: '',
      low_battery_alarm_threshold: '',
      purchase_price: '',
      expected_rest_value: '',
      odometer_at_purchase: '',
      purchase_date: '',
      depreciation_time_months: ''
    };
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$route.params, "id")) {
      if (this.$route.params.id != "new") {
        this.getVehicleDetails(this.$route.params.id)
      }
    }

  },
  methods: {

    getVehicleDetails(id) {

      const api = 'https://api.615autorental.com/api/admin/vehicle/' + id + '/details'
      const admintoken = localStorage.getItem('auth_token_default')
      const headers = {
        'Authorization': `Bearer ${admintoken}`,
        'Content-Type': 'application/json' // You can adjust content type as needed
      };
      axios
        .get(api, { headers }, {
          params: {
            page: 1,
            limit: 30
          }
        })
        .then((response) => {
          const responseData = response.data.data;
          this.vehicle_photo = responseData.vehicle_photo;
          this.vin = responseData.vin;
          this.color = responseData.color;
          this.year_model = responseData.year_model;
          this.url_path = responseData.url_path;
          this.license_plate = responseData.license_plate;
          this.current_renter = responseData.current_renter;
          this.model = responseData.model;
          this.make = responseData.make;
          this.car_class = responseData.class;
          this.location = responseData.location;
          this.loc_lat = responseData.loc_lat;
          this.price = parseFloat(responseData.price);
          this.loc_long = responseData.loc_long;
          this.is_available = responseData.is_available;
          this.hardware_id = responseData.hardware_id;
          this.status = responseData.status;
          this.available_date = responseData.available_date;
          this.available_until = responseData.available_until;
          this.odometer = responseData.odometer;
          this.fuel_level = responseData.fuel_level;
          this.current_location = responseData.current_location;
          this.fleet = responseData.fleet;
          this.doors = parseInt(responseData.doors);
          this.media = responseData.media;
          this.transmission = responseData.transmission;
          this.coolant_temperature_alarm_threshold = responseData.coolant_temperature_alarm_threshold;
          this.low_battery_alarm_threshold = responseData.low_battery_alarm_threshold;
          this.purchase_price = parseFloat(responseData.purchase_price);
          this.expected_rest_value = parseFloat(responseData.expected_rest_value);
          this.odometer_at_purchase = parseInt(responseData.odometer_at_purchase);
          this.purchase_date = responseData.purchase_date;
          this.depreciation_time_months = parseInt(responseData.depreciation_time_months);

        })
        .catch((error) => {
          console.error('There was an error!', error.message)
        })
    },
    closeModal() {
      // Get the modal element by its ID and invoke UIKit's modal method to close it
      UIkit.modal('#createmodal').hide();
      location.href = "/vehicles"
    },
    uploadFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('category', 'vehicle')
      const admintoken = localStorage.getItem('auth_token_default')
      axios.post('https://api.615autorental.com/api/admin/upload', formData, {
        headers: {
          'Authorization': `Bearer ${admintoken}`,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          console.log('File uploaded successfully:', response.data.path);
          this.vehicle_photo = response.data.path
          console.log(this.vehicle_photo)
          // handle response
        })
        .catch(error => {
          console.error('Error uploading file:', error);
          // handle error
        });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0]
      this.vehicle_photo = URL.createObjectURL(this.file)
      this.uploadFile()

    },
    createVehicle() {
      let formData = {
        "company_id": 1, // Update with the actual company ID
        "vin": this.vin,
        "color": this.color,
        "year_model": this.year_model,
        "url_path": this.url_path,
        "license_plate": this.license_plate,
        "current_renter": this.current_renter,
        "name": this.make + ' ' + this.model, // Concatenating make and model to form the name
        "model": this.model,
        "make": this.make,
        "class": this.car_class,
        "transmission": this.transmission,
        "doors": this.doors,
        "media": this.media,
        "price": this.price, // Update default price if needed
        "location": this.location,
        "loc_lat": this.loc_lat,
        "loc_long": this.loc_long,
        "is_available": this.is_available,
        "hardware_id": this.hardware_id,
        "status": this.status,
        "vehicle_photo": this.vehicle_photo,
        "available_date": this.available_date,
        "available_until": this.available_until,
        "odometer": this.odometer,
        "fuel_level": this.fuel_level,
        "current_location": this.current_location,
        "fleet": this.fleet,
        "coolant_temperature_alarm_threshold": this.coolant_temperature_alarm_threshold,
        "low_battery_alarm_threshold": this.low_battery_alarm_threshold,
        "purchase_price": this.purchase_price,
        "expected_rest_value": this.expected_rest_value,
        "odometer_at_purchase": this.odometer_at_purchase,
        "purchase_date": this.purchase_date + " 00:00:00",
        "depreciation_time_months": this.depreciation_time_months
      };
      if (Object.prototype.hasOwnProperty.call(this.$route.params, "id")) {
        if (this.$route.params.id == "new") {
          // new vehicle flow
          const apiUrl = 'https://api.615autorental.com/api/admin/vehicles';
          axios.post(apiUrl, formData, {
            headers: {
              'Content-Type': 'application/json', // Update content type to JSON
            }
          })
            .then((response) => {
              if (response.data.success) {
                this.success();
              } else {
                this.requiredfileds = response.data;
              }
            })
            .catch((error) => {
              this.errorMessage = error.message;
              console.error('There was an error!', error);
            });
        } else {
          // edti vehicle flow
          let vehicleid = this.$route.params.id
          const apiUrl = 'https://api.615autorental.com/api/admin/vehicles/' + vehicleid;
          axios.put(apiUrl, formData, {
            headers: {
              'Content-Type': 'application/json', // Update content type to JSON
            }
          })
            .then((response) => {
              if (response.data.success) {
                this.success();
              } else {
                this.requiredfileds = response.data;
              }
            })
            .catch((error) => {
              this.errorMessage = error.message;
              console.error('There was an error!', error);
            });
        }
      }

    },
    success() {
      UIkit.modal('#createmodal').show();
      var notyf = new Notyf();
      notyf.confirm('Successfully Updated!');
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
