<template>
    <div class="row align-items-center">
        <div class="col-lg-12">

            <div class="grey-box">
            <h6 class="p-2 mt-2 uk-text-bold"> <i class="fas fa-balance-scale mr-2"></i> Legal Agreement </h6>
            <form @submit.prevent="updateLegal" ref="updateLegal" class="grey-box">
                <div class="row">
                    <div class="col-lg-12">
                        <ul uk-tab style="padding-left: 15px;">
                            <li class="uk-active">
                            <a href="#" class="no-bullet">Legal Agreement (English)</a>
                            </li>
                            <!-- <li>
                            <a href="#" class="no-bullet">Legal Agreement (Thai)</a>
                            </li> -->
                        </ul>
                        <ul class="uk-switcher uk-margin">
                            <li>
                            <div class="form-group">
                                <textarea rows="30" name="legal_agreement_text" v-model="company.legal_agreement_text" required ></textarea>
                                <label>Please enter Legal Agreement text (English)</label>
                                <!-- <label style="color: red">{{ isRequired('legal_agreement') }} </label> -->
                            </div>
                            </li>
                            <!-- <li>
                            <div class="form-group">
                                <textarea rows="30" name="legal_agreement" v-model="company.legal_agreement" required ></textarea>
                                <label>Please enter Legal Agreement text (Thai)</label>
                            </div>
                            </li> -->
                        </ul>
                    </div>
                    <div class="col-md-6 mb-md-0">
                        <h6 style="font-weight: 600; color: #111;"> <span uk-icon="upload"></span> Upload Document </h6>
                        <div class="js-upload uk-placeholder uk-text-center mr-2">
                            <div class="mb-2 mt-2">
                                <span class="uk-link btn-upload"> Upload </span>
                            </div>
                            <span uk-icon="icon: cloud-upload" class="mr-2"></span>
                            <span class="uk-text-middle"> Drag & Drop file </span>
                            <div uk-form-custom>
                                <input
                                type="file"
                                name="legal_agreement_doc"
                                class="file-upload"
                                id="file"
                                ref="legalAgreementDoc"
                                v-on:change="legalAgreementDocUpload()"
                                />
                                <img v-if="legalUrl" :src="legalUrl" />

                            </div>
                        </div>
                        <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 pl-4 pr-1 pb-0">
                        <div class="row w-100 bg-lightgray border-radius-default pb-0 mb-0">
                            <div class="col-md-2 mb-0 pb-0">
                                <div class="p-3 pb-0 mb-0">
                                    <span class="uk-text-uppercase"> Signed date </span>
                                    <p class="uk-text-uppercase mt-0 pt-2 pb-0 mb-0">25 Aug 2022</p>
                                </div>
                            </div>
                            <div class="col-md-3 mb-0 pb-0">
                                <div class="p-3 pb-0 mb-0">
                                    <span class="uk-text-uppercase"> Signed From </span>
                                    <p class="uk-text-uppercase mt-0 pt-2 pb-0 mb-0">Macbook Pro 2021 - M1 | Safari</p>
                                </div>
                            </div>
                            <div class="col-md-2 mb-0 pb-0">
                                <div class="p-3 pb-0 mb-0">
                                    <span class="uk-text-uppercase"> IP Address </span>
                                    <p class="uk-text-uppercase mt-0 pt-2 pb-0 mb-0">171.50.49.132</p>
                                </div>  
                            </div>
                            <div class="col-md-5 mb-0 pb-0 text-right">
                                <button type="button" @click="downloadPdf(company.legal_agreement_doc)" id="client" class="save-btn download-btn border-0 ml-2 mr-2 mt-4 mb-2 uk-position-right">
                                    <i class="fas fa-arrow-to-bottom mr-2"></i> Download PDF
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-right mt-3">
                        <a class="cancel-btn mr-2">Cancel</a>
                        <button type="submit" class="save-btn">Save</button>
                    </div>
                </div>
            </form>

            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: {
        company: {
            legal_agreement_text: String,
            legal_agreement_doc: null,
        }
    },
    data() {
        return {
            requiredfileds: {},
            legalUrl: ''
        }
    },
    mounted() {
        console.log(this.company)
        this.legalUrl = this.company.legal_agreement_doc? this.legal_agreement_doc : this.legalUrl
    },
    methods: {
        legalAgreementDocUpload() {
            this.company.legal_agreement_doc = this.$refs.legalAgreementDoc.files[0]
            this.legalUrl = URL.createObjectURL(this.company.legal_agreement_doc)
        },
        updateLegal() {
            let form = this.$refs.updateLegal;
            let formData = new FormData(form);

            const apiUrl = 'admin/agencies/' + this.$route.params.id + '/legal_agreement'
            axios
            .post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            )
            .then((response) => {
                if(response.data.success) {
                    this.success()
                }else{
                    this.requiredfileds = response.data
                }
            })
            .catch((error) => {
            this.errorMessage = error.message;
            console.error('There was an error!', error)
            })
        },
        downloadPdf(url, title = 'Legal Agreement') {
            axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            })
            .then((response) => {
                this.forceFileDownload(response, title)
            })
            .catch(() => console.log('error occured'))
        },
        forceFileDownload(response, title) {
            console.log(title)
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },
    }
  }
</script>