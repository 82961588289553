<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative"></div>
      </div>
      <div class="table-responsive">
        <table class="table data-table table-round table-hover">
          <thead>
            <tr>
              <th>
                    ID
                    <Sort column="id" />
                  </th>
                  <th>
                    Email
                    <Sort column="name_en" />
                  </th>
                  <th>
                    Phone Number
                  </th>
                  <th>
                    Pick-up Location
                  </th>
                  <th>
                    Return Location
                  </th>
                  <th>
                    Pick-up Date
                  </th>
                  <th>
                    Return Date
                  </th>
                  <th>
                    Contact
                  </th>
                  <th>
                    Branch
                  </th>
                  <th>
                    Vehicle Class
                  </th>
                  <th>
                    Last Step
                  </th>
                  <th class="blankhead"></th>
            </tr>
          </thead>
          <tbody>
                <tr v-for="(list, index) in reservationAttemptList" :key="index">
                  <td><a @click="editReservation(list.id)" style="color: blue;" >{{ list.id }}</a></td>
                  <td>{{ list.customer.email }}</td>
                  <td>{{ list.customer.phone_number }}</td>
                  <td>{{ list.pickup_location }}</td>
                  <td>{{ list.return_location }}</td>
                  <td>{{ list.pickup_datetime }}</td>
                  <td>{{ list.return_datetime }}</td>
                  <td>{{ list.customer.emergency_contact_name }}</td>
                  <td>{{ list.company_id }}</td>
                  <td>{{ list.vehicle.class }}</td>
                  <td></td>
                  <td class="text-right">

                    <a href="" uk-toggle="target: #viewreservation" class="view-btn"><i class="fal fa-eye"></i> View</a>
                    <a href="" uk-toggle="target: #reservemodal" class="edit-btn"><i class="fal fa-envelope"></i></a>
                  </td>
                </tr>
                
              </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- PAGINATION -->
      <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
        <jw-pagination
          :items="pages"
          @changePage="onChangePage"
          :pageSize="limit"
          :styles="customStyles"
          :labels="customLabels"
        ></jw-pagination>
      </div>

      <!-- Add Modal -->
      <div id="reservemodal" uk-modal>
        <div class="uk-modal-dialog uk-modal-body p-2 text-center">
            <button class="uk-modal-close-outside" type="button" uk-close></button>
            <div class="row align-items-center mb-4">
                <div class="col-lg-12">
                <div class="grey-box">
                    <div class="view-ui mt-4 mb-2">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" value="" autocomplete="off" required>
                                    <label>To</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" value="" autocomplete="off" required>
                                    <label>Cc</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" value="" autocomplete="off" required>
                                    <label>Reply to</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                              <client-only>
                                <VueEditor />
                              </client-only>
                            </div>
            
                            <div class="clearfix"></div>
                            
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <a href="#" class="uk-button uk-button-primary mr-3" type="button">Send Email</a>
        </div>
      </div>
      <!-- End modal -->

      <!-- Reservation Modal -->
      <div id="viewreservation" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-modal-lg p-2">
            <button class="uk-modal-close-outside" type="button" uk-close></button>
            <div class="row align-items-center mb-4">
                <div class="col-lg-12">
                <div class="grey-box">
                    <div class="view-ui mt-4 mb-2">
                      <div class="row">
                        <div class="col-md-6 text-left">
                          <h5 class="mt-1 mb-2 uk-text-bold">Attempt</h5>
                        </div>
                      </div>
                      <div class="view-ui mt-2">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="p-2 bg-light" style="border: solid 1px #e9e9e9;">
                              <div class="row p-0 pb-0">
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Contact </h6>
                                    <p class="result m-0 mb-4">Contact not set</p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Reservation Type</h6>
                                    <p class="result m-0 mb-4"> car_rental.reservations </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Email</h6>
                                    <p class="result m-0 mb-4"> hahahah@gmail.com </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Phone Number</h6>
                                    <p class="result m-0 mb-4"> 091234456789 </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Pickup Date</h6>
                                    <p class="result m-0 mb-4"> 13-08-2021 20:00 </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Pickup Date</h6>
                                    <p class="result m-0 mb-4"> 13-08-2021 20:00 </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Pickup Location</h6>
                                    <p class="result m-0 mb-4"> Office </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Return Location</h6>
                                    <p class="result m-0 mb-4"> Office </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Branch</h6>
                                    <p class="result m-0 mb-4"> 234 </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Vehicle class</h6>
                                    <p class="result m-0 mb-4"> Vehicle class not set </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Country</h6>
                                    <p class="result m-0 mb-4"> Venezuela </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">State</h6>
                                    <p class="result m-0 mb-4"> Distrito Federal </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">City</h6>
                                    <p class="result m-0 mb-4"> Caracas </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Booker IP Address</h6>
                                    <p class="result m-0 mb-4"> 190.6.49.88 </p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div>
                                    <h6 class="title mb-1">Referral</h6>
                                    <p class="result m-0 mb-4"> </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <table class="table data-table table-round table-hover">
                        <thead>
                          <tr>
                            <th>Vehicle class</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                              <td>Economic Manual</td>
                              <td>11</td>
                          </tr>
                          <tr>
                              <td>Economic Automatic</td>
                              <td>11</td>
                          </tr>
                          <tr>
                              <td>SUV</td>
                              <td>4</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
                </div>
            </div>
            <a href="#" class="uk-button uk-button-danger mr-3" type="button">Delete</a>
            <a href="#" class="uk-button uk-button-secondary mr-3" type="button">Copy Public Link</a>
            <a href="#" class="uk-button uk-button-primary mr-3" type="button">Continue Reservation</a>
        </div>
      </div>
      <!-- Reservation modal -->
    </div>
    
  </template>
  
  
  
  <script>

  export default {
    props: {
          companyDetail: {
              id: Number,
              bank_account_no: String,
              bank_owner_name: String,
              bank_name: String,
              bank_branch_code: String,
              bank_book: String,
  
          }
      },
      data() {
          return {
            reservationAttemptList: []
          }
      },
      mounted() {
        this.getReservationAttemptList()
      },
      
      methods: {
        editReservation(id){
          location.href = "/reservation/edit/"+ id
        },
        getReservationAttemptList() {
        const api = 'https://api.615autorental.com/api/admin/reservation-attempt'
          const admintoken = localStorage.getItem('auth_token_default')
          const headers = {
            'Authorization': `Bearer ${admintoken}`,
          'Content-Type': 'application/json' // You can adjust content type as needed
        };
        axios
            .get(api,{ headers },{
              params: {
                page:1,
                limit: 30
              }
            })
            .then((response) => {
                this.reservationAttemptList = response.data.data
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
      } 
      }
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>