<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
                <li><span>Admin</span></li>
                <li><a href="/commission-partner">Commission Partner</a></li>
                <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative"></div>
      </div>
      
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-calendar mr-2"></i> Commission Partner</h5>
              </div>
            </div>
            <div class="view-ui mt-4 mb-2">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>Name</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="email" class="form-control" value="" autocomplete="off" required>
                    <label>Email Address</label>
                  </div>
                </div>

                <!-- Add editable table for phone number -->
                <div class="col-md-6">
                    <p class="add-table-p">Phone Number</p>
                    <div class="table-responsive mb-4">
                        <table class="table data-table table-round table-hover">
                            <thead>
                                <tr>
                                    <th width="30%" class="">Type</th>
                                    <th width="55%" class="">Phone Number</th>
                                    <th class="blankhead" width="15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="pt-3 pb-2">
                                        <div class="form-group select-dropdown mb-2 mt-0">
                                            <select class="form-control uk-form-small pt-0" name="number" required>
                                                <option value="" selected="selected">Home</option>
                                                <option value="1">Work</option>
                                                <option value="2">Mobile</option>
                                                <option value="3">Emergency</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td class="bg-Total pt-3">
                                        <div class="form-group mb-2 mt-1">
                                            <input type="text" class="form-control uk-form-small pt-0" required style="width:200px"/>
                                        </div>
                                    </td>
                                    <td class="bg-lightgreen text-right">
                                        <a href="#" class="del-btn">
                                            <i class="fal fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="col-md-12 text-right p-0">
                                            <a class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center float-left">
                                                <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>  
                <!-- Add editable table for address -->
                <div class="col-md-6">
                    <p class="add-table-p">Address</p>
                    <div class="table-responsive mb-4">
                        <table class="table data-table table-round table-hover">
                            <thead>
                                <tr>
                                    <th width="30%" class="">Type</th>
                                    <th width="55%" class="">Address</th>
                                    <th class="blankhead" width="15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="pt-3 pb-2">
                                        <div class="form-group select-dropdown mb-2 mt-0">
                                            <select class="form-control uk-form-small pt-0" name="number" required>
                                                <option value="" selected="selected">Main</option>
                                                <option value="1">Home</option>
                                                <option value="2">Work</option>
                                                <option value="3">Mailing</option>
                                                <option value="3">Billing</option>
                                                <option value="3">Shipping</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td class="bg-Total pt-3">
                                        <div class="form-group mb-2 mt-1">
                                            <input type="text" class="form-control uk-form-small pt-0" required style="width:200px"/>
                                        </div>
                                    </td>
                                    <td class="bg-lightgreen text-right">
                                        <a href="#" class="del-btn">
                                            <i class="fal fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="col-md-12 text-right p-0">
                                            <a class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center float-left">
                                                <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>        
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="date" class="form-control" value="" autocomplete="off" required>
                    <label>Commission Percentage</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <select class="form-control" required>
                        <option>Commission</option>
                        <option>Invoice</option>
                    </select>
                    <label>Type</label>
                  </div>
                </div>
                <!-- Add editable table for contact person and their number -->
                <div class="col-md-6">
                    <p class="add-table-p">Contact Person</p>
                    <div class="table-responsive mb-4">
                        <table class="table data-table table-round table-hover">
                            <thead>
                                <tr>
                                    <th width="30%" class="">Contact Person</th>
                                    <th width="55%" class="">Phone Number</th>
                                    <th class="blankhead" width="15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="pt-3 pb-2">
                                        <div class="form-group select-dropdown mb-2 mt-0">
                                            <input type="text" class="form-control uk-form-small pt-0" required/>
                                        </div>
                                    </td>
                                    <td class="bg-Total pt-3">
                                        <div class="form-group mb-2 mt-1">
                                            <input type="text" class="form-control uk-form-small pt-0" required style="width:200px"/>
                                        </div>
                                    </td>
                                    <td class="bg-lightgreen text-right">
                                        <a href="#" class="del-btn">
                                            <i class="fal fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="col-md-12 text-right p-0">
                                            <a class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center float-left">
                                                <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>  
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea class="form-control" required></textarea>
                    <label>Agreements</label>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea class="form-control" required></textarea>
                    <label>Internal Notes</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-6 text-left">
                    <h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-cog mr-2"></i> Settings</h5>
                </div>
            </div>
            <div class="row pb-4 pt-3">
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" value="" autocomplete="off" required>
                        <label>No Show Penalty</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" value="" autocomplete="off" required>
                        <label>Cancellation Penalty</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" value="" autocomplete="off" required>
                        <label>Mandatory Additional Charges</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" value="" autocomplete="off" required>
                        <label>Excluded Additional Charges</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" value="" autocomplete="off" required>
                        <label>Charges paid by Commission Partner</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" value="" autocomplete="off" required>
                        <label>Automatic Discounts</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" value="" autocomplete="off" required>
                        <label>Currency </label>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <textarea class="form-control" required></textarea>
                        <label>Custom text for airport Pickup </label>
                    </div>
                </div>
                <div class="col-md-12">
                    <h6 class="mt-3" style="font-weight: 600; color: #111; font-size: 13px;"> <span uk-icon="upload"></span> RENTAL AGREEMENT ATTACHMENT </h6>
                    <div class="js-upload uk-placeholder uk-text-center mr-2">
                        <div class="mb-2 mt-2">
                            <span class="uk-link btn-upload"> Upload </span>
                        </div>
                        <span uk-icon="icon: cloud-upload" class="mr-2"></span>
                        <span class="uk-text-middle"> Drag & Drop file </span>
                        <div uk-form-custom>
                            <input
                            type="file"
                            name="rental_agreement_doc"
                            class="file-upload"
                            id="file"
                            ref="legalAgreementDoc"
                            v-on:change="rentalAgreementDocUpload()"
                            />
                            <img v-if="legalUrl" :src="legalUrl" />
                        </div>
                    </div>
                    <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <h6 class="mt-3" style="font-weight: 600; color: #111; font-size: 13px;"> <span uk-icon="upload"></span> SIGNATURE</h6>
                        <client-only>
                            <VueEditor />
                        </client-only>
                        
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                </button>
            </div>
          </div>
        </div>

      </div>
    </div>
    
  </template>
  
  
  
  <script>

export default {
    props: {
        compartnerl: {
            rental_agreement_doc: null,
        }
    },
    data() {
        return {
            requiredfileds: {},
            legalUrl: ''
        }
    },
    mounted() {
        console.log(this.compartnerl)
        this.legalUrl = this.compartnerl.rental_agreement_doc? this.rental_agreement_doc : this.legalUrl
    },
    methods: {
        rentalAgreementDocUpload() {
            this.compartnerl.rental_agreement_doc = this.$refs.legalAgreementDoc.files[0]
            this.legalUrl = URL.createObjectURL(this.compartnerl.rental_agreement_doc)
        },
        updateLegal() {
            let form = this.$refs.updateLegal;
            let formData = new FormData(form);

            const apiUrl = 'admin/agencies/' + this.$route.params.id + '/legal_agreement'
            axios
            .post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            )
            .then((response) => {
                if(response.data.success) {
                    this.success()
                }else{
                    this.requiredfileds = response.data
                }
            })
            .catch((error) => {
            this.errorMessage = error.message;
            console.error('There was an error!', error)
            })
        },
        downloadPdf(url, title = 'Legal Agreement') {
            axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            })
            .then((response) => {
                this.forceFileDownload(response, title)
            })
            .catch(() => console.log('error occured'))
        },
        forceFileDownload(response, title) {
            console.log(title)
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },
    }
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>