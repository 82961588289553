import { render, staticRenderFns } from "./PublicReservation3.vue?vue&type=template&id=0c84b4d8&scoped=true&"
import script from "./PublicReservation3.vue?vue&type=script&lang=js&"
export * from "./PublicReservation3.vue?vue&type=script&lang=js&"
import style0 from "./PublicReservation3.vue?vue&type=style&index=0&id=0c84b4d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c84b4d8",
  null
  
)

export default component.exports