<template>
<div class="row">
      <div class="col-md-12 pt-2 pb-4">
        <div class="chart-wrapper p-4 uk-animation-slide-bottom-small">
            <div class="row">
                <div class="col-md-12 relative text-right">
                    <h6 class="uk-position-top-left mt-3 ml-4 uk-text-bold"> Financial </h6>
                    <div class="uk-float-right">
                        <ul class="uk-inline" uk-tab>
                            <li v-for="(category, categoryIndex) in categories" :key="categoryIndex" :class="selectedCategory == category? 'uk-active' : ''" >
                                <a href="#" class="no-bullet uk-text-uppercase" @click="selectedCategoryData(category)">{{ category }}</a>
                            </li>
                        </ul> 
                    </div>
                    <div class="uk-float-right mr-4">
                        <ul class="uk-inline" uk-tab>
                            <li class="uk-active">
                                <a href="#" class="no-bullet uk-text-uppercase" @click="chartData('amount')" > Amount </a>
                            </li>
                            <li>
                                <a href="#" class="no-bullet uk-text-uppercase" @click="chartData('profit')" > Profit </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-12 mt-4 chart">
                    <canvas id="financial-chart" height="400"></canvas>
                </div>
            </div> 
        </div>
    </div>
    </div>

</template>

<script>

export default {
    // chartTypes: chartTypes,
    mounted() {
        this.chartData('amount')
    },
    data() {
        return {
            start_date: '',
            end_date: '',
            financialChart: null,
            selectedType: '',
            chartbyCategory:[],
            categories: [],
            selectedCategory:'',
            config: {
                responsive: true,
                showTooltips: false,
                inflateAmount: 'auto',
                barPercentage: 0.5,
                maintainAspectRatio: false,
                legend: { 
                    display: false 
                },
                title: {
                    display: false,
                    text: '',
                },
                plugins: {
                    legend: {
                        display: false
                    }
                },
                animations: {
                    tension: {
                        duration: 1000,
                        easing: 'linear',
                        from: 1,
                        to: 0,
                        loop: true
                    }
                },
            },
        }
    },
    methods: {
        chartData(type) {
            this.selectedType = type

            var notyf = new Notyf();
            const api = `admin/quotations/chart`
            axios
                .get(api, {
                    params: {
                    //    start_date: this.start_date,
                    //    end_date: this.end_date,
                       bidding_status: 'all'
                    }
                })
                .then((response) => {
                    this.categories = []

                    if(response.data.success) {
                        let results = response.data.data

                        var groupByCategory = []
                        results.forEach((element) => {

                            if(groupByCategory[element.name_en] != undefined) {
                                groupByCategory[element.name_en].labels.push(element.year + '/' + element.month_in_word) 
                                groupByCategory[element.name_en].data.push(element.total_price) 
                            }else{

                                this.categories.push(element.name_en)
                                var labels = []
                                labels.push(element.year + '/' + element.month_in_word)

                                var data = []
                                data.push(element.total_price)
                                groupByCategory[element.name_en] = {labels: labels, data: data}
                            }
                           
                        });
                        this.selectedCategory = this.categories[0]

                        this.chartbyCategory = groupByCategory
                        this.selectedCategoryData(this.selectedCategory)
                    }else{
                        notyf.alert(response.data.message);
                    }
                })
                .catch((error) => {
                    notyf.alert(error.message);
                    console.error('There was an error!', error.message)
                })
        },
        selectedCategoryData(category) {
            this.selectedCategory = category
            this.createChart(this.chartbyCategory[category].labels, this.chartbyCategory[category].data)
        },
        createChart(getLabels, getData) {
            if(this.financialChart != null) {
                this.financialChart.destroy();
            }

            let ctx = document.getElementById('financial-chart')
            this.financialChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: getLabels,
                    datasets: [
                        {
                            label: 'Financial',
                            backgroundColor: ['#1363f8'],
                            data: getData
                        },
                    ],
                },
                options: this.config
            });
        }
    }
}
</script>