<template>
    <div>
        <!----- Header Title ----->
        <section class="row">
        <Header />
        </section>

        <!----- * TABS * ----->
        <TabList />
        
        <section class="tpui-container">
            <div class="row align-items-center mb-4">
                <div class="col-lg-12">
                    <div class="grey-box p-3 pt-4">
                        <Rating />
                        <Data />
                        <BodyguardPriceList :companyDetail="companyDetail" />
                        <TransportationPrice :companyDetail="companyDetail" />
                        <EscortPrice />
                    </div>
                </div>
            </div>
        </section>

        <Gallery />
        <AgentList :companyDetail="companyDetail" />
        <MissionList :companyDetail="companyDetail" />
    </div>
</template>

<script>
import TabList from "../Edit/TabList.vue"
import Data from "./Data.vue";
import Rating from "./Rating.vue";
import BodyguardPriceList from "./BodyguardPrice.vue";
import TransportationPrice from "./TransportationPrice.vue";
import EscortPrice from "./EscortPrice.vue";
import Gallery from "./Gallery.vue";
import AgentList from "./AgentList.vue";
import MissionList from "./MissionList.vue";

export default {
    components: {
        TabList,
        Data,
        Rating,
        BodyguardPriceList,
        TransportationPrice,
        EscortPrice,
        Gallery,
        AgentList,
        MissionList
    },
    data() {
        return {
            companyDetail: {
            },
            owners: [],
            id: this.$route.params.id,
        }
    },
    mounted() {

        this.getCompanyDetail()
    },
    methods: {
        getCompanyDetail() {
            const api = 'admin/agencies/' + this.$route.params.id
            axios
            .get(api)
            .then((response) => {
                if(!response.data.success) {
                this.$router.push('/404')
                }
                this.companyDetail = response.data.data
                this.owners = response.data.data.owners
                console.log(this.companyDetail)
                this.title()
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
        },
        title() {
            this.$route.meta.title = this.companyDetail.name_en
        },
        formatTHB(num) {
            return '฿' + Number(num).toLocaleString()
        },
        updateAddress(addressFormData) {
                console.log('parent component')
            console.log(addressFormData)
            
            let address = {
            lat: addressFormData.lat,
            lng: addressFormData.lng,
            country : addressFormData.country,
            address : addressFormData.address,
            province : addressFormData.province,
            sub_district : addressFormData.sub_district,
            district : addressFormData.district,
            zip_code : addressFormData.zip_code,
            company_country : addressFormData.country,
            company_address : addressFormData.address,
            company_province : addressFormData.province,
            company_sub_district : addressFormData.sub_district,
            company_district : addressFormData.district,
            company_zip_code : addressFormData.zip_code,
            website: addressFormData.website,

            contact_number: '663222222',
            contact_person: this.companyDetail.contact_person,
            name_en: this.companyDetail.name_en,
            registration_id: this.companyDetail.registration_id,
            tax_id: this.companyDetail.tax_id

            }

                const apiUrl = 'admin/agencies/' + this.$route.params.id + '/company'
                axios
            .post(apiUrl, address)
            .then((response) => {

                if(response.data.success) {
                    this.$helpers.notification(true, 'Updated!')
                }else{
                    this.$helpers.notification(false, response.data.message)
                }
                
            })
            .catch((error) => {
                this.$helpers.notification(false, error.message)
                console.error('There was an error!', error)
            })
        },
        

    },

}
</script>
