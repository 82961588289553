<template>
  <div class="container-fluid mb-5">
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3 text-right">
          <a href="add-vip-service.html" class="outline-btn"><i class="fal fa-plus-circle mr-2"></i>Add</a>
        </div>
        <div class="table-responsive">
          <table class="table data-table table-round table-hover">
            <thead>
              <tr>
                <th scope="col">Company ID</th>
                <th scope="col">Company Name</th>
                <th scope="col">No. of Sevices</th>
                <th scope="col">Missions</th>
                <th scope="col">Ratings</th>
                <th scope="col">Location</th>
                <th scope="col" class="blankhead"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01</td>
                <td>Star Security Company</td>
                <td>500</td>
                <td>11</td>
                <td><img src="img/star-ico.svg" class="mr-1" />4.0</td>
                <td>Pattaya</td>
                <td class="text-right"><a href="star-vip-services.html" class="view-btn">View</a></td>
              </tr>
              <tr>
                <td>02</td>
                <td>Star Security Company</td>
                <td>500</td>
                <td>11</td>
                <td><img src="img/star-ico.svg" class="mr-1" />4.0</td>
                <td>Pattaya</td>
                <td class="text-right"><a href="star-vip-services.html" class="view-btn">View</a></td>
              </tr>
              <tr>
                <td>03</td>
                <td>Star Security Company</td>
                <td>500</td>
                <td>11</td>
                <td><img src="img/star-ico.svg" class="mr-1" />4.0</td>
                <td>Pattaya</td>
                <td class="text-right"><a href="star-vip-services.html" class="view-btn">View</a></td>
              </tr>
              <tr>
                <td>04</td>
                <td>Star Security Company</td>
                <td>500</td>
                <td>11</td>
                <td><img src="img/star-ico.svg" class="mr-1" />4.0</td>
                <td>Pattaya</td>
                <td class="text-right"><a href="star-vip-services.html" class="view-btn">View</a></td>
              </tr>
              <tr>
                <td>05</td>
                <td>Star Security Company</td>
                <td>500</td>
                <td>11</td>
                <td><img src="img/star-ico.svg" class="mr-1" />4.0</td>
                <td>Pattaya</td>
                <td class="text-right"><a href="star-vip-services.html" class="view-btn">View</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  mounted() {},
  data() {
    return {
      params: {
        sort: { column: 'id', type: 'desc' },
      },
    }
  },
  methods: {
    sort(sort) {
      this.params.sort = sort
      console.log(sort.column, sort.type)
    },
  },
}
</script>

<style scoped>
</style>