<template>
  <div class="company-edit">

    <Header />
    
    <section class="tpui-container">
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-3 uk-text-bold">
                  <i class="fas fa-user mr-2"></i> Company Information
                </h5>
              </div>
              <div class="col-md-6 text-right">
              </div>
            </div>
            <fieldset class="mt-5">
              <template v-if="companyDetail.id">
                <CompanyProfileEdit v-if="companyDetail.id" :companyDetail="companyDetail"></CompanyProfileEdit>
              </template>
              <template v-if="companyDetail.id">
                <Owner :owners="owners" :companyDetail="companyDetail"></Owner>
              </template>
            </fieldset>
            <CompanyContactPerson v-if="companyDetail.id" :companyDetail="companyDetail"></CompanyContactPerson>
            <DbdDocument></DbdDocument>
            <VerifyLocation></VerifyLocation>
            
          </div>
        </div>
      </div>
    </section>


    <section class="tpui-container">
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box pt-3">
            <div class="col-md-12 pl-1 pr-1">
              <div class="pt-2">
                <div class="row">
                  <div class="col-md-6 text-left">
                    <h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-map-marker-alt mr-2"></i> Company Address</h5>
                  </div>
                  <div class="col-md-6 text-right"></div>
                </div>
                <div class="row">
                  <div class="col-md-12 pb-3 pt-1">
                    <div class="table-responsive">
                      <table class="table data-table table-round table-hover">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Address</th>
                            <th>Province</th>
                            <th>District</th>
                            <th>Zipcode</th>
                            <th>Country</th>
                            <th class="blankhead" width="100px"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">
                              <i uk-icon="home"></i>
                            </td>
                            <td> {{companyDetail.address}} </td>
                            <td> {{companyDetail.province}} </td>
                            <td> {{companyDetail.district}} </td>
                            <td> {{companyDetail.zip_code}} </td>
                            <td> {{companyDetail.country}} </td>
                            <td class="text-right">
                              <a class="view-btn clickable" data-toggle="collapse" data-target="#latest_address_1">Map View</a>
                              <!-- <a class="edit-btn" href="#modal-edit-address" uk-toggle><i class="fal fa-edit"></i></a> -->
                              <!-- <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a> -->
                            </td>
                          </tr>
                          <tr v-if="companyDetail.address">
                            <td colspan="9" class="p-0">
                              <div id="latest_address_1" class="collapse">
                                <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important">
                                  <CompanyAddress :addressObj="companyDetail" v-on:submitLocation="updateAddress($event)"></CompanyAddress>
                                </div>

                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      
    <Bank :companyDetail="companyDetail"></Bank>
    <PriceList :companyDetail="companyDetail"></PriceList>
    <AgentList :companyDetail="companyDetail"></AgentList>
    <MissionList></MissionList>
    <LegalAgreementEdit :company="companyDetail"></LegalAgreementEdit>
  </div>
</template>

<script>

import CompanyProfileEdit from "./CompanyProfileEdit.vue";
import CompanyContactPerson from "./CompanyContactPerson.vue";
import Owner from "./Owner.vue";
import CompanyAddress from "../GoogleMap.vue";
import DbdDocument from "./DbdDocument.vue";
import VerifyLocation from "./VerifyLocation.vue";

import PriceList from "./PriceList.vue";
import MissionList from "./MissionList.vue";
import LegalAgreementEdit from "./LegalAgreementEdit.vue";

// import InvoiceList from "./InvoiceList.vue"
import Bank from "./Bank.vue"
import AgentList from "./AgentList.vue"

export default {
  components: {
    CompanyProfileEdit,
    CompanyContactPerson,
    CompanyAddress,
    DbdDocument,
    VerifyLocation,
    Owner,
    Bank,
    PriceList,
    AgentList,
    MissionList,
    LegalAgreementEdit
  },
  mounted() {

    // this.checkRequired()
      this.getCompanyDetail()
  },
  data() {
    return {
      companyDetail: {
      },
      owners: [],
      headerdata: {
        route: '',
        search: false,
        addBtn: false
      }
    }
  },
  methods: {
    isRequired(key) {
      if (this.requiredfileds.hasOwnProperty(key)) {
        return '*'
      } else {
        return ''
      }
    },
    getCompanyDetail() {
      const api = 'admin/agencies/' + this.$route.params.id
      axios
        .get(api)
        .then((response) => {
          if(!response.data.success) {
            this.$router.push('/404')
          }
          this.companyDetail = response.data.data
          this.owners = response.data.data.owners
          console.log(this.companyDetail)
          this.title()
        })
        .catch((error) => {
          console.error('There was an error!', error.message)
        })
    },
    title() {
      this.$route.meta.title = this.companyDetail.name_en
    },
    formatTHB(num) {
      return '฿' + Number(num).toLocaleString()
    },
    updateAddress(addressFormData) {
			console.log('parent component')
      console.log(addressFormData)
      
      let address = {
        lat: addressFormData.lat,
        lng: addressFormData.lng,
        country : addressFormData.country,
        address : addressFormData.address,
        province : addressFormData.province,
        sub_district : addressFormData.sub_district,
        district : addressFormData.district,
        zip_code : addressFormData.zip_code,
        company_country : addressFormData.country,
        company_address : addressFormData.address,
        company_province : addressFormData.province,
        company_sub_district : addressFormData.sub_district,
        company_district : addressFormData.district,
        company_zip_code : addressFormData.zip_code,
        website: addressFormData.website,

        contact_number: '663222222',
        contact_person: this.companyDetail.contact_person,
        name_en: this.companyDetail.name_en,
        registration_id: this.companyDetail.registration_id,
        tax_id: this.companyDetail.tax_id

      }

			const apiUrl = 'admin/agencies/' + this.$route.params.id + '/company'
			axios
        .post(apiUrl, address)
        .then((response) => {

            if(response.data.success) {
              this.success()
            }else{
                this.requiredfileds = response.data
            }
            
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error('There was an error!', error)
        })
    },
    success() {
        var notyf = new Notyf();
            notyf.confirm('Successfully Updated!');
    },
    
  },
}
</script>

