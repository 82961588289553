<template>
  <div class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="background-image: url('https://rent.615autorental.com/assets/img/slider-2.jpeg'); background-position: center;"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-0 mt-3">Welcome</h1>
            <p
              class="text-lead text-white mt-0 mb-0"
            >Start your booking system with 615 Auto Rental</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0 mb-4">
            <div class="card-body pt-5">
              <form role="form">
                <div class="form-group" aria-label="Email">
                  <div class="">
                    <!--v-if-->
                    <input type="email" class="form-control form-control-default invalid" placeholder="Email" isrequired="false">
                    <!--v-if-->
                  </div>
                </div>
                <div class="form-group" aria-label="Company">
                  <div class="">
                    <!--v-if-->
                    <input type="text" class="form-control form-control-default invalid" placeholder="Company" isrequired="false">
                    <!--v-if-->
                  </div>
                </div>
                <div class="form-group" aria-label="Phone Number">
                  <div class="">
                    <!--v-if-->
                    <input type="text" class="form-control form-control-default invalid" placeholder="Phone Number" isrequired="false">
                    <!--v-if-->
                  </div>
                </div>
                <div class="form-group" aria-label="Website">
                  <div class="">
                    <!--v-if-->
                    <input type="text" class="form-control form-control-default invalid" placeholder="Website" isrequired="false">
                    <!--v-if-->
                  </div>
                </div>
                <div class="form-group" aria-label="Password">
                  <div class="">
                    <!--v-if-->
                    <input type="password" class="form-control form-control-default invalid" placeholder="Password" isrequired="false">
                    <!--v-if-->
                  </div>
                </div>
                <div class="form-check signup">
                  <input class="form-check-input" type="checkbox" checked>
                  <label class="custom-control-label">
                    <label class="form-check-label" for="flexCheckDefault"> I agree the <a href="javascript:;" class="text-dark font-weight-bolder">Terms and Conditions</a></label>
                  </label>
                </div>
                <div class="form-check signup">
                  <input class="form-check-input" type="checkbox">
                  <label class="custom-control-label">
                    <label class="form-check-label" for="flexCheckDefault"> I agree the <a href="javascript:;" class="text-dark font-weight-bolder">Privacy Policy</a></label>
                  </label>
                </div>
                <div class="text-center">
                  <button class="btn mb-0 bg-gradient-dark text-white btn-md w-100 null my-4">Sign up</button>
                </div>
                <p class="text-sm mt-0 mb-0"> Already have an account? 
                  <router-link :to="'/login/'">
                      <a class="text-dark font-weight-bolder">Sign in</a>
                  </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const body = document.getElementsByTagName("body")[0];

export default {
  name: "signin",
  components: {
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>

<style>
.mt-0 {
    margin-top: 0 !important;
}
.page-header {
    padding: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: 50%;
}
.min-vh-100 {
    min-height: 100vh !important;
}
.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}
.flex-column {
    flex-direction: column !important;
}
.d-flex {
    display: flex !important;
}
.pe-0 {
    padding-right: 0 !important;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    box-shadow: 1px 1px 15px #0000003d;
}
.pb-0 {
    padding-bottom: 0!important;
}
.card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
    border-radius: 1rem 1rem 0 0;
}
.card .card-header {
    padding: 1.5rem;
}

.text-start {
    text-align: left!important;
}
.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.justify-content-center {
    justify-content: center !important;
}
.d-none {
    display: none !important;
}
.card.card-plain {
    background-color: transparent;
    box-shadow: none !important;
}
.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}
.card .card-body {
    font-family: "Open Sans", sans-serif;
    padding: 1.5rem;
}
.flex-column {
    flex-direction: column !important;
}
.h-100 {
    height: 100% !important;
}
.end-0 {
    right: 0 !important;
}
.top-0 {
    top: 0 !important;
}
.position-absolute {
    position: absolute !important;
}
.card-footer:last-child {
    border-radius: 0 0 1rem 1rem;
}
.card .card-footer {
    padding: 1.5rem;
    background-color: transparent;
}
.font-weight-bolder {
    font-weight: 700 !important;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.min-vh-50 {
    min-height: 50vh !important;
}
.align-items-start {
    align-items: flex-start !important;
}
.pb-11 {
    padding-bottom: 14rem !important;
}
.pt-5 {
    padding-top: 3rem !important;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: #495057!important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2d6da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.5rem;
    transition: box-shadow 0.15s ease, border-color 0.15s ease;
}
.form-control-lg {
    min-height: unset;
    padding: 0.75rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
}
.form-check.signup {
    display: block;
    min-height: 1.5rem;
    padding-left: 25px;
    margin-bottom: 0.125rem;
}
.form-check.signup .custom-control-label::before {
  top: 0.3rem;
  left: -1.45rem;
}
.form-switch {
    display: flex;
    align-items: center;
    padding-left: 3rem;
}
.form-check-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    float: left;
    width: 2.5rem;
    margin-left: -3rem;
    background-image: none;
    background-position: left center;
    border-radius: 2.5rem;
    transition: background-color 0.25s ease, border-color 0.25s ease, background-position 0.15s ease-in-out, opacity 0.15s ease-out, box-shadow 0.15s ease-in-out;
}
.form-check-label, .form-check-input[type=checkbox] {
    cursor: pointer;
}

.form-switch .form-check-input {
    border: 1px solid #e9ecef;
    position: relative;
    background-color: rgba(33, 37, 41, 0.1);
    height: 1.25em;
}

.form-switch .form-check-input:after {
    transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    position: absolute;
    background-color: #fff;
    transform: translateX(1px);
    box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07);
    top: 1px;
}
.form-check-label {
    font-size: 0.875rem;
    font-weight: 400;
}
.form-switch .form-check-label {
    margin-bottom: 0;
    margin-left: 0.5rem;
}
.text-sm {
    font-size: 0.875rem !important;
    line-height: 1.5;
}
.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}
.border-radius-lg {
    border-radius: 0.75rem;
}
.px-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
}
.m-3 {
    margin: 1rem !important;
}
.bg-gradient-dark {
    background-image: linear-gradient(310deg, #212229 0%, #212529 100%);
}
.mask {
    position: absolute;
    background-size: cover;
    background-position: center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
}
.opacity-6 {
    opacity: 0.6 !important;
}
.mt-n10 {
    margin-top: -12rem !important;
}
.z-index-0 {
    z-index: 0 !important;
}
.form-check-input {
    width: 1.23em;
    height: 1.23em;
    margin-top: 0.135em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    transition: background-color 0.25s ease, border-color 0.25s ease, background-position 0.15s ease-in-out, opacity 0.15s ease-out, box-shadow 0.15s ease-in-out;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.73em;
}
.form-check-input[type=checkbox] {
    border-radius: 0.35rem;
}
.form-check-input:checked {
    background-color: transparent;
    border-color: transparent;
}
.form-check-input:checked[type=checkbox] {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e);
}
.form-check:not(.form-switch) .form-check-input[type=checkbox], .form-check:not(.form-switch) .form-check-input[type=radio] {
    border: 1px solid #cbd3da;
    margin-top: 0.25rem;
    position: relative;
}
.form-check-input:checked[type=checkbox] {
    background-image: linear-gradient(310deg, #cd1818 0%, #ff1616 100%);
}
.form-check:not(.form-switch) .form-check-input[type=checkbox]:checked, .form-check:not(.form-switch) .form-check-input[type=radio]:checked {
    border: 0;
}
.form-check:not(.form-switch) .form-check-input[type=checkbox]:after {
    transition: opacity 0.25s ease-in-out;
    font-family: "FontAwesome";
    content: "\f00c";
    width: 100%;
    height: 100%;
    color: #fff;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.67rem;
    opacity: 0;
}
.form-check:not(.form-switch) .form-check-input[type=checkbox]:checked:after {
    opacity: 1;
} 
label, .form-label {
    font-size: 0.75rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #344767;
    margin-left: 0.25rem;
    display: inline-block;
}
.form-check-label {
    font-size: 0.875rem;
    font-weight: 400;
    display: inline-block;
    top: 4px;
    position: relative;
}
[type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
  left: -0.75px;
  z-index: 9;
  top: 0.76px;
}
.custom-control-label::before {
  border-radius: 7px;
}
@media (min-width: 992px) {
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .mt-lg-n10 {
    margin-top: -12rem !important;
  }
}
@media (min-width: 768px) {
  .mt-md-n11 {
    margin-top: -14rem !important;
  }
}

</style>
