<template>
  <div>
  <!----- Header Title ----->
  <section class="row">
    <div class="col-md-6">
      <div>
        <h3 class="page-title mb-1">
            {{ this.$route.meta.title }}
        </h3>
      </div>
      <div class="breadcrumb-container">
        <ul class="uk-breadcrumb">
          <li><span>Admin</span></li>
          <li><a href="/company">Service Provider</a></li>
          <li><a href="">{{ this.$route.meta.title }}</a></li>
        </ul>
      </div>
    </div>
    <div class="col-md-6 relative"></div>
  </section>
  <section class="tpui-container">
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-user mr-2"></i> Profile </h5>
              </div>
              <div class="col-md-6 text-right">
                <!-- <div class="switches-container w-200 uk-position-right" style="margin-right: 10px;">
                                    <input type="radio" id="active" name="status[type]" value="active" checked="checked" onclick="companyType()" autocomplete="off" required>
                                    <input type="radio" id="inactive" name="status[type]" value="inactive" onclick="companyType()" autocomplete="off" required>
                                    <label for="active" id="lbl_active"> Active </label>
                                    <label for="inactive" id="lbl_inactive"> Inactive </label>
                                    <div class="switch-wrapper">
                                      <div class="switch">
                                      <div id="lbl_active"> Active </div>
                                      <div id="lbl_inactive"> Inactive </div>
                                      </div>
                                    </div>
                                  </div> -->
              </div>
            </div>
            <fieldset class="mt-5">
              <div class="view-ui mt-2">
                <div class="row">
                  <div class="col-md-3 relative pb-5">
                    <div class="profile-img position-relative text-center" style="margin-top: -55px">
                      <span class="edit-btn">
                        <input
                          type="file"
                          name="company-img"
                          class="file-upload"
                          id="file"
                          ref="file"
                          v-on:change="handleFileUpload()"
                        />
                        <i class="fal fa-pen upload-button"></i> Change
                      </span>
                      <img
                        src="https://cdn.pixabay.com/photo/2016/03/26/20/35/young-man-1281282_960_720.jpg"
                        alt="Border pill"
                        uk-cover
                      />
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="p-3">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <input type="text" name="company" v-model="id" class="form-control" value="" autocomplete="off" required>
                            <label>ID Number</label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="row">
                            <div class="col-md-6 col-sm-12 pr-0">
                              <div class="form-group">
                                <input type="text" name="dob" v-model="dob" class="form-control" value="" autocomplete="off" required>
                                <label>Date of Birth</label>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12 pl-0">
                              <div class="form-group">
                                <input type="text" name="age" v-model="age" class="form-control" value="" autocomplete="off" required>
                                <label>Age</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group select-dropdown">
                            <select class="form-control" name="gender" autocomplete="off" required>
                              <option disabled selected>-- Select --</option>
                              <option value="Female">Female</option>
                              <option value="Male">Male</option>
                            </select>
                            <label>Gender</label>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-md-4">
                          <div class="form-group select-dropdown">
                            <select class="form-control" name="nationnality" autocomplete="off" required>
                              <option disabled selected>-- Select --</option>
                              <option value="Thailand">Thailand</option>
                              <option value="USA">USA</option>
                            </select>
                            <label>Nationality</label>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="form-group select-hidden select-dropdown">
                            <select name="language" id="language" class="form-control"  multiple="multiple" style="display: none;">
                              <option value="English">English</option>
                              <option value="Thai">Thai</option>
                              <option value="Chinese">Chinese</option>
                              <option value="Swedish">Swedish</option>
                              <option value="Russian">Russian</option>
                            </select>
                            <label>Spoken Language</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="col-md-12">
                    <div class="p-3">
                      <div class="row p-0 pb-0">
                        <div class="col-md-3">
                          <div class="form-group">
                            <input type="text" name="firstname_en" class="form-control" value="" autocomplete="off" required>
                            <label>First name (in English)</label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <input type="text" name="lastname_en" class="form-control" value="" autocomplete="off" required>
                            <label>Last name (in English)</label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <input type="text" name="firstname_th" class="form-control" value="" autocomplete="off" required>
                            <label>First name (in Thai)</label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <input type="text" name="lastname_th" class="form-control" value="" autocomplete="off" required>
                            <label>Last name (in Thai)</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <textarea rows="15" name="desc_en" id="desc_en" class="form-control" onkeyup="count_char(this, 1000, '.desc_en_count')" maxlength="1000" autocomplete="off" required></textarea>
                            <span class="uk-float-right mr-3 mb-3 charts uk-text-small uk-text-secondary"><span class="desc_en_count">0</span> / 1000</span>
                            <label for="desc_en"> Description (in English) </label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <textarea rows="15" name="desc_th" id="desc_th" class="form-control" onkeyup="count_char(this, 1000, '.desc_th_count')" maxlength="1000" autocomplete="off" required></textarea>
                            <span class="uk-float-right mr-3 mb-3 charts uk-text-small uk-text-secondary"><span class="desc_th_count">0</span> / 1000</span>
                            <label for="desc_th"> Description (in Thai) </label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <input type="text" name="personal_id" class="form-control" value="" autocomplete="off" required>
                            <label> ID Number </label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <input type="text" name="vat" class="form-control" value="" autocomplete="off" required>
                            <label> VAT Number </label>
                          </div>
                        </div>

                        <div class="col-md-12 mt-3 mb-3">
                          <hr />
                          <h6 class="uk-text-uppercase pl-1 mb-2 uk-text-bold">Service Area</h6>
                        </div>

                        <div class="col-md-12 mt-0 mb-0 pr-0">
                          <div class="form-group select-hidden select-dropdown">
                            <select
                              name="service"
                              id="servicearea"
                              class="form-control"
                              multiple="multiple"
                              style="display: none"
                            >
                              <option value="VIP Service">VIP Service</option>
                              <option value="Bodyguard Service">Bodyguard Service</option>
                              <option value="Security Service">Security Service</option>
                              <option value="Private Investigate">Private Investigate</option>
                              <option value="Education">Education</option>
                              <option value="Car Rental Service">Car Rental Service</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-12 mt-3 mb-3">
                          <hr />
                          <h6 class="uk-text-uppercase pl-1 uk-text-bold">Address</h6>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <input type="text" name="address" class="form-control" value="" autocomplete="off" required>
                            <label> Address </label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group select-dropdown">
                            <select class="form-control" name="country" autocomplete="off" required>
                              <option disabled selected>-- Select --</option>
                              <option>Thailand</option>
                            </select>
                            <label>Country</label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group select-dropdown">
                            <select class="form-control" name="city" autocomplete="off" required>
                              <option disabled selected>-- Select --</option>
                              <option>Pattaya</option>
                            </select>
                            <label>City</label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group select-dropdown">
                            <select class="form-control" name="district" autocomplete="off" required>
                              <option disabled selected>-- Select --</option>
                              <option>Banglamung</option>
                            </select>
                            <label>District</label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <input type="text" name="zipcode" class="form-control" value="" autocomplete="off" required>
                            <label> Zip Code </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 text-right pt-1 mt-0 mb-4 pb-1">
                    <button href="#" class="cancel-btn border-0">
                      <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                    </button>
                    <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                      <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                    </button>
                  </div>
                </div>
              </div>
            </fieldset>

            <!---- Contact Person ---->

            <fieldset style="background-color: rgb(237 243 255) !important; border: dashed 2px rgb(173 199 250) !important">
              <div class="row pl-3 pr-1">

                <div class="col-md-12 mt-3 mb-1 pt-3"><h6 class="uk-text-uppercase pl-1 uk-text-bold"> Contact</h6></div>

                <div class="col-md-12 mb-2">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group vue-tel-input-wrapper">
                        <template>
                          <vue-tel-input v-model="phone"></vue-tel-input>
                          <label>Phone Number</label>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <input type="text" name="company" class="form-control" value="" autocomplete="off" required>
                        <label> Email </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-0 mb-1 pt-0">
                  <hr class="mb-0 pb-0 pt-1 mt-0">
                  <h6 class="uk-text-uppercase pl-1 uk-text-bold mt-0">Emergency Contact</h6>
                </div>

                <div class="col-md-12 mb-2">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <input type="text" name="company" class="form-control" value="" autocomplete="off" required>
                        <label>Contact Person</label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <input type="text" name="company" class="form-control" value="" autocomplete="off" required>
                        <label>Relationship</label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group vue-tel-input-wrapper">
                        <template>
                          <vue-tel-input v-model="phone"></vue-tel-input>
                          <label>Phone Number</label>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <input type="text" name="company" class="form-control" value="" autocomplete="off" required>
                        <label> Email </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-right pt-1 mt-0 mb-4 pb-1">
                  <button href="#" class="cancel-btn border-0">
                    <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                  </button>
                  <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                    <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                  </button>
                </div>
              </div>
            </fieldset>

             <!---- Skill ---->

             <fieldset class="mt-1">
              <div class="row pl-3 pr-1">
                <div class="col-md-12 mt-4"><h6 class="uk-text-uppercase pl-1 uk-text-bold">Skill</h6></div>
                <div class="col-md-12 mb-2">
                          <div class="form-group select-hidden select-dropdown">
                            <select name="skill" id="skill" class="form-control"  multiple="multiple" style="display: none;">
                              <option value="">Karate</option>
                              <option value="">Sharpshooting</option>
                              <option value="">Krev Maga</option>
                              <option value="">Boxing</option>
                              <option value="">Drving Car</option>
                            </select>
                          </div>
                </div>
                <div class="col-md-12 text-right pt-1 mt-0 mb-4 pb-1">
                  <button href="#" class="cancel-btn border-0">
                    <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                  </button>
                  <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                    <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                  </button>
                </div>
              </div>
            </fieldset>

            <!---- Firearm (Por.12 Is A Must) ---->

            <fieldset style="background: none; border: none !important;">
                                <div class="row pl-0 pr-0">

                                      <div class="col-md-12 mt-3 pt-3"> <h6 class="uk-text-uppercase pl-1 uk-text-bold"><i class="fas fa-file-alt mr-2"></i> Firearm (Por.12 Is A Must) </h6> </div>

                                      <div class="col-md-12">
                                        <div class="table-responsive">
                                          <table class="table data-table table-round table-hover">
                                            <thead>
                                              <tr>
                                                <th width="75%" class="text-left">
                                                  Upload dated
                                                </th>
                                                <th width="15%" class="text-left">
                                                  File
                                                </th>
                                                <th class="blankhead" width="100px">

                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>

                                              <!--- If : already uploaded --->

                                              <tr>
                                                <td class="time" style="font-size: 12px;">
                                                  <i class="fal fa-calendar mr-1 relative" aria-hidden="true" style="top: 1px;"></i> 
                                                  14 AUG 2022 <span class="end-time ml-1" style="font-size: 11px;"> 20:03 </span>
                                                </td>
                                                <td class="text-left">
                                                  <p class="pt-0 pb-0 mt-0 mb-0"> file.pdf </p>
                                                </td>
                                                <td class="text-right">
                                                  <span uk-lightbox>
                                                    <a class="view-btn clickable only-icon pl-2 pr-2" href="https://www.itwsf.com/Portals/0/Holographic-Passport-Film.jpg?ver=1Mjc1ciIZ-jzbuNI7QL1Ow%3D%3D" data-caption="File.pdf"></a>
                                                  </span>
                                                  <a class="edit-btn pl-2 pr-2" data-toggle="collapse" data-target="#edit_firearm_file_1"><i class="fal fa-edit"></i></a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="7" class="p-0">
                                                  <div id="edit_firearm_file_1" class="collapse">
                                                    <form>
                                                      <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important;">
                                                        <div class="row p-0 pb-0 mt-0 mb-0 view-ui">
                                                          <div class="col-md-12 p-0 pb-0 mt-0 mb-0">
                                                            <div class="js-upload uk-placeholder uk-text-center mt-0 mb-0">
                                                                <span uk-icon="icon: cloud-upload" class="mr-2 mt-1"></span>
                                                                <span class="uk-text-middle">Upload or Drag & Drop here</span>
                                                                <div uk-form-custom>
                                                                    <input type="file" multiple>
                                                                </div>
                                                            </div>
                                                            <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </form>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colspan="7">
                                                  <a href="#" class="save-btn sendmail-btn border-0 mt-0 text-center border-radius-default uk-text-primary uk-text-uppercase" style="height: auto; width: auto !important; text-align: left !important;">
                                                    <i class="fas fa-plus mr-1" aria-hidden="true" style="font-size: 12px; display: block;"></i> Add more
                                                  </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                </div>
                            </fieldset>

            <!---- ID Card ---->

            <fieldset style="background: none; border: none !important">
              <div class="row pl-0 pr-0">
                <div class="col-md-12 mt-3 pt-3">
                  <h6 class="uk-text-uppercase pl-1 uk-text-bold"><i class="fas fa-file-alt mr-2"></i> ID Card </h6>
                </div>

                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table data-table table-round table-hover">
                      <thead>
                        <tr>
                          <th width="75%" class="text-left">Upload dated</th>
                          <th width="15%" class="text-left">File</th>
                          <th class="blankhead" width="100px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="time" style="font-size: 12px">
                            <i class="fal fa-calendar mr-1 relative" aria-hidden="true" style="top: 1px"></i>
                            14 AUG 2022 <span class="end-time ml-1" style="font-size: 11px"> 20:03 </span>
                          </td>
                          <td class="text-left">
                            <p class="pt-0 pb-0 mt-0 mb-0">File.pdf</p>
                          </td>
                          <td class="text-right">
                            <span uk-lightbox>
                              <a
                                class="view-btn clickable only-icon pl-2 pr-2"
                                href="https://www.itwsf.com/Portals/0/Holographic-Passport-Film.jpg?ver=1Mjc1ciIZ-jzbuNI7QL1Ow%3D%3D"
                                data-caption="File.pdf"
                              ></a>
                            </span>
                            <a class="edit-btn pl-2 pr-2" data-toggle="collapse" data-target="#edit_dbd_file_1"
                              ><i class="fal fa-edit"></i
                            ></a>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" class="p-0">
                            <div id="edit_dbd_file_1" class="collapse">
                              <form>
                                <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important">
                                  <div class="row p-0 pb-0 mt-0 mb-0 view-ui">
                                    <div class="col-md-12 p-0 pb-0 mt-0 mb-0">
                                      <div class="js-upload uk-placeholder uk-text-center mt-0 mb-0">
                                        <span uk-icon="icon: cloud-upload" class="mr-2 mt-1"></span>
                                        <span class="uk-text-middle">Upload or Drag & Drop here</span>
                                        <div uk-form-custom>
                                          <input type="file" multiple />
                                        </div>
                                      </div>
                                      <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7">
                            <a
                              href="#"
                              class="
                                save-btn
                                sendmail-btn
                                border-0
                                mt-0
                                text-center
                                border-radius-default
                                uk-text-primary uk-text-uppercase
                              "
                              style="height: auto; width: auto !important; text-align: left !important"
                            >
                              <i class="fas fa-plus mr-1" aria-hidden="true" style="font-size: 12px; display: block"></i> Add more
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </fieldset>

            <!---- Previous Invoice ---->

            <fieldset style="background: none; border: none !important">
              <div class="row pl-0 pr-0">
                <div class="col-md-12 mt-3 pt-3">
                  <h6 class="uk-text-uppercase pl-1 uk-text-bold"><i class="fas fa-file-alt mr-2"></i> Previous Invoice</h6>
                </div>

                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table data-table table-round table-hover">
                      <thead>
                        <tr>
                          <th width="75%" class="text-left">Upload dated</th>
                          <th width="15%" class="text-left">File</th>
                          <th class="blankhead" width="100px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="time" style="font-size: 12px">
                            <i class="fal fa-calendar mr-1 relative" aria-hidden="true" style="top: 1px"></i>
                            14 AUG 2022 <span class="end-time ml-1" style="font-size: 11px"> 20:03 </span>
                          </td>
                          <td class="text-left">
                            <p class="pt-0 pb-0 mt-0 mb-0">File.pdf</p>
                          </td>
                          <td class="text-right">
                            <span uk-lightbox>
                              <a
                                class="view-btn clickable only-icon pl-2 pr-2"
                                href="https://www.itwsf.com/Portals/0/Holographic-Passport-Film.jpg?ver=1Mjc1ciIZ-jzbuNI7QL1Ow%3D%3D"
                                data-caption="File.pdf"
                              ></a>
                            </span>
                            <a class="edit-btn pl-2 pr-2" data-toggle="collapse" data-target="#edit_invoice_file_1"
                              ><i class="fal fa-edit"></i
                            ></a>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" class="p-0">
                            <div id="edit_invoice_file_1" class="collapse">
                              <form>
                                <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important">
                                  <div class="row p-0 pb-0 mt-0 mb-0 view-ui">
                                    <div class="col-md-12 p-0 pb-0 mt-0 mb-0">
                                      <div class="js-upload uk-placeholder uk-text-center mt-0 mb-0">
                                        <span uk-icon="icon: cloud-upload" class="mr-2 mt-1"></span>
                                        <span class="uk-text-middle">Upload or Drag & Drop here</span>
                                        <div uk-form-custom>
                                          <input type="file" multiple />
                                        </div>
                                      </div>
                                      <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7">
                            <a
                              href="#"
                              class="
                                save-btn
                                sendmail-btn
                                border-0
                                mt-0
                                text-center
                                border-radius-default
                                uk-text-primary uk-text-uppercase
                              "
                              style="height: auto; width: auto !important; text-align: left !important"
                            >
                              <i class="fas fa-plus mr-1" aria-hidden="true" style="font-size: 12px; display: block"></i> Add more
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </fieldset>

            <!---- Document to Vefiry Locaiton ---->

            <fieldset style="background: none; border: none !important">
              <div class="row pl-0 pr-0">
                <div class="col-md-12 mt-3 pt-3">
                  <h6 class="uk-text-uppercase pl-1 uk-text-bold">
                    <i class="fas fa-file-alt mr-2"></i> Document to verify location
                  </h6>
                </div>

                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table data-table table-round table-hover">
                      <thead>
                        <tr>
                          <th width="75%" class="text-left">Upload dated</th>
                          <th width="15%" class="text-left">File</th>
                          <th class="blankhead" width="100px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="time" style="font-size: 12px">
                            <i class="fal fa-calendar mr-1 relative" aria-hidden="true" style="top: 1px"></i>
                            14 AUG 2022 <span class="end-time ml-1" style="font-size: 11px"> 20:03 </span>
                          </td>
                          <td class="text-left">
                            <p class="pt-0 pb-0 mt-0 mb-0">File.pdf</p>
                          </td>
                          <td class="text-right">
                            <span uk-lightbox>
                              <a
                                class="view-btn clickable only-icon pl-2 pr-2"
                                href="https://www.itwsf.com/Portals/0/Holographic-Passport-Film.jpg?ver=1Mjc1ciIZ-jzbuNI7QL1Ow%3D%3D"
                                data-caption="File.pdf"
                              ></a>
                            </span>
                            <a class="edit-btn pl-2 pr-2" data-toggle="collapse" data-target="#edit_verify_file_1"
                              ><i class="fal fa-edit"></i
                            ></a>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" class="p-0">
                            <div id="edit_verify_file_1" class="collapse">
                              <form>
                                <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important">
                                  <div class="row p-0 pb-0 mt-0 mb-0 view-ui">
                                    <div class="col-md-12 p-0 pb-0 mt-0 mb-0">
                                      <div class="js-upload uk-placeholder uk-text-center mt-0 mb-0">
                                        <span uk-icon="icon: cloud-upload" class="mr-2 mt-1"></span>
                                        <span class="uk-text-middle">Upload or Drag & Drop here</span>
                                        <div uk-form-custom>
                                          <input type="file" multiple />
                                        </div>
                                      </div>
                                      <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7">
                            <a
                              href="#"
                              class="
                                save-btn
                                sendmail-btn
                                border-0
                                mt-0
                                text-center
                                border-radius-default
                                uk-text-primary uk-text-uppercase
                              "
                              style="height: auto; width: auto !important; text-align: left !important"
                            >
                              <i class="fas fa-plus mr-1" aria-hidden="true" style="font-size: 12px; display: block"></i> Add more
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </section>

    <section class="tpui-container">
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-university mr-2"></i> Bank Details</h5>
              </div>
            </div>
            <div class="view-ui mt-4 mb-2">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <select class="form-control" name="language" autocomplete="off" required>
                      <option>Kasikorn Bank</option>
                    </select>
                    <label>Bank</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <input type="text" name="company" class="form-control" value="" autocomplete="off" required>
                    <label>Account Number</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <input type="text" name="company" class="form-control" value="" autocomplete="off" required>
                    <label>Branch Code</label>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="col-md-12">
                  <div class="row pl-0 pr-0">
                    <div class="col-md-12 mt-3 pt-3">
                      <h6 class="uk-text-uppercase pl-1 uk-text-bold">
                        <i class="fas fa-map-marker-alt mr-2"></i> Account Holder Details
                      </h6>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <input type="text" name="owner_name" class="form-control" value="" autocomplete="off" required>
                        <label>Owner Name</label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group vue-tel-input-wrapper">
                        <template>
                          <vue-tel-input v-model="phone"></vue-tel-input>
                          <label>Phone Number</label>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row p-0 pb-0 mt-3 view-ui">
                        <div class="col-md-12">
                          <div class="gmap-iframe ratio ratio-4x3 ratio-sm-21x9" lc-helper="gmap-embed">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3875.6647443463908!2d100.5592540929188!3d13.73873605017498!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x524dad67eb707a4b!2sThe%20Trendy%20Office%20Building!5e0!3m2!1sen!2sth!4v1646302645610!5m2!1sen!2sth"
                              width="100%"
                              height="450"
                              style="border: 0"
                              allowfullscreen=""
                              loading="lazy"
                            ></iframe>
                          </div>
                        </div>
                        <div class="col-md-12 pt-4">
                          <div class="uk-margin">
                            <div class="uk-inline uk-width-1-1">
                              <span class="uk-form-icon" uk-icon="icon: location"></span>
                              <input class="uk-input uk-width-1-1" type="text" autocomplete="off" required>
                              <label class="ml-5"> Address </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group select-box">
                            <select class="form-control" autocomplete="off" required>
                              <option>-- Select --</option>
                            </select>
                            <label>Province</label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <select class="form-control" autocomplete="off" required>
                              <option>-- Select --</option>
                            </select>
                            <label>District</label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <select class="form-control" autocomplete="off" required>
                              <option>-- Select --</option>
                            </select>
                            <label>Sub District</label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <input type="text" name="search" class="form-control" autocomplete="off" required>
                            <label> Zip code </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="col-md-12">
                  <div class="row pl-0 pr-0">
                    <div class="col-md-12 mt-3 pt-3">
                      <h6 class="uk-text-uppercase pl-1 uk-text-bold">
                        <i class="fas fa-file-alt mr-2"></i> Book Bank and Bank Statement
                      </h6>
                    </div>

                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table data-table table-round table-hover">
                          <thead>
                            <tr>
                              <th width="75%" class="text-left">Upload dated</th>
                              <th width="15%" class="text-left">File</th>
                              <th class="blankhead" width="100px"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="time" style="font-size: 12px">
                                <i class="fal fa-calendar mr-1 relative" aria-hidden="true" style="top: 1px"></i>
                                14 AUG 2022 <span class="end-time ml-1" style="font-size: 11px"> 20:03 </span>
                              </td>
                              <td class="text-left">
                                <p class="pt-0 pb-0 mt-0 mb-0">Passport.jpg</p>
                              </td>
                              <td class="text-right">
                                <span uk-lightbox>
                                  <a
                                    class="view-btn clickable only-icon pl-2 pr-2"
                                    href="https://www.itwsf.com/Portals/0/Holographic-Passport-Film.jpg?ver=1Mjc1ciIZ-jzbuNI7QL1Ow%3D%3D"
                                    data-caption="Passport.jpg"
                                  ></a>
                                </span>
                                <a class="edit-btn pl-2 pr-2" data-toggle="collapse" data-target="#edit_dbd_file_1"
                                  ><i class="fal fa-edit"></i
                                ></a>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="7" class="p-0">
                                <div id="edit_dbd_file_1" class="collapse">
                                  <form>
                                    <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important">
                                      <div class="row p-0 pb-0 mt-0 mb-0 view-ui">
                                        <div class="col-md-12 p-0 pb-0 mt-0 mb-0">
                                          <div class="js-upload uk-placeholder uk-text-center mt-0 mb-0">
                                            <span uk-icon="icon: cloud-upload" class="mr-2 mt-1"></span>
                                            <span class="uk-text-middle">Upload or Drag & Drop here</span>
                                            <div uk-form-custom>
                                              <input type="file" multiple />
                                            </div>
                                          </div>
                                          <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="7">
                                <a
                                  href="#"
                                  class="
                                    save-btn
                                    sendmail-btn
                                    border-0
                                    mt-0
                                    text-center
                                    border-radius-default
                                    uk-text-primary uk-text-uppercase
                                  "
                                  style="height: auto; width: auto !important; text-align: left !important"
                                >
                                  <i class="fas fa-plus mr-1" aria-hidden="true" style="font-size: 12px; display: block"></i> Add
                                  more
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                  <hr class="mt-2 mb-4" />
                  <button href="#" class="cancel-btn border-0">
                    <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                  </button>
                  <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                    <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4 pb-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-chart-bar mr-2"></i> Data</h5>
              </div>
            </div>
            <div class="view-ui mt-2 mb-2">
              <div class="row">
                <div class="col-md-12">
                  <ul class="dashboard-page">
                    <li class="one-value default blue">
                      <div class="icon">
                        <i class="far fa-male"></i>
                      </div>
                      <div class="result">
                        <div class="uk-text-center">
                          <div class="uk-width-1-1 main">
                            <span class="title">
                              <span class="primary-title mt-1"> ARMED </span>
                            </span>
                            <span class="value count border border-radius-default"> 1,520 </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="one-value default blue">
                      <div class="icon">
                        <i class="far fa-female"></i>
                      </div>
                      <div class="result">
                        <div class="uk-text-center">
                          <div class="uk-width-1-1 main">
                            <span class="title">
                              <span class="primary-title mt-1"> Profile </span>
                            </span>
                            <span class="value count border border-radius-default"> 1,520 </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="one-value default blue">
                      <div class="icon">
                        <i class="fab fa-uncharted"></i>
                      </div>
                      <div class="result">
                        <div class="uk-text-center">
                          <div class="uk-width-1-1 main">
                            <span class="title">
                              <span class="primary-title mt-1"> Vehicle </span>
                            </span>
                            <span class="value count border border-radius-default"> 1,520 </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="one-value default blue">
                      <div class="icon">
                        <i class="fal fa-car"></i>
                      </div>
                      <div class="result">
                        <div class="uk-text-center">
                          <div class="uk-width-1-1 main">
                            <span class="title">
                              <span class="primary-title mt-1"> Local/Expat </span>
                              <span class="sub-title"> </span>
                            </span>
                            <span class="value count border border-radius-default"> 1,520 </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-chart-bar mr-2"></i> Bodyguard price list</h5>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table data-table table-round table-hover">
                    <thead>
                      <tr>
                        <th width="16%" class="pl-4 pr-4">Period</th>
                        <th width="16%" class="pl-4 pr-4">Hours</th>
                        <th width="16%" class="pl-4 pr-4">Thai (Unarmed)</th>
                        <th width="16%" class="pl-4 pr-4">Thai (Armed)</th>
                        <th width="16%" class="pl-4 pr-4">Expat (Unarmed)</th>
                        <th width="16%" class="pl-4 pr-4">Expat (Armed)</th>
                        <th class="blankhead" width="auto"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="bg-white pl-4 pr-4 pt-3">
                          <div class="form-group select-dropdown mb-2 mt-0">
                            <select class="form-control uk-form-small pt-0" name="language" autocomplete="off" required>
                              <option disabled selected>-- Select --</option>
                              <option>Half Day</option>
                              <option>Day</option>
                              <option>Week</option>
                            </select>
                          </div>
                        </td>
                        <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                          <div class="form-group select-dropdown mb-2 mt-0">
                            <select class="form-control uk-form-small pt-0" name="language" autocomplete="off" required>
                              <option disabled selected>-- Select --</option>
                              <option>Half Day</option>
                              <option>Day</option>
                              <option>Week</option>
                            </select>
                          </div>
                        </td>
                        <td class="bg-white pl-4 pr-4 pl-4 pr-4 pt-3 pb-2">
                          <div class="form-group mb-2 mt-1">
                            <input type="text" name="company" class="form-control uk-form-small pt-0" value="" autocomplete="off" required>
                          </div>
                        </td>
                        <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                          <div class="form-group mb-2 mt-1">
                            <input type="text" name="company" class="form-control uk-form-small pt-0" value="" autocomplete="off" required>
                          </div>
                        </td>
                        <td class="bg-white pl-4 pr-4 pt-3 pb-2">
                          <div class="form-group mb-2 mt-1">
                            <input type="text" name="company" class="form-control uk-form-small pt-0" value="" autocomplete="off" required>
                          </div>
                        </td>
                        <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                          <div class="form-group mb-2 mt-1">
                            <input type="text" name="company" class="form-control uk-form-small pt-0" value="" autocomplete="off" required>
                          </div>
                        </td>
                        <td class="bg-white text-right">
                          <a class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                          <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="7">
                          <a href="#" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center"
                            ><i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-chart-bar mr-2"></i> Transportation price list</h5>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table data-table table-round table-hover">
                    <thead>
                      <tr>
                        <th class="blankhead" width="100px"></th>
                        <th width="14%" class="pl-4 pr-4">Brand</th>
                        <th width="14%" class="pl-4 pr-4">Model</th>
                        <th width="14%" class="pl-4 pr-4">Plat No.</th>
                        <th width="14%" class="pl-4 pr-4">Seats</th>
                        <th width="14%" class="pl-4 pr-4">Price</th>
                        <th width="14%" class="pl-4 pr-4">Period</th>
                        <th class="blankhead" width="auto"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="bg-white pt-3 text-center">
                          <img
                            class="uk-border-circle"
                            src="https://www.audi.com.my/media/TextBox_Image_Component/77543-paragraphs-895705-image/dh-1136-6ba340/3ea5de04/1660285277/1920x1080-aa5-sb-191004-oe.jpg"
                            width="50"
                            height="50"
                          />
                        </td>
                        <td class="bg-white pl-4 pr-4 pt-3">
                          <div class="form-group select-dropdown mb-2 mt-0">
                            <select class="form-control uk-form-small pt-0" name="language" autocomplete="off" required>
                              <option disabled selected>-- Select --</option>
                              <option>Audi</option>
                              <option>BMW</option>
                              <option>Mercedes</option>
                            </select>
                          </div>
                        </td>
                        <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                          <div class="form-group mb-2 mt-1">
                            <input type="text" name="company" class="form-control uk-form-small pt-0" value="" autocomplete="off" required>
                          </div>
                        </td>
                        <td class="bg-white pl-4 pr-4 pl-4 pr-4 pt-3 pb-2">
                          <div class="form-group mb-2 mt-1">
                            <input type="text" name="company" class="form-control uk-form-small pt-0" value="" autocomplete="off" required>
                          </div>
                        </td>
                        <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                          <div class="form-group mb-2 mt-1">
                            <input type="text" name="company" class="form-control uk-form-small pt-0" value="" autocomplete="off" required>
                          </div>
                        </td>
                        <td class="bg-white pl-4 pr-4 pt-3 pb-2">
                          <div class="form-group mb-2 mt-1">
                            <input type="text" name="company" class="form-control uk-form-small pt-0" value="" autocomplete="off" required>
                          </div>
                        </td>
                        <td class="bg-lightgray pl-4 pr-4 pt-3">
                          <div class="form-group select-dropdown mb-2 mt-0">
                            <select class="form-control uk-form-small pt-0" name="language" autocomplete="off" required>
                              <option disabled selected>-- Select --</option>
                              <option>Half Day</option>
                              <option>Hour</option>
                              <option>Week</option>
                            </select>
                          </div>
                        </td>
                        <td class="bg-white text-right">
                          <a class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                          <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="7">
                          <a href="#" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center"
                            ><i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12"></div>
            </div>

            <div class="row mt-5">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-chart-bar mr-2"></i> Price escort price list</h5>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table data-table table-round table-hover">
                    <thead>
                      <tr>
                        <th width="23%" class="pl-5 pr-5">Type of Vehicle</th>
                        <th width="23%" class="pl-5 pr-5">No. Of Vehicle</th>
                        <th width="23%" class="pl-5 pr-5">Price</th>
                        <th width="23%" class="pl-5 pr-5">Period</th>
                        <th class="blankhead" width="auto"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="bg-white pl-5 pr-5 pt-3">
                          <div class="form-group select-dropdown mb-2 mt-0">
                            <select class="form-control uk-form-small pt-0" name="language" autocomplete="off" required>
                              <option disabled selected>-- Select --</option>
                              <option>Car</option>
                              <option>Motorbike</option>
                              <option>Car</option>
                            </select>
                          </div>
                        </td>
                        <td class="bg-white pl-5 pr-5 pt-3">
                          <div class="form-group select-dropdown mb-2 mt-0">
                            <select class="form-control uk-form-small pt-0" name="language" autocomplete="off" required>
                              <option disabled selected>-- Select --</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                            </select>
                          </div>
                        </td>
                        <td class="bg-white pl-5 pr-5 pt-3 pb-2">
                          <div class="form-group mb-2 mt-1">
                            <input type="text" name="company" class="form-control uk-form-small pt-0" value="" autocomplete="off" required>
                          </div>
                        </td>
                        <td class="bg-lightgray pl-5 pr-5 pt-3">
                          <div class="form-group select-dropdown mb-2 mt-0">
                            <select class="form-control uk-form-small pt-0" name="language" autocomplete="off" required>
                              <option disabled selected>-- Select --</option>
                              <option>Half Day</option>
                              <option>Day</option>
                              <option>Week</option>
                            </select>
                          </div>
                        </td>
                        <td class="bg-white text-right">
                          <a class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                          <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="5" class="pl-3 pr-5">
                          <a href="#" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center"
                            ><i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="col-md-12 text-right pt-4 mt-0 mb-4">
                <hr class="mt-2 mb-4" />
                <button href="#" class="cancel-btn border-0">
                  <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                </button>
                <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                  <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-info-circle mr-2"></i> Legal Agreement</h5>
              </div>
            </div>
            <div class="view-ui mt-4 mb-2">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-lg-12">
                      <ul uk-tab>
                        <li class="uk-active pl-0"><a href="#" class="no-bullet">Legal Agreement (English)</a></li>
                        <li><a href="#" class="no-bullet">Legal Agreement (Thai)</a></li>
                      </ul>
                      <ul class="uk-switcher uk-margin">
                        <li>
                          <div class="form-group">
                            <client-only>
                              <VueEditor />
                            </client-only>
                          </div>
                        </li>
                        <li>
                          <div class="form-group">
                            <client-only>
                              <VueEditor />
                            </client-only>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 pl-4 pr-1 pb-0">
                  <div class="row w-100 bg-lightgray border-radius-default pb-0 mb-0">
                    <div class="col-md-2 mb-0 pb-0">
                      <div class="p-3 pb-0 mb-0">
                        <span class="uk-text-uppercase"> Signed date </span>
                        <p class="uk-text-uppercase mt-0 pt-2 pb-0 mb-0">25 Aug 2022</p>
                      </div>
                    </div>
                    <div class="col-md-3 mb-0 pb-0">
                      <div class="p-3 pb-0 mb-0">
                        <span class="uk-text-uppercase"> Signed From </span>
                        <p class="uk-text-uppercase mt-0 pt-2 pb-0 mb-0">Macbook Pro 2021 - M1 | Safari</p>
                      </div>
                    </div>
                    <div class="col-md-2 mb-0 pb-0">
                      <div class="p-3 pb-0 mb-0">
                        <span class="uk-text-uppercase"> IP Address </span>
                        <p class="uk-text-uppercase mt-0 pt-2 pb-0 mb-0">171.50.49.132</p>
                      </div>
                    </div>
                    <div class="col-md-5 mb-0 pb-0 text-right">
                      <button href="#" id="client" class="save-btn download-btn border-0 ml-2 mr-2 mt-4 mb-2 uk-position-right">
                        <i class="fas fa-arrow-to-bottom mr-2"></i> Download PDF
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>


  </div>
</template>


<script>
  export default {
    mounted() {

      // Multi Selection

      $("#language").bsMultiSelect({
        containerClass:"dashboardcode-bsmultiselect",
      });

      $(function () {
        $('#servicearea').bsMultiSelect({
          containerClass: 'dashboardcode-bsmultiselect-large',
        })
      });

      $("#skill").bsMultiSelect({
        containerClass:"dashboardcode-bsmultiselect-large",
      });

    }
  }
</script>