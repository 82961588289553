<template>
  <div>
    <Header />   
    <section class="tpui-container">
      <div class="row align-items-center mb-4">
        <div class="col-md-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-user mr-2"></i> New Admin Information</h5>
              </div>
            </div>
            <div class="view-ui mt-2">
              <form @submit.prevent="submitAdminData">
                <div class="row">
                  <div class="col-md-3 relative pb-5">
                    <div class="profile-img position-relative text-center non-img mt-4">
                      <span class="add-btn">
                        <input
                          type="file"
                          class="file-upload"
                          id="file"
                          ref="profile"
                          v-on:change="handleFileUpload()"
                        />
                        <i class="fal fa-plus mr-1"></i> Add
                      </span>
											<img v-if="url" :src="url" alt="Profile" uk-cover>
                    </div>
                    <div class="col-md-9"></div>
                  </div>
                  <div class="col-md-9">
                    <div class="p-3 pt-0">
                      <div class="row p-0 pb-0 pt-0">
                        <div class="col-md-12 mt-0 pl-3 mb-0 pb-0 mb-0">
                          <h6 class="uk-text-uppercase pb-0 mb-2 pt-0 mt-0" style="color: #a5a5a5">Information</h6>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <input type="text" v-model="formData.first_name" class="form-control" required />
                            <label>First name <span style="color: red">{{ isRequired('first_name') }} </span></label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <input type="text" v-model="formData.last_name" class="form-control" required />
                            <label>Last name <span style="color: red">{{ isRequired('last_name') }} </span></label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <select v-model="formData.gender" class="form-control" required>
                              <option value="">-- Select --</option>
                              <option v-for="(genderOption, genderIndex) in genderOptions" :key="genderIndex" :value="genderOption.value" required>
                                {{ genderOption.text }}
                              </option>
                            </select>
                            <label>Gender <span style="color: red">{{ isRequired('gender') }} </span></label>
                          </div>
                        </div>
                        <div class="col-md-4 pr-0">
                          <div class="form-group">
                            <input type="text" v-model="formData.dob" class="form-control" required />
                            <label> Date of Birth <span style="color: red">{{ isRequired('dob') }} </span></label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <input type="text" v-model="formData.nationality" class="form-control" required />
                            <label>Nationality <span style="color: red">{{ isRequired('nationality') }} </span></label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <select v-model="formData.position" class="form-control" required>
                              <option value="">-- Select --</option>
                              <option v-for="(position, positionIndex) in positions" :key="positionIndex" :value="position.value">
                                {{ position.text }}
                              </option>
                            </select>
                            <label>Position <span style="color: red">{{ isRequired('position') }} </span></label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <select v-model="formData.role_id" class="form-control" required>
                              <option value="">-- Select --</option>
                              <option v-for="(role, roleIndex) in roles" :key="roleIndex" :value="role.value">
                                {{ role.text }}
                              </option>
                            </select>
                            <label>Role <span style="color: red">{{ isRequired('role') }} </span></label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <input type="password" v-model="formData.password" id="password" class="form-control" required />
                            <label for="password">Password <span style="color: red">{{ isRequired('password') }} </span></label>
                          </div> 
                        </div>

                        <div class="clearfix"></div>
                        <div class="col-md-12 mt-2 pl-3 pb-0 mb-0">
                          <h6 class="uk-text-uppercase pb-0 mb-2 pt-0 mt-0" style="color: #a5a5a5">Contact</h6>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group">
                            <input type="text" v-model="formData.email" class="form-control" required />
                            <label> Email <span style="color: red">{{ isRequired('email') }} </span></label>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group vue-tel-input-wrapper">
                            <template>
                              <vue-tel-input v-model="formData.phone"></vue-tel-input>
                              <label>Phone Number <span style="color: red">{{ isRequired('email') }} </span></label>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row p-4">
                  <!-- <div class="col-md-12 text-left">
                    <h6 class="uk-text-uppercase pb-0 mb-2 pt-0 mt-0" style="color: #a5a5a5">Contact</h6>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="text" name="company" class="form-control" value="" required />
                      <label> Address </label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <select class="form-control" required>
                        <option>-- Select --</option>
                        <option>Admin</option>
                      </select>
                      <label>Position</label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <select class="form-control" required>
                        <option>-- Select --</option>
                        <option>Admin</option>
                      </select>
                      <label>Position</label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <select class="form-control" required>
                        <option>-- Select --</option>
                        <option>Admin</option>
                      </select>
                      <label>Position</label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <input type="text" name="company" class="form-control" value="" required />
                      <label> Zip Code </label>
                    </div>
                  </div> -->
                  <div class="col-md-12 text-right">
                    <hr class="mt-3 mb-3" style="border-color: #e1e1e1; background: none" />
                    <div role="group" aria-label="Basic example" class="btn-group mt-2">
                      <button @click="redirectList" class="cancel-btn border-0">
                        <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                      </button>
                      <button type="submit" id="client" class="save-btn border-0 ml-2 mr-2">
                        <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  mounted() {
  },
  data() {
    return {
        formData: {
          first_name: '',
          last_name: '',
          dob: '',
          nationality: '',
          position: '',
          phone: '',
          email: '',
          gender: '',
          avatar: null,
          role_id: 0,
          password: '',
        },
        requiredFields: {},
        url: null,
        genderOptions: [
          { text: 'Female', value: 'female' },
          { text: 'Male', value: 'male' },
        ],
        positions: [
          { text: 'CEO', value: 'CEO'},
          { text: 'Project Manager', value: 'Project Manager'},
        ],
        roles: [
          { text: 'Admin', value: 1},
          { text: 'Staff', value: 2},
        ],
        errorMessage: [],
        headerdata: {
            route: '',
            search: false,
            addBtn: false
        }
    }
  },
	methods: {
    isRequired(key) {
      if (this.requiredFields.hasOwnProperty(key)) {
        return '*'
      } else {
        return ''
      }
    },
		submitAdminData(){
			const api = 'admin/admins'
      var formData1 = new FormData();
			formData1.append('avatar', this.formData.avatar)
			formData1.append('email', this.formData.email)
			formData1.append('first_name', this.formData.first_name)
			formData1.append('last_name', this.formData.last_name)
			formData1.append('dob', this.formData.dob)
			formData1.append('nationality', this.formData.nationality)
			formData1.append('position', this.formData.position)
			formData1.append('phone', this.formData.phone)
			formData1.append('gender', this.formData.gender)
			formData1.append('role_id', this.formData.role_id)
			formData1.append('password', this.formData.password)
			axios
				.post(api, formData1, {
            headers: {
                    'Content-Type': 'multipart/form-data',
            }
        })
				.then((response) => {
					let resData = response.data;
          console.log(resData)
					if(resData.success) {

            // this.noti()
						// this.redirectList()
					}else{
						this.requiredFields = resData
					}
				})
				.catch((error) => {
					this.errorMessage = error.message
					console.error('There was an error!', error)
				})
		},
		/*
    *	Handles a change on the file upload
    */
		handleFileUpload() {
			this.formData.avatar = this.$refs.profile.files[0]
			this.url = URL.createObjectURL(this.formData.avatar)
		},
    redirectList() {
      this.$router.push('/staff');
    },
    noti() {
      var notyf = new Notyf();

        setTimeout(function () {
            notyf.confirm('Created Admin');
        }, 2000)
    }
	}
}

</script>