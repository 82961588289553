<template>
    <div>
        <div class="row">
            <div class="col-md-12 text-left">
                <h5 class="mt-1 mb-3 uk-text-bold">
                    <i class="fas fa-user mr-2"></i> Profile
                </h5>
            </div>
        </div>
        <fieldset class="mt-5">
            <div class="view-ui mt-2">
                <div class="row">
                    <div class="col-md-3 relative pb-5">
                        <div class="profile-img position-relative text-center" style="margin-top: -35px">
                            <div class="img-wrapper">
                                <img v-if="logoUrl" :src="logoUrl"  alt="Company Profile" uk-cover />
                                <span class="edit-btn">
                                    <input
                                        type="file"
                                        name="logo"
                                        class="file-upload"
                                        id="file"
                                        ref="logo"
                                        v-on:change="handleFileUpload()"
                                    />
                                </span>
                            </div>
                            <span class="delete-btn"></span>
                        </div>
                    </div>

                    <div class="col-md-9">
                        <EditProfileMission />
                    </div>

                    <div class="clearfix"></div>
                    <div class="col-md-12">
                        <div class="p-3">
                            <h6 class="uk-text-uppercase pl-1 uk-text-bold">Information</h6>
                            <div class="row p-0 pb-0">
                                <div class="col-md-6">
                                    <div class="form-group">
                                    <input type="text" name="name_en" class="form-control" v-model="companyDetail.name_en" autofocus required />
                                    <label>Name (In English)</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                    <input type="text" name="name_local" class="form-control" v-model="companyDetail.name_local" autofocus required />
                                    <label>Name (In Thai)</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <textarea name="description_en" rows="15" class="form-control" v-model="companyDetail.description_en" required></textarea>
                                        <label for="desc_en"> Description in English </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <textarea name="description_local" rows="15" class="form-control" v-model="companyDetail.description_local" required></textarea>
                                        <label for="desc_th"> Description in Thai </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" name="registration_id" class="form-control" v-model="companyDetail.registration_id" required />
                                        <label> Registration ID </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" name="tax_id" class="form-control" v-model="companyDetail.tax_id" required />
                                        <label>Tax ID</label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <select id="founded_year" name="founded_year" v-model="companyDetail.founded_year" required>
                                            <option selected> -- Select -- </option>
                                            <option value="2022"> 2022 </option>
                                            <option value="2021"> 2021 </option>
                                            <option value="2020"> 2020 </option>
                                        </select>
                                        <label for="founded_year">Founded Year</label>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-3 mb-3">
                                    <hr />
                                    <h6 class="uk-text-uppercase pl-1 uk-text-bold">Address</h6>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="company" class="form-control" value="" required />
                                        <label> Address </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <select class="form-control" name="language" required>
                                        <option>Thailand</option>
                                        </select>
                                        <label>Country</label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <select class="form-control" name="language" required>
                                        <option>Pattaya</option>
                                        </select>
                                        <label>City</label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <select class="form-control" name="language" required>
                                        <option>Banglamung</option>
                                        </select>
                                        <label>District</label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" name="company" class="form-control" value="21223" required />
                                        <label> Zip Code </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 text-right pt-5 mt-0 mb-4 pb-1">
                        <button href="#" class="cancel-btn border-0">
                            <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                        </button>
                        <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                            <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                        </button>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
import EditProfileMission from "./EditProfileMission.vue"

export default {
    components: {
        EditProfileMission
    },
    props: {
        companyDetail: {
            id: Number,
            logo: null,
            name_en: String,
            name_local: String,
            description_en: String,
            description_local: String,
            has_parent_company: Boolean,

            registration_id: String,
            tax_id: String,
            founded_year: Number,

            country: String,
            address: String,
            province: String,
            district: String,
            sub_district: String,
            zip_code: Number,

            // company_owner_name: String,
            // company_website: String,
            company_lat: null,
            company_lng: null,

        }
    },
    mounted() {
        
    },
    data() {
        return {
            logoUrl: '',
        }
    },
    methods: {
        updateCompany() {
            this.loading()
            const api = 'admin/agencies/' + this.companyDetail.id + '/company'
            axios
                .post(api, this.getFormData(), {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                })
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.$helpers.notification(true, 'Updated!')
                    }else{
                        this.$helpers.notification(false, response.data.message)
                    }
                })
                .catch((error) => {
                    this.$helpers.notification(false, error.message)
                    console.error('There was an error!', error.message)
                })
        },
        getFormData() {
            let form = this.$refs.updateCompanyForm;
            let formData = new FormData(form);

            
            formData.append('company_tax_id', formData.get('tax_id'))
            formData.append('company_name_en', formData.get('name_en'))
            formData.append('company_name_local', formData.get('name_local'))
            formData.append('company_registration_id', formData.get('registration_id'))

            return formData
        },
        handleFileUpload() {
            this.companyDetail.logo = this.$refs.logo.files[0]
            this.logoUrl = URL.createObjectURL(this.companyDetail.logo)
        },
        loading() {
            this.$toast.loading({
                message: 'Loading...',
                forbidClick: true,
            })
        },
       
    }
}
</script>