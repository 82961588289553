<template>
    <div class="view-ui mt-2">
        <form @submit.prevent="updateCompany" ref="updateCompanyForm">
            <div class="row">
                <div class="col-md-3 relative pb-5">
                    <div class="profile-img position-relative text-center" style="margin-top: -55px">
                        <span class="edit-btn">
                            <input
                                type="file"
                                name="logo"
                                class="file-upload"
                                id="file"
                                ref="logo"
                                v-on:change="handleFileUpload()"
                            />
                            <i class="fal fa-pen upload-button"></i> Change
                        </span>
                        <img v-if="logoUrl" :src="logoUrl"  alt="Company Profile" uk-cover />
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="p-3">
                        <ul class="dashboard-page">
                        <!-- <li class="one-value default">
                            <div class="icon">
                            <i class="far fa-bullseye-arrow"></i>
                            </div>
                            <div class="result">
                            <div class="uk-text-center">
                                <div class="uk-width-1-1 main">
                                <span class="title">
                                    <span class="primary-title"> Mission </span>
                                    <span class="sub-title"> Cases </span>
                                </span>
                                <span class="value count"> 16,520 </span>
                                </div>
                            </div>
                            </div>
                        </li>
                        <li class="one-value default">
                            <div class="icon">
                            <i class="fal fa-sack-dollar"></i>
                            </div>
                            <div class="result">
                            <div class="uk-text-center">
                                <div class="uk-width-1-1 main">
                                <span class="title">
                                    <span class="primary-title"> Revenue </span>
                                    <span class="sub-title"> Net Amount </span>
                                </span>
                                <span class="value count"> 16,520 </span>
                                </div>
                            </div>
                            </div>
                        </li>
                        <li class="one-value default">
                            <div class="icon">
                            <i class="far fa-chart-line"></i>
                            </div>
                            <div class="result">
                            <div class="uk-text-center">
                                <div class="uk-width-1-1 main">
                                <span class="title">
                                    <span class="primary-title"> Profit </span>
                                    <span class="sub-title"> Net Amount </span>
                                </span>
                                <span class="value count"> 16,520 </span>
                                </div>
                            </div>
                            </div>
                        </li> -->
                        </ul>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="col-md-12">
                    <div class="p-3">
                        <h6 class="uk-text-uppercase pl-1 uk-text-bold">Information</h6>
                        <div class="row p-0 pb-0">
                            <div class="col-md-6">
                                <div class="form-group">
                                <input type="text" name="name_en" class="form-control" v-model="companyDetail.name_en" autofocus required />
                                <label>Name (In English)</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                <input type="text" name="name_local" class="form-control" v-model="companyDetail.name_local" autofocus required />
                                <label>Name (In Thai)</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <textarea name="description_en" rows="15" class="form-control" v-model="companyDetail.description_en" required></textarea>
                                    <label for="desc_en"> Description in English </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <textarea name="description_local" rows="15" class="form-control" v-model="companyDetail.description_local" required></textarea>
                                    <label for="desc_th"> Description in Thai </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" name="registration_id" class="form-control" v-model="companyDetail.registration_id" required />
                                    <label> Registration ID </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" name="tax_id" class="form-control" v-model="companyDetail.tax_id" required />
                                    <label>Tax ID</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <select id="founded_year" name="founded_year" v-model="companyDetail.founded_year" required>
                                        <option selected> -- Select -- </option>
                                        <option value="2022"> 2022 </option>
                                        <option value="2021"> 2021 </option>
                                        <option value="2020"> 2020 </option>
                                    </select>
                                    <label for="founded_year">Founded Year</label>
                                </div>
                            </div>

                            <div class="col-md-12 mt-3 mb-3">
                                <hr />
                                <h6 class="uk-text-uppercase pl-1 mb-2 uk-text-bold">Service Area</h6>
                            </div>

                            <div class="col-md-12 mt-0 mb-0 pr-0">
                                <div class="form-group select-hidden">
                                    <select
                                        name="service"
                                        id="servicearea"
                                        class="form-control"
                                        multiple="multiple"
                                        style="display: none"
                                    >
                                        <option selected value="VIP Service">VIP Service</option>
                                        <option selected value="Bodyguard Service">Bodyguard Service</option>
                                        <option value="Security Service">Security Service</option>
                                        <option value="Private Investigate">Private Investigate</option>
                                        <option value="Education">Education</option>
                                        <option value="Car Rental Service">Car Rental Service</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-12 mt-3 mb-3">
                                <hr />
                                <h6 class="uk-text-uppercase pl-1 uk-text-bold">Address</h6>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" name="company" class="form-control" value="" required />
                                    <label> Address </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <select class="form-control" name="language" required>
                                    <option>Thailand</option>
                                    </select>
                                    <label>Country</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <select class="form-control" name="language" required>
                                    <option>Pattaya</option>
                                    </select>
                                    <label>City</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <select class="form-control" name="language" required>
                                    <option>Banglamung</option>
                                    </select>
                                    <label>District</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" name="company" class="form-control" value="21223" required />
                                    <label> Zip Code </label>
                                </div>
                            </div>
                            <div class="col-sm-12 text-right">
                                <button type="submit" id="client" class="save-btn ml-2 mr-2" style="border:0px">
                                    <i class="fas fa-save mr-2"></i> save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    props: {
        companyDetail: {
            id: Number,
            logo: null,
            name_en: String,
            name_local: String,
            description_en: String,
            description_local: String,
            has_parent_company: Boolean,

            registration_id: String,
            tax_id: String,
            founded_year: Number,

            country: String,
            address: String,
            province: String,
            district: String,
            sub_district: String,
            zip_code: Number,

            // company_owner_name: String,
            // company_website: String,
            company_lat: null,
            company_lng: null,

        }
    },
    mounted() {
        $(function () {
            $('#servicearea').bsMultiSelect({
                containerClass: 'dashboardcode-bsmultiselect-large',
            })
        });
    },
    data() {
        return {
            logoUrl: '',
            requiredfileds:[]
        }
    },
    methods: {
        updateCompany() {
            this.loading()
            const api = 'admin/agencies/' + this.companyDetail.id + '/company'
            axios
                .post(api, this.getFormData(), {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                })
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        getFormData() {
            let form = this.$refs.updateCompanyForm;
            let formData = new FormData(form);

            
            formData.append('company_tax_id', formData.get('tax_id'))
            formData.append('company_name_en', formData.get('name_en'))
            formData.append('company_name_local', formData.get('name_local'))
            formData.append('company_registration_id', formData.get('registration_id'))

            return formData
        },
        handleFileUpload() {
            this.companyDetail.logo = this.$refs.logo.files[0]
            this.logoUrl = URL.createObjectURL(this.companyDetail.logo)
        },
        loading() {
            this.$toast.loading({
                message: 'Loading...',
                forbidClick: true,
            })
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },
    }
}
</script>