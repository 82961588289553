<template>
  <div>
    <!----- Header Title ----->
    <section>
      <div class="row content-header company">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
              {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><router-link :to="'/approval-image/gallery'"><a>{{ this.$route.meta.title }}</a></router-link></li>
              <li><router-link :to="'/approval-image/gallery'"><a> Gallery</a></router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative">
          <div class="pb-3 pl-2 pt-5 mt-3 uk-float-right">
            <div class="form-group search notify-btn mb-1">
                    <input type="text" name="keyword" placeholder="Search for..." class="form-control shadow-none border-0" />
                    <div class="input-group-prepend">
                      <span class="input-group-text border-0 bg-transparent">
                        <button type="submit" class="bg-transparent border-0"><i class="far fa-search"></i></button>
                      </span>
                    </div>
            </div>
          </div>
          <div class="pt-5 mt-3 uk-float-right">
            <div class="mb-3 text-right">
              <router-link :to="'/approval-image/gallery/rejected'">
                <a class="uk-button uk-button-light uk-text-danger pt-1"><i class="fad fa-external-link-alt mr-2"></i> Rejcted List</a>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-12 pt-4 pb-2">
            <ul class="filter d-md-inline-flex d-none">
              <li v-for="(list, index) in tablist" :key="list.path + index" :class="index === tabselected ? 'active' : ''">
                <router-link class="pl-3 pr-3" :to="list.path" tag="a">
                  {{ list.name }}
                </router-link>
              </li>
            </ul>
          </div>
      </div>
    </section>

    <section class="tpui-container mb-5">
      <div class="row">
        <div class="col-lg-12 pt-4">
          <div class="uk-child-width-1-5@s uk-grid-small uk-grid-match" uk-grid>
            <div v-for="(list, index) in imageProfileList" :key="index">
                <div class="grey-box uk-card uk-card-default uk-card-body uk-padding-small">
                  <div class="img-wrapper uk-cover-container uk-height-small" uk-lightbox>
                    <a :href="list.image">
                      <img :src="list.image">
                    </a>

                    <label>
                      {{ list.name }}
                    </label>
                  </div>
                  <div class="form-group select-dropdown w-100 mt-3">
                        <select class="form-control uk-form-small pt-0 uk-text-uppercase" v-model="list.status" name="approval">
                          <option value="accepted" class="uk-text-secondary"> Accept </option>
                          <option value="not_clear" class="uk-text-danger"> Not Clear </option>
                          <option value="not_relevant" class="uk-text-danger"> Not Relevant </option>
                          <option value="contain_contact_information" class="uk-text-danger"> Not Clear </option>
                          <option value="inappropriate_content" class="uk-text-danger"> Inappropriate Content </option>
                          <option value="other" class="uk-text-danger"> Other </option>
                        </select>
                  </div>
                  <div class="pt-3">
                    <div class="uk-grid-small" uk-grid>
                      <div class="uk-width-auto">
                        <img :src="list.avatar" class="uk-border-circle" style="width: 35px; height: 35px;"> 
                      </div>
                      <div class="uk-width-expand">
                          <h6 class="uk-text-normal uk-margin-remove-bottom uk-text-secondary" v-if="list.full_name != null"><router-link :to="'customer/edit/' + list.id" class="uk-text-secondary">{{ list.full_name }}</router-link></h6>
                          <p class="time pt-0 mt-1 uk-text-small">{{ list.date }}<span class="end-time ml-1" style="font-size: 10px;"> {{ list.time}} </span> </p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 pt-1 uk-text-left">
          <button id="btn-perform-all" class="save-btn border-0 mt-4 uk-text-uppercase" v-on:click="performAll()">
            <i class="far fa-check-square uk-margin-small-right"></i> Perform All
          </button>
        </div>
        <div class="col-md-9 col-sm-12 uk-text-right">
          <div class="card-footer pr-0 pl-0" v-if="numberOfPage > 1">
            <jw-pagination
              :items="pages"
              @changePage="onChangePage"
              :pageSize="limit"
              :styles="customStyles"
              :labels="customLabels"
            ></jw-pagination>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
const customLabels = {
  first: '<<',
  last: '>>',
  previous: '<',
  next: '>',
}
const customStyles = {
  ul: {},
  li: {
    display: 'inline-block',

    margin: '5px',
  },
  a: {
    color: 'blue',
    margin: '5px',
  },
}
export default {
  mounted() {
    this.getApprovalAccountList(this.page, this.limit, '');

  },
  data() {
    return {
      params: {
        sort: { column: 'id', type: 'desc' },
      },
      tabselected: 1,
      tablist: [
        { name: 'Profile', path: '/approval-image/profile' },
        { name: 'Gallery', path: '/approval-image/gallery' },
        { name: 'Video', path: '/approval-image/video' },
      ],
      keyword: '',
      firstload: false,
      customStyles: customStyles,
      customLabels: customLabels,
      numberOfPage: 0,
      pages: [],
      page: 1,
      limit: 10,
      customerType:'',
      sort: 'id',
      orderby: 'asc',
      imageProfileList: [],
    }
  },
  methods: {
    performAll(){
      let imageProfileList = []
      for(var i = 0;i<this.imageProfileList.length;i++){
        imageProfileList.push({id:this.imageProfileList[i].id,status:this.imageProfileList[i].status})
      }
      let payload = {"images":imageProfileList}
      console.log(payload)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/approval-images/update'
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .post(api, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${admintoken}`,
          },
        })
        .then((response) => {
          console.log(response.data)
          if (response.data.code == 200) {
            console.log(response.data)
            location.reload()
          } else {
            this.errorMessage = response.data.message
            this.$dialog.alert({
              title: 'Failed',
              message: this.errorMessage,
              showCancelButton: false,
              confirmButtonText: 'OK',
            })
          }
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    del_method: function (id, name) {
      UIkit.modal.confirm('Do you want to delete user: ' + name + " ? ").then(function () {
        console.log('Confirmed.')
      }, function () {
        console.log('Rejected.')
      });
    },
    filterType(type){
        this.customerType = type
        this.getApprovalAccountList(this.page, this.limit,this.keyword)
    },
    onChangePage(pageOfItems) {
      if (typeof pageOfItems !== undefined && pageOfItems !== null) {
        this.getApprovalAccountList(pageOfItems[0], this.limit,this.keyword)
      }
    },
    getApprovalAccountList(page, limit, keyword) {
      this.page = page;
      let pagevalue = '' + page;
      if (pagevalue == 'undefined') {
        return
      }
      const api =
        'https://dev-api.trueprotection.co.th/api/admin/approval-images?page='+page+'&limit='+limit+'&type=gallery'
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.imageProfileList = []
          this.imageProfileList = response.data.data.records

          if (this.firstload == false) {
            this.firstload = true
            this.numberOfPage = response.data.data.total_pages

            var counter = 1
            var pagenumber = 1
            this.pages = []
            for (var i = 0; i < this.numberOfPage * this.limit; i++) {
              if (counter - 1 == this.limit) {
                counter = 1
                pagenumber++
              } else {
                counter++
                this.pages.push(pagenumber)
              }
            }
          }
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    sortcol(sort) {
      this.params.sort = sort
      this.sort = sort.column
      this.orderby = sort.type
      this.getApprovalAccountList(this.page, this.limit,this.keyword)
      console.log(sort.column, sort.type)
    },
    encode(id) {
      return btoa(id)
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    phoneformat: function (value) {
      if (!value) return ''
      value = value.toString()
      var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      return value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
  }
}
</script>