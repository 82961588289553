<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-3">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-9 relative">
            <div class="grey-box">
                <div class="d-inline-block mt-5">
                    <DateRange style="float: none;" @dateChange="dateChange" />
                    <!-- <div class="mt-4">
                        <ul class="filter d-md-inline-flex d-none">
                            <li v-for="(day, dayIndex) in dayMenus" 
                                :key="dayIndex" 
                                :class="dayIndex | isActive(filters.day)" 
                                @click="filter(dayIndex)"
                            >
                            <a href="#">{{ day }}</a>
                            </li>
                        </ul>
                    </div> -->
                </div>
                <div class="d-inline-block pr-3">
                    <div class="text-right">
                    <router-link :to="'/vehicles/new'">
                        <a  class="outline-btn pt-1" style="padding: 5px 10px;">
                            <i class="far fa-file-pdf mr-2"></i> Download
                        </a>
                    </router-link>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table data-table table-round table-hover">
          <thead>
            <tr>
                <th>
                    Label
                    <Sort column="id" />
                </th>
                  <th>
                    Pick-up Date
                  </th>
                  <th>
                    Return Date
                  </th>
                  <th>
                    Cancellation Date
                  </th>
                  <th>
                    Total Days
                  </th>
                  <th>
                    Total Price
                  </th>
                  <th>
                    Total Revenue
                  </th>
                  <th>
                    Total Refunded
                  </th>
                <th>
                    Cancellation Fee
                </th>
                <th>
                    Cancellation Reason
                </th>
                <th>
                    Status
                </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(list, index) in cancellationList" :key="index">
              <td>{{ list.label }}</td>
              <td>{{ list.pick_up_date }}</td>
              <td>{{ list.return_date }}</td>
              <td>{{ list.cancellation_date }}</td>
              <td>{{ list.total_days }}</td>
              <td class="text-right">$ {{ list.total_price }}</td>
              <td class="text-right">$ {{ list.total_revenue }}</td>
              <td class="text-right">$ {{ list.total_refunded }}</td>
              <td class="text-right">$ {{ list.cancellation_fee }}</td>
              <td class="text-right">$ {{ list.cancellation_reason }}</td>
              <td>{{ list.status }}</td>
            </tr>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>  

      <!-- PAGINATION -->
      <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
        <jw-pagination :items="pages" @changePage="onChangePage" :pageSize="limit" :styles="customStyles"
        :labels="customLabels"></jw-pagination>
      </div>
      <!-- END OF PAGINATION -->
    </div>
    
  </template>
  
  
  
  <script>

  export default {
    data() {
      return {
        cancellationList: [],
      }
    },
    mounted() {
      this.getCancellationList()
    },
  
    methods: {
      getCancellationList() {
        this.cancellationList = []
        const api = 'https://api.615autorental.com/api/admin/reports/cancelation'
        const admintoken = localStorage.getItem('auth_token_default')
        const headers = {
          'Authorization': `Bearer ${admintoken}`,
          'Content-Type': 'application/json' // You can adjust content type as needed
        };
        axios
          .get(api, { headers }, {
            params: {
              page: 1,
              limit: 30
            }
          })
          .then((response) => {
            this.cancellationList = response.data.data
          })
          .catch((error) => {
            console.error('There was an error!', error.message)
          })
      },
      encode(id) {
        return btoa(id)
      },
    }
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  .grey-box {
  float: right; 
  background-color: transparent; 
  box-shadow: none; 
  border: none; 
  padding: 10px 0
}
.d-inline-block {
    display: inline-block;
}
  </style>