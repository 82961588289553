<template>
  <div>
    <!----- Header Title ----->
    <div class="row content-header">
      <div class="col-md-12">
        <Header />
      </div>
    </div>

    <Chart></Chart>
    <div class="row">
      <div class="col-sm-12 uk-animation-slide-bottom-small">
        <ul class="filter d-md-inline-flex d-none mr-3">
            <li :class="'all' | isActive(filters.category)"  @click="filter('all', 'mission_category')" >
                <a href="#">All</a>
            </li>
            <li v-for="(category, cIndex) in categories" 
                :key="cIndex" 
                :class="category.name_en | isActive(filters.category)" 
                @click="filter(category.name_en, 'mission_category')"
            >
                <a href="#">{{ category.name_en }}</a>
            </li>
        </ul>

        <ul class="filter d-md-inline-flex d-none mr-3">

            <li v-for="(status, statusIndex) in $options.statuses" 
                :key="statusIndex" 
                :class="statusIndex | isActive(filters.status)" 
                @click="filter(statusIndex, 'status')"
            >
                <a href="#">{{ status.text }}</a>
            </li>
        </ul>

      </div>

      
    </div>

    <div class="table-responsive mt-3">
      <table class="table data-table table-round table-hover uk-animation-slide-bottom-small">
        <thead>
          <tr>
            <th scope="col" width="12%">
              Quotation ID
            </th>
            <th scope="col" width="15%">
              Requester <Sort @change="sort" column="customer" />
            </th>
            <th scope="col" width="10%">
              Order Via
            </th>
            <th scope="col" width="8%" class="text-center">
              Type
            </th>
            <th scope="col" width="8%" class="text-right">
              Price <Sort @change="sort" column="customer" />
            </th>
            <th scope="col" width="10%" class="text-center">
              Status <Sort @change="sort" column="customer" />
            </th>
            <th scope="col" width="10%" class="text-center">
              Requested DATE/TIME
            </th>
            <th scope="col" width="6%" class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="quotation in quotationList" :key="'quotation-' + quotation.id">
            <td class="text-left pl-3 pr-3"> 
              {{ quotation.quotation_no}} 
            </td>
            <td class="text-left pl-3 pr-3">
              <a href="#"> {{ quotation.customer }} </a>
            </td>
            <td class="text-left pl-3 pr-3">
              <span uk-icon="icon: phone; ratio: 0.9;" class="relative mr-1" style="top: -1px;"></span> Application
              <!-- 
                ** Condition (v-if) **
                IF Order == "app"
                <span uk-icon="icon: phone; ratio: 0.9;" class="relative mr-1" style="top: -1px;"></span> Application 
                ELSE 
                <span uk-icon="icon: laptop; ratio: 1.1;" class="relative mr-2" style="top: -1px;"></span> Website
              -->
            </td>
            <td class="text-center pl-3 pr-3">
              <span class="uk-label mr-1">
                {{ quotation.mission_category }}
              </span>
            </td>
            <td class="text-right pl-3 pr-3">
              {{ quotation.formatted_price }}
            </td>
            <td class="status text-center">
              <span :class="$options.statuses[quotation.status].class">
                {{ $options.statuses[quotation.status].text }}
              </span>
            </td>
            <td class="time text-center pl-3 pr-3" style="font-size: 12px">
              <i class="fal fa-calendar mr-1 relative" style="top: -1px"></i> 
              {{ quotation.created_at | splitDate }}
              <span class="end-time ml-1" style="font-size: 11px"> 
                {{ quotation.created_at | splitTime }}
              </span>
            </td>
            <td class="text-right">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer pb-0" v-if="quotationList.length > 0">
        <!-- <Pagination 
        @getCurrentPagination="getCurrentPagination" 
        :totalPages="paginData.totalPages"
        :page="paginData.page" /> -->

        <jw-pagination 
                    @changePage="onChangePage"
                    :items="paginData.pages" 
                    :styles="customStyles"
                    :labels="customLabels" 
                    >
                    </jw-pagination>
    </div>
  </div>
</template>

<script>

const statuses = {
  all: {
    text: 'All',
    class: 'all'
  },
  accept_agency: {
    text: 'Accepted',
    class: 'approved'
  },
  rejected_client: {
    text: 'Rejected',
    class: 'cancelled'
  },
  new_offer: {
    text: 'New Offer',
    class: 'pending'
  }
}

import Chart from './_Chart.vue'

export default {
  components: {
    Chart, 
  },
  statuses: statuses,
  mounted() {
    // console.log(statuses)
    this.getCategories()
    this.getQuotationList()
  },
  data() {
    return {
      // selectedDate: [new Date(), new Date(new Date().getTime() + 9 * 24 * 60 * 60 * 1000)],
      // pickerOptions: {
      //   shortcuts: [
      //     {
      //       text: 'Latest Week',
      //       onClick(picker) {
      //         const end = new Date()
      //         const start = new Date()
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      //         picker.$emit('pick', [start, end])
      //       },
      //     },
      //     {
      //       text: 'Latest Month',
      //       onClick(picker) {
      //         const end = new Date()
      //         const start = new Date()
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      //         picker.$emit('pick', [start, end])
      //       },
      //     },
      //     {
      //       text: 'Latest Three Month',
      //       onClick(picker) {
      //         const end = new Date()
      //         const start = new Date()
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      //         picker.$emit('pick', [start, end])
      //       },
      //     },
      //   ],
      // },
      // pickerOptions2: {
      //   shortcuts: [
      //     {
      //       text: 'Today',
      //       onClick(picker) {
      //         picker.$emit('pick', new Date())
      //       },
      //     },
      //     {
      //       text: 'Yesterday',
      //       onClick(picker) {
      //         const date = new Date()
      //         date.setTime(date.getTime() - 3600 * 1000 * 24)
      //         picker.$emit('pick', date)
      //       },
      //     },
      //     {
      //       text: 'A week ago',
      //       onClick(picker) {
      //         const date = new Date()
      //         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
      //         picker.$emit('pick', date)
      //       },
      //     },
      //   ],
      // },
      categories: [],
      quotationList: [],
      
      paginData: {
          page: 1,
          totalPages: 0,
          limit: this.$helpers.pageLimit()
      },
      
      filters: {
          keyword: '',
          status: 'all',
          category: 'all',
      },

      sortParams: {
          column: 'id',
          type: 'desc',
      },
    }
  },
  methods: {
    filter(keyword, type = 'keyword') {
      this.filters[type] = keyword
      this.getQuotationList()
    },
    sort(params) {
      this.sortParams = {
          column: params.column,
          type: params.type,
      }
      this.getQuotationList()
    },
    getCurrentPagination(currentPage) {
        this.paginData.page = currentPage
        this.getQuotationList()
    },
    getQuotationList() {
      const api = 'admin/quotations'
      axios.get(api, {
        params:{
          page: this.paginData.page,
          limit: this.paginData.limit,
          active: 1,
          sort_column: this.sortParams.column,
          sort_type: this.sortParams.type,
          status: this.filters.status
        }
      })
      .then((response) => {
        if(response.data.success) {
          this.quotationList = response.data.data.records
          this.paginData.totalPages = response.data.data.total_pages
        }else{
          this.$helpers.notification(response.data.success, response.data.message)
        }
      })
      .catch((error) => {
        this.$helpers.notification(false, error.message)
        console.error('There was an error!', error)
      })
    },
    getCategories() {
      var notyf = new Notyf();
      const api = 'admin/mission_categories'
      axios.get(api)
      .then((response) => {
        if(response.data.success) {
          this.categories = response.data.data
        }else{
          notyf.alert(response.data.message);
        }
      })
      .catch((error) => {
        console.error('There was an error!', error)
        notyf.alert(error.message);
      })
    }
  },
}
</script>

<style scoped>
.card-footer {
  text-align: right;
  position: relative;
  background: #fff;
}

.pagination {
  top: 20px;
  position: absolute;
  right: 0px;
  align-content: flex-end;
  text-align: end;
}

</style>