<template>

    <jw-pagination 
        @changePage="onChangePage"
        :items="paginData.pages" 
        :styles="customStyles"
        :labels="customLabels" 
        >
    </jw-pagination>

   
</template>

<script>


export default {
    props:{
        totalPages: Number, page: Number
    },
    components: {
    },
    mounted() {
        console.log(this.totalPages)
        this.paginationLists = Array.from(Array(this.totalPages).keys())
        this.currentPage = this.page
    },
    data() {
        return {
            paginationLists: [],
            currentPage:1,
        }
    },
    methods: {

        nextPage() {
            if (this.currentPage !== this.totalPages) {
                this.currentPage++
            }
            this.goToPage(this.currentPage)
        },
        backPage() {
            if (this.currentPage !== 1) {
                this.currentPage--
            }
            this.goToPage(this.currentPage)
        },
        goToPage(numPage) {
            console.log(this.paginationLists)
            this.currentPage = numPage;
            this.$emit('getCurrentPagination', this.currentPage)
        }
       
    },
    filters: {
    }
}

</script>

<style scoped>
.uk-pagination>.uk-active>* {
    color: #75a9d1;
}
</style>