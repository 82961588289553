<template>
    <div class="row">
        <div class="col-sm-12 mb-2">
            <h5 class="mt-1 mb-2 uk-text-bold" for="">Location</h5>
            <hr />
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>