<template>
    <div>
        <!----- Header Title ----->
        <section class="row">
        <Header />
        </section>

        <!----- * TABS * ----->
        <TabList />
        
        <section class="tpui-container">
            <div class="row align-items-center ">
                <div class="col-md-12 mb-3">
                    <div class="grey-box p-3 pt-4">
                        <div class="col-md-12 text-left">
                            <h5 class="mt-1 mb-2 uk-text-bold">
                                <i class="fa fa-bar-chart mr-2"></i> Created by you 
                            </h5>
                        </div>
                        <ul uk-accordion>
                            <li class="uk-open with-border">
                                <a class="uk-accordion-title with-bg" href="#"> 
                                    <i class="fas fa-file-spreadsheet mr-2"></i>
                                    General information of course
                                </a>
                                <div class="uk-accordion-content p-3">
                                    <div class="row">
                                        <div class="col-md-6 ">
                                            <div class="form-group">
                                                <label>Type of Course</label>
                                                <p>Kick Boxing</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="form-group">
                                                <input type="number" name="" value="3000" class="form-control" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <hr />
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="form-group">
                                                <label>Type</label>
                                                <p>Private Course</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="form-group">
                                                <input type="number" name="" value="2000" class="form-control" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <hr />
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Location</label>
                                                <p>Trendy Gym (10/31) 10 Soi Sukhumvit 13, Khlong Toei Nuea Watthana, Bangkok 10110, Thailand</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Start Date</label>
                                                <p>23 AUG 2024</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Schdule</label>
                                                <p>Flexible</p>
                                            </div>
                                        </div>
                                    </div>

                                    <ScheduleList />
                                </div>
                            </li>
                            <li class="uk-open with-border">
                                <a class="uk-accordion-title with-bg" href="#"> 
                                    <i class="fas fa-file-spreadsheet mr-2"></i>
                                    About Client
                                </a>
                                <div class="uk-accordion-content p-3">
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>No. of Client</label>
                                                <p>2 People</p>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                    
                                    <div class="row p-3">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-6 ">
                                                    <div class="form-group">
                                                        <label>Type</label>
                                                        <p>Private Course</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 ">
                                                    <div class="form-group">
                                                        <input type="number" name="" value="2000" class="form-control" required>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row p-3">
                                        <div class="col-md-6 ">
                                            <div class="form-group">
                                                <label>Type</label>
                                                <p>Private Course</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="form-group">
                                                <input type="number" name="" value="2000" class="form-control" required>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <hr />
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>True Protection</label>
                                                <p>Organisation Name </p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <hr />
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Level of Experience</label>
                                                <p>3</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <hr />
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Year of Experience</label>
                                                <p>Less than a Year</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <hr />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Do you have criminal record?</label>
                                                <p>NO</p>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <hr />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Were you in the army?</label>
                                                <p>YES</p>
                                            </div>
                                            <!-- if yes, show the info -->
                                            <div class="form-group">
                                                <fieldset>
                                                    Serve in Thai army for 1 year laofiadsfnpiaro gjeripgvodsf pasdoijfis adfojaospdfjd saopfjdsaplfmdsa asdoifjnjspd aiofjsodpfjko dsajfosadjfspoadfjop
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <hr />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <div class="form-group">
                                                <label>Were you police officer?</label>
                                                <p>NO</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <h5>Total</h5>
                            </div>
                            <div class="col-md-6 total-amount">
                                <h5>฿14,000</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6" style="color: #E55356">

                    <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    Hide mission
                </div>
                <div class="col-sm-6 text-right">
                    <a href="#" class="edit-btn quote btn-height-normal">
                        <i class="fa fa-comment-o mr-2" aria-hidden="true"></i>
                        Quote
                    </a>
                </div>
            </div>
        </section>
      
    </div>
</template>

<script >
import TabList from "../../Edit/TabList.vue"
import ScheduleList from "./ScheduleList.vue";

export default {
    components: {
        TabList,
        ScheduleList
    },
    data() {
        return {
           
        }
    },
    mounted() {

    },
    methods: {
      
    },

}
</script>


<style  scoped>
.grey-box label{
    color: #a5a5a5 !important;
    left: 0;
    position: relative;
}
.grey-box p{
    margin-top: 10px;
    color: #212529 !important;
}
.total-amount h5 {
    font-weight: 700;
    color: #0B63F8 !important;
}
.edit-btn.quote {
    background: #FEC02F;
    color: #212529 !important;
}
</style>
