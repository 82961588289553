<template>
    <form @submit.prevent="updatePrice">
        <div class="row mt-3">
            <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold">
                    <i class="fas fa-chart-bar mr-2"></i> 
                    Private investigator price list
                </h5>
            </div>
            
            <div class="col-md-12 pt-3">
                <div class="table-responsive">
                    <table class="table data-table table-round table-hover">
                        <thead>
                            <tr>
                                <th width="30%" class="">Price</th>
                                <th width="15%" class="">Period</th>
                                <th width="15%"></th>
                                <th class="blankhead" width="15%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(price, priceIndex) in priceLists" :key="priceIndex">
                              
                                <td class="pt-3 pb-2">
                                    <div class="form-group mb-2 mt-1">
                                        <input type="text" name="price" v-model="priceLists[priceIndex].price" class="form-control uk-form-small pt-0" required style="width:200px"/>
                                    </div>
                                </td>
                                 <td class="bg-Total pt-3">
                                    <div class="form-group select-dropdown mb-2 mt-0">
                                        <select class="form-control uk-form-small pt-0" name="number" v-model="priceLists[priceIndex].number" required>
                                            <option value="" selected="selected">no.</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>
                                </td>
                                <td class="bg-Total pt-3">
                                    <div class="form-group select-dropdown mb-2 mt-0">
                                        <select class="form-control uk-form-small pt-0" name="range_period" v-model="priceLists[priceIndex].range_period" required>
                                            <option value="half-day">Half Day</option>
                                            <option value="day">Day</option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>
                                            <option value="year">Year</option>
                                        </select>
                                    </div>
                                </td>
                                <td class="bg-lightgreen text-right">
                                    <a href="#" @click="copyPrice(priceIndex)" class="edit-btn" uk-toggle>
                                        <i class="far fa-copy"></i>
                                    </a>
                                    <a href="#" @click="deletePrice(priceIndex)" class="del-btn">
                                        <i class="fal fa-trash-alt"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="6">
                                    <div class="col-md-12 text-right p-0">
                                        <a @click="addPrice" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center float-left">
                                            <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                                        </a>
                                        
                                        <button type="button" class="cancel-btn border-0">
                                            <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                                        </button>
                                        <button type="submit" class="save-btn border-0 ml-2 mr-2">
                                            <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

export default {
    mounted() {
        this.priceLists = [{
            id: 1,
            price: 200,
            number: 1,
            range_period: 'week'
        }]
        // this.PiPrices()
    },
    data() {
        return {
            priceLists: [],
            formData: []
        }
    },
    methods: {
        PiPrices() {
            const api = 'admin/agencies/' + this.$route.params.id + '/escort_prices'
            axios
                .get(api, {
                    params: {
                        page:1,
                        limit:30
                    }
                })
                .then((response) => {
                    this.priceLists = response.data.data
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
                
        },
        addPrice() {
            this.priceLists.push({
                range_period: 'month',
                type_of_vehicle: 'car',
                no_of_vehicle: 1
            })
        },
        copyPrice(index) {
            this.priceLists.push(this.priceLists[index])
        },
        updatePrice() {
            console.log(this.priceLists)
            // this.loading()
            const api = 'admin/agencies/' + this.$route.params.id + '/escort_prices'
            axios
                .post(api,{
                    prices: this.getFormData()
                })
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        getFormData() {
            // let form = this.$refs.updatePriceForm;
            // let formData = new FormData(form);

            return this.priceLists
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },
        deletePrice(index) {
            this.priceLists.splice(index, 1); 
        }

    }
}
</script>