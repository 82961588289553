<template>
    <div class="p-5">
          <!----- Header Title ----->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h3 class="page-title mb-1 mt-0">
                        {{ this.$route.meta.title }}
                    </h3>
                </div>
                <div class="breadcrumb-container">
                    <ul class="uk-breadcrumb">
                        <li><span>Admin</span></li>
                        <li><a href="/reservation">Reservation</a></li>
                        <li><a href="">{{ this.$route.meta.title }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 relative"></div>
        </div>
      <!-- New Reservation -->
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
            <section class="grey-box register" v-if="!hasSeenCongrats">
                <div class="register-stepper">
                    <div class="step" :class="{'step-active' : step === 1, 'step-done': step > 1}"><span class="step-number">1</span></div>
                    <div class="step" :class="{'step-active' : step === 2, 'step-done': step > 2}"><span class="step-number">2</span></div>
                    <div class="step" :class="{'step-active' : step === 3, 'step-done': step > 3}"><span class="step-number">3</span></div>
                    <div class="step" :class="{'step-active' : step === 4, 'step-done': step > 4}"><span class="step-number">4</span></div>
                    <div class="step" :class="{'step-active' : step === 5, 'step-done': step > 5}"><span class="step-number">5</span></div>
                    <div class="step" :class="{'step-active' : step === 6, 'step-done': step > 6}"><span class="step-number">6</span></div>
                </div>

                <transition name="slide-fade">
                    <section v-show="step === 1">
                        <form class="form" method="post" action="#" @submit.prevent="next">
                            <p class="text-uppercase text-sm mb-2">Date & Time</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="date" class="form-control" value="" autocomplete="off" required>
                                        <label>Pick-up Date</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="time" class="form-control" autocomplete="off" required />
                                        <label>Pick-up Time</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="date" class="form-control" autocomplete='off' required/>
                                        <label>Return Date</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="time" class="form-control" autocomplete='off' required/>
                                        <label>Return Time</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" autocomplete='off' required/>
                                        <label>Pick-up Location</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" autocomplete='off' required/>
                                        <label>Return Location</label>
                                    </div>
                                </div>
                            </div>
                            <div class="cta d-flex justify-content-end" data-style="see-through" data-alignment="right" data-text-color="custom">
                                <p class="cta-color">
                                    <span class="link_wrap">
                                        <input type="submit" value="Next" class="link_text btn next-btn" />
                                        <span class="arrow-next"></span>
                                    </span>
                                </p>
                            </div>
                        </form>
                    </section>
                </transition>
                <transition name="slide-fade">
                    <section v-show="step === 2">
                        <form class="form" method="post" action="#" @submit.prevent="next">
                            <p class="text-uppercase text-sm mb-2">Select Available vehicles</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="table-responsive p-0 cta-step mb-3">
                                <table class="table align-items-center mb-0">
                                    <tbody>
                                        <tr>
                                            <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                    <img
                                                        src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                        class="availablecars me-3"
                                                        alt="user1"
                                                    />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center" style="margin-left: 20px;">
                                                <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                                <ul class="car-list">
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                                </ul>
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                                <h3 class="vehicle_price">$35.00 per day</h3>
                                                <a href="#" @click.prevent="next()" class="link_text btn btn-sm btn-primary w-100">Avail</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                <img
                                                    src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                    class="availablecars me-3"
                                                    alt="user1"
                                                />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center" style="margin-left: 20px;">
                                                <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                                <ul class="car-list">
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                                </ul>
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                                <h3 class="vehicle_price">$35.00 per day</h3>
                                                <a href="#" @click.prevent="next()" class="link_text btn btn-sm btn-primary w-100">Avail</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                <img
                                                    src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                    class="availablecars me-3"
                                                    alt="user1"
                                                />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center" style="margin-left: 20px;">
                                                <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                                <ul class="car-list">
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                                </ul>
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                                <h3 class="vehicle_price">$35.00 per day</h3>
                                                <a href="#" @click.prevent="next()" class="link_text btn btn-sm btn-primary w-100">Avail</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                <img
                                                    src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                    class="availablecars me-3"
                                                    alt="user1"
                                                />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center" style="margin-left: 20px;">
                                                <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                                <ul class="car-list">
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                                </ul>
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                                <h3 class="vehicle_price">$35.00 per day</h3>
                                                <a href="#" @click.prevent="next()" class="link_text btn btn-sm btn-primary w-100">Avail</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                <img
                                                    src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                    class="availablecars me-3"
                                                    alt="user1"
                                                />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center" style="margin-left: 20px;">
                                                <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                                <ul class="car-list">
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                                </ul>
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                                <h3 class="vehicle_price">$35.00 per day</h3>
                                                <a href="#" @click.prevent="next()" class="link_text btn btn-sm btn-primary w-100">Avail</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                <img
                                                    src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                    class="availablecars me-3"
                                                    alt="user1"
                                                />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center" style="margin-left: 20px;">
                                                <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                                <ul class="car-list">
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                                </ul>
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                            <button class="btn btn-sm btn-primary w-100">Avail</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="form-group cta-step">
                                <div class="cta prev">
                                    <p class="cta-color">
                                        <span class="link_wrap">
                                            <a class="btn prev-btn link_text" href="#" @click.prevent="prev()"><span class="arrow-prev"></span>Previous
                                            </a>
                                        </span>
                                    </p>
                                </div>
                                <div class="cta">
                                    <p class="cta-color">
                                        <span class="text"></span>
                                        <span class="link_wrap">
                                            <input type="submit" value="Next" class="link_text btn next-btn" />
                                            <span class="arrow-next"></span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </section>
                </transition>
                <transition name="slide-fade">
                    <section v-show="step === 3">
                        <form class="form" action="#" @submit.prevent="next">
                            <p class="text-uppercase text-sm mb-2">Driver Information</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required/>
                                        <label>Driver's Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required/>
                                        <label>Additional Drivers (Optional)</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <textarea class="form-control" required></textarea>
                                        <label>Additional Drivers (Optional)</label>
                                    </div>
                                </div>
                            </div>
                            <p class="text-uppercase text-sm mt-4 mb-2">Discount</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required />
                                        <label>Coupon Code</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required/>
                                        <label>Custom Discount Value</label>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group cta-step mt-4">
                                <div class="cta prev">
                                    <p class="cta-color">
                                        <span class="link_wrap">
                                            <a class="btn prev-btn link_text" href="#" @click.prevent="prev()"><span class="arrow-prev"></span>Previous
                                            </a>
                                        </span>
                                    </p>
                                </div>
                                <div class="cta">
                                    <p class="cta-color">
                                        <span class="text"></span>
                                        <span class="link_wrap">
                                            <input type="submit" value="Next" class="link_text btn next-btn" />
                                            <span class="arrow-next"></span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </section>
                </transition>
                <transition name="slide-fade">
                    <section v-show="step === 4">
                        <form class="form" action="#" @submit.prevent="next">
                            <p class="text-uppercase text-sm mb-2">Customer Information</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <select class="form-control" required>
                                            <option></option>
                                            <option>Hanah Salazar</option>
                                            <option>Daniel Valmomo</option>
                                            <option>Abel Belo</option>
                                            <option>Alford Dominique</option>
                                            <option>Billy Ford</option>
                                            <option>Xander Ford</option>
                                        </select>
                                        <label>Customer</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <p class="mb-0">or</p>
                                    <p class="m-0"><button class="btn btn-sm btn-primary">Create new customer</button></p>
                                </div>
                            </div>

                            <p class="text-uppercase text-sm mt-3 mb-2">Customer Information</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required/>
                                        <label>First Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required/>
                                        <label>Last Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control" required/>
                                        <label>Email Address</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="tel" class="form-control" required />
                                        <label>Phone Number</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required />
                                        <label>Street</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required />
                                        <label>City</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required />
                                        <label>State</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required />
                                        <label>Zip</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required />
                                        <label>Country</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="date" class="form-control" required />
                                        <label>Birthday</label>
                                    </div>
                                </div>
                            </div>
                            <p class="text-uppercase text-sm mt-4 mb-2">Driver's License</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" required />
                                        <label>DL Number</label>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="date" class="form-control" required />
                                        <label>Expiration Date</label>
                                    </div>
                                    
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="file" class="form-control" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group cta-step mt-4">
                                <div class="cta prev">
                                    <p class="cta-color">
                                        <span class="link_wrap">
                                            <a class="btn prev-btn link_text" href="#" @click.prevent="prev()"><span class="arrow-prev"></span>Previous
                                            </a>
                                        </span>
                                    </p>
                                </div>
                                <div class="cta">
                                    <p class="cta-color">
                                        <span class="text"></span>
                                        <span class="link_wrap">
                                            <input type="submit" value="Next" class="link_text btn next-btn" />
                                            <span class="arrow-next"></span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </section>
                </transition>
                <transition name="slide-fade">
                    <section v-show="step === 5">
                        <form class="form" action="#" @submit.prevent="next">
                            <div class="row mt-5">
                                <div class="col-md-7">
                                    <p class="text-uppercase text-sm mb-2">Selected Vehicle</p>
                                    <hr class="horizontal dark mt-0" />
                                    <div class="d-flex px-2 py-1">
                                        <div class="mr-4">
                                            <img
                                                src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                class="availablecars me-3"
                                                alt="user1"
                                            />
                                        </div>
                                        <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                        <ul class="car-list">
                                            <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                            <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                            <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                            <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <p class="text-uppercase text-sm mb-2">Summary</p>
                                    <hr class="horizontal dark mt-0" />
                                    <table class="table table-bordered">
                                        <tr>
                                            <td><h6 class="text-sm mb-0 ">Vehicle</h6></td>
                                            <td class="text-right"><p class="text-sm mb-0">3x days</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">CDW</h6></td>
                                            <td colspan="2" class="text-right"><p class="text-sm mb-0">$8</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">Insurance</h6></td>
                                            <td colspan="2" class="text-right"><p class="text-sm mb-0">$11</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">Discount</h6></td>
                                            <td class="text-right"><p class="text-sm mb-0">s6xwOH</p></td>
                                            <td class="text-right"><p class="text-sm mb-0">$2</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">TOTAL</h6></td>
                                            <td colspan="2" class="text-right"><h4 class="mr-0 mb-0">$35.00</h4></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <p class="text-uppercase text-sm mt-4 mb-2">Date & Time</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-3">
                                    <p class="label-detail">Pick-up Date</p>
                                    <p class="mt-0">29-01-2024</p>
                                </div>
                                <div class="col-md-3">
                                    <p class="label-detail">Pick-up Time</p>
                                    <p class="mt-0">08:00</p>
                                </div>
                                <div class="col-md-3">
                                    <p class="label-detail">Return Date</p>
                                    <p class="mt-0">29-01-2024</p>
                                </div>
                                <div class="col-md-3">
                                    <p class="label-detail">Return Time</p>
                                    <p class="mt-0">08:00</p>
                                </div>
                                <div class="col-md-3">
                                    <p class="label-detail">Pick-up Location</p>
                                    <p class="mt-0">29-01-2024</p>
                                </div>
                                <div class="col-md-3">
                                    <p class="label-detail">Return Location</p>
                                    <p class="mt-0">08:00</p>
                                </div>
                            </div>
                            <p class="text-uppercase text-sm mt-4 mb-2">Customer Information</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="label-detail">Name</p>
                                    <p class="mt-0">Hanah Salazar</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Email Address</p>
                                    <p class="mt-0">barry.johnson@wisoky.com</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Phone Number</p>
                                    <p class="mt-0">+15406069319</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Street</p>
                                    <p class="mt-0">7691 Jody Villages Suite 931</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">City</p>
                                    <p class="mt-0">Spinkamouth</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">State</p>
                                    <p class="mt-0">Oklahoma</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Zip</p>
                                    <p class="mt-0">13275-0026</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Country</p>
                                    <p class="mt-0">Wallis & Futuna</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Birthday</p>
                                    <p class="mt-0">27-01-1994</p>
                                </div>
                            </div>
                            <p class="text-uppercase text-sm mt-4 mb-2">Driver's Information</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="label-detail">Driver's Name</p>
                                    <p class="mt-0">Weys Agnes</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">DL Number</p>
                                    <p class="mt-0">7273</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Expiration Date</p>
                                    <p class="mt-0">13-03-2024</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="label-detail">Files Upload</p>
                                    <p class="mt-0">No Images</p>
                                </div>
                            </div>
                            
                            <div class="form-group cta-step mt-4">
                                <div class="cta prev">
                                    <p class="cta-color">
                                        <span class="link_wrap">
                                            <a class="btn prev-btn link_text" href="#" @click.prevent="prev()"><span class="arrow-prev"></span>Previous
                                            </a>
                                        </span>
                                    </p>
                                </div>
                                <div class="cta">
                                    <p class="cta-color">
                                        <span class="text"></span>
                                        <span class="link_wrap">
                                            <input type="submit" value="Confirm" class="link_text next-btn" />
                                            <span class="arrow-next"></span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </section>
                </transition>
                <transition name="slide-fade">
                    <section v-show="step === 6">
                        <form class="form" action="#" @submit.prevent="newBooking">
                            <div class="row">
                                <div class="col-md-8">
                                    <p class="text-uppercase text-sm mb-2">Payment</p>
                                    <hr class="horizontal dark mt-0" />
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="table-responsive mb-4">
                                                <table class="table data-table table-round table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Payment Type</th>
                                                            <th>Payment Method</th>
                                                            <th>Date</th>
                                                            <th>Amount</th>
                                                            <th>Payment Status</th>
                                                            <th>Created At</th>
                                                            <th>Created By</th> 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Card/Credit Card</td>
                                                            <td>VISA</td>
                                                            <td>09-04-2024</td>
                                                            <td>$471,43</td>
                                                            <td>Approved</td>
                                                            <td>29-02-2024 09:13</td>
                                                            <td>System - Support</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="text-uppercase text-sm mb-2">Process payment</p>
                                    <hr class="horizontal dark mt-0" />
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" value="$150" readonly/>
                                                <label>Amount</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" value="Payment for Reservation #54" readonly/>
                                                <label>Description</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group mb-3">
                                                <label class="form_radio float-left mr-3" style="height: 10px;">
                                                    <input type="radio" name="paymenttypeopt" id="paymentType_offline" v-model="radioButton" value="offline" />
                                                    <span class="design"></span>
                                                    <span class="text">Offline</span>
                                                </label>
                                                <label class="form_radio" style="height: 10px;margin-left: 20px!important;">
                                                    <input type="radio" name="paymenttypeopt" id="paymentType_online" v-model="radioButton" value="online" />
                                                    <span class="design"></span>
                                                    <span class="text">Online</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- OFFLINE PAYMENT -->
                                    <div class="row" id="offline_box" v-if="radioButton=='offline'">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <select class="form-control">
                                                    <option></option>
                                                    <option>Cash</option>
                                                    <option>Credit Card </option>
                                                    <option>Bank Transfer</option>
                                                    <option>Maestro</option>
                                                </select>
                                                <label>Payment Type</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6" hidden>
                                            <div class="form-group">
                                                <input type="text" class="form-control" required/>
                                                <label>Payment Method</label>
                                            </div>
                                        </div>
                                        <!-- Realtime -->
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="date" class="form-control" required/>
                                                <label>Date</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div id="credit_card_box">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <select class="form-control">
                                                                <option>January</option>
                                                                <option>February</option>
                                                                <option>March</option>
                                                                <option>April</option>
                                                                <option>May</option>
                                                                <option>June</option>
                                                                <option>July</option>
                                                                <option>August</option>
                                                                <option>September</option>
                                                                <option>October</option>
                                                                <option>November</option>
                                                                <option>December</option>
                                                            </select>
                                                            <label>Exp. month</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <select class="form-control">
                                                                <option>2024</option>
                                                                <option>2025</option>
                                                                <option>2026</option>
                                                                <option>2027</option>
                                                                <option>2028</option>
                                                                <option>2029</option>
                                                                <option>2030</option>
                                                                <option>2031</option>
                                                                <option>2032</option>
                                                                <option>2033</option>
                                                                <option>2034</option>
                                                                <option>2035</option>
                                                                <option>2036</option>
                                                                <option>2037</option>
                                                            </select>
                                                            <label>Exp. year</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <select class="form-control">
                                                                <option>Visa</option>
                                                                <option>Master Card</option>
                                                                <option>Discover</option>
                                                            </select>
                                                            <label>Brand</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" required/>
                                                            <label>Last 4 digits</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" required/>
                                                <label>Amount</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <select type="text" class="form-control">
                                                    <option></option>
                                                    <option>Approved</option>   
                                                    <option>Denied</option>    
                                                </select>
                                                <label>Payment Status</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group mb-3">
                                                <div class="row w-100">
                                                    <div class="col-md-7">
                                                        <label >Send Confirmation Email</label>
                                                    </div>
                                                    <div class="col-md-5">
                                                        <label class="form_radio float-left mr-3" style="height: 20px;">
                                                            <input type="radio" name="sendemailOpt" id="send_yes" >
                                                            <span class="design"></span>
                                                            <span class="text">Yes</span>
                                                        </label>
                                                        <label class="form_radio" style="height: 20px;">
                                                            <input type="radio" name="sendemailOpt" id="send_no" >
                                                            <span class="design"></span>
                                                            <span class="text">No</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <button class="btn btn-sm btn-primary" style="width: 200px;">Save</button>
                                        </div>
                                    </div>

                                    <!-- ONLINE PAYMENT -->
                                    <div class="row mt-3" id="online_box" v-if="radioButton=='online'">
                                        <div class="col-md-12 mb-3">
                                            <div class="form-group mb-3">
                                                <label class="form_radio float-left mr-3" style="height: 10px;">
                                                    <input type="radio" name="paymenttypeopt" id="paymentType_offline" />
                                                    <span class="design"></span>
                                                    <span class="text">
                                                        <img src="/assets/img/pay_pal.png" style="width: 130px;" />
                                                    </span>
                                                </label>
                                                <label class="form_radio" style="height: 10px;margin-left: 20px!important;">
                                                    <input type="radio" name="paymenttypeopt" id="paymentType_online" />
                                                    <span class="design"></span>
                                                    <span class="text">
                                                        <img src="/assets/img/stripe_sca.png" style="height: 40px;" />
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="email" class="form-control" required/>
                                                <label>Email Address</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="date" class="form-control" required/>
                                                <label>Due Date</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <client-only>
                                                <VueEditor />
                                            </client-only>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <button class="btn btn-sm btn-primary" style="width:200px">Send Request</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <p class="text-uppercase text-sm mb-2">Summary</p>
                                    <hr class="horizontal dark mt-0" />
                                    <table class="table table-bordered">
                                        <tr>
                                            <td><h6 class="text-sm mb-0 ">Vehicle</h6></td>
                                            <td class="text-right"><p class="text-sm mb-0">3x days</p></td>
                                            <td class="text-right"><p class="text-sm mb-0">$35.00</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">CDW</h6></td>
                                            <td colspan="2" class="text-right"><p class="text-sm mb-0">$8</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">Insurance</h6></td>
                                            <td colspan="2" class="text-right"><p class="text-sm mb-0">$11</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">Discount</h6></td>
                                            <td class="text-right"><p class="text-sm mb-0">s6xwOH</p></td>
                                            <td class="text-right"><p class="text-sm mb-0">$2</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">TOTAL</h6></td>
                                            <td colspan="2" class="text-right"><h4 class="mr-0 mb-0">$35.00</h4></td>
                                        </tr>
                                    </table>
                                </div>
                                
                            </div>
                            
                            <div class="form-group cta-step mt-4">
                                <div class="cta prev">
                                    <p class="cta-color">
                                        <span class="link_wrap">
                                            <a class="btn prev-btn link_text" href="#" @click.prevent="prev()"><span class="arrow-prev"></span>Previous
                                            </a>
                                        </span>
                                    </p>
                                </div>
                                <div class="cta">
                                    <p class="cta-color">
                                        <span class="text"></span>
                                        <span class="link_wrap">
                                            <input type="submit" value="Reserve" class="link_text btn next-btn" />
                                            <span class="arrow-next"></span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </section>
                </transition>
            </section>
            <section class="congrats register text-center mt-5" v-if="hasSeenCongrats">
                <div class="register-icon">
                    <img class="register-icon-item" src="../../public/assets/img/success-goal.png" alt="vue logo">

                </div>
                <h2 class="congrats-title">Success!</h2>
                <p class="congrats-subtitle">
                    You have successfully made a reservation!
                </p>
                <button @click="redirectReservation()" class="btn btn-primary">Go Back</button>
            </section>
        </div>
      </div>
    </div>
    
  </template>
  
  
  
  <script>
    import router from '../routes';

    export default {
        el: "#app",
        data: () => {
            return {
            steps: {},
            paymentopt: 'offline',
            step: 1,
            customer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            hasSeenCongrats: false,
            tempCustomer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            };
        },
    methods: {
        redirectReservation() { // 
            router.push({path: '/reservation/'})
        },

        prev() {
        this.step--;
        },

        next() {
        this.step++;
        },

        newBooking: function () {
            this.hasSeenCongrats = true;
        },
    }

};
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>