var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._m(0),_c('div',{staticClass:"uk-accordion-content legal-agreement"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"grey-box"},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_vm._m(2),_c('ul',{staticClass:"uk-switcher uk-margin"},[_c('li',[_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.legal_agreement),expression:"company.legal_agreement"}],attrs:{"rows":"30","name":"legal_agreement","required":""},domProps:{"value":(_vm.company.legal_agreement)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.company, "legal_agreement", $event.target.value)}}}),_c('label',[_vm._v("Please enter Legal Agreement text (English)")])])])])]),_c('div',{staticClass:"col-md-6 mb-md-0"},[_vm._m(3),_c('div',{staticClass:"js-upload uk-placeholder uk-text-center mr-2"},[_vm._m(4),_c('span',{staticClass:"mr-2",attrs:{"uk-icon":"icon: cloud-upload"}}),_c('span',{staticClass:"uk-text-middle"},[_vm._v(" Drag & Drop file ")]),_c('div',{attrs:{"uk-form-custom":""}},[_c('input',{ref:"legalAgreementDoc",staticClass:"file-upload",attrs:{"type":"file","name":"legal_agreement_doc","id":"file"},on:{"change":function($event){return _vm.legalAgreementDocUpload()}}}),(_vm.legalUrl)?_c('img',{attrs:{"src":_vm.legalUrl}}):_vm._e()])]),_c('progress',{staticClass:"uk-progress",attrs:{"id":"js-progressbar","value":"0","max":"100","hidden":""}})])]),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"uk-accordion-title",attrs:{"href":"#"}},[_c('span',{staticClass:"mr-2 relative",staticStyle:{"top":"-2px"},attrs:{"uk-icon":"info"}}),_vm._v(" Legal Agreement ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"p-2 mt-2 uk-text-bold"},[_c('i',{staticClass:"fas fa-balance-scale mr-2"}),_vm._v(" Legal Agreement ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticStyle:{"padding-left":"15px"},attrs:{"uk-tab":""}},[_c('li',{staticClass:"uk-active"},[_c('a',{staticClass:"no-bullet",attrs:{"href":"#"}},[_vm._v("Legal Agreement (English)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticStyle:{"font-weight":"600","color":"#111"}},[_c('span',{attrs:{"uk-icon":"upload"}}),_vm._v(" Upload Document ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 mt-2"},[_c('span',{staticClass:"uk-link btn-upload"},[_vm._v(" Upload ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 pb-5"},[_c('a',{staticClass:"btn download-btn",attrs:{"href":""}},[_c('i',{staticClass:"fas fa-download mr-2"}),_vm._v(" Download PDF ")])])])
}]

export { render, staticRenderFns }