<template>
    <div class="p-5">
          <!----- Header Title ----->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h3 class="page-title mb-1 mt-0">
                        {{ this.$route.meta.title }}
                    </h3>
                </div>
                <div class="breadcrumb-container">
                    <ul class="uk-breadcrumb">
                        <li><span>Admin</span></li>
                        <li><a href="/reservation">Reservation</a></li>
                        <li><a href="">{{ this.$route.meta.title }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 relative"></div>
        </div>
      <!-- New Reservation -->
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
            <section class="grey-box register" v-if="!hasSeenCongrats">
                <div class="register-stepper">
                    <div class="step step-active"><span class="step-number">1</span></div>
                    <div class="step"><span class="step-number">2</span></div>
                    <div class="step"><span class="step-number">3</span></div>
                    <div class="step"><span class="step-number">4</span></div>
                    <div class="step"><span class="step-number">5</span></div>
                    <div class="step"><span class="step-number">6</span></div>
                </div>

                <transition name="slide-fade">
                    <section>
                        <form class="form" method="post" action="#">
                            <p class="text-uppercase text-sm mb-2">Date & Time</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="date" class="form-control" value="" autocomplete="off" required>
                                        <label>Pick-up Date</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="time" class="form-control" autocomplete="off" required />
                                        <label>Pick-up Time</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="date" class="form-control" autocomplete='off' required/>
                                        <label>Return Date</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="time" class="form-control" autocomplete='off' required/>
                                        <label>Return Time</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" autocomplete='off' required/>
                                        <label>Pick-up Location</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" autocomplete='off' required/>
                                        <label>Return Location</label>
                                    </div>
                                </div>
                            </div>
                            <div class="cta d-flex justify-content-end" data-style="see-through" data-alignment="right" data-text-color="custom">
                                <p class="cta-color">
                                    <span class="link_wrap">
                                        <router-link :to="'/book/2'">
                                            <a class="link_text btn next-btn" style="padding-right: 30px!important;">Next</a>
                                        </router-link>
                                        <span class="arrow-next"></span>
                                    </span>
                                </p>
                            </div>
                        </form>
                    </section>
                </transition>
            </section>
        </div>
      </div>
    </div>
    
  </template>
  
  
  
  <script>
    import router from '../../routes';

    export default {
        el: "#app",
        data: () => {
            return {
            steps: {},
            paymentopt: 'offline',
            step: 1,
            customer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            hasSeenCongrats: false,
            tempCustomer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            };
        },
    methods: {
        redirectReservation() { // 
            router.push({path: '/reservation/'})
        },

        prev() {
        this.step--;
        },

        next() {
        this.step++;
        },

        newBooking: function () {
            this.hasSeenCongrats = true;
        },
    }

};
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>