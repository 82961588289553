<template>
    <div class="education">
        <!----- Header Title ----->
        <section class="row">
        <Header />
        </section>

        <!----- * TABS * ----->
        <TabList />
        
        <section class="tpui-container">
            <div class="row align-items-center mb-4">
                <div class="col-lg-12">
                    <div class="grey-box p-3 pt-4">
                        <Classes />
                        <ClientRequestedClass />
                    </div>
                </div>
            </div>
        </section>

        <Gallery />
        <InstructorList />

    </div>
</template>

<script>
import TabList from '../../Edit/TabList.vue';
import Classes from "./Classes.vue";
import ClientRequestedClass from "./ClientRequestedClass.vue";
import Gallery from "./Gallery.vue";
import InstructorList from "./InstructorList.vue";

export default {
    components: {
        TabList,
        Classes,
        ClientRequestedClass,
        Gallery,
        InstructorList

    }
}
</script>