<template>
    <div class="view-ui mt-2">
        <div class="row">
            <div class="col-md-3 relative pb-5">
                <div class="profile-img position-relative text-center" style="margin-top: -35px">
                    <div class="img-wrapper">
                    <img src="https://cdn.pixabay.com/photo/2016/03/26/20/35/young-man-1281282_960_720.jpg" alt="Border pill" />
                    <span class="edit-btn">
                        <input type="file" name="company-img" class="file-upload" id="file" ref="file"
                        v-on:change="handleFileUpload()" />
                        <i class="fal fa-pen upload-button"></i> Change
                    </span>
                    </div>
                    <span class="delete-btn"></span>
                </div>
            </div>
            <div class="col-md-9">
                <div class="p-3">
                    <!-- <ul class="dashboard-page">
                        <li class="one-value default blue">
                            <div class="icon">
                            <i class="far fa-bullseye-arrow"></i>
                            </div>
                            <div class="result">
                            <div class="uk-text-center">
                                <div class="uk-width-1-1 main">
                                <span class="title">
                                    <span class="primary-title"> Mission </span>
                                    <span class="sub-title"> Cases </span>
                                </span>
                                <span class="value count"> 16,520 </span>
                                </div>
                            </div>
                            </div>
                        </li>
                        <li class="one-value default blue">
                            <div class="icon">
                            <i class="fal fa-sack-dollar"></i>
                            </div>
                            <div class="result">
                            <div class="uk-text-center">
                                <div class="uk-width-1-1 main">
                                <span class="title">
                                    <span class="primary-title"> Revenue </span>
                                    <span class="sub-title"> Net Amount </span>
                                </span>
                                <span class="value count"> 16,520 </span>
                                </div>
                            </div>
                            </div>
                        </li>
                        <li class="one-value default blue">
                            <div class="icon">
                            <i class="far fa-chart-line"></i>
                            </div>
                            <div class="result">
                            <div class="uk-text-center">
                                <div class="uk-width-1-1 main">
                                <span class="title">
                                    <span class="primary-title"> Profit </span>
                                    <span class="sub-title"> Net Amount </span>
                                </span>
                                <span class="value count"> 16,520 </span>
                                </div>
                            </div>
                            </div>
                        </li>
                    </ul> -->
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="col-md-12">
                <div class="p-3">
                    <h6 class="uk-text-uppercase pl-1 uk-text-bold">Information</h6>
                    <div class="row p-0 pb-0">
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="firstname_en" class="form-control" value="" required />
                            <label>First name in English</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="lastname_en" class="form-control" value="" required />
                            <label>Last name in English</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="firstname_th" class="form-control" value="" required />
                            <label>First name in Thai</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="lastname_th" class="form-control" value="" required />
                            <label>Last name in Thai</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                            <textarea rows="15" name="desc_en" id="desc_en" class="form-control"
                                onkeyup="count_char(this, 1000, '.desc_en_count')" maxlength="1000" required>
                                Test
                            </textarea>
                            <span class="uk-float-right mr-3 mb-3 charts uk-text-small uk-text-secondary"><span
                                class="desc_en_count">0</span> / 1000</span>
                            <label for="desc_en"> Description in English </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                            <textarea rows="15" name="desc_th" id="desc_th" class="form-control"
                                onkeyup="count_char(this, 1000, '.desc_th_count')" maxlength="1000" required>
                                Test
                            </textarea>
                            <span class="uk-float-right mr-3 mb-3 charts uk-text-small uk-text-secondary"><span
                                class="desc_th_count">0</span> / 1000</span>
                            <label for="desc_th"> Description in Thai </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="personal_id" class="form-control" value="" required />
                            <label> ID Number </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="vat" class="form-control" value="" required />
                            <label> VAT Number </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group select-dropdown">
                            <select class="form-control" name="gender" required>
                                <option>Male</option>
                                <option>Female</option>
                            </select>
                            <label>Gender</label>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 pr-0">
                            <div class="form-group calendar">
                            <input type="text" name="vat" class="form-control" value="" required />
                            <label> Date of birth </label>
                            </div>
                        </div>
                        <div class="col-md-1 col-sm-6 pl-0">
                            <div class="form-group">
                            <input type="text" name="vat" class="form-control" value="" required />
                            <label> Age </label>
                            </div>
                        </div>

                        <!-- <div class="col-md-12 mt-3 mb-3">
                            <hr />
                            <h6 class="uk-text-uppercase pl-1 mb-2 uk-text-bold">Service Area</h6>
                        </div>

                        <div class="col-md-12 mt-0 mb-0 pr-0">
                            <div class="form-group select-hidden">
                            <select name="service" id="servicearea" class="form-control" multiple="multiple"
                                style="display: none">
                                <option value="VIP Service">VIP Service</option>
                                <option value="Bodyguard Service">Bodyguard Service</option>
                                <option value="Security Service">Security Service</option>
                                <option value="Private Investigate">Private Investigate</option>
                                <option value="Education">Education</option>
                                <option value="Car Rental Service">Car Rental Service</option>
                            </select>
                            </div>
                        </div> -->

                        <div class="col-md-12 mt-3 mb-3">
                            <hr />
                            <h6 class="uk-text-uppercase pl-1 uk-text-bold">Address</h6>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                            <input type="text" name="address" class="form-control" value="" required />
                            <label> Address </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <select class="form-control" name="country" required>
                                <option>Thailand</option>
                            </select>
                            <label>Country</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <select class="form-control" name="city" required>
                                <option>Pattaya</option>
                            </select>
                            <label>City</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <select class="form-control" name="district" required>
                                <option>Banglamung</option>
                            </select>
                            <label>District</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="zipcode" class="form-control" value="" required />
                            <label> Zip Code </label>
                            </div>
                        </div>

                        <div class="col-md-12 mt-3 mb-3">
                            <hr />
                            <h6 class="uk-text-uppercase pl-1 uk-text-bold">Physical Information</h6>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="weight" class="form-control" value="85" required />
                            <label> Weight (in KG) </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="weight" class="form-control" value="187" required />
                            <label> Weight (in ib) </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="weight" class="form-control" value="170" required />
                            <label> Height (in cm) </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="weight" class="form-control" value="5.5" required />
                            <label> Height (in ft) </label>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3 mb-0">
                            <h6 class="uk-text-uppercase pl-1 uk-text-bold">Body type</h6>
                        </div>
                        <div class="col-md-3">
                            <label class="form_radio bodytype">
                            <input type="radio" id="medical_needs_n" value="Skinny" name="bodytype">
                            <span class="design"></span>
                            <span class="text">Skinny</span>
                            </label>
                        </div>
                        <div class="col-md-3">
                            <label class="form_radio bodytype">
                            <input type="radio" id="medical_needs_n" value="Normal" name="bodytype">
                            <span class="design"></span>
                            <span class="text">Normal</span>
                            </label>
                        </div>
                        <div class="col-md-3">
                            <label class="form_radio bodytype">
                            <input type="radio" id="medical_needs_n" value="Atheltic" name="bodytype">
                            <span class="design"></span>
                            <span class="text">Atheltic</span>
                            </label>
                        </div>
                        <div class="col-md-3">
                            <label class="form_radio bodytype">
                            <input type="radio" id="medical_needs_n" value="Large" name="bodytype">
                            <span class="design"></span>
                            <span class="text">Large</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-right pt-5 mt-0 mb-4 pb-1">
                <button href="#" class="cancel-btn border-0">
                    <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                </button>
                <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                    <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        companyDetail: {
            id: Number,
            logo: null,
            name_en: String,
            name_local: String,
            description_en: String,
            description_local: String,
            has_parent_company: Boolean,

            registration_id: String,
            tax_id: String,
            founded_year: Number,

            country: String,
            address: String,
            province: String,
            district: String,
            sub_district: String,
            zip_code: Number,

            // company_owner_name: String,
            // company_website: String,
            company_lat: null,
            company_lng: null,

        }
    },
    mounted() {
        $(function () {
            $('#servicearea').bsMultiSelect({
                containerClass: 'dashboardcode-bsmultiselect-large',
            })
        });
    },
    data() {
        return {
            logoUrl: '',
            requiredfileds:[]
        }
    },
    methods: {
        updateCompany() {
            this.loading()
            const api = 'admin/agencies/' + this.companyDetail.id + '/company'
            axios
                .post(api, this.getFormData(), {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                })
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        getFormData() {
            let form = this.$refs.updateCompanyForm;
            let formData = new FormData(form);

            
            formData.append('company_tax_id', formData.get('tax_id'))
            formData.append('company_name_en', formData.get('name_en'))
            formData.append('company_name_local', formData.get('name_local'))
            formData.append('company_registration_id', formData.get('registration_id'))

            return formData
        },
        handleFileUpload() {
            this.companyDetail.logo = this.$refs.logo.files[0]
            this.logoUrl = URL.createObjectURL(this.companyDetail.logo)
        },
        loading() {
            this.$toast.loading({
                message: 'Loading...',
                forbidClick: true,
            })
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },
    }
}
</script>