<template>
  <div>
    <!----- Header Title ----->
    <div class="row">
      <div class="col-md-6">
        <div>
          <h3 class="page-title mb-1">
            {{ this.$route.meta.title }}
          </h3>
        </div>
        <div class="breadcrumb-container">
          <ul class="uk-breadcrumb">
            <li><span>Admin</span></li>
            <li><a href="">{{ this.$route.meta.title }}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 relative"></div>
    </div>
    <div class="row mt-2">
      <div class="col-md-8">
      </div>
      <div class="col-md-4 relative">
        <div class="uk-position-bottom-right pr-3">
          <div class="mb-3 text-right">
            <router-link :to="'/customer/new'">
              <a class="outline-btn pt-1" style="padding: 5px 10px;">
                <i class="fas fa-plus mr-2"></i>Add Customer
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table data-table table-round table-hover">
        <thead>
          <tr>
            <th>
              Name
              <Sort column="id" />
            </th>
            <th>
              Email Address
              <Sort column="name_en" />
            </th>
            <th>
              Phone Number
            </th>
            <th>
              Address
            </th>
            <th>
              DL Number
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in customerList" :key="index">
            <td :id="[list.id]">{{ showName(list) }}</td>
            <td>{{ list.email }}</td>
            <td>{{ list.phone_number }}</td>
            <td>{{ list.address }}</td>
            <td>{{ list.id_number }}</td>
            <td class="text-right">
              <router-link :to="'/customer/details/' + list.id">
                <a class="view-btn">View</a>
              </router-link>
              <a @click="deleteConfirm(list)" class="del-btn"><i class="fal fa-trash-alt"></i></a>
            </td>
          </tr>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- PAGINATION -->
    <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
      <jw-pagination :items="pages" @changePage="onChangePage" :pageSize="limit" :styles="customStyles"
        :labels="customLabels"></jw-pagination>
    </div>
    <div id="deletesuccess" uk-modal>
      <div class="uk-modal-dialog uk-modal-body p-2 text-center">
        <button class="uk-modal-close-outside" type="button" uk-close></button>
        <div class="row align-items-center mb-4">
          <div class="col-md-12 mt-4">
            <h4>Delete customer successful!</h4>
          </div>
        </div>
        <a @click="closeModal" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
      </div>
    </div>
    <!-- Delete Modal -->
    <div id="deletemodal" uk-modal>
      <div class="uk-modal-dialog uk-modal-body p-2 text-center">
        <button class="uk-modal-close-outside" type="button" uk-close></button>
        <div class="row align-items-center mb-4">
          <div class="col-md-12 mt-4">
            <h4>Are you sure you want to delete {{ selectedName }}?</h4>
          </div>
        </div>
        <a @click="closeModal()" class="uk-button uk-button-default mr-3 mb-3" type="button">No</a>
        <a @click="deleteCustomer()" class="uk-button uk-button-danger mr-3 mb-3" type="button">Yes</a>
      </div>
    </div>
  </div>

</template>



<script>

export default {
  props: {
    companyDetail: {
      id: Number,
      bank_account_no: String,
      bank_owner_name: String,
      bank_name: String,
      bank_branch_code: String,
      bank_book: String,

    }
  },
  data() {
    return {
      customerList: [],
      selectedID: 0,
      selectedName: ''
    }
  },
  mounted() {
    this.getCustomerList()
  },

  methods: {
    deleteConfirm(customer) {
      this.selectedID = customer.id
      this.selectedName = customer.fname + " " + customer.lname
      UIkit.modal('#deletemodal').show();
    },
    closeModal() {
      UIkit.modal('#deletemodal').hide();
      UIkit.modal('#deletesuccess').hide();

    },
    deleteCustomer() {
      UIkit.modal('#deletemodal').hide();
      const api = 'https://api.615autorental.com/api/admin/customers/' + this.selectedID
      const admintoken = localStorage.getItem('auth_token_default')
      const headers = {
        'Authorization': `Bearer ${admintoken}`,
        'Content-Type': 'application/json' // You can adjust content type as needed
      };
      axios
        .delete(api, { headers }, {
          params: {
            page: 1,
            limit: 30
          }
        })
        .then((response) => {
          UIkit.modal('#deletesuccess').show();
          this.getCustomerList()

        })
        .catch((error) => {
          console.error('There was an error!', error.message)
        })
    },
    showName(customer) {
      let name = customer.fname + " " + customer.lname
      return name.length <= 30 ? name : name.substring(0, 27) + '...';
    },
    getCustomerList() {
      this.customerList = []
      const api = 'https://api.615autorental.com/api/admin/customers'
      const admintoken = localStorage.getItem('auth_token_default')
      const headers = {
        'Authorization': `Bearer ${admintoken}`,
        'Content-Type': 'application/json' // You can adjust content type as needed
      };
      axios
        .get(api, { headers }, {
          params: {
            page: 1,
            limit: 30
          }
        })
        .then((response) => {
          this.customerList = response.data.data
        })
        .catch((error) => {
          console.error('There was an error!', error.message)
        })
    },
    encode(id) {
      return btoa(id)
    },
  }
}
</script>

<style scoped>
.card-footer {
  text-align: right;
  position: relative;
  background: #fff;
}

.pagination {
  top: 20px;
  position: absolute;
  right: 0px;
  align-content: flex-end;
  text-align: end;
}
</style>