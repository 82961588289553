<template>
  <div class="row align-items-center mb-4">
    <div class="col-lg-12">
      <div class="grey-box p-3 pt-4">
        <div class="row">
          <div class="col-md-6 text-left">
            <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-clock mr-2"></i> Missions </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="uk-float-left">
              <ul class="filter d-md-inline-flex ">
                <li v-for="(day, dayIndex) in $options.statusfilters" 
                    :key="dayIndex" 
                    :class="dayIndex | isActive(filters.status)" 
                    @click="filter(dayIndex, 'status')" >
                    <a href="#">{{ day }}</a>
                </li>
              </ul>
          </div>
          </div>
        </div>
        <div class="view-ui mt-4 mb-2">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table data-table table-round table-hover">
                  <thead>
                    <tr>
                      <th scope="col" class="text-left" width="20%">Mission ID</th>
                      <th scope="col" class="text-left" width="20%">Client</th>
                      <th scope="col" class="text-left" width="15%">Started Date</th>
                      <th scope="col" class="text-left" width="10%">End Date</th>
                      <th scope="col" class="text-left" width="10%">Location</th>
                      <th scope="col" class="text-center" width="10%">Status</th>
                      <th scope="col" class="blankhead" width="80px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(mission, missionKey) in missionLists" :key="missionKey">
                      <td class="text-left">
                          {{ mission.id }}
                      </td>
                      <td class="text-left">
                          {{ mission.customer }}
                      </td>
                      <td class="text-left time" style="font-size: 12px">
                        <i class="fal fa-calendar mr-1 relative" aria-hidden="true" style="top: 1px"></i> 
                        {{ mission.start_time }}
                      </td>
                      <td class="text-left">
                        <i class="fal fa-calendar mr-1 relative" aria-hidden="true" style="top: 1px"></i>
                        {{ mission.end_time}}
                      </td>
                      <td class="text-left">
                        {{ mission.address }}
                      </td>
                      <!-- <td class="text-left avatar-list">
                          <AgentProfile :profiles="mission.hired_staff"></AgentProfile>
                      </td> -->
                      <td class="text-center status">
                        <span :class="mission.status">
                          {{ mission.status }}
                        </span>
                      </td>
                      <td class="text-right">
                        <div class="action-btn d-flex justify-content-end">
                          <router-link :to="'/mission/details/' + mission.id" class="view-btn">
                          </router-link>
                          <router-link :to="'/mission/edit/' + mission.id" class="edit-btn">
                            <i class="fal fa-edit"></i>
                          </router-link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import AgentProfile from "./MissionAgentProfile.vue"


const statusfilters = {
    'all': 'All',
    'quotations-pending': 'Quotations / Pending',
    'ongoing': 'Ongoing ',
    'completed': 'Completed',
    'failed': 'Failed'
}

export default {
    statusfilters: statusfilters,
    components: {
      AgentProfile
    },
    mounted() {
        this.getMissionList()
    },
    data() {
        return {
            missionLists: [],
            filters: {
              status: 'all'
            }
        }
    },
    methods: {
      filter(keyword, type = 'keyword') {
          this.filters[type] = keyword
          this.getMissionList()
      },
      getMissionList() {

        const api = 'admin/missions';
        axios
            .get(api,{
              params: {
                page:1,
                limit: 30,
                sort_column: 'id',
                sort_type: 'desc',
                status: 'pending',
                agency_id: this.$route.params.id
              }
            })
            .then((response) => {
                this.missionLists = []
                if(response.data.success) {
                  this.missionLists = response.data.data.records
                }else{
                  this.$helpers.notification(false, response.data.message)
                }
            })
            .catch((error) => {
                this.$helpers.notification(false, error.message)
                console.error('There was an error!', error.message)
            })
      },
    },
}
</script>

<style scoped>
.filter li a {
  min-width: 312px;
}
</style>