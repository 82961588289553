<template>
  <div>
		<!----- Header Title ----->
		<section class="row">
			<div class="col-md-6">
				<div>
					<h3 class="page-title mb-1">
							{{companyDetails.name_en}}
					</h3>
				</div>
				<div class="breadcrumb-container">
					<ul class="uk-breadcrumb">
						<li><span>Admin</span></li>
						<li><a href="/company">Company</a></li>
						<li><a href="">{{companyDetails.name_en}}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-md-6 relative"></div>
		</section>
    <section class="tpui-container">
                      
                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-building mr-2"></i> Company Information </h5>
                              </div>
                              <div class="col-md-6 text-right">
                              </div>
                            </div>
                            <div class="view-ui mt-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="img-profile-cover m-4" style="margin-top: 40px !important;">
                                    <img :src="companyDetails.logo" />
                                  </div>
                                  <div class="p-5 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 30px !important;">
                                    <div class="row p-0 pb-0">
                                      <div class="col-md-6">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Company Name (English)</h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> Aracely  Nikol </p>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Company Name (Thai)</h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> Aracely  Nikol TH </p>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Description (English)</h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> - </p>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Description (Thai)</h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> - </p>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Founded Year</h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> 1994 </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                               <div class="row mt-4">
                                      <div class="col-md-12">
                                        <div class="row">
                                          <div class="col-xl-4 text-center col-4 mb-md-3 order-3 order-sm-2">
                                            <div class="dashboard p-4">
                                              <h6 class="title mb-3"> Missions </h6>
                                              <span style="font-size: 23px; color: red; font-weight: bold;">{{ companyDetails.total_missions }}</span>
                                            </div>
                                          </div>
                                          <div class="col-xl-4 text-center col-4 mb-md-3 order-4 order-sm-3">
                                            <div class="dashboard p-4">
                                              <h6 class="title mb-3"> Revenue </h6>
                                              <span style="font-size: 23px; color: blue; font-weight: bold;">5,00,000</span>
                                            </div>
                                          </div>
                                          <div class="col-xl-4 text-center col-4 mb-md-3 order-4 order-sm-4">
                                            <div class="dashboard p-4">
                                              <h6 class="title mb-3"> Profit </h6>
                                              <span style="font-size: 23px; color: green; font-weight: bold;">1,00,000</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-money-check-alt mr-2"></i> Bank Details </h5>
                              </div>
                              <div class="col-md-6 text-right">
                              </div>
                            </div>
                            <div class="view-ui mt-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="p-3">
                                    <div class="row p-0 pb-0">
                                      <div class="col-md-4">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Bank Name </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> Kasikorn Bank </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Account Number </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> 2345 5678 8908 </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Owner Name  </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> Dummy Name </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Branch Code </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> 242325 </p>
                                        </div>
                                      </div>
                                      <div class="col-md-4">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Book Bank Attachment </h6>
                                          <a class="view-btn clickable ml-4" data-toggle="collapse" data-target="#accordion_row_1"> View Attachment</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-phone-rotary mr-2"></i> Contact Information </h5>
                              </div>
                              <div class="col-md-6 text-right">
                              </div>
                            </div>
                            <div class="view-ui mt-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="p-3">
                                    <div class="row p-0 pb-0">
                                      <div class="col-md-3">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Contact Person </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> Alverta Parisian </p>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Position </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> Support </p>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Phone  </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> (330) 613-6945 </p>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Email </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> josiah59@hotmail.com </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-map-marker-alt mr-2"></i> Company Address </h5>
                              </div>
                              <div class="col-md-6 text-right">
                              </div>
                            </div>
                            <div class="view-ui mt-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="p-3">
                                    <div class="row p-0 pb-0">
                                      <div class="col-md-6">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Address </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> 56134 Langworth Bypass Suite 106 East Yasmeenfort, ID 44362-8221 </p>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Sub District </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> South Lindsay </p>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> District  </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> South Uniquebury </p>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Province </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> South Cody </p>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Country </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> TH </p>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Zip Code </h6>
                                          <p class="result m-0 mb-4" style="padding-left: 29px;"> 19291 </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-info-circle mr-2"></i> Information </h5>
                              </div>
                              <div class="col-md-6 text-right">
                              </div>
                            </div>
                            <div class="view-ui mt-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="p-3">
                                    <div class="row p-0 pb-0">
                                      <div class="col-md-4 mb-4">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Number of Male Bodyguards </h6>
                                          <span class="uk-label ml-4" style="background-color: #1c3c9d; font-size: 11px;">2</span>
                                        </div>
                                      </div>
                                      <div class="col-md-4 mb-4">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Number of Female BODYGUARDS </h6>
                                          <span class="uk-label ml-4" style="background-color: #1c3c9d; font-size: 11px;">1</span>
                                        </div>
                                      </div>
                                      <div class="col-md-4 mb-4">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Number of Foreinger BODYGUARDS  </h6>
                                          <span class="uk-label ml-4" style="background-color: #1c3c9d; font-size: 11px;">0</span>
                                        </div>
                                      </div>
                                      <div class="col-md-4 mb-4">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Armed BODYGUARDS </h6>
                                          <span class="uk-label ml-4" style="background-color: green; font-size: 11px;">Yes</span>
                                        </div>
                                      </div>
                                      <div class="col-md-4 mb-4">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> TRAINED TO Work in Group </h6>
                                          <span class="uk-label ml-4" style="background-color: red; font-size: 11px;">No</span>
                                        </div>
                                      </div>
                                      <div class="col-md-4 mb-4">
                                        <div>
                                          <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Number of Armed BODYGUARDS </h6>
                                          <span class="uk-label ml-4" style="background-color: #1c3c9d; font-size: 11px;">1</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-info-circle mr-2"></i> Price List Bodyguards </h5>
                              </div>
                              <div class="col-md-6 text-right">
                              </div>
                            </div>
                            <div class="view-ui mt-4 mb-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="table-responsive">
                                    <table id="listinfo" class="table data-table table-round table-hover">
                                      <thead>
                                        <tr>
                                          <th>Period</th>
                                          <th>Hours ( Per Day )</th>
                                          <th>Thai Armed</th>
                                          <th>Thai Unarmed</th>
                                          <th>Foriegner Unarmed</th>
                                          <th>Foriegner Armed</th>
                                          <th>Type</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(list, index) in priceListBodyguard" :key="index">
                                          <td>{{ list.range_period }}</td>
                                          <td>{{ list.hour_per_day }}</td>
                                          <td>{{ list.formatted_thai_armed.replace(/\s/g, "").toLowerCase().toString().split(".")[0] }}</td>
                                          <td>{{ list.formatted_thai_unarmed.replace(/\s/g, "").toLowerCase().toString().split(".")[0] }}</td>
                                          <td>{{ list.formatted_foreigner_unarmed.replace(/\s/g, "").toLowerCase().toString().split(".")[0] }}</td>
                                          <td>{{ list.formatted_foreigner_armed.replace(/\s/g, "").toLowerCase().toString().split(".")[0] }}</td>
                                          <td>{{ list.staff_type }}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-lg-6">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-info-circle mr-2"></i> Car with Driver </h5>
                              </div>
                              <div class="col-md-6 text-right">
                              </div>
                            </div>
                            <div class="view-ui mt-4 mb-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="table-responsive">
                                    <table id="carinfo" class="price table editable table-bordered table-hover table-round">
                                      <thead>
                                          <tr>
                                            <th class="text-center">Name</th>
                                            <th class="text-center">Model</th>
                                            <th class="text-center">Brand</th>
                                            <th class="text-center">Price per day</th>
                                            <!-- <th class="text-center">Passengers</th> -->
                                          </tr>
                                        </thead>
                                        <tbody>
                                        <tr class="addingfild" v-for="(list, index) in carList" :key="index">
                                          <td class="text-center">
                                            {{ list.name_en }}
                                          </td>
                                          <td class="text-center">
                                            {{ list.model }}
                                          </td>
                                        <td class="text-center">
                                            {{ list.total_seat }}
                                          </td>
                                          <td class="text-center">
                                            <div v-for="price in list.prices" :key="index">
                                              <span v-if="price.range_period === 'day'">{{ formatTHB(price.price) }}</span>
                                            </div>
                                          </td>
                                          <!-- <td class="text-center">
                                            {{ list.total_seat }}
                                          </td> -->
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-info-circle mr-2"></i> Police Escort </h5>
                              </div>
                              <div class="col-md-6 text-right">
                              </div>
                            </div>
                            <div class="view-ui mt-4 mb-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="table-responsive">
                                     <table
                                      v-for="(escort, index) in carEscort"
                                      :key="index"
                                      id="carinfo"
                                      class="price table editable table-bordered table-hover table-round"
                                    >
                                      <thead>
                                        <tr>
                                          <th class="text-center">Name</th>
                                          <th class="text-center">Model</th>
                                          <th class="text-center">Brand</th>

                                          <th class="text-center">Passengers</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr class="addingfild" v-for="(list, index2) in escort.vehicles" :key="index2">
                                          <td class="text-center">
                                            {{ list.name_en }}
                                          </td>
                                          <td class="text-center">
                                            {{ list.model }}
                                          </td>
                                          <td class="text-center">
                                            {{ list.brand }}
                                          </td>

                                          <td class="text-center">
                                            {{ list.total_seat }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-info-circle mr-2"></i> List of Bodyguards </h5>
                              </div>
                            </div>
                            <div class="view-ui mt-4 mb-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div v-for="(list, index) in staffList" :key="index" class="col-xl-12 col-lg-4 col-sm-6 col-12 mb-3">
                                    <div class="grey-box bodyguard-list">
                                      <div class="row align-items-center">
                                        <div class="col-xl-3 col-lg-12 mb-xl-0 mb-3">
                                          <div class="guard-img d-inline-flex align-items-center">
                                            <div>
                                              <img :src="list.avatar" alt="user" class="mr-3" />
                                            </div>
                                            <div class="text-left">
                                              <h5 class="text-normal mb-0" style="color: rgb(17 25 70); font-weight: 600 !important; font-size: 14px;">{{ list.first_name }} {{ list.last_name }}</h5>
                                              <span>{{ list.gender }}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-12 border-left no-border mb-xl-0 mb-3">
                                          <div class="guard-item">
                                            <h5 class="mb-0" style="color: #3d3d3d; font-weight: 600 !important; font-size: 13px;">{{ list.nationality }} bodyguard</h5>
                                            <span v-if="list.is_armed">Armed</span>
                                          </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-12 border-left no-border mb-xl-0 mb-3">
                                          <div class="guard-item">
                                            <h5 class="mb-0" style="color: #3d3d3d; font-weight: 600 !important; font-size: 13px;">Missions completed</h5>
                                            <span>??</span>
                                          </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-12 border-left no-border mb-xl-0 mb-3">
                                          <div class="guard-item mw-100">
                                            <h5 class="mb-0" style="color: #3d3d3d; font-weight: 600 !important; font-size: 13px;">Ratings</h5>
                                            <div class="d-inline-flex align-items-center">
                                              5.0
                                              <span class="rating-star ml-2">
                                                <i class="fas fa-star fill"></i>
                                                <i class="fas fa-star fill"></i>
                                                <i class="fas fa-star fill"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <!-- <div class="col-xl-1 col-lg-12 mb-md-3">
                                          <a href="bodygaurd-information.html" class="edit-view mb-xl-2 mr-3 mr-xl-0"
                                            ><i class="fal fa-pen mr-2"></i> Edit</a
                                          >
                                          <a href="#" class="del-view"><i class="fal fa-trash-alt mr-2"></i>Delete</a>
                                        </div> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-info-circle mr-2"></i> Previous Missions </h5>
                              </div>
                              <div class="col-md-6 text-right">
                              </div>
                            </div>
                            <div class="view-ui mt-4 mb-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div v-for="(list, index) in missionList" :key="index" class="mission-item col-lg-12 col-sm-6 col-12 mb-3">
                                    <ul class="grey-box previous-data d-lg-flex align-items-center justify-content-between">
                                      <li class="status">
                                        <h6>Status</h6>
                                        <span v-if="list.status == 'on_bidding'" class="pending">Bidding</span>
                                        <span v-if="list.status == 'agency_rejected'" class="cancelled">Rejected</span>
                                        <span v-if="list.status == 'client_rejected'" class="cancelled">Rejected</span>
                                        <span v-if="list.status == 'agency_accepted'" class="approved">Accepted</span>
                                        <span v-if="list.status == 'client_accepted'" class="approved">Accepted</span>
                                        <span v-if="list.status == 'started'" class="pending">Started</span>
                                        <span v-if="list.status == 'failed'" class="cancelled">Failed</span>
                                        <span v-if="list.status == 'finished'" class="approved">Finished</span>
                                      </li>
                                      <li>
                                        <h6>Customer</h6>
                                        <span>{{ list.name }}</span>
                                      </li>
                                      <li>
                                        <h6>Type of mission</h6>
                                        <span>{{ list.mission_type }}</span>
                                      </li>
                                      <li>
                                        <h6>Price</h6>
                                        <span>{{ list.formatted_total_price }}</span>
                                      </li>
                                      <li>
                                        <h6>Commission</h6>
                                        <span>{{ list.formatted_commission }}</span>
                                      </li>
                                      <li>
                                        <h6>Date</h6>
                                        <span>{{ list.created_at }}</span>
                                      </li>
                                      <li><a href="#" class="view-btn">View</a></li>
                                    </ul>
                                  </div>

                                  <div class="col-lg-12 text-center">
                                    <a href="#" class="load-btn" id="loadmore">Load More<i class="fal fa-chevron-down ml-2"></i></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-info-circle mr-2"></i> Legal Agreement </h5>
                              </div>
                            </div>
                            <div class="view-ui mt-4 mb-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                          <div class="col-lg-12">
                                              <ul uk-tab style="padding-left: 15px;">
                                                <li class="uk-active"><a href="#" class="no-bullet">Legal Agreement (English)</a></li>
                                                <li><a href="#" class="no-bullet">Legal Agreement (Thai)</a></li>
                                              </ul>
                                              <ul class="uk-switcher uk-margin">
                                                <li>
                                                  <div class="form-group">
                                                    <textarea rows="30" name="legal_agreement" readonly></textarea>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div class="form-group">
                                                    <textarea rows="30" name="legal_agreement" readonly></textarea>
                                                  </div>
                                                </li>
                                              </ul>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-12 pb-5">
                                            <a href="" class="btn download-btn"> <i class="fas fa-download mr-2"></i>  Download PDF </a>
                                          </div>
                                        </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      
    </section>

<!-- 
    <div class="card-box mb-5">
      <form>
        <div class="row align-items-center">
          <div class="col-6 mb-3">
            <h5 class="mb-0">Company Information</h5>
          </div>
          <div class="col-md-6 col-4 mb-3 text-right">
            <a href="#" id="carinfo" class="edit-btns"><i class="fal fa-pen mr-1"></i>Edit</a>
            <a href="#" id="carinfo" class="update-btns">Update</a>
          </div>
          <div class="col-6 mb-3 text-right">&nbsp;</div>

          <div class="col-lg-12">
            <div class="grey-box">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label>Company Name EN</label><label style="color: red">{{ isRequired('company_name_en') }} </label>
                  <input
                    type="text"
                    name="company_name_en"
                    v-model="companyInfo.name_en"
                    placeholder="Company Name EN"
                    readonly
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <label>Company Name TH</label><label style="color: red">{{ isRequired('company_name_local') }} </label>
                  <input
                    type="text"
                    name="company_name_local"
                    v-model="companyInfo.name_local"
                    placeholder="Company Name TH"
                    readonly
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label>Contact Person</label><label style="color: red">{{ isRequired('company_contact_person') }} </label>
                  <input
                    type="text"
                    name="company_contact_person"
                    v-model="companyInfo.contact_person"
                    placeholder="Contact Person"
                    readonly
                  />
                </div>

                <div class="col-md-6 mb-3">
                  <label>Position</label><label style="color: red">{{ isRequired('contact_person_position') }} </label>
                  <input
                    type="text"
                    name="contact_person_position"
                    v-model="companyInfo.position"
                    placeholder="Postion, e.g. Manager"
                    readonly
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3">
                  <label>Contact Number</label><label style="color: red">{{ isRequired('company_contact_number') }} </label>
                  <input
                    type="text"
                    name="company_contact_number"
                    v-model="companyInfo.contact_number"
                    placeholder="Phone number"
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <label>Owner</label><label style="color: red">{{ isRequired('company_owner_name') }} </label>
                  <input type="text" name="company_owner_name" v-model="companyInfo.owner_name" placeholder="Name" readonly />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-0 mb-md-3">
                  <div class="row">
                    <div class="col-md-4 mb-3 mb-md-0">
                      <label>PROVINCE</label><label style="color: red">{{ isRequired('company_province') }} </label>
                      <input
                        type="text"
                        name="company_province"
                        v-model="companyInfo.province"
                        placeholder="Enter Province"
                        readonly
                      />
                    </div>
                    <div class="col-md-4 mb-3 mb-md-0">
                      <label>District</label><label style="color: red">{{ isRequired('company_district') }} </label>
                      <input type="text" name="company_district" v-model="companyInfo.district" placeholder="District" readonly />
                    </div>
                    <div class="col-md-4 mb-3 mb-md-0">
                      <label>Zipcode</label><label style="color: red">{{ isRequired('company_zip_code') }} </label>
                      <input type="text" name="company_zip_code" v-model="companyInfo.zip_code" placeholder="00000" readonly />
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-0 mb-md-3">
                  <div class="row">
                    <div class="col-md-4 mb-3 mb-md-0">
                      <label>Can Operate other areas?</label>
                      <input
                        type="text"
                        name="can_go_other_area"
                        v-model="companyDetails.can_travel_other_area"
                        placeholder="yes/no"
                        readonly
                      />
                    </div>
                    <div class="col-md-4 mb-3 mb-md-0">
                      <label>Tax ID</label>
                      <input type="text" name="company_tax_id" v-model="companyInfo.tax_id" placeholder="00000" readonly />
                    </div>
                    <div class="col-md-4 mb-3 mb-md-0">
                      <label>Registration ID</label>
                      <input
                        type="text"
                        name="company_tax_id"
                        v-model="companyInfo.registration_id"
                        placeholder="00000"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mb-3">
                  <label>Sub District </label><label style="color: red">{{ isRequired('company_sub_district') }} </label>
                  <input
                    type="text"
                    name="company_sub_district"
                    v-model="companyInfo.sub_district"
                    placeholder="Sub District"
                    readonly
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <label>Address </label><label style="color: red">{{ isRequired('company_address') }} </label>
                  <input type="text" name="company_address" v-model="companyInfo.address" placeholder="Enter Address" readonly />
                </div>
                <div class="col-md-4 mb-3">
                  <label>Country </label><label style="color: red">{{ isRequired('company_country') }} </label>
                  <input type="text" name="company_country" v-model="companyInfo.country" placeholder="Country" readonly />
                </div>
                <div class="col-md-4 mb-3">
                  <label>Webpage</label><label style="color: red">{{ isRequired('company_website') }} </label>
                  <input
                    type="url"
                    name="company_website"
                    v-model="companyInfo.website"
                    placeholder="https://yourwebsite.com"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    
    <div class="card-box mb-5">
      <div class="row">
        <div class="col-6 mb-3">
          <h5 class="mb-0">Information</h5>
        </div>
        <div class="col-6 mb-3 text-right">
          <a href="#" id="staffinfo" class="edit-btns"><i class="fal fa-pen mr-1"></i>Edit</a>
          <a href="#" id="staffinfo" class="update-btns">Update</a>
        </div>
        <div class="col-lg-12">
          <div class="grey-box">
            <form id="staffinfo">
              <div class="row">
                <div class="col-lg-6 col-md-6 mb-3">
                  <label>Number of male Bodyguards</label>
                  <input type="number" name="maleguard" v-model="companyDetails.size_male_staff" value="123" readonly />
                </div>
                <div class="col-lg-6 col-md-6 mb-3">
                  <label>Number of Female BODYGUARDS</label>
                  <input type="number" name="femaleguard" v-model="companyDetails.size_female_staff" value="123" readonly />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6 mb-3">
                  <label>Number of Foreinger BODYGUARDS</label>
                  <input type="number" name="foreinger" v-model="companyDetails.size_foreigner_staff" value="45" readonly />
                </div>
                <div class="col-lg-6 col-md-6 mb-3">
                  <label>Number of Thai BODYGUARDS</label>
                  <input type="number" name="thai" v-model="companyDetails.size_local_staff" value="56" readonly />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6 mb-3">
                  <label>Armed BODYGUARDS</label>
                  <div class="d-flex align-items-center border-bottom">
                    <div class="form-check-inline mr-4">
                      <input type="radio" v-model="companyDetails.has_armed" value="true" readonly id="ayes" name="Armedguard" />
                      <label for="ayes" class="pl-0">Yes</label>
                    </div>
                    <div class="form-check-inline">
                      <input type="radio" v-model="companyDetails.has_armed" value="false" readonly id="ano" name="Armedguard" />
                      <label for="ano" class="pl-0">No</label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 mb-3">
                  <label>TRAINED TO Work in Group</label>
                  <div class="d-flex align-items-center border-bottom">
                    <div class="form-check-inline mr-4">
                      <input
                        type="radio"
                        v-model="companyDetails.trained_for_group"
                        value="true"
                        readonly
                        id="trainedyes"
                        name="trainedgroup"
                      />
                      <label for="trainedyes" class="pl-0">Yes</label>
                    </div>
                    <div class="form-check-inline">
                      <input
                        type="radio"
                        v-model="companyDetails.trained_for_group"
                        value="true"
                        readonly
                        id="trainedno"
                        name="trainedgroup"
                      />
                      <label for="trainedno" class="pl-0">No</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="companyDetails.has_armed">
                <div class="col-lg-6 col-md-6 mb-3">
                  <label>Number of Armed BODYGUARDS</label>
                  <input type="number" name="foreinger" v-model="companyDetails.size_armed_staff" value="45" readonly />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="card-box mb-5">
      <div class="row p-2">
        <div class="col-lg-12 mb-5 mb-lg-0">
          <div class="row">
            <div class="col-md-6 col-8 mb-3">
              <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-info-circle mr-2"></i> Price List Bodyguards </h5>
            </div>
            <div class="col-md-6 col-4 mb-3 text-right">
              <a href="#" id="listinfo" class="edit-btns"><i class="fal fa-pen mr-1"></i>Edit</a>
              <a href="#" id="listinfo" class="update-btns">Update</a>
            </div>
            <div class="col-lg-12">
              <div class="grey-box p-0">
                <div class="table-responsive">
                  <table id="listinfo" class="price table table-hover editable table-bordered table-round">
                    <tr>
                      <th>Period</th>
                      <th>Hours ( Per Day )</th>

                      <th>Thai Armed</th>
                      <th>Thai Unarmed</th>
                      <th>Foriegner Unarmed</th>
                      <th>Foriegner Armed</th>
                      <th>Type</th>
                    </tr>
                    <tr v-for="(list, index) in priceListBodyguard" :key="index">
                      <td>{{ list.range_period }}</td>
                      <td>{{ list.hour_per_day }}</td>
                      <td>{{ list.formatted_thai_armed.replace(/\s/g, "").toLowerCase().toString().split(".")[0] }}</td>
                      <td>{{ list.formatted_thai_unarmed.replace(/\s/g, "").toLowerCase().toString().split(".")[0] }}</td>
                      <td>{{ list.formatted_foreigner_unarmed.replace(/\s/g, "").toLowerCase().toString().split(".")[0] }}</td>
                      <td>{{ list.formatted_foreigner_armed.replace(/\s/g, "").toLowerCase().toString().split(".")[0] }}</td>
                      <td>{{ list.staff_type }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="card-box mb-5">
      <div class="row"> -->
        <!-- <div class="col-lg-6 mb-5 mb-lg-0">
          <div class="row">
            <div class="col-md-6 col-8 mb-3">
              <h5 class="mb-0">Car with Driver</h5>
            </div>
            <div class="col-md-6 col-4 mb-3 text-right">
              <a href="#" id="carinfo" class="edit-btns"><i class="fal fa-pen mr-1"></i>Edit</a>
              <a href="#" id="carinfo" class="update-btns">Update</a>
            </div>
            <div class="col-lg-12">
              <div class="grey-box p-0 mb-3">
                <div class="table-responsive">
                  <table id="carinfo" class="price table editable table-bordered table-hover table-round">
                    <tbody class="dynamic-field" id="dynamiccar">
                      <tr>
                        <th class="text-center">Name</th>
                        <th class="text-center">Model</th>
                        <th class="text-center">Brand</th>
                        <th class="text-center">Price per day</th>
                        <th class="text-center">Passengers</th>
                      </tr>
                      <tr class="addingfild" v-for="(list, index) in carList" :key="index">
                        <td class="text-center">
                          {{ list.name_en }}
                        </td>
                        <td class="text-center">
                          {{ list.model }}
                        </td>
                       <td class="text-center">
                          {{ list.total_seat }}
                        </td>
                        <td class="text-center">
                          <div v-for="price in list.prices" :key="index">
                            <span v-if="price.range_period === 'day'">{{ formatTHB(price.price) }}</span>
                          </div>
                        </td>
                        <td class="text-center">
                          {{ list.total_seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <a href="#" class="submit-btn addfield" id="dynamiccar">Add More</a>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-lg-6 mb-5 mb-lg-0">
          <div class="row">
            <div class="col-md-6 col-8 mb-3">
              <h5 class="mb-0">Police Escort</h5>
            </div>
            <div class="col-md-6 col-4 mb-3 text-right">
              <a href="#" id="carinfo" class="edit-btns"><i class="fal fa-pen mr-1"></i>Edit</a>
              <a href="#" id="carinfo" class="update-btns">Update</a>
            </div>
            <div class="col-lg-12">
              <div class="grey-box p-0 mb-3">
                <div class="table-responsive">
                  <table
                    v-for="(escort, index) in carEscort"
                    :key="index"
                    id="carinfo"
                    class="price table editable table-bordered table-hover table-round"
                  >
                    <tbody class="dynamic-field" id="dynamiccar">
                      <tr>
                        <th class="text-center">Name</th>
                        <th class="text-center">Model</th>
                        <th class="text-center">Brand</th>

                        <th class="text-center">Passengers</th>
                      </tr>
                      <tr class="addingfild" v-for="(list, index2) in escort.vehicles" :key="index2">
                        <td class="text-center">
                          {{ list.name_en }}
                        </td>
                        <td class="text-center">
                          {{ list.model }}
                        </td>
                        <td class="text-center">
                          {{ list.brand }}
                        </td>

                        <td class="text-center">
                          {{ list.total_seat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <a href="#" class="submit-btn addfield" id="dynamiccar">Add More</a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="card-box mb-5">
      <div class="row">
        <div class="col-6 mb-3">
          <h5 class="mb-0">Contact Person</h5>
        </div>
        <div class="col-6 mb-3 text-right">
          <a href="#" id="contactinfo" class="edit-btns"><i class="fal fa-pen mr-1"></i>Edit</a>
          <a href="#" id="contactinfo" class="update-btns">Update</a>
        </div>
        <div class="col-lg-12">
          <div class="grey-box">
            <form id="contactinfo">
              <div class="row">
                <div class="col-lg-6 col-md-6 mb-3">
                  <label>Name</label>
                  <input type="text" v-model="companyDetails.contact_person" name="personname" value="abc" readonly />
                </div>
                <div class="col-lg-6 col-md-6 mb-3">
                  <label>Email</label>
                  <input
                    type="email"
                    v-model="companyDetails.contact_person_email"
                    name="personemail"
                    value="abc@gmail.com"
                    readonly
                  />
                </div>
                <div class="col-lg-6 col-md-6 mb-3">
                  <label>Position</label>
                  <input
                    type="text"
                    v-model="companyDetails.contact_person_position"
                    name="position"
                    value="Delivery Manager"
                    readonly
                  />
                </div>
                <div class="col-lg-6 col-md-6 mb-3">
                  <label>Phone</label>
                  <input
                    type="text"
                    v-model="companyDetails.contact_person_number"
                    name="personphone"
                    value="+ 12345 67890"
                    readonly
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="card-box mb-5">
      <div class="row align-items-center">
        <div class="col-md-4 mb-3">
          <h5 class="mb-0">List of Bodyguards</h5>
        </div>
        <div class="col-md-8 mb-3">
          <div class="d-flex align-items-center justify-content-md-end">
            <div class="form-group search notify-btn mr-3 mb-0">
              <input type="text" name="keyword" placeholder="Search for..." class="form-control shadow-none border-0" />
              <div class="input-group-prepend">
                <span class="input-group-text border-0 bg-transparent">
                  <button type="submit" class="bg-transparent border-0"><i class="far fa-search" aria-hidden="true"></i></button>
                </span>
              </div>
            </div>
            <a href="add-bodyguard-information.html" class="outline-btn"
              ><i class="fal fa-plus-circle mr-2" aria-hidden="true"></i>Add</a
            >
          </div>
        </div>
        <div v-for="(list, index) in staffList" :key="index" class="col-xl-12 col-lg-4 col-sm-6 col-12 mb-3">
          <div class="grey-box bodyguard-list">
            <div class="row align-items-center">
              <div class="col-xl-3 col-lg-12 mb-xl-0 mb-3">
                <div class="guard-img d-inline-flex align-items-center">
                  <div>
                    <img :src="list.avatar" alt="user" class="mr-3" />
                  </div>
                  <div class="text-left">
                    <h5 class="text-normal mb-0" style="color: rgb(17 25 70); font-weight: 600 !important; font-size: 14px;">{{ list.first_name }} {{ list.last_name }}</h5>
                    <span>{{ list.gender }}</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-12 border-left no-border mb-xl-0 mb-3">
                <div class="guard-item">
                  <h5 class="mb-0" style="color: #3d3d3d; font-weight: 600 !important; font-size: 13px;">{{ list.nationality }} bodyguard</h5>
                  <span v-if="list.is_armed">Armed</span>
                </div>
              </div>
              <div class="col-xl-3 col-lg-12 border-left no-border mb-xl-0 mb-3">
                <div class="guard-item">
                  <h5 class="mb-0" style="color: #3d3d3d; font-weight: 600 !important; font-size: 13px;">Missions completed</h5>
                  <span>??</span>
                </div>
              </div>
              <div class="col-xl-2 col-lg-12 border-left no-border mb-xl-0 mb-3">
                <div class="guard-item mw-100">
                  <h5 class="mb-0" style="color: #3d3d3d; font-weight: 600 !important; font-size: 13px;">Ratings</h5>
                  <div class="d-inline-flex align-items-center">
                    5.0
                    <span class="rating-star ml-2">
                      <i class="fas fa-star fill"></i>
                      <i class="fas fa-star fill"></i>
                      <i class="fas fa-star fill"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xl-1 col-lg-12 mb-md-3">
                <a href="bodygaurd-information.html" class="edit-view mb-xl-2 mr-3 mr-xl-0"
                  ><i class="fal fa-pen mr-2"></i> Edit</a
                >
                <a href="#" class="del-view"><i class="fal fa-trash-alt mr-2"></i>Delete</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="card-box mb-5">
      <div class="row mission-list">
        <div class="col-md-12 mb-3">
          <h5 class="mb-0">Previous Missions</h5>
        </div>
        <div v-for="(list, index) in missionList" :key="index" class="mission-item col-lg-12 col-sm-6 col-12 mb-3">
          <ul class="grey-box previous-data d-lg-flex align-items-center justify-content-between">
            <li class="status">
              <h6>Status</h6>
              <span v-if="list.status == 'on_bidding'" class="pending">Bidding</span>
              <span v-if="list.status == 'agency_rejected'" class="cancelled">Rejected</span>
              <span v-if="list.status == 'client_rejected'" class="cancelled">Rejected</span>
              <span v-if="list.status == 'agency_accepted'" class="approved">Accepted</span>
              <span v-if="list.status == 'client_accepted'" class="approved">Accepted</span>
              <span v-if="list.status == 'started'" class="pending">Started</span>
              <span v-if="list.status == 'failed'" class="cancelled">Failed</span>
              <span v-if="list.status == 'finished'" class="approved">Finished</span>
            </li>
            <li>
              <h6>Customer</h6>
              <span>{{ list.name }}</span>
            </li>
            <li>
              <h6>Type of mission</h6>
              <span>{{ list.mission_type }}</span>
            </li>
            <li>
              <h6>Price</h6>
              <span>{{ list.formatted_total_price }}</span>
            </li>
            <li>
              <h6>Commission</h6>
              <span>{{ list.formatted_commission }}</span>
            </li>
            <li>
              <h6>Date</h6>
              <span>{{ list.created_at }}</span>
            </li>
            <li><a href="#" class="view-btn">View</a></li>
          </ul>
        </div>

        <div class="col-lg-12 text-center">
          <a href="#" class="load-btn" id="loadmore">Load More<i class="fal fa-chevron-down ml-2"></i></a>
        </div>
      </div>
    </div> -->
    <!-- <div class="card-box mb-5">
      <div class="row">
        <div class="col-md-6 col-8 mb-3">
          <h5 class="mb-0">Legal Agreement</h5>
        </div>

        <div class="col-md-6 col-4 mb-3 text-right">
          <a href="#" id="carinfo" class="edit-btns"><i class="fal fa-pen mr-1"></i>Edit</a>
        </div>
        <div class="col-lg-12">
          <div class="grey-box company-data">
            <h5>LEGAL AGREEMENT</h5>
            <p>
              {{ companyDetails.legal_agreement_text }}
            </p>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>


<script>
export default {
  mounted() {
    //document.title = "Edit Case " + this.$route.params.id
    this.checkRequired()
   // this.$route.meta.title = this.companyName
    if (this.isValidId(this.$route.params.id)) {
      this.getCompanyDetails()
      this.getCompanyPrices()
      this.getCompanyCars()
      this.getEscorts()
      this.getBodyguardList()
      this.getMissionList()
    } else {
      console.log('dsdasdsada')
      this.$router.push('/404')
    }
  },
  metaInfo() {
    return {
      title: () => {
        return 'My title with params ' + this.$route.params.id
      },
    }
  },
  data() {
    return {
      companyDetails: {},
      parentCompany: {},
      requiredfileds: {},
      companyInfo: {},
      dangerPrices: [],
      priceListBodyguard: [],
      carList: [],
      carEscort: [],
      staffList: [],
      missionList: [],
    }
  },
  methods: {
    isRequired(key) {
      if (this.requiredfileds.hasOwnProperty(key)) {
        return '*'
      } else {
        return ''
      }
    },
    hasCopmany() {
      return this.companyDetails.hasOwnProperty('parent_company')
    },
    checkRequired() {
      let formData = new FormData()
      formData.append('test', 'test')
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies'
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .post(api, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${admintoken}`,
          },
        })
        .then((response) => {
          console.log(response)
          this.requiredfileds = response.data.data
          console.log(this.requiredfileds)
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getCompanyDetails() {
      const company_id = atob(this.$route.params.id)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies/' + company_id
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.companyDetails = response.data.data
          if (this.hasCopmany()) {
            this.companyInfo = response.data.data.parent_company
          }
          if (this.companyDetails.hasOwnProperty('danger_prices')) {
            this.dangerPrices = response.data.data.danger_prices
          }
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getCompanyPrices() {
      const company_id = atob(this.$route.params.id)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies/' + company_id + '/prices?page=1&limit=10'
      const admintoken = localStorage.getItem('auth_token_default')

      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.priceListBodyguard = response.data.data
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getCompanyCars() {
      const company_id = atob(this.$route.params.id)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies/' + company_id + '/vehicles?page=1&limit=10'
      const admintoken = localStorage.getItem('auth_token_default')

      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.carList = response.data.data;
          console.log(response.data.data)
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getEscorts() {
      const company_id = atob(this.$route.params.id)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies/' + company_id + '/escorts?page=1&limit=10'
      const admintoken = localStorage.getItem('auth_token_default')

      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.carEscort = response.data.data
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getBodyguardList() {
      const company_id = atob(this.$route.params.id)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies/' + company_id + '/staff?page=1&limit=100'
      const admintoken = localStorage.getItem('auth_token_default')

      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.staffList = response.data.data.records
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getMissionList() {
      const company_id = atob(this.$route.params.id)
      const api =
        'https://dev-api.trueprotection.co.th/api/admin/missions?page=1&limit=10&keyword=&sort_column=id&sort_type=desc&status=history&agency_id=' +
        company_id
      const admintoken = localStorage.getItem('auth_token_default')

      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.missionList = response.data.data.records
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    isValidId(str) {
      try {
        return btoa(atob(str)) == str
      } catch (err) {
        return false
      }
    },
    formatTHB(num) {
      return "฿" + Number(num).toLocaleString();
    },
  },
}


</script>

<style scoped>
</style>