<template>
  <li>
    <a class="uk-accordion-title" href="#"> 
      <span uk-icon="info" class="mr-2 relative" style="top: -2px;"></span> Legal Agreement 
    </a>
    <div class="uk-accordion-content legal-agreement">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="grey-box">
            <h6 class="p-2 mt-2 uk-text-bold"> <i class="fas fa-balance-scale mr-2"></i> Legal Agreement </h6>
            <div class="row">
              <div class="col-lg-12">
                  <ul uk-tab style="padding-left: 15px;">
                    <li class="uk-active">
                      <a href="#" class="no-bullet">Legal Agreement (English)</a>
                    </li>
                    <!-- <li>
                      <a href="#" class="no-bullet">Legal Agreement (Thai)</a>
                    </li> -->
                  </ul>
                  <ul class="uk-switcher uk-margin">
                    <li>
                      <div class="form-group">
                        <textarea rows="30" name="legal_agreement" v-model="company.legal_agreement" required ></textarea>
                        <label>Please enter Legal Agreement text (English)</label>
                        <!-- <label style="color: red">{{ isRequired('legal_agreement') }} </label> -->
                      </div>
                    </li>
                    <!-- <li>
                      <div class="form-group">
                        <textarea rows="30" name="legal_agreement" v-model="company.legal_agreement" required ></textarea>
                        <label>Please enter Legal Agreement text (Thai)</label>
                      </div>
                    </li> -->
                  </ul>
              </div>
              <div class="col-md-6 mb-md-0">
                  <h6 style="font-weight: 600; color: #111;"> <span uk-icon="upload"></span> Upload Document </h6>
                  <div class="js-upload uk-placeholder uk-text-center mr-2">
                      <div class="mb-2 mt-2">
                        <span class="uk-link btn-upload"> Upload </span>
                      </div>
                      <span uk-icon="icon: cloud-upload" class="mr-2"></span>
                      <span class="uk-text-middle"> Drag & Drop file </span>
                      <div uk-form-custom>
                        <input
                          type="file"
                          name="legal_agreement_doc"
                          class="file-upload"
                          id="file"
                          ref="legalAgreementDoc"
                          v-on:change="legalAgreementDocUpload()"
                        />
                        <img v-if="legalUrl" :src="legalUrl" />

                      </div>
                  </div>
                  
                  <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
              </div>
            </div>

            <!-- <h6 class="p-2 mb-3 uk-text-bold"> <i class="fas fa-balance-scale mr-2"></i> Legal Agreement </h6> -->
            <div class="row">
              <div class="col-md-12 pb-5">
                <a href="" class="btn download-btn"> <i class="fas fa-download mr-2"></i>  Download PDF </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
  export default {
    props: {
        company: {
          legal_agreement: String,
          legal_agreement_doc: null,
        }
    },
    data() {
      return {
        requiredfileds: {},
        legalUrl: ''
      }
    },
    mounted() {
        this.legalUrl = this.company.legal_agreement_doc? this.legal_agreement_doc : this.legalUrl
    },
    methods: {
      legalAgreementDocUpload() {
        this.company.legal_agreement_doc = this.$refs.legalAgreementDoc.files[0]
			  this.legalUrl = URL.createObjectURL(this.company.legal_agreement_doc)
      }
    }
  }
</script>