<template>
  <div>
    <!----- Header Title ----->
    <div class="row content-header">
      <div class="col-md-2">
        <Header />
      </div>

      <div class="col-md-10">
        <div class="grey-box mt-5">
          <div class="">
            <DateRange @dateChange="dateChange" />
            <div class="uk-float-left mt-4">
              <ul class="filter d-md-inline-flex d-none">
                <li v-for="(day, dayIndex) in dayMenus" 
                    :key="dayIndex" 
                    :class="dayIndex | isActive(filters.day)" 
                    @click="filter(dayIndex)"
                >
                  <a href="#">{{ day }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- cards -->
    <div>
      <div class="row">
        <div class="col-md-12"
          uk-scrollspy="cls: uk-animation-slide-bottom-small; target: .animate; delay: 200; repeat: false">
          <div class="row">
            <ul class="dashboard-page">
              <li class="multi-value pending animate">
                <div class="icon">
                  <i class="fal fa-clock"></i>
                </div>
                <div class="result">
                  <div class="uk-text-center">
                    <div class="uk-width-1-1 main">
                      <span class="title">
                        <span class="primary-title"> Pending </span>
                        <span class="subtitle"> Missions </span>
                      </span>
                      <span class="value count"> {{ this.cards.pending }} </span>
                    </div>
                  </div>
                  <!-- <div class="uk-text-center" uk-grid>
                    <div class="uk-width-1-2 sub text-left">
                      <span class="title"> Net Amount </span>
                      <span class="value"> ฿200,000 </span>
                    </div>
                    <div class="uk-width-1-2 sub text-right">
                      <span class="title"> Commission </span>
                      <span class="value"> ฿20,000 </span>
                    </div>
                  </div> -->
                </div>
              </li>
              <li class="multi-value ongoing animate">
                <div class="icon">
                  <i class="fal fa-play-circle"></i>
                </div>
                <div class="result">
                  <div class="uk-text-center">
                    <div class="uk-width-1-1 main">
                      <span class="title">
                        <span class="primary-title"> OnGoing </span>
                        <span class="subtitle"> Missions </span>
                      </span>
                      <span class="value count"> {{ this.cards.on_going }} </span>
                    </div>
                  </div>
                  <!-- <div class="uk-text-center" uk-grid>
                    <div class="uk-width-1-2 sub text-left">
                      <span class="title"> Net Amount </span>
                      <span class="value"> ฿16,520 </span>
                    </div>
                    <div class="uk-width-1-2 sub text-right">
                      <span class="title"> Commission </span>
                      <span class="value"> ฿16,520 </span>
                    </div>
                  </div> -->
                </div>
              </li>
              <li class="multi-value completed animate">
                <div class="icon">
                  <i class="fal fa-check-circle"></i>
                </div>
                <div class="result">
                  <div class="uk-text-center">
                    <div class="uk-width-1-1 main">
                      <span class="title">
                        <span class="primary-title"> Completed </span>
                        <span class="subtitle"> Missions </span>
                      </span>
                      <span class="value count"> {{ this.cards.finished }} </span>
                    </div>
                  </div>
                  <!-- <div class="uk-text-center" uk-grid>
                    <div class="uk-width-1-2 sub text-left">
                      <span class="title"> Net Amount </span>
                      <span class="value"> ฿16,520 </span>
                    </div>
                    <div class="uk-width-1-2 sub text-right">
                      <span class="title"> Commission </span>
                      <span class="value"> ฿16,520 </span>
                    </div>
                  </div> -->
                </div>
              </li>
              <li class="multi-value cancel animate">
                <div class="icon">
                  <i class="fal fa-exclamation-circle"></i>
                </div>
                <div class="result">
                  <div class="uk-text-center">
                    <div class="uk-width-1-1 main">
                      <span class="title">
                        <span class="primary-title"> Cancelled </span>
                        <span class="subtitle"> Missions </span>
                      </span>
                      <span class="value count"> {{ this.cards.cancelled }} </span>
                    </div>
                  </div>
                  <!-- <div class="uk-text-center" uk-grid>
                    <div class="uk-width-1-2 sub text-left">
                      <span class="title"> Net Amount </span>
                      <span class="value"> ฿16,520 </span>
                    </div>
                    <div class="uk-width-1-2 sub text-right">
                      <span class="title"> Commission </span>
                      <span class="value"> ฿16,520 </span>
                    </div>
                  </div> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <List></List>
    
  </div>
</template>

<script>

import List from './_List.vue'

export default {
  components: {
    List
  },
  mounted() {
    this.getCards()
  },
  data() {
    return {
      cards: {},
      filters: {
        day: 'all'
      },
      start_date: null,
      end_date: null,
      dayMenus: this.$helpers.dayMenus()

    }
  },
  methods: {
    filter(keyword, type = 'keyword') {
      this.filters[type] = keyword
      this.getCards()
    },
    getCards() {
      const api = 'admin/dashboard/missions'
      axios
      .get(api, {
        params: {
          keyword: this.filters.day,
          start_date: this.start_date,
          end_date: this.end_date,
        }
      })
      .then((response) => {
          if(response.data.success) {
              this.cards = response.data.data
          }
      })
      .catch((error) => {
        var notyf = new Notyf();
            notyf.alert(error.message);
        console.error('There was an error!', error)
      })
    },
    sdateChange() {
      var sDate = new Date(this.start_date)

      if(!this.end_date) {
        this.addNextDay()
      } else {

        var eDate = new Date(this.end_date)
        if(sDate.getTime() > eDate.getTime()) {
          this.addNextDay()
        }else{
          this.setDateFormat(sDate, eDate)
        }
      }
    },
    edateChange() {
      var endDate = new Date(this.end_date)

      if(!this.start_date) {
        this.addPreviousDay()
      } else {
        var startDate = new Date(this.start_date)
        if(startDate.getTime() > endDate.getTime()) {
          this.addPreviousDay()
        }else{
          this.setDateFormat(startDate, endDate)
        }
      }
    },
    addNextDay(){
      var sDate = new Date(this.start_date)
      var eDate = new Date(this.start_date)
          eDate.setDate(eDate.getDate() + 1)

          this.setDateFormat(sDate, eDate)
    },
    addPreviousDay(){
      var eDate = new Date(this.end_date)
      var sDate = new Date(this.end_date)
          sDate.setDate(sDate.getDate() - 1)
          this.setDateFormat(sDate, eDate)
    },
    setDateFormat(sDate, eDate) {
      var start = sDate.getDate() < 10 ? '0'+ sDate.getDate() : sDate.getDate()
      var end = eDate.getDate() < 10 ? '0'+ eDate.getDate() : eDate.getDate()
      
      var sMonth = sDate.getMonth() + 1
          sMonth = sMonth < 10 ? '0' + sMonth : sMonth

      var eMonth = eDate.getMonth() + 1
          eMonth = eMonth < 10 ? '0' + eMonth : eMonth

      this.start_date = sDate.getFullYear() + '-' + sMonth + '-' + start
      this.end_date = eDate.getFullYear() + '-' + eMonth + '-' + end

      this.getCards()
    },
  },
}
</script>

<style scoped>
.card-footer {
  text-align: right;
  position: relative;
  background: #fff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  border: 0;
  box-shadow: 0px 10px 10px 20px rgb(176 184 214 / 9%), 10px 10px 15px -5px #b0b8d6;
  border-radius: 8px;
}

.card-header:first-child {
  border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
}

.card-header,
.card-title {
  margin-bottom: 0;
}

.card-title {
  line-height: 1.2;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0.02rem;
  font-size: 1.1rem;
}

.card-title:only-child {
  margin-bottom: 0;
}

.grey-box {
  float: right; 
  background-color: transparent; 
  box-shadow: none; 
  border: none; 
  padding: 10px 0
}
</style>