<template>
  <div>
    <!----- Header Title ----->
    <section class="row">
      <Header />
    </section>

    <!----- * TABS * ----->

    <nav class="service-tabs">
        <ul class="nav nav-fill">
          <li v-for="(list, index) in tablist" :key="'a' + index">
            <router-link class="nav-item nav-link" :to="list.path" tag="a" v-if="list.checked == true">
              {{ list.name }}
            </router-link>
            <dd class="nav-item nav-link" v-if="list.checked == false">
              {{ list.name }}
            </dd>
            <label class="switch" v-if="list.name != 'General'">
              <input type="checkbox" v-model="list.checked"/>
              <span class="slider round"></span>
            </label>
          </li>
        </ul>
    </nav>

    <section class="tpui-container">
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-user mr-2"></i> Profile</h5>
              </div>
              <div class="col-md-6 text-right">
              
              </div>
            </div>
            <fieldset class="mt-5">
                <CompanyProfileEdit v-if="companyDetail.id" :companyDetail="companyDetail"></CompanyProfileEdit>
            </fieldset>

            <!---- Contact Person ---->

            <fieldset
              style="background-color: rgb(237 243 255) !important; border: dashed 2px rgb(173 199 250) !important">
              <div class="row pl-3 pr-1">
                <div class="col-md-12 mt-3 mb-1 pt-3">
                  <h6 class="uk-text-uppercase pl-1 uk-text-bold">Contact</h6>
                </div>

                <div class="col-md-12 mb-2">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group vue-tel-input-wrapper">
                        <template>
                          <vue-tel-input v-model="phone"></vue-tel-input>
                          <label>Phone Number</label>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <input type="text" name="company" class="form-control" value="" required />
                        <label> Email </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-0 mb-1 pt-0">
                  <hr class="mb-0 pb-0 pt-1 mt-0" />
                  <h6 class="uk-text-uppercase pl-1 uk-text-bold mt-0">Emergency Contact</h6>
                </div>

                <div class="col-md-12 mb-2">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <input type="text" name="company" class="form-control" value="" required />
                        <label>Contact Person</label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <input type="text" name="company" class="form-control" value="" required />
                        <label>Position</label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group vue-tel-input-wrapper">
                        <template>
                          <vue-tel-input v-model="phone"></vue-tel-input>
                          <label>Phone Number</label>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <input type="text" name="company" class="form-control" value="" required />
                        <label> Email </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-right pt-1 mt-0 mb-4 pb-1">
                  <button href="#" class="cancel-btn border-0">
                    <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                  </button>
                  <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                    <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                  </button>
                </div>
              </div>
            </fieldset>

            <!---- Skill ---->

            <fieldset class="mt-1">
              <div class="row pl-3 pr-1">
                <div class="col-md-12 mt-4">
                  <h6 class="uk-text-uppercase pl-1 uk-text-bold">Skill</h6>
                </div>
                <div class="col-md-12 mb-2">
                  <div class="form-group select-hidden">
                    <select name="skill" id="skill" class="form-control" multiple="multiple" style="display: none">
                      <option value="">Karate</option>
                      <option value="">Sharpshooting</option>
                      <option value="">Krev Maga</option>
                      <option value="">Boxing</option>
                      <option value="">Drving Car</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12 text-right pt-1 mt-0 mb-4 pb-1">
                  <button href="#" class="cancel-btn border-0">
                    <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                  </button>
                  <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                    <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                  </button>
                </div>
              </div>
            </fieldset>

          </div>
        </div>
      </div>
    </section>

    <section class="tpui-container">
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box pt-3">
            <div class="col-md-12">
              <div class="pt-2">
                <div class="row">
                  <div class="col-md-6 text-left">
                    <h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-map-marker-alt mr-2"></i> Company Address</h5>
                  </div>
                  <div class="col-md-6 text-right"></div>
                </div>
                <div class="row">
                  <div class="col-md-12 pb-3 pt-1">
                    <div class="table-responsive">
                      <table class="table data-table table-round table-hover">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Address</th>
                            <th>Province</th>
                            <th>Country</th>
                            <th class="blankhead" width="100px"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">
                              <i uk-icon="home"></i>
                            </td>
                            <td>10/31 Soi Sukhumvit 13, Khlong Toei Nuea</td>
                            <td>Bangkok</td>
                            <td>Thailand</td>
                            <td class="text-right">
                              <a class="edit-btn" data-toggle="collapse" data-target="#latest_address_1"><i class="fal fa-edit"></i></a>
                              <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="9" class="p-0">
                              <div id="latest_address_1" class="collapse">
                                <form>
                                  <div class="p-4 bg-light"
                                    style="border: solid 1px #e9e9e9; padding-bottom: 20px !important">
                                    <div class="row p-0 pb-0 mt-3 view-ui">
                                      <div class="col-md-12">
                                        <div>
                                          <h6 class="title mb-3"><i class="fas fa-map-marker-alt mr-1"></i> Full Address
                                          </h6>
                                          <p class="result m-0 mb-4">
                                            10/31 Soi Sukhumvit 13, Khlong Toei Nuea, Bangkok, Thailand 10291
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-12">
                                        <div class="gmap-iframe ratio ratio-4x3 ratio-sm-21x9" lc-helper="gmap-embed">
                                          <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3875.6647443463908!2d100.5592540929188!3d13.73873605017498!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x524dad67eb707a4b!2sThe%20Trendy%20Office%20Building!5e0!3m2!1sen!2sth!4v1646302645610!5m2!1sen!2sth"
                                            width="100%" height="450" style="border: 0" allowfullscreen=""
                                            loading="lazy"></iframe>
                                        </div>
                                      </div>
                                      <div class="col-md-12 pt-4">
                                        <div class="uk-margin">
                                          <div class="uk-inline uk-width-1-1">
                                            <span class="uk-form-icon" uk-icon="icon: search"></span>
                                            <input class="uk-input uk-width-1-1" type="text" required />
                                            <label class="ml-5"> Search Address </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group select-box">
                                          <select class="form-control" required>
                                            <option>-- Select --</option>
                                          </select>
                                          <label>Province</label>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <select class="form-control" required>
                                            <option>-- Select --</option>
                                          </select>
                                          <label>District</label>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <select class="form-control" required>
                                            <option>-- Select --</option>
                                          </select>
                                          <label>Sub District</label>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <input type="text" name="search" class="form-control" required />
                                          <label> Zip code </label>
                                        </div>
                                      </div>
                                      <div class="col-md-9">
                                        <div class="uk-margin">
                                          <div class="uk-inline uk-width-1-1">
                                            <span class="uk-form-icon" uk-icon="icon: location"></span>
                                            <input class="uk-input uk-width-1-1" type="text" required />
                                            <label class="ml-5"> Address </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-3 text-right">
                                        <div role="group" aria-label="Basic example" class="btn-group mt-2">
                                          <a href="#" class="cancel-btn"> <i class="fas fa-minus-circle mr-2"></i>
                                            Cancel </a>
                                          <a href="#" id="client" class="save-btn ml-2 mr-2">
                                            <i class="fas fa-save mr-2"></i> Save</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-university mr-2"></i> Bank Details</h5>
              </div>
            </div>
            <div class="view-ui mt-4 mb-2">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <select class="form-control" name="language" required>
                      <option>Kasikorn Bank</option>
                    </select>
                    <label>Bank</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <input type="text" name="company" class="form-control" value="414-122-2321" required />
                    <label>Account Number</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <input type="text" name="company" class="form-control" value="001" required />
                    <label>Branch Code</label>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                  <hr class="mt-2 mb-4" />
                  <button href="#" class="cancel-btn border-0">
                    <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                  </button>
                  <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                    <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-clipboard-list mr-2"></i> Documents </h5>
              </div>
            </div>
            <div class="view-ui mt-3 mb-2">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table data-table table-round table-hover">
                      <thead>
                        <tr>
                          <th scope="col" width="10%">Type</th>
                          <th scope="col" width="auto">Uploaded Date</th>
                          <th scope="col" width="20%">Expiry Date</th>
                          <th scope="col" width="20%">File name</th>
                          <th scope="col" width="20%">Verification</th>
                          <th scope="col" width="130px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-left pl-3 pr-3"> ID Card </td>
                          <td class="time text-left pl-3 pr-3" style="font-size: 12px">
                            <i class="fal fa-calendar mr-1 relative" style="top: 1px"></i> 14 AUG 2022
                            <span class="end-time ml-1" style="font-size: 11px"> 10:03</span>
                          </td>
                          <td class="time text-left pl-3 pr-3" style="font-size: 12px">
                            <i class="fal fa-calendar mr-1 relative" style="top: 1px"></i> 14 DEC 2022
                            <span class="end-time ml-1" style="font-size: 11px"> 10:03</span>
                          </td>
                          <td class="text-left pl-3 pr-3">18290380192312.pdf</td>
                          <td class="text-left pl-3 pr-3 verification"> 
                            <span class="approved hide mr-1 pl-2 pr-2">Verified</span>
                            <span class="cancelled mr-1 pl-2 pr-2">Reject</span>
                          </td>
                          <td class="text-left pl-3 pr-3 verification uk-text-right"> 
                              <div class="uk-inline" style="margin-top: -1px;" uk-lightbox><a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" class="view-btn only-icon mr-1" data-type="iframe"></a></div>
                              <a class="upload-btn" data-toggle="collapse" data-target="#edit_upload_1"></a>
                              <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                          </td>
                        </tr>
                        <!----- Edit File Upload ----->
                        <tr>
                            <td colspan="9" class="p-0 relative">
                              <div id="edit_upload_1" class="collapse">
                                <form method="post" enctype="multipart/form-data">
                                  <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important">
                                    <div class="js-upload uk-placeholder uk-text-center">
                                        <span class="uk-text-middle">Drag and drop file here</span>
                                        <div uk-form-custom>
                                            <input type="file" multiple>
                                            <span class="uk-link">Upload File</span>
                                        </div>
                                    </div>
                                    <progress id="js-progressbar" class="uk-progress in-table" value="0" max="100" hidden></progress>
                                  </div>
                                  <div class="text-right w-100">
                                    <div role="group" class="btn-group mt-2 mb-2 pr-2">
                                      <button type="button" class="cancel-btn"><i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel </button>
                                      <button type="submit" class="save-btn ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </td>
                        </tr>
                        <!----- !END - Edit File Upload ----->
                        <tr>
                          <td class="text-left pl-3 pr-3"> ID Card </td>
                          <td class="time text-left pl-3 pr-3" style="font-size: 12px">
                            <i class="fal fa-calendar mr-1 relative" style="top: 1px"></i> 15 AUG 2022
                            <span class="end-time ml-1" style="font-size: 11px"> 22:20</span>
                          </td>
                          <td class="time text-left pl-3 pr-3" style="font-size: 12px">
                            <i class="fal fa-calendar mr-1 relative" style="top: 1px"></i> 15 DEC 2022
                            <span class="end-time ml-1" style="font-size: 11px"> 22:20</span>
                          </td>
                          <td class="text-left pl-3 pr-3">18290380192312-2.pdf</td>
                          <td class="text-left pl-3 pr-3 verification"> 
                            <span class="approved mr-1 pl-2 pr-2">Verified</span>
                            <span class="cancelled hide mr-1 pl-2 pr-2">Reject</span>
                          </td>
                          <td class="text-left pl-3 pr-3 verification uk-text-right"> 
                              <div class="uk-inline" style="margin-top: -1px;" uk-lightbox><a href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" class="view-btn only-icon mr-1" data-type="iframe"></a></div>
                              <a class="upload-btn" data-toggle="collapse" data-target="#edit_upload_2"></a>
                              <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                          </td>
                        </tr>
                        <!----- Edit File Upload ----->
                        <tr>
                            <td colspan="9" class="p-0 relative">
                              <div id="edit_upload_2" class="collapse">
                                <form method="post" enctype="multipart/form-data">
                                  <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important">
                                    <div class="js-upload uk-placeholder uk-text-center">
                                        <span class="uk-text-middle">Drag and drop file here</span>
                                        <div uk-form-custom>
                                            <input type="file" multiple>
                                            <span class="uk-link">Upload File</span>
                                        </div>
                                    </div>
                                    <progress id="js-progressbar" class="uk-progress in-table" value="0" max="100" hidden></progress>
                                  </div>
                                  <div class="text-right w-100">
                                    <div role="group" class="btn-group mt-2 mb-2 pr-2">
                                      <button type="button" class="cancel-btn"><i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel </button>
                                      <button type="submit" class="save-btn ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </td>
                        </tr>
                        <!----- !END - Edit File Upload ----->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-info-circle mr-2"></i> Legal Agreement</h5>
              </div>
            </div>
            <div class="view-ui mt-4 mb-2">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-lg-12">
                      <ul uk-tab>
                        <li class="uk-active pl-0"><a href="#" class="no-bullet">Legal Agreement (English)</a></li>
                        <li><a href="#" class="no-bullet">Legal Agreement (Thai)</a></li>
                      </ul>
                      <ul class="uk-switcher uk-margin">
                        <li>
                          <div class="form-group">
                            <client-only>
                              <VueEditor />
                            </client-only>
                          </div>
                        </li>
                        <li>
                          <div class="form-group">
                            <client-only>
                              <VueEditor />
                            </client-only>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 pl-4 pr-1 pb-0">
                  <div class="row w-100 bg-lightgray border-radius-default pb-0 mb-0">
                    <div class="col-md-2 mb-0 pb-0">
                      <div class="p-3 pb-0 mb-0">
                        <span class="uk-text-uppercase"> Signed date </span>
                        <p class="uk-text-uppercase mt-0 pt-2 pb-0 mb-0">25 Aug 2022</p>
                      </div>
                    </div>
                    <div class="col-md-3 mb-0 pb-0">
                      <div class="p-3 pb-0 mb-0">
                        <span class="uk-text-uppercase"> Signed From </span>
                        <p class="uk-text-uppercase mt-0 pt-2 pb-0 mb-0">Macbook Pro 2021 - M1 | Safari</p>
                      </div>
                    </div>
                    <div class="col-md-2 mb-0 pb-0">
                      <div class="p-3 pb-0 mb-0">
                        <span class="uk-text-uppercase"> IP Address </span>
                        <p class="uk-text-uppercase mt-0 pt-2 pb-0 mb-0">171.50.49.132</p>
                      </div>
                    </div>
                    <div class="col-md-5 mb-0 pb-0 text-right">
                      <button href="#" id="client"
                        class="save-btn download-btn border-0 ml-2 mr-2 mt-4 mb-2 uk-position-right">
                        <i class="fas fa-arrow-to-bottom mr-2"></i> Download PDF
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-clipboard-list mr-2"></i> Activity Logs</h5>
              </div>
            </div>
            <div class="view-ui mt-3 mb-2">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table data-table table-round table-hover">
                      <thead>
                        <tr>
                          <th scope="col" width="20%">Time</th>
                          <th scope="col" width="auto">Detail</th>
                          <th scope="col" width="20%">Page</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="time text-left pl-3 pr-3" style="font-size: 12px">
                            <i class="fal fa-calendar mr-1 relative" style="top: 1px"></i> 14 AUG 2022
                            <span class="end-time ml-1" style="font-size: 11px"> 10:03</span>
                          </td>
                          <td class="text-left pl-3 pr-3">Log out</td>
                          <td class="text-left pl-3 pr-3"><a href="#"> Tim Duncan </a></td>
                        </tr>
                        <tr>
                          <td class="time text-left pl-3 pr-3" style="font-size: 12px">
                            <i class="fal fa-calendar mr-1 relative" style="top: 1px"></i> 13 AUG 2022
                            <span class="end-time ml-1" style="font-size: 11px"> 10:03</span>
                          </td>
                          <td class="text-left pl-3 pr-3">Disable User</td>
                          <td class="text-left pl-3 pr-3">-</td>
                        </tr>
                        <tr>
                          <td class="time text-left pl-3 pr-3" style="font-size: 12px">
                            <i class="fal fa-calendar mr-1 relative" style="top: 1px"></i> 12 AUG 2022
                            <span class="end-time ml-1" style="font-size: 11px"> 10:03</span>
                          </td>
                          <td class="text-left pl-3 pr-3">Change Bodyguard Profile</td>
                          <td class="text-left pl-3 pr-3"><a href="#"> #BG21213 </a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-md-12 text-center">
                  <a href="#" class="btn loadmore-btn mt-4"> Load more </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CompanyProfileEdit from "./CompanyProfileEdit1.vue";

export default {
    components: {
        CompanyProfileEdit
    },
    data() {
        return {
            companyDetail: {
            },
            owners: [],
            id: this.$route.params.id,
            tablist: this.$helpers.serviceTabs(this.$route.params.id),
        }
    },
    mounted() {


        this.getCompanyDetail()

        $(function () {
            $('#servicearea').bsMultiSelect({
            containerClass: 'dashboardcode-bsmultiselect-large',
            })
        });

        $('#skill').bsMultiSelect({
            containerClass: 'dashboardcode-bsmultiselect-large',
        });

        // Upload
        var bar = document.getElementById('js-progressbar');

        UIkit.upload('.js-upload', {

            url: '',
            multiple: true,

            beforeSend: function () {
                console.log('beforeSend', arguments);
            },
            beforeAll: function () {
                console.log('beforeAll', arguments);
            },
            load: function () {
                console.log('load', arguments);
            },
            error: function () {
                console.log('error', arguments);
            },
            complete: function () {
                console.log('complete', arguments);
            },

            loadStart: function (e) {
                console.log('loadStart', arguments);

                bar.removeAttribute('hidden');
                bar.max = e.total;
                bar.value = e.loaded;
            },

            progress: function (e) {
                console.log('progress', arguments);

                bar.max = e.total;
                bar.value = e.loaded;
            },

            loadEnd: function (e) {
                console.log('loadEnd', arguments);

                bar.max = e.total;
                bar.value = e.loaded;
            },

            completeAll: function () {
                console.log('completeAll', arguments);

                setTimeout(function () {
                    bar.setAttribute('hidden', 'hidden');
                }, 1000);

                alert('Upload Completed');
            }

        });

    },
    methods: {
        isRequired(key) {
            if (this.requiredfileds.hasOwnProperty(key)) {
            return '*'
            } else {
            return ''
            }
        },
        getCompanyDetail() {
            const api = 'admin/agencies/' + this.$route.params.id
            axios
            .get(api)
            .then((response) => {
                if(!response.data.success) {
                this.$router.push('/404')
                }
                this.companyDetail = response.data.data
                this.owners = response.data.data.owners
                console.log(this.companyDetail)
                this.title()
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
        },
        title() {
            this.$route.meta.title = this.companyDetail.name_en
        },
        formatTHB(num) {
            return '฿' + Number(num).toLocaleString()
        },
        updateAddress(addressFormData) {
                console.log('parent component')
            console.log(addressFormData)
            
            let address = {
            lat: addressFormData.lat,
            lng: addressFormData.lng,
            country : addressFormData.country,
            address : addressFormData.address,
            province : addressFormData.province,
            sub_district : addressFormData.sub_district,
            district : addressFormData.district,
            zip_code : addressFormData.zip_code,
            company_country : addressFormData.country,
            company_address : addressFormData.address,
            company_province : addressFormData.province,
            company_sub_district : addressFormData.sub_district,
            company_district : addressFormData.district,
            company_zip_code : addressFormData.zip_code,
            website: addressFormData.website,

            contact_number: '663222222',
            contact_person: this.companyDetail.contact_person,
            name_en: this.companyDetail.name_en,
            registration_id: this.companyDetail.registration_id,
            tax_id: this.companyDetail.tax_id

            }

                const apiUrl = 'admin/agencies/' + this.$route.params.id + '/company'
                axios
            .post(apiUrl, address)
            .then((response) => {

                if(response.data.success) {
                    this.success()
                }else{
                    this.requiredfileds = response.data
                }
                
            })
            .catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error)
            })
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },

    },

}
</script>
