<template>
  <div>
    <!----- Header Title ----->
    <section class="row">
      <Header />
    </section>

    <!----- * TABS * ----->
    <TabList />
    
    <section class="tpui-container">
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
            <div class="grey-box p-3 pt-4">
                <Profile />

                <!---- Contact Person ---->
                <Contact />

                <Skill />

                <Achievement />

            </div>
        </div>
      </div>
    </section>
    
  </div>
</template>

<script>
import TabList from '../../Edit/TabList.vue';
import Profile from "./Profile.vue";
import Contact from "./Contact.vue";
import Skill from "./Skill.vue";
import Achievement from "./Achievement.vue";

export default {
    components: {
        TabList,
        Profile,
        Contact,
        Skill,
        Achievement
       
    },
    data() {
        return {
         
        }
    },
    mounted() {
    },
    methods: {
        

    },

}
</script>
