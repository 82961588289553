<script>

export default {
 
    agencyTypes() {
        return {
        'company': 'Company',
        'freelancer': 'Freelancer',
        }

    },
    dayMenus() {
        return {
            'all': 'All',
            'day': 'Day',
            'week': 'Week',
            'month': 'Month',
            'year': 'Year'
        }
    },
    statusMenus() {
        return {
        'all': 'All',
        'approved': 'Approved',
        'cancelled': 'Cancelled',
        'pending': 'Pending',
        'history': 'History'
        }
    },
    statuses() {

        return {
            client_rejected: {
                class: 'cancelled',
                text: 'rejected',
            },
            agency_rejected: {
                class: 'cancelled',
                text: 'rejected',
            },
            client_accepted: {
                class: 'approved',
                text: 'Accepted',
            },
            agency_accepted: {
                class: 'approved',
                text: 'Accepted',
            },
            on_bidding: {
                class: 'pending',
                text: 'Bidding',
            },
            started: {
                class: 'pending',
                text: 'Started',
            },
            failed: {
                class: 'cancelled',
                text: 'Failed',
            },
            finished: {
                class: 'approved',
                text: 'Finished',
            },
        }
    },

    pageLimit() {
        return 20
    },

    notification(success, message) {
        var notyf = new Notyf();

        setTimeout(function() {
            if(success){
                notyf.confirm(message);
            }else{
                notyf.alert(message);
            }
        }, 1000);
    },
    languages() {
        return [
            {code: 'en', text: 'English'},
            {code: 'th', text: 'Thai'},
            {code: 'ch', text: 'Chinese'}
        ]
    },

    services() {
        return {
            'bodyguard' : 'Bodyguard',
            'vip-service' : 'VIP Service',
            'private-investigator' : 'Private Investigator',
            'security-guard' : 'Security Guard',
            'education' : 'Education',
            'car-rental' : 'Car Rental',

        }
    },


    serviceTabs(id) {
       return [
            { name: 'General', checked: true, path: '/company/edit/' + id },
            { name: 'Bodyguard', checked: true, path: `/company/${id}/services/bodyguard`},
            { name: 'Security Guard', checked: true, path: `/company/${id}/services/security-guard` },
            { name: 'Private Investigator', checked: false, path: `/company/${id}/services/private-investigator`},
            { name: 'VIP Service', checked: false, path: `/company/${id}/services/vip`},
            { name: 'Education', checked: false, path: `/company/${id}/services/education`},
            { name: 'Car Rental', checked: false, path: `/company/${id}/services/car-rental`},
        ]
    }

}
</script>