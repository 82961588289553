<template>
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-4">
    <div class="card-block p-4">
      <router-link :to="link" name="card-list">
        <div class="card-ico mb-4">
          <img :src="icon" />
        </div>
        <div class="card-point">
          <h6 class="mb-3">{{ title }}</h6>
          <h4 class="mb-0">{{ value }}</h4>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ['link', 'title', 'value', 'icon'],
}
</script>