<template>
    <div class="p-5">
          <!----- Header Title ----->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h3 class="page-title mb-1 mt-0">
                        {{ this.$route.meta.title }}
                    </h3>
                </div>
                <div class="breadcrumb-container">
                    <ul class="uk-breadcrumb">
                        <li><span>Admin</span></li>
                        <li><a href="/reservation">Reservation</a></li>
                        <li><a href="">{{ this.$route.meta.title }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 relative"></div>
        </div>
      <!-- New Reservation -->
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
            <section class="grey-box register" v-if="!hasSeenCongrats">
                <div class="register-stepper">
                    <div class="step step-done"><span class="step-number">1</span></div>
                    <div class="step step-done"><span class="step-number">2</span></div>
                    <div class="step step-done"><span class="step-number">3</span></div>
                    <div class="step step-done"><span class="step-number">4</span></div>
                    <div class="step step-done"><span class="step-number">5</span></div>
                    <div class="step step-active"><span class="step-number">6</span></div>
                </div>

                <transition name="slide-fade">
                    <section>
                        <form class="form" action="#">
                            <div class="row">
                                <div class="col-md-8">
                                    <p class="text-uppercase text-sm mb-2">Payment</p>
                                    <hr class="horizontal dark mt-0" />
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="table-responsive mb-4">
                                                <table class="table data-table table-round table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Payment Type</th>
                                                            <th>Payment Method</th>
                                                            <th>Date</th>
                                                            <th>Amount</th>
                                                            <th>Payment Status</th>
                                                            <th>Created At</th>
                                                            <th>Created By</th> 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Card/Credit Card</td>
                                                            <td>VISA</td>
                                                            <td>09-04-2024</td>
                                                            <td>$471,43</td>
                                                            <td>Approved</td>
                                                            <td>29-02-2024 09:13</td>
                                                            <td>System - Support</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="text-uppercase text-sm mb-2">Process payment</p>
                                    <hr class="horizontal dark mt-0" />
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" value="$150" readonly/>
                                                <label>Amount</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" value="Payment for Reservation #54" readonly/>
                                                <label>Description</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group mb-3">
                                                <label class="form_radio float-left mr-3" style="height: 10px;">
                                                    <input type="radio" name="paymenttypeopt" id="paymentType_offline" v-model="radioButton" value="offline" />
                                                    <span class="design"></span>
                                                    <span class="text">Offline</span>
                                                </label>
                                                <label class="form_radio" style="height: 10px;margin-left: 20px!important;">
                                                    <input type="radio" name="paymenttypeopt" id="paymentType_online" v-model="radioButton" value="online" />
                                                    <span class="design"></span>
                                                    <span class="text">Online</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- OFFLINE PAYMENT -->
                                    <div class="row" id="offline_box">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <select class="form-control">
                                                    <option></option>
                                                    <option>Cash</option>
                                                    <option>Credit Card </option>
                                                    <option>Bank Transfer</option>
                                                    <option>Maestro</option>
                                                </select>
                                                <label>Payment Type</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6" hidden>
                                            <div class="form-group">
                                                <input type="text" class="form-control" required/>
                                                <label>Payment Method</label>
                                            </div>
                                        </div>
                                        <!-- Realtime -->
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="date" class="form-control" required/>
                                                <label>Date</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div id="credit_card_box">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <select class="form-control">
                                                                <option>January</option>
                                                                <option>February</option>
                                                                <option>March</option>
                                                                <option>April</option>
                                                                <option>May</option>
                                                                <option>June</option>
                                                                <option>July</option>
                                                                <option>August</option>
                                                                <option>September</option>
                                                                <option>October</option>
                                                                <option>November</option>
                                                                <option>December</option>
                                                            </select>
                                                            <label>Exp. month</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <select class="form-control">
                                                                <option>2024</option>
                                                                <option>2025</option>
                                                                <option>2026</option>
                                                                <option>2027</option>
                                                                <option>2028</option>
                                                                <option>2029</option>
                                                                <option>2030</option>
                                                                <option>2031</option>
                                                                <option>2032</option>
                                                                <option>2033</option>
                                                                <option>2034</option>
                                                                <option>2035</option>
                                                                <option>2036</option>
                                                                <option>2037</option>
                                                            </select>
                                                            <label>Exp. year</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <select class="form-control">
                                                                <option>Visa</option>
                                                                <option>Master Card</option>
                                                                <option>Discover</option>
                                                            </select>
                                                            <label>Brand</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" required/>
                                                            <label>Last 4 digits</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" required/>
                                                <label>Amount</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <select type="text" class="form-control">
                                                    <option></option>
                                                    <option>Approved</option>   
                                                    <option>Denied</option>    
                                                </select>
                                                <label>Payment Status</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group mb-3">
                                                <div class="row w-100">
                                                    <div class="col-md-7">
                                                        <label >Send Confirmation Email</label>
                                                    </div>
                                                    <div class="col-md-5">
                                                        <label class="form_radio float-left mr-3" style="height: 20px;">
                                                            <input type="radio" name="sendemailOpt" id="send_yes" >
                                                            <span class="design"></span>
                                                            <span class="text">Yes</span>
                                                        </label>
                                                        <label class="form_radio" style="height: 20px;">
                                                            <input type="radio" name="sendemailOpt" id="send_no" >
                                                            <span class="design"></span>
                                                            <span class="text">No</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <button class="btn btn-sm btn-primary" style="width: 200px;">Save</button>
                                        </div>
                                    </div>

                                    <!-- ONLINE PAYMENT -->
                                    <!-- <div class="row mt-3" id="online_box" v-if="radioButton=='online'">
                                        <div class="col-md-12 mb-3">
                                            <div class="form-group mb-3">
                                                <label class="form_radio float-left mr-3" style="height: 10px;">
                                                    <input type="radio" name="paymenttypeopt" id="paymentType_offline" />
                                                    <span class="design"></span>
                                                    <span class="text">
                                                        <img src="/assets/img/pay_pal.png" style="width: 130px;" />
                                                    </span>
                                                </label>
                                                <label class="form_radio" style="height: 10px;margin-left: 20px!important;">
                                                    <input type="radio" name="paymenttypeopt" id="paymentType_online" />
                                                    <span class="design"></span>
                                                    <span class="text">
                                                        <img src="/assets/img/stripe_sca.png" style="height: 40px;" />
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="email" class="form-control" required/>
                                                <label>Email Address</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="date" class="form-control" required/>
                                                <label>Due Date</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <client-only>
                                                <VueEditor />
                                            </client-only>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <button class="btn btn-sm btn-primary" style="width:200px">Send Request</button>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="col-md-4">
                                    <p class="text-uppercase text-sm mb-2">Summary</p>
                                    <hr class="horizontal dark mt-0" />
                                    <table class="table table-bordered">
                                        <tr>
                                            <td><h6 class="text-sm mb-0 ">Vehicle</h6></td>
                                            <td class="text-right"><p class="text-sm mb-0">3x days</p></td>
                                            <td class="text-right"><p class="text-sm mb-0">$35.00</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">CDW</h6></td>
                                            <td colspan="2" class="text-right"><p class="text-sm mb-0">$8</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">Insurance</h6></td>
                                            <td colspan="2" class="text-right"><p class="text-sm mb-0">$11</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">Discount</h6></td>
                                            <td class="text-right"><p class="text-sm mb-0">s6xwOH</p></td>
                                            <td class="text-right"><p class="text-sm mb-0">$2</p></td>
                                        </tr>
                                        <tr>
                                            <td><h6 class="text-sm mb-0">TOTAL</h6></td>
                                            <td colspan="2" class="text-right"><h4 class="mr-0 mb-0">$35.00</h4></td>
                                        </tr>
                                    </table>
                                </div>
                                
                            </div>
                            
                            <div class="form-group cta-step mt-4">
                                <div class="cta prev">
                                    <p class="cta-color">
                                        <span class="link_wrap">
                                            <router-link :to="'/book/5'">
                                                <a class="btn prev-btn link_text"><span class="arrow-prev" style="padding-right: 5px;"></span>Previous</a>
                                            </router-link>
                                        </span>
                                    </p>
                                </div>
                                <div class="cta">
                                    <p class="cta-color">
                                        <span class="text"></span>
                                        <span class="link_wrap">
                                            <input type="button" uk-toggle="target: #createmodal" value="Reserve" class="link_text btn next-btn" />
                                            
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </section>
                </transition>
            </section>
        </div>
      </div>
      
      <!-- Create success Modal -->
		<div id="createmodal" uk-modal>
			<div class="uk-modal-dialog uk-modal-body p-2 text-center">
				<button class="uk-modal-close-outside" type="button" uk-close></button>
				<div class="row align-items-center ">
                    <div class="col-md-12 mt-4">
                        <div class="register-icon mt-0 mb-0">
                            <img class="register-icon-item" src="../../../public/assets/img/success-goal.png" alt="vue logo">

                        </div>
                        <h2 class="congrats-title mt-0">Success!</h2>
                        <p class="congrats-subtitle mb-4">
                            You have successfully made a reservation!
                        </p>
                    </div>
				</div>
			</div>
		</div>
    </div>
    
  </template>
  
  
  
  <script>
    import router from '../../routes';

    export default {
        el: "#app",
        data: () => {
            return {
            steps: {},
            paymentopt: 'offline',
            step: 1,
            customer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            hasSeenCongrats: false,
            tempCustomer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            };
        },
    methods: {
        redirectReservation() { // 
            router.push({path: '/reservation/'})
        },

        prev() {
        this.step--;
        },

        next() {
        this.step++;
        },

        newBooking: function () {
            this.hasSeenCongrats = true;
        },
    }

};
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>