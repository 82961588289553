<template>
    <div class="row mt-4">
        <div class="col-md-6 text-left">
            <h5 class="mt-1 mb-2 uk-text-bold">
                <i class="fas fa-chart-bar mr-2"></i> Security Guard Price List
            </h5>
        </div>
 
        <div class="col-md-12 pt-3">
            <form @submit.prevent="updatePrice" ref="updatePriceForm">
                <div class="table-responsive">
                    <table class="table data-table table-round table-hover">
                        <thead>
                            <tr>
                                <th width="40%" class="pl-4 pr-4" colspan="2">Period</th>
                                <th width="10%"></th>
                                <th width="20%" class="pl-4 pr-4">Regular Security Guard</th>
                                <th width="20%" class="pl-4 pr-4">Supervisor Security Guard</th>
                                <th class="blankhead" width="auto"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(price, priceIndex) in priceLists" :key="priceIndex">
                                <td width="40%" class="bg-white pl-4 pr-0 pt-3">
                                    <div class="form-group select-dropdown mb-2 mt-0">
                                        <select name="period" v-model="priceLists[priceIndex].period" class="form-control uk-form-small pt-0" required>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </select>
                                    </div>
                                </td>
                                <td width="40%" class="bg-white pl-1 pr-4 pt-3">
                                    <div class="form-group select-dropdown mb-2 mt-0">
                                        <select name="range_period" v-model="priceLists[priceIndex].range_period" class="form-control uk-form-small pt-0" required>
                                            <option :value="'half-day'">Half Day</option>
                                            <option :value="'day'">Day</option>
                                            <option :value="'week'">Week</option>
                                            <option :value="'month'">Month</option>
                                        </select>
                                    </div>
                                </td>
                                <th width="10%"></th>

                                <td  class="bg-lightgreen pl-4 pr-4 pl-4 pr-4 pt-3 pb-2">
                                    <div class="form-group mb-2 mt-1">
                                        <input type="number" name="regular_security_guard" v-model="priceLists[priceIndex].regular_security_guard" class="form-control uk-form-small pt-0" required />
                                    </div>
                                </td>
                                <td class="bg-white pl-4 pr-4 pl-4 pr-4 pt-3 pb-2">
                                    <div class="form-group mb-2 mt-1">
                                        <input type="number" name="supervisor_security_guard" v-model="priceLists[priceIndex].supervisor_security_guard" class="form-control uk-form-small pt-0" required />
                                    </div>
                                </td>
                                <td class="bg-lightgreen text-right">
                                    <a href="#" @click="copySGPrice(priceIndex)" class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                                    <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="6">
                                    
                                    <a @click="addSGPrice" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center" >
                                        <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                                    </a>
                                    <button v-if="priceLists.length > 0" type="submit" id="client" class="save-btn border-0 ml-2 mr-2 float-right">
                                        <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    </div>
</template>


<script>

export default {
    props: {
        companyDetail: Object
    },
    mounted() {
        this.priceLists = [{period: 1, range_period: 'day', regular_security_guard: 100, supervisor_security_guard: 200}]
        // this.securityguardPrices()
    },
    data() {
        return {
            priceLists: [],
            formData: []
        }
    },
    methods: {
        securityguardPrices() {
            const api = 'admin/agencies/' + this.$route.params.id + '/prices'
            axios
                .get(api, {
                    params: {
                        page:1,
                        limit:30
                    }
                })
                .then((response) => {
                    this.priceLists = response.data.data
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
                
        },
        addSGPrice() {
            console.log('sfd')
            this.priceLists.push({
                range_period: 'month',
                staff_type: 'bodyguard'
            })
        },
        copySGPrice(index) {
            this.priceLists.push(this.priceLists[index])
        },
        updatePrice() {
            console.log(this.priceLists)
            // this.loading()
            const api = 'admin/agencies/' + this.$route.params.id + '/prices'
            axios
                .post(api, {prices: this.getFormData()})
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        getFormData() {
            // let form = this.$refs.updatePriceForm;
            // let formData = new FormData(form);

            return this.priceLists
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        }
    },

}
</script>