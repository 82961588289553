<template>
  <div>
    <!----- Header Title ----->
    <section>
      <div class="row content-header company">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
              {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative">
          <div class="pb-3 pl-2 pt-5 mt-3 uk-float-right">
            <div class="form-group search notify-btn mb-1">
                    <input type="text" name="keyword" placeholder="Search for..." class="form-control shadow-none border-0" />
                    <div class="input-group-prepend">
                      <span class="input-group-text border-0 bg-transparent">
                        <button type="submit" class="bg-transparent border-0"><i class="far fa-search"></i></button>
                      </span>
                    </div>
            </div>
          </div>
          <div class="pt-5 mt-3 uk-float-right">
            <div class="mb-3 text-right">
              <router-link :to="'/approval-account/rejected'">
                <a class="uk-button uk-button-light uk-text-danger pt-1"><i class="fad fa-external-link-alt mr-2"></i> Rejcted List</a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="tpui-container mb-5">
      <div class="row">
      <div class="col-lg-12">
        <div class="grey-box p-0">
          <form name="form_approval_account" method="post">
          <div class="table-responsive">
            <table class="table data-table table-round table-hover uk-animation-slide-bottom-small">
              <thead>
                <tr>
                  <!-- <th scope="col" class="blankhead" width="35px"></th> -->
                  <th scope="col" class="blankhead text-left pl-4" width="15%">Time</th>
                  <th scope="col" class="blankhead text-left" width="auto">Account</th>
                  <th scope="col" class="blankhead text-left" width="15%">Location</th>
                  <th scope="col" class="blankhead text-left" width="15%">Action</th>
                  <th scope="col" class="blankhead" width="50px"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, index) in customerList" :key="index">
                  <!-- <td>
                    <label class="form_radio m-0" style="top: 0;">
                      <input type="checkbox" :value="list.id" name="profile"><span class="design"></span>
                    </label>
                  </td> -->
                  <td class="time pl-4" style="font-size: 12px;">
                    <i class="fal fa-calendar mr-1 relative"></i> {{list.date}} <span class="end-time ml-1" style="font-size: 11px;"> {{list.time}} </span> 
                  </td>
                  <td> 
                    <img :src="list.avatar" class="uk-border-circle uk-margin-small-right" width="40px" height="40px"> 
                    <router-link :to="'customer/edit/' + list.id">{{ list.name }}</router-link>
                  </td>
                  <td> {{list.location}} </td>
                  <td> 
                    <div class="form-group select-dropdown">
                      <!-- 'accepted','not_clear','not_relevant','contain_contact_information','inappropriate_content','other' -->
                        <select class="form-control uk-form-small pt-0 uk-text-uppercase" v-model="list.status" name="approval">
                          <option value="accepted" class="uk-text-secondary"> Accept </option>
                          <option value="not_clear" class="uk-text-danger"> Not Clear </option>
                          <option value="not_relevant" class="uk-text-danger"> Not Relevant </option>
                          <option value="contain_contact_information" class="uk-text-danger"> Not Clear </option>
                          <option value="inappropriate_content" class="uk-text-danger"> Inappropriate Content </option>
                          <option value="other" class="uk-text-danger"> Other </option>

                        </select>
                    </div>
                  </td>
                  <td class="text-right pr-4">
                    <div class="action-btn d-flex justify-content-end">
                      <a href="#" class="del-btn" v-on:click="del_method(list.id, list.name)"><i class="fal fa-trash-alt"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </form>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 pt-1 uk-text-left">
        <button id="btn-perform-all" class="save-btn border-0 mt-4 uk-text-uppercase" v-on:click="performAll()">
          <i class="far fa-check-square uk-margin-small-right"></i> Perform All
        </button>
      </div>
      <div class="col-md-9 col-sm-12 uk-text-right">
        <div class="card-footer pr-0 pl-0" v-if="numberOfPage > 1">
          <jw-pagination
            :items="pages"
            @changePage="onChangePage"
            :pageSize="limit"
            :styles="customStyles"
            :labels="customLabels"
          ></jw-pagination>
        </div>
      </div>
    </div>
    </section>

  </div>
</template>

<script>
const customLabels = {
  first: '<<',
  last: '>>',
  previous: '<',
  next: '>',
}
const customStyles = {
  ul: {},
  li: {
    display: 'inline-block',

    margin: '5px',
  },
  a: {
    color: 'blue',
    margin: '5px',
  },
}
export default {
  mounted() {
    this.getApprovalAccountList(this.page, this.limit, '');

  },
  data() {
    return {
      params: {
        sort: { column: 'id', type: 'desc' },
      },
      keyword: '',
      firstload: false,
      customStyles: customStyles,
      customLabels: customLabels,
      numberOfPage: 0,
      pages: [],
      page: 1,
      limit: 20,
      customerType:'',
      sort: 'id',
      orderby: 'asc',
      customerList: [],
    }
  },
  methods: {
    performAll(){
      let approvalList = []
      for(var i = 0;i<this.customerList.length;i++){
        approvalList.push({id:this.customerList[i].id,status:this.customerList[i].status})
      }
      let payload = {"agencies":approvalList}
      console.log(payload)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/approval-account/update'
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .post(api, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${admintoken}`,
          },
        })
        .then((response) => {
          console.log(response.data)
          if (response.data.code == 200) {
            console.log(response.data)
            location.reload()
          } else {
            this.errorMessage = response.data.message
            this.$dialog.alert({
              title: 'Failed',
              message: this.errorMessage,
              showCancelButton: false,
              confirmButtonText: 'OK',
            })
          }
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    del_method: function (id, name) {
      UIkit.modal.confirm('Do you want to delete user: ' + name + " ? ").then(function () {
        console.log('Confirmed.')
        let approvalList = []
        approvalList.push({id:id,status:'other'})
        let payload = {"agencies":approvalList}
        console.log(payload)
        const api = 'https://dev-api.trueprotection.co.th/api/admin/approval-account/update'
        const admintoken = localStorage.getItem('auth_token_default')
        axios
          .post(api, payload, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${admintoken}`,
            },
          })
          .then((response) => {
            console.log(response.data)
            if (response.data.code == 200) {
              console.log(response.data)
              location.reload()
            } else {
              this.errorMessage = response.data.message
              this.$dialog.alert({
                title: 'Failed',
                message: this.errorMessage,
                showCancelButton: false,
                confirmButtonText: 'OK',
              })
            }
          })
          .catch((error) => {
            this.errorMessage = error.message
            console.error('There was an error!', error)
          })
      }, function () {
        console.log('Rejected.')
      });
    },
    filterType(type){
        this.customerType = type
        this.getApprovalAccountList(this.page, this.limit,this.keyword)
    },
    onChangePage(pageOfItems) {
      if (typeof pageOfItems !== undefined && pageOfItems !== null) {
        this.getApprovalAccountList(pageOfItems[0], this.limit,this.keyword)
      }
    },
    getApprovalAccountList(page, limit, keyword) {
      this.page = page;
      let pagevalue = '' + page;
      if (pagevalue == 'undefined') {
        return
      }
      const api =
        'https://dev-api.trueprotection.co.th/api/admin/approval-account?page='+page+'&limit='+limit
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.customerList = []
          this.customerList = response.data.data.records
          for(var i = 0; i< this.customerList.length;i++){
            if(this.customerList[i].status == null){
              this.customerList[i].status = 'accepted';
            }
          }
          if (this.firstload == false) {
            this.firstload = true
            this.numberOfPage = response.data.data.total_pages

            var counter = 1
            var pagenumber = 1
            this.pages = []
            for (var i = 0; i < this.numberOfPage * this.limit; i++) {
              if (counter - 1 == this.limit) {
                counter = 1
                pagenumber++
              } else {
                counter++
                this.pages.push(pagenumber)
              }
            }
          }
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    sortcol(sort) {
      this.params.sort = sort
      this.sort = sort.column
      this.orderby = sort.type
      this.getApprovalAccountList(this.page, this.limit,this.keyword)
      console.log(sort.column, sort.type)
    },
    encode(id) {
      return btoa(id)
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    phoneformat: function (value) {
      if (!value) return ''
      value = value.toString()
      var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      return value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
  }
}
</script>