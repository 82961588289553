<template>
    <div class="services">
        <!----- Header Title ----->
        <section class="row">
            <Header />
        </section>

        <!----- * TABS * ----->
        <TabList />

        <section class="tpui-container">
            <div class="row align-items-center mb-4">
                <div class="col-lg-12">
                    <div class="grey-box p-3">


                        <div class="uk-margin-large-bottom">
                            <div class="uk-form-width-medium uk-float-right">
                                <div class="form-group select-dropdown mb-2 mt-0">
                                    <select name="filter" class="form-control uk-form-small pt-0" required="required">
                                        <option value="Transportations">Transportations</option>
                                        <option value="Accomodation">Accomodation</option>
                                        <option value="Events">Events</option>
                                        <option value="Activities">Activities</option>
                                        <option value="Staffs">Staffs</option>
                                        <option value="Other">Other</option>
                                        <option value="Bundles">Bundles</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-12">
                                <h6 class="uk-text-uppercase pl-1 uk-text-bold">
                                    <i class="fas fa-chart-bar uk-margin-small-right"></i> Luxury Car Pricelist
                                </h6>
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table data-table table-round table-hover">
                                        <thead>
                                            <tr>
                                                <th width="auto"></th>
                                                <th width="15%" class="text-left">Brand</th>
                                                <th width="15%" class="text-left">Model</th>
                                                <th width="15%" class="text-left">Plate No.</th>
                                                <th width="15%" class="text-left">Seats</th>
                                                <th width="15%" class="text-left">Color</th>
                                                <th class="blankhead" width="auto"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center" style="width: fit-content">
                                                    <img src="https://cdn.pixabay.com/photo/2014/09/07/22/34/car-race-438467_960_720.jpg"
                                                        width="50px" height="50px" class="uk-border-circle" />
                                                </td>
                                                <td class="text-left">
                                                    <div class="form-group mb-0 pb-0">
                                                        <div class="form-group select-dropdown select-tbl-style">
                                                            <select name="brand"
                                                                class="form-control select-tbl-style pt-0"
                                                                required="required">
                                                                <option value="Chevrolet">Chevrolet</option>
                                                                <option value="Toyota">Toyota</option>
                                                                <option value="Benz">Benz</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="text-left">
                                                    <input type="text" class="form-control input-tbl-style"
                                                        value="South Affrica" />
                                                </td>
                                                <td class="text-left">
                                                    <input type="text" class="form-control input-tbl-style"
                                                        value="CEO" />
                                                </td>
                                                <td class="text-left">
                                                    <div
                                                        class="form-group select-dropdown uk-inline uk-float-left select-tbl-style">
                                                        <select required="required" class="form-control pt-0 mr-2"
                                                            style="font-size: 12px; min-width: 150px">
                                                            <option value="40%">40%</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td class="text-left">
                                                    <div
                                                        class="form-group select-dropdown uk-inline uk-float-left select-tbl-style">
                                                        <select required="required" class="form-control pt-0 mr-2"
                                                            style="font-size: 12px; min-width: 150px">
                                                            <option value="Yes">Yes</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    <a class="edit-btn pl-2 pr-2 uk-inline" data-toggle="collapse"
                                                        data-target="#accordion_luxury_car_price_list_1"><i
                                                            class="fal fa-edit"></i></a>
                                                    <a href="#" class="del-btn uk-inline"><i
                                                            class="fal fa-trash-alt"></i></a>
                                                </td>
                                            </tr>
                                            <tr class="accordion-wrapper">
                                                <td colspan="7" class="uk-padding-remove uk-margin-remove">
                                                    <div id="accordion_luxury_car_price_list_1" class="collapse show">
                                                        <form>
                                                            <div class="row uk-padding-small uk-padding-remove-bottom">
                                                                <div class="col-md-6 col-sm-12">
                                                                    <div class="form-group">
                                                                        <textarea rows="10" name="desc_en" id="desc_en"
                                                                            required="required"
                                                                            class="form-control"></textarea><label
                                                                            for="desc_en"> Description in English
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-sm-12">
                                                                    <div class="form-group">
                                                                        <textarea rows="10" name="desc_th" id="desc_th"
                                                                            required="required"
                                                                            class="form-control"></textarea><label
                                                                            for="desc_th"> Description in Thai </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row uk-padding-small">
                                                                <div class="col-md-6 col-sm-12">
                                                                    <dt class="uk-text-uppercase  uk-padding-small-top">
                                                                        Gallery <span
                                                                            class="uk-text-small uk-text-muted">(Max 8
                                                                            pictures)</span>
                                                                    </dt>
                                                                    <div
                                                                        class="gallery-img position-relative text-left uk-margin-bottom">
                                                                        <div class="img-wrapper">
                                                                            <img src="https://cdn.pixabay.com/photo/2014/09/07/22/34/car-race-438467_960_720.jpg"
                                                                                alt="Vehicle Image" /><span
                                                                                class="edit-btn"></span>
                                                                        </div>
                                                                        <span class="delete-btn"></span>
                                                                    </div>
                                                                    <div
                                                                        class="gallery-img position-relative text-left uk-margin-bottom">
                                                                        <div class="img-wrapper">
                                                                            <img src="https://cdn.pixabay.com/photo/2014/09/07/22/34/car-race-438467_960_720.jpg"
                                                                                alt="Vehicle Image" /><span
                                                                                class="edit-btn"></span>
                                                                        </div>
                                                                        <span class="delete-btn"></span>
                                                                    </div>
                                                                    <div
                                                                        class="gallery-img position-relative text-left uk-margin-bottom">
                                                                        <div class="img-wrapper">
                                                                            <img src="https://cdn.pixabay.com/photo/2014/09/07/22/34/car-race-438467_960_720.jpg"
                                                                                alt="Vehicle Image" /><span
                                                                                class="edit-btn"></span>
                                                                        </div>
                                                                        <span class="delete-btn"></span>
                                                                    </div>
                                                                    <div
                                                                        class="gallery-img position-relative text-left uk-margin-bottom">
                                                                        <div class="img-wrapper">
                                                                            <img src="https://cdn.pixabay.com/photo/2014/09/07/22/34/car-race-438467_960_720.jpg"
                                                                                alt="Vehicle Image" /><span
                                                                                class="edit-btn"></span>
                                                                        </div>
                                                                        <span class="delete-btn"></span>
                                                                    </div>
                                                                    <div
                                                                        class="gallery-img position-relative text-center">
                                                                        <div class="img-wrapper"
                                                                            style="border: 1px solid rgb(19, 99, 248)">
                                                                            <span class="edit-btn"
                                                                                style="height: 65%; background: none"><input
                                                                                    name="images[]" type="file"
                                                                                    id="file" class="file-upload" /><i
                                                                                    class="fal fa-plus upload-button"
                                                                                    aria-hidden="true"
                                                                                    style="color: rgb(19, 99, 248)"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-sm-12">
                                                                    <dt class="uk-text-uppercase">
                                                                        Price list
                                                                    </dt>
                                                                    <div class="">
                                                                        <div
                                                                            class="table-responsive uk-margin-small-top">
                                                                            <table
                                                                                class="table data-table table-round table-hover">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th width="30%"
                                                                                            class="text-left">Price</th>
                                                                                        <th width="40%"
                                                                                            class="text-left">Period
                                                                                        </th>
                                                                                        <th class="blankhead"
                                                                                            width="100px"></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="text-left">
                                                                                            <div
                                                                                                class="form-group mb-0 pb-0">
                                                                                                <input type="text"
                                                                                                    class="form-control input-tbl-style"
                                                                                                    value="฿4,150" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="text-left">
                                                                                            <div
                                                                                                class="form-group select-dropdown uk-inline select-tbl-style">
                                                                                                <select
                                                                                                    required="required"
                                                                                                    class="form-control pt-0 mr-2"
                                                                                                    style="font-size: 12px; min-width: 100px">
                                                                                                    <option value="1">1
                                                                                                    </option>
                                                                                                    <option value="2">2
                                                                                                    </option>
                                                                                                    <option value="3">3
                                                                                                    </option>
                                                                                                    <option value="4">4
                                                                                                    </option>
                                                                                                    <option value="5">5
                                                                                                    </option>
                                                                                                </select>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group select-dropdown uk-inline select-tbl-style">
                                                                                                <select
                                                                                                    required="required"
                                                                                                    class="form-control pt-0 mr-2"
                                                                                                    style="font-size: 12px; min-width: 150px">
                                                                                                    <option
                                                                                                        value="hour">
                                                                                                        hour</option>
                                                                                                    <option value="day">
                                                                                                        day</option>
                                                                                                    <option
                                                                                                        value="week">
                                                                                                        week</option>
                                                                                                    <option
                                                                                                        value="month">
                                                                                                        month</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="text-right">
                                                                                            <a class="edit-btn pl-2 pr-2 uk-inline"
                                                                                                href="#modal-edit-keyprofile"
                                                                                                uk-toggle><i
                                                                                                    class="fal fa-edit"></i></a>
                                                                                            <a href="#"
                                                                                                class="del-btn uk-inline"><i
                                                                                                    class="fal fa-trash-alt"></i></a>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="text-left">
                                                                                            <div
                                                                                                class="form-group mb-0 pb-0">
                                                                                                <input type="text"
                                                                                                    class="form-control input-tbl-style"
                                                                                                    value="฿4,150" />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="text-left">
                                                                                            <div
                                                                                                class="form-group select-dropdown uk-inline select-tbl-style">
                                                                                                <select
                                                                                                    required="required"
                                                                                                    class="form-control pt-0 mr-2"
                                                                                                    style="font-size: 12px; min-width: 100px">
                                                                                                    <option value="1">1
                                                                                                    </option>
                                                                                                    <option value="2">2
                                                                                                    </option>
                                                                                                    <option value="3">3
                                                                                                    </option>
                                                                                                    <option value="4">4
                                                                                                    </option>
                                                                                                    <option value="5">5
                                                                                                    </option>
                                                                                                </select>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group select-dropdown uk-inline select-tbl-style">
                                                                                                <select
                                                                                                    required="required"
                                                                                                    class="form-control pt-0 mr-2"
                                                                                                    style="font-size: 12px; min-width: 150px">
                                                                                                    <option
                                                                                                        value="hour">
                                                                                                        hour</option>
                                                                                                    <option value="day">
                                                                                                        day</option>
                                                                                                    <option
                                                                                                        value="week">
                                                                                                        week</option>
                                                                                                    <option
                                                                                                        value="month">
                                                                                                        month</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="text-right">
                                                                                            <a class="edit-btn pl-2 pr-2 uk-inline"
                                                                                                href="#modal-edit-keyprofile"
                                                                                                uk-toggle><i
                                                                                                    class="fal fa-edit"></i></a>
                                                                                            <a href="#"
                                                                                                class="del-btn uk-inline"><i
                                                                                                    class="fal fa-trash-alt"></i></a>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colspan="3">
                                                                                            <a href="#"
                                                                                                class="save-btn addmore-btn border-0 mt-0 text-center border-radius-default uk-text-primary uk-text-uppercase"
                                                                                                style="height: auto; width: auto !important; text-align: left !important">
                                                                                                <i class="fas fa-plus uk-margin-small-right"
                                                                                                    aria-hidden="true"></i>
                                                                                                Add
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                                                                    <hr class="mt-2 mb-4"><button href="#"
                                                                        class="cancel-btn border-0"><i
                                                                            aria-hidden="true"
                                                                            class="fas fa-minus-circle mr-2"></i> Cancel
                                                                    </button><button href="#" id="client"
                                                                        class="save-btn border-0 ml-2 mr-2"><i
                                                                            aria-hidden="true"
                                                                            class="fas fa-save mr-2"></i> Save </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="7">
                                                    <a href="#"
                                                        class="save-btn addmore-btn border-0 mt-0 text-center border-radius-default uk-text-primary uk-text-uppercase"
                                                        style="height: auto; width: auto !important; text-align: left !important">
                                                        <i class="fas fa-plus uk-margin-small-right"
                                                            aria-hidden="true"></i>
                                                        Add
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <div class="row uk-margin-top">
                            <div class="col-md-12 uk-margin-top">
                                <h6 class="uk-text-uppercase pl-1 uk-text-bold">
                                    <i class="fas fa-chart-bar uk-margin-small-right"></i> Police escort price list
                                </h6>
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table data-table table-round table-hover">
                                        <thead>
                                            <tr>
                                                <th width="35%" class="text-left">Type of vehicle</th>
                                                <th width="15%" class="text-left">Price</th>
                                                <th width="15%" class="text-left">Period </th>
                                                <th class="blankhead" width="100px"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-left">
                                                    <div class="form-group select-dropdown uk-inline select-tbl-style">
                                                        <select required="required" class="form-control pt-0 mr-2"
                                                            style="font-size: 12px; min-width: 23vw;">
                                                            <option value="Car">Car
                                                            </option>
                                                            <option value="Motorbike">Motorbike
                                                            </option>
                                                            <option value="Supercar">Supercar
                                                            </option>
                                                            <option value="EV Car">EV Car
                                                            </option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td class="text-left">
                                                    <div class="form-group mb-0 pb-0">
                                                        <input type="text" class="form-control input-tbl-style"
                                                            value="฿4,150" />
                                                    </div>
                                                </td>
                                                <td class="text-left">
                                                    <div
                                                        class="form-group select-dropdown uk-inline select-tbl-style uk-flex">
                                                        <select required="required" class="form-control pt-0 mr-2"
                                                            style="font-size: 12px; min-width: 150px">
                                                            <option value="hour">
                                                                hour</option>
                                                            <option value="5h">
                                                                5h</option>
                                                            <option value="12h">
                                                                12h</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td class="text-right">
                                                    <a class="edit-btn pl-2 pr-2 uk-inline"><i
                                                            class="fal fa-edit"></i></a>
                                                    <a href="#" class="del-btn uk-inline"><i
                                                            class="fal fa-trash-alt"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3">
                                                    <a href="#"
                                                        class="save-btn addmore-btn border-0 mt-0 text-center border-radius-default uk-text-primary uk-text-uppercase"
                                                        style="height: auto; width: auto !important; text-align: left !important">
                                                        <i class="fas fa-plus uk-margin-small-right"
                                                            aria-hidden="true"></i>
                                                        Add
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <div class="row uk-margin-top">
                            <div class="col-md-12 uk-margin-top">
                                <h6 class="uk-text-uppercase pl-1 uk-text-bold">
                                    <i class="fas fa-plane uk-margin-small-right"></i> Private Jet Service
                                </h6>
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table data-table table-round table-hover">
                                        <thead>
                                            <tr>
                                                <th width="35%" class="text-left"><span
                                                        class="uk-margin-large-left uk-flex">Model</span></th>
                                                <th width="15%" class="text-left">Maximum Passengers</th>
                                                <th width="15%" class="text-left">Status </th>
                                                <th class="blankhead" width="100px"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-left">
                                                    <img src="https://cdn.pixabay.com/photo/2014/09/07/22/34/car-race-438467_960_720.jpg"
                                                        width="50px" height="50px" class="uk-border-circle uk-inline" />
                                                    <div
                                                        class="form-group select-dropdown uk-inline select-tbl-style uk-margin-left">
                                                        <select required="required" class="form-control pt-0 mr-2"
                                                            style="font-size: 12px; min-width: 20vw;">
                                                            <option value="Legacy 650">Legacy 650 </option>
                                                            <option value="Gulfstream G-V">Gulfstream G-V </option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td class="text-left">
                                                    <div
                                                        class="form-group select-dropdown uk-inline select-tbl-style uk-flex">
                                                        <select required="required" class="form-control pt-0 mr-2"
                                                            style="font-size: 12px; min-width: 150px">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td class="text-left relative">
                                                    <label class="switch"><input type="checkbox" checked><span
                                                            class="slider round"></span></label>
                                                </td>
                                                <td class="text-right">
                                                    <a class="edit-btn pl-2 pr-2 uk-inline" data-toggle="collapse"
                                                        data-target="#accordion_private_jet_price_list_1"><i
                                                            class="fal fa-edit"></i></a>
                                                    <a href="#" class="del-btn uk-inline"><i
                                                            class="fal fa-trash-alt"></i></a>
                                                </td>
                                            </tr>
                                            <tr class="accordion-wrapper">
                                                <td colspan="4" class="uk-padding-remove uk-margin-remove">
                                                    <div id="accordion_luxury_car_price_list_1" class="collapse show">
                                                        <form>
                                                            <div class="row uk-padding-small uk-padding-remove-bottom">
                                                                <div class="col-md-6 col-sm-12">
                                                                    <div class="form-group">
                                                                        <textarea rows="10" name="desc_en" id="desc_en"
                                                                            required="required"
                                                                            class="form-control"></textarea><label
                                                                            for="desc_en"> Description in English
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-sm-12">
                                                                    <div class="form-group">
                                                                        <textarea rows="10" name="desc_th" id="desc_th"
                                                                            required="required"
                                                                            class="form-control"></textarea><label
                                                                            for="desc_th"> Description in Thai </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row uk-padding-small">
                                                                <div class="col-md-12 col-sm-12">
                                                                    <dt class="uk-text-uppercase  uk-padding-small-top">
                                                                        Gallery <span
                                                                            class="uk-text-small uk-text-muted">(Max 8
                                                                            pictures)</span>
                                                                    </dt>
                                                                    <div
                                                                        class="gallery-img position-relative text-left uk-margin-bottom">
                                                                        <div class="img-wrapper">
                                                                            <img src="https://cdn.pixabay.com/photo/2017/12/15/12/31/aircraft-3020976__340.jpg"
                                                                                alt="Vehicle Image" /><span
                                                                                class="edit-btn"></span>
                                                                        </div>
                                                                        <span class="delete-btn"></span>
                                                                    </div>
                                                                    <div
                                                                        class="gallery-img position-relative text-left uk-margin-bottom">
                                                                        <div class="img-wrapper">
                                                                            <img src="https://cdn.pixabay.com/photo/2017/12/15/12/31/aircraft-3020976__340.jpg"
                                                                                alt="Vehicle Image" /><span
                                                                                class="edit-btn"></span>
                                                                        </div>
                                                                        <span class="delete-btn"></span>
                                                                    </div>
                                                                    <div
                                                                        class="gallery-img position-relative text-left uk-margin-bottom">
                                                                        <div class="img-wrapper">
                                                                            <img src="https://cdn.pixabay.com/photo/2017/12/15/12/31/aircraft-3020976__340.jpg"
                                                                                alt="Vehicle Image" /><span
                                                                                class="edit-btn"></span>
                                                                        </div>
                                                                        <span class="delete-btn"></span>
                                                                    </div>
                                                                    <div
                                                                        class="gallery-img position-relative text-left uk-margin-bottom">
                                                                        <div class="img-wrapper">
                                                                            <img src="https://cdn.pixabay.com/photo/2017/12/15/12/31/aircraft-3020976__340.jpg"
                                                                                alt="Vehicle Image" /><span
                                                                                class="edit-btn"></span>
                                                                        </div>
                                                                        <span class="delete-btn"></span>
                                                                    </div>
                                                                    <div
                                                                        class="gallery-img position-relative text-center">
                                                                        <div class="img-wrapper"
                                                                            style="border: 1px solid rgb(19, 99, 248)">
                                                                            <span class="edit-btn"
                                                                                style="height: 65%; background: none"><input
                                                                                    name="images[]" type="file"
                                                                                    id="file" class="file-upload" /><i
                                                                                    class="fal fa-plus upload-button"
                                                                                    aria-hidden="true"
                                                                                    style="color: rgb(19, 99, 248)"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                                                                    <hr class="mt-2 mb-4"><button href="#"
                                                                        class="cancel-btn border-0"><i
                                                                            aria-hidden="true"
                                                                            class="fas fa-minus-circle mr-2"></i> Cancel
                                                                    </button><button href="#" id="client"
                                                                        class="save-btn border-0 ml-2 mr-2"><i
                                                                            aria-hidden="true"
                                                                            class="fas fa-save mr-2"></i> Save </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <a href="#"
                                                        class="save-btn addmore-btn border-0 mt-0 text-center border-radius-default uk-text-primary uk-text-uppercase"
                                                        style="height: auto; width: auto !important; text-align: left !important">
                                                        <i class="fas fa-plus uk-margin-small-right"
                                                            aria-hidden="true"></i>
                                                        Add
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <div class="row uk-margin-top">
                            <div class="col-md-12 uk-margin-top">
                                <h6 class="uk-text-uppercase pl-1 uk-text-bold">
                                    <i class="fas fa-ship uk-margin-small-right"></i> Yacht Service
                                </h6>
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table data-table table-round table-hover">
                                        <thead>
                                            <tr>
                                                <th width="20%" class="text-left"><span
                                                        class="uk-margin-large-left uk-flex">Name</span></th>
                                                <th width="15%" class="text-left">Type</th>
                                                <th width="15%" class="text-left">Size (FT) </th>
                                                <th width="15%" class="text-left">Capacity </th>
                                                <th width="15%" class="text-left">Staff ona board </th>
                                                <th width="15%" class="text-left">Status </th>
                                                <th class="blankhead" width="100px"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-left">
                                                    <img src="https://cdn.pixabay.com/photo/2014/09/07/22/34/car-race-438467_960_720.jpg"
                                                        width="50px" height="50px" class="uk-border-circle uk-inline" />
                                                    <div class="form-group uk-inline uk-margin-left mb-0 pb-0">
                                                        <input type="text" class="form-control input-tbl-style"
                                                            value="Capella" />
                                                    </div>
                                                </td>
                                                <td class="text-left">
                                                    <div class="form-group uk-inline mb-0 pb-0">
                                                        <input type="text" class="form-control input-tbl-style"
                                                            value="Motor Speedboat" />
                                                    </div>
                                                </td>
                                                <td class="text-left relative">
                                                    <div
                                                        class="form-group select-dropdown uk-inline select-tbl-style uk-flex">
                                                        <select required="required" class="form-control pt-0 mr-2"
                                                            style="font-size: 12px; min-width: 150px">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td class="text-left relative">
                                                    <div
                                                        class="form-group select-dropdown uk-inline select-tbl-style uk-flex">
                                                        <select required="required" class="form-control pt-0 mr-2"
                                                            style="font-size: 12px; min-width: 150px">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td class="text-left relative">
                                                    <div
                                                        class="form-group select-dropdown uk-inline select-tbl-style uk-flex">
                                                        <select required="required" class="form-control pt-0 mr-2"
                                                            style="font-size: 12px; min-width: 150px">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td class="relative">
                                                    <label class="switch"><input type="checkbox" checked><span
                                                            class="slider round"></span></label>
                                                </td>
                                                <td class="text-right">
                                                    <a class="edit-btn pl-2 pr-2 uk-inline" data-toggle="collapse"
                                                        data-target="#accordion_private_jet_price_list_1"><i
                                                            class="fal fa-edit"></i></a>
                                                    <a href="#" class="del-btn uk-inline"><i
                                                            class="fal fa-trash-alt"></i></a>
                                                </td>
                                            </tr>
                                            <tr class="accordion-wrapper">
                                                <td colspan="7" class="uk-padding-remove uk-margin-remove">
                                                    <div id="accordion_luxury_car_price_list_1" class="collapse show">
                                                        <form>
                                                            <div class="row uk-padding-small uk-padding-remove-bottom">
                                                                <div class="col-md-6 col-sm-12">
                                                                    <div class="form-group">
                                                                        <textarea rows="10" name="desc_en" id="desc_en"
                                                                            required="required"
                                                                            class="form-control"></textarea><label
                                                                            for="desc_en"> Description in English
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-sm-12">
                                                                    <div class="form-group">
                                                                        <textarea rows="10" name="desc_th" id="desc_th"
                                                                            required="required"
                                                                            class="form-control"></textarea><label
                                                                            for="desc_th"> Description in Thai </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row uk-padding-small">
                                                                <div class="col-md-12 col-sm-12">
                                                                    <dt class="uk-text-uppercase  uk-padding-small-top">
                                                                        Gallery <span
                                                                            class="uk-text-small uk-text-muted">(Max 8
                                                                            pictures)</span>
                                                                    </dt>
                                                                    <div
                                                                        class="gallery-img position-relative text-left uk-margin-bottom">
                                                                        <div class="img-wrapper">
                                                                            <img src="https://cdn.pixabay.com/photo/2017/12/15/12/31/aircraft-3020976__340.jpg"
                                                                                alt="Vehicle Image" /><span
                                                                                class="edit-btn"></span>
                                                                        </div>
                                                                        <span class="delete-btn"></span>
                                                                    </div>
                                                                    <div
                                                                        class="gallery-img position-relative text-left uk-margin-bottom">
                                                                        <div class="img-wrapper">
                                                                            <img src="https://cdn.pixabay.com/photo/2017/12/15/12/31/aircraft-3020976__340.jpg"
                                                                                alt="Vehicle Image" /><span
                                                                                class="edit-btn"></span>
                                                                        </div>
                                                                        <span class="delete-btn"></span>
                                                                    </div>
                                                                    <div
                                                                        class="gallery-img position-relative text-left uk-margin-bottom">
                                                                        <div class="img-wrapper">
                                                                            <img src="https://cdn.pixabay.com/photo/2017/12/15/12/31/aircraft-3020976__340.jpg"
                                                                                alt="Vehicle Image" /><span
                                                                                class="edit-btn"></span>
                                                                        </div>
                                                                        <span class="delete-btn"></span>
                                                                    </div>
                                                                    <div
                                                                        class="gallery-img position-relative text-left uk-margin-bottom">
                                                                        <div class="img-wrapper">
                                                                            <img src="https://cdn.pixabay.com/photo/2017/12/15/12/31/aircraft-3020976__340.jpg"
                                                                                alt="Vehicle Image" /><span
                                                                                class="edit-btn"></span>
                                                                        </div>
                                                                        <span class="delete-btn"></span>
                                                                    </div>
                                                                    <div
                                                                        class="gallery-img position-relative text-center">
                                                                        <div class="img-wrapper"
                                                                            style="border: 1px solid rgb(19, 99, 248)">
                                                                            <span class="edit-btn"
                                                                                style="height: 65%; background: none"><input
                                                                                    name="images[]" type="file"
                                                                                    id="file" class="file-upload" /><i
                                                                                    class="fal fa-plus upload-button"
                                                                                    aria-hidden="true"
                                                                                    style="color: rgb(19, 99, 248)"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 col-sm-12">
                                                                    <dt class="uk-text-uppercase uk-margin-top">
                                                                        Facilities
                                                                    </dt>
                                                                    <div class="uk-padding-small">
                                                                        <div class="uk-grid-small uk-child-width-1-5@s uk-flex-left uk-text-left"
                                                                            uk-grid>
                                                                            <div><label
                                                                                    class="form_radio uk-inline"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">Shaded
                                                                                        rear cockpit area
                                                                                    </span></label></div>
                                                                            <div><label
                                                                                    class="form_radio uk-inline"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">Kitchenette</span></label>
                                                                            </div>
                                                                            <div><label
                                                                                    class="form_radio uk-inline"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">snorkeling
                                                                                        gear</span></label></div>
                                                                            <div><label
                                                                                    class="form_radio uk-inline"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">kayak</span></label>
                                                                            </div>
                                                                            <div><label
                                                                                    class="form_radio uk-inline"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">paddle
                                                                                        boards</span></label></div>
                                                                            <div><label
                                                                                    class="form_radio uk-inline"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">Safety
                                                                                        Equipment</span></label></div>
                                                                            <div><label
                                                                                    class="form_radio uk-inline"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">Allow
                                                                                        Smoking</span></label></div>
                                                                            <div><label
                                                                                    class="form_radio uk-inline"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">Bluetooth
                                                                                        speakers</span></label></div>
                                                                            <div class="relative">
                                                                                <label
                                                                                    class="form_radio uk-inline uk-position-relative uk-float-left">
                                                                                    <input type="checkbox" value=""
                                                                                        name="">
                                                                                    <span class="design"></span>
                                                                                </label>
                                                                                <div
                                                                                    class="form-group uk-inline uk-float-left uk-margin-small-top">
                                                                                    <input type="text"
                                                                                        class="form-control uk-form-width-medium uk-form-small uk-padding-remove-top"
                                                                                        placeholder="Other" value="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-sm-12">
                                                                    <dt class="uk-text-uppercase uk-margin-top">
                                                                        Staff
                                                                    </dt>
                                                                    <div class="uk-padding-small">
                                                                        <div class="uk-grid-small uk-child-width-1-2@s uk-flex-left uk-text-left"
                                                                            uk-grid>
                                                                            <div><label
                                                                                    class="form_radio uk-inline"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">Captain</span></label>
                                                                            </div>
                                                                            <div><label
                                                                                    class="form_radio uk-inline"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">Deckhand</span></label>
                                                                            </div>
                                                                            <div><label
                                                                                    class="form_radio uk-inline"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">Hostress</span></label>
                                                                            </div>
                                                                            <div class="relative">
                                                                                <label
                                                                                    class="form_radio uk-inline uk-position-relative uk-float-left">
                                                                                    <input type="checkbox" value=""
                                                                                        name="">
                                                                                    <span class="design"></span>
                                                                                </label>
                                                                                <div
                                                                                    class="form-group uk-inline uk-float-left uk-margin-small-top">
                                                                                    <input type="text"
                                                                                        class="form-control uk-form-width-medium uk-form-small uk-padding-remove-top"
                                                                                        placeholder="Other" value="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-sm-12">
                                                                    <dt class="uk-text-uppercase uk-margin-top">
                                                                        Room
                                                                    </dt>
                                                                    <div class="uk-padding-small">
                                                                        <div class="uk-grid-small uk-child-width-1-2@s uk-flex-left uk-text-left"
                                                                            uk-grid>
                                                                            <div>
                                                                                <label class="form_radio"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">restroom</span></label>
                                                                                <div
                                                                                    class="form-group uk-inline uk-margin-top">
                                                                                    <input type="text"
                                                                                        class="form-control uk-form-width-medium uk-form-small uk-padding-remove-top"
                                                                                        placeholder="No. of room"
                                                                                        value="" />
                                                                                </div>
                                                                            </div>
                                                                            <div><label
                                                                                    class="form_radio uk-inline"><input
                                                                                        type="checkbox" value=""
                                                                                        name=""><span
                                                                                        class="design"></span><span
                                                                                        class="text uk-text-capitalize uk-text-middle uk-padding-remove-left"
                                                                                        style="font-size: 13px;">Bedroom</span></label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row uk-padding-small">
                                                                <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                                                                    <hr class="mt-2 mb-4"><button href="#"
                                                                        class="cancel-btn border-0"><i
                                                                            aria-hidden="true"
                                                                            class="fas fa-minus-circle mr-2"></i> Cancel
                                                                    </button><button href="#" id="client"
                                                                        class="save-btn border-0 ml-2 mr-2"><i
                                                                            aria-hidden="true"
                                                                            class="fas fa-save mr-2"></i> Save </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <a href="#"
                                                        class="save-btn addmore-btn border-0 mt-0 text-center border-radius-default uk-text-primary uk-text-uppercase"
                                                        style="height: auto; width: auto !important; text-align: left !important">
                                                        <i class="fas fa-plus uk-margin-small-right"
                                                            aria-hidden="true"></i>
                                                        Add
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <div class="row align-items-center mb-4 mt-4">
                            <div class="col-lg-12">
                                <div class="grey-box p-3 pt-4">
                                    <div class="row">
                                        <div class="col-md-6 text-left">
                                            <h6 class="uk-text-uppercase pl-1 uk-text-bold"><i class="fas fa-images uk-margin-small-right"></i> Gallery </h6>
                                        </div>
                                    </div>
                                    <div class="view-ui mt-1 mb-4">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="gallery-img position-relative text-center mb-2">
                                                    <div class="img-wrapper"><img
                                                            src="https://cdn.pixabay.com/photo/2012/10/25/23/57/soldier-62902__340.jpg"
                                                            alt="Border pill"><span class="edit-btn"><input type="file"
                                                                name="company-img" id="file" class="file-upload"><i
                                                                class="fal fa-pen upload-button" aria-hidden="true"></i>
                                                            Change </span></div><span class="delete-btn"></span>
                                                </div>
                                                <div class="gallery-img position-relative text-center mb-2">
                                                    <div class="img-wrapper"><img
                                                            src="https://cdn.pixabay.com/photo/2015/08/13/05/04/man-886601__340.jpg"
                                                            alt="Border pill"><span class="edit-btn"><input type="file"
                                                                name="company-img" id="file" class="file-upload"><i
                                                                class="fal fa-pen upload-button" aria-hidden="true"></i>
                                                            Change </span></div><span class="delete-btn"></span>
                                                </div>
                                                <div class="gallery-img position-relative text-center mb-2">
                                                    <div class="img-wrapper"><img
                                                            src="https://cdn.pixabay.com/photo/2017/04/13/14/14/gun-2227646__340.jpg"
                                                            alt="Border pill"><span class="edit-btn"><input type="file"
                                                                name="company-img" id="file" class="file-upload"><i
                                                                class="fal fa-pen upload-button" aria-hidden="true"></i>
                                                            Change </span></div><span class="delete-btn"></span>
                                                </div>
                                                <div class="gallery-img position-relative text-center mb-2">
                                                    <div class="img-wrapper"><img
                                                            src="https://cdn.pixabay.com/photo/2015/07/27/18/33/life-862973__340.jpg"
                                                            alt="Border pill"><span class="edit-btn"><input type="file"
                                                                name="company-img" id="file" class="file-upload"><i
                                                                class="fal fa-pen upload-button" aria-hidden="true"></i>
                                                            Change </span></div><span class="delete-btn"></span>
                                                </div>
                                                <div class="gallery-img mb-3 position-relative text-center"><div class="img-wrapper" style="border: 1px solid rgb(19, 99, 248);"><span class="edit-btn" style="height: 65%; background: none;"><input name="images[]" type="file" id="file" class="file-upload"><i class="fal fa-plus upload-button" aria-hidden="true" style="color: rgb(19, 99, 248);"></i></span></div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <MissionList />

                    </div>
                </div>
            </div>
        </section>
        
    </div>
</template>

<script>
import TabList from '../Edit/TabList.vue';
import MissionList from "./MissionList.vue";

export default {
    components: {
        TabList,
        MissionList,
    },
    data() {
        return {
            tablist: this.$helpers.serviceTabs(),
        }
    },
    mounted() {
        // this.getCarRental()
    },
    methods: {
        getCarRental() {
            const api = 'admin/agencies/' + this.$route.params.id
            axios
                .get(api)
                .then((response) => {
                    if (!response.data.success) {
                        this.$router.push('/404')
                    }
                    this.companyDetail = response.data.data
                    this.owners = response.data.data.owners
                    console.log(this.companyDetail)
                    this.title()
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
    },
}
</script>
