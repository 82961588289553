<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
                <li><span>Admin</span></li>
                <li><a href="/reservation">Reservation</a></li>
                <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative"></div>
      </div>
      <!-- Check Availability -->
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-calendar mr-2"></i> Date & Time</h5>
              </div>
            </div>
            <div class="view-ui mt-4 mb-2">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="date" name="company" class="form-control" value="" autocomplete="off" required>
                    <label>Pick-up Date</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="time" name="company" class="form-control" value="" autocomplete="off" required>
                    <label>Pick-up Time</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="date" name="company" class="form-control" value="" autocomplete="off" required>
                    <label>Return Date</label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <input type="time" name="company" class="form-control" value="" autocomplete="off" required>
                    <label>Return Time</label>
                  </div>
                </div>            
                <div class="clearfix"></div>
                <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                  <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                    <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                  </button>
                </div>
                <div class="clearfix"></div>
                <div class="col-md-12">
                  <div class="row pl-0 pr-0">
                    <div class="col-md-12 pt-3">
                      <h6 class="uk-text-uppercase pl-1 uk-text-bold">
                        <i class="fas fa-car mr-2"></i> Available Vehicles
                      </h6>
                    </div>

                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table data-table table-round table-hover">
                          <thead>
                            <tr>
                              <th width="50%" class="text-left">Cars</th>
                              <th width="40%" class="text-left"></th>
                              <th width="10%" class="blankhead"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="time" style="font-size: 12px">
                                <div>
                                    <img
                                        src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                        class="availablecars me-3"
                                        alt="user1"
                                    />
                                </div>
                              </td>
                              <td class="text-left">
                                <h5 class="mb-1 text-sm">Mercedez Benz E-Class</h5>
                                <ul class="car-list">
                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                </ul>
                              </td>
                              <td class="text-right">
                                <a class="btn btn-sm save-btn pl-2 pr-2"
                                  >Avail</a>
                              </td>
                            </tr>
                            <tr>
                              <td class="time" style="font-size: 12px">
                                <div>
                                    <img
                                        src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                        class="availablecars me-3"
                                        alt="user1"
                                    />
                                </div>
                              </td>
                              <td class="text-left">
                                <h5 class="mb-1 text-sm">Mercedez Benz E-Class</h5>
                                <ul class="car-list">
                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                </ul>
                              </td>
                              <td class="text-right">
                                <a class="btn btn-sm save-btn pl-2 pr-2"
                                  >Avail</a>
                              </td>
                            </tr>
                            <tr>
                              <td class="time" style="font-size: 12px">
                                <div>
                                    <img
                                        src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                        class="availablecars me-3"
                                        alt="user1"
                                    />
                                </div>
                              </td>
                              <td class="text-left">
                                <h5 class="mb-1 text-sm">Mercedez Benz E-Class</h5>
                                <ul class="car-list">
                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                </ul>
                              </td>
                              <td class="text-right">
                                <a class="btn btn-sm save-btn pl-2 pr-2"
                                  >Avail</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </template>
  
  
  
  <script>

  export default {
    
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>