<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-3">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-9 relative">
            <div class="grey-box">
                <div class="d-inline-block mt-5">
                    <DateRange style="float: none;" @dateChange="dateChange" />
                    <!-- <div class="mt-4">
                        <ul class="filter d-md-inline-flex d-none">
                            <li v-for="(day, dayIndex) in dayMenus" 
                                :key="dayIndex" 
                                :class="dayIndex | isActive(filters.day)" 
                                @click="filter(dayIndex)"
                            >
                            <a href="#">{{ day }}</a>
                            </li>
                        </ul>
                    </div> -->
                </div>
                <div class="d-inline-block pr-3">
                    <div class="text-right">
                    <router-link :to="'/vehicles/new'">
                        <a  class="outline-btn pt-1" style="padding: 5px 10px;">
                            <i class="far fa-file-pdf mr-2"></i> Download
                        </a>
                    </router-link>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table data-table table-round table-hover">
          <thead>
            <tr>
                <th>
                    License Plate
                    <Sort column="id" />
                </th>
                  <th>
                    Created At
                    <Sort column="name_en" />
                  </th>
                  <th>
                    Pick-up Date
                  </th>
                  <th>
                    Return Date
                  </th>
                  <th>
                    Total Days
                  </th>
                  <th>
                    Rack Rate
                  </th>
                  <th>
                    Total Price
                  </th>
                  <th>
                    Total Revenue
                  </th>
                <th>
                    Security Deposit
                </th>
                <th>
                    Odometer at Pickup
                </th>
                <th>
                    Odometer at Return
                </th>
                <th>
                    Fuel Charge Price
                </th>
            </tr>
          </thead>
          <tbody>
                <tr v-for="(list, index) in revenueList" :key="index">
                  <td>{{ list.license_plate }}</td>
                  <td>{{ list.created_at }}</td>
                  <td>{{ list.pick_up_date }}</td>
                  <td>{{ list.return_date }}</td>
                  <td>{{ list.total_days }}</td>
                  <td class="text-right">$ {{ list.rack_rate }}</td>
                  <td class="text-right">$ {{ list.total_price }}</td>
                  <td class="text-right">$ {{ list.total_revenue }}</td>
                  <td class="text-right">$ {{ list.security_deposit }}</td>
                  <td>{{ list.odometer_at_pickup }}</td>
                  <td>{{ list.odometer_at_return }}</td>
                  <td class="text-right">$ {{ list.fuel_charge_price }}</td>
                </tr>
              </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- PAGINATION -->
      <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
        <jw-pagination :items="pages" @changePage="onChangePage" :pageSize="limit" :styles="customStyles"
        :labels="customLabels"></jw-pagination>
      </div>
      <!-- END OF PAGINATION -->
    </div>
    
  </template>
  
  
  
  <script>
    export default {
      data() {
        return {
          revenueList: [],
        }
      },
      mounted() {
        this.getRevenueList()
      },
    
      methods: {
        getRevenueList() {
          this.revenueList = []
          const api = 'https://api.615autorental.com/api/admin/reports/revenue'
          const admintoken = localStorage.getItem('auth_token_default')
          const headers = {
            'Authorization': `Bearer ${admintoken}`,
            'Content-Type': 'application/json' // You can adjust content type as needed
          };
          axios
            .get(api, { headers }, {
              params: {
                page: 1,
                limit: 30
              }
            })
            .then((response) => {
              this.revenueList = response.data.data
            })
            .catch((error) => {
              console.error('There was an error!', error.message)
            })
        },
        encode(id) {
          return btoa(id)
        },
      }
    }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  .grey-box {
  float: right; 
  background-color: transparent; 
  box-shadow: none; 
  border: none; 
  padding: 10px 0
}
.d-inline-block {
    display: inline-block;
}
  </style>