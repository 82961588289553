<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
                <li><span>Admin</span></li>
                <li><a href="/customer">Customer</a></li>
                <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative"></div>
      </div>
      
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">
            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold"><i class="fas fa-user mr-2"></i> Customer Information</h5>
              </div>
            </div>
            <div class="view-ui mt-4 mb-2">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>First Name</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>Last Name</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="email" class="form-control" value="" autocomplete="off" required>
                    <label>Email Address</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>Phone Number</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>Street</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>City</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>State</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>Zip</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" value="" autocomplete="off" required>
                    <label>Country</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="date" class="form-control" value="" autocomplete="off" required>
                    <label>Birthday</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-6 text-left">
                    <h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-car mr-2"></i> Driver's License</h5>
                </div>
            </div>
            <div class="row pb-4 pt-3">
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" value="" autocomplete="off" required>
                        <label>DL Number</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="date" class="form-control" value="" autocomplete="off" required>
                        <label>Expiration Date</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="file" class="form-control" value="" autocomplete="off" required>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                </button>
            </div>
          </div>
        </div>

      </div>
    </div>
    
  </template>
  
  
  
  <script>

export default {
    props: {
        compartnerl: {
            rental_agreement_doc: null,
        }
    },
    data() {
        return {
            requiredfileds: {},
            legalUrl: ''
        }
    },
    mounted() {
        console.log(this.compartnerl)
        this.legalUrl = this.compartnerl.rental_agreement_doc? this.rental_agreement_doc : this.legalUrl
    },
    methods: {
        rentalAgreementDocUpload() {
            this.compartnerl.rental_agreement_doc = this.$refs.legalAgreementDoc.files[0]
            this.legalUrl = URL.createObjectURL(this.compartnerl.rental_agreement_doc)
        },
        updateLegal() {
            let form = this.$refs.updateLegal;
            let formData = new FormData(form);

            const apiUrl = 'admin/agencies/' + this.$route.params.id + '/legal_agreement'
            axios
            .post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
            )
            .then((response) => {
                if(response.data.success) {
                    this.success()
                }else{
                    this.requiredfileds = response.data
                }
            })
            .catch((error) => {
            this.errorMessage = error.message;
            console.error('There was an error!', error)
            })
        },
        downloadPdf(url, title = 'Legal Agreement') {
            axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            })
            .then((response) => {
                this.forceFileDownload(response, title)
            })
            .catch(() => console.log('error occured'))
        },
        forceFileDownload(response, title) {
            console.log(title)
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },
    }
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>