import Vue from 'vue'
import Sort from './global/Sort'
import ActiveBtn from './global/ActiveBtn'
import AddBtn from './global/AddBtn'
import DateRange from './global/DateRange'
import DayFilter from './global/DayFilter'
import Title from './layouts/Title'
import Breadcrumb from './layouts/Breadcrumb'
import Header from './layouts/Header'
import NavBar from './layouts/NavBar'
import Pagination from './layouts/Pagination'
import Search from './layouts/Search'
import SideBar from './layouts/SideBar'
import JwPagination from 'jw-vue-pagination';

import helpers from "./helpers/helper";

Vue.component('jw-pagination', JwPagination);
Vue.component('Sort', Sort)
Vue.component('ActiveBtn', ActiveBtn)
Vue.component('AddBtn', AddBtn)
Vue.component('DateRange', DateRange)
Vue.component('DayFilter', DayFilter)
Vue.component('Header', Header)
Vue.component('Title', Title)
Vue.component('Breadcrumb', Breadcrumb)
Vue.component('NavBar', NavBar)
Vue.component('Pagination', Pagination)
Vue.component('Search', Search)
Vue.component('SideBar', SideBar)


const plugins = {
    install() {
        Vue.helpers = helpers;
        Vue.prototype.$helpers = helpers;
    }
}
Vue.use(plugins);