var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"row p-2 mt-2"},[_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"col-md-12"},[_c('ScheduledList')],1)]),_c('div',{staticClass:"row p-2 mt-2"},[_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"col-md-12"},[_c('SessionPrices')],1)]),_vm._m(9)]),_vm._m(10),_vm._m(11),_vm._m(12)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-left"},[_c('h5',{staticClass:"mt-1 mb-2 uk-text-bold"},[_vm._v(" Schdule Your Class ")]),_c('hr')]),_c('div',{staticClass:"col-sm-3 mb-2"},[_c('div',{staticClass:"form-group mb-2"},[_c('select',{staticClass:"form-control",attrs:{"name":""}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("20+")]),_c('option',{attrs:{"value":""}},[_vm._v("30+")])]),_c('label',{attrs:{"for":""}},[_vm._v("Maximum number of attendees")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-md-12"},[_c('h6',{staticClass:"uk-text-uppercase"},[_vm._v("Schdule Course Date range")])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group mb-2"},[_c('input',{staticClass:"form-control",attrs:{"type":"date"}}),_c('label',{attrs:{"for":""}},[_vm._v("From")])])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group mb-2"},[_c('input',{staticClass:"form-control",attrs:{"type":"date"}}),_c('label',{attrs:{"for":""}},[_vm._v("Till")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12"},[_c('p',{staticClass:"uk-text-uppercase"},[_vm._v(" Schdule Course Date range ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group mb-2"},[_c('label',{staticClass:"form_radio"},[_c('input',{attrs:{"type":"radio","value":"Schedule","checked":""}}),_c('span',{staticClass:"design"}),_c('span',{staticClass:"text"},[_vm._v("Schedule")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"form-group mb-2"},[_c('label',{staticClass:"form_radio"},[_c('input',{attrs:{"type":"radio","value":"Schedule"}}),_c('span',{staticClass:"design"}),_c('span',{staticClass:"text"},[_vm._v("Flexible (Allow client to custom schdule from their ends)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('p',[_vm._v("Frequency")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group mb-4"},[_c('label',{staticClass:"form_radio"},[_c('input',{attrs:{"type":"radio","value":"Schedule","checked":""}}),_c('span',{staticClass:"design"}),_c('span',{staticClass:"text"},[_vm._v("Sessions")])])]),_c('div',{staticClass:"form-group mb-2"},[_c('select',{staticClass:"form-control",attrs:{"name":"","id":""}},[_c('option',{attrs:{"value":""}},[_vm._v("20")])]),_c('label',{attrs:{"for":""}},[_vm._v("No. of session")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group mb-2"},[_c('label',{staticClass:"form_radio"},[_c('input',{attrs:{"type":"radio","value":"Schedule"}}),_c('span',{staticClass:"design"}),_c('span',{staticClass:"text"},[_vm._v("period of")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 mt-3"},[_c('h5',{staticClass:"mt-1 mb-2 uk-text-bold"},[_vm._v("Prices")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-2 special-option"},[_c('div',{staticClass:"col-md-12"},[_c('p',{staticClass:"uk-text-uppercase uk-text-small"},[_vm._v("Special option")])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-md-9"},[_c('p',{staticClass:"uk-text-uppercase mb-0"},[_vm._v(" Remote / home training ")])]),_c('div',{staticClass:"col-sm-6 col-md-3 text-right"},[_c('label',{staticClass:"switch"},[_c('input',{attrs:{"type":"checkbox","checked":""}}),_c('span',{staticClass:"slider round"})])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group mt-2"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","value":"2342423"}})])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-md-9"},[_c('p',{staticClass:"uk-text-uppercase mb-0"},[_vm._v(" Rent Equipment ")])]),_c('div',{staticClass:"col-sm-6 col-md-3 text-right"},[_c('label',{staticClass:"switch"},[_c('input',{attrs:{"type":"checkbox"}}),_c('span',{staticClass:"slider round"})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group mt-2"},[_c('input',{staticClass:"form-control",attrs:{"type":"date"}}),_c('label',{attrs:{"for":""}},[_vm._v("From")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticStyle:{"display":"inline-flex","float":"left"}},[_vm._v("Activate")]),_c('label',{staticClass:"switch",staticStyle:{"position":"relative","top":"1px"}},[_c('input',{attrs:{"type":"checkbox","checked":""}}),_c('span',{staticClass:"slider round"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 text-right"},[_c('a',{staticClass:"cancel-btn",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-minus-circle mr-2"}),_vm._v(" Cancel ")]),_c('button',{staticClass:"save-btn ml-2 mr-2",attrs:{"type":"submit","id":"client"}},[_c('i',{staticClass:"fas fa-save mr-2",attrs:{"aria-hidden":"true"}}),_vm._v(" Save")])])
}]

export { render, staticRenderFns }