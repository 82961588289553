<template>
    <form action="">
        <ClassGallery />
        <div class="row">
            <div class="col-md-12">
                <ul class="uk-tab-bottom" uk-tab>
                    <li class="uk-active"><a href="#" class="no-bullet">English</a></li>
                    <li><a href="#" class="no-bullet">Thai</a></li>
                </ul>
                <ul class="uk-switcher uk-margin">
                    <li>
                        <ClassInformation />

                        <Location />

                        <ScheduleClass />
                    </li>
                    <li>
                        <div class="form-group">
                        afasdf
                        </div>
                    </li>
                </ul>
                    
            </div>
        </div>
    </form>
</template>

<script>
import ClassGallery from "./ClassGallery.vue";
import ClassInformation from "./ClassInformation.vue";
import Location from "./Location.vue";
import ScheduleClass from "./ScheduleClass.vue";
export default {
    components: {
        ClassGallery,
        ClassInformation,
        Location,
        ScheduleClass
    }
}
</script>


<style scoped>
.uk-active a {
    border-top: 0px !important;
    border-bottom: #1363f8 solid 2px !important;
}
.uk-tab-bottom li a {
    text-transform: uppercase !important;
}
</style>