<template>
    <div>

        <fieldset class="mt-5">
        <div class="view-ui mt-2">
            <div class="row">
            <div class="col-md-3 relative pb-5">
                <div class="profile-img position-relative text-center" style="margin-top: -55px">
                <span class="edit-btn">
                    <input type="file" name="avatar" ref="avatar" v-on:change="handleFileUpload()" class="file-upload"  />
                    <i class="fal fa-pen upload-button"></i> Change
                </span>
                <img v-if="profileUrl" :src="profileUrl" alt="Agent Profile" uk-cover />
                </div>
            </div>
            <div class="col-md-9">
                <div class="p-3">
                <div class="row">
                    <!-- <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" name="company" v-model="id" class="form-control" value="109980100078201"
                        autocomplete="on" required>
                        <label>ID Number</label>
                    </div>
                    </div> -->
                    
                    <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" name="first_name" v-model="agentFormData.first_name" class="form-control" autocomplete="on" required>
                        <label>First Name (In English)</label>
                    </div>
                    </div>
                    
                    <div class="col-md-4">
                    <div class="form-group">
                        <input type="text" name="last_name" v-model="agentFormData.last_name" class="form-control" autocomplete="on" required>
                        <label>Last Name (In English)</label>
                    </div>
                    </div>
                    
                    <div class="col-md-4">
                    <div class="form-group">
                        <input type="date" name="dob" v-model="agentFormData.dob" class="form-control" autocomplete="on" required>
                        <label>Date of Birth</label>
                    </div>
                    </div>

                    
                    <div class="col-md-4">
                    <div class="form-group select-dropdown">
                        <select class="form-control" name="gender" v-model="agentFormData.gender" autocomplete="on" required>
                        <option disabled selected>-- Select --</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        </select>
                        <label>Gender</label>
                    </div>
                    </div>
                    <div class="clearfix"></div>
                    
                    <div class="col-md-4">
                        <div class="form-group select-dropdown">
                            <select class="form-control" name="nationnality" v-model="agentFormData.nationnality" autocomplete="on" required>
                            <option disabled selected>-- Select --</option>
                            <option value="Thailand">Thailand</option>
                            <option value="USA">USA</option>
                            </select>
                            <label>Nationality</label>
                        </div>
                    </div>
                    
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" name="relationship_status" v-model="agentFormData.relationship_status" class="form-control" autocomplete="on" required>
                            <label>Relationship Status</label>
                        </div>
                    </div>
                    
                    <div class="col-md-12">
                        <div class="form-group select-hidden select-dropdown">
                            <select v-model="spoken_languages" @change="languageChange" id="spoken_languages" class="form-control" multiple>
                                <option v-for="(language, lIndex) in languages" :key="lIndex" :value="language"> {{language}} </option>
                            </select>
                            <label>Spoken Languages</label>
                            <input type="hidden" name="spoken_languages" v-model="agentFormData.spoken_languages">
                        </div>
                    </div>

                </div>
                </div>
            </div>

            <div class="clearfix"></div>
                <div class="col-md-12">
                    <div class="p-3">
                        <div class="row p-0 pb-0">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" name="body_type" v-model="agentFormData.body_type" class="form-control" autocomplete="on" required>
                                    <label>Body Type</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <select name="profile" v-model="agentFormData.profile" class="form-control" required>
                                    <option value="low">Low</option>
                                    <option value="high">high</option>
                                    </select>
                                    <label>Profile</label>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" name="height" v-model="agentFormData.height" class="form-control" autocomplete="on" required>
                                    <label>height</label>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" name="weight" v-model="agentFormData.weight" class="form-control" autocomplete="on" required>
                                    <label>weight</label>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" name="experience_year" v-model="agentFormData.experience_year" class="form-control" autocomplete="on" required>
                                    <label>Experience Year</label>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" name="children" v-model="agentFormData.children" class="form-control" autocomplete="on" required>
                                    <label>Children</label>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" name="passport" v-model="agentFormData.passport" class="form-control" autocomplete="on" required>
                                    <label>Passport</label>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="date" name="passport_issued_at" v-model="agentFormData.passport_issued_at" class="form-control" autocomplete="on" required>
                                    <label>Passport Issued At</label>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="date" placeholder="" name="passport_expired_at" v-model="agentFormData.passport_expired_at" class="form-control" autocomplete="on" required>
                                    <label>Passport Expired At</label>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" name="national_id_card" v-model="agentFormData.national_id_card" class="form-control" autocomplete="on" required>
                                    <label>National Id Card</label>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group">
                                    <select name="able_to_work" v-model="agentFormData.able_to_work" class="form-control" required>
                                    <option value="alone">Alone</option>
                                    <option value="group">Group</option>
                                    </select>
                                    <label>Able to Work</label>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label >Is Local</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label class="form_radio float-left mr-3">
                                                <input type="radio" name="is_local" id="is_local_yes" v-model="agentFormData.is_local" value="true"  >
                                                <span class="design"></span>
                                                <span class="text">Yes</span>
                                            </label>
                                            <label class="form_radio">
                                                <input type="radio" name="is_local" id="is_local_no" v-model="agentFormData.is_local" value="false" >
                                                <span class="design"></span>
                                                <span class="text">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label >Is Armed</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label class="form_radio float-left mr-3">
                                                <input type="radio" name="is_armed" id="is_armed_yes" v-model="agentFormData.is_armed" value="true"  >
                                                <span class="design"></span>
                                                <span class="text">Yes</span>
                                            </label>
                                            <label class="form_radio">
                                                <input type="radio" name="is_armed" id="is_armed_no" v-model="agentFormData.is_armed" value="false" >
                                                <span class="design"></span>
                                                <span class="text">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label >Is Armed</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label class="form_radio float-left mr-3">
                                                <input type="radio" name="is_trained" id="is_trained_yes" v-model="agentFormData.is_trained" value="true"  >
                                                <span class="design"></span>
                                                <span class="text">Yes</span>
                                            </label>
                                            <label class="form_radio">
                                                <input type="radio" name="is_trained" id="is_trained_no" v-model="agentFormData.is_trained" value="false" >
                                                <span class="design"></span>
                                                <span class="text">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label >Is Trained</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label class="form_radio float-left mr-3">
                                                <input type="radio" name="has_tattoo" id="has_tattoo_yes" v-model="agentFormData.has_tattoo" value="true"  >
                                                <span class="design"></span>
                                                <span class="text">Yes</span>
                                            </label>
                                            <label class="form_radio">
                                                <input type="radio" name="has_tattoo" id="has_tattoo_no" v-model="agentFormData.has_tattoo" value="false" >
                                                <span class="design"></span>
                                                <span class="text">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label >Is Trained</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label class="form_radio float-left mr-3">
                                                <input type="radio" name="is_soldier" id="is_soldier_yes" v-model="agentFormData.is_soldier" value="true"  >
                                                <span class="design"></span>
                                                <span class="text">Yes</span>
                                            </label>
                                            <label class="form_radio">
                                                <input type="radio" name="is_soldier" id="is_soldier_no" v-model="agentFormData.is_soldier" value="false" >
                                                <span class="design"></span>
                                                <span class="text">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label >Is Police</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label class="form_radio float-left mr-3">
                                                <input type="radio" name="is_police" id="is_police_yes" v-model="agentFormData.is_police" value="true"  >
                                                <span class="design"></span>
                                                <span class="text">Yes</span>
                                            </label>
                                            <label class="form_radio">
                                                <input type="radio" name="is_police" id="is_police_no" v-model="agentFormData.is_police" value="false" >
                                                <span class="design"></span>
                                                <span class="text">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label >Blood Sickness</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label class="form_radio float-left mr-3">
                                                <input type="radio" name="blood_sickness" id="blood_sickness_yes" v-model="agentFormData.blood_sickness" value="true"  >
                                                <span class="design"></span>
                                                <span class="text">Yes</span>
                                            </label>
                                            <label class="form_radio">
                                                <input type="radio" name="blood_sickness" id="blood_sickness_no" v-model="agentFormData.blood_sickness" value="false" >
                                                <span class="design"></span>
                                                <span class="text">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label >Has Car License</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label class="form_radio float-left mr-3">
                                                <input type="radio" name="has_car_license" id="has_car_license_yes" v-model="agentFormData.has_car_license" value="true"  >
                                                <span class="design"></span>
                                                <span class="text">Yes</span>
                                            </label>
                                            <label class="form_radio">
                                                <input type="radio" name="has_car_license" id="has_car_license_no" v-model="agentFormData.has_car_license" value="false" >
                                                <span class="design"></span>
                                                <span class="text">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label >Has Bike License</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label class="form_radio float-left mr-3">
                                                <input type="radio" name="has_bike_license" id="has_bike_license_yes" v-model="agentFormData.has_bike_license" value="true"  >
                                                <span class="design"></span>
                                                <span class="text">Yes</span>
                                            </label>
                                            <label class="form_radio">
                                                <input type="radio" name="has_bike_license" id="has_bike_license_no" v-model="agentFormData.has_bike_license" value="false" >
                                                <span class="design"></span>
                                                <span class="text">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mt-3 mb-3">
                                <hr />
                                <h6 class="uk-text-uppercase pl-1 mb-2 uk-text-bold">Service Area</h6>
                            </div>
                            <div class="col-md-12 mt-0 mb-0 pr-0">
                                <div class="form-group select-dropdown">
                                    <select name="type" v-model="agentFormData.type" id="servicearea" class="form-control">
                                    <option value="VIP Service">VIP Serviceeeee</option>
                                    <option value="Bodyguard Service">Bodyguard Service</option>
                                    <option value="Security Service">Security Service</option>
                                    <option value="Private Investigate">Private Investigate</option>
                                    <option value="Education">Education</option>
                                    <option value="Car Rental Service">Car Rental Service</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-12 mt-3 mb-3">
                                <hr />
                                <h6 class="uk-text-uppercase pl-1 uk-text-bold">Address</h6>
                            </div> 
                            <div class="col-md-12">
                                <Address></Address>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </fieldset>

        <fieldset class="contact-person">
        <div class="row pl-3 pr-1">

            <!---- Contact Person ---->

            <div class="col-md-12 mt-3 mb-1 pt-3">
            <h6 class="uk-text-uppercase pl-1 uk-text-bold">Contact</h6>
            </div>
            <div class="col-md-12 mb-2">
            <div class="row">
                <div class="col-md-3">
                <div class="form-group">
                    <input type="email" name="email" v-model="agentFormData.email" class="form-control" autocomplete="on" required>
                    <label>Email</label>
                </div>
                </div>
                <div class="col-md-3">
                <div class="form-group">
                    <input type="password" name="password" v-model="agentFormData.password" class="form-control" autocomplete="on" required>
                    <label>Password</label>
                </div>
                </div>
                <div class="col-md-3">
                <div class="form-group">
                    <input type="text" name="dial_code" v-model="agentFormData.dial_code" class="form-control" autocomplete="on" required>
                    <label>Dial Code</label>
                </div>
                </div>
                <div class="col-md-3">
                <div class="form-group">
                    <input type="text" name="phone" v-model="agentFormData.phone" class="form-control" autocomplete="on" required>
                    <label>Phone</label>
                </div>
                </div>
            </div>
            </div>

            <div class="col-md-12 mt-3 mb-1">
            <h6 class="uk-text-uppercase pl-1 uk-text-bold">Emergency Contact</h6>
            </div>

            <div class="col-md-12 mb-2">
            <div class="row">
                <div class="col-md-3">
                <div class="form-group">
                    <input type="text" name="emg_contact_name" v-model="agentFormData.emg_contact_name" class="form-control" autocomplete="on" required>
                    <label>Contact Person</label>
                </div>
                </div>
                <div class="col-md-3">
                <div class="form-group">
                    <input type="text" name="emg_contact_relationship" v-model="agentFormData.emg_contact_relationship" class="form-control" autocomplete="on" required>
                    <label>Relationship</label>
                </div>
                </div>
                <div class="col-md-3">
                <div class="form-group vue-tel-input-wrapper">
                    <template>
                    <vue-tel-input name="emg_contact_phone" v-model="agentFormData.emg_contact_phone"></vue-tel-input>
                    <label>Contact Number</label>
                    </template>
                </div>
                </div>
                <div class="col-md-3">
                <div class="form-group">
                    <input type="text" name="emg_contact_email" v-model="agentFormData.emg_contact_email" class="form-control" autocomplete="on" required>
                    <label> Email Address </label>
                </div>
                </div>
            </div>
            </div>
            
        </div>
        </fieldset>

        <!---- Skill ---->

        <!-- 
                <fieldset class="mt-1">
                  <div class="row pl-3 pr-1">
                    <div class="col-md-12 mt-4">
                      <h6 class="uk-text-uppercase pl-1 uk-text-bold">Skill</h6>
                    </div>
                    <div class="col-md-12 mb-2">
                      <div class="form-group  select-dropdown">
                        <select name="skill" id="skill" class="form-control" multiple="multiple" style="display: none;"
                          autocomplete="on" required>
                          <option value="">Karate</option>
                          <option value="">Sharpshooting</option>
                          <option value="">Krev Maga</option>
                          <option value="">Boxing</option>
                          <option value="">Drving Car</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12 text-right pt-1 mt-0 mb-4 pb-1">
                      <button href="#" class="cancel-btn border-0">
                        <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                      </button>
                      <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                        <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                      </button>
                    </div>
                  </div>
                </fieldset> 
              -->
    </div>
</template>


<script>
import 'babel-polyfill'; // es6 shim
import Address from "../GoogleMapAddress.vue";
export default {
  components: {
    Address
  },
  props: {
    agentDetail: Object
  },
  data() {
    return {
        languages: ['en', 'th', 'ch', 'se','ru' ],
        spoken_languages: [],
        agentFormData:{
            avatar: null,
            first_name: '',
            nick_name: '',
            last_name: '',
            spoken_languages: '',

            nationality: '',
            gender: '',
            dob: '',
            is_local: false,
            is_armed: false,
            is_trained: false,
            has_tattoo: false,
            is_soldier: false,
            is_police: false,
            blood_sickness: false,
            has_car_license: false,
            has_bike_license: false,

            able_to_work: '',
            relationship_status: '',
            body_type: '',

            profile: '',
            height: '',
            weight: '',

            experience_year: '',
            children: '',
            passport: '',
            passport_issued_at: '',
            passport_expired_at: '',
            national_id_card: '',


            password: '',
            dial_code: '',
            phone_number: '',
            pass_code: '',
            type: '',
            active: true,

            lat: 0,
            lng: 0,

            address: '',
            sub_district: '',
            district: '',
            province: '',
            country: '',
            zip_code: '',


            emg_contact_name: '',
            emg_contact_email: '',
            emg_contact_phone: '',
            emg_contact_relationship: '',
        },
        profileUrl: null,
    }
  },
  mounted() {
    
    $(function () {
    //   $("#spoken_languages").bsMultiSelect({
    //     containerClass: "dashboardcode-bsmultiselect",
    //   });
      $("#skill").bsMultiSelect({
        containerClass: "dashboardcode-bsmultiselect-large",
      });
    });

    if(this.agentDetail) {
        this.setFormData()
    }
  },
  methods: {
    handleFileUpload() {
      this.agentFormData.avatar = this.$refs.avatar.files[0]
      this.profileUrl = URL.createObjectURL(this.agentFormData.avatar)
    },
    setFormData() {
        this.profileUrl = this.agentDetail.avatar
        this.agentFormData = this.agentDetail
        if(this.agentFormData.spoken_languages != '' && this.agentFormData.spoken_languages != undefined) {
            this.spoken_languages = this.agentFormData.spoken_languages.split(',')
        }
    },
    languageChange() {
        this.agentFormData.spoken_languages = this.spoken_languages.join(',')
    }
  },
}
</script>

<style scoped>
  .contact-person {
    background-color: rgb(237 243 255) !important;
    border: dashed 2px rgb(173 199 250) !important
  }
</style>