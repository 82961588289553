<template>
    <div class="uk-float-left mt-4 date-range">
        <div class="uk-inline mr-3">
            <label> FROM </label>
        </div>
        <div class="uk-inline mr-3">
            <input type="date" v-model="start_date" @change="sdateChange" class="uk-input uk-text-uppercase"  style="height: 38px" />
        </div>
        <div class="uk-inline mr-3">
            <label> TO </label>
        </div>
        <div class="uk-inline">
            <input type="date" v-model="end_date" @change="edateChange" class="uk-input uk-text-uppercase" style="height: 38px" />
        </div>
    </div>
</template>

<script>

import moment from "moment";
export default {
    data() {
        return {
            start_date: null,
            end_date: null,
        }
    },
    mounted() {

    },
    methods: {

        sdateChange() {
            var sDate = moment(this.start_date)

            if(!this.end_date) {
                this.addNextDay()
            } else {
                var eDate = moment(this.end_date)
                if(sDate.isAfter(eDate)) {
                    this.addNextDay()
                }else{
                    this.setDateFormat()
                }
            }
        },
        edateChange() {
            var eDate = moment(this.end_date)

            if(!this.start_date) {
                this.addPreviousDay()
            } else {
                var sDate = moment(this.start_date)
                if(sDate.isAfter(eDate)) {
                    this.addPreviousDay()
                }else{
                    this.setDateFormat()
                }
            }
        },
        addNextDay(){
            this.end_date = moment(this.start_date).add(1, 'day').format('YYYY-MM-DD')
            this.setDateFormat()
        },
        addPreviousDay(){
            this.start_date = moment(this.end_date).subtract(1, 'day').format('YYYY-MM-DD')
            this.setDateFormat()
        },
        setDateFormat() {
            console.log(this.start_date)
            console.log(this.end_date)

            this.$emit('dateChange', {start_date: this.start_date, end_date: this.end_date})
        },
    }
}
</script>

<style scoped>
.date-range input {
    max-width: 160px;
    padding: 15px 20px !important
}
label {
    top: 0px;
    left: 0px;
    position: relative;
}
</style>