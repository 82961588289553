<template>
	<div>
		<Header />
		<section class="row">
			<div class="col-md-12 text-right relative uk-animation-slide-bottom-small">
        		<AddBtn :url="`add/staff`" />
				<Search></Search>
			</div>
		</section>
		<section class="tpui-container">
			<div class="row align-items-center mb-4">
				<div class="col-md-12">
					<div class="grey-box p-3 pt-4">
						<div class="row">
							<div class="col-md-6 text-left">
							<h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-user mr-2"></i> Admin Information </h5>
							</div>
							<div class="col-md-6 text-right">
								<div class="switches-container w-200 uk-position-right" style="margin-right: 10px;">
									<input type="radio" id="active" v-model="profileActive" v-bind:value="activeVal" @click="activeUser"  />
									<input type="radio" id="inactive" v-model="profileActive" v-bind:value="inactiveVal" @click="activeUser" />
									<label for="active" id="lbl_active"> Active </label>
									<label for="inactive" id="lbl_inactive"> Inactive </label>
									<div class="switch-wrapper">
										<div class="switch">
											<div id="lbl_active"> Active </div>
											<div id="lbl_inactive"> Inactive </div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="view-ui mt-2">
							<div class="row">
								<div class="col-md-3 relative pb-5">
									<div class="profile-img position-relative text-center">
											<span class="edit-btn">
												<input
												type="file"
												class="file-upload"
												id="file"
												ref="file"
												v-on:change="handleFileUpload()"
												/>
												<i class="fal fa-pen upload-button"></i> Change
											</span>
											<img :src="url ? url : formData.avatar" alt="Profile" uk-cover>
									</div>
									<div class="col-md-9">
									</div>
								</div>
								<div class="col-md-9">
									<div class="p-3">
										<form @submit.prevent="updateData">
											<div class="row p-0 pb-0 pt-2">
												<div class="col-md-4">
													<div class="form-group">
														<input type="text" v-model="formData.first_name" class="form-control" required>
														<label>First name</label>
													</div>
												</div>
												<div class="col-md-4">
													<div class="form-group">
														<input type="text" v-model="formData.last_name" class="form-control" required>
														<label>Last name</label>
													</div>
												</div>
												<div class="col-md-4">
													<div class="form-group">
														<select v-model="formData.gender" class="form-control" required>
															<option v-for="(genderOption, genderIndex) in genderOptions" :key="genderIndex" :value="genderOption.value">
																{{ genderOption.text }}
															</option>
														</select>
														<label>Gender</label>
													</div>
												</div>
												<div class="col-md-4">
													<div class="form-group">
														<input type="date" v-model="formData.dob" class="form-control" required>
														<label> Date of Birth</label>
													</div>
												</div>
												<div class="col-md-4">
													<div class="form-group">
														<input type="text" v-model="formData.nationality" class="form-control" required>
														<label>Nationality</label>
													</div>
												</div>
												<div class="col-md-4">
													<div class="form-group">
														<select v-model="formData.position" class="form-control" required>
															<option v-for="(position, positionIndex) in positions" :key="positionIndex" :value="position.value">
																{{ position.text }}
															</option>
														</select>
														<label>Position</label>
													</div>
												</div>
												<div class="col-md-4">
													<div class="form-group">
														<select v-model="formData.role_id" class="form-control" required>
															<option v-for="(role, roleIndex) in roles" :key="roleIndex" :value="role.value">
																{{ role.text }}
															</option>
														</select>
														<label>Role</label>
													</div>
												</div>
												
												<div class="clearfix"></div>
												<div class="col-md-12 mt-3 pl-3 pb-0 mb-0">
													<h6 class="uk-text-uppercase" style="color: #a5a5a5;"> Contact </h6>
												</div>
								
												<div class="col-md-4">
													<div class="form-group">
														<input type="text" v-model="formData.email" class="form-control" required>
														<label> Email </label>
													</div>
												</div>
												<div class="col-md-4">
													<div class="form-group vue-tel-input-wrapper">
														<template>
															<vue-tel-input v-model="formData.phone"></vue-tel-input>
															<label>Phone Number</label>
														</template>
													</div>
												</div>
												<div class="col-md-4 text-right pt-1">
													<div role="group" aria-label="Basic example" class="btn-group mt-2">
														<button href="#" class="cancel-btn border-0"><i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel </button>
														<!-- <input type="submit" id="client" class="save-btn border-0 ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save -->
														<button type="submit" id="client" class="save-btn border-0 ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>	
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

export default {
  	data() {

    	return {
			formData: {
				first_name: '',
				last_name: '',
				email: '',
				gender: '',
				dob: '',
				avatar: null,
				role_id: 0,
				password: '',
				active: true,
			},
			
			requiredFields: {},
			profileActive:'',
			activeVal:'active',
			inactiveVal:'inactive',

			url: null,
			genderOptions: [
				{ text: 'Female', value: 'female' },
				{ text: 'Male', value: 'male' },
			],
			positions: [
				{ text: 'CEO', value: 'CEO'},
				{ text: 'Project Manager', value: 'Project Manager'},
			],
			roles: [
				{ text: 'Admin', value: 1},
				{ text: 'Staff', value: 2},
			],
			errorMessage: [],
		}
  	},
	mounted() {
		this.getAdminData()
  	},
	methods: {
		
		getAdminData() {
			const api = 'admin/admins/' + this.$route.params.id
			axios
				.get(api)
				.then((response) => {
					this.formData = response.data.data
					this.formData.role_id = this.formData.role.id
					delete this.formData.created_at
					delete this.formData.role
					this.profileActive = this.formData.active? this.activeVal : this.inactiveVal
				})
				.catch((error) => {
					alert( error.message)
					console.error('There was an error!', error)
					this.$router.push('/404')

				})
		},
		updateData(){
			const apiUrl = 'admin/admins/' + this.$route.params.id
			console.log(this.formData)
  			
			var formData1 = new FormData();
			formData1.append('avatar', this.formData.avatar)
			formData1.append('email', this.formData.email)
			formData1.append('first_name', this.formData.first_name)
			formData1.append('last_name', this.formData.last_name)
			formData1.append('dob', this.formData.dob)
			formData1.append('nationality', this.formData.nationality)
			formData1.append('position', this.formData.position)
			formData1.append('phone', this.formData.phone)
			formData1.append('gender', this.formData.gender)
			formData1.append('role_id', this.formData.role_id)
			formData1.append('password', this.formData.password)

			axios.put(apiUrl, formData1,  {
				headers: {
            		'Content-Type': 'multipart/form-data',
				}
			})
			.then((response) => {
				let resData = response.data;
				if(resData.success) {
					this.$root.$emit('notyf_message', {type: 'success', text: 'Successfully Created!'});
					this.redirectList()
				}else{
					this.requiredFields = resData.data
				}
			})
			.catch((error) => {
				alert(error.message);
				console.error('There was an error!', error)
			})
		},
		activeUser() {
			this.formData.active = !this.formData.active
			this.profileActive = this.formData.active? this.activeVal : this.inactiveVal

			this.updateData();
		},
		/*
         *	Handles a change on the file upload
      	 */
		handleFileUpload() {
			this.formData.avatar = this.$refs.file.files[0]
			this.url = URL.createObjectURL(this.formData.avatar)
		},
		redirectList() {
			this.$router.push('/staff');
		},
		noti() {
			var notyf = new Notyf();

			setTimeout(function () {
				notyf.confirm('Created Admin');
			}, 1000)
		}
		
	},
}

</script>