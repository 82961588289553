<template>
	<div>
		<section class="row">
			<div class="col-md-6">
				<div>
					<h3 class="page-title mb-1">
						{{ this.$route.meta.title }}
					</h3>
				</div>
				<div class="breadcrumb-container">
					<ul class="uk-breadcrumb">
						<li><span>Admin</span></li>
						<li><a href="/customer">Customer</a></li>
						<li><a href="">{{ this.$route.meta.title }}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-md-6 relative">

			</div>
		</section>
		<section class="tpui-container">
			<div class="row align-items-center mb-4">
				<div class="col-lg-12">
					<div class="grey-box p-3 pt-4">
						<div class="row">
							<div class="col-md-6 text-left">
								<h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-user mr-2"></i> Customer Information </h5>
							</div>
						</div>
						<div class="view-ui mt-2">
							<div class="row">
								<div class="col-md-12">
									<div class="p-3">
										<div class="row p-0 pb-0">
											<div class="col-md-4">
												<div class="form-group">
													<input type="text" class="form-control" v-model="fname"  required>
													<label>First name</label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="text" class="form-control" v-model="lname"  required>
													<label>Last name</label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<select class="form-control" v-model="gender" required>
														<option value="male"> Male </option>
														<option value="female"> Female </option>
													</select>
													<label>Gender</label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="text" class="form-control" v-model="nationality" required>
													<label>Nationality</label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="date" class="form-control" v-model="birth_date" required>
													<label> Date of Birth</label>
												</div>
											</div>
											<div class="col-md-4">
													<div class="form-group">
														<select class="form-control" name="language" v-model="language" required>
															<option value="english"> English </option>
														</select>
														<label>Language</label>
													</div>
												</div>
											<div class="col-md-4">
												<div class="form-group vue-tel-input-wrapper">
													<template>
														<vue-tel-input v-model="phone_number"></vue-tel-input>
														<label>Phone Number</label>
													</template>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="email" class="form-control" v-model="email" required>
													<label> Email </label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="text" class="form-control" v-model="street" required>
													<label> Street </label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="text" class="form-control" v-model="state" required>
													<label> State </label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="text" class="form-control" v-model="city" required>
													<label> City </label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="text" class="form-control" v-model="zipcode" required>
													<label> Zip code </label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="text" class="form-control" v-model="country" required>
													<label> Country </label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12 text-right p-3">
									<div role="group" class="btn-group mt-2" style="margin-right: -10px;">
										<button href="#" class="cancel-btn border-0"> <i class="far fa-times-circle mr-2"></i> Cancel
										</button>
										<button @click="save()" id="client" class="save-btn border-0 ml-2 mr-2"> 
											<i class="fal fa-check-circle mr-2"></i> Save
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Add **uk-toggle="target: #createmodal" in div -->
		<!-- Create success Modal -->
		<div id="createmodal" uk-modal>
			<div class="uk-modal-dialog uk-modal-body p-2 text-center">
				<button class="uk-modal-close-outside" type="button" uk-close></button>
				<div class="row align-items-center mb-4">
				<div class="col-md-12 mt-4">
					<h4>Create customer successful!</h4>
				</div>
				</div>
				<a @click="closeModal" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
			</div>
		</div>

		<!-- Add **uk-toggle="target: #updatemodal" in div -->
		<!-- Create success Modal -->
		<div id="updatemodal" uk-modal>
			<div class="uk-modal-dialog uk-modal-body p-2 text-center">
				<button class="uk-modal-close-outside" type="button" uk-close></button>
				<div class="row align-items-center mb-4">
				<div class="col-md-12 mt-4">
					<h4>Update customer successful!</h4>
				</div>
				</div>
				<a @click="closeModal" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	components: {
	
	},
	data() {
          return {
			fname: '',
			lname: '',
			state: '',
			street: '',
			city: '',
			country: '',
			zipcode: '',
			gender: '',
			type: 'Main',
			email: '',
			avatar: '',
			phone_code: '01',
			phone_number: '',
			nationality: '',
			emergency_contact_name: 'NA',
			emergency_contact_number:'NA',
			language: 'english',
			contract_language: 'english',
			id_number: '0',
			issued_date: '2024-01-01',
			expiry_date: '2024-01-01',
			birth_date: '',
			notes: 'NA'
          }
      },
      mounted() {
		if(this.$route.params.id != "new"){
			this.getCustomerEdit()
		}
        
      },
      
      methods: {
		closeModal() {
		// Get the modal element by its ID and invoke UIKit's modal method to close it
			UIkit.modal('#updatemodal').hide();
			UIkit.modal('#createmodal').hide();
		},
		handleFileUpload() {
			this.file = this.$refs.file.files[0]
			this.avatar = URL.createObjectURL(this.file)
			this.uploadFile()

		},
		save(){
			

			// Assuming you have the updated customer data stored in a variable called updatedCustomerData
			const updatedCustomerData = {
				"fname": this.fname,
				"lname": this.lname,
				"state": this.state,
				"street": this.street,
				"city": this.city,
				"country":  this.country,
				"zipcode":  this.zipcode,
				"gender": this.gender,
				"type": this.type,
				"email": this.email,
				"avatar": this.avatar,
				"phone_code":this.phone_code,
				"phone_number": this.phone_number,
				"nationality":this.nationality,
				"emergency_contact_name": this.emergency_contact_name,
				"emergency_contact_number": this.emergency_contact_number,
				"language":this.language,
				"contract_language":this.contract_language,
				"id_number": this.id_number,
				"issued_date": this.issued_date,
				"expiry_date": this.expiry_date,
				"birth_date": this.birth_date,
				"notes": this.notes,
			};
			const admintoken = localStorage.getItem('auth_token_default')
			if(this.$route.params.id === "new"){
				axios.post(`https://api.615autorental.com/api/admin/customers`, updatedCustomerData, {
					headers: {
					'Authorization': `Bearer ${admintoken}`,
					'Content-Type': 'application/json'
					}
				})
				.then(response => {
				
					UIkit.modal('#createmodal').show();
					console.log('Customer updated successfully:', response.data);
					// handle success
				})
				.catch(error => {
					console.error('Error updating customer:', error);
					// handle error
				});
			}else{
				axios.put(`https://api.615autorental.com/api/admin/customers/${this.$route.params.id}`, updatedCustomerData, {
					headers: {
					'Authorization': `Bearer ${admintoken}`,
					'Content-Type': 'application/json'
					}
				})
				.then(response => {
					UIkit.modal('#updatemodal').show();
					console.log('Customer updated successfully:', response.data);
					// handle success
				})
				.catch(error => {
					console.error('Error updating customer:', error);
					// handle error
				});
			}
			
		},
		uploadFile() {
			let formData = new FormData();
			formData.append('file', this.file);
			formData.append('category','lisence')
			const admintoken = localStorage.getItem('auth_token_default')
			axios.post('https://api.615autorental.com/api/admin/upload', formData, {
				headers: {
				'Authorization': `Bearer ${admintoken}`,
				'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				console.log('File uploaded successfully:', response.data.path);
				this.avatar = response.data.path
				console.log(this.avatar)
				// handle response
			})
			.catch(error => {
				console.error('Error uploading file:', error);
				// handle error
			});
		},
        getCustomerEdit() {
        const apiUrl = 'https://api.615autorental.com/api/admin/customer/' + this.$route.params.id + '/details'
          const admintoken = localStorage.getItem('auth_token_default')
          const headers = {
            'Authorization': `Bearer ${admintoken}`,
          'Content-Type': 'application/json' // You can adjust content type as needed
        };
        axios
            .get(apiUrl,{ headers },{
              params: {
                page:1,
                limit: 30
              }
            })
            .then((response) => {
                this.customerEdit = response.data.data
				this.fname = response.data.data.fname;
				this.lname = response.data.data.lname;
				this.state = response.data.data.state;
				this.street = response.data.data.street;
				this.city = response.data.data.city;
				this.country = response.data.data.country;
				this.zipcode = response.data.data.zipcode;
				this.gender = response.data.data.gender;
				this.type = response.data.data.type;
				this.email = response.data.data.email;
				this.avatar = response.data.data.avatar;
				this.phone_code = response.data.data.phone_code;
				this.phone_number = response.data.data.phone_number;
				this.nationality = response.data.data.nationality;
				this.emergency_contact_name = response.data.data.emergency_contact_name;
				this.emergency_contact_number = response.data.data.emergency_contact_number;
				this.language = response.data.data.language;
				this.contract_language = response.data.data.contract_language;
				this.id_number = response.data.data.id_number;
				this.issued_date = response.data.data.issued_date;
				this.expiry_date = response.data.data.expiry_date;
				this.birth_date = response.data.data.birth_date;
				this.notes = response.data.data.notes;
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
        } ,
      }

}

</script>
