import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import routes from './routes'
import axios from 'axios'
import VueAxios from 'vue-axios'
import env from '../env'
import Vant from 'vant'
import 'vant/lib/index.css'
import './components/index'
import './components/filters'


import auth from '@websanova/vue-auth/src/v2.js'
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js'
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js'
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js'
import 'v2-datepicker/lib/index.css';   // v2 need to improt css
import V2Datepicker from 'v2-datepicker';
import * as VueGoogleMaps from "vue2-google-maps";
import VueTelInput from 'vue-tel-input';
// import "./components/global/Filters"
import Vue2Editor from 'vue2-editor'
Vue.use(Vue2Editor)


import { Bar, Doughnut} from 'vue-chartjs'
import { 
    Chart as ChartJS, 
    Title, 
    Tooltip, 
    Legend, 
    BarElement, 
    CategoryScale, 
    ArcElement,
    LinearScale 
} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale)
Vue.component('Bar', Bar)
Vue.component('Doughnut', Doughnut)

// import VueMoment from 'vue-moment'
// // import moment from 'moment-timezone'
// Vue.use(VueMoment, {
//   moment
// })


import './app.scss'

Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: env.googleMapKey,
    libraries: "places"
  }
});


Vue.use(VueTelInput, {
  
  "country": {
    "name": "Thailand",
    "iso2": "TH",
    "dialCode": "66"
  },
  "countryCode": "TH",
  "valid": false,
  "formatted": "+66 684"
})


Vue.use(VueAxios, axios)
Vue.use(VueRouter)
Vue.use(Vant)
Vue.use(V2Datepicker)

const router = new VueRouter({
  mode: 'history',
  routes,
  linkExactActiveClass: 'active',
})

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    staySignedIn: true,
    stores: ['storage'],
    loginData: {
      url: 'auth/signin',
      method: 'POST',
    },
    registerData: {
      url: 'auth/signin',
      method: 'POST',
    },
    fetchData: {
      url: 'admin/auth/user',
      method: 'GET',
    },
    // refreshData: {
    //   url: 'auth/refresh',
    //   method: 'POST',
    // },
  },
})


window.axios = axios
window.userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
axios.defaults.baseURL = env.baseUrl
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('auth_token_default')}`
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['X-TZ'] = window.userTZ
// axios.defaults.headers.common['Content-Type'] = 'application/json'

let vueApp = new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')

// listen for axios interceptors to show error
axios.interceptors.response.use((res) => {
  vueApp.$toast.clear()
  console.log(res.config.url)
  if (res.config.url.endsWith('auth/signin')) {
    localStorage.setItem('auth_token_default',res.data.token)
  }
  if (res.data.success) {
    return res
  }
  // validation error
  if (res.data.code == 422) {
    return res
  }
  if(Object.prototype.hasOwnProperty.call(res.data, 'error')){
      if(res.data.error == "Token is invalid"){
        
        vueApp.$dialog.alert({
          title: 'Error',
          message: res.data.error,
          showCancelButton: false,
          confirmButtonText: 'OK',
        }).then(() => {
          // Your function to execute after the user clicks OK
          vueApp.$auth.logout()
          vueApp.$router.push('/login')
        });
      }   
  }
  
  

  return res
})
