<template>
  <div>
    <!----- Header Title ----->
    <div class="row">
      <div class="col-md-6">
        <div>
          <h3 class="page-title mb-1">
                {{ this.$route.meta.title }}
          </h3>
        </div>
        <div class="breadcrumb-container">
          <ul class="uk-breadcrumb">
            <li><span>Admin</span></li>
            <li><a href="/companies">Companies</a></li>
            <li><a href="">{{ this.$route.meta.title }}</a></li>
          </ul>
        </div>
      </div>
    </div>

    <!----- Form ----->
    <form @submit.prevent="createCompany" ref="createCompanyForm">
      <CompanyProfile :company="company"></CompanyProfile>
      <ul uk-accordion>
          <CompanyInfo :company="company"></CompanyInfo>
          <BankDetail :company="company"></BankDetail>
          <BodyguardInfo :company="company"></BodyguardInfo>
          <LegalAgreement :company="company"></LegalAgreement>
      </ul>
      <div class="col-12 text-right mt-3">
        <a class="cancel-btn mr-2">Cancel</a>
        <button type="submit" class="save-btn">Save</button>
      </div>
    </form>

  </div>
</template>

<script>

  import CompanyProfile from "../Company/CompanyProfile.vue";
  import CompanyInfo from "../Company/CompanyInfo.vue";
  import BankDetail from "../Company/BankDetail.vue";
  import BodyguardInfo from "../Company/BodyguardInfo.vue";
  import LegalAgreement from "../Company/LegalAgreement.vue";

  export default {
    components: {
      CompanyProfile, CompanyInfo, BankDetail, BodyguardInfo, LegalAgreement
    },
    data() {
      return {
        company: {
          logo: null,
          name_en: '',
          name_local: '',
          description_en: '',
          description_local: '',
          active: true,
          has_parent_company: 1,

          currency: '',
          registration_id: '',
          tax_id: '',
          founded_year: null,
          can_go_other_area: 1,
          login_phone_code:'',
          login_phone: '',

          country: '',
          address: '',
          province: '',
          district: '',
          sub_district: '',
          zip_code: null,
          dbd_doc: null,

          contact_person: '',
          contact_person_email: '',
          contact_person_position: '',
          contact_person_number: '',

          company_owner_name: '',
          company_website: '',
          company_lat: null,
          company_lng: null,

          bank_name: '',
          bank_branch_code: '',
          bank_account_no: '',
          bank_owner_name: '',
          bank_book: null,

          size_male_staff: 1,
          size_female_staff : 1,
          size_foreigner_staff: 1,
          size_local_staff: 1,
          trained_for_group: 1,
          size_armed_staff: 1,
          no_of_security_dog: 0,
          question_1: null,
          question_2: null,

        },
      }
    },
    mounted() {
      
    },
    methods: {
      createCompany() {
        this.loading()
        const api = 'admin/agencies'
        axios
          .post(api, this.getFormData(), {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
          })
          .then((response) => {

            if(response.data.success) {
              this.success()
            }else{
              this.requiredfileds = response.data
            }
            console.log(response.data)

          })
          .catch((error) => {
            this.errorMessage = error.message
            console.error('There was an error!', error)
          })
      },
      getFormData() {
        let form = this.$refs.createCompanyForm;
        let formData = new FormData(form);
        let activeUser = formData.get('active') == 'active'? true : false
        formData.set('active', activeUser)

        formData.append('company_country', formData.get('country'))
        formData.append('company_address', formData.get('address'))
        formData.append('company_province', formData.get('province'))
        formData.append('company_sub_district', formData.get('sub_district'))
        formData.append('company_district', formData.get('district'))
        formData.append('company_zip_code', formData.get('zip_code'))
        formData.append('company_tax_id', formData.get('tax_id'))
        formData.append('company_name_en', formData.get('name_en'))
        formData.append('company_name_local', formData.get('name_local'))
        formData.append('company_registration_id', formData.get('registration_id'))
        formData.append('company_contact_person', formData.get('contact_person'))
        formData.append('company_contact_number', formData.get('contact_person_number'))

        return formData
      },
      isRequired(key) {
        if (this.requiredfileds.hasOwnProperty(key)) {
          return '*'
        } else {
          return ''
        }
      },
      loading() {
        this.$toast.loading({
          message: 'Loading...',
          forbidClick: true,
        })
      },
      success() {
        var notyf = new Notyf();
            notyf.confirm('Successfully Created!');
        
        this.$router.push('/companies');
      },

    },
  }

</script>