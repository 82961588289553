<template>
  <div>
    <!----- Header Title ----->
    <section>
      <div class="row content-header company">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
              {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative">
          <div class="pb-3 pl-2 pt-5 mt-3 uk-float-right">
            <div class="form-group search notify-btn mb-1">
                    <input type="text" name="keyword" placeholder="Search for..." class="form-control shadow-none border-0" />
                    <div class="input-group-prepend">
                      <span class="input-group-text border-0 bg-transparent">
                        <button type="submit" class="bg-transparent border-0"><i class="far fa-search"></i></button>
                      </span>
                    </div>
            </div>
          </div>
          <div class="pt-5 mt-3 uk-float-right">
            <div class="mb-3 text-right">
              <router-link :to="'/approval-comment/rejected'">
                <a class="uk-button uk-button-light uk-text-danger pt-1"><i class="fad fa-external-link-alt mr-2"></i> Rejcted List</a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="tpui-container mb-5">
      <div class="row">
      <div class="col-lg-12 pt-4">
        <form name="form_approval_account" method="post">
          <div class="grey-box uk-card uk-card-default uk-width-1-1@m uk-margin-small-bottom p-0" v-for="(list, index) in reviewList" :key="index">
            <div class="uk-card-header uk-padding-small" style="border-bottom: none !important;">
                <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-auto">
                      <img :src="list.user_image_url" class="uk-border-circle" width="40px" height="40px"> 
                    </div>
                    <div class="uk-width-expand pt-2">
                        <h6 class="uk-text-normal uk-margin-remove-bottom uk-text-secondary" v-if="list.user_name != null"><router-link :to="'customer/edit/' + list.id" class="uk-text-secondary">{{ list.user_name }}</router-link></h6>
                        <h6 class="uk-text-normal uk-margin-remove-bottom uk-text-secondary" v-if="list.user_name == null">N/A</h6>
                        <p class="time pt-0 mt-1 uk-text-small"><i class="fal fa-calendar mr-1 relative"></i> {{ list.comment_date }}<span class="end-time ml-1" style="font-size: 11px;"> {{ list.comment_time }} </span> </p>
                    </div>
                    <div class="uk-width-1-4" style="max-width: 180px; min-width: 120px;">
                      <div class="form-group select-dropdown">
                        <select class="form-control uk-form-small pt-0 uk-text-uppercase" v-model="list.status" name="approval">
                          <option value="accepted" class="uk-text-secondary"> Accept </option>
                          <option value="not_clear" class="uk-text-danger"> Not Clear </option>
                          <option value="not_relevant" class="uk-text-danger"> Not Relevant </option>
                          <option value="contain_contact_information" class="uk-text-danger"> Not Clear </option>
                          <option value="inappropriate_content" class="uk-text-danger"> Inappropriate Content </option>
                          <option value="other" class="uk-text-danger"> Other </option>

                        </select>
                      </div>
                    </div>
                </div>
                <div class="row pt-3 pb-0 pr-3">
                  <div class="col-md-6 col-sm-12">
                    <span v-for="(tag, index2) in list.tags" :key="index2" class="uk-label uk-margin-small-right uk-background-primary p-1 pl-2 pr-2">{{tag}}</span>
                  </div>
                  <div class="col-md-6 col-sm-12" hidden>
                    <div class="rating" style="margin-top: -8px;">
                        <input type="radio" id="star5" :name="rating+index" value="5" /><label class = "full" for="star5" title="Awesome - 5 stars"></label>
                        <input type="radio" id="star4half" :name="rating+index" value="4 and a half"/><label class="half" for="star4half" title="Pretty good - 4.5 stars"></label>
                        <input type="radio" id="star4" name="rating" value="4" checked /><label class = "full" for="star4" title="Pretty good - 4 stars"></label>
                        <input type="radio" id="star3half" name="rating" value="3 and a half" /><label class="half" for="star3half" title="Meh - 3.5 stars"></label>
                        <input type="radio" id="star3" name="rating" value="3" /><label class = "full" for="star3" title="Meh - 3 stars"></label>
                        <input type="radio" id="star2half" name="rating" value="2 and a half" /><label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label>
                        <input type="radio" id="star2" name="rating" value="2" /><label class = "full" for="star2" title="Kinda bad - 2 stars"></label>
                        <input type="radio" id="star1half" name="rating" value="1 and a half" /><label class="half" for="star1half" title="Meh - 1.5 stars"></label>
                        <input type="radio" id="star1" name="rating" value="1" /><label class = "full" for="star1" title="Sucks big time - 1 star"></label>
                        <input type="radio" id="starhalf" name="rating" value="half" /><label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label>
                    </div>
                  </div>
                </div>
            </div>
            <div class="uk-card-body uk-padding-small message">
              <dd> {{ list.comment }} </dd>
            </div>
            <div class="uk-card-footer uk-padding-small">
              <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-auto">
                      <dd class="uk-text-uppercase pt-2"> Commented on "{{ list.agency_name }}"</dd>
                    </div>
                    <div class="uk-width-auto">
                      <img :src="list.agency_image_url" class="uk-border-circle" style="width: 40px !important; height: 40px !important; "> 
                    </div>
                    <div class="uk-width-expand">
                        <h6 class="uk-text-normal uk-margin-remove-bottom uk-text-secondary pt-2 mt-1" v-if="list.user_name != null"><router-link :to="'customer/edit/' + list.id" class="uk-text-secondary">{{ list.full_name }}</router-link></h6>
                    </div>  
                </div>
            </div>
        </div>
        </form>
      </div>
      <div class="col-md-3 col-sm-12 pt-1 uk-text-left">
        <button id="btn-perform-all" class="save-btn border-0 mt-4 uk-text-uppercase" v-on:click="performAll()">
          <i class="far fa-check-square uk-margin-small-right"></i> Perform All
        </button>
      </div>
      <div class="col-md-9 col-sm-12 uk-text-right">
        <div class="card-footer pr-0 pl-0" v-if="numberOfPage > 1">
          <jw-pagination
            :items="pages"
            @changePage="onChangePage"
            :pageSize="limit"
            :styles="customStyles"
            :labels="customLabels"
          ></jw-pagination>
        </div>
      </div>
    </div>
    </section>

  </div>
</template>

<script>
const customLabels = {
  first: '<<',
  last: '>>',
  previous: '<',
  next: '>',
}
const customStyles = {
  ul: {},
  li: {
    display: 'inline-block',

    margin: '5px',
  },
  a: {
    color: 'blue',
    margin: '5px',
  },
}
export default {
  mounted() {
    this.getApprovalCommentList(this.page, this.limit, '');

  },
  data() {
    return {
      params: {
        sort: { column: 'id', type: 'desc' },
      },
      keyword: '',
      firstload: false,
      customStyles: customStyles,
      customLabels: customLabels,
      numberOfPage: 0,
      pages: [],
      page: 1,
      limit: 10,
      customerType:'',
      sort: 'id',
      orderby: 'asc',
      reviewList: [],
    }
  },
  methods: {
    performAll(){
      let approvalList = []
      for(var i = 0;i<this.reviewList.length;i++){
        approvalList.push({id:this.reviewList[i].id,status:this.reviewList[i].status})
      }
      let payload = {"reviews":approvalList}
      console.log(payload)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/review-status/update'
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .post(api, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${admintoken}`,
          },
        })
        .then((response) => {
          console.log(response.data)
          if (response.data.code == 200) {
            console.log(response.data)
            location.reload()
          } else {
            this.errorMessage = response.data.message
            this.$dialog.alert({
              title: 'Failed',
              message: this.errorMessage,
              showCancelButton: false,
              confirmButtonText: 'OK',
            })
          }
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    del_method: function (id, name) {
      UIkit.modal.confirm('Do you want to delete user: ' + name + " ? ").then(function () {
        console.log('Confirmed.')
      }, function () {
        console.log('Rejected.')
      });
    },
    filterType(type){
        this.customerType = type
        this.getApprovalCommentList(this.page, this.limit,this.keyword)
    },
    onChangePage(pageOfItems) {
      if (typeof pageOfItems !== undefined && pageOfItems !== null) {
        this.getApprovalCommentList(pageOfItems[0], this.limit,this.keyword)
      }
    },
    getApprovalCommentList(page, limit, keyword) {
      this.page = page;
      let pagevalue = '' + page;
      if (pagevalue == 'undefined') {
        return
      }
      const api =
        'https://dev-api.trueprotection.co.th/api/admin/accept-review-list?page='+page+'&limit='+limit;
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.reviewList = []
          this.reviewList = response.data.data.records
          for(var i =0; i<this.reviewList.length;i++){
            if(this.reviewList[i].comment_tag != null){
              this.reviewList[i].tags = this.reviewList[i].comment_tag.split(",")
            }
            if(this.reviewList[i].status == null){
              this.reviewList[i].status = 'accepted';
            }
          }
          console.log(this.reviewList)
          if (this.firstload == false) {
            this.firstload = true
            this.numberOfPage = response.data.data.total_pages

            var counter = 1
            var pagenumber = 1
            this.pages = []
            for (var i = 0; i < this.numberOfPage * this.limit; i++) {
              if (counter - 1 == this.limit) {
                counter = 1
                pagenumber++
              } else {
                counter++
                this.pages.push(pagenumber)
              }
            }
          }
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    sortcol(sort) {
      this.params.sort = sort
      this.sort = sort.column
      this.orderby = sort.type
      this.getApprovalCommentList(this.page, this.limit,this.keyword)
      console.log(sort.column, sort.type)
    },
    encode(id) {
      return btoa(id)
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    phoneformat: function (value) {
      if (!value) return ''
      value = value.toString()
      var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      return value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
  }
}
</script>