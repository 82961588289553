<template>
    <div class="form-group search notify-btn mb-1 mr-0 float-right">
        <input type="text" v-model="keyword" @keyup.enter="filter" placeholder="Search for..." class="form-control shadow-none border-0" />
        <div class="input-group-prepend">
        <span class="input-group-text border-0 bg-transparent">
            <button type="submit" class="bg-transparent border-0" @click="filter"><i class="far fa-search"></i></button>
        </span>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
    },
    data() {
        return {
            keyword: ''
        }
    },
    methods: {
        filter() {
            this.$emit('filter', this.keyword)
        }
    }
}
</script>

<style scoped>
.search {
    width: 200px;
}
</style>