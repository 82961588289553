<template>
    <li>
      <a class="uk-accordion-title" href="#"> 
        <span uk-icon="info" class="mr-2 relative" style="top: -2px;"></span> Bank Information
      </a>
      <div class="uk-accordion-content">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="grey-box">
                <h6 class="p-2 mt-2 mb-3 uk-text-bold"> <i class="fad fa-money-check mr-2"></i> Bank Information </h6>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <input type="text" name="bank_name" class="form-control" v-model="company.bank_name" required />
                      <label>Bank</label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                    <input type="text" name="bank_branch_code" class="form-control" v-model="company.bank_branch_code" required />
                    <label>BRANCH Code</label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                    <input type="number" name="bank_account_no" class="form-control" v-model="company.bank_account_no" required />
                    <label>Bank account number</label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                    <input type="text" name="bank_owner_name" class="form-control" v-model="company.bank_owner_name" required />
                    <label> Name of the account holder </label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <h5 style="font-weight: 600; color: #111;"> <span uk-icon="upload"></span> Upload Bank Book </h5>
                    <div class="js-upload uk-placeholder uk-text-center mr-2">
                        <div class="mt-2 mb-2">
                          <span class="uk-link btn-upload"> Upload </span>
                        </div>
                        <span uk-icon="icon: cloud-upload" class="mr-2"></span>
                        <span class="uk-text-middle"> Drag & Drop the image of your bank book here (PNG, JPG, PDF) </span>
                        <div uk-form-custom>
                            <input
                                type="file"
                                accept=""
                                name="bank_book"
                                class="file-upload"
                                id="file"
                                ref="bankBookFiles"
                                v-on:change="bankBookFileUpload()"
                                
                            />
                            <img v-if="bankBookUrl" :src="bankBookUrl" />
                        </div>
                    </div>
                    <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </li>
</template>

<script>

  export default {
    props: {
        company: {
          bank_name: String,
          bank_branch_code: String,
          bank_account_no: Number,
          bank_owner_name: String,
          bank_book: null,
        }
    },
    data() {
      return {
        requiredfileds: {},
        bankBookUrl: null
      }
    },
    mounted() {
        this.bankBookUrl = this.company.bank_book? this.company.bank_book : this.bankBookUrl
    },
    methods: {
      bankBookFileUpload() {
        this.company.bank_book = this.$refs.bankBookFiles.files[0]
			  this.bankBookUrl = URL.createObjectURL(this.company.bank_book)
      }
    }
   
  }
</script>