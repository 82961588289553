<template>
	<div>
		<section class="row">
			<div class="col-md-6">
				<div>
					<h3 class="page-title mb-1">
						{{ this.$route.meta.title }}
					</h3>
				</div>
				<div class="breadcrumb-container">
					<ul class="uk-breadcrumb">
						<li><span>Admin</span></li>
						<li><a href="/customer">Customer</a></li>
						<li><a href="">{{ this.$route.meta.title }}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-md-6 relative">
				
			</div>
		</section>
		<section class="tpui-container">
				<div class="row align-items-center mb-4">
					<div class="col-lg-12">
						<div class="grey-box p-3 pt-4">
							<div class="row">
								<div class="col-md-6 text-left">
									<h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-user mr-2"></i> Customer Information </h5>
								</div>
								<div class="col-md-6 text-right">
									 <router-link :to="'/customer/' + customerDetails.id">
                      					<a class="edit-btn"><i class="fal fa-edit"></i></a>
									 </router-link>
								</div>
							</div>
							<div class="view-ui mt-2">
								<div class="row">
									<div class="col-md-12">
										<div class="p-5 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 30px !important;">
											<div class="row p-0 pb-0">
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-user-circle mr-1"></i> First Name </h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.fname }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-user-circle mr-1"></i> Last name</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.lname }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-venus-mars mr-1"></i> Gender</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.gender }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-globe-americas mr-1"></i> Nationality</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.nationality }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-birthday-cake mr-1"></i> Date of Birth</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.birth_date }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-language mr-1"></i> Language</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.language }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-phone-alt mr-1"></i> Phone Number</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.phone_number }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-envelope mr-1"></i> Email</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.email }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-envelope mr-1"></i> Street</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.street }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-envelope mr-1"></i> City</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.city }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-envelope mr-1"></i> State</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.state }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-envelope mr-1"></i> Zip code</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.zipcode }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-envelope mr-1"></i> Country</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> {{ customerDetails.country }} </p>
													</div>
												</div>
												<div class="col-md-4">
													<div>
														<h6 class="title mb-3"><i class="fas fa-envelope mr-1"></i> Update Date</h6>
														<p class="result m-0 mb-4" style="padding-left: 29px;"> <span class="end-time" style="font-size: 11px;"> <i class="fal fa-stopwatch mr-1 relative" style="top: 1px;"></i> 14-08-2022 10:03 </span></p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>	
						</div>
					</div>
					
				</div>
		</section>
	</div>
</template>

<script>

export default {
  props: {
          companyDetail: {
              id: Number,
              bank_account_no: String,
              bank_owner_name: String,
              bank_name: String,
              bank_branch_code: String,
              bank_book: String,
  
          }
      },
      data() {
          return {
            customerDetails: {}
          }
      },
      mounted() {
        this.getCustomerDetails()
      },
      
      methods: {
        getCustomerDetails() {
        const apiUrl = 'https://api.615autorental.com/api/admin/customer/' + this.$route.params.id + '/details'
          const admintoken = localStorage.getItem('auth_token_default')
          const headers = {
            'Authorization': `Bearer ${admintoken}`,
          'Content-Type': 'application/json' // You can adjust content type as needed
        };
        axios
            .get(apiUrl,{ headers },{
              params: {
                page:1,
                limit: 30
              }
            })
            .then((response) => {
                this.customerDetails = response.data.data
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
        } ,
      }
}
</script>