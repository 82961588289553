<template>
  <span :class="'sorter-' + column">
    <i v-if="type == 'asc'" class="fa fa-sort-amount-asc" aria-hidden="true"></i>
    <i v-else class="fa fa-sort-amount-desc" aria-hidden="true"></i>
  </span>
</template>

<script>
export default {
  props: ['column'],
  data() {
    return {
      type: 'desc',
    }
  },
  mounted() {
    var self = this
    $('.sorter-' + self.column)
      .parent('th')
      .click(function () {
        self.sortColumn()
      })
  },
  methods: {
    sortColumn() {
      let type = this.type == 'desc' ? 'asc' : 'desc'
      this.type = type
      this.$emit('change', { column: this.column, type })
    },
  },
}
</script>