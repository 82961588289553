<template>
    <div>
        <!----- Header Title ----->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h3 class="page-title mb-1">
                        {{ this.$route.meta.title }}
                    </h3>
                </div>
                <div class="breadcrumb-container">
                    <ul class="uk-breadcrumb">
                        <li><span>Admin</span></li>
                        <li><a href="/reservation">Reservation</a></li>
                        <li><a href="">{{ this.$route.meta.title }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 relative"></div>
        </div>
        <!-- New Reservation -->
        <div class="row align-items-center mb-4">
            <div class="col-lg-12">
                <section class="grey-box register" v-if="!hasSeenCongrats">
                    <div class="register-stepper">
                        <div class="step step-active"><span class="step-number">1</span></div>
                        <div class="step"><span class="step-number">2</span></div>
                        <div class="step"><span class="step-number">3</span></div>
                        <div class="step"><span class="step-number">4</span></div>
                        <div class="step"><span class="step-number">5</span></div>
                        <div class="step"><span class="step-number">6</span></div>
                    </div>

                    <transition name="slide-fade">
                        <section v-show="step === 1">
                            <form class="form" method="post" action="#">
                                <p class="text-uppercase text-sm mb-2">Date & Time</p>
                                <hr class="horizontal dark mt-0" />
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="date" v-model="pickupDate" class="form-control" value="" autocomplete="off"
                                                required>
                                            <label>Pick-up Date</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="time" v-model="pickupTime" class="form-control" autocomplete="off" required />
                                            <label>Pick-up Time</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="date" v-model="returnDate" class="form-control" autocomplete='off' required />
                                            <label>Return Date</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="time" v-model="returnTime" class="form-control" autocomplete='off' required />
                                            <label>Return Time</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" v-model="pickupLoc" class="form-control" autocomplete='off' required />
                                            <label>Pick-up Location</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" v-model="returnLoc" class="form-control" autocomplete='off' required />
                                            <label>Return Location</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="cta d-flex justify-content-end" data-style="see-through"
                                    data-alignment="right" data-text-color="custom">
                                    <p class="cta-color">
                                        <span class="link_wrap">
                                            <a @click="save" class="link_text btn next-btn"
                                                    style="padding-right: 30px!important;">Next</a>
                                            <span class="arrow-next"></span>
                                        </span>
                                    </p>
                                </div>
                            </form>
                        </section>
                    </transition>
                </section>
            </div>
        </div>


        <!-- Add **uk-toggle="target: #createmodal" in div -->
        <!-- Create success Modal -->
        <div id="createmodal" uk-modal>
            <div class="uk-modal-dialog uk-modal-body p-2 text-center">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div class="row align-items-center mb-4">
                    <div class="col-md-12 mt-4">
                        <h4>Create reservation successful!</h4>
                    </div>
                </div>
                <a @click="next" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
            </div>
        </div>

        <!-- Add **uk-toggle="target: #updatemodal" in div -->
        <!-- Create success Modal -->
        <div id="updatemodal" uk-modal>
            <div class="uk-modal-dialog uk-modal-body p-2 text-center">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div class="row align-items-center mb-4">
                    <div class="col-md-12 mt-4">
                        <h4>Update reservation successful!</h4>
                    </div>
                </div>
                <a href="#" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
            </div>
        </div>
    </div>

</template>



<script>
import router from '../../routes';

export default {
    el: "#app",
    data: () => {
        return {
            step:1,
            pickupDate: "",
            pickupTime: "",
            pickupLoc: "",
            returnDate: "",
            returnTime: "",
            returnLoc: "",
            address: "",
            drivername: "",
            hasSeenCongrats: false,
            reservation:{}
            
        };
    },
    mounted() {
        if(Object.prototype.hasOwnProperty.call(this.$route.params, "id")){
            localStorage.setItem("reservation_id",this.$route.params.id)
            this.getReservationDetails()
        }
        
    },
    methods: {
        redirectReservation() { // 
            router.push({ path: '/reservation/' })
        },
        next(){
            if(Object.prototype.hasOwnProperty.call(this.$route.params, "id")){
                location.href="/reservation/2/edit/" + this.$route.params.id
            }else{
                location.href="/new/reservation/2"
            }
            
        },
        save() {
            let reservationData = {
                "customer_id": 0,
                "vehicle_id": 0,
                "pickup_datetime": this.pickupDate + ' ' + this.pickupTime,
                "return_datetime": this.returnDate + ' ' + this.returnTime,
                "pickup_lat": "",
                "pickup_long": "",
                "return_lat": "",
                "return_long": "",
                "pickup_location": this.pickupLoc,
                "return_location": this.returnLoc,
                "total_days": 0,
                "daily_rate": 0,
                "total_balance": 0,
                "status": "active",
                "notes": "",
                "is_complete": 0
            }
            const admintoken = localStorage.getItem('auth_token_default')
            axios.post(`https://api.615autorental.com/api/admin/reservation`, reservationData, {
                headers: {
                    'Authorization': `Bearer ${admintoken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if(response.data.data.id){
                        localStorage.setItem("reservation_id",response.data.data.id)
                    }
                    UIkit.modal('#createmodal').show();
                    console.log('Customer updated successfully:', response.data);
                    // handle success
                })
                .catch(error => {
                    alert("Please complete form")
                    console.error('Error updating customer:', error);
                    // handle error
                });
        },
        getReservationDetails(){
            const reservation_id = localStorage.getItem("reservation_id")
            const api = 'https://api.615autorental.com/api/admin/reservation/'+reservation_id+'/details'
            const admintoken = localStorage.getItem('auth_token_default')
            const headers = {
                'Authorization': `Bearer ${admintoken}`,
                'Content-Type': 'application/json' // You can adjust content type as needed
            };
            axios
                .get(api, { headers }, {
                params: {
                    page: 1,
                    limit: 30
                }
                })
                .then((response) => {
                this.reservation = response.data.data;
                this.pickupDate = this.showDate(this.reservation.pickup_datetime);
                this.pickupTime = this.showTime(this.reservation.pickup_datetime),
                this.pickupLoc = this.reservation.pickup_location
                this.returnDate = this.showDate(this.reservation.return_datetime);
                this.returnTime = this.showTime(this.reservation.return_datetime);
                this.returnLoc = this.reservation.return_location
                this.address = this.reservation.customer.address
                this.drivername = this.reservation.customer.fname
                console.log(this.reservation)
                this.readyShow = true
                })
                .catch((error) => {
                console.error('There was an error!', error.message)
                })
        },
        showDate(datetime) {
            return datetime.substring(0,10)
        },
        showTime(datetime){
            return datetime.substring(10)
        },
        newBooking: function () {
            this.hasSeenCongrats = true;
        },
    }

};
</script>

<style scoped>
.card-footer {
    text-align: right;
    position: relative;
    background: #fff;
}

.pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
}
</style>