<template>
    <div class="row mt-4">
        <div class="col-md-6 text-left">
            <h5 class="mt-1 mb-2 uk-text-bold">
                <i class="fas fa-chart-bar mr-2"></i> Transportation price list
            </h5>
        </div>
        <div class="col-lg-12">
            <div class="table-responsive grey-box p-0">
                <table class="table data-table table-round table-hover uk-animation-slide-bottom-small">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Brand</th>
                            <th scope="col">Model</th>
                            <th scope="col">Plate Number</th>
                            <th scope="col">Seats</th>
                            <th scope="col">Color</th>
                            <th scope="col">Status</th>
                            <th scope="col" class="blankhead" width="150px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(vehicle, vIndex1) in vehicleLists" >
                            <template>
                                <tr :key="vIndex1" ref="vehicleForm">
                                    <td>
                                        <img :src="`/assets/img/logo.png`" class="uk-border-circle" width="55px" height="55px"> 
                                    </td>
                                    <td>
                                        <div class="form-group mb-2 mt-0">
                                            <input type="text"  v-model="vehicleLists[vIndex1].brand"  class="form-control uk-form-small pt-0" required>
                                        </div>
                                    </td>
                                    <td>
                                        <!-- {{ vehicle.model }} -->
                                        <div class="form-group mb-2 mt-0">
                                            <input type="text"  v-model="vehicleLists[vIndex1].model"  class="form-control uk-form-small pt-0" required>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-group mb-2 mt-0">
                                            <input type="text"  v-model="vehicleLists[vIndex1].plate_number"  class="form-control uk-form-small pt-0" required>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-group mb-2 mt-0">
                                            <input type="number" v-model="vehicleLists[vIndex1].total_seat"  class="form-control uk-form-small pt-0" required>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-group mb-2 mt-0">
                                            <input type="number" v-model="vehicleLists[vIndex1].color"  class="form-control uk-form-small pt-0" required>
                                        </div>
                                    </td>
                                    <td class="text-center relative">
                                        <label class="switch">
                                            <input type="checkbox" v-model="vehicleLists[vIndex1].active" :checked="vehicle.active" />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                    
                                    <td class="text-right">
                                        <a v-if="vehicle.id != undefined" data-toggle="collapse"
                                            v-bind:data-target="`#price_list_${vehicle.id}`"  
                                            class="edit-btn clickable mb-2" 
                                            type="button">
                                            <i class="fal fa-edit"></i>
                                        </a>

                                        <button v-else type="button" @click="submitVehicle(vehicle.id, vIndex1)" class="save-btn ml-2 mr-2" style="border:0px">
                                            <i class="fas fa-save mr-2"></i> save
                                        </button>
                                        
                                        <!-- <a v-if="vehicle.id != undefined" @click="copyVehicle(vehicle.id, vIndex1)" class="edit-btn mb-2" uk-toggle>
                                            <i class="far fa-copy"></i>
                                        </a> -->
                                        <a @click="deleteVehicle(vehicle.id, vIndex1)" href="#" class="del-btn mb-2">
                                            <i class="fal fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr :key="'price'+vIndex1" :id="`price_list_${vehicle.id}`" class="collapse">
                                    <td colspan="8" class="p-3" style="border: 2px solid #dee2e6;border-radius: 10px;">
                                        <div class="row">
                                            <div class="col-md-6 vehicles mb-3">
                                                <h5 class="uppercase">Gallery <span style="font-size: 10px">(MAX 8 pictures)</span></h5>
                                                <div v-for="(vImg, imgIndex) in vehicle.images" :key="imgIndex" class="gallery-img position-relative text-center">
                                                    <div class="img-wrapper">
                                                        <img :src="vImg.path" alt="Vehicle Image" />
                                                        <span class="edit-btn">
                                                            <!-- <input 
                                                                type="file" 
                                                                name="images[]" 
                                                                class="file-upload" 
                                                                id="file" 
                                                                v-on:change="handleFileUpload($event.target.files, vIndex1, imgIndex)" >
                                                            <i class="fal fa-pen upload-button"></i> Change -->
                                                        </span>
                                                    </div>
                                                    <span class="delete-btn" @click="deleteImage(vIndex1, imgIndex)"></span>
                                                </div>
                                                <div class="gallery-img position-relative text-center">
                                                    <div class="img-wrapper" style="border: 1px solid #1363f8;">
                                                        <span class="edit-btn" style="height:65%; background:none">
                                                            <input 
                                                                name="images[]"
                                                                type="file" 
                                                                class="file-upload" 
                                                                id="file" 
                                                                v-on:change="handleFileUpload($event.target.files, vIndex1)" >
                                                            <i class="fal fa-plus upload-button" style="color:#1363f8"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <h5 class="uppercase">Price List</h5>
                                                <div class="table-responsive grey-box p-0">
                                                    <table class="table data-table table-round table-hover" >
                                                        <thead>
                                                            <tr>
                                                                <th width="30%" class="pl-4 pr-4">Price</th>
                                                                <th width="60%" class="pl-4 pr-4" colspan="2">Period</th>
                                                                <th width="auto" class="pl-4 pr-4"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            
                                                            <tr v-for="(price, vPriceIndex) in vehicle.prices" :key="vPriceIndex">
                                                                <td class="bg-lightgreen">
                                                                    <input type="text" v-model="vehicle.prices[vPriceIndex].price" class="form-control uk-form-small" required>
                                                                </td>
                                                                <td class="bg-white" width="30%">
                                                                    <div class="form-group mb-2 mt-1">
                                                                    <input type="text" v-model="vehicle.prices[vPriceIndex].range_value" class="form-control uk-form-small" required>
                                                                    </div>
                                                                </td>
                                                                <td class="bg-white" width="30%">
                                                                    <div class=" form-group select-dropdown mb-2 mt-0">
                                                                        <select v-model="vehicle.prices[vPriceIndex].range_period"  class="form-control uk-form-small pt-0" required>
                                                                            <option value="day">Day</option>
                                                                            <option value="week">Week</option>
                                                                            <option value="month">Month</option>
                                                                        </select>
                                                                    </div>
                                                                </td>
                                                                
                                                                <td class="bg-lightgreen">
                                                                    <a @click="deleteVPrice(vPriceIndex, 'price'+vIndex1)" href="#" class="del-btn mb-2">
                                                                        <i class="fal fa-trash-alt"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="4">
                                                                    <a @click="addPrice('price'+vIndex1)" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center">
                                                                        <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 text-right">
                                                <hr />
                                                <!-- <a @click="updateVehicle(vehicle.id, vIndex1)" class="save-btn ml-1 mb-2">
                                                    <i class="fal fa-save" aria-hidden="true"></i>
                                                </a> -->
                                                <button type="button" @click="submitVehicle(vehicle.id, vIndex1)" class="save-btn ml-2 mr-2" style="border:0px">
                                                    <i class="fas fa-save mr-2"></i> save
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                        <tr>
                            <td colspan="8">
                                <a @click="addVehicle" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center">
                                    <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="card-footer pb-0" v-show="vehicleList.length > 0">
                <Pagination 
                @getCurrentPagination="getCurrentPagination" 
                :totalPages="paginData.totalPages"
                :page="paginData.page" />
            </div> -->
        </div>
    </div>
</template>

<script>

export default {
  
    mounted() {

                                        
        this.vehicleLists = [{ id:1, 
        brand: 'brand',
        model: 'm3333',
        plate_number: '32xx',
        total_seat: 3,
        color: 'white',
        active: true,
        images:[{id: 1, path: '/assets/img/logo.png'}], 
        prices:[{ id:1, price: 200,
            range_value: 222,
            range_period: 'week'}] }]

        // this.getVehicleList()
    },
    data() {
        return {
            sortParams: {
                column: 'id',
                type: 'asc',
            },

            paginData: {
                page: 1,
                totalPages: 0,
                limit: this.$helpers.pageLimit()
            },

            filters: {
                keyword: '',
            },

            vehicleLists: [],
        }
    },
    methods: {
    
        filter(keyword, type = 'keyword') {
            this.filters[type] = keyword
            this.getVehicleList()
        },
        sort(params) {
            this.sortParams = {
                column: params.column,
                type: params.type,
            }
            this.getVehicleList()
        },
        // getCurrentPagination(currentPage) {
        //     this.paginData.page = currentPage
        //     this.getVehicleList()
        // },
        getVehicleList() {
            axios.get('vehicles', {
                    params: {
                        page: this.paginData.page,
                        limit: this.paginData.limit,
                        sort_column: this.sortParams.column,
                        sort_type: this.sortParams.type,
                        keyword: this.filters.keyword,
                    }
                })
                .then((response) => {
                    if(response.data.success) {

                        this.vehicleLists = response.data.data
                        // this.paginData.totalPages = response.data.data.total_pages
                    }else{
                        this.$helpers.notification(response.data.success, response.data.message)
                    }
                })
                .catch((error) => {
                    this.$helpers.notification(response.data.success, error.message)
                    console.error('There was an error!', error)
                })
        },
        del_method: function (id, name) {
            UIkit.modal.confirm('Do you want to delete vihicle: ' + name + " ? ").then(
                function () {
                    axios.delete(`vehicles/${id}`)
                    .then(response => {
                        this.getVehicleList()
                    })
                    .catch((error) => {
                        this.$helpers.notification(false, error.message)
                        console.error('There was an error!', error)
                    })
                },
            )
        },
        // vehicleActive(index) {
        //     this.vehicleLists[index].active = !this.vehicleLists[index].active
        //     console.log(this.vehicleLists[index].active)

        // },
        addVehicle() {

            this.vehicleLists.push({
                    name_en: '',
                    vehicle_code: '',
                    model: '',
                    brand: '',
                    plate_number: '',
                    total_seat: null,
                    active: true,
                    images: [], 
                    prices: []
                })
        },
        // copyVehicle(vehicleId, index) {
        //     if(vehicleId != undefined){
        //         this.createVehicle(this.getFormData(index))
        //     }else{
        //         this.vehicleLists.push({})
        //     }
        // },
        submitVehicle(vehicleId, index) {
       
            if(this.vehicleLists[index].prices.length > 0) {
                this.updatePrice(vehicleId, index)
            }else{
                this.updateVehicle(vehicleId, index)
            }
            
        },
        changeStatus(vehicleId, index) {
            this.updateVehicle(vehicleId, index)
        },
        updateVehicle(vehicleId, index) {
            const api = (vehicleId == undefined)? 'vehicles' :  'vehicles/' + vehicleId
            axios
                .post(api, this.getFormData(index), {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.$helpers.notification(true, 'Success!')
                        this.getVehicleList()
                    }else{
                        // this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    this.$helpers.notification(false, error.message)

                    console.error('There was an error!', error.message)
                })
        },
        getFormData(index) {
            let formData = new FormData();

            let data = this.vehicleLists[index]
            formData.append('name_en', data.name_en)
            formData.append('vehicle_code', data.vehicle_code)
            formData.append('model', data.model)
            formData.append('brand', data.brand)
            formData.append('plate_number', data.plate_number)
            formData.append('total_seat', parseInt(data.total_seat))
            // formData.append('has_siren',false)
            // formData.append('is_police_vehicle', false)
            formData.append('active', data.active? 1 : 0)
            
            let images = this.vehicleLists[index].images
            if(images.length > 0){
                return this.getImg(images, formData)
            }
            
            return formData
        },
        getImg(images, formData) {

            let oldIds = []
            images.forEach(function(img){
                if(img.id){
                    oldIds.push(img.id)
                }
                if(img.file){
                    formData.append('images[]', img.file)
                }
            })
            
            if(oldIds.length > 0) {
                formData.append('old_images_id', oldIds.join(','))
            }
            return formData
        },
        deleteVehicle(vehicleId, index) {

            if(vehicleId == undefined) {
                this.vehicleLists.splice(index, 1);
                this.$helpers.notification(true, 'Deleted!')
            }else{

                const api = 'vehicles/' + vehicleId
                axios
                    .delete(api)
                    .then((response) => {
                        if(response.data.success) {
                            this.$helpers.notification(false, 'Deleted!')
                            this.getVehicleList()
                        }
                    })
                    .catch((error) => {
                        this.$helpers.notification(false, error.message)
                        console.error('There was an error!', error.message)
                    })
            }

        },
        addPrice(vehicleIndex) {
            console.log(this.vehicleLists[vehicleIndex.split('price')[1]].prices)
            this.vehicleLists[vehicleIndex.split('price')[1]].prices.push({})
        },
        updatePrice(vehicleId, vehicleIndex) {
                console.log(this.vehicleLists[vehicleIndex].prices)
                const api = `vehicles/${vehicleId}/prices`
                axios
                    .post(api, { prices: this.vehicleLists[vehicleIndex].prices })
                    .then((response) => {
                        
                        this.updateVehicle(vehicleId, vehicleIndex)
                    })
                    .catch((error) => {
                        console.error('There was an error!', error.message)
                    })
        },
        deleteVPrice(priceIndex, vehicleIndex) {
            let vIndex = vehicleIndex.split('price')[1]
            let vehicle = this.vehicleLists[vIndex]
            let prices = this.vehicleLists[vIndex].prices
            let priceId = prices[priceIndex].id
                prices.splice(priceIndex, 1); 

            if( priceId != undefined){
                this.updatePrice(vehicle.id, vIndex)
            }
                this.$helpers.notification(false, 'Deleted!')

        },
        handleFileUpload(file, vIndex) {
            this.vehicleLists[vIndex].images.push({id:null, path:URL.createObjectURL(file[0]), file: file[0]})
        },
        deleteImage(vIndex, imgIndex) {
            this.vehicleLists[vIndex].images.splice(imgIndex, 1)
        }
    },
}
</script>
<style scoped>
</style>