<template>
    <section class="tpui-container">
        <div class="row align-items-center mb-4">
            <div class="col-lg-12">
                <div class="grey-box p-3 pt-4">
                    <div class="row">
                        <div class="col-md-6 text-left">
                            <h5 class="mt-1 mb-2 uk-text-bold">
                                <i class="fas fa-university mr-2"></i> Bank Details
                            </h5>
                        </div>
                    </div>
                    <div class="view-ui mt-4 mb-2">
                        <form @submit.prevent="updateBank" ref="updateBankForm">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" name="bank_name" v-model="companyDetail.bank_name" class="form-control" required />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" name="bank_account_no" v-model="companyDetail.bank_account_no" class="form-control" required />
                                        <label>Account Number</label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" name="bank_branch_code" v-model="companyDetail.bank_branch_code" class="form-control" required />
                                        <label>Branch Code</label>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-md-12">
                                    <div class="row pl-0 pr-0">
                                        <div class="col-md-12 mt-3 pt-3">
                                            <h6 class="uk-text-uppercase pl-1 uk-text-bold">
                                                <i class="fas fa-map-marker-alt mr-2"></i> Account Holder Details
                                            </h6>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <input type="text" name="bank_owner_name" v-model="companyDetail.bank_owner_name" class="form-control" required />
                                                <label>Owner Name</label>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-3">
                                            <div class="form-group vue-tel-input-wrapper">
                                                <template>
                                                    <vue-tel-input v-model="phone"></vue-tel-input>
                                                    <label>Phone Number</label>
                                                </template>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                                    <hr class="mt-2 mb-4" />
                                    <button href="#" class="cancel-btn border-0">
                                        <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                                    </button>
                                    <button type="submit" id="client" class="save-btn border-0 ml-2 mr-2">
                                        <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                                    </button>
                                </div>
                            </div>
                        </form>
                            <!-- <div class="row">
                                <div class="clearfix"></div>
                                <div class="col-md-12">
                                    <div class="row pl-0 pr-0">
                                        <div class="col-md-12 mt-3 pt-3">
                                            <h6 class="uk-text-uppercase pl-1 uk-text-bold">
                                                <i class="fas fa-file-alt mr-2"></i> Book Bank and Bank Statement
                                            </h6>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="table-responsive">
                                                <table class="table data-table table-round table-hover">
                                                    <thead>
                                                    <tr>
                                                        <th width="75%" class="text-left">Upload dated</th>
                                                        <th width="15%" class="text-left">File</th>
                                                        <th class="blankhead" width="100px"></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="time" style="font-size: 12px">
                                                        <i class="fal fa-calendar mr-1 relative" aria-hidden="true" style="top: 1px"></i>
                                                        14 AUG 2022 <span class="end-time ml-1" style="font-size: 11px"> 20:03 </span>
                                                        </td>
                                                        <td class="text-left">
                                                        <p class="pt-0 pb-0 mt-0 mb-0">Passport.jpg</p>
                                                        </td>
                                                        <td class="text-right">
                                                        <span uk-lightbox>
                                                            <a
                                                            class="view-btn clickable only-icon pl-2 pr-2"
                                                            href="https://www.itwsf.com/Portals/0/Holographic-Passport-Film.jpg?ver=1Mjc1ciIZ-jzbuNI7QL1Ow%3D%3D"
                                                            data-caption="Passport.jpg"
                                                            ></a>
                                                        </span>
                                                        <a class="edit-btn pl-2 pr-2" data-toggle="collapse" data-target="#edit_dbd_file_1"
                                                            ><i class="fal fa-edit"></i
                                                        ></a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="7" class="p-0">
                                                        <div id="edit_dbd_file_1" class="collapse">
                                                            <form>
                                                            <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important">
                                                                <div class="row p-0 pb-0 mt-0 mb-0 view-ui">
                                                                <div class="col-md-12 p-0 pb-0 mt-0 mb-0">
                                                                    <div class="js-upload uk-placeholder uk-text-center mt-0 mb-0">
                                                                    <span uk-icon="icon: cloud-upload" class="mr-2 mt-1"></span>
                                                                    <span class="uk-text-middle">Upload or Drag & Drop here</span>
                                                                    <div uk-form-custom>
                                                                        <input type="file" multiple />
                                                                    </div>
                                                                    </div>
                                                                    <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </form>
                                                        </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="7">
                                                        <a
                                                            href="#"
                                                            class="
                                                            save-btn
                                                            sendmail-btn
                                                            border-0
                                                            mt-0
                                                            text-center
                                                            border-radius-default
                                                            uk-text-primary uk-text-uppercase
                                                            "
                                                            style="height: auto; width: auto !important; text-align: left !important"
                                                        >
                                                            <i class="fas fa-plus mr-1" aria-hidden="true" style="font-size: 12px; display: block"></i> Add
                                                            more
                                                        </a>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>

export default {
    props: {
        companyDetail: {
            id: Number,
            bank_account_no: String,
            bank_owner_name: String,
            bank_name: String,
            bank_branch_code: String,
            bank_book: String,

        }
    },
    mounted() {
    },
    data() {
        return {
            logoUrl: '',
            requiredfileds:[]
        }
    },
    methods: {
        updateBank() {
            this.loading()
            const api = 'admin/agencies/' + this.companyDetail.id + '/bank_account'
            axios
                .post(api, this.getFormData(), {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                })
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        getFormData() {
            let form = this.$refs.updateBankForm;
            let formData = new FormData(form);
            return formData
        },
        handleFileUpload() {
            this.companyDetail.logo = this.$refs.logo.files[0]
            this.logoUrl = URL.createObjectURL(this.companyDetail.logo)
        },
        loading() {
            this.$toast.loading({
                message: 'Loading...',
                forbidClick: true,
            })
        },
        success() {
            var notyf = new Notyf();
            notyf.confirm('Successfully Updated!');
        },
    }
}
</script>