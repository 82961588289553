<template>
    <div class="row mt-2 mb-3">
      
      <!----- Bodyguard ----->
      
      <div class="col-md-4">
        <div class="dashboard home animate p-2 mb-2">
          <div class="row">
            <div class="col-md-8 text-left">
              <h6 class="mt-3 mb-1"> 
                <i class="fas fa-user-tie mr-2"></i> Revenue
              </h6>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="value-box relative">
                    <span class="title uk-text-uppercase"> Companies </span>
                    <span class="value"> {{ totalResults.BG? totalResults.BG.total_agencies : 0 }} </span>
                    <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                      <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.BG? totalResults.BG.compare_agencies : 0 }}%</small>
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="value-box relative">
                    <span class="title uk-text-uppercase"> Freelancers </span>
                    <span class="value"> {{ totalResults.BG? totalResults.BG.total_freelance : 0 }} </span>
                    <span class="uk-position-bottom-right mb-3 mr-1" style="color: red;">
                      <i uk-icon="icon: chevron-down; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.BG? totalResults.BG.compare_freelance : 0 }}%</small>
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="value-box relative">
                    <span class="title uk-text-uppercase"> Total </span>
                    <span class="value"> {{ totalResults.BG? totalResults.BG.total : 0 }} </span>
                    <span class="uk-position-bottom-right mb-3 mr-1" style="color: red;">
                      <i uk-icon="icon: chevron-down; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.BG? totalResults.BG.compare_total : 0 }}%</small>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!----- Security Guard ----->

      <div class="col-md-4">
        <div class="dashboard home animate p-2 mb-2">
          <div class="row">
              <div class="col-md-8 text-left">
                <h6 class="mt-3 mb-1"> 
                  <i class="fas fa-user-shield mr-2"></i> Total Security Guard
                </h6>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Companies </span>
                      <span class="value"> {{ totalResults.SG? totalResults.SG.total_agencies : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.SG? totalResults.SG.compare_agencies : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Freelancers </span>
                      <span class="value"> {{ totalResults.SG? totalResults.SG.total_freelance : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.SG? totalResults.SG.compare_freelance : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Total </span>
                      <span class="value"> {{ totalResults.SG? totalResults.SG.total : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.SG? totalResults.SG.compare_total : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <!----- Private Invertigate ----->

      <div class="col-md-4">
        <div class="dashboard home animate p-2 mb-2">
          <div class="row">
              <div class="col-md-8 text-left">
                <h6 class="mt-3 mb-1"> <i class="fas fa-user-cowboy mr-2"></i> Total Private Investigate</h6>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Companies </span>
                      <span class="value"> {{ totalResults.PI? totalResults.PI.total_agencies : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.PI? totalResults.PI.compare_agencies : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Freelancers </span>
                      <span class="value"> {{ totalResults.PI? totalResults.PI.total_freelance : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.PI? totalResults.PI.compare_freelance : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Total </span>
                      <span class="value"> {{ totalResults.PI? totalResults.PI.total : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.PI? totalResults.PI.compare_total : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <!----- VIP Service ----->

      <div class="col-md-4 pt-3">
        <div class="dashboard home animate p-2 mb-2">
          <div class="row">
              <div class="col-md-8 text-left">
                <h6 class="mt-3 mb-1"> <i class="fas fa-user-crown mr-2"></i> Total VIP Service</h6>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Companies </span>
                      <span class="value"> {{ totalResults.VI? totalResults.VI.total_agencies : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.VI? totalResults.VI.compare_agencies : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Freelancers </span>
                      <span class="value"> {{ totalResults.VI? totalResults.VI.total_freelance : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.VI? totalResults.VI.compare_freelance : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Total </span>
                      <span class="value"> {{ totalResults.VI? totalResults.VI.total : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.VI? totalResults.VI.compare_total : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <!----- Educators ----->

      <div class="col-md-4 pt-3">
        <div class="dashboard home animate p-2 mb-2">
          <div class="row">
              <div class="col-md-8 text-left">
                <h6 class="mt-3 mb-1"> <i class="fas fa-user-graduate mr-2"></i> Total Educators </h6>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Companies </span>
                      <span class="value"> {{ totalResults.ED? totalResults.ED.total_agencies : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.ED? totalResults.ED.compare_agencies : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Freelancers </span>
                      <span class="value"> {{ totalResults.ED? totalResults.ED.total_freelance : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.ED? totalResults.ED.compare_freelance : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Total </span>
                      <span class="value"> {{ totalResults.ED? totalResults.ED.total : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.ED? totalResults.ED.compare_total : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <!----- Car Rental Services ----->

      <div class="col-md-4 pt-3">
        <div class="dashboard home animate p-2 mb-2">
          <div class="row">
              <div class="col-md-8 text-left">
                <h6 class="mt-3 mb-1"> <i class="fad fa-car mr-2"></i> Total Car Rental Services </h6>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Car Providers </span>
                      <span class="value"> {{ totalResults.CA? totalResults.CA.total_agencies : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.CA? totalResults.CA.compare_agencies : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Freelancers </span>
                      <span class="value"> {{ totalResults.CA? totalResults.CA.total_freelance : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.CA? totalResults.CA.compare_freelance : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="value-box relative">
                      <span class="title uk-text-uppercase"> Total </span>
                      <span class="value"> {{ totalResults.CA? totalResults.CA.total : 0 }} </span>
                      <span class="uk-position-bottom-right mb-3 mr-1" style="color: green;">
                        <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>{{ totalResults.CA? totalResults.CA.compare_total : 0 }}%</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>

export default {
  data() {
    return {
      totalResults: {}
    }
  },
  mounted() {
    this.getTotalResult()
  },
  methods: {
    getTotalResult() {
      const api = 'admin/dashboard/get_total'
      axios
        .get(api)
        .then((response) => {
            this.totalResults = response.data.data

        })
        .catch((error) => {
        })
    }
  }
}
</script>