<template>
  <div>
    
    <Chart></Chart>
    <div class="row">
      <div class="col-md-9 col-12 mb-3 uk-animation-slide-bottom-small">
        <ul class="filter d-md-inline-flex d-none mr-3">
          <li class="active" @click="filter('True Protection', 'type')">
            <a href="#">True Protection</a>
          </li>
          <li @click="filter('Client', 'type')">
            <a href="#">Client</a>
          </li>
          <li @click="filter('Service Providers', 'type')">
            <a href="#">Service Providers</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3 col-12 text-right mb-3 uk-animation-slide-bottom-small">
            <Search @filter="filter"></Search>
      </div>
    </div>

    <Payments :payments="payments"></Payments>
    
    <div class="card-footer pb-0" v-if="payments.length > 0">
        <Pagination 
        @getCurrentPagination="getCurrentPagination" 
        :totalPages="paginData.totalPages"
        :page="paginData.page" />
    </div>
  </div>
</template>

<script>

import Payments from "../Finance/_PaymentHistory.vue";
import Chart from "./_Chart.vue";

export default {
  components: {
     Payments, Chart
  },
  mounted() {
    this.getPaymentList()
  },
  data() {
    return {
      sortParams: {
          column: 'id',
          type: 'desc',
      },
      paginData: {
          page: 1,
          totalPages: 0,
          limit: this.$helpers.pageLimit()
      },
      
      filters: {
        keyword: '',
        chart: '',
        status: 'completed',
      },
      dateFilter: {
        start_date: null,
        end_date: null
      },

      start_date: null,
      end_date: null,

      payments: [],
      
    }
  },
  methods: {
    filter(keyword, type = 'keyword') {
      this.filters[type] = keyword
      this.getPaymentList()
    },
    sort(params) {
      this.sortParams = {
        column: params.column,
        type: params.type,
      }
      this.getPaymentList()
    },

    getCurrentPagination(currentPage) {
        this.paginData.page = currentPage
        this.getPaymentList()
    },
    
    getPaymentList() {
      const api = 'admin/payments'
      axios
        .get(api, { 
          params: {
            page: this.paginData.page,
            limit: this.paginData.limit,
            sort_column: this.sortParams.column,
            sort_type: this.sortParams.type,
            status: this.filters.status,
            keyword: this.filters.keyword,
          }
        })
        .then((response) => {
          if(response.data.success) {
            this.payments = response.data.data.records
            this.paginData.totalPages = response.data.data.total_pages
          }else{
            this.$helpers.notification(response.data.success, response.data.message)
          }
        })
        .catch((error) => {
          this.$helpers.notification(false, error.message)
          console.error('There was an error!', error)
        })
    },
  },
}
</script>