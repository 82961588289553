<template>
  <div class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <div role="form">
                    <div class="mb-3">
                      <div class="form-group">
                        <div class="">
                          <!--v-if-->
                          <input type="email" class="form-control form-control-lg invalid" name="email" placeholder="Email" v-model="params.email" isrequired="false">
                          <!--v-if-->
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="form-group">
                        <div class="">
                          <!--v-if-->
                          <input type="password" class="form-control form-control-lg invalid" name="password" v-model="params.password"  placeholder="Password" isrequired="false">
                          <!--v-if-->
                        </div>
                      </div>
                    </div>
                    <div class="form-check form-switch">
                      <label class="switch">
                          <input type="checkbox" />
                          <span class="slider round"></span>
                      </label>
                      <label class="form-check-label" for="rememberMe">Remember me</label>
                    </div>
                    <div class="text-center">
                      <button @click="login()" class="btn mb-0 btn-primary btn-lg w-100 null mt-4">Sign in</button>
                    </div>
                  </div>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <router-link :to="'/signup/'">
                      <a class="text-primary text-gradient font-weight-bold">Sign up</a>
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url('https://rent.615autorental.com/assets/img/slider-2.jpeg');
          background-size: cover;"
              >
                <span class="mask bg-gradient-dark opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >Start your booking system with 615 Auto Rental</h4>
                <p
                  class="text-white position-relative"
                >Upgrade your booking experience with our 615 Auto Rental System</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const body = document.getElementsByTagName("body")[0];

export default {
  name: "signin",
  components: {
  },
  data() {
    return {
      params: {
        email: 'dev@autorental.com',
        password: '123456789',
      },
    }
  },
  methods: {
    login() {
      this.$toast.loading({
        message: 'Loading...',
        forbidClick: true,
      })
      
      this.$auth.login({
        data: this.params,
        redirect: '/',
        staySignedIn: true,
        fetchUser: true,
        success() {
            console.log('success ' + this.context);
        },
        error(res) {

            console.log(res.response);

            console.log('error ' + this.context);

            this.error = res.data;
        }
      })

      // location.href = "/"
    },
  },
  created() {
   
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
   
    body.classList.add("bg-gray-100");
  },
};
</script>

<style> 
.mt-0 {
    margin-top: 0 !important;
}
.page-header {
    padding: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: 50%;
}
.min-vh-100 {
    min-height: 100vh !important;
}
.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}
.flex-column {
    flex-direction: column !important;
}
.d-flex {
    display: flex !important;
}
.pe-0 {
    padding-right: 0 !important;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
}
.pb-0 {
    padding-bottom: 0!important;
}
.card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
    border-radius: 1rem 1rem 0 0;
}
.card .card-header {
    padding: 1.5rem;
}

.text-start {
    text-align: left!important;
}
.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.justify-content-center {
    justify-content: center !important;
}
.d-none {
    display: none !important;
}
.card.card-plain {
    background-color: transparent;
    box-shadow: none !important;
}
.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}
.card .card-body {
    font-family: "Open Sans", sans-serif;
    padding: 1.5rem;
}
.flex-column {
    flex-direction: column !important;
}
.h-100 {
    height: 100% !important;
}
.end-0 {
    right: 0 !important;
}
.top-0 {
    top: 0 !important;
}
.position-absolute {
    position: absolute !important;
}
.card-footer:last-child {
    border-radius: 0 0 1rem 1rem;
}
.card .card-footer {
    padding: 1.5rem;
    background-color: transparent;
}
.font-weight-bolder {
    font-weight: 700 !important;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2d6da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.5rem;
    transition: box-shadow 0.15s ease, border-color 0.15s ease;
}
.form-control-lg {
    min-height: unset;
    padding: 0.75rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
}
.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.73em;
    margin-bottom: 0.125rem;
}

.form-switch {
    display: flex;
    align-items: center;
    padding-left: 3rem;
}
.form-check-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    float: left;
    width: 2.5rem;
    margin-left: -3rem;
    background-image: none;
    background-position: left center;
    border-radius: 2.5rem;
    transition: background-color 0.25s ease, border-color 0.25s ease, background-position 0.15s ease-in-out, opacity 0.15s ease-out, box-shadow 0.15s ease-in-out;
}
.form-check-label, .form-check-input[type=checkbox] {
    cursor: pointer;
}

.form-switch .form-check-input {
    border: 1px solid #e9ecef;
    position: relative;
    background-color: rgba(33, 37, 41, 0.1);
    height: 1.25em;
}

.form-switch .form-check-input:after {
    transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    position: absolute;
    background-color: #fff;
    transform: translateX(1px);
    box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07);
    top: 1px;
}
.form-check-label {
    font-size: 0.875rem;
    font-weight: 400;
    top: -7px;
}
.form-switch .form-check-label {
    margin-bottom: 0;
    margin-left: 1rem!important;
}
.text-sm {
    font-size: 0.875rem !important;
    line-height: 1.5;
}
.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}
.border-radius-lg {
    border-radius: 0.75rem;
}
.px-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
}
.m-3 {
    margin: 1rem !important;
}
.bg-gradient-dark {
    background-image: linear-gradient(310deg, #212229 0%, #212529 100%);
}
.mask {
    position: absolute;
    background-size: cover;
    background-position: center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
}
.opacity-6 {
    opacity: 0.6 !important;
}
.form-check.form-switch {
  padding-left: 0!important;
}

@media (min-width: 992px) {
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
}


</style>