<template>
<div>
  <header id="header" class="uk-animation-slide-top-small">
    <div class="header-container p-0">
      <div class="row align-items-center position-relative">
        <!-- <button type="button" id="sidebarCollapse" class="btn d-lg-none" name="collapsebtn">
          <i class="far fa-bars"></i>
        </button> -->
        <div class="hamburger show" uk-scrollspy="cls: uk-animation-slide-left-small; target: .line; delay: 200; repeat: false"> 
          <span class="line"></span> <span class="line"></span> <span class="line"></span>
        </div>
        <div class="col-md-12 text-right order-1 order-lg-2 mb-5 mb-lg-0">
          <ul class="navbar-right list-inline float-right mb-0 d-flex">
            <!-- message -->
            <!-- <li class="notification-list list-inline-item d-none d-md-block">
              <div class="form-group search notify-btn mb-0">
                <input type="text" name="keyword" placeholder="Search for..." class="form-control shadow-none border-0" />
                <div class="input-group-prepend">
                  <span class="input-group-text border-0 bg-transparent">
                    <button type="submit" class="bg-transparent border-0"><i class="far fa-search"></i></button>
                  </span>
                </div>
              </div>
            </li> -->
            <!-- notification -->
            <li class="notification-list list-inline-item">
              <div class="notify-btn">
                <a href="#"><i class="far fa-bell"></i> <span class="badge badge-pill badge-danger"></span></a>
              </div>
            </li>
            <!-- Language -->
            <li class="notification-list list-inline-item d-none d-md-block">
              <div class="notify-btn flag">
                <a href="#"><img src="/assets/img/flag-ico.svg" /></a>
              </div>
            </li>
            <li class="dropdown notification-list list-inline-item">
              <div class="dropdown notification-list nav-pro-img">
                <div>
                  <a
                    class="dropdown-toggle nav-link arrow-none nav-user d-flex align-items-center p-0"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="false"
                    aria-expanded="false"
                  >
                    <div>
                      <img src="/assets/img/avatar-1.png" alt="user" class="mr-3" />
                    </div>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right profile-dropdown">
                    <!-- item-->
                    <a class="dropdown-item d-block" href="/settings"><span uk-icon="settings" class="mr-3"></span> Settings</a>
                    <a class="dropdown-item text-danger logout-btn pointer" @click="logout()">
                      <span uk-icon="sign-out" class="mr-3"></span> Logout
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  <div class="clearfix mt-5 mb-5"></div>
  </div>
</template>

<script>
export default {
  methods: {
    async logout() {
      await this.$dialog.alert({
        title: 'Logout',
        message: 'Are you sure you want to logout?',
        confirmButtonText: 'Logout',
        cancelButtonText: 'Cancel',
        showCancelButton: true,
      })
      this.$auth.logout()
      this.$router.push('/login')
    },
  },
}
</script>