<template>
    <div class="col-md-7 mt-0">
        <div class="dashboard home animate relative p-2 mb-2 mt-2">
            <div class="p-1">
            <div class="row">
                <div class="col-md-12 col-sm-12 text-left">
                <h6 class="mt-3 uk-text-bold"> Client Locations </h6>
                </div>
            </div>
            <div class="col-md-12 text-center pt-4 pb-3">
                <div id="worldmap" class="relative" style="width: 100%; height: 400px;"></div>
                <div class="clearfix mt-4">
                <hr>
                </div>
                <div class="row">
                <div class="col-md-12">
                    <div class="value-box relative p-0 m-0 mt-2 pl-2 pb-2">
                    <span class="title uk-text-uppercase"> <i class="fa-duotone fa-location-crosshairs mr-1"></i> Top
                        Location </span>
                    </div>
                </div>
                </div>
                <div class="row">
                <div class="col-md-3">
                    <div class="value-box relative p-0 m-0 mt-2 text-center">
                    <span class="value uk-block"> 1,000 </span>
                    <span class="title uk-text-uppercase uk-block"> Netherland </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="value-box relative p-0 m-0 mt-2 text-center">
                    <span class="value uk-block"> 998 </span>
                    <span class="title uk-text-uppercase uk-block"> France </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="value-box relative p-0 m-0 mt-2 text-center">
                    <span class="value uk-block"> 922 </span>
                    <span class="title uk-text-uppercase uk-block"> Germany </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="value-box relative p-0 m-0 mt-2 text-center">
                    <span class="value uk-block"> 818 </span>
                    <span class="title uk-text-uppercase uk-block"> Thailand </span>
                    </div>
                </div>
                </div>
                <div class="row">
                <div class="col-md-3">
                    <div class="value-box relative p-0 m-0 mt-2 text-center">
                    <span class="value uk-block"> 802 </span>
                    <span class="title uk-text-uppercase uk-block"> Russia </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="value-box relative p-0 m-0 mt-2 text-center">
                    <span class="value uk-block"> 794 </span>
                    <span class="title uk-text-uppercase uk-block"> Chili </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="value-box relative p-0 m-0 mt-2 text-center">
                    <span class="value uk-block"> 793 </span>
                    <span class="title uk-text-uppercase uk-block"> China </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="value-box relative p-0 m-0 mt-2 text-center">
                    <span class="value uk-block"> 643 </span>
                    <span class="title uk-text-uppercase uk-block"> United States </span>
                    </div>
                </div>
                </div>
                <div class="row">
                <div class="col-md-3">
                    <div class="value-box relative p-0 m-0 mt-2 text-center">
                    <span class="value uk-block"> 600 </span>
                    <span class="title uk-text-uppercase uk-block"> Malaysia </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="value-box relative p-0 m-0 mt-2 text-center">
                    <span class="value uk-block"> 599 </span>
                    <span class="title uk-text-uppercase uk-block"> Iran </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="value-box relative p-0 m-0 mt-2 text-center">
                    <span class="value uk-block"> 433 </span>
                    <span class="title uk-text-uppercase uk-block"> United Arab Emirates </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="value-box relative p-0 m-0 mt-2 text-center">
                    <span class="value uk-block"> 412 </span>
                    <span class="title uk-text-uppercase uk-block"> Singapore </span>
                    </div>
                </div>
                </div>
                <div class="text-center mt-4">
                <hr style="margin-top: -10px;">
                <a href="#" class="uk-text-primary"> See all top 100 countries </a>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        $(function () {
            $(document).ready(function () {
                $('#worldmap').vectorMap({
                    map: 'world_en',
                    backgroundColor: 'transparent',
                    color: '#999999',
                    hoverColor: '#1565f8',
                    borderWidth: 0.1,
                    borderOpacity: 1,
                    selectedColor: '#1363f8',
                    enableZoom: true,
                    showTooltip: true,
                    scaleColors: ['#C8EEFF', '#006491'],
                    normalizeFunction: 'linear',
                    markerStyle: {
                        initial: {
                            fill: '#1565f8',
                            stroke: '#1565f8'
                        }
                    },
                    markers: [
                    
                        { latLng: [43.73, 7.41], name: 'Monaco' },
                        { latLng: [-0.52, 166.93], name: 'Nauru' },
                        { latLng: [-8.51, 179.21], name: 'Tuvalu' },
                        { latLng: [43.93, 12.46], name: 'San Marino' },
                        { latLng: [47.14, 9.52], name: 'Liechtenstein' },
                        { latLng: [7.11, 171.06], name: 'Marshall Islands' },
                        { latLng: [17.3, -62.73], name: 'Saint Kitts and Nevis' },
                        { latLng: [3.2, 73.22], name: 'Maldives' },
                        { latLng: [35.88, 14.5], name: 'Malta' },
                        { latLng: [12.05, -61.75], name: 'Grenada' },
                        { latLng: [13.16, -61.23], name: 'Saint Vincent and the Grenadines' },
                        { latLng: [13.16, -59.55], name: 'Barbados' },
                        { latLng: [17.11, -61.85], name: 'Antigua and Barbuda' },
                        { latLng: [-4.61, 55.45], name: 'Seychelles' },
                        { latLng: [7.35, 134.46], name: 'Palau' },
                        { latLng: [42.5, 1.51], name: 'Andorra' },
                        { latLng: [14.01, -60.98], name: 'Saint Lucia' },
                        { latLng: [6.91, 158.18], name: 'Federated States of Micronesia' },
                        { latLng: [1.3, 103.8], name: 'Singapore' },
                        { latLng: [1.46, 173.03], name: 'Kiribati' },
                        { latLng: [-21.13, -175.2], name: 'Tonga' },
                        { latLng: [15.3, -61.38], name: 'Dominica' },
                        { latLng: [-20.2, 57.5], name: 'Mauritius' },
                        { latLng: [26.02, 50.55], name: 'Bahrain' },
                        { latLng: [0.33, 6.73], name: 'São Tomé and Príncipe' }
                    ],
                    onRegionClick: function (event, code, region) { //onRegionOver
                        alert(region)
                        UIkit.modal.dialog("<div class='p-3 uk-text-large uk-text-center'><stong class=' uk-text-bold'>" + region + "</stong><br>" + "<stong class='uk-text-primary uk-text-bold uk-text-large'>Total Clients:" + "200 </strong></div>").then(function () {

                        });

                        if (code == 'TH-50') {
                            alert("You hover " + region);
                            event.preventDefault();
                        }
                    }
                });
            });
        })
    }
}
</script>