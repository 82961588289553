<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative"></div>
      </div>
      <div class="row mt-2">
        <div class="col-md-8 mb-3">
          <ul class="filter d-md-inline-flex d-none">
            <li class="active">
              <a  @click="filterType('')">All</a>
            </li>
            <li>
              <a @click="filterType('bodyguard')">Today's Return</a>
            </li>
            <li>
              <a @click="filterType('')">Today's Pickup</a>
            </li>
            <li>
              <a @click="filterType('')">On Rent</a>
            </li>
            <li>
              <a @click="filterType('')">Completed</a>
            </li>
            <li>
              <a @click="filterType('')">Cancelled</a>
            </li>
          </ul>
        </div>
        <div class="col-md-4 relative">
          <div class="uk-position-bottom-right pr-3">
            <div class="mb-3 text-right">
              <router-link class="mr-2" :to="'/vehicles/'">
                <a class="outline-btn"><i class="fal fa-calendar mr-2"></i>Availability Check</a>
              </router-link>
              <router-link :to="'/new/reservation/1'">
                <a class="outline-btn"><i class="fal fa-calendar-check mr-2"></i>New Reservation</a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table data-table table-round table-hover">
          <thead>
            <tr>
              <th>
                    ID
                    <Sort column="id" />
                  </th>
                  <th>
                    Customer
                    <Sort column="name_en" />
                  </th>
                  <th>
                    Pick-up Date
                  </th>
                  <th>
                    Return Date
                  </th>
                  <th>
                    Pick Up Location
                  </th>
                  <th>
                    Vehicle Class
                  </th>
                  <th>
                    Vehicle
                  </th>
                  <th>
                    Daily Rate
                  </th>
                  <th>
                    Total Price
                  </th>
                  <th>
                    Total Revenue
                  </th>
                  <th>
                    Total Paid
                  </th>
                  <th>
                    Outstanding Balance
                  </th>
                  <th>
                    Status
                  </th>
                  <th>
                    Total Days
                  </th>
                  <!-- <th>
                    Country
                    <Sort column="country" @change="sortcol" />
                  </th> -->
                  <th class="blankhead"></th>
            </tr>
          </thead>
          <tbody>
                <tr v-for="(list, index) in reservationList" :key="index">
                  <td>#{{list.id}}</td>
                  <td>{{showCustomerName(list.customer)}}</td>
                  <td>{{list.pickup_datetime}}</td>
                  <td>{{list.return_datetime}}</td>
                  <td>{{list.pickup_location}}</td>
                  <td>{{list.vehicle.class}}</td>
                  <td>{{list.vehicle.make}}</td>
                  <td class="text-right">{{list.daily_rate}}</td>
                  <td class="text-right">{{list.total_price}}</td>
                  <td class="text-right">{{list.total_revenue}}</td>
                  <td class="text-right">{{list.total_paid}}</td>
                  <td class="text-right">{{list.total_balance}}</td>
                  <td><span class="badge badge-success">{{list.status}}</span></td>
                  <td>{{list.total_days}}</td>
                  <td class="text-right">
                    <a href="#" class="del-btn"><i class="fal fa-eye"></i></a>
                  </td>
                </tr>
             
              </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- PAGINATION -->
      <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
        <jw-pagination
          :items="pages"
          @changePage="onChangePage"
          :pageSize="limit"
          :styles="customStyles"
          :labels="customLabels"
        ></jw-pagination>
      </div>
    </div>
    
  </template>
  
  
  
  <script>

  export default {
      props: {
          companyDetail: {
              id: Number,
              bank_account_no: String,
              bank_owner_name: String,
              bank_name: String,
              bank_branch_code: String,
              bank_book: String,
  
          }
      },
      data() {
          return {
            reservationList: []
          }
      },
      mounted() {
        this.getReservationList()
      },
      
      methods: {
        showCustomerName(customer){
          return customer.fname + ' ' + customer.lname
        },
        getReservationList() {
        const api = 'https://api.615autorental.com/api/admin/reservation'
          const admintoken = localStorage.getItem('auth_token_default')
          const headers = {
            'Authorization': `Bearer ${admintoken}`,
          'Content-Type': 'application/json' // You can adjust content type as needed
        };
        axios
            .get(api,{ headers },{
              params: {
                page:1,
                limit: 30
              }
            })
            .then((response) => {
                this.reservationList = response.data.data
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
      } 
      }
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>