<template>
    <div>
        <!----- Header Title ----->
        <div class="row content-header">
            <div class="col-md-2">
                <Header />
            </div>
            <div class="col-md-10 pt-5 text-right">
                <DateRange @dateChange="dateChange" class="float-right" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 pt-2 pb-4">
                <div class="chart-wrapper p-4 uk-animation-slide-bottom-small">
                    <div class="row">
                        <div class="col-md-12 relative text-right">
                            <h6 class="uk-position-top-left mt-3 ml-4 uk-text-bold"> Utilization </h6>
                            <div class="uk-float-right">
                                <ul class="uk-inline" uk-tab>
                                    <li v-for="(category, categoryIndex) in categories" :key="categoryIndex" :class="selectedCategory == category? 'uk-active' : ''" >
                                        <a href="#" class="no-bullet uk-text-uppercase" @click="selectedCategoryData(category)">{{ category }}</a>
                                    </li>
                                </ul> 
                            </div>
                            <div class="uk-float-right mr-4">
                                <ul class="uk-inline" uk-tab>
                                    <li class="uk-active">
                                        <a href="#" class="no-bullet uk-text-uppercase" @click="typeChange('amount')" > Amount </a>
                                    </li>
                                    <li>
                                        <a href="#" class="no-bullet uk-text-uppercase" @click="typeChange('profit')" > Profit </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-12 mt-4 chart">
                            <canvas id="financial-chart" height="400"></canvas>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    mounted() {
        this.chartData()
    },
    data() {
        return {
            dateFilter:{
                start_date: null,
                end_date: null
            },
            financialChart: null,
            selectedType: 'amount',
            chartbyCategory:[],
            categories: [],
            selectedCategory:'',
            config: {
                responsive: true,
                showTooltips: false,
                inflateAmount: 'auto',
                barPercentage: 0.5,
                maintainAspectRatio: false,
                legend: { 
                    display: false 
                },
                title: {
                    display: false,
                    text: '',
                },
                plugins: {
                    legend: {
                        display: false
                    }
                },
                animations: {
                    tension: {
                        duration: 1000,
                        easing: 'linear',
                        from: 1,
                        to: 0,
                        loop: true
                    }
                },
            },
        }
    },
    methods: {
        dateChange(dateObj){
            this.dateFilter = dateObj
        },
        typeChange(type) {
            this.selectedType = type
            this.chartData()
        },
        chartData() {

            var notyf = new Notyf();
            const api = `admin/dashboard/get_financial_${this.selectedType}`
            axios
                .get(api, {
                    params: {
                       start_date: this.dateFilter.start_date,
                       end_date: this.dateFilter.end_date
                    }
                })
                .then((response) => {
                    this.categories = []

                    if(response.data.success) {
                        let results = response.data.data
                        if(results.length > 0){
                        
                            var groupByCategory = []
                            results.forEach((element) => {
                                if(groupByCategory[element.name_en] != undefined) {
                                    groupByCategory[element.name_en].labels.push(element.year + '/' + element.month_in_word) 
                                    groupByCategory[element.name_en].data.push((this.selectedType == 'amount')? element.total_price : element.total_profit) 
                                }else{
                                    
                                    this.categories.push(element.name_en)
                                    var labels = []
                                    labels.push(element.year + '/' + element.month_in_word)

                                    var data = []
                                    data.push((this.selectedType == 'amount')? element.total_price : element.total_profit)
                                    groupByCategory[element.name_en] = {labels: labels, data: data}
                                }
                            
                            });
                            this.selectedCategory = this.categories.length> 0 ? this.categories[0] : ''

                            this.chartbyCategory = groupByCategory
                            this.selectedCategoryData(this.selectedCategory)
                        }else{
                            notyf.alert('No result');
                            this.destroyChart()
                        }

                    }else{
                        notyf.alert(response.data.message);
                    }
                })
                .catch((error) => {
                    notyf.alert(error.message);
                    console.error('There was an error!', error.message)
                })
        },
        selectedCategoryData(category) {
            this.selectedCategory = category
            this.createChart(this.chartbyCategory[category].labels, this.chartbyCategory[category].data)
        },

        // amountChartUpdate(getLabels, getData) {
        //     this.amountChart.data.datasets[0].data = getData;
        //     this.amountChart.data.labels = getLabels;
        //     this.amountChart.update();
        // },
        // profitChartUpdate(getLabels, getData) {
        //     this.profitChart.data.datasets[0].data = getData;
        //     this.profitChart.data.labels = getLabels;
        //     this.profitChart.update();
        // },
        destroyChart() {
            if(this.financialChart != null) {
                this.financialChart.destroy();
            }
        },
        createChart(getLabels, getData) {
            
            this.destroyChart()

            let ctx = document.getElementById('financial-chart')
            this.financialChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: getLabels,
                    datasets: [
                        {
                            label: 'Financial',
                            backgroundColor: ['#1363f8'],
                            data: getData
                        },
                    ],
                },
                options: this.config
            });
        }
    }
}
</script>