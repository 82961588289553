<template>
    <section class="tpui-container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-12 text-left">
                        <h5 class="mt-1 mb-3 uk-text-bold">
                            Profile
                            <a class="fal edit-btn p-2">
                                <i class="fas fa-edit"></i>
                            </a>
                        </h5>
                    </div>
                </div>
                <div class="view-ui mt-2">
                    <div class="row">
                        <div class="col-md-3 relative pb-5">
                            <div class="profile-img position-relative text-center">
                                <span class="edit-btn">
                                    <input type="file" class="file-upload" id="file" ref="file" v-on:change="handleFileUpload()" />
                                    <i class="fal fa-pen upload-button"></i> Change
                                </span>
                                <img :src="avatar" alt="Border pill" uk-cover>
                            </div>

                        </div>
                        <div class="col-md-9">
                            <div class="p-3">
                                <div class="row p-0 pb-0">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" v-model="profile.first_name" required>
                                            <label>First name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" v-model="profile.last_name" required>
                                            <label>Last name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <select class="form-control" v-model="profile.gender" required>
                                                <option value="male"> Male </option>
                                                <option value="female"> Female </option>
                                            </select>
                                            <label>Gender</label>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group vue-tel-input-wrapper">
                                            <template>
                                                <vue-tel-input v-model="profile.phone_number"></vue-tel-input>
                                                <label>Phone Number</label>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input type="date" class="form-control" v-model="profile.dob" required>
                                            <label> Date of Birth</label>
                                        </div>
                                    </div>
                                
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" v-model="profile.spoken_languages" required>
                                            <label>Spoken Language</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6" style="border-right: 1px solid #e2e2e2">
                            <h5>Working Days</h5>
                            <div class="form-group mb-4">
                                <label class="checkbox mr-2"> <input type="checkbox" checked> Monday </label>
                                <label class="checkbox mr-2"> <input type="checkbox"> Tuesday </label>
                                <label class="checkbox mr-2"> <input type="checkbox"> Wednesday </label>
                                <label class="checkbox mr-2"> <input type="checkbox"> Thursday </label>
                                <label class="checkbox mr-2"> <input type="checkbox"> Friday </label>
                                <label class="checkbox mr-2"> <input type="checkbox"> Saturday </label>
                                <label class="checkbox mr-2"> <input type="checkbox"> Sunday </label>
                            </div>
                        </div>
                        <div class="col-md-6 mb-4">
                            <h5>Experiences</h5>
                            <div class="form-group">
                                <label class="checkbox mr-2"> <input type="checkbox" checked> Supervising  </label>
                                <label class="checkbox mr-2"> <input type="checkbox"> Dog Handling  </label>
                                <label class="checkbox mr-2"> <input type="checkbox"> Driving  </label>
                                <label class="checkbox mr-2"> <input type="checkbox"> Firearm  </label>
                                <label class="checkbox mr-2"> <input type="checkbox"> Baton  </label>
                                <label class="checkbox mr-2"> <input type="checkbox"> Money Transportation  </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <hr />
                        </div>
                        <div class="col-md-12 text-right p-3">
                            <div role="group" class="btn-group mt-2" style="margin-right: -10px;">
                                <button href="#" class="cancel-btn border-0"> <i class="far fa-times-circle mr-2"></i> Cancel
                                </button>
                                <button @click="save" id="client" class="save-btn border-0 ml-2 mr-2"> 
                                    <i class="fal fa-check-circle mr-2"></i> Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    props: {
        addNewGuard: Boolean

    },
	mounted() {
        this.newGuard = this.addNewGuard
		// this.getProfileInfo()
	},

	data() {
		return {
            profile: {
                first_name: 'nnna',
                last_name: 'sdfdsf',
                gender: 'female',
                dob: '1991-10-10',
                phone_number: '34232233',
                spoken_languages: 'th, en'
                

            },
            newGuard: false
			
		}
	},

	methods: {
        getProfileInfo() {

        }
	
	}
}

</script>
