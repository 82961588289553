<template>
	<div v-if="ready">
		<!----- Header Title ----->
		<section class="row">
			<div class="col-md-6">
				<div>
					<h3 class="page-title mb-1">
						{{ this.$route.meta.title }}
					</h3>
				</div>
				<div class="breadcrumb-container">
					<ul class="uk-breadcrumb">
						<li><span>Admin</span></li>
						<li><a href="/missions">Missions</a></li>
						<li><a href="">{{ this.$route.meta.title }}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-md-6 relative"></div>
		</section>
		<section class="tpui-container">
			<div class="row align-items-center mb-4">
				<div class="col-lg-12">
					<div class="grey-box p-3 pt-4">
						<div class="row">
							<div class="col-md-6 text-left">
								<h5 class="mt-1 mb-4 uk-text-bold"> <i class="fas fa-building mr-2"></i> Service
									Provider </h5>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3 relative pb-5">
								<div class="profile-img position-relative text-center">
									<!-- <span class="edit-btn">
										<input type="file" name="company-img" class="file-upload" id="file" ref="file"
											v-on:change="handleFileUpload()" />
										<i class="fal fa-pen upload-button"></i> Change
									</span> -->
									<img src="https://cdn.pixabay.com/photo/2016/03/26/20/35/young-man-1281282_960_720.jpg"
										alt="Border pill" uk-cover>
								</div>
							</div>
							<div class="col-md-9">
								<form id="client">
									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<input type="text" v-model="missionDetails.agency.name_en"
													name="company" value="abc" class="form-control" required>
												<label>Name</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<input type="text" v-model="missionDetails.agency.contact_person"
													name="contactperson" class="form-control" required>
												<label>Contact Person</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group vue-tel-input-wrapper">
												<template>
													<vue-tel-input
														v-model="missionDetails.agency.contact_person_number">
													</vue-tel-input>
													<label>Contact Number</label>
												</template>
											</div>
										</div>
										<!-- <div class="col-md-4">
											<div class="form-group">
												<input type="email" v-model="missionDetails.agency.contact_person_email"
													name="email" class="form-control" required>
												<label>Email</label>
											</div>
										</div> -->
										<div class="col-md-4">
											<div class="form-group">
												<input type="text" v-model="missionDetails.agency.company_registration"
													name="contactperson" class="form-control" required>
												<label>ID Number</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<input type="text" v-model="missionDetails.agency.company_vat"
													name="contactperson" class="form-control" required>
												<label>VAT Number</label>
											</div>
										</div>
									</div>
									<div class="row pt-4 pb-5">
										<div class="col-md-12">
											<h6> Address </h6>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<input type="text" v-model="missionDetails.agency.country" name="state"
													value="India" class="form-control" required>
												<label>Country</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<input type="text" v-model="missionDetails.agency.province" name="state"
													value="Gujarat" class="form-control" required>
												<label>City</label>
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<input type="text" v-model="missionDetails.agency.district" name="city"
													value="Pattaya" class="form-control" required>
												<label>District</label>
											</div>
										</div>
										<div class="col-md-8">
											<div class="form-group">
												<input type="text" v-model="missionDetails.agency.address"
													name="address"
													value="A-22 Indraheights, Nr. Pratham Road, Pattaya , Thailand"
													class="form-control" required>
												<label>Address</label>
											</div>
										</div>
										<div class="col-md-4 text-right">
											<a href="#" id="client" class="edit-btns mt-3 pl-3 pr-3 pt-1"><i class="fas fa-pen mr-2" style="font-size: 13px !important;"></i> Edit </a>
											<!-- <div class="form-group">
												<input type="text" v-model="missionDetails.agency.zip_code"
													name="zipcode" value="332343" class="form-control" required>
												<label>Zip Code</label>
											</div> -->
										</div>
									</div>
								</form>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div class="row align-items-center mb-4">
				<div class="col-lg-12">
					<div class="grey-box p-3 pt-4">
						<div class="row mb-2">
							<div class="col-md-6 text-left">
								<h5 class="mt-1 mb-3 uk-text-bold"> <i class="fa-solid fa-shield-halved mr-2"></i> Object to
									protect </h5>
							</div>
							<div class="col-md-6 text-right">
								<a href="#" id="client" class="edit-btns"><i class="material-icons">save</i> Save</a>
								<a href="#" id="client" class="update-btns"><i class="material-icons">save</i>
									Update</a>
							</div>
						</div>

						<!---- Person 1 --->

						<div class="p-3">
							<div class="row bg-lightblue pt-4" style="border-radius: 20px;">
								<div class="col-md-3 relative pb-5">
										<div class="profile-img position-relative text-center mt-0">
											<!-- <span class="edit-btn">
												<input type="file" name="company-img" class="file-upload" id="file" ref="file"
													v-on:change="handleFileUpload()" />
												<i class="fal fa-pen upload-button"></i> Change
											</span> -->
											<img src="https://cdn.pixabay.com/photo/2016/03/26/20/35/young-man-1281282_960_720.jpg"
												alt="Border pill" uk-cover>
										</div>
								</div>
								<div class="col-md-9">
									<form id="protect">
										<div class="row">
											<div class="col-md-12 mb-1 pt-2">
												<h6 class="uk-text-uppercase uk-text-bold"> Person 1 (Booked For) </h6>
												<hr>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="text" v-model="objectToProtect.first_name" name="fname"
														value="Abc Xyz" class="form-control" required>
													<label>Name</label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="text" v-model="objectToProtect.last_name" name="lname"
														value="patel" class="form-control" required>
													<label>LastName</label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
															<select class="form-control" required>
																<option> Male </option>
																<option> Female </option>
															</select>
															<label>Gender</label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
															<select class="form-control" required>
																<option> Thailand </option>
															</select>
															<label>Nationality</label>
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
															<input type="text" name="company" class="form-control" value="25 December 1980" required>
															<label> Date of Birth</label>
												</div>
											</div>
											<div class="col-md-1 pl-0">
												<div class="form-group">
													<input type="text" name="lname"
														value="57" class="form-control" required>
													<label>Age</label>
												</div>
											</div>
											<div class="col-md-4">
														<div class="form-group vue-tel-input-wrapper">
															<template>
																<vue-tel-input v-model="phone"></vue-tel-input>
																<label>Phone Number</label>
															</template>
														</div>
											</div>
											<div class="clearfix mt-3"></div>
											<div class="col-md-4 pt-3 pb-4">
												<h6 class="mb-0">Physical Issues</h6>
												<div class="row">
													<div class="col-md-4 mb-5">
														<label class="form_radio">
															<input type="radio" id="physical_issues_yes" value="yes" v-model="physical_issues" name="physical_issues">
																<span class="design"></span>
																<span class="text">Yes</span>
														</label>
													</div>
													<div class="col-md-4 mb-5">
														<label class="form_radio">
															<input type="radio" id="physical_issues_no" value="no" v-model="physical_issues" name="physical_issues">
																<span class="design"></span>
																<span class="text">No</span>
														</label>
													</div>
												</div>
												<div class="form-group" style="margin-top:-12px;" v-show="physical_issues == 'yes'">
															<input type="text" name="company" class="form-control" value="Lorem ipsum" required>
															<label> Description </label>
												</div>
											</div>
											<div class="col-md-4 pt-3 pb-4">
												<h6 class="mb-0">Medical Needs</h6>
												<div class="row">
													<div class="col-md-4 mb-5">
														<label class="form_radio">
															<input type="radio" id="medical_needs_y" value="yes" v-model="medical_needs" name="medical_needs">
																<span class="design"></span>
																<span class="text">Yes</span>
														</label>
													</div>
													<div class="col-md-4 mb-5">
														<label class="form_radio">
															<input type="radio" id="medical_needs_n" value="no" v-model="medical_needs" name="medical_needs">
																<span class="design"></span>
																<span class="text">No</span>
														</label>
													</div>
												</div>
												<div class="form-group" style="margin-top:-12px;" v-show="medical_needs == 'yes'">
															<input type="text" name="company" class="form-control" value="Lorem ipsum" required>
															<label> Description </label>
												</div>
											</div>
											<div class="col-md-4 pt-3 pb-4">
												<h6 class="mb-0">Blood Sickness</h6>
												<div class="row">
													<div class="col-md-4 mb-5">
														<label class="form_radio">
															<input type="radio" id="bood_sickness_y" value="yes" v-model="bood_sickness" name="bood_sickness">
																<span class="design"></span>
																<span class="text">Yes</span>
														</label>
													</div>
													<div class="col-md-4 mb-5">
														<label class="form_radio">
															<input type="radio" id="bood_sickness_n" value="no" v-model="bood_sickness" name="bood_sickness">
																<span class="design"></span>
																<span class="text">No</span>
														</label>
													</div>
												</div>
												<div class="form-group" style="margin-top:-12px;" v-show="bood_sickness == 'yes'">
															<input type="text" name="company" class="form-control" value="Lorem ipsum" required>
															<label> Description </label>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>

						<!---- Person 2 --->

						<div class="p-3">
							<div class="row bg-lightblue pt-4" style="border-radius: 20px;">
								<div class="col-md-3 relative pb-5">
										<div class="profile-img position-relative text-center mt-0">
											<!-- <span class="edit-btn">
												<input type="file" name="company-img" class="file-upload" id="file" ref="file"
													v-on:change="handleFileUpload()" />
												<i class="fal fa-pen upload-button"></i> Change
											</span> -->
											<img src="https://cdn.pixabay.com/photo/2016/03/26/20/35/young-man-1281282_960_720.jpg"
												alt="Border pill" uk-cover>
										</div>
								</div>
								<div class="col-md-9">
									<form id="protect">
										<div class="row">
											<div class="col-md-12 mb-1 pt-2">
												<h6 class="uk-text-uppercase uk-text-bold"> Person 2 </h6>
												<hr>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="text" v-model="objectToProtect.first_name" name="fname"
														value="Abc Xyz" class="form-control" required>
													<label>Name</label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<input type="text" v-model="objectToProtect.last_name" name="lname"
														value="patel" class="form-control" required>
													<label>LastName</label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
															<select class="form-control" required>
																<option> Male </option>
																<option> Female </option>
															</select>
															<label>Gender</label>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
															<select class="form-control" required>
																<option> Thailand </option>
															</select>
															<label>Nationality</label>
												</div>
											</div>
											<div class="col-md-3">
												<div class="form-group">
															<input type="text" name="company" class="form-control" value="25 December 1980" required>
															<label> Date of Birth</label>
												</div>
											</div>
											<div class="col-md-1 pl-0">
												<div class="form-group">
													<input type="text" name="lname"
														value="57" class="form-control" required>
													<label>Age</label>
												</div>
											</div>
											<div class="col-md-4">
														<div class="form-group vue-tel-input-wrapper">
															<template>
																<vue-tel-input v-model="phone"></vue-tel-input>
																<label>Phone Number</label>
															</template>
														</div>
											</div>
											<div class="clearfix mt-3"></div>
											<div class="col-md-4 pt-3">
												<h6 class="mb-0">Physical Issues</h6>
												<div class="row">
													<div class="col-md-4 mb-5">
														<label class="form_radio">
															<input type="radio" id="physical_issues_yes" value="yes" v-model="physical_issues" name="physical_issues">
																<span class="design"></span>
																<span class="text">Yes</span>
														</label>
													</div>
													<div class="col-md-4 mb-5">
														<label class="form_radio">
															<input type="radio" id="physical_issues_no" value="no" v-model="physical_issues" name="physical_issues">
																<span class="design"></span>
																<span class="text">No</span>
														</label>
													</div>
												</div>
												<div class="form-group" style="margin-top:-12px;" v-show="physical_issues == 'yes'">
															<input type="text" name="company" class="form-control" value="Lorem ipsum" required>
															<label> Description </label>
												</div>
											</div>
											<div class="col-md-4 pt-3">
												<h6 class="mb-0">Medical Needs</h6>
												<div class="row">
													<div class="col-md-4 mb-5">
														<label class="form_radio">
															<input type="radio" id="medical_needs_y" value="yes" v-model="medical_needs" name="medical_needs">
																<span class="design"></span>
																<span class="text">Yes</span>
														</label>
													</div>
													<div class="col-md-4 mb-5">
														<label class="form_radio">
															<input type="radio" id="medical_needs_n" value="no" v-model="medical_needs" name="medical_needs">
																<span class="design"></span>
																<span class="text">No</span>
														</label>
													</div>
												</div>
												<div class="form-group" style="margin-top:-12px;" v-show="medical_needs == 'yes'">
															<input type="text" name="company" class="form-control" value="Lorem ipsum" required>
															<label> Description </label>
												</div>
											</div>
											<div class="col-md-4 pt-3">
												<h6 class="mb-0">Blood Sickness</h6>
												<div class="row">
													<div class="col-md-4 mb-5">
														<label class="form_radio">
															<input type="radio" id="bood_sickness_y" value="yes" v-model="bood_sickness" name="bood_sickness">
																<span class="design"></span>
																<span class="text">Yes</span>
														</label>
													</div>
													<div class="col-md-4 mb-5">
														<label class="form_radio">
															<input type="radio" id="bood_sickness_n" value="no" v-model="bood_sickness" name="bood_sickness">
																<span class="design"></span>
																<span class="text">No</span>
														</label>
													</div>
												</div>
												<div class="form-group" style="margin-top:-12px;" v-show="bood_sickness == 'yes'">
															<input type="text" name="company" class="form-control" value="Lorem ipsum" required>
															<label> Description </label>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-6 text-left mt-0 mb-4 pl-0">
												<button href="#" class="cancel-btn border-0"><i class="fas fa-trash-alt mr-2"></i> Remove </button>
											</div>
											<div class="col-md-6 text-right mt-0 mb-4">
												<button href="#" class="cancel-btn border-0"> <i class="fas fa-minus-circle mr-2"></i> Cancel </button>
												<button href="#" id="client" class="save-btn border-0 ml-2 mr-2"> <i class="fas fa-save mr-2"></i> Save</button>
											</div>
										</div>
									</form>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row mb-4">
				<div class="col-md-4">
					<div class="grey-box p-3 pt-4">
						<form id="physical">
							<div class="row">
								<div class="col-md-12 mb-3">
									<h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-building mr-2"></i> Physical
										Issues </h5>
									<div class="pt-2">
										<div class="switches-container">
											<input type="radio" id="physical_issue_y" name="physical_issue[type]"
												value="true" checked="checked" required />
											<input type="radio" id="physical_issue_n" name="physical_issue[type]"
												value="false" required />
											<label for="physical_issue_y" id="physical_issue_y"> Yes </label>
											<label for="physical_issue_n" id="physical_issue_n"> No </label>
											<div class="switch-wrapper">
												<div class="switch">
													<div id="physical_issue_y"> Yes </div>
													<div id="physical_issue_n"> No </div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12" v-show="objectToProtect.has_physical_issue" id="pyes">
									<label>Describe your physical issue</label>
									<textarea rows="4"></textarea>
								</div>
							</div>
							<!-- <div class="col-md-12 mb-3 text-left">
									<a href="#" id="medical" class="edit-btns"><i class="material-icons">save</i> Save</a>
									<a href="#" id="medical" class="update-btns"><i class="material-icons">save</i> Update</a>
								</div> -->
						</form>
					</div>
				</div>
				<div class="col-md-4">
					<div class="grey-box p-3 pt-4">
						<form id="physical">
							<div class="row">
								<div class="col-lg-12 mb-3">
									<h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-building mr-2"></i> Medicine
									</h5>
									<div class="pt-2">
										<div class="switches-container">
											<input type="radio" id="medicine_y" name="medicine[type]" value="true"
												checked="checked" required />
											<input type="radio" id="medicine_n" name="medicine[type]" value="false"
												required />
											<label for="medicine_y" id="medicine_y"> Yes </label>
											<label for="medicine_n" id="medicine_n"> No </label>
											<div class="switch-wrapper">
												<div class="switch">
													<div id="medicine_y"> Yes </div>
													<div id="medicine_n"> No </div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12" v-show="objectToProtect.medicine" id="myes">
									<label>Describe your medicine issue</label>
									<textarea rows="4"></textarea>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="col-md-4">
					<div class="grey-box p-3 pt-4">
						<form id="physical">
							<div class="row">
								<div class="col-lg-12 mb-3">
									<h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-building mr-2"></i> Blood
										Sickness </h5>
									<div class="pt-2">
										<div class="switches-container">
											<input type="radio" id="bloodsickness_y" name="bloodsickness[type]"
												value="true" checked="checked" required />
											<input type="radio" id="bloodsickness_n" name="bloodsickness[type]"
												value="false" required />
											<label for="bloodsickness_y" id="bloodsickness_y"> Yes </label>
											<label for="bloodsickness_n" id="bloodsickness_n"> No </label>
											<div class="switch-wrapper">
												<div class="switch">
													<div id="bloodsickness_y"> Yes </div>
													<div id="bloodsickness_n"> No </div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 mt-2" v-show="objectToProtect.blood_sickness" id="byes">
									<div class="form-group">
										<textarea rows="4" id="blood_sickness_desc" class="form-control"
											name="blood_sickness_desc"></textarea>
										<label for="blood_sickness_desc">Describe your Blood Sickness issue</label>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="row mb-4">
				<div class="col-lg-12">
					<div class="grey-box p-3 pt-4">
						<form id="mission">
							<div class="row pt-3">
								<div class="col-md-3 col-6 mb-3">
									<div class="form-group">
										<input type="text" v-model="missionDetails.start_time" name="mstart"
											class="form-control" required>
										<label>Mission Start</label>
									</div>
								</div>
								<div class="col-md-3 col-6 mb-3">
									<div class="form-group">
										<input type="text" v-model="missionDetails.end_time" name="mend"
											class="form-control" required>
										<label>Mission end</label>
									</div>
								</div>
								<div class="col-md-6">
									<div class="row">
										<div class="col-md-4">
											<h6 class="mt-2">Used bodyguard before</h6>
										</div>
										<div class="col-md-8">
											<div>
												<div class="switches-container" style="float:left;">
													<input type="radio" id="use_service_before_y"
														name="use_service_before[type]"
														v-model="missionDetails.use_service_before" value="1"
														required />
													<input type="radio" id="use_service_before_n"
														name="use_service_before[type]"
														v-model="missionDetails.use_service_before" value="0"
														required />
													<label for="use_service_before_y" id="use_service_before_y"> Yes
													</label>
													<label for="use_service_before_n" id="use_service_before_n"> No
													</label>
													<div class="switch-wrapper">
														<div class="switch">
															<div id="use_service_before_y"> Yes </div>
															<div id="use_service_before_n"> No </div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- <div class="form-check-inline mr-4">
												<input type="radio" v-model="missionDetails.use_service_before"  id="before1" name="before1" value="1" >
												<label for="byes" class="pl-0">Yes</label>
											</div>
											<div class="form-check-inline">
												<input type="radio" v-model="missionDetails.use_service_before"    id="before12" name="before1" value="0" >
												<label for="bno" class="pl-0">No</label>
											</div> -->
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" v-model="missionDetails.danger_level"
											name="threatlevel" value="High" required>
										<label>Threat Level </label>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" v-model="missionDetails.reason"
											name="reason" value="Personal" required>
										<label>Reason for need bodyguard</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12 mb-6">
									<div class="form-group">
										<textarea class="form-control" v-model="missionDetails.description" rows="4"
											required></textarea>
										<label>Description </label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" v-model="accomodations.type"
											name="accommodation" value="Hotel" required>
										<label>Accommodation</label>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" v-model="accomodations.address"
											name="address"
											value="312-301 Phetchakasnoi Krathum Baen Samutsakhon 74110 Thailand, 74110"
											required>
										<label>Address</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" v-model="accomodations.name"
											name="aname" value="XYZ" required>
										<label>Name of accommodation</label>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" v-model="accomodations.phone"
											name="aphone" value="+1 0848 9896" required>
										<label>Phone number to accommodation</label>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="row mb-4">
				<div class="col-lg-12">
					<div class="grey-box p-3 pt-4">
						<form id="bodyform">
							<div class="row">
								<div class="col-md-6">
									<div class="hint-field position-relative">
										<div class="form-group">
											<span class="hint-text">+100,000</span>
											<input type="text" class="form-control" name="noguards" value="10" required>
											<label>Number of bodyguards</label>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="hint-field position-relative">
										<div class="form-group">
											<span class="hint-text">+100,000</span>
											<input type="text" class="form-control" name="profile" value="High"
												required>
											<label>Profile</label>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="hint-field position-relative">
										<div class="form-group">
											<span class="hint-text">+0</span>
											<input type="text" class="form-control" name="gender" value="Male" required>
											<label>Gender</label>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="position-relative">
										<div class="form-group">
											<span class="hint-text">+0</span>
											<input type="text" class="form-control" name="workinghours" value="5"
												required>
											<label>hint-field Working hours per day</label>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="hint-field position-relative">
										<div class="form-group">
											<span class="hint-text">+500</span>
											<input type="text" class="form-control" name="thai" value="thai" required>
											<label>Thai or Forienger</label>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="hint-field position-relative">
										<div class="form-group">
											<span class="hint-text">+500</span>
											<input type="text" class="form-control" name="armed" value="Yes" required>
											<label>Armed</label>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" name="prelang" value="Thai" required>
										<label>First preferred language </label>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" name="secondlang" value="Thai" required>
										<label>Second preferred language </label>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="row mb-4">
				<div class="col-lg-12">
					<div class="grey-box p-3 pt-4">
						<form id="transport">
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" name="driver" value="Yes" required>
										<label>Transport with Driver</label>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" name="vehicle"
											value="Mercedez benz D200" required>
										<label>Vehicle</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" name="escot" value="Yes" required>
										<label>Police Escot</label>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input id="datetimepicker1" class="form-control" type="text" name="date"
											value="12-02-2021 09:23 PM" required>
										<label>Date</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" name="fromaddress"
											value="A-22 Indraheights, Nr. Pratham Road, Pattaya , Thailand" required>
										<label>From</label>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" name="toaddress"
											value="A-22 Indraheights, Nr. Pratham Road, Pattaya , Thailand" required>
										<label>To</label>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="row mb-4">
				<div class="col-lg-12">
					<div class="grey-box p-3 pt-4">
						<form id="#">
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" name="calculation" value="" required>
										<label>Calculation</label>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" name="company" value="Company" required>
										<label>Bodyguard Compnay</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" name="commission" value="Yes" required>
										<label>Commission</label>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<input id="datetimepicker1" type="text" class="form-control" name="paymentfee"
											value="12-02-2021 09:23 PM" required>
										<label>Payment Fee</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<input type="text" class="form-control" name="fromaddress"
											value="A-22 Indraheights, Nr. Pratham Road, Pattaya , Thailand" required>
										<label>Customer Pay</label>
									</div>
								</div>
								<div class="col-md-6">
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="row mb-4">
				<div class="col-md-8">
					<div class="total-cost">
						Total Cost : {{ missionDetails.formatted_total_price }}
					</div>
				</div>
				<div class="col-md-4 text-right">
					<div class="dual-btn d-flex align-items-center">
						<a href="#" class="view-btn mr-2 btn-height-normal"><i class="material-icons">email</i> Send the
							Quote</a>
						<a href="#" class="view-btn grey-btn btn-height-normal"><i
								class="material-icons">picture_as_pdf</i> Generate PDF</a>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import Vue from 'vue';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput);

export default {

	mounted() {
		console.log(this.$route.params.id)
		if (this.isValidId(this.$route.params.id)) {
			this.getMissionDetails()
		}
		else {
			this.$router.push('/404')
		}
	},
	data() {
		return {
			params: {
				sort: { column: 'id', type: 'desc' },
			},
			ready: false,
			missionDetails: {},
			objectToProtect: {},
			accomodations: {},
			components: {
				VueTelInput,
			},
			physical_issues: 'yes',
			bood_sickness: 'no',
			medical_needs: 'no',
		}
	},
	methods: {
		getMissionDetails() {
			const mission_id = atob(this.$route.params.id)
			const api = 'https://dev-api.trueprotection.co.th/api/admin/missions/' + mission_id
			const admintoken = localStorage.getItem('auth_token_default')
			axios
				.get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
				.then((response) => {
					this.missionDetails = response.data.data
					this.objectToProtect = this.missionDetails.objects_to_protect[0]
					this.accomodations = this.missionDetails.accomodations[0]
					this.objectToProtect.spokenlang = this.objectToProtect.spoken_languages.join()
					this.ready = true
					console.log(this.missionDetails.objects_to_protect[0].spoken_languages)
				})
				.catch((error) => {
					this.errorMessage = error.message
					console.error('There was an error!', error)
				})
		},
		isValidId(str) {
			try {
				return btoa(atob(str)) == str
			} catch (err) {
				return false
			}
		},
		hideInput() {
			this.checked = !this.checked;
		}
	},
}
</script>