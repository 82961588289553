<template>
    <div class="row align-items-center mb-4">
        <div class="col-lg-12">
          <div class="grey-box p-3 pt-4">

            <div class="row">
              <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold">
                  <i class="fas fa-user-tie mr-2"></i> List of Agents
                </h5>
              </div>
              <div class="col-md-6 text-right">
                <router-link :to="'/company/'+ this.$route.params.id +'/add/agent/'">
                  <a class="outline-btn pt-1" style="height: 40px">
                    <i class="fas fa-plus mr-2" aria-hidden="true"></i>Add
                  </a>
                </router-link>
              </div>
            </div>

            <div class="view-ui mt-4 mb-2">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table data-table table-round table-hover">
                      <thead>
                        <tr>
                          <th scope="col" width="auto">Name</th>
                          <th scope="col">Gender, Age</th>
                          <th scope="col">Location</th>
                          <th scope="col" width="10%" class="text-center">Service Area</th>
                          <th scope="col" width="10%" class="text-right">Mission</th>
                          <th scope="col" width="15%" class="text-center">Rating</th>
                          <th scope="col" width="7%" class="blankhead text-left">Status</th>
                          <th scope="col" width="80px" class="blankhead"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(agent, agnetIndex) in agentLists" :key="agnetIndex">
                          <td>
                            <a>
                              <img :src="agent.avatar" class="uk-border-circle mr-3" width="50px" height="50px" /> 
                              {{ agent.name_en }}
                            </a>
                          </td>
                          <td>
                            {{ agent.gender }}, {{ agent.dob  | ages}}
                          </td>
                          <td>
                            {{ agent.address }}
                          </td>
                          <td class="text-center">
                            {{agent.type}}
                            <!-- <span class="uk-label mr-1">{{ checkService(agent.type) }}</span> -->
                          </td>
                          <td class="text-right">
                            {{ agent.completed_missions }}
                          </td>
                          <td class="text-center">
                            <span class="rating"> 
                              <i class="fas fa-star"></i> {{ agent.rating }} 
                            </span>
                          </td>
                          <td class="text-center relative">
                            <label class="switch">
                              <input type="checkbox" v-model="agentLists[agnetIndex].active" :checked="agent.active" @change="agentActive(agnetIndex)"/>
                              <span class="slider round"></span>
                            </label>
                          </td>
                          <td class="text-right">
                            <div class="action-btn d-flex justify-content-end">
                              <router-link :to="'/company/'+ agent.agency_id + '/edit/agent/' + agent.id">
                                <a class="edit-btn"><i class="fal fa-edit"></i></a>
                              </router-link>
                              <a v-on:click="del_method(agent.id, agent.first_name + ' ' + agent.last_name)" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
    </div>
</template>
<!-- <div class="col-md-12 text-right pt-4 mt-0 mb-4">
    <hr class="mt-2 mb-4" />
    <button href="#" class="cancel-btn border-0">
      <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
    </button>
    <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
      <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
    </button>
  </div> -->
<script>

export default {
    props: {
        companyDetail: {}
    },
    mounted() {
      this.getAgentData()
    },
    data() {
      return {
          agentLists: [],
          requiredfileds:[],
          services:[
            {key: 'vip', text:'VIP Service', value: 'VI'},
            {key:'bodyguard', text:'Bodyguard Service', value: 'BG'},
            {key:'security', text:'Security Service', value: 'SG'},
            {key:'private', text:'Private Investigate', value: 'PI'},
            {key:'education', text:'Education', value: 'ED'},
            {key:'car-rental', text:'Car Rental Service', value: 'CA'},
          ]
      }
    },
    methods: {
      checkService(service) {
        // this.services.filter(function(s){
        //   return service == s.key
        // })
      },
      getAgentData() {
        const api = 'admin/agencies/' + this.$route.params.id + '/staff'
        axios
            .get(api,{
              params: {
                page:1,
                limit: 30
              }
            })
            .then((response) => {
                this.agentLists = response.data.data.records
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
      },
      del_method: function (id, name) {
        let company_id = this.$route.params.id
        let self = this
        UIkit.modal.confirm('Do you want to delete company name: ' + name + " ? ").then(function () {
          console.log('Confirmed.')
          const api = 'admin/agencies/' + company_id + '/staff/' + id
          axios
              .delete(api)
              .then((response) => {
                 self.getAgentData()
              })
              .catch((error) => {
                  console.error('There was an error!', error.message)
              })
        }, function () {
          console.log('Rejected.')
        });
      },
      agentActive(index) {
        const api = 'admin/agencies/' + this.$route.params.company_id + '/staff/' + this.agentLists[index].id
        axios
            .post(api, {active: this.agentLists[index].active} )
            .then((response) => {
                if(response.data.success) {
                    this.success()
                }else{
                    this.requiredfileds = response.data
                }
            })
            .catch((error) => {
                console.error('There was an error!', error.message)
            })
      },
        
    }
}
</script>