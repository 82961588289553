<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative"></div>
      </div>
      <div class="row mt-2">
        <div class="col-md-8">
        </div>
        <div class="col-md-4 relative">
          <div class="uk-position-bottom-right pr-3">
            <div class="mb-3 text-right">
              <router-link :to="'/vehicles/new'">
                <a  class="outline-btn pt-1" style="padding: 5px 10px;">
                    <i class="fas fa-plus mr-2"></i>Add Vehicle
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table data-table table-round table-hover">
          <thead>
            <tr>
              <th>
                    Vehicle Key
                    <Sort column="id" />
                  </th>
                  <th>
                    Vin
                    <Sort column="name_en" />
                  </th>
                  <th>
                    License Plate
                  </th>
                  <th>
                    Status
                  </th>
                  <th>
                    Current Renter
                  </th>
                  <th>
                    Make
                  </th>
                  <th>
                    Vehicle Model
                  </th>
                  <th>
                    Vehicle Class
                  </th>
                  <th>
                    Current Location
                  </th>
                  <th>
                    Available
                  </th>
                  <th>
                    Hardware ID
                  </th>
            </tr>
          </thead>
          <tbody>
                <tr v-for="(list, index) in vehicleList" :key="index">
                  <td><a @click="editVehicle(list.id)" style="color: blue;" >{{ list.id }}</a></td>
                  <td>{{ list.vin }}</td>
                  <td>{{ list.license_plate }}</td>
                  <td>{{ list.status }}</td>
                  <td>{{ list.current_renter }}</td>
                  <td>{{ list.make }}</td>
                  <td>{{ list.model }} {{ list.year_model }}</td>
                  <td>{{ list.class }}</td>
                  <td>{{ list.location }}</td>
                  <td>{{ list.is_available }}</td>
                  <td>{{ list.hardware_id }}</td>
                </tr>
              </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- pagination -->
      <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
            <jw-pagination
              :items="pages"
              @changePage="onChangePage"
              :pageSize="limit"
              :styles="customStyles"
              :labels="customLabels"
            ></jw-pagination>
          </div>
    </div>
    
  </template>
  
  
  
  <script>

  export default {
    props: {
            companyDetail: {
                id: Number,
                bank_account_no: String,
                bank_owner_name: String,
                bank_name: String,
                bank_branch_code: String,
                bank_book: String,
    
            }
        },
        data() {
            return {
              vehicleList: []
            }
        },
        mounted() {
          this.getVehicleList()
        },
        
        methods: {
          editVehicle(id){
            location.href = "/vehicles/"+ id
          },
          getVehicleList() {
          const api = 'https://api.615autorental.com/api/admin/vehicles'
            const admintoken = localStorage.getItem('auth_token_default')
            const headers = {
              'Authorization': `Bearer ${admintoken}`,
            'Content-Type': 'application/json' // You can adjust content type as needed
          };
          axios
              .get(api,{ headers },{
                params: {
                  page:1,
                  limit: 30
                }
              })
              .then((response) => {
                  this.vehicleList = response.data.data
              })
              .catch((error) => {
                  console.error('There was an error!', error.message)
              })
          } ,
          encode(id) {
            return btoa(id)
          },
        }
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>