<template>
    <div>
        <div class="row">
            <div class="col-md-12 text-left">
                <h5 class="mt-1 mb-3 uk-text-bold">
                    <i class="fas fa-user mr-2"></i> Instructor Profile
                </h5>
            </div>
        </div>
        <fieldset class="mt-5">
            <div class="view-ui mt-2">
                <div class="row">
                    <div class="col-md-3 relative pb-5">
                        <div class="profile-img position-relative text-center" style="margin-top: -68px">
                            <div class="img-wrapper">
                                <img v-if="logoUrl" :src="logoUrl"  alt="Company Profile" uk-cover />
                                <span class="edit-btn">
                                    <input
                                        type="file"
                                        name="logo"
                                        class="file-upload"
                                        id="file"
                                        ref="logo"
                                        v-on:change="handleFileUpload()"
                                    />
                                </span>
                            </div>
                            <span class="delete-btn"></span>
                        </div>
                    </div>
                    <div class="col-md-9 relative pt-5">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" name="id_number" class="form-control" v-model="instructorDetail.id_number" required />
                                    <label> ID Number </label>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 pr-0">
                                <div class="row">
                                    <div class="col-sm-7">
                                        <div class="form-group calendar">
                                            <input type="text" name="vat" class="form-control" value="" required />
                                            <label> Date of birth </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-5 pl-0">
                                        <div class="form-group">
                                            <input type="text" name="vat" class="form-control" value="" required />
                                            <label> Age </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group select-dropdown">
                                    <select class="form-control" name="gender" required>
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                    <label>Gender</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" name="tax_id" class="form-control" v-model="instructorDetail.nationality" required />
                                    <label>Nationality</label>
                                </div>
                            </div>
                            <div class="col-md-8 ">
                                <h6 class="mb-0 uk-text-uppercase">Spoken English</h6>
                                <div class="row pl-1">
                                    <div class="col-md-3 col-sm-12 pr-0 pl-1">
                                        <div class="form-group select-dropdown ">
                                            <select name="spoken_languages[]" label="text" required="required"
                                                class="form-control uk-form-small pt-0 bg-blue uk-text-uppercase"
                                                style="font-size: 11px">
                                                <option value="th">Thai</option>
                                                <option value="en">English</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-12 pr-1 pl-1">
                                        <button @click="addLanguage(staffIndex)" class="cancel-btn border-0 mr-0 add-more-btn">
                                            <i class="fas fa-plus mr-1" style="font-size: 10px;"></i> Add more
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="p-3">
                            <div class="row ">
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <input type="text" name="name_en" class="form-control" v-model="instructorDetail.name_en" autofocus required />
                                    <label>First Name (In English)</label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <input type="text" name="name_en" class="form-control" v-model="instructorDetail.name_en" autofocus required />
                                    <label>Last Name (In English)</label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <input type="text" name="name_local" class="form-control" v-model="instructorDetail.name_local" autofocus required />
                                    <label>First Name (In Thai)</label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <input type="text" name="name_local" class="form-control" v-model="instructorDetail.name_local" autofocus required />
                                    <label>Last Name (In Thai)</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <textarea name="description_en" rows="15" class="form-control" v-model="instructorDetail.description_en" required></textarea>
                                        <label for="desc_en"> Description in English </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <textarea name="description_local" rows="15" class="form-control" v-model="instructorDetail.description_local" required></textarea>
                                        <label for="desc_th"> Description in Thai </label>
                                    </div>
                                </div>
                                
                                <div class="col-md-12 mt-3 mb-3">
                                    <hr />
                                    <h6 class="uk-text-uppercase pl-1 uk-text-bold">Address</h6>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="company" class="form-control" value="" required />
                                        <label> Address </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <select class="form-control" name="language" required>
                                        <option>Thailand</option>
                                        </select>
                                        <label>Country</label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <select class="form-control" name="language" required>
                                        <option>Pattaya</option>
                                        </select>
                                        <label>City</label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <select class="form-control" name="language" required>
                                        <option>Banglamung</option>
                                        </select>
                                        <label>District</label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <input type="text" name="company" class="form-control" value="21223" required />
                                        <label> Zip Code </label>
                                    </div>
                                </div>

                                <div class="col-md-12 text-right mt-0 ">
                                    <button href="#" class="cancel-btn border-0">
                                        <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                                    </button>
                                    <button href="#" id="client" class="save-btn border-0 ml-2 mr-2">
                                        <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>

export default {
    mounted() {
        
    },
    data() {
        return {
            instructorDetail: {},
            logoUrl: '',
        }
    },
    methods: {
   
        handleFileUpload() {
            // this.instructorDetail.logo = this.$refs.logo.files[0]
            // this.logoUrl = URL.createObjectURL(this.instructorDetail.logo)
        },
    
    }
}
</script>