<template>
  <div class="container-fluid mb-5">
  <!----- Header Title ----->
    <div class="row relative" style="z-index: 1;">
      <div class="col-md-6">
        <div>
          <h3 class="page-title mb-1">
                {{ this.$route.meta.title }}
          </h3>
        </div>
        <div class="breadcrumb-container">
          <ul class="uk-breadcrumb">
            <li><span>Admin</span></li>
            <li><a href="">{{ this.$route.meta.title }}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 relative uk-animation-slide-bottom-small pt-5">
        <div class="pt-3 text-right">
          <router-link :to="'/add/contract'">
            <a class="outline-btn" style="height: 40px;"><i class="fas fa-plus mr-2"></i>Add</a>
          </router-link>
        </div>
      </div>
      <!-- <div class="col-md-6 relative uk-animation-slide-bottom-small pt-5">
        <div class="pt-3" style="width: 100%; z-index: 1;">
          <div class="form-group select-hidden select-dropdown mb-3">
                            <select
                              name="contactfilter"
                              id="contactfilter"
                              class="form-control"
                              multiple="multiple"
                              style="display: none; width: 100%;"
                            >
                              <option selected value="All Contact">All Contact</option>
                              <option value="TRUE PROTECTION TO CLIENTS">TRUE PROTECTION TO CLIENTS</option>
                              <option value="TRUE PROTECTION TO SERVICE PROVIDERS">TRUE PROTECTION TO SERVICE PROVIDERS</option>
                              <option value="SERVICE PROVIDERS TO CLIENTS">SERVICE PROVIDERS TO CLIENTS</option>
                              <option value="TRUE PROTECTION TO BODYGUARD">TRUE PROTECTION TO BODYGUARD</option>
                              <option value="TRUE PROTECTION TO SECURITY GUARD">TRUE PROTECTION TO SECURITY GUARD</option>
                              <option value="TRUE PROTECTION TO VIP SERVICE">TRUE PROTECTION TO VIP SERVICE</option>
                              <option value="TRUE PROTECTION TO PRIVATE INVESTIGATOR">TRUE PROTECTION TO PRIVATE INVESTIGATOR</option>
                              <option value="TRUE PROTECTION TO EDUCATION">TRUE PROTECTION TO EDUCATION</option>
                              <option value="TRUE PROTECTION TO CAR RENTAL SERVICE"> TRUE PROTECTION TO CAR RENTAL SERVICE</option>
                            </select>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table data-table table-round table-hover uk-animation-slide-bottom-small">
            <thead>
              <tr>
                <th scope="col" width="15%" class="text-left">Latest Version Date</th>
                <th scope="col" width="auto" class="text-left">Type</th>
                <th scope="col" width="auto" class="text-left">Number of Signed</th>
                <th scope="col" width="auto" class="text-left">Number of Versions</th>
                <th scope="col" width="5%" class="blankhead"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="time text-left" style="font-size: 12px">
                  <i class="fal fa-calendar mr-1 relative" style="top: -1px"></i> 14 AUG 2022
                  <span class="end-time ml-1" style="font-size: 11px"> 10:03 </span>
                </td>
                <td class="text-left">
                  "True Protection to Clients" Contract
                </td>
                <td class="text-left">
                  10
                </td>
                <td class="text-left">
                  25
                </td>
                <td class="text-right">
                  <div class="action-btn d-flex justify-content-end">
                    <a class="save-btn download-btn border-0"><i class="fas fa-arrow-to-bottom mr-2" aria-hidden="true"></i> Download PDF </a>
                    <a href="/contract-settings/edit/1" class="edit-btn" style="width: 35px; height: 34px;"><i class="fal fa-edit"></i></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>
export default {
  mounted() {

    /* Multi Selection */
    $(function () {
          $('#contactfilter').bsMultiSelect({
            containerClass: 'dashboardcode-bsmultiselect-large',
          })
    });

  },
  data() {
    return {
     
    }
  },
  methods: {
  }
}
</script>