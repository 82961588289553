<template>
  <div>
        <!----- Header Title ----->
    <div class="row">
      <div class="col-md-6">
        <div>
          <h3 class="page-title mb-1">
                {{ this.$route.meta.title }}
          </h3>
        </div>
        <div class="breadcrumb-container">
          <ul class="uk-breadcrumb">
            <li><span>Admin</span></li>
            <li><a href="">{{ this.$route.meta.title }}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 relative"></div>
    </div>
    <div class="row mt-2">
      <div class="col-md-8">
      </div>
      <div class="col-md-4 relative">
        <div class="uk-position-bottom-right pr-3">
          <div class="mb-3 text-right">
            <router-link :to="'/new/company'">
              <a class="outline-btn pt-1" style="padding: 5px 10px;">
                  <i class="fas fa-plus mr-2"></i>Add Company
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table data-table table-round table-hover">
        <thead>
          <tr>
            <th>
                  Name
                  <Sort column="id" />
                </th>
                <th>
                  Email Address
                  <Sort column="name_en" />
                </th>
                <th>
                  Phone Number
                </th>
                <th>
                  Address
                </th>
                <th>
                  DL Number
                </th>
          </tr>
        </thead>
        <tbody>
              <tr v-for="(list, index) in companyList" :key="index">
                <td>{{list.company_name}}</td>
                <td>{{list.company_email}}</td>
                <td>{{list.phone_number}}</td>
                <td>{{list.location_address}}</td>
                <td>{{list.dl_number}}</td>
              </tr>
              
            </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- PAGINATION -->
    <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
          <jw-pagination
            :items="pages"
            @changePage="onChangePage"
            :pageSize="limit"
            :styles="customStyles"
            :labels="customLabels"
          ></jw-pagination>
        </div>
  </div>
  
</template>



<script>

export default {
    props: {
        companyDetail: {
            id: Number,
            bank_account_no: String,
            bank_owner_name: String,
            bank_name: String,
            bank_branch_code: String,
            bank_book: String,

        }
    },
    mounted() {
      this.getCompanyList()
     //this.showdialog()
    },
    data() {
        return {
          companyList: []
        }
    },
    methods: {
      showdialog(){
        this.$dialog.alert({
            title: 'Failed',
            message: "test",
            showCancelButton: false,
            confirmButtonText: 'OK',
          })
      },
      getCompanyList() {
        const api = 'https://api.615autorental.com/api/admin/copmanies'
          const admintoken = localStorage.getItem('auth_token_default')
          const headers = {
            'Authorization': `Bearer ${admintoken}`,
          'Content-Type': 'application/json' // You can adjust content type as needed
        };
      axios
          .get(api,{ headers },{
            params: {
              page:1,
              limit: 30
            }
          })
          .then((response) => {
              this.companyList = response.data.data
          })
          .catch((error) => {
              console.error('There was an error!', error.message)
          })
    } 
    }
}
</script>

<style scoped>
.card-footer {
  text-align: right;
  position: relative;
  background: #fff;
}
.pagination {
  top: 20px;
  position: absolute;
  right: 0px;
  align-content: flex-end;
  text-align: end;
}
</style>