<template>
	<div>
		<!----- Header Title ----->
		<section class="row">
			<div class="col-md-6">
				<div>
					<h3 class="page-title mb-1">
						{{ this.$route.meta.title }}
					</h3>
				</div>
				<div class="breadcrumb-container">
					<ul class="uk-breadcrumb">
						<li><span>Admin</span></li>
						<li><a href="/missions">Missions</a></li>
						<li><a href="">{{ this.$route.meta.title }}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-md-6 relative"></div>
		</section>
		<section class="tpui-container">

			<template v-if="agency.id > 0">
			<ServiceProvider :agency="agency"></ServiceProvider>
			</template>

			<template v-if="objects_to_protect.length > 0">
			<ObjectToProtect :objects_to_protect="objects_to_protect"></ObjectToProtect>
			</template>

			<template v-if="missionDetail.id > 0">
			<MissionDetail :missionDetail="missionDetail"></MissionDetail>
			</template>
			
		</section>
	</div>
</template>

<script>
import Vue from 'vue';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput);


import ServiceProvider from '@/pages/Mission/Edit/_ServiceProvider'
import ObjectToProtect from '@/pages/Mission/Edit/_ObjectToProtect'
import MissionDetail from '@/pages/Mission/Edit/_MissionDetail'
export default {
	components: {
		ServiceProvider, ObjectToProtect, MissionDetail
	},
	mounted() {
		this.getMissionDetails()
	},
	data() {
		return {
			missionDetail: {},
			agency: {},
			objects_to_protect: [],
			start_date: '',
			end_date: '',
			start_time: '',
			end_time: '',
			// components: {
			// 	VueTelInput,
			// },
		}
	},
	methods: {
		getMissionDetails() {
			const api = 'admin/missions/' + this.$route.params.id
			axios
				.get(api)
				.then((response) => {
					let data = response.data.data
					this.agency = data.agency
					this.objects_to_protect = data.objects_to_protect
					this.missionDetail = data

					this.missionDetail.start_date = data.start_time.split(' ')[0]
					this.missionDetail.start_time = data.start_time.split(' ')[1]
					this.missionDetail.end_date = data.end_time.split(' ')[0]
					this.missionDetail.end_time = data.end_time.split(' ')[1]
				})
				.catch((error) => {
					this.errorMessage = error.message
					console.error('There was an error!', error)
				})
		}
	},
}
</script>

<style>
/* #calendar .ui-datepicker {
	width: 100% !important;
} */

.ui-datepicker-calendar td.booked a {
	background-color: #1362f8;
	border-radius: 7px;
	color: #fff;
	font-weight: 700;
}

/* #calendar .ui-datepicker-calendar td a {
    border: solid 2px #fff;
	margin: 5px 5px 0 0;
} */
.ui-state-default,
.ui-widget-content .ui-state-default {
	font-size: 14.5px;
}

.ui-datepicker table tbody {
	margin-top: 5px;
}

.ui-datepicker {
	border-radius: 12px;
}

.ui-datepicker th {
	padding: 0.8em 0.3em;
	text-align: center;
	font-weight: 600;
	font-size: 13px;
	color: #000000;
	border: 0;
}

span.balance-down {
	color: #e75252;
}

span.balance-up {
	color: #3ea76c;
}

span.balance-down::before,
span.balance-up::before {
	content: "";
	font-family: FontAwesome;
	margin-right: 5px;
	font-size: 8px;
	position: relative;
	top: -1px;
	line-height: 14px;
}

span.balance-down::before {
	content: "\f068";
}

span.balance-up::before {
	content: "\f067";
}

.save-btn.sendmail-btn,
.save-btn.download-btn {
	text-transform: capitalize;
	font-size: 13px !important;
}

.save-btn.sendmail-btn {
	background: transparent;
	border: solid 1px #69aaeb !important;
	color: #565756 !important;
}

.save-btn.download-btn {
	background-color: #69aaeb;
}

.save-btn.download-btn:hover {
	background-color: #4788c9;
}

.save-btn.sendmail-btn:hover {
	background-color: #69aaeb;
	color: #fff !important;
}

fieldset {
	border: solid 1px #dadada;
	border-radius: 15px;
	background: #fafafa;
}
</style>