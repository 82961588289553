<template>
    <div>
        <!----- Header Title ----->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h3 class="page-title mb-1">
                        {{ this.$route.meta.title }}
                    </h3>
                </div>
                <div class="breadcrumb-container">
                    <ul class="uk-breadcrumb">
                        <li><span>Admin</span></li>
                        <li><a href="/reservation">Reservation</a></li>
                        <li><a href="">{{ this.$route.meta.title }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 relative"></div>
        </div>
        <!-- New Reservation -->
        <div class="row align-items-center mb-4">
            <div class="col-lg-12">
                <section class="grey-box register" v-if="!hasSeenCongrats">
                    <div class="register-stepper">
                        <div class="step step-done"><span class="step-number">1</span></div>
                        <div class="step step-done"><span class="step-number">2</span></div>
                        <div class="step step-active"><span class="step-number">3</span></div>
                        <div class="step"><span class="step-number">4</span></div>
                        <div class="step"><span class="step-number">5</span></div>
                        <div class="step"><span class="step-number">6</span></div>
                    </div>

                    <transition name="slide-fade">
                        <section>
                            <form class="form" action="#">
                                <p class="text-uppercase text-sm mb-2">Driver Information</p>
                                <hr class="horizontal dark mt-0" />
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" v-model="driver1" class="form-control" required />
                                            <label>Driver's Name</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" v-model="driver2" class="form-control" required />
                                            <label>Additional Drivers (Optional)</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" v-model="driver3" class="form-control" required />
                                            <label>Additional Drivers (Optional)</label>
                                        </div>
                                    </div>

                                </div>
                                <p class="text-uppercase text-sm mt-4 mb-2">Discount</p>
                                <hr class="horizontal dark mt-0" />
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" v-model="couponCode" class="form-control" required />
                                            <label>Coupon Code</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" v-model="discountValue" class="form-control" required />
                                            <label>Custom Discount Value</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group cta-step mt-4">
                                    <div class="cta prev">
                                        <p class="cta-color">
                                            <span class="link_wrap">
                                                <router-link :to="'/new/reservation/2'">
                                                    <a class="btn prev-btn link_text"><span class="arrow-prev"
                                                            style="padding-right: 5px;"></span>Previous</a>
                                                </router-link>
                                            </span>
                                        </p>
                                    </div>
                                    <div class="cta">
                                        <p class="cta-color">
                                            <span class="text"></span>
                                            <span class="link_wrap">
                                                <a class="link_text btn next-btn" @click="save"
                                                    style="padding-right: 30px!important;">Next</a>
                                                <span class="arrow-next"></span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </section>
                    </transition>
                </section>
            </div>
        </div>


        <!-- Add **uk-toggle="target: #createmodal" in div -->
        <!-- Create success Modal -->
        <div id="createmodal" uk-modal>
            <div class="uk-modal-dialog uk-modal-body p-2 text-center">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div class="row align-items-center mb-4">
                    <div class="col-md-12 mt-4">
                        <h4>Create reservation successful!</h4>
                    </div>
                </div>
                <a @click="next" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
            </div>
        </div>

        <!-- Add **uk-toggle="target: #updatemodal" in div -->
        <!-- Create success Modal -->
        <div id="updatemodal" uk-modal>
            <div class="uk-modal-dialog uk-modal-body p-2 text-center">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div class="row align-items-center mb-4">
                    <div class="col-md-12 mt-4">
                        <h4>Update reservation successful!</h4>
                    </div>
                </div>
                <a @click="next" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
            </div>
        </div>
    </div>

</template>



<script>
import router from '../../routes';

export default {
    el: "#app",
    data: () => {
        return {
            steps: {},
            paymentopt: 'offline',
            step: 1,
            customer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            reservation:{},
            hasSeenCongrats: false,
            driver1: '',
            driver2: '',
            driver3: '',
            couponCode: '',
            discountValue: ''
        };
    },
    mounted() {
        if (Object.prototype.hasOwnProperty.call(this.$route.params, "id")) {
            this.getReservationDetails()
        }
    },
    methods: {
        getReservationDetails(){
            const reservation_id = localStorage.getItem("reservation_id")
            const api = 'https://api.615autorental.com/api/admin/reservation/'+reservation_id+'/details'
            const admintoken = localStorage.getItem('auth_token_default')
            const headers = {
                'Authorization': `Bearer ${admintoken}`,
                'Content-Type': 'application/json' // You can adjust content type as needed
            };
            axios
                .get(api, { headers }, {
                params: {
                    page: 1,
                    limit: 30
                }
                })
                .then((response) => {
                this.reservation = response.data.data;
                
                })
                .catch((error) => {
                console.error('There was an error!', error.message)
                })
        },
        redirectReservation() { // 
            router.push({ path: '/reservation/' })
        },

        next() {
            if (Object.prototype.hasOwnProperty.call(this.$route.params, "id")) {
                location.href = "/reservation/4/edit/" + this.$route.params.id
            } else {
                location.href = "/new/reservation/4"
            }
        },
        save() {
            let driverList = []
            driverList.push(this.driver1)
            driverList.push(this.driver2)
            driverList.push(this.driver3)
            let reservationData = {
                "driver_list": driverList,
                "coupon_code": this.couponCode,
                "discount_value": this.discountValue,
                "discount_type": "Flat"


            }
            const admintoken = localStorage.getItem('auth_token_default')
            const reservation_id = localStorage.getItem("reservation_id")
            if (reservation_id) {
                axios.put(`https://api.615autorental.com/api/admin/reservation/` + reservation_id, reservationData, {
                    headers: {
                        'Authorization': `Bearer ${admintoken}`,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {

                        UIkit.modal('#createmodal').show();
                        console.log('Customer updated successfully:', response.data);
                        // handle success
                    })
                    .catch(error => {
                        alert("Please complete form")
                        console.error('Error updating customer:', error);
                        // handle error
                    });
            } else {
                location.href = "/new/reservation/1"
            }

        },

        newBooking: function () {
            this.hasSeenCongrats = true;
        },
    }

};
</script>

<style scoped>
.card-footer {
    text-align: right;
    position: relative;
    background: #fff;
}

.pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
}
</style>