<template>
    <section class="tpui-container">
        <div class="row align-items-center mb-4">
            <div class="col-lg-12">
                <div class="grey-box p-3 pt-4">
                    <div class="row pl-0 pr-0">
                        <div class="col-md-12 mt-3">
                            <h6 class="uk-text-uppercase pl-1 uk-text-bold"><i class="fas fa-file-alt mr-2"></i> Documents</h6>
                        </div>

                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table data-table table-round table-hover">
                                    <thead>
                                    <tr>
                                        <th width="25%">Type</th>
                                        <th width="25%" class="text-left">Upload Date</th>
                                        <th width="15%" class="text-left">File Name</th>
                                        <th width="20%" class="text-left">Verification</th>
                                        <th class="blankhead" width="auto"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(document, dIndex) in documentLists" :key="dIndex">
                                        <td class="text-left">
                                            {{ document.document_type }}
                                        </td>
                                        <td class="time" style="font-size: 12px">
                                            <i class="fal fa-calendar mr-1 relative" aria-hidden="true" style="top: 1px"></i> 
                                            {{ document.created_at | splitDate }}
                                            <span class="end-time ml-1" style="font-size: 11px"> 
                                                {{ document.created_at | splitTime }} 
                                            </span>
                                        </td>
                                        <td class="text-left">
                                            <p class="pt-0 pb-0 mt-0 mb-0"> {{ document.image }}</p>
                                            <div :id="`edit_dbd_file_${document.id}`" class="collapse">
                                                <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important">
                                                    <div class="row p-0 pb-0 mt-0 mb-0 view-ui">
                                                        <div class="col-md-12 p-0 pb-0 mt-0 mb-0">
                                                            <div class="js-upload uk-placeholder uk-text-center mt-0 mb-0">
                                                                <span uk-icon="icon: cloud-upload" class="mr-2 mt-1"></span>
                                                                <span class="uk-text-middle">Upload or Drag & Drop here</span>
                                                                <div uk-form-custom>
                                                                    <input type="file" @change="updateDoc($event.target.files, dIndex)" />
                                                                </div>
                                                            </div>
                                                            <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            Verify / Reject
                                        </td>
                                        <td class="text-right">
                                            <!-- <span uk-lightbox>
                                                <a
                                                class="view-btn clickable only-icon pl-2 pr-2"
                                                href="https://www.itwsf.com/Portals/0/Holographic-Passport-Film.jpg?ver=1Mjc1ciIZ-jzbuNI7QL1Ow%3D%3D"
                                                data-caption="Passport.jpg"
                                                ></a>
                                            </span> -->
                                            <a class="edit-btn pl-2 pr-2" data-toggle="collapse" v-bind:data-target="`#edit_dbd_file_${document.id}`">
                                                <i class="fal fa-edit"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr v-show="addFile">
                                        <td colspan="5">
                                            <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important">
                                                <div class="row p-0 pb-0 mt-0 mb-0 view-ui">
                                                    <div class="col-md-12 p-0 pb-0 mt-0 mb-0">
                                                        <div class="js-upload uk-placeholder uk-text-center mt-0 mb-0">
                                                            <span uk-icon="icon: cloud-upload" class="mr-2 mt-1"></span>
                                                            <span class="uk-text-middle">Upload or Drag & Drop here</span>
                                                            <div uk-form-custom>
                                                                <input type="file" @change="addNewDoc($event.target.files)" />
                                                            </div>
                                                        </div>
                                                        <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="5">
                                            <a @click="addMore"
                                                href="#"
                                                class="
                                                save-btn
                                                sendmail-btn
                                                border-0
                                                mt-0
                                                text-center
                                                border-radius-default
                                                uk-text-primary uk-text-uppercase
                                                "
                                                style="height: auto; width: auto !important; text-align: left !important"
                                            >
                                                <i class="fas fa-plus mr-1" aria-hidden="true" style="font-size: 12px; display: block"></i> Add more
                                            </a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    
    data() {
        return {
            documentLists: [],
            docUrl:'',
            newdocUrl:'',
            noti:  new Notyf(),
            addFile: false,
            show: []
        }
    },
    mounted() {
        this.getDocumentLists()
    },
    methods: {
        getDocumentLists() {
			const api = `admin/agencies/${this.$route.params.id}/documents`
            axios
                .get(api)
                .then((response) => {
                    if(response.data.success) {
                        this.documentLists = response.data.data
                    }else{
                        this.noti.error(response.data.message)
                    }
                })
                .catch((error) => {
                    this.noti.error(error.message)
                })
        },
        addNewDoc(file, index) {
            // this.documentLists[index].id_card = file[0]
            let formData = new FormData();
            formData.set('image', file[0])
            formData.set('document_type', 'dbd')

            this.newdocUrl = URL.createObjectURL(file[0])
            const api = `admin/agencies/${this.$route.params.id}/documents`
            axios
                .post(api, formData)
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.documentLists.push(response.data.data)
                        this.noti.confirm('Uploaded Successfully!')
                    }else{
                        this.noti.error(response.data.message)
                    }
                })
                .catch((error) => {
                    this.noti.error(error.message)
                })
        },
        updateDoc(file, index) {
            // this.documentLists[index].image = file[0]
            this.docUrl = URL.createObjectURL(file[0])

            let formData = new FormData();
            formData.set('image', file[0])
            formData.set('document_type', 'dbd')

            const api = `admin/agencies/${this.$route.params.id}/documents/${this.documentLists[index].id}`
            axios
                .post(api, formData)
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.documentLists= []
                        this.getDocumentLists()
                        this.noti.confirm('Uploaded Successfully!')
                    }else{
                        this.noti.error(response.data.message)
                    }
                })
                .catch((error) => {
                    this.noti.error(error.message)
                })
        },
        addMore() {
            this.addFile = this.addFile? false: true
        },
        
    }

}
</script>