<template>
  <div>
    <section class="row">
      <div class="col-md-6">
        <div>
          <h3 class="page-title mb-1">
            {{ this.$route.meta.title }}
          </h3>
        </div>
        <div class="breadcrumb-container">
          <ul class="uk-breadcrumb">
            <li><span>Admin</span></li>
            <li><a href="/companies">Company</a></li>
            <li>
              <a href="">{{ this.$route.meta.title }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 relative"></div>
    </section>

    <section class="tpui-container">
      <form @submit.prevent="updateAgentForm" ref="updateAgentForm">
        <div class="row align-items-center mb-4">
          <div class="col-lg-12">
            <div class="grey-box p-3 pt-4">
                <div class="row">
                <div class="col-md-12 text-left">
                    <h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-user mr-2"></i> Agent Profile </h5>
                </div>
                </div>

                <template v-if="callForm">
                    <AgentForm :agentDetail="agentDetail"></AgentForm>
                </template>

                <div class="row pl-3 pr-1">
                    <div class="col-md-12 text-right pt-1 mt-0 mb-4 pb-1">
                    <button class="cancel-btn border-0">
                        <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                    </button>
                    <button type="submit" class="save-btn border-0 ml-2 mr-2">
                        <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                    </button>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import AgentForm from "./AgentForm.vue";
export default {
    components: {
        AgentForm
    },
    data() {
        return {
            agentDetail: {},
            callForm: false
        }
    },
    mounted() {
        this.getAgentDetail()
    },
    methods: {
        getAgentDetail() {
            const api = 'admin/agencies/' + this.$route.params.company_id + '/staff/' + this.$route.params.id
            axios
                .get(api)
                .then((response) => {
                    if(!response.data.success) {
                        // this.$router.push('/404')
                    }
                    this.agentDetail = response.data.data
                    this.callForm = true
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        updateAgentForm() {
            this.loading()
            const api = 'admin/agencies/' + this.$route.params.company_id + '/staff/' + this.$route.params.id 
            axios
                .post(api, this.getFormData(), {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    },
                })
                .then((response) => {
                    if(response.data.success) {
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        getFormData() {
            let form = this.$refs.updateAgentForm;
            let formData = new FormData(form);
            return formData
        },
        loading() {
            this.$toast.loading({
                message: 'Loading...',
                forbidClick: true,
            })
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
            this.$router.push('/company/edit/' + this.$route.params.company_id );
        },
    },
}
</script>