<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative"></div>
      </div>
      <div class="table-responsive">
        <table class="table data-table table-round table-hover">
          <thead>
            <tr>
              <th>
                    Commission Partner
                    <Sort column="id" />
                  </th>
                  <th>
                    Reservation
                    <Sort column="name_en" />
                  </th>
                  <th>
                    Customer
                  </th>
                  <th>
                    Pick-up Date
                  </th>
                  <th>
                    Return Date
                  </th>
                  <th>
                    Total Days
                  </th>
                  <th>
                    Commission Percentage
                  </th>
                  <th>
                    Commission Amount
                  </th>
                  <th>
                    Reservation Status
                  </th>
                  <th>
                    Commission Status
                  </th>
            </tr>
          </thead>
          <tbody>
                <tr>
                  <td>Commission Partner - Invoice</td>
                  <td>19</td>
                  <td>Alene Goldner</td>
                  <td>08-01-2024 12:55</td>
                  <td>28-01-2022 20:00</td>
                  <td>26</td>
                  <td>20.0</td>
                  <td class="text-right">$924.30</td>
                  <td><span class="badge badge-warning">Open</span></td>
                  <td><span class="badge badge-warning">Open</span></td>
                </tr>
                <tr>
                  <td>Commission Partner - Invoice</td>
                  <td>19</td>
                  <td>Alene Goldner</td>
                  <td>08-01-2024 12:55</td>
                  <td>28-01-2022 20:00</td>
                  <td>26</td>
                  <td>20.0</td>
                  <td class="text-right">$924.30</td>
                  <td><span class="badge badge-warning">Open</span></td>
                  <td><span class="badge badge-warning">Open</span></td>
                </tr>
                <tr>
                  <td>Commission Partner - Invoice</td>
                  <td>19</td>
                  <td>Alene Goldner</td>
                  <td>08-01-2024 12:55</td>
                  <td>28-01-2022 20:00</td>
                  <td>26</td>
                  <td>20.0</td>
                  <td class="text-right">$924.30</td>
                  <td><span class="badge badge-warning">Open</span></td>
                  <td><span class="badge badge-warning">Open</span></td>
                </tr>
                <tr>
                  <td>Commission Partner - Invoice</td>
                  <td>19</td>
                  <td>Alene Goldner</td>
                  <td>08-01-2024 12:55</td>
                  <td>28-01-2022 20:00</td>
                  <td>26</td>
                  <td>20.0</td>
                  <td class="text-right">$924.30</td>
                  <td><span class="badge badge-warning">Open</span></td>
                  <td><span class="badge badge-warning">Open</span></td>
                </tr>
                <tr>
                  <td>Commission Partner - Invoice</td>
                  <td>19</td>
                  <td>Alene Goldner</td>
                  <td>08-01-2024 12:55</td>
                  <td>28-01-2022 20:00</td>
                  <td>26</td>
                  <td>20.0</td>
                  <td class="text-right">$924.30</td>
                  <td><span class="badge badge-warning">Open</span></td>
                  <td><span class="badge badge-warning">Open</span></td>
                </tr>
              </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- PAGINATION -->
      <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
            <jw-pagination
              :items="pages"
              @changePage="onChangePage"
              :pageSize="limit"
              :styles="customStyles"
              :labels="customLabels"
            ></jw-pagination>
          </div>
    </div>
    
  </template>
  
  
  
  <script>

  export default {
    
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>