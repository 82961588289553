<template>
  <div>
		<!----- Header Title ----->
		<section class="row">
			<div class="col-md-6">
				<div>
					<h3 class="page-title mb-1">
							{{companyDetails.name_en}}
					</h3>
				</div>
				<div class="breadcrumb-container">
					<ul class="uk-breadcrumb">
						<li><span>Admin</span></li>
						<li><a href="/company">Company</a></li>
						<li><a href="">{{companyDetails.name_en}}</a></li>
					</ul>
				</div>
			</div>
			<div class="col-md-6 relative"></div>
		</section>
                    <section class="tpui-container">
                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-user mr-2"></i> Customer Information </h5>
                              </div>
                              <div class="col-md-6 text-right">
                                <!-- <div class="switches-container w-200 uk-position-right" style="margin-right: 10px;">
                                    <input type="radio" id="active" name="status[type]" value="active" checked="checked" onclick="companyType()" required />
                                    <input type="radio" id="inactive" name="status[type]" value="inactive" onclick="companyType()" required />
                                    <label for="active" id="lbl_active"> Active </label>
                                    <label for="inactive" id="lbl_inactive"> Inactive </label>
                                    <div class="switch-wrapper">
                                      <div class="switch">
                                      <div id="lbl_active"> Active </div>
                                      <div id="lbl_inactive"> Inactive </div>
                                      </div>
                                    </div>
                                  </div> -->
                              </div>
                            </div>
                            <fieldset class="mt-5">
                            <div class="view-ui mt-2">
                              <div class="row">
                                <div class="col-md-3 relative pb-5">
                                  <div class="profile-img position-relative text-center" style="margin-top: -55px;">
                                      <span class="edit-btn">
                                        <input
                                        type="file"
                                        name="company-img"
                                        class="file-upload"
                                        id="file"
                                        ref="file"
                                        v-on:change="handleFileUpload()"
                                        />
                                        <i class="fal fa-pen upload-button"></i> Change
                                      </span>
                                      <img src="https://cdn.pixabay.com/photo/2016/03/26/20/35/young-man-1281282_960_720.jpg" alt="Border pill" uk-cover>
                                  </div>
                                  
                                </div>
                                <div class="col-md-9">
                                  <div class="p-3">
                                    <ul class="dashboard-page">
                                      <li class="one-value default">
                                        <div class="icon">
                                          <i class="far fa-bullseye-arrow"></i>
                                        </div>
                                        <div class="result">
                                          <div class="uk-text-center">
                                              <div class="uk-width-1-1 main">
                                                <span class="title">
                                                  <span class="primary-title"> Mission </span>
                                                  <span class="sub-title"> Cases </span>
                                                </span>
                                                <span class="value count"> 16,520 </span>
                                              </div>
                                          </div>
                                        </div>
                                      </li> 
                                      <li class="one-value default">
                                        <div class="icon">
                                          <i class="fal fa-sack-dollar"></i>
                                        </div>
                                        <div class="result">
                                          <div class="uk-text-center">
                                              <div class="uk-width-1-1 main">
                                                <span class="title">
                                                  <span class="primary-title"> Revenue </span>
                                                  <span class="sub-title"> Net Amount </span>
                                                </span>
                                                <span class="value count"> 16,520 </span>
                                              </div>
                                          </div>
                                        </div>
                                      </li> 
                                      <li class="one-value default">
                                        <div class="icon">
                                          <i class="far fa-chart-line"></i>
                                        </div>
                                        <div class="result">
                                          <div class="uk-text-center">
                                              <div class="uk-width-1-1 main">
                                                <span class="title">
                                                  <span class="primary-title"> Profit </span>
                                                  <span class="sub-title"> Net Amount </span>
                                                </span>
                                                <span class="value count"> 16,520 </span>
                                              </div>
                                          </div>
                                        </div>
                                      </li> 
                                      
                                    </ul>
                                  </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-md-12">
                                  <div class="p-3">
                                    <h6 class="uk-text-uppercase pl-1 uk-text-bold">Information</h6>
                                    <div class="row p-0 pb-0">
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <input type="text" name="company" class="form-control" value="TrueProtection Thailand" required>
                                          <label>Name (In English)</label>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <input type="text" name="company" class="form-control" value="ทรูโพเทคชั่น ประเทศไทย" required>
                                          <label>Name (In Thai)</label>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <textarea rows="15" name="desc_en" id="desc_en" class="form-control" required></textarea>
                                          <label for="desc_en"> Description in English </label>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="form-group">
                                          <textarea rows="15" name="desc_th" id="desc_th" class="form-control" required></textarea>
                                          <label for="desc_th"> Description in Thai </label>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <input type="text" name="company" class="form-control" value="DOB1234567890" required>
                                          <label> Company ID Number </label>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <input type="text" name="company" class="form-control" value="1234567890" required>
                                          <label> VAT Number </label>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <select class="form-control" name="language" required>
                                            <option> 2022 </option>
                                            <option> 2021 </option>
                                            <option> 2020 </option>
                                          </select>
                                          <label>Founded</label>
                                        </div>
                                      </div>
                                      
                                      <div class="col-md-12 mt-3 mb-3"> <hr> <h6 class="uk-text-uppercase pl-1 mb-2 uk-text-bold">Service Area</h6> </div>

                                      <div class="col-md-12 mt-0 mb-0 pr-0 select-gradient-bg">
                                        <div class="form-group select-dropdown uk-inline uk-float-left">
                                          <select required="required" class="form-control pt-0 bg-blue mr-2" style="font-size: 12px; min-width: 150px;">
                                            <option value="VIP Service"> VIP Service </option>
                                          </select>
                                        </div>
                                        <div class="form-group select-dropdown uk-inline uk-float-left">
                                          <select required="required" class="form-control pt-0 bg-blue mr-2" style="font-size: 12px; min-width: 150px;">
                                            <option value="Bodyguard"> Bodyguard </option>
                                          </select>
                                        </div>
                                        <div class="form-group select-dropdown uk-inline uk-float-left">
                                          <select required="required" class="form-control pt-0 bg-blue mr-2" style="font-size: 12px; min-width: 150px;">
                                            <option value="Private Investigate"> Private Investigate </option>
                                          </select>
                                        </div>
                                        <div class="form-group uk-inline uk-float-left">
                                          
                                        </div>
                                      </div>



                                      <div class="col-md-12 mt-3 mb-3"> <hr> <h6 class="uk-text-uppercase pl-1 uk-text-bold">Address</h6> </div>

                                      <div class="col-md-12">
                                        <div class="form-group">
                                          <input type="text" name="company" class="form-control" value="" required>
                                          <label> Address </label>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <select class="form-control" name="language" required>
                                            <option> Thailand </option>
                                          </select>
                                          <label>Country</label>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <select class="form-control" name="language" required>
                                            <option> Pattaya </option>
                                          </select>
                                          <label>City</label>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <select class="form-control" name="language" required>
                                            <option> Banglamung </option>
                                          </select>
                                          <label>District</label>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <input type="text" name="company" class="form-control" value="21223" required>
                                          <label> Zip Code </label>
                                        </div>
                                      </div>
                                     
                                     <div class="col-md-12 mt-3 mb-3"> <hr> <h6 class="uk-text-uppercase pl-1 uk-text-bold"> <i class="fas fa-user mr-2"></i> Key Profile</h6> </div>


                                      <div class="col-md-12">
                                        <div class="table-responsive">
                                          
                                        </div>
                                        <!-- <div class="row">

                                          <div class="col-md-3">
                                            <div class="preview-file">
                                              <div class="input-group">
                                                <div class="form-group">
                                                  <input type="text" name="name" class="form-control" value="David Carl" required>
                                                  <label for=""> Person 1 </label>
                                                </div>
                                                <div class="form-group">
                                                  <select name="position" required><option>Chirman</option></select>
                                                  <label for=""> Position </label>
                                                </div>
                                              </div>
                                              <div class="file mt-3">
                                                <div class="row">
                                                  <div class="col-md-4">
                                                    <img src="https://data.unhcr.org/images/documents/big_4cda85d892a5c0b5dd63b510a9c83e9c9d06e739.jpg">
                                                  </div>
                                                  <div class="col-md-8">
                                                    <div class="row mt-3">
                                                      <div class="col-md-8">
                                                        <span class="filename"> Kasikorn Bank.jpg </span>
                                                      </div>
                                                      <div class="col-md-4" style="padding-right: 18px;">
                                                        <a href="#" class="del-btn"><i data-v-8ace0cfc="" class="fal fa-trash-alt" aria-hidden="true"></i></a>
                                                      </div>
                                                      <div class="col-md-6 pr-1">
                                                        <a href="" class="view-btn pl-3 pr-3 mt-3 w-100"> View</a>
                                                      </div>
                                                      <div class="col-md-6 pl-1">
                                                        <a href="#" class="edit-btns pl-3 pr-3 mt-3 w-100" style="float: right;"><i class="fas fa-pen mr-2" style="font-size: 13px !important;"></i> Edit </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-md-3">
                                            <div class="preview-file">
                                              <div class="input-group">
                                                <div class="form-group">
                                                  <input type="text" name="name" class="form-control" value="David Carl" required>
                                                  <label for=""> Person 1 </label>
                                                </div>
                                                <div class="form-group">
                                                  <select name="position" required><option>Chirman</option></select>
                                                  <label for=""> Position </label>
                                                </div>
                                              </div>
                                              <div class="file mt-3">
                                                <div class="row">
                                                  <div class="col-md-4">
                                                    <img src="https://data.unhcr.org/images/documents/big_4cda85d892a5c0b5dd63b510a9c83e9c9d06e739.jpg">
                                                  </div>
                                                  <div class="col-md-8">
                                                    <div class="row mt-3">
                                                      <div class="col-md-8">
                                                        <span class="filename"> Kasikorn Bank.jpg </span>
                                                      </div>
                                                      <div class="col-md-4" style="padding-right: 18px;">
                                                        <a href="#" class="del-btn"><i data-v-8ace0cfc="" class="fal fa-trash-alt" aria-hidden="true"></i></a>
                                                      </div>
                                                      <div class="col-md-6 pr-1">
                                                        <a href="" class="view-btn pl-3 pr-3 mt-3 w-100"> View</a>
                                                      </div>
                                                      <div class="col-md-6 pl-1">
                                                        <a href="#" class="edit-btns pl-3 pr-3 mt-3 w-100" style="float: right;"><i class="fas fa-pen mr-2" style="font-size: 13px !important;"></i> Edit </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-md-3">
                                            <div class="preview-file">
                                              <div class="input-group">
                                                <div class="form-group">
                                                  <input type="text" name="name" class="form-control" value="David Carl" required>
                                                  <label for=""> Person 1 </label>
                                                </div>
                                                <div class="form-group">
                                                  <select name="position" required><option>Chirman</option></select>
                                                  <label for=""> Position </label>
                                                </div>
                                              </div>
                                              <div class="file mt-3">
                                                <div class="row">
                                                  <div class="col-md-4">
                                                    <img src="https://data.unhcr.org/images/documents/big_4cda85d892a5c0b5dd63b510a9c83e9c9d06e739.jpg">
                                                  </div>
                                                  <div class="col-md-8">
                                                    <div class="row mt-3">
                                                      <div class="col-md-8">
                                                        <span class="filename"> Kasikorn Bank.jpg </span>
                                                      </div>
                                                      <div class="col-md-4" style="padding-right: 18px;">
                                                        <a href="#" class="del-btn"><i data-v-8ace0cfc="" class="fal fa-trash-alt" aria-hidden="true"></i></a>
                                                      </div>
                                                      <div class="col-md-6 pr-1">
                                                        <a href="" class="view-btn pl-3 pr-3 mt-3 w-100"> View</a>
                                                      </div>
                                                      <div class="col-md-6 pl-1">
                                                        <a href="#" class="edit-btns pl-3 pr-3 mt-3 w-100" style="float: right;"><i class="fas fa-pen mr-2" style="font-size: 13px !important;"></i> Edit </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-md-3">
                                            <div class="preview-file">
                                              <div class="input-group">
                                                <div class="form-group">
                                                  <input type="text" name="name" class="form-control" value="David Carl" required>
                                                  <label for=""> Person 1 </label>
                                                </div>
                                                <div class="form-group">
                                                  <select name="position" required><option>Chirman</option></select>
                                                  <label for=""> Position </label>
                                                </div>
                                              </div>
                                              <div class="file mt-3">
                                                <div class="row">
                                                  <div class="col-md-4">
                                                    <img src="https://data.unhcr.org/images/documents/big_4cda85d892a5c0b5dd63b510a9c83e9c9d06e739.jpg">
                                                  </div>
                                                  <div class="col-md-8">
                                                    <div class="row mt-3">
                                                      <div class="col-md-8">
                                                        <span class="filename"> Kasikorn Bank.jpg </span>
                                                      </div>
                                                      <div class="col-md-4" style="padding-right: 18px;">
                                                        <a href="#" class="del-btn"><i data-v-8ace0cfc="" class="fal fa-trash-alt" aria-hidden="true"></i></a>
                                                      </div>
                                                      <div class="col-md-6 pr-1">
                                                        <a href="" class="view-btn pl-3 pr-3 mt-3 w-100"> View</a>
                                                      </div>
                                                      <div class="col-md-6 pl-1">
                                                        <a href="#" class="edit-btns pl-3 pr-3 mt-3 w-100" style="float: right;"><i class="fas fa-pen mr-2" style="font-size: 13px !important;"></i> Edit </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-md-3">
                                            <div class="preview-file mt-4">
                                              <div class="input-group">
                                                <div class="form-group">
                                                  <input type="text" name="name" class="form-control" value="David Carl" required>
                                                  <label for=""> Person 1 </label>
                                                </div>
                                                <div class="form-group">
                                                  <select name="position" required><option>Chirman</option></select>
                                                  <label for=""> Position </label>
                                                </div>
                                              </div>
                                              <div class="file mt-3">
                                                <div class="row">
                                                  <div class="col-md-4">
                                                    <img src="https://data.unhcr.org/images/documents/big_4cda85d892a5c0b5dd63b510a9c83e9c9d06e739.jpg">
                                                  </div>
                                                  <div class="col-md-8">
                                                    <div class="row mt-3">
                                                      <div class="col-md-8">
                                                        <span class="filename"> Kasikorn Bank.jpg </span>
                                                      </div>
                                                      <div class="col-md-4" style="padding-right: 18px;">
                                                        <a href="#" class="del-btn"><i data-v-8ace0cfc="" class="fal fa-trash-alt" aria-hidden="true"></i></a>
                                                      </div>
                                                      <div class="col-md-6 pr-1">
                                                        <a href="" class="view-btn pl-3 pr-3 mt-3 w-100"> View</a>
                                                      </div>
                                                      <div class="col-md-6 pl-1">
                                                        <a href="#" class="edit-btns pl-3 pr-3 mt-3 w-100" style="float: right;"><i class="fas fa-pen mr-2" style="font-size: 13px !important;"></i> Edit </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-md-3">
                                            <a href="#" class="save-btn sendmail-btn border-0 mt-4" style="width: 100% !important; height: 45px; text-align: left !important;">
                                              <i class="fas fa-plus mr-2" style="font-size: 15px;"></i> Add New 
                                            </a>
                                          </div>

                                        </div> -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </fieldset>

                            <fieldset style="background-color: rgb(237 243 255); border: dashed 2px rgb(173 199 250);">
                                <div class="row pl-3 pr-1">

                                      <!---- Company Contact ---->

                                      <div class="col-md-12 mt-3 mb-3 pt-3"> <h6 class="uk-text-uppercase pl-1 uk-text-bold"> <i class="fas fa-address-book mr-2"></i> Company Contact </h6> </div>

                                      <div class="col-md-12 mb-3">
                                        <div class="row">
                                          <div class="col-md-3">
                                            <div class="form-group">
                                              <input type="text" name="company" class="form-control" value="Pongchana U." required>
                                              <label>Contact Person</label>
                                            </div>
                                          </div>
                                          <div class="col-md-3">
                                            <div class="form-group">
                                              <input type="text" name="company" class="form-control" value="Support" required>
                                              <label>Position</label>
                                            </div>
                                          </div>
                                          <div class="col-md-3">
                                            <div class="form-group vue-tel-input-wrapper">
                                              <template>
                                                <vue-tel-input v-model="phone"></vue-tel-input>
                                                <label>Phone Number</label>
                                              </template>
                                            </div>
                                          </div>
                                          <div class="col-md-3">
                                            <div class="form-group">
                                              <input type="text" name="company" class="form-control" value="schiller.dorcas@borer.com" required>
                                              <label> Email </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                </div>
                            </fieldset>

                            <!---- DBD Document ---->

                            <fieldset>
                                <div class="row pl-3 pr-1">

                                      <div class="col-md-12 mt-3 pt-3"> <h6 class="uk-text-uppercase pl-1 uk-text-bold"><i class="fas fa-file-alt mr-2"></i> DBD Document </h6> </div>

                                      <div class="col-md-3">
                                            <div class="preview-file mb-4">
                                              <div class="file mt-3">
                                                <div class="row">
                                                  <div class="col-md-4">
                                                    <img src="https://data.unhcr.org/images/documents/big_4cda85d892a5c0b5dd63b510a9c83e9c9d06e739.jpg">
                                                  </div>
                                                  <div class="col-md-8">
                                                    <div class="row mt-3">
                                                      <div class="col-md-8">
                                                        <span class="filename"> DBD.jpg </span>
                                                      </div>
                                                      <div class="col-md-4" style="padding-right: 18px;">
                                                        <a href="#" class="del-btn"><i data-v-8ace0cfc="" class="fal fa-trash-alt" aria-hidden="true"></i></a>
                                                      </div>
                                                      <div class="col-md-6 pr-1">
                                                        <a href="" class="view-btn pl-3 pr-3 mt-3 w-100"> View</a>
                                                      </div>
                                                      <div class="col-md-6 pl-1">
                                                        <a href="#" class="edit-btns pl-3 pr-3 mt-3 w-100" style="float: right;"><i class="fas fa-pen mr-2" style="font-size: 13px !important;"></i> Edit </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                      </div>
                                      <div class="col-md-4">
                                        <a href="#" class="save-btn sendmail-btn border-0 mt-4 text-center" style="height: 95px; width: 80px !important; text-align: left !important;">
                                          <i class="fas fa-plus mr-1" style="font-size: 15px; display: block;"></i> Add
                                        </a>
                                      </div>
                                </div>
                            </fieldset>
                            

                            <!---- Previous Invoice ---->

                            <fieldset>
                                <div class="row pl-3 pr-1">

                                      <div class="col-md-12 mt-3 pt-3"> <h6 class="uk-text-uppercase pl-1 uk-text-bold"><i class="fas fa-file-alt mr-2"></i> Previous Invoice </h6> </div>

                                      <div class="col-md-3">
                                            <div class="preview-file mb-4">
                                              <div class="file mt-3">
                                                <div class="row">
                                                  <div class="col-md-4">
                                                    <img src="https://data.unhcr.org/images/documents/big_4cda85d892a5c0b5dd63b510a9c83e9c9d06e739.jpg">
                                                  </div>
                                                  <div class="col-md-8">
                                                    <div class="row mt-3">
                                                      <div class="col-md-8">
                                                        <span class="filename"> #00001 Invoice.jpg </span>
                                                      </div>
                                                      <div class="col-md-4" style="padding-right: 18px;">
                                                        <a href="#" class="del-btn"><i data-v-8ace0cfc="" class="fal fa-trash-alt" aria-hidden="true"></i></a>
                                                      </div>
                                                      <div class="col-md-6 pr-1">
                                                        <a href="" class="view-btn pl-3 pr-3 mt-3 w-100"> View</a>
                                                      </div>
                                                      <div class="col-md-6 pl-1">
                                                        <a href="#" class="edit-btns pl-3 pr-3 mt-3 w-100" style="float: right;"><i class="fas fa-pen mr-2" style="font-size: 13px !important;"></i> Edit </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                      </div>
                                      <div class="col-md-4">
                                        <a href="#" class="save-btn sendmail-btn border-0 mt-4 text-center" style="height: 95px; width: 80px !important; text-align: left !important;">
                                          <i class="fas fa-plus mr-1" style="font-size: 15px; display: block;"></i> Add
                                        </a>
                                      </div>
                                </div>
                            </fieldset>
                              <div class="col-md-12 text-right pt-1 mt-0 mb-4">
                                  <hr class="mt-2 mb-4">
                                  <button href="#" class="cancel-btn border-0"><i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel </button>
                                  <button href="#" id="client" class="save-btn border-0 ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
                              </div>
                          </div>
                          
                        </div>
                      </div>
                    </section>

                    <section class="tpui-container">
                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box pt-3">
                            <div class="col-md-12">
                                <div class="pt-2">		
                                  <div class="row">
                                    <div class="col-md-6 text-left">
                                      <h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-map-marker-alt mr-2"></i> Company Address </h5>
                                    </div>
                                    <div class="col-md-6 text-right"></div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="table-responsive">
                                        <table class="table data-table table-round table-hover">
                                          <thead>
                                          <tr>
                                            <!-- <th>
                                              ID
                                            </th> -->
                                            <th>
                                              
                                            </th>
                                            <th>
                                              Address
                                            </th>
                                            <th>
                                              Province
                                            </th>
                                            <th>
                                              Country
                                            </th>
                                            <th class="blankhead" width="100px">

                                            </th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <!-- <td>1</td> -->
                                              <td class="text-center">
                                                <i uk-icon="home"></i>
                                              </td>
                                              <td>
                                                10/31 Soi Sukhumvit 13, Khlong Toei Nuea
                                              </td>
                                              <td>
                                                Bangkok
                                              </td>
                                              <td>
                                                Thailand
                                              </td>
                                              <td class="text-right">
                                                <a class="view-btn clickable" data-toggle="collapse" data-target="#latest_address_1">Map View</a>
                                                <a class="edit-btn" href="#modal-edit-address" uk-toggle><i class="fal fa-edit"></i></a>
                                                <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colspan="9">
                                                <div id="latest_address_1" class="collapse">
                                                  <form>
                                                  <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important;">
                                                    <div class="row p-0 pb-0 mt-3 view-ui">
                                                      <div class="col-md-12">
                                                        <div>
                                                          <h6 class="title mb-3"><i class="fas fa-map-marker-alt mr-1"></i> Full Address</h6>
                                                          <p class="result m-0 mb-4"> 10/31 Soi Sukhumvit 13, Khlong Toei Nuea, Bangkok, Thailand 10291 </p>
                                                        </div>
                                                      </div>
                                                      <div class="col-md-12">
                                                        <div class="gmap-iframe ratio ratio-4x3 ratio-sm-21x9" lc-helper="gmap-embed">
                                                          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3875.6647443463908!2d100.5592540929188!3d13.73873605017498!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x524dad67eb707a4b!2sThe%20Trendy%20Office%20Building!5e0!3m2!1sen!2sth!4v1646302645610!5m2!1sen!2sth" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                                                        </div>
                                                      </div>
                                                      <div class="col-md-12 pt-4">
                                                        <div class="uk-margin">
                                                          <div class="uk-inline uk-width-1-1">
                                                            <span class="uk-form-icon" uk-icon="icon: search"></span>
                                                            <input class="uk-input uk-width-1-1" type="text" required>
                                                            <label class="ml-5"> Search Address </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="col-md-3">
                                                        <div class="form-group select-box">
                                                          <select class="form-control" required>
                                                            <option> -- Select -- </option>
                                                          </select>
                                                          <label>Province</label>
                                                        </div>
                                                      </div>
                                                      <div class="col-md-3">
                                                        <div class="form-group">
                                                          <select class="form-control" required>
                                                            <option> -- Select -- </option>
                                                          </select>
                                                          <label>District</label>
                                                        </div>
                                                      </div>
                                                      <div class="col-md-3">
                                                        <div class="form-group">
                                                          <select class="form-control" required>
                                                            <option> -- Select -- </option>
                                                          </select>
                                                          <label>Sub District</label>
                                                        </div>
                                                      </div>
                                                      <div class="col-md-3">
                                                        <div class="form-group">
                                                          <input type="text" name="search" class="form-control" required>
                                                          <label> Zip code </label>
                                                        </div>
                                                      </div>
                                                      <div class="col-md-9">
                                                        <div class="uk-margin">
                                                          <div class="uk-inline uk-width-1-1">
                                                            <span class="uk-form-icon" uk-icon="icon: location"></span>
                                                            <input class="uk-input uk-width-1-1" type="text" required>
                                                            <label class="ml-5"> Address </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="col-md-3 text-right">
                                                        <div role="group" aria-label="Basic example" class="btn-group mt-2">
                                                          <a href="#" class="cancel-btn"> <i class="fas fa-minus-circle mr-2"></i> Cancel </a>
                                                          <a href="#" id="client" class="save-btn ml-2 mr-2"> <i class="fas fa-save mr-2"></i> Save</a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  </form>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="modal-edit-address" uk-modal>
                                    <div class="uk-modal-dialog">
                                      <button class="uk-modal-close-default" type="button" uk-close></button>
                                      <div class="uk-modal-header">
                                        <h2 class="uk-modal-title">Edit Addresses</h2>
                                      </div>
                                      <div class="uk-modal-body">
                                        <div class="row">
                                          <div class="col-md-6 text-left">
                                            Labor
                                          </div>
                                          <div class="col-md-6 text-left">
                                            <div class="form-group">
                                              <input type="text" class="form-control" value="">
                                              <label></label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="uk-modal-footer">
                                        <a href="#" class="delete-btns large uk-modal-close uk-float-right"><i class="fas fa-times-circle mr-2"></i> Cancel </a>
                                        <a href="#" id="client" class="edit-btns large uk-float-right mr-1"><i class="material-icons">save</i> Save</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <fieldset style="border:none; background: none;">
                                    <div class="row pl-2 pr-1">

                                          <div class="col-md-12 mt-3 pt-3"> <h6 class="uk-text-uppercase pl-1 uk-text-bold"><i class="fas fa-file-alt mr-2"></i> Document to verify location </h6> </div>

                                          <div class="col-md-3">
                                                <div class="preview-file mb-4">
                                                  <div class="file mt-3">
                                                    <div class="row">
                                                      <div class="col-md-4">
                                                        <img src="https://data.unhcr.org/images/documents/big_4cda85d892a5c0b5dd63b510a9c83e9c9d06e739.jpg">
                                                      </div>
                                                      <div class="col-md-8">
                                                        <div class="row mt-3">
                                                          <div class="col-md-8">
                                                            <span class="filename">  Address.jpg </span>
                                                          </div>
                                                          <div class="col-md-4" style="padding-right: 18px;">
                                                            <a href="#" class="del-btn"><i data-v-8ace0cfc="" class="fal fa-trash-alt" aria-hidden="true"></i></a>
                                                          </div>
                                                          <div class="col-md-6 pr-1">
                                                            <a href="" class="view-btn pl-3 pr-3 mt-3 w-100"> View</a>
                                                          </div>
                                                          <div class="col-md-6 pl-1">
                                                            <a href="#" class="edit-btns pl-3 pr-3 mt-3 w-100" style="float: right;"><i class="fas fa-pen mr-2" style="font-size: 13px !important;"></i> Edit </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                          </div>
                                          <div class="col-md-4">
                                            <a href="#" class="save-btn sendmail-btn border-0 mt-4 text-center" style="height: 95px; width: 80px !important; text-align: left !important;">
                                              <i class="fas fa-plus mr-1" style="font-size: 15px; display: block;"></i> Add
                                            </a>
                                          </div>
                                    </div>
                                </fieldset>
                              </div>
                              <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                                <hr class="mt-2 mb-4">
                                <button href="#" class="cancel-btn border-0"><i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel </button>
                                <button href="#" id="client" class="save-btn border-0 ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
                              </div>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-university mr-2"></i> Bank Detail </h5>
                              </div>
                            </div>
                            <div class="view-ui mt-4 mb-2">
                              <div class="row">
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <select class="form-control" name="language" required>
                                      <option> Kasikorn Bank </option>
                                    </select>
                                    <label>Bank</label>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <input type="text" name="company" class="form-control" value="ทรูโพเทคชั่น ประเทศไทย" required>
                                    <label>Account Number</label>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <input type="text" name="company" class="form-control" value="ทรูโพเทคชั่น ประเทศไทย" required>
                                    <label>Owner Name</label>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <input type="text" name="company" class="form-control" value="ทรูโพเทคชั่น ประเทศไทย" required>
                                    <label>Branch Code</label>
                                  </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-md-6">
                                  <fieldset>
                                      <div class="row pl-3 pr-1 pb-4">

                                            <div class="col-md-12 mt-3 pt-2"> <h6 class="uk-text-uppercase pl-1 uk-text-bold"><i class="fas fa-file-alt mr-2"></i> Book Bank </h6> </div>

                                            <div class="col-md-6">
                                                  <div class="preview-file mb-4">
                                                    <div class="file mt-3">
                                                      <div class="row">
                                                        <div class="col-md-4">
                                                          <img src="https://data.unhcr.org/images/documents/big_4cda85d892a5c0b5dd63b510a9c83e9c9d06e739.jpg">
                                                        </div>
                                                        <div class="col-md-8">
                                                          <div class="row mt-3">
                                                            <div class="col-md-8">
                                                              <span class="filename"> DBD.jpg </span>
                                                            </div>
                                                            <div class="col-md-4" style="padding-right: 18px;">
                                                              <a href="#" class="del-btn"><i data-v-8ace0cfc="" class="fal fa-trash-alt" aria-hidden="true"></i></a>
                                                            </div>
                                                            <div class="col-md-6 pr-1">
                                                              <a href="" class="view-btn pl-3 pr-3 mt-3 w-100"> View</a>
                                                            </div>
                                                            <div class="col-md-6 pl-1">
                                                              <a href="#" class="edit-btns pl-3 pr-3 mt-3 w-100" style="float: right;"><i class="fas fa-pen mr-2" style="font-size: 13px !important;"></i> Edit </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                            </div>
                                            <div class="col-md-4">
                                              <a href="#" class="save-btn sendmail-btn border-0 mt-4 text-center" style="height: 95px; width: 80px !important; text-align: left !important;">
                                                <i class="fas fa-plus mr-1" style="font-size: 15px; display: block;"></i> Add
                                              </a>
                                            </div>
                                      </div>
                                  </fieldset>
                                </div>
                                <div class="col-md-6">
                                  <fieldset>
                                      <div class="row pl-3 pr-1 pb-4">

                                            <div class="col-md-12 mt-3 pt-2"> <h6 class="uk-text-uppercase pl-1 uk-text-bold"><i class="fas fa-file-alt mr-2"></i> 6 Month Statement </h6> </div>

                                            <div class="col-md-6">
                                                  <div class="preview-file mb-4">
                                                    <div class="file mt-3">
                                                      <div class="row">
                                                        <div class="col-md-4">
                                                          <img src="https://data.unhcr.org/images/documents/big_4cda85d892a5c0b5dd63b510a9c83e9c9d06e739.jpg">
                                                        </div>
                                                        <div class="col-md-8">
                                                          <div class="row mt-3">
                                                            <div class="col-md-8">
                                                              <span class="filename"> DBD.jpg </span>
                                                            </div>
                                                            <div class="col-md-4" style="padding-right: 18px;">
                                                              <a href="#" class="del-btn"><i data-v-8ace0cfc="" class="fal fa-trash-alt" aria-hidden="true"></i></a>
                                                            </div>
                                                            <div class="col-md-6 pr-1">
                                                              <a href="" class="view-btn pl-3 pr-3 mt-3 w-100"> View</a>
                                                            </div>
                                                            <div class="col-md-6 pl-1">
                                                              <a href="#" class="edit-btns pl-3 pr-3 mt-3 w-100" style="float: right;"><i class="fas fa-pen mr-2" style="font-size: 13px !important;"></i> Edit </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                            </div>
                                            <div class="col-md-4">
                                              <a href="#" class="save-btn sendmail-btn border-0 mt-4 text-center" style="height: 95px; width: 80px !important; text-align: left !important;">
                                                <i class="fas fa-plus mr-1" style="font-size: 15px; display: block;"></i> Add
                                              </a>
                                            </div>
                                      </div>
                                  </fieldset>
                                </div>
                                <div class="col-md-12 text-right pt-0 mt-0 mb-4">
                                  <hr class="mt-2 mb-4">
                                  <button href="#" class="cancel-btn border-0"><i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel </button>
                                  <button href="#" id="client" class="save-btn border-0 ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4 pb-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-chart-bar mr-2"></i> Data </h5>
                              </div>
                            </div>
                            <div class="view-ui mt-4 mb-2">
                              <div class="row">

                                <div class="col-md-3">
                                  <div class="input-icon-box">
                                        <div class="icon default">
                                          <span class="img-icon male-bodyguard-icon"></span>
                                          <span class="primary-title">Male Bodyguards</span>
                                        </div>
                                        <div class="form-group">
                                          <input type="text" name="company" class="form-control" value="1,520" required>
                                          <label>Person</label>
                                        </div>
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <div class="input-icon-box">
                                        <div class="icon default">
                                          <span class="img-icon female-bodyguard-icon"></span>
                                          <span class="primary-title">Female Bodyguards</span>
                                        </div>
                                        <div class="form-group">
                                          <input type="text" name="company" class="form-control" value="1,520" required>
                                          <label>Person</label>
                                        </div>
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <div class="input-icon-box">
                                        <div class="icon default">
                                          <span class="img-icon armed-bodyguard-icon"></span>
                                          <span class="primary-title">Armed Bodyguards</span>
                                        </div>
                                        <div class="form-group">
                                          <input type="text" name="company" class="form-control" value="1,520" required>
                                          <label>Person</label>
                                        </div>
                                  </div>
                                </div>

                                <div class="col-md-3">
                                  <div class="input-icon-box">
                                        <div class="icon default">
                                          <span class="img-icon vehicle-icon"></span>
                                          <span class="primary-title">Vehicle</span>
                                        </div>
                                        <div class="form-group">
                                          <input type="text" name="company" class="form-control" value="1,520" required>
                                          <label>Person</label>
                                        </div>
                                  </div>
                                </div>

                                <div class="clearfix"></div>
                              </div>
                            </div>

                            <div class="row mt-4">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-chart-bar mr-2"></i> Bodyguard price list </h5>
                              </div>
                            </div>
                            <div class="row pt-3">
                                    <div class="col-md-12">
                                      <div class="table-responsive">
                                        <table class="table data-table table-round table-hover">
                                          <thead>
                                          <tr>
                                            <th width="16%" class="pl-4 pr-4">
                                              Period
                                            </th>
                                            <th width="16%" class="pl-4 pr-4">
                                              Hours
                                            </th>
                                            <th width="16%" class="pl-4 pr-4">
                                              Thai (Unarmed)
                                            </th>
                                            <th width="16%" class="pl-4 pr-4">
                                              Thai (Armed)
                                            </th>
                                            <th width="16%" class="pl-4 pr-4">
                                              Expat (Unarmed)
                                            </th>
                                            <th width="16%" class="pl-4 pr-4">
                                              Expat (Armed)
                                            </th>
                                            <th class="blankhead" width="auto"></th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td class="bg-white pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Half Day </option>
                                                    <option> Day </option>
                                                    <option> Week </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Half Day </option>
                                                    <option> Day </option>
                                                    <option> Week </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿1,520" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿1,520" required>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿1,520" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿1,520" required>
                                                </div>
                                              </td>
                                              <td class="bg-white text-right">
                                                <a class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                                                <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="bg-white pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Half Day </option>
                                                    <option> Day </option>
                                                    <option> Week </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Half Day </option>
                                                    <option> Day </option>
                                                    <option> Week </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿1,520" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿1,520" required>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿1,520" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿1,520" required>
                                                </div>
                                              </td>
                                              <td class="text-right">
                                                <a class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                                                <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="bg-white pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Half Day </option>
                                                    <option> Day </option>
                                                    <option> Week </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Half Day </option>
                                                    <option> Day </option>
                                                    <option> Week </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿1,520" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿1,520" required>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿1,520" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿1,520" required>
                                                </div>
                                              </td>
                                              <td class="bg-white text-right">
                                                <a class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                                                <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                            </div>


                            <div class="row mt-4">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-chart-bar mr-2"></i> Transportation price list </h5>
                              </div>
                            </div>
                            <div class="row pt-3">
                                    <div class="col-md-12">
                                      <div class="table-responsive">
                                        <table class="table data-table table-round table-hover">
                                          <thead>
                                          <tr>
                                            <th class="blankhead" width="100px"></th>
                                            <th width="14%" class="pl-4 pr-4">
                                              Brand
                                            </th>
                                            <th width="14%" class="pl-4 pr-4">
                                              Model
                                            </th>
                                            <th width="14%" class="pl-4 pr-4">
                                              Plat No.
                                            </th>
                                            <th width="14%" class="pl-4 pr-4">
                                              Seats
                                            </th>
                                            <th width="14%" class="pl-4 pr-4">
                                              Price
                                            </th>
                                            <th width="14%" class="pl-4 pr-4">
                                              Period
                                            </th>
                                            <th class="blankhead" width="auto"></th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td class="bg-white pt-3 text-center">
                                                <img class="uk-border-circle" src="https://www.audi.com.my/media/TextBox_Image_Component/77543-paragraphs-895705-image/dh-1136-6ba340/3ea5de04/1660285277/1920x1080-aa5-sb-191004-oe.jpg" width="50" height="50">
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Audi </option>
                                                    <option> BMW </option>
                                                    <option> Mercedes </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="ILX" required>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="กพ 3581" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="5" required>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿4,150" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Half Day </option>
                                                    <option> Day </option>
                                                    <option> Week </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white text-right">
                                                <a class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                                                <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="bg-white pt-3 text-center">
                                                <img class="uk-border-circle" src="https://www.audi.com.my/media/TextBox_Image_Component/77543-paragraphs-895705-image/dh-1136-6ba340/3ea5de04/1660285277/1920x1080-aa5-sb-191004-oe.jpg" width="50" height="50">
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Audi </option>
                                                    <option> BMW </option>
                                                    <option> Mercedes </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="ILX" required>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="กพ 3581" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="5" required>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿4,150" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Half Day </option>
                                                    <option> Day </option>
                                                    <option> Week </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white text-right">
                                                <a class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                                                <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="bg-white pt-3 text-center">
                                                <img class="uk-border-circle" src="https://www.audi.com.my/media/TextBox_Image_Component/77543-paragraphs-895705-image/dh-1136-6ba340/3ea5de04/1660285277/1920x1080-aa5-sb-191004-oe.jpg" width="50" height="50">
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Audi </option>
                                                    <option> BMW </option>
                                                    <option> Mercedes </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="ILX" required>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="กพ 3581" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="5" required>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿4,150" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Half Day </option>
                                                    <option> Hour </option>
                                                    <option> Week </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white text-right">
                                                <a class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                                                <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colspan="7">
                                                <a href="#" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center"><i class="fas fa-plus mr-2" style="font-size: 15px; display: block;"></i> Add </a>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="col-md-12">
                                    </div>
                            </div>


                            <div class="row mt-4">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-chart-bar mr-2"></i> Price escort price list </h5>
                              </div>
                            </div>
                            <div class="row pt-3">
                                    <div class="col-md-8">
                                      <div class="table-responsive">
                                        <table class="table data-table table-round table-hover">
                                          <thead>
                                          <tr>
                                            <th width="23%" class="pl-4 pr-4">
                                              Type of Vehicle
                                            </th>
                                            <th width="23%" class="pl-4 pr-4">
                                              No. Of Vehicle
                                            </th>
                                            <th width="23%" class="pl-4 pr-4">
                                              Price
                                            </th>
                                            <th width="23%" class="pl-4 pr-4">
                                              Period
                                            </th>
                                            <th class="blankhead" width="auto"></th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td class="bg-white pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Car </option>
                                                    <option> Motorbike </option>
                                                    <option> Car </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> 1 </option>
                                                    <option> 2 </option>
                                                    <option> 3 </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿4,150" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Half Day </option>
                                                    <option> Day </option>
                                                    <option> Week </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white text-right">
                                                <a class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                                                <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="bg-white pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Car </option>
                                                    <option> Motorbike </option>
                                                    <option> Car </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> 1 </option>
                                                    <option> 2 </option>
                                                    <option> 3 </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿4,150" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Half Day </option>
                                                    <option> Day </option>
                                                    <option> Week </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white text-right">
                                                <a class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                                                <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="bg-white pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Car </option>
                                                    <option> Motorbike </option>
                                                    <option> Car </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> 1 </option>
                                                    <option> 2 </option>
                                                    <option> 3 </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white pl-4 pr-4 pt-3 pb-2">
                                                <div class="form-group mb-2 mt-1">
                                                  <input type="text" name="company" class="form-control uk-form-small pt-0" value="฿4,150" required>
                                                </div>
                                              </td>
                                              <td class="bg-lightgray pl-4 pr-4 pt-3">
                                                <div class="form-group select-dropdown mb-2 mt-1">
                                                  <select class="form-control uk-form-small pt-0" name="language" required>
                                                    <option> Half Day </option>
                                                    <option> Day </option>
                                                    <option> Week </option>
                                                  </select>
                                                </div>
                                              </td>
                                              <td class="bg-white text-right">
                                                <a class="edit-btn" uk-toggle><i class="far fa-copy"></i></a>
                                                <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colspan="5">
                                                <a href="#" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center"><i class="fas fa-plus mr-2" style="font-size: 15px; display: block;"></i> Add </a>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="col-md-12 text-right pt-4 mt-0 mb-4">
                                      <hr class="mt-2 mb-4">
                                      <button href="#" class="cancel-btn border-0"><i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel </button>
                                      <button href="#" id="client" class="save-btn border-0 ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
                                    </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-user-tie mr-2"></i> List of Agents </h5>
                              </div>
                            </div>
                            <div class="view-ui mt-4 mb-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="table-responsive">
                                    <table class="table data-table table-round table-hover">
                                      <thead>
                                        <tr>
                                          <th scope="col" width="120px"></th>
                                          <th scope="col">Name</th>
                                          <th scope="col">Gender, Age</th>
                                          <th scope="col">Location</th>
                                          <th scope="col" width="10%" class="text-center">Service Area</th>
                                          <th scope="col" width="10%" class="text-right">Mission</th>
                                          <th scope="col" width="15%" class="text-center">Rating</th>
                                          <th scope="col" width="5%" class="blankhead text-left">Status</th>
                                          <th scope="col" width="80px" class="blankhead"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td> <img src="/assets/img/user.jpg" class="uk-border-circle" width="50px" height="50px"> </td>
                                          <td><a href="#"> M. Kemal Yilmaz </a></td>
                                          <td>Male, 35</td>
                                          <td>Bangkok</td>
                                          <td class="text-center">
                                            <span class="uk-label mr-1">VI</span>
                                            <!-- <span class="uk-label mr-1">BG</span>
                                            <span class="uk-label mr-1">SG</span> -->
                                          </td>
                                          <td class="text-right">75</td>
                                          <td class="text-center"> <span class="rating"> <i class="fas fa-star"></i> 5.0 </span> </td>
                                          <td class="text-center relative">
                                            <label class="switch">
                                              <input type="checkbox" checked />
                                              <span class="slider round"></span>
                                            </label>
                                          </td>
                                          <td class="text-right">
                                            <div class="action-btn d-flex justify-content-end">
                                              <router-link>
                                                <a class="edit-btn"><i class="fal fa-edit"></i></a>
                                              </router-link>
                                              <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td> <img src="/assets/img/user.jpg" class="uk-border-circle" width="50px" height="50px"> </td>
                                          <td><a href="#"> M. Kemal Yilmaz </a></td>
                                          <td>Male, 35</td>
                                          <td>Bangkok</td>
                                          <td class="text-center">
                                            <span class="uk-label mr-1">VI</span>
                                            <!-- <span class="uk-label mr-1">BG</span>
                                            <span class="uk-label mr-1">SG</span> -->
                                          </td>
                                          <td class="text-right">75</td>
                                          <td class="text-center"> <span class="rating"> <i class="fas fa-star"></i> 5.0 </span> </td>
                                          <td class="text-center relative">
                                            <label class="switch">
                                              <input type="checkbox" checked />
                                              <span class="slider round"></span>
                                            </label>
                                          </td>
                                          <td class="text-right">
                                            <div class="action-btn d-flex justify-content-end">
                                              <router-link>
                                                <a class="edit-btn"><i class="fal fa-edit"></i></a>
                                              </router-link>
                                              <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td> <img src="/assets/img/user.jpg" class="uk-border-circle" width="50px" height="50px"> </td>
                                          <td><a href="#"> M. Kemal Yilmaz </a></td>
                                          <td>Male, 35</td>
                                          <td>Bangkok</td>
                                          <td class="text-center">
                                            <span class="uk-label mr-1">VI</span>
                                            <!-- <span class="uk-label mr-1">BG</span>
                                            <span class="uk-label mr-1">SG</span> -->
                                          </td>
                                          <td class="text-right">75</td>
                                          <td class="text-center"> <span class="rating"> <i class="fas fa-star"></i> 5.0 </span> </td>
                                          <td class="text-center relative">
                                            <label class="switch">
                                              <input type="checkbox" checked />
                                              <span class="slider round"></span>
                                            </label>
                                          </td>
                                          <td class="text-right">
                                            <div class="action-btn d-flex justify-content-end">
                                              <router-link>
                                                <a class="edit-btn"><i class="fal fa-edit"></i></a>
                                              </router-link>
                                              <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="col-md-12 text-right pt-4 mt-0 mb-4">
                                  <hr class="mt-2 mb-4">
                                  <button href="#" class="cancel-btn border-0"><i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel </button>
                                  <button href="#" id="client" class="save-btn border-0 ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-info-circle mr-2"></i> Legal Agreement </h5>
                              </div>
                            </div>
                            <div class="view-ui mt-4 mb-2">
                              <div class="row">
                                <div class="col-md-12">
                                        <div class="row">
                                          <div class="col-lg-12">
                                              <ul uk-tab style="padding-left: 15px;">
                                                <li class="uk-active"><a href="#" class="no-bullet">Legal Agreement (English)</a></li>
                                                <li><a href="#" class="no-bullet">Legal Agreement (Thai)</a></li>
                                              </ul>
                                              <ul class="uk-switcher uk-margin">
                                                <li>
                                                  <div class="form-group">
                                                    <textarea rows="30" name="legal_agreement"></textarea>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div class="form-group">
                                                    <textarea rows="30" name="legal_agreement"></textarea>
                                                  </div>
                                                </li>
                                              </ul>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-12 pb-5">
                                            <a href="" class="btn download-btn"> <i class="fas fa-download mr-2"></i>  Download PDF </a>
                                          </div>
                                        </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div class="row align-items-center mb-4">
                        <div class="col-lg-12">
                          <div class="grey-box p-3 pt-4">
                            <div class="row">
                              <div class="col-md-6 text-left">
                                <h5 class="mt-1 mb-2 uk-text-bold"> <i class="fas fa-clipboard-list mr-2"></i> Activity Logs </h5>
                              </div>
                            </div>
                            <div class="view-ui mt-3 mb-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="table-responsive">
                                    <table class="table data-table table-round table-hover">
                                      <thead>
                                        <tr>
                                          <th scope="col" width="20%">Time</th>
                                          <th scope="col" width="auto">Detail</th>
                                          <th scope="col" width="20%">Page</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td class="time text-left pl-3 pr-3" style="font-size: 12px;">
                                            <i class="fal fa-calendar mr-1 relative" style="top: 1px;"></i> 14 AUG 2022 <span class="end-time ml-1" style="font-size: 11px;"> 10:03 </span> 
                                          </td>
                                          <td class="text-left pl-3 pr-3"> Log out </td>
                                          <td class="text-left pl-3 pr-3"><a href="#"> Tim Duncan </a></td>
                                        </tr>
                                        <tr>
                                          <td class="time text-left pl-3 pr-3" style="font-size: 12px;">
                                            <i class="fal fa-calendar mr-1 relative" style="top: 1px;"></i> 13 AUG 2022 <span class="end-time ml-1" style="font-size: 11px;"> 10:03 </span> 
                                          </td>
                                          <td class="text-left pl-3 pr-3"> Disable User </td>
                                          <td class="text-left pl-3 pr-3"> - </td>
                                        </tr>
                                        <tr>
                                          <td class="time text-left pl-3 pr-3" style="font-size: 12px;">
                                            <i class="fal fa-calendar mr-1 relative" style="top: 1px;"></i> 12 AUG 2022 <span class="end-time ml-1" style="font-size: 11px;"> 10:03 </span> 
                                          </td>
                                          <td class="text-left pl-3 pr-3"> Change Bodyguard Profile </td>
                                          <td class="text-left pl-3 pr-3"><a href="#"> #BG21213 </a></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="col-md-12 text-left">
                                  <a href="#" class="btn download-btn mt-4">  Load more </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
    </section>
  </div>
</template>


<script>
export default {
  mounted() {
    //document.title = "Edit Case " + this.$route.params.id
    this.checkRequired()
   // this.$route.meta.title = this.companyName
    if (this.isValidId(this.$route.params.id)) {
      this.getCompanyDetails()
      this.getCompanyPrices()
      this.getCompanyCars()
      this.getEscorts()
      this.getBodyguardList()
      this.getMissionList()
    } else {
      console.log('dsdasdsada')
      this.$router.push('/404')
    }
  },
  metaInfo() {
    return {
      title: () => {
        return 'My title with params ' + this.$route.params.id
      },
    }
  },
  data() {
    return {
      companyDetails: {},
      parentCompany: {},
      requiredfileds: {},
      companyInfo: {},
      dangerPrices: [],
      priceListBodyguard: [],
      carList: [],
      carEscort: [],
      staffList: [],
      missionList: [],
    }
  },
  methods: {
    isRequired(key) {
      if (this.requiredfileds.hasOwnProperty(key)) {
        return '*'
      } else {
        return ''
      }
    },
    hasCopmany() {
      return this.companyDetails.hasOwnProperty('parent_company')
    },
    checkRequired() {
      let formData = new FormData()
      formData.append('test', 'test')
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies'
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .post(api, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${admintoken}`,
          },
        })
        .then((response) => {
          console.log(response)
          this.requiredfileds = response.data.data
          console.log(this.requiredfileds)
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getCompanyDetails() {
      const company_id = atob(this.$route.params.id)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies/' + company_id
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.companyDetails = response.data.data
          if (this.hasCopmany()) {
            this.companyInfo = response.data.data.parent_company
          }
          if (this.companyDetails.hasOwnProperty('danger_prices')) {
            this.dangerPrices = response.data.data.danger_prices
          }
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getCompanyPrices() {
      const company_id = atob(this.$route.params.id)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies/' + company_id + '/prices?page=1&limit=10'
      const admintoken = localStorage.getItem('auth_token_default')

      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.priceListBodyguard = response.data.data
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getCompanyCars() {
      const company_id = atob(this.$route.params.id)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies/' + company_id + '/vehicles?page=1&limit=10'
      const admintoken = localStorage.getItem('auth_token_default')

      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.carList = response.data.data;
          console.log(response.data.data)
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getEscorts() {
      const company_id = atob(this.$route.params.id)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies/' + company_id + '/escorts?page=1&limit=10'
      const admintoken = localStorage.getItem('auth_token_default')

      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.carEscort = response.data.data
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getBodyguardList() {
      const company_id = atob(this.$route.params.id)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/agencies/' + company_id + '/staff?page=1&limit=100'
      const admintoken = localStorage.getItem('auth_token_default')

      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.staffList = response.data.data.records
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    getMissionList() {
      const company_id = atob(this.$route.params.id)
      const api =
        'https://dev-api.trueprotection.co.th/api/admin/missions?page=1&limit=10&keyword=&sort_column=id&sort_type=desc&status=history&agency_id=' +
        company_id
      const admintoken = localStorage.getItem('auth_token_default')

      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.missionList = response.data.data.records
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    isValidId(str) {
      try {
        return btoa(atob(str)) == str
      } catch (err) {
        return false
      }
    },
    formatTHB(num) {
      return "฿" + Number(num).toLocaleString();
    },
  },
}


</script>

<style scoped>
</style>