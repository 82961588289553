<template>
    <div>
        <!----- Header Title ----->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h3 class="page-title mb-1">
                        {{ this.$route.meta.title }}
                    </h3>
                </div>
                <div class="breadcrumb-container">
                    <ul class="uk-breadcrumb">
                        <li><span>Admin</span></li>
                        <li><a href="/reservation">Reservation</a></li>
                        <li><a href="">{{ this.$route.meta.title }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 relative"></div>
        </div>
        <!-- New Reservation -->
        <div class="row align-items-center mb-4">
            <div class="col-lg-12">
                <section class="grey-box register" v-if="!hasSeenCongrats">
                    <div class="register-stepper">
                        <div class="step step-done"><span class="step-number">1</span></div>
                        <div class="step step-active"><span class="step-number">2</span></div>
                        <div class="step"><span class="step-number">3</span></div>
                        <div class="step"><span class="step-number">4</span></div>
                        <div class="step"><span class="step-number">5</span></div>
                        <div class="step"><span class="step-number">6</span></div>
                    </div>

                    <transition name="slide">
                        <section>
                            <form class="form" method="post" action="#">
                                <p class="text-uppercase text-sm mb-2">Select Available vehicles</p>
                                <hr class="horizontal dark mt-0" />
                                <div class="table-responsive p-0 cta-step mb-3">
                                    <table class="table align-items-center mb-0">
                                        <tbody>
                                            <tr v-for="(list, index) in vehicleList" :key="index">
                                                <td>
                                                    <div class="d-flex px-2 py-1">
                                                        <div>
                                                            <img :src="list.vehicle_photo"
                                                                class="availablecars me-3" alt="user1" />
                                                        </div>
                                                        <div class="d-flex flex-column justify-content-center"
                                                            style="margin-left: 20px;">
                                                            <h6 class="mb-0 text-sm">{{ list.model }}  {{ list.make }} {{ list.class }}</h6>
                                                            <ul class="car-list">
                                                                <li class="text-xs text-secondary mb-0"><i
                                                                        class="fa fa-car" aria-hidden="true"></i> {{ list.transmission }}</li>
                                                                <li class="text-xs text-secondary mb-0"><i
                                                                        class="fa fa-snowflake-o"
                                                                        aria-hidden="true"></i> {{ list.aircondition}}</li>
                                                                <li class="text-xs text-secondary mb-0"><i
                                                                        class="fa fa-play-circle-o"
                                                                        aria-hidden="true"></i> {{ list.media}}</li>
                                                                <li class="text-xs text-secondary mb-0"><i
                                                                        class="fa fa-building-o" aria-hidden="true"></i>
                                                                        {{ list.doors}}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h3 class="vehicle_price">${{ list.price }} per day</h3>
                                                    <a @click="save(list.id)" class="link_text btn btn-sm btn-primary text-white w-100">Avail</a>
                                                </td>
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                </div>

                                <div class="form-group cta-step">
                                    <div class="cta prev">
                                        <p class="cta-color">
                                            <span class="link_wrap">
                                                <router-link :to="'/new/reservation/1'">
                                                    <a class="btn prev-btn link_text"><span class="arrow-prev"
                                                            style="padding-right: 5px;"></span>Previous</a>
                                                </router-link>
                                            </span>
                                        </p>
                                    </div>
                                    <div class="cta">
                                        <p class="cta-color">
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </section>
                    </transition>
                </section>
            </div>
        </div>


        <!-- Add **uk-toggle="target: #createmodal" in div -->
        <!-- Create success Modal -->
        <div id="createmodal" uk-modal>
            <div class="uk-modal-dialog uk-modal-body p-2 text-center">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div class="row align-items-center mb-4">
                    <div class="col-md-12 mt-4">
                        <h4>Update Car Info successful!</h4>
                    </div>
                </div>
                <a @click="next" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
            </div>
        </div>

        <!-- Add **uk-toggle="target: #updatemodal" in div -->
        <!-- Create success Modal -->
        <div id="updatemodal" uk-modal>
            <div class="uk-modal-dialog uk-modal-body p-2 text-center">
                <button class="uk-modal-close-outside" type="button" uk-close></button>
                <div class="row align-items-center mb-4">
                    <div class="col-md-12 mt-4">
                        <h4>Update reservation successful!</h4>
                    </div>
                </div>
                <a href="#" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
            </div>
        </div>
    </div>

</template>



<script>
import router from '../../routes';

export default {
    el: "#app",
    data: () => {
        return {
            steps: {},
            paymentopt: 'offline',
            step: 1,
            customer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            hasSeenCongrats: false,
            tempCustomer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            vehicleID: 0,
            reservation:{},
            vehicleList: []
        };
    },
    mounted() {
        if(Object.prototype.hasOwnProperty.call(this.$route.params, "id")){
            this.getReservationDetails()
        }
        this.getVehicleList()
    },
    methods: {
        getReservationDetails(){
            const reservation_id = localStorage.getItem("reservation_id")
            const api = 'https://api.615autorental.com/api/admin/reservation/'+reservation_id+'/details'
            const admintoken = localStorage.getItem('auth_token_default')
            const headers = {
                'Authorization': `Bearer ${admintoken}`,
                'Content-Type': 'application/json' // You can adjust content type as needed
            };
            axios
                .get(api, { headers }, {
                params: {
                    page: 1,
                    limit: 30
                }
                })
                .then((response) => {
                this.reservation = response.data.data;
               
                })
                .catch((error) => {
                console.error('There was an error!', error.message)
                })
        },
        getVehicleList() {
            const api = 'https://api.615autorental.com/api/admin/vehicles'
            const admintoken = localStorage.getItem('auth_token_default')
            const headers = {
                'Authorization': `Bearer ${admintoken}`,
                'Content-Type': 'application/json' // You can adjust content type as needed
            };
            axios
                .get(api, { headers }, {
                    params: {
                        page: 1,
                        limit: 30
                    }
                })
                .then((response) => {
                    this.vehicleList = []
                    this.vehicleList = response.data.data
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        redirectReservation() { // 
            router.push({ path: '/reservation/' })
        },
        next(){
            if(Object.prototype.hasOwnProperty.call(this.$route.params, "id")){
                location.href="/reservation/3/edit/" + this.$route.params.id
            }else{
                location.href="/new/reservation/3"
            }
        },
        save(id) {
            let reservationData = {
                "vehicle_id": id

            }
            const admintoken = localStorage.getItem('auth_token_default')
            const reservation_id = localStorage.getItem("reservation_id")
            if (reservation_id) {
                axios.put(`https://api.615autorental.com/api/admin/reservation/` + reservation_id, reservationData, {
                    headers: {
                        'Authorization': `Bearer ${admintoken}`,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                       
                        UIkit.modal('#createmodal').show();
                        console.log('Customer updated successfully:', response.data);
                        // handle success
                    })
                    .catch(error => {
                        alert("Please complete form")
                        console.error('Error updating customer:', error);
                        // handle error
                    });
            } else {
                location.href = "/new/reservation/1"
            }

        },

        newBooking: function () {
            this.hasSeenCongrats = true;
        },
    }

};
</script>

<style scoped>
.card-footer {
    text-align: right;
    position: relative;
    background: #fff;
}

.pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
}
</style>