<template>
    <div class="row mt-5">
        <div class="col-md-6 text-left">
            <h5 class="mt-1 mb-2 uk-text-bold">
                <i class="fas fa-chart-bar mr-2"></i> Transportation price list
            </h5>
        </div>
        <div class="col-md-12 pt-2">
            <div class="table-responsive">
                <table class="table data-table table-round table-hover">
                    <thead>
                        <tr>
                            <th width="auto" class="pl-4 pr-4"></th>
                            <th width="18%" class="pl-4 pr-4">Brand</th>
                            <th width="18%" class="pl-4 pr-4">Model</th>
                            <th width="23%" class="pl-4 pr-4">Plat No.</th>
                            <th width="18%" class="pl-4 pr-4">Seats</th>
                            <th width="23%" class="pl-4 pr-4">Color</th>
                            <th class="blankhead" width="15%"></th>
                        </tr>
                    </thead>
                    <tbody>

                        <template v-for="(vehicle, vIndex1) in vehicleLists" >
                            <template>
                                <tr :key="vIndex1">
                                    <td class="bg-white pt-3 text-center">
                                        <img
                                        class="uk-border-circle"
                                        src="https://www.audi.com.my/media/TextBox_Image_Component/77543-paragraphs-895705-image/dh-1136-6ba340/3ea5de04/1660285277/1920x1080-aa5-sb-191004-oe.jpg"
                                        width="50"
                                        height="50"
                                        />
                                    </td>
                                    <td width="16%" class="bg-white pl-4 pr-4 pt-2 pb-2">
                                        <div class="form-group mb-2 mt-0">
                                            <input type="text"  v-model="vehicleLists[vIndex1].brand"  class="form-control uk-form-small pt-0" required>
                                            <!-- <select>
                                                <option>Audi</option>
                                                <option>BMW</option>
                                                <option>Mercedes</option>
                                                <option>Lexus</option>
                                            </select> -->
                                        </div>
                                    </td>
                                    <td width="16%" class="bg-lightgreen pl-4 pr-4 pl-4 pr-4 pt-2 pb-2">
                                        <div class="form-group mb-2 mt-1">
                                        <input type="text" v-model="vehicleLists[vIndex1].model" class="form-control uk-form-small pt-0" required />
                                        </div>
                                    </td>
                                    <td width="16%" class="bg-white pl-4 pr-4 pt-2 pb-2">
                                        <div class="form-group mb-2 mt-1">
                                        <input type="text" v-model="vehicleLists[vIndex1].plate_number" class="form-control uk-form-small pt-0" required />
                                        </div>
                                    </td>
                                    <td width="16%" class="bg-lightgreen pl-4 pr-4 pt-2 pb-2">
                                        <div class="form-group mb-2 mt-1">
                                        <input type="text" v-model="vehicleLists[vIndex1].total_seat" class="form-control uk-form-small pt-0" required />
                                        </div>
                                    </td>
                                    <td width="16%" class="bg-white pl-4 pr-4 pt-2 pb-2">
                                        <div class="form-group mb-2 mt-1">
                                            <input type="text" v-model="vehicleLists[vIndex1].color" class="form-control uk-form-small pt-0" required />
                                        </div>
                                    </td>
                                    <td width="15%" class="bg-lightgreen text-right">
                                        <a v-if="vehicle.id != undefined" data-toggle="collapse" v-bind:data-target="`#latest_address_${vehicle.id}`"  class="view-btn clickable mb-2" type="button"></a>
                                        <a v-if="vehicle.id != undefined" @click="copyVehicle(vehicle.id, vIndex1)" class="edit-btn mb-2" uk-toggle><i class="far fa-copy"></i></a>
                                        <a @click="updateVehicle(vehicle.id, vIndex1)" class="save-btn ml-1 mb-2"><i class="fal fa-save" aria-hidden="true"></i></a>
                                        <a @click="deleteVehicle(vehicle.id, vIndex1)" href="#" class="del-btn mb-2"><i class="fal fa-trash-alt"></i></a>
                                    </td>
                                </tr>
                                <tr :key="'price'+vIndex1" :id="`latest_address_${vehicle.id}`" class="collapse">
                                    <td colspan="7" class="p-0">
                                        <div class="row">
                                        <div class="p-4 bg-light" style="border: solid 1px #e9e9e9; padding-bottom: 20px !important; width: 60%; margin: auto; margin-top: 30px; margin-bottom: 30px;">
                                            <!-- <h4>Prices</h4> -->
                                            <form @submit.prevent="updatePrice('price'+vIndex1)" ref="updatePriceForm">
                                                <div class="table-responsive grey-box p-0">
                                                    <table class="table data-table table-round table-hover" >
                                                        <thead>
                                                            <tr>
                                                                <th width="30%" class="pl-4 pr-4">Range Value</th>
                                                                <th width="30%" class="pl-4 pr-4">Range Period</th>
                                                                <th width="30%" class="pl-4 pr-4">Price</th>
                                                                <th width="auto" class="pl-4 pr-4"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(price, vPriceIndex) in vehicle.prices" :key="vPriceIndex">
                                                                <td class="bg-white">
                                                                    <div class="form-group mb-2 mt-1">
                                                                    <input type="text" v-model="vehicle.prices[vPriceIndex].range_value" class="form-control uk-form-small" required>
                                                                    </div>
                                                                </td>
                                                                <td class="bg-lightgreen">
                                                                    <div class=" form-group select-dropdown mb-2 mt-0">
                                                                        <select v-model="vehicle.prices[vPriceIndex].range_period"  class="form-control uk-form-small pt-0" required>
                                                                            <option value="day">Day</option>
                                                                            <option value="week">Week</option>
                                                                            <option value="month">Month</option>
                                                                        </select>
                                                                    </div>
                                                                </td>
                                                                <td class="bg-white">
                                                                    <input type="text" v-model="vehicle.prices[vPriceIndex].price" class="form-control uk-form-small" required>
                                                                </td>
                                                                <td class="bg-lightgreen">
                                                                    <a @click="deleteVPrice(vPriceIndex, 'price'+vIndex1)" href="#" class="del-btn mb-2">
                                                                        <i class="fal fa-trash-alt"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="">
                                                    <a @click="addPrice('price'+vIndex1)" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center">
                                                        <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                                                    </a>
                                                    <button class="uk-button uk-button-primary mr-2 mt-2 mb-2 float-right" type="submit">Save</button>
                                                    <button class="uk-button uk-button-default uk-modal-close mt-2 mb-2 float-right mr-2" type="button">Close</button>
                                                </div>
                                            </form>
                                        </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                        
                        <tr>
                            <td colspan="7">
                                <a @click="addVehicle" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center">
                                    <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                                </a>
                                <!-- <button type="submit" id="client" class="save-btn border-0 ml-2 mr-2 float-right">
                                    <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                                </button> -->
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    props: {
        companyDetail: Object
    },
    mounted() {
        this.vehiclePrices()
    },
    data() {
        return {
            vehicleLists: [],
            imageLists: [],
            formData: []
        }
    },
    methods: {
        vehiclePrices() {
            const api = 'admin/agencies/' + this.$route.params.id + '/vehicles'
            axios
                .get(api, {
                    params: {
                        page:1,
                        limit:30
                    }
                })
                .then((response) => {
                    this.vehicleLists = response.data.data
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
                
        },
        addVehicle() {
            this.vehicleLists.push({})
        },
        copyVehicle(vehicleId, index) {
            if(vehicleId != undefined){
                this.createVehicle(this.getFormData(index))
            }else{
                this.vehicleLists.push({})
            }
        },
        createVehicle(formData) {
            const api = 'admin/agencies/' + this.$route.params.id + '/vehicles'
            axios
                .post(api, formData)
                .then((response) => {
                    console.log('copied')
                    if(response.data.success) {
                        this.vehicleLists.push(response.data.data)
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        updateVehicle(vehicleId, index) {
            if(vehicleId != undefined) {
                this.updateData(vehicleId, index)
            }else{
                this.createVehicle(this.getFormData(index))
            }
        },
        updateData(vehicleId, index) {
            const api = 'admin/agencies/' + this.$route.params.id + '/vehicles/' + vehicleId
            axios
                .post(api, this.getFormData(index))
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        getFormData(index) {
            // let form = this.$refs.updatePriceForm;
            // let formData = new FormData(form);
            let data = this.vehicleLists[index]
            delete data.prices
            delete data.created_at
            delete data.images
            
            return data
        },
        deleteVehicle(vehicleId, index) {
            if(vehicleId != undefined) {
                const api = 'admin/agencies/' + this.$route.params.id + '/vehicles/' + vehicleId
                axios
                    .delete(api)
                    .then((response) => {
                        console.log('deleted')
                        if(response.data.success) {
                            var notyf = new Notyf();
                            notyf.confirm('Successfully Deleted!');

                            this.vehicleLists.splice(index, 1);
                        }else{
                            this.requiredfileds = response.data
                        }
                    })
                    .catch((error) => {
                        console.error('There was an error!', error.message)
                    })
            }else{
                this.vehicleLists.splice(index, 1);
            }
        },
        addPrice(vehicleIndex) {

            this.vehicleLists[vehicleIndex.split('price')[1]].prices.push({})
        },
        updatePrice(vehicleIndex) {
            let key = vehicleIndex.split('price')
            const api = 'admin/agencies/' + this.$route.params.id + '/vehicles/' + this.vehicleLists[key[1]].id + '/prices'
            axios
                .post(api, { prices: this.vehicleLists[key[1]].prices })
                .then((response) => {
                    console.log(response.data)
                    // this.vehicleLists = response.data.data
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        deleteVPrice(priceIndex, vehicleIndex) {
            this.vehicleLists[vehicleIndex.split('price')[1]].prices.splice(priceIndex, 1); 
            this.updatePrice(vehicleIndex)
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },
        // previewImage(vehicleId, index) {
        //     this.currentVehicleId = vehicleId
        //     this.imageLists = this.vehicleLists[index].images
        // },
        // addImage() {
        //     this.imageLists.push({})
        // },
        // updateImage() {
        //     const api = 'admin/agencies/' + this.$route.params.id + '/vehicles/' + this.currentVehicleId + '/prices'
        //     axios
        //         .post(api, { prices: this.imageLists })
        //         .then((response) => {
        //             console.log(response.data)
        //             // this.vehicleLists = response.data.data
        //         })
        //         .catch((error) => {
        //             console.error('There was an error!', error.message)
        //         })
        // }


    }
}
</script>
<style scoped>
label.form_radio .design {
    width:20px;
    height:20px
}
.save-btn {
    padding: 5px 10px !important;
    height: 30px;
}
.view-btn {
    padding: 5px 10px
}
a.view-btn::before {
    margin-right: 0px;
}
</style>