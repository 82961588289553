<template>
  <div>
    <!----- Header Title ----->
    <section>
      <div class="row content-header company">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
              {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative">
          <div class="pb-3 pl-2 pt-5 mt-3 uk-float-right">
            <div class="form-group search notify-btn mb-1">
                    <input type="text" name="keyword" placeholder="Search for..." class="form-control shadow-none border-0" />
                    <div class="input-group-prepend">
                      <span class="input-group-text border-0 bg-transparent">
                        <button type="submit" class="bg-transparent border-0"><i class="far fa-search"></i></button>
                      </span>
                    </div>
            </div>
          </div>
          <div class="pt-5 mt-3 uk-float-right">
            <div class="mb-3 text-right">
              <router-link :to="'/approval-text/rejected'">
                <a class="uk-button uk-button-light uk-text-danger pt-1"><i class="fad fa-external-link-alt mr-2"></i> Rejcted List</a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="tpui-container mb-5">
      <div class="row">
      <div class="col-lg-12 pt-4">
        <form name="form_approval_account" method="post">
          <div class="grey-box uk-card uk-card-default uk-width-1-1@m uk-margin-small-bottom p-0" v-for="(list, index) in textcontentList" :key="index">
            <div class="uk-card-header uk-padding-small">
                <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-auto">
                      <img src="/assets/img/user.jpg" class="uk-border-circle" width="40px" height="40px"> 
                    </div>
                    <div class="uk-width-expand pt-2">
                        <h6 class="uk-text-normal uk-margin-remove-bottom uk-text-secondary" v-if="list.name != null"><router-link :to="'textcontent/edit/' + list.id" class="uk-text-secondary">{{ list. name }}</router-link></h6>
                        <h6 class="uk-text-normal uk-margin-remove-bottom uk-text-secondary" v-if="list.name == null">N/A</h6>
                        <p class="time pt-0 mt-1 uk-text-small"><i class="fal fa-calendar mr-1 relative"></i> {{ list.date }} <span class="end-time ml-1" style="font-size: 11px;"> {{ list.time }} </span> </p>
                    </div>
                    <div class="uk-width-1-4" style="max-width: 180px; min-width: 120px;">
                      <div class="form-group select-dropdown">
                        <select class="form-control uk-form-small pt-0 uk-text-uppercase" v-model="list.status" name="approval">
                          <option value="accepted" class="uk-text-secondary"> Accept </option>
                          <option value="not_clear" class="uk-text-danger"> Not Clear </option>
                          <option value="not_relevant" class="uk-text-danger"> Not Relevant </option>
                          <option value="contain_contact_information" class="uk-text-danger"> Not Clear </option>
                          <option value="inappropriate_content" class="uk-text-danger"> Inappropriate Content </option>
                          <option value="other" class="uk-text-danger"> Other </option>

                        </select>
                      </div>
                    </div>
                </div>
            </div>
            <div class="uk-card-body uk-padding-small message">
              {{ list.column }}: 
              <dd> {{ list.text}} </dd>
            </div>
        </div>
        </form>
      </div>
      <div class="col-md-3 col-sm-12 pt-1 uk-text-left">
        <button id="btn-perform-all" class="save-btn border-0 mt-4 uk-text-uppercase" v-on:click="performAll()">
          <i class="far fa-check-square uk-margin-small-right"></i> Perform All
        </button>
      </div>
      <div class="col-md-9 col-sm-12 uk-text-right">
        <div class="card-footer pr-0 pl-0" v-if="numberOfPage > 1">
          <jw-pagination
            :items="pages"
            @changePage="onChangePage"
            :pageSize="limit"
            :styles="customStyles"
            :labels="customLabels"
          ></jw-pagination>
        </div>
      </div>
    </div>
    </section>

  </div>
</template>

<script>
const customLabels = {
  first: '<<',
  last: '>>',
  previous: '<',
  next: '>',
}
const customStyles = {
  ul: {},
  li: {
    display: 'inline-block',

    margin: '5px',
  },
  a: {
    color: 'blue',
    margin: '5px',
  },
}
export default {
  mounted() {
    this.getApprovalTextList(this.page, this.limit, '');

  },
  data() {
    return {
      params: {
        sort: { column: 'id', type: 'desc' },
      },
      keyword: '',
      firstload: false,
      customStyles: customStyles,
      customLabels: customLabels,
      numberOfPage: 0,
      pages: [],
      page: 1,
      limit: 10,
      textcontentType:'',
      sort: 'id',
      orderby: 'asc',
      textcontentList: [],
    }
  },
  methods: {
    performAll(){
      let textcontentList = []
      for(var i = 0;i<this.textcontentList.length;i++){
        textcontentList.push({id:this.textcontentList[i].id,status:this.textcontentList[i].status})
      }
      let payload = {"texts":textcontentList}
      console.log(payload)
      const api = 'https://dev-api.trueprotection.co.th/api/admin/approval-texts/update'
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .post(api, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${admintoken}`,
          },
        })
        .then((response) => {
          console.log(response.data)
          if (response.data.code == 200) {
            console.log(response.data)
            location.reload()
          } else {
            this.errorMessage = response.data.message
            this.$dialog.alert({
              title: 'Failed',
              message: this.errorMessage,
              showCancelButton: false,
              confirmButtonText: 'OK',
            })
          }
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    del_method: function (id, name) {
      UIkit.modal.confirm('Do you want to delete user: ' + name + " ? ").then(function () {
        console.log('Confirmed.')
      }, function () {
        console.log('Rejected.')
      });
    },
    filterType(type){
        this.textcontentType = type
        this.getApprovalTextList(this.page, this.limit,this.keyword)
    },
    onChangePage(pageOfItems) {
      if (typeof pageOfItems !== undefined && pageOfItems !== null) {
        this.getApprovalTextList(pageOfItems[0], this.limit,this.keyword)
      }
    },
    getApprovalTextList(page, limit, keyword) {
      this.page = page;
      let pagevalue = '' + page;
      if (pagevalue == 'undefined') {
        return
      }
      const api =
        'https://dev-api.trueprotection.co.th/api/admin/approval-texts?page='+page+'&limit='+limit
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.textcontentList = []
          this.textcontentList = response.data.data.records
          for(var i = 0; i< this.customerList.length;i++){
            if(this.textcontentList[i].status == null){
              this.textcontentList[i].status = 'accepted';
            }
          }
          
          if (this.firstload == false) {
            this.firstload = true
            this.numberOfPage = response.data.data.total_pages

            var counter = 1
            var pagenumber = 1
            this.pages = []
            for (var i = 0; i < this.numberOfPage * this.limit; i++) {
              if (counter - 1 == this.limit) {
                counter = 1
                pagenumber++
              } else {
                counter++
                this.pages.push(pagenumber)
              }
            }
          }
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    sortcol(sort) {
      this.params.sort = sort
      this.sort = sort.column
      this.orderby = sort.type
      this.getApprovalTextList(this.page, this.limit,this.keyword)
      console.log(sort.column, sort.type)
    },
    encode(id) {
      return btoa(id)
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    phoneformat: function (value) {
      if (!value) return ''
      value = value.toString()
      var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      return value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
  }
}
</script>