import Vue from 'vue'

Vue.filter("ages", function (value) {
    if (!value) return ''
    value = value.toString();
    var valedif = new Date(value);
    var datenow = Date.now();
    var agedif = new Date(datenow);
    return Math.abs(agedif.getUTCFullYear() - valedif.getUTCFullYear());
  });
  
  Vue.filter("capitalize", function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  });
  
  Vue.filter("phoneformat", function (value) {
    if (!value) return ''
    value = value.toString()
    var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    return value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  });
  
  Vue.filter("priceSymbol", function (value) {
    if (!value) return ''
    var splited =  value.split(' ')
    if(splited.length > 0) {
      return splited[0]
    }
    return value
  });
  
  Vue.filter("isActive", function (value, arg1) {
    if (!value) return ''
    return value == arg1? 'active' : ''
  });
  
  Vue.filter("splitDate", function (value, arg1) {
    if (!value) return ''
    return value.split(" ")[0]
  });
  
  Vue.filter("splitTime", function (value, arg1) {
    if (!value) return ''
    return value.split(" ")[1]
  });


  Vue.filter("experiencedYr", function (value) {
      if (!value) return ''
      var valedif = new Date(value);
      var datenow = Date.now();
      var currentYear = new Date(datenow);

      var year = Math.abs(currentYear.getUTCFullYear() - valedif.getUTCFullYear());
      return year > 1? year + ' Years': year + ' Year';
  });