<template>
    <div class="row mt-3">
        <div class="col-md-6 text-left">
            <h5 class="mt-1 mb-2 uk-text-bold">
                <i class="fas fa-chart-bar mr-2"></i> Transportation price list
            </h5>
        </div>
        <div class="col-md-12 pt-2">
            <div class="table-responsive">
                <table class="table data-table table-round table-hover">
                    <thead>
                        <tr>
                            <th width="auto" class="pl-4 pr-4"></th>
                            <th width="15%" class="pl-4 pr-4">Brand</th>
                            <th width="15%" class="pl-4 pr-4">Model</th>
                            <th width="15%" class="pl-4 pr-4">Plat No.</th>
                            <th width="15%" class="pl-4 pr-4">Seats</th>
                            <th width="15%" class="pl-4 pr-4">Color</th>
                            <th width="15%"></th>
                            <th width="5%" class="pl-4 pr-4">Status</th>
                            <th class="blankhead" width="auto"></th>
                        </tr>
                    </thead>
                    <tbody>

                        <template v-for="(vehicle, vIndex1) in vehicleLists" >
                            <template>
                                <tr :key="vIndex1">
                                    <td class="bg-white pt-3 text-center">
                                        <img
                                        class="uk-border-circle"
                                        src="/assets/img/logo.png"
                                        width="50"
                                        height="50"
                                        />
                                    </td>
                                    <td width="16%" class="bg-white pl-4 pr-4 pt-2 pb-2">
                                        <div class="form-group mb-2 mt-0">
                                            <input type="text"  v-model="vehicleLists[vIndex1].brand"  class="form-control uk-form-small pt-0" required>
                                            <!-- <select>
                                                <option>Audi</option>
                                                <option>BMW</option>
                                                <option>Mercedes</option>
                                                <option>Lexus</option>
                                            </select> -->
                                        </div>
                                    </td>
                                    <td width="16%" class="bg-lightgreen pl-4 pr-4 pl-4 pr-4 pt-2 pb-2">
                                        <div class="form-group mb-2 mt-1">
                                        <input type="text" v-model="vehicleLists[vIndex1].model" class="form-control uk-form-small pt-0" required />
                                        </div>
                                    </td>
                                    <td width="16%" class="bg-white pl-4 pr-4 pt-2 pb-2">
                                        <div class="form-group mb-2 mt-1">
                                        <input type="text" v-model="vehicleLists[vIndex1].plate_number" class="form-control uk-form-small pt-0" required />
                                        </div>
                                    </td>
                                    <td width="16%" class="bg-lightgreen pl-4 pr-4 pt-2 pb-2">
                                        <div class="form-group mb-2 mt-1">
                                        <input type="text" v-model="vehicleLists[vIndex1].total_seat" class="form-control uk-form-small pt-0" required />
                                        </div>
                                    </td>
                                    <td width="16%" class="bg-white pl-4 pr-4 pt-2 pb-2">
                                        <div class="form-group mb-2 mt-1">
                                            <input type="text" v-model="vehicleLists[vIndex1].color" class="form-control uk-form-small pt-0" required />
                                        </div>
                                    </td>
                                    <td width="16%" class="bg-lightgreen pl-4 pr-4 pt-2 pb-2">
                                        <div class="form-group mb-2 mt-1">
                                            <select class="form-control uk-form-small pt-0" required>
                                                <option value="2021" selected>2021</option>
                                                <option value="2020">2020</option>
                                                <option value="2019">2019</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td class="text-center relative">
                                        <label class="switch">
                                            <input type="checkbox" v-model="vehicleLists[vIndex1].active" :checked="vehicle.active" @change="vehicleActive(vIndex1)"/>
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                    <td width="15%" class="bg-lightgreen text-right">
                                        <a v-if="vehicle.id != undefined" data-toggle="collapse" v-bind:data-target="`#latest_address_${vehicle.id}`"  class="view-btn clickable mb-2" type="button"></a>
                                        <a v-if="vehicle.id != undefined" @click="copyVehicle(vehicle.id, vIndex1)" class="edit-btn mb-2" uk-toggle><i class="far fa-copy"></i></a>
                                        <a @click="updateVehicle(vehicle.id, vIndex1)" class="save-btn ml-1 mb-2"><i class="fal fa-save" aria-hidden="true"></i></a>
                                        <a @click="deleteVehicle(vehicle.id, vIndex1)" href="#" class="del-btn mb-2"><i class="fal fa-trash-alt"></i></a>
                                    </td>
                                </tr>
                                <tr :key="'price'+vIndex1" :id="`latest_address_${vehicle.id}`" class="collapse">
                                    <td colspan="9" class="p-3">
                                        <div class="row">
                                            <div class="col-md-6 vehicles mb-3">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h5 class="uk-text-uppercase">Service option</h5>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="">
                                                            <input type="checkbox" class="checkbox mr-2" />
                                                            Rent Car Only
                                                        </label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="">
                                                            <input type="checkbox" class="checkbox mr-2" />
                                                            Rent With Chuffer
                                                        </label>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <h5 class="uppercase">Gallery <span style="font-size: 10px">(MAX 8 pictures)</span></h5>
                                                        <div v-for="(vImg, imgIndex) in vehicle.images" :key="imgIndex" class="gallery-img position-relative text-center">
                                                            <div class="img-wrapper">
                                                                <img :src="vImg.path" alt="Vehicle Image" />
                                                                <span class="edit-btn">
                                                                    <!-- <input 
                                                                        type="file" 
                                                                        name="images[]" 
                                                                        class="file-upload" 
                                                                        id="file" 
                                                                        v-on:change="handleFileUpload($event.target.files, vIndex1, imgIndex)" >
                                                                    <i class="fal fa-pen upload-button"></i> Change -->
                                                                </span>
                                                            </div>
                                                            <span class="delete-btn" @click="deleteImage(vIndex1, imgIndex)"></span>
                                                        </div>
                                                        <div class="gallery-img position-relative text-center">
                                                            <div class="img-wrapper" style="border: 1px solid #1363f8;">
                                                                <span class="edit-btn" style="height:65%; background:none">
                                                                    <input 
                                                                        name="images[]"
                                                                        type="file" 
                                                                        class="file-upload" 
                                                                        id="file" 
                                                                        v-on:change="handleFileUpload($event.target.files, vIndex1)" >
                                                                    <i class="fal fa-plus upload-button" style="color:#1363f8"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <h5 class="uppercase">Description <span style="font-size: 10px">(MAX 8 pictures)</span></h5>
                                                        <textarea name="" id="" cols="30" rows="10" class="form-control">Lorem</textarea>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <h5 class="uppercase">Insurance</h5>
                                                        <label class="form_radio float-left mr-3" for="1st">
                                                            <input type="radio" name="insurance" id="1st" value="1st"  >
                                                            <span class="design"></span>
                                                            <span class="text">1st</span>
                                                        </label>
                                                        <label class="form_radio" for="2nd">
                                                            <input type="radio" name="insurance" id="2nd" value="2nd" >
                                                            <span class="design"></span>
                                                            <span class="text">2nd</span>
                                                        </label>
                                                        <label class="form_radio" for="3rd">
                                                            <input type="radio" name="insurance" id="3rd" value="3rd" >
                                                            <span class="design"></span>
                                                            <span class="text">3rd</span>
                                                        </label>
                                                        <label class="form_radio" for="no">
                                                            <input type="radio" name="insurance" id="no" value="no" >
                                                            <span class="design"></span>
                                                            <span class="text">No</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <h5 class="uppercase">Car label</h5>
                                                        <label for="">
                                                            <input type="checkbox" class="checkbox mr-2" />
                                                            Limusine
                                                        </label>
                                                        <label for="">
                                                            <input type="checkbox" class="checkbox mr-2" />
                                                            Luxury Car
                                                        </label>
                                                        <label for="">
                                                            <input type="checkbox" class="checkbox mr-2" />
                                                            Super Car
                                                        </label>
                                                        <label for="">
                                                            <input type="checkbox" class="checkbox mr-2" />
                                                            SUV
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <h5 class="uk-text-uppercase">Price List</h5>
                                                <form @submit.prevent="updatePrice('price'+vIndex1)" ref="updatePriceForm">
                                                    <div class="table-responsive grey-box p-0">
                                                        <table class="table data-table table-round table-hover" >
                                                            <thead>
                                                                <tr>
                                                                    <th width="20%" class="pl-4 pr-4">Price</th>
                                                                    <th width="40%" class="pl-4 pr-4" colspan="2">Period</th>
                                                                    <th width="auto" class="pl-4 pr-4"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(price, vPriceIndex) in vehicle.prices" :key="vPriceIndex">
                                                                    <td class="bg-white">
                                                                        <input type="text" v-model="vehicle.prices[vPriceIndex].price" class="form-control uk-form-small" required>
                                                                    </td>
                                                                    <td class="bg-white">
                                                                        <div class="form-group mb-2 mt-1">
                                                                        <input type="text" v-model="vehicle.prices[vPriceIndex].range_value" class="form-control uk-form-small" required>
                                                                        </div>
                                                                    </td>
                                                                    <td class="bg-white">
                                                                        <div class=" form-group select-dropdown mb-2 mt-0">
                                                                            <select v-model="vehicle.prices[vPriceIndex].range_period"  class="form-control uk-form-small pt-0" required>
                                                                                <option value="day">Day</option>
                                                                                <option value="week">Week</option>
                                                                                <option value="month">Month</option>
                                                                            </select>
                                                                        </div>
                                                                    </td>
                                                                    <td class="bg-lightgreen">
                                                                        <a @click="deleteVPrice(vPriceIndex, 'price'+vIndex1)" href="#" class="del-btn mb-2">
                                                                            <i class="fal fa-trash-alt"></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="4">
                                                                        <a @click="addPrice('price'+vIndex1)" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center">
                                                                            <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                                                                        </a>
                                                                        <button class="uk-button uk-button-primary mr-2 mt-2 mb-2 float-right" type="submit">Save</button>
                                                                        <button class="uk-button uk-button-default uk-modal-close mt-2 mb-2 float-right mr-2" type="button">Close</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                        
                        <tr>
                            <td colspan="9">
                                <a @click="addVehicle" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center">
                                    <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                                </a>
                                <!-- <button type="submit" id="client" class="save-btn border-0 ml-2 mr-2 float-right">
                                    <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                                </button> -->
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
        <!-- <div class="col-md-12 pt-2">
            pagination
        </div> -->
        <div class="col-md-12 pt-2">
            <h5 class="mt-1 mb-2 uk-text-bold">
                <i class="fas fa-file mr-2"></i> Additional Service 
            </h5>
            <label for="">
                <input type="checkbox" class="checkbox mr-2" />
                Pick Up
            </label>
            <label for="">
                <input type="checkbox" class="checkbox mr-2" />
                Drop Off
            </label>
        </div>
    </div>
</template>


<script>

export default {
    props: {
        companyDetail: Object
    },
    mounted() {
        this.vehicleLists = [{ id:1, 
        brand: 'brand',
        model: 'm3333',
        plate_number: '32xx',
        total_seat: 3,
        color: 'white',
        active: true,
        images:[{id: 1, path: '/assets/img/logo.png'}], 
        prices:[{ id:1, price: 200,
            range_value: 222,
            range_period: 'week'}] }]


        // this.vehiclePrices()
    },
    data() {
        return {
            vehicleLists: [],
            imageLists: [],
            formData: []
        }
    },
    methods: {
        vehiclePrices() {
            const api = 'admin/agencies/' + this.$route.params.id + '/vehicles'
            axios
                .get(api, {
                    params: {
                        page:1,
                        limit:30
                    }
                })
                .then((response) => {
                    this.vehicleLists = response.data.data
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
                
        },
        addVehicle() {
            this.vehicleLists.push({})
        },
        copyVehicle(vehicleId, index) {
            if(vehicleId != undefined){
                this.createVehicle(this.getFormData(index))
            }else{
                this.vehicleLists.push({})
            }
        },
        createVehicle(formData) {
            const api = 'admin/agencies/' + this.$route.params.id + '/vehicles'
            axios
                .post(api, formData)
                .then((response) => {
                    console.log('copied')
                    if(response.data.success) {
                        this.vehicleLists.push(response.data.data)
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        updateVehicle(vehicleId, index) {
            if(vehicleId != undefined) {
                this.updateData(vehicleId, index)
            }else{
                this.createVehicle(this.getFormData(index))
            }
        },
        updateData(vehicleId, index) {
            const api = 'admin/agencies/' + this.$route.params.id + '/vehicles/' + vehicleId
            axios
                .post(api, this.getFormData(index))
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        getFormData(index) {
            // let form = this.$refs.updatePriceForm;
            // let formData = new FormData(form);
            let data = this.vehicleLists[index]
            delete data.prices
            delete data.created_at
            delete data.images
            
            return data
        },
        deleteVehicle(vehicleId, index) {
            if(vehicleId != undefined) {
                const api = 'admin/agencies/' + this.$route.params.id + '/vehicles/' + vehicleId
                axios
                    .delete(api)
                    .then((response) => {
                        console.log('deleted')
                        if(response.data.success) {
                            var notyf = new Notyf();
                            notyf.confirm('Successfully Deleted!');

                            this.vehicleLists.splice(index, 1);
                        }else{
                            this.requiredfileds = response.data
                        }
                    })
                    .catch((error) => {
                        console.error('There was an error!', error.message)
                    })
            }else{
                this.vehicleLists.splice(index, 1);
            }
        },
        addPrice(vehicleIndex) {

            this.vehicleLists[vehicleIndex.split('price')[1]].prices.push({})
        },
        updatePrice(vehicleIndex) {
            let key = vehicleIndex.split('price')
            const api = 'admin/agencies/' + this.$route.params.id + '/vehicles/' + this.vehicleLists[key[1]].id + '/prices'
            axios
                .post(api, { prices: this.vehicleLists[key[1]].prices })
                .then((response) => {
                    console.log(response.data)
                    // this.vehicleLists = response.data.data
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        deleteVPrice(priceIndex, vehicleIndex) {
            this.vehicleLists[vehicleIndex.split('price')[1]].prices.splice(priceIndex, 1); 
            this.updatePrice(vehicleIndex)
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },
        // previewImage(vehicleId, index) {
        //     this.currentVehicleId = vehicleId
        //     this.imageLists = this.vehicleLists[index].images
        // },
        // addImage() {
        //     this.imageLists.push({})
        // },
        // updateImage() {
        //     const api = 'admin/agencies/' + this.$route.params.id + '/vehicles/' + this.currentVehicleId + '/prices'
        //     axios
        //         .post(api, { prices: this.imageLists })
        //         .then((response) => {
        //             console.log(response.data)
        //             // this.vehicleLists = response.data.data
        //         })
        //         .catch((error) => {
        //             console.error('There was an error!', error.message)
        //         })
        // }


    }
}
</script>
<style scoped>
label.form_radio .design {
    width:20px;
    height:20px
}
.save-btn {
    padding: 5px 10px !important;
    height: 30px;
}
.view-btn {
    padding: 5px 10px
}
a.view-btn::before {
    margin-right: 0px;
}
</style>