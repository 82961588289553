<template>
    <div class="row mt-4">
        <div class="col-md-12 pt-3">
            <div class="table-responsive">
                <table class="table data-table table-round table-hover">
                    <thead>
                        <tr>
                            <th width="40%" class="pl-4 pr-4">Days</th>
                            <th width="50%" class="pl-4 pr-4">Course Duration</th>
                            <th class="blankhead" width="auto"></th>
                        </tr>
                    </thead>
                    <tbody>

                        <template v-for="(schedule, sIndex) in scehduledLists" >
                            <template>
                                <tr :key="sIndex">
                                    <td>
                                        <span class="uk-label uk-label-success uk-text-uppercase mr-1">MON</span>
                                        <span class="uk-label uk-label-success uk-text-uppercase mr-1">TUE</span>
                                    </td>
                                    <td>
                                        <span class="uk-label uk-label-success uk-text-uppercase mr-1">10:00 – 11:30</span>
                                        <span class="uk-label uk-label-success uk-text-uppercase mr-1">13:00 – 14:30</span>
                                    </td>
                                    <td class="text-right">
                                        <a v-if="schedule.id != undefined" data-toggle="collapse" v-bind:data-target="`#schedule_form${schedule.id}`"  class="edit-btn clickable mb-2" type="button">Edit</a>
                                        <a @click="deleteScehdule(schedule.id, sIndex)" href="#" class="del-btn mb-2"><i class="fal fa-trash-alt"></i></a>
                                    </td>
                                </tr>
                                <tr :key="'schedule'+sIndex" :id="`schedule_form${schedule.id}`" class="collapse">
                                    <td colspan="3" class="p-0">
                                        <div class="p-4 bg-light" >
                                            <!-- <h4>Prices</h4> -->
                                            <form @submit.prevent="updateSchedule('schedule'+sIndex)" ref="updateScheduleForm">
                                                <div class="col-md-12">
                                                    <span class="uk-text-uppercase uk-text-small">Which Days are you avaliable?</span>
                                                </div>
                                                <div class="col-md-12 ">
                                                    <label class="checkbox mr-3" style="left:0"> <input type="checkbox" class="mr-1" checked> MON </label>
                                                    <label class="checkbox mr-3" style="left:0"> <input type="checkbox" class="mr-1" checked> TUE </label>
                                                    <label class="checkbox mr-3" style="left:0"> <input type="checkbox" class="mr-1" > WED </label>
                                                    <label class="checkbox mr-3" style="left:0"> <input type="checkbox" class="mr-1" > THU </label>
                                                    <label class="checkbox mr-3" style="left:0"> <input type="checkbox" class="mr-1" > FRI </label>
                                                    <label class="checkbox mr-3" style="left:0"> <input type="checkbox" class="mr-1" > SAT </label>
                                                    <label class="checkbox"> <input type="checkbox" class="mr-1" > SUN </label>
                                                </div>
                                                <div class="col-md-12 mt-5">
                                                    <span class="uk-text-uppercase uk-text-small">Schdule Course Date range</span>
                                                </div>
                                                <div class="col-md-12 schedules">
                                                    <span class="uk-label date-range uk-text-uppercase mr-1">
                                                        10:00 – 11:30 
                                                        <i class="fa fa-close"></i>
                                                    </span>

                                                    <span class="uk-label date-range uk-text-uppercase mr-1">
                                                        13:00 – 14:30
                                                        <i class="fa fa-close"></i>
                                                    </span>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-sm-3">
                                                            <div class="form-group">
                                                                <input type="time" class="form-control">
                                                                <label for="">From</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-3">
                                                            <div class="form-group">
                                                                <input type="time" class="form-control">
                                                                <label for="">Till</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-3">
                                                            <a class="save-btn sendmail-btn border-0 mb-2 text-center mt-2">
                                                                <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add Time
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div style="display: inline-flex;float: left;">Activate</div>
                                                            <label class="switch" style="position: relative;top: 1px;">
                                                                <input type="checkbox" checked/>
                                                                <span class="slider round"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-6 text-right">
                                                            <a href="#" class="cancel-btn"> <i class="fas fa-minus-circle mr-2"></i> Cancel </a>
                                                            <button type="submit" id="client" class="save-btn ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                            </form>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                        
                        <tr>
                            <td colspan="3">
                                <a @click="addSchedule" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center">
                                    <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add Schedule
                                </a>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    mounted() {
        this.getSchedules()
    },
    data() {
        return {
            scehduledLists: [],
        }
    },
    methods: {
        getSchedules() {
            const api = 'admin/agencies/' + this.$route.params.id + '/vehicles'
            axios
                .get(api, {
                    params: {
                        page:1,
                        limit:30
                    }
                })
                .then((response) => {
                    this.scehduledLists = response.data.data
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
                
        },
        addSchedule() {
            this.scehduledLists.push({})
        },
        createSchedule(formData) {
            const api = 'admin/agencies/' + this.$route.params.id + '/vehicles'
            axios
                .post(api, formData)
                .then((response) => {
                    console.log('copied')
                    if(response.data.success) {
                        this.scehduledLists.push(response.data.data)
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        updateVehicle(vehicleId, index) {
            if(vehicleId != undefined) {
                this.updateData(vehicleId, index)
            }else{
                this.createSchedule(this.getFormData(index))
            }
        },
        updateData(vehicleId, index) {
            const api = 'admin/agencies/' + this.$route.params.id + '/vehicles/' + vehicleId
            axios
                .post(api, this.getFormData(index))
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        getFormData(index) {
            // let form = this.$refs.updateScheduleForm;
            // let formData = new FormData(form);
            let data = this.scehduledLists[index]
            delete data.prices
            delete data.created_at
            delete data.images
            
            return data
        },
        deleteScehdule(vehicleId, index) {
            if(vehicleId != undefined) {
                const api = 'admin/agencies/' + this.$route.params.id + '/vehicles/' + vehicleId
                axios
                    .delete(api)
                    .then((response) => {
                        console.log('deleted')
                        if(response.data.success) {
                            var notyf = new Notyf();
                            notyf.confirm('Successfully Deleted!');

                            this.scehduledLists.splice(index, 1);
                        }else{
                            this.requiredfileds = response.data
                        }
                    })
                    .catch((error) => {
                        console.error('There was an error!', error.message)
                    })
            }else{
                this.scehduledLists.splice(index, 1);
            }
        },
        addPrice(vehicleIndex) {

            this.scehduledLists[vehicleIndex.split('price')[1]].prices.push({})
        },
        updateSchedule(vehicleIndex) {
            let key = vehicleIndex.split('price')
            const api = 'admin/agencies/' + this.$route.params.id + '/vehicles/' + this.scehduledLists[key[1]].id + '/prices'
            axios
                .post(api, { prices: this.scehduledLists[key[1]].prices })
                .then((response) => {
                    console.log(response.data)
                    // this.scehduledLists = response.data.data
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        deleteVPrice(priceIndex, vehicleIndex) {
            this.scehduledLists[vehicleIndex.split('price')[1]].prices.splice(priceIndex, 1); 
            this.updateSchedule(vehicleIndex)
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },
    }
}
</script>
<style scoped>
/* label.form_radio .design {
    width:20px;
    height:20px
}
.save-btn {
    padding: 5px 10px !important;
    height: 30px;
}
.view-btn {
    padding: 5px 10px
}
a.view-btn::before {
    margin-right: 0px;
} */

.grey-box label.checkbox {
    display: inline-flex;
    float: left;
}
.schedules .date-range .fa-close{
    color: #fff;
}
.schedules .date-range .fa-close:hover{
    cursor: pointer;
}
.schedules .date-range{
    background: #0B63F8;
    color: #fff;
}

</style>