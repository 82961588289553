<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 relative"></div>
      </div>
      <div class="grey-box">
        <div class="table-responsive">
            <table class="table data-table table-round table-hover">
            <thead>
                <tr>
                
                    <th width="30%">
                        User
                        <Sort column="name_en" />
                    </th>
                    <th>
                        Car Rental
                    </th>
                    <th>
                        Fleet
                    </th>
                    <th>
                        Contacts
                    </th>
                    <th>
                        Reports
                    </th>
                    <th>
                        General Settings
                    </th>
                    <th>
                        User Management
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <h6 class="user-roles-name">Hanah Salazar </h6>
                        <span class="user-roles-detail">Manager</span>
                    </td>
                    <td align="right"><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                </tr>
                <tr>
                    <td>
                        <h6 class="user-roles-name">Jogn Doe </h6>
                        <span class="user-roles-detail">Reservation</span>
                    </td>
                    <td align="right"><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                </tr>
                <tr>
                    <td>
                        <h6 class="user-roles-name">Hanah Salazar </h6>
                        <span class="user-roles-detail">Manager</span>
                    </td>
                    <td align="right"><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                </tr>
                <tr>
                    <td>
                        <h6 class="user-roles-name">Jogn Doe </h6>
                        <span class="user-roles-detail">Reservation</span>
                    </td>
                    <td align="right"><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                </tr>
                <tr>
                    <td>
                        <h6 class="user-roles-name">Hanah Salazar </h6>
                        <span class="user-roles-detail">Manager</span>
                    </td>
                    <td align="right"><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                </tr>
                <tr>
                    <td>
                        <h6 class="user-roles-name">Jogn Doe </h6>
                        <span class="user-roles-detail">Reservation</span>
                    </td>
                    <td align="right"><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" checked /></td>
                    <td><input type="checkbox" class="form-control" /></td>
                </tr>
            </tbody>
            <tfoot>
            </tfoot>
            </table>
        </div>
      </div>

    </div>
    
  </template>
  
  
  
  <script>

  export default {
    
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>