<template>
    <section class="row">
        <div class="col-sm-12">
            <Title />
        </div>
        <div class="col-md-12">
            <Breadcrumb />
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>