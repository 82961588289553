<template>
    <div class="p-5">
          <!----- Header Title ----->
        <div class="row">
            <div class="col-md-6">
                <div>
                    <h3 class="page-title mb-1 mt-0">
                        {{ this.$route.meta.title }}
                    </h3>
                </div>
                <div class="breadcrumb-container">
                    <ul class="uk-breadcrumb">
                        <li><span>Admin</span></li>
                        <li><a href="/reservation">Reservation</a></li>
                        <li><a href="">{{ this.$route.meta.title }}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 relative"></div>
        </div>
      <!-- New Reservation -->
      <div class="row align-items-center mb-4">
        <div class="col-lg-12">
            <section class="grey-box register" v-if="!hasSeenCongrats">
                <div class="register-stepper">
                    <div class="step step-done"><span class="step-number">1</span></div>
                    <div class="step step-active"><span class="step-number">2</span></div>
                    <div class="step"><span class="step-number">3</span></div>
                    <div class="step"><span class="step-number">4</span></div>
                    <div class="step"><span class="step-number">5</span></div>
                    <div class="step"><span class="step-number">6</span></div>
                </div>

                <transition name="slide-fade">
                    <section>
                        <form class="form" method="post" action="#" @submit.prevent="next">
                            <p class="text-uppercase text-sm mb-2">Select Available vehicles</p>
                            <hr class="horizontal dark mt-0" />
                            <div class="table-responsive p-0 cta-step mb-3">
                                <table class="table align-items-center mb-0">
                                    <tbody>
                                        <tr>
                                            <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                    <img
                                                        src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                        class="availablecars me-3"
                                                        alt="user1"
                                                    />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center" style="margin-left: 20px;">
                                                <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                                <ul class="car-list">
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                                </ul>
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                                <h3 class="vehicle_price">$35.00 per day</h3>
                                                <router-link :to="'/book/3'">
                                                    <a class="link_text btn btn-sm btn-primary text-white w-100">Avail</a>
                                                </router-link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                <img
                                                    src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                    class="availablecars me-3"
                                                    alt="user1"
                                                />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center" style="margin-left: 20px;">
                                                <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                                <ul class="car-list">
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                                </ul>
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                                <h3 class="vehicle_price">$35.00 per day</h3>
                                                <router-link :to="'/book/3'">
                                                    <a class="link_text btn btn-sm btn-primary text-white w-100">Avail</a>
                                                </router-link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                <img
                                                    src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                    class="availablecars me-3"
                                                    alt="user1"
                                                />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center" style="margin-left: 20px;">
                                                <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                                <ul class="car-list">
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                                </ul>
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                                <h3 class="vehicle_price">$35.00 per day</h3>
                                                <router-link :to="'/book/3'">
                                                    <a class="link_text btn btn-sm btn-primary text-white w-100">Avail</a>
                                                </router-link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                <img
                                                    src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                    class="availablecars me-3"
                                                    alt="user1"
                                                />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center" style="margin-left: 20px;">
                                                <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                                <ul class="car-list">
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                                </ul>
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                                <h3 class="vehicle_price">$35.00 per day</h3>
                                                <router-link :to="'/book/3'">
                                                    <a class="link_text btn btn-sm btn-primary text-white w-100">Avail</a>
                                                </router-link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="d-flex px-2 py-1">
                                                    <div>
                                                    <img
                                                        src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                        class="availablecars me-3"
                                                        alt="user1"
                                                    />
                                                    </div>
                                                    <div class="d-flex flex-column justify-content-center" style="margin-left: 20px;">
                                                    <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                                    <ul class="car-list">
                                                        <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                                        <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                                        <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                                        <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <h3 class="vehicle_price">$35.00 per day</h3>
                                                <router-link :to="'/book/3'">
                                                    <a class="link_text btn btn-sm btn-primary text-white w-100">Avail</a>
                                                </router-link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                <img
                                                    src="https://www.615autorental.com/app/web/upload/medium/615-auto-rental-exotic-car-rental-atlanta-home-page-background-image-gray-mercedes-2716-1696250142.png"
                                                    class="availablecars me-3"
                                                    alt="user1"
                                                />
                                                </div>
                                                <div class="d-flex flex-column justify-content-center" style="margin-left: 20px;">
                                                <h6 class="mb-0 text-sm">Mercedez Benz E-Class</h6>
                                                <ul class="car-list">
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-car" aria-hidden="true"></i> Manual Transmission</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-snowflake-o" aria-hidden="true"></i> Airconditioning</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-play-circle-o" aria-hidden="true"></i> Radio/CD Player</li>
                                                    <li class="text-xs text-secondary mb-0"><i class="fa fa-building-o" aria-hidden="true"></i> 4-doors</li>
                                                </ul>
                                                </div>
                                            </div>
                                            </td>
                                            <td>
                                                <h3 class="vehicle_price">$35.00 per day</h3>
                                                <router-link :to="'/book/2'">
                                                    <a class="link_text btn btn-sm btn-primary text-white w-100">Avail</a>
                                                </router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="form-group cta-step">
                                <div class="cta prev">
                                    <p class="cta-color">
                                        <span class="link_wrap">
                                            <router-link :to="'/book/3'">
                                                <a class="btn prev-btn link_text"><span class="arrow-prev" style="padding-right: 5px;"></span>Previous</a>
                                            </router-link>
                                        </span>
                                    </p>
                                </div>
                                <div class="cta">
                                </div>
                            </div>
                        </form>
                    </section>
                </transition>
            </section>
        </div>
      </div>
    </div>
    
  </template>
  
  
  
  <script>
    import router from '../../routes';

    export default {
        el: "#app",
        data: () => {
            return {
            steps: {},
            paymentopt: 'offline',
            step: 1,
            customer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            hasSeenCongrats: false,
            tempCustomer: {
                pickupDate: "",
                pickupTime: "",
                pickupLoc: "",
                returnDate: "",
                returnTime: "",
                returnLoc: "",
                address: "",
                drivername: "",
            },
            };
        },
    methods: {
        redirectReservation() { // 
            router.push({path: '/reservation/'})
        },

        prev() {
        this.step--;
        },

        next() {
        this.step++;
        },

        newBooking: function () {
            this.hasSeenCongrats = true;
        },
    }

};
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>