import { render, staticRenderFns } from "./PublicReservation2.vue?vue&type=template&id=4fe116c2&scoped=true&"
import script from "./PublicReservation2.vue?vue&type=script&lang=js&"
export * from "./PublicReservation2.vue?vue&type=script&lang=js&"
import style0 from "./PublicReservation2.vue?vue&type=style&index=0&id=4fe116c2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe116c2",
  null
  
)

export default component.exports