<template>
    <div>
        <!----- MISSION DETAILS ----->

        <div class="row align-items-center mb-4">
            <div class="col-lg-12">
                <div class="grey-box p-3 pt-4">
                    <div class="row mb-1">
                        <div class="col-md-6 text-left">
                            <h5 class="mt-1 mb-3 uk-text-bold"> 
                                <i class="fas fa-bullseye-arrow mr-2"></i> Mission Details 
                            </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            
                            <!-- Period -->
                            <div>
                                <ul uk-accordion>
                                    <li class="uk-open with-border">
                                        <a class="uk-accordion-title with-bg" href="#"> 
                                            <i class="fas fa-calendar-week mr-2"></i> Period of the mission
                                        </a>
                                        <div class="uk-accordion-content p-3">
                                            <form @submit.prevent="updatePeriod" ref="updatePeriod">
                                                <div class="row relative pl-5">
                                                    <div class="col-md-6 relative pr-0">
                                                        <div class="form-group relative mission-calendar-field">
                                                            <div class="calendar-icon"></div>
                                                            <input type="date" name="start_date" v-model="period.start_date" class="form-control" required>
                                                            <label>Start date</label>
                                                        </div>
                                                        <div class="form-group relative mission-calendar-field">
                                                            <div class="calendar-icon"></div>
                                                            <input type="date" name="end_date" v-model="period.end_date" class="form-control" required>
                                                            <label>End date</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 pl-0">
                                                        <div class="form-group">
                                                            <input type="time" step="any" name="start_time" v-model="period.start_time" class="form-control" required>
                                                            <label>Start Time</label>
                                                        </div>
                                                        <div class="form-group">
                                                            <input type="time" step="any" name="end_time" v-model="period.end_time" class="form-control" required>
                                                            <label>End Time</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 text-right mb-3">
                                                        <button href="#" class="cancel-btn border-0"> 
                                                            <i class="fas fa-minus-circle mr-2"></i> Cancel 
                                                        </button>
                                                        <button type="submit" id="client" class="save-btn border-0 ml-2 mr-2"> 
                                                            <i class="fas fa-save mr-2"></i> Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <!-- Type of mission -->
                            <div class="mt-3">
                                <ul uk-accordion>
                                    <li class="uk-open with-border">
                                        <a class="uk-accordion-title with-bg" href="#"> 
                                            <i class="fas fa-file-spreadsheet mr-2"></i>
                                            Type of mission
                                        </a>
                                        <div class="uk-accordion-content p-3">
                                            <form @submit.prevent="updateMissionType" ref="updateMissionType">
                                                <div class="row">
                                                    <div class="col-md-6 pl-4">
                                                        <h6 class="mb-2" style="text-transform: uppercase; color: #979797;"> 
                                                            Person Type 
                                                        </h6>
                                                        <div class="form-group">
                                                            <input type="text" name="person_type" v-model="missiontype.person_type" class="form-control" required>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-md-6">
                                                        <h6 class="mb-2" style="text-transform: uppercase; color: #979797;">
                                                            Mission Type 
                                                        </h6>
                                                        <div class="form-group">
                                                            <input type="text" name="mission_type" v-model="missiontype.mission_type" class="form-control" required>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-md-12">
                                                        <button href="#" id="client" class="save-btn addmore-btn border-0 ml-2 mr-2 mt-2"> <i
                                                                class="fas fa-plus mr-2"></i> Add More </button>
                                                    </div> -->
                                                    <div class="col-md-12 pt-2 pl-4">
                                                        <div class="form-group">
                                                            <textarea name="description" v-model="missiontype.description" rows="5" class="note-style" required></textarea>
                                                            <label>Notes</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col-md-12 pl-4 pb-2">
                                                        <h6 class="mb-2" style="text-transform: uppercase; color: #979797;"> 
                                                            Threat
                                                        </h6>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 pl-4">
                                                        <div class="form-group select-dropdown text-white">
                                                            <select name="danger_level" v-model="missiontype.danger_level" class="form-control uk-form-small pt-0 bg-red text-white uk-text-uppercase" required>
                                                                <option value="1"> Level 1: Life & Death </option>
                                                                <option value="2"> Level 2: Life & Death </option>
                                                                <option value="3"> Level 3: Life & Death </option>
                                                                <option value="4"> Level 4: Life & Death </option>
                                                                <option value="5"> Level 5: Life & Death </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 pl-4 pt-2">
                                                        <div class="form-group">
                                                            <textarea name="danger_level_note" v-model="missiontype.danger_level_note" rows="5" class="note-style" required></textarea>
                                                            <label>Notes</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col-md-12 text-right mb-3 pt-3">
                                                        <button href="#" class="cancel-btn border-0"> 
                                                            <i class="fas fa-minus-circle mr-2"></i> Cancel 
                                                        </button>
                                                        <button type="submit" id="client" class="save-btn border-0 ml-2 mr-2"> 
                                                            <i class="fas fa-save mr-2"></i> Save
                                                        </button>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </li>
                                    <!-- <li class="uk-open with-border">
                                        <a class="uk-accordion-title with-bg" href="#"> <i class="fas fa-file-spreadsheet mr-2"></i>
                                            Type of mission</a>
                                        <div class="uk-accordion-content p-3">
                                            <div class="row">
                                                <div class="col-md-12 pl-4 mt-2">
                                                    <h6 class="mb-2" style="text-transform: uppercase; color: #979797;"> Client
                                                        Type </h6>
                                                </div>
                                                <div class="col-md-4 col-sm-12 pr-0 pl-4">
                                                    <div class="form-group select-dropdown">
                                                        <select class="form-control uk-form-small pt-0 bg-blue" style="font-size: 11px;" required>
                                                            <option class="ceo"> CEO </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-sm-12 pr-0 pl-0">
                                                    <div class="form-group select-dropdown">
                                                        <select class="form-control uk-form-small pt-0 bg-blue" style="font-size: 11px;" required>
                                                            <option class="ceo"> VIP </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-sm-12 pl-0">
                                                    <div class="form-group select-dropdown">
                                                        <select class="form-control uk-form-small pt-0 bg-blue" style="font-size: 11px;" required>
                                                            <option class="ceo"> CELEBLITY </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <button href="#" id="client" class="save-btn addmore-btn border-0 ml-2 mr-2 mt-2"> <i
                                                            class="fas fa-plus mr-2"></i> Add More </button>
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-md-12 pl-4 pb-2">
                                                    <h6 class="mb-2" style="text-transform: uppercase; color: #979797;"> Mission
                                                        Type </h6>
                                                </div>
                                                <div class="col-md-4 col-sm-12 pr-0 pl-4">
                                                    <div class="form-group select-dropdown">
                                                        <select class="form-control uk-form-small pt-0 bg-blue" style="font-size: 11px;" required>
                                                            <option class="ceo"> Night Life </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-sm-12 pr-0 pl-0">
                                                    <div class="form-group select-dropdown">
                                                        <select class="form-control uk-form-small pt-0 bg-blue" style="font-size: 11px;" required>
                                                            <option class="ceo"> Under Threat </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-sm-12 pl-0">
                                                    <div class="form-group select-dropdown">
                                                        <select class="form-control uk-form-small pt-0 bg-blue" style="font-size: 11px;" required>
                                                            <option class="ceo"> Other </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <button href="#" id="client" class="save-btn addmore-btn border-0 ml-2 mr-2 mt-2"> <i
                                                            class="fas fa-plus mr-2"></i> Add More </button>
                                                </div>
                                                <div class="col-md-12 pt-2 pl-4">
                                                    <div class="form-group">
                                                        <textarea rows="2" class="note-style" required></textarea>
                                                        <label>Notes</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-md-12 pl-4 pb-2">
                                                    <h6 class="mb-2" style="text-transform: uppercase; color: #979797;"> Threat
                                                    </h6>
                                                </div>
                                                <div class="col-md-12 col-sm-12 pl-4">
                                                    <div class="form-group select-dropdown text-white">
                                                        <select class="form-control uk-form-small pt-0 bg-red text-white uk-text-uppercase"
                                                            required>
                                                            <option class="ceo"> Level 5: Life & Death </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 pl-4 pt-2">
                                                    <div class="form-group">
                                                        <textarea rows="2" class="note-style" required></textarea>
                                                        <label>Notes</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>

                            <!-- Bodyguard -->
                            <div class="mt-3">
                                <ul uk-accordion>
                                    <li class="uk-open with-border">
                                        <a class="uk-accordion-title with-bg" href="#"> 
                                            <i class="fas fa-user-tie mr-2"></i> Bodyguard on mission
                                        </a>
                                        <div class="uk-accordion-content p-3">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group mt-2 pl-0">
                                                        <input type="text" :value="`${hired_staffs.length} People`" readonly>
                                                        <!-- <select class="form-control bg-white" required>
                                                            <option> 4 People </option>
                                                            <option> 5 People </option>
                                                        </select> -->
                                                        <label>Number of Agent</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-for="(staff, staffIndex) in hired_staffs" :key="staffIndex" class="row">
                                                <div class="col-md-12 pt-3 pl-4">
                                                    <h6 class="mb-0 uk-text-uppercase">Local/Expat</h6>
                                                    <div class="row pb-5 pl-1">
                                                        <div class="col-md-3">
                                                            <label class="form_radio">
                                                                <input type="radio" id="medical_needs_y" value="true" name="is_local" v-model="staff.is_local">
                                                                <span class="design"></span>
                                                                <span class="text">Local</span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <label class="form_radio">
                                                                <input type="radio" id="medical_needs_n" value="false" name="is_local" v-model="staff.is_local">
                                                                <span class="design"></span>
                                                                <span class="text">Expat</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 pt-4 pl-4">
                                                    <h6 class="mb-0 uk-text-uppercase">Profile</h6>
                                                    <div class="row pb-5 pl-1">
                                                        <div class="col-md-3">
                                                            <label class="form_radio">
                                                                <input type="radio" id="medical_needs_y" value="low" name="profile" v-model="staff.profile">
                                                                <span class="design"></span>
                                                                <span class="text">Low</span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <label class="form_radio">
                                                                <input type="radio" id="medical_needs_n" value="high" name="profile" v-model="staff.profile">
                                                                <span class="design"></span>
                                                                <span class="text">High</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 pt-4 pl-4">
                                                    <h6 class="mb-0 uk-text-uppercase">Gender</h6>
                                                    <div class="row pb-5 pl-1">
                                                        <div class="col-md-3">
                                                            <label class="form_radio">
                                                                <input type="radio" id="medical_needs_y" value="male" name="gender" v-model="staff.gender">
                                                                <span class="design"></span>
                                                                <span class="text">Male</span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <label class="form_radio">
                                                                <input type="radio" id="medical_needs_n" value="female" name="gender" v-model="staff.gender">
                                                                <span class="design"></span>
                                                                <span class="text">Female</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 pt-3 pl-4">
                                                    <h6 class="mb-0 uk-text-uppercase">Armed</h6>
                                                    <div class="row pb-5 pl-1">
                                                        <div class="col-md-3">
                                                            <label class="form_radio">
                                                                <input type="radio" id="armed_y" value="true" name="is_armed" v-model="staff.is_armed">
                                                                <span class="design"></span>
                                                                <span class="text">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <label class="form_radio">
                                                                <input type="radio" id="armed_n" value="false" name="is_armed" v-model="staff.is_armed">
                                                                <span class="design"></span>
                                                                <span class="text">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 pt-3 pl-4">
                                                    <h6 class="mb-0 uk-text-uppercase">Spoken English</h6>
                                                    <div class="row pl-1">
                                                        <div v-for="(language, lIndex) in staff.spoken_languages" :key="lIndex" class="col-md-3 col-sm-12 pr-0 pl-1">
                                                            <div class="form-group select-dropdown mt-3">
                                                                <select name="spoken_languages[]" label="text" required="required"
                                                                    class="form-control uk-form-small pt-0 bg-blue uk-text-uppercase"
                                                                    style="font-size: 11px">
                                                                    <option v-for="(lang, langIndex) in languages" :key="langIndex" :value="lang.code" :selected="language == lang.code? 'selected' : ''"> {{lang.text}} </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-sm-12 pr-1 pl-1">
                                                            <button @click="addLanguage(staffIndex)" class="cancel-btn border-0 mt-3 mr-0 add-more-btn">
                                                                <i class="fas fa-plus mr-1" style="font-size: 10px;"></i> Add more
                                                            </button>
                                                        </div>
                                                        
                                                        <div class="col-md-12 text-right mb-4 mt-3">
                                                            <button class="cancel-btn border-0"> 
                                                                <i class="fas fa-minus-circle mr-2"></i> Cancel
                                                            </button>
                                                            <button type="submit" class="save-btn border-0 ml-2 mr-2"> 
                                                                <i class="fas fa-save mr-2"></i> Save
                                                            </button>
                                                        </div>
                                                        <div class="col-sm-12">
                                                            <hr />
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- end bodyguard -->

                                                <div class="col-md-12 pt-3 pl-4">
                                                    <h6 class="mb-2 uk-text-uppercase">Accommodation</h6>
                                                    <template v-for="(accommodation, aIndex) in accommoditions" >
                                                        <form :key="aIndex" @submit.prevent="updateAccommodation" ref="updateAccommodation1" >
                                                            <div class="row pb-5 pl-1">
                                                                <div class="col-md-6">
                                                                    <label class="form_radio">
                                                                        <input type="radio" id="accomodation_for_client1" value="client" name="accomodation_for" v-model="accommodation.accomodation_for">
                                                                        <span class="design"></span>
                                                                        <span class="text">Client</span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label class="form_radio">
                                                                        <input type="radio" id="accomodation_for_bodyguard1" value="staff" name="accomodation_for" v-model="accommodation.accomodation_for">
                                                                        <span class="design"></span>
                                                                        <span class="text">Bodyguard</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                                
                                                            <!-- if bodyguard, show another info -->
                                                            <!-- <div class="row" v-show="accommodation.accomodation_for == 'staff' "> -->
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <select name="type" v-model="accommodation.type" class="form-control" required>
                                                                            <option value="hotel">Hotel</option>
                                                                            <option value="guest_house">Guest House</option>
                                                                        </select>
                                                                        <label>Type</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <input type="text" name="name1" v-model="accommodation.name" class="form-control" required>
                                                                        <label>Name</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <textarea name="description" v-model="accommodation.description" cols="30" rows="10" class="form-control"></textarea>
                                                                        <label>Description</label>
                                                                    </div>
                                                                </div>
                                                                <!-- address -->
                                                                <div class="col-md-12 text-right mb-3 pt-3">
                                                                    <button class="cancel-btn border-0"> 
                                                                        <i class="fas fa-minus-circle mr-2"></i> Cancel
                                                                    </button>
                                                                    <button type="submit" class="save-btn border-0 ml-2 mr-2"> 
                                                                        <i class="fas fa-save mr-2"></i> Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </template>
                                                </div>
                                                <!-- end accommoditions -->
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-md-8">
                            <!-- Locations of the mission -->
                            <div>
                                <ul uk-accordion>
                                    <li class="uk-open with-border">
                                        <a class="uk-accordion-title with-bg" href="#"> 
                                            <i class="fas fa-map-marker-alt mr-2"></i> Locations of the mission
                                        </a>
                                        <div class="uk-accordion-content p-3 relative">
                                            <form @submit.prevent="updateLocation">
                                                <div v-for="(location, locationIndex) in mission_locations" :key="locationIndex" class="row">
                                                    <div class="col-md-12">
                                                        <div class="row relative pl-5 mission-location-field">
                                                            <div class="col-md-3 location-icon">
                                                                <div class="form-group">
                                                                    <input type="text" v-model="mission_locations[locationIndex].full_address" class="form-control" required>
                                                                    <label>Meeting Location</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 pr-0">
                                                                <div class="form-group calendar">
                                                                    <input type="text" v-model="mission_locations[locationIndex].appointment_date" class="form-control datepicker-default" required>
                                                                    <label>Date</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 pr-0">
                                                                <div class="form-group">
                                                                    <input type="time" v-model="mission_locations[locationIndex].appointment_time" class="form-control" required>
                                                                    <label>Start Time</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 text-right pt-2">
                                                                <a v-show="locationIndex > 0" href="#" @click="deleteLocation(locationIndex)" class="del-btn uk-position-top-right mt-2 mr-4">
                                                                    <i class="fas fa-trash-alt" aria-hidden="true"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="row relative pl-5 mission-location-field">
                                                            <div class="col-md-3 location-icon">
                                                                <div class="form-group">
                                                                    <input type="text" name="fname" value="Pattaya" class="form-control" required>
                                                                    <label>Destination 1</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 pr-0">
                                                                <div class="form-group calendar">
                                                                    <input type="text" name="fname" value="15 JUL 2022"
                                                                        class="form-control datepicker-default" required>
                                                                    <label>Date</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 pr-0">
                                                                <div class="form-group">
                                                                    <input type="time" name="fname" value="10:25" class="form-control" required>
                                                                    <label>Start Time</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 text-right pt-2">
                                                                <a href="#" class="del-btn uk-position-top-right mt-2 mr-4"><i class="fas fa-trash-alt"
                                                                        aria-hidden="true"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="row relative pl-5 mission-location-field">
                                                            <div class="col-md-3 location-icon">
                                                                <div class="form-group">
                                                                    <input type="text" name="fname" value="Pattaya" class="form-control" required>
                                                                    <label>Destination 2</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 pr-0">
                                                                <div class="form-group calendar">
                                                                    <input type="text" name="fname" value="15 JUL 2022"
                                                                        class="form-control datepicker-default" required>
                                                                    <label>End Date</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 text-right pt-2">
                                                                <a href="#" class="del-btn uk-position-top-right mt-2 mr-4"><i class="fas fa-trash-alt"
                                                                        aria-hidden="true"></i></a>
                                                            </div>
                                                        </div> -->
                                                        <div class="row pl-5">
                                                            <div class="col-md-12 text-right mb-3">
                                                                <button href="#" class="cancel-btn border-0"> 
                                                                    <i class="fas fa-minus-circle mr-2"></i> Cancel
                                                                </button>
                                                                <button type="submit" id="client" class="save-btn border-0 ml-2 mr-2"> 
                                                                    <i class="fas fa-save mr-2"></i> Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <!-- Transportation for the mission -->
                            <div class="mt-3">
                                <ul uk-accordion>
                                    <li class="uk-open with-border">
                                        <a class="uk-accordion-title with-bg" href="#"> 
                                            <i class="fal fa-car-alt mr-2"></i> Transportation for the mission
                                        </a>
                                        <div class="uk-accordion-content p-2 relative">
                                            <div v-show="transportations.length == 0" class="row mt-2">
                                                <div class="col-md-12">
                                                    <h6 class="mb-0" style="text-transform: uppercase; color: #979797;">
                                                        Do client need transportation 
                                                    </h6>
                                                </div>
                                                <div class="col-md-5 mb-5">
                                                    <label class="form_radio mt-0 pt-0">
                                                        <input type="radio" id="transportation_y" value="yes" disabled />
                                                        <span class="design"></span>
                                                        <span class="text">Yes</span>
                                                    </label>
                                                </div>
                                                <div class="col-md-5 mb-5">
                                                    <label class="form_radio mt-0 pt-0">
                                                        <input type="radio" id="transportation_n" value="no" :checked="true" disabled />
                                                        <span class="design"></span>
                                                        <span class="text">No</span>
                                                    </label>
                                                </div>
                                            </div>
                                            
                                            <div v-for="(transportation, tIndex) in transportations" :key="tIndex" class="row pl-4" id="mission-location-1">
                                                <div class="col-md-6">
                                                    <div class="row mt-2">
                                                        <div class="col-md-12">
                                                            <h6 class="mb-0" style="text-transform: uppercase; color: #979797;">
                                                                Do client need transportation 
                                                            </h6>
                                                        </div>
                                                        <div class="col-md-5 mb-5">
                                                            <label class="form_radio mt-0 pt-0">
                                                                <input type="radio" id="transportation_y" value="yes" :checked="true" disabled />
                                                                <span class="design"></span>
                                                                <span class="text">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div class="col-md-5 mb-5">
                                                            <label class="form_radio mt-0 pt-0">
                                                                <input type="radio" id="transportation_n" value="no" disabled />
                                                                <span class="design"></span>
                                                                <span class="text">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group mt-4 pl-0">
                                                        <select v-model="transportations[tIndex].no_of_pessenger" class="form-control bg-white" required>
                                                            <option v-for="(people, pIndex) in [1,2,3,4,5,6,7,8,9,10]" :key="pIndex" :value="people"> 
                                                                {{ people }} People 
                                                            </option>
                                                        </select>
                                                        <label>Number of passenger</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="row mt-2">
                                                        <div class="col-md-12">
                                                            <h6 class="mb-0" style="text-transform: uppercase; color: #979797;">
                                                                Schedule Transportation 
                                                            </h6>
                                                        </div>
                                                        <div class="col-md-12 mb-5 pt-3">
                                                            <v2-datepicker v-model="transportations[tIndex].date_time"></v2-datepicker>
                                                            <!-- <div :id="`calendar${tIndex}`" class="pr-3"></div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="row mt-2">
                                                        <div class="col-md-12">
                                                            <h6 class="mb-0" style="text-transform: uppercase; color: #979797;">
                                                                Selected Vehicle 
                                                            </h6>
                                                        </div>
                                                        <div class="col-md-12 mb-5 pt-3">
                                                            <div class="vehicle-wrapper">
                                                                <div class="vehicle">
                                                                    <div class="img-wrapper">
                                                                        <img class="uk-border-rounded"
                                                                            src="https://www.autoinfo.co.th/wp-content/uploads/2021/11/AUDI-A8-2022-30.jpg"
                                                                            width="200" height="150" alt="Border rounded">
                                                                    </div>
                                                                    <div class="content">
                                                                        <div class="label">
                                                                            <span class="uk-label ml-1">{{ transportation.vehicle.name_en }}</span>
                                                                            <span class="uk-label ml-1">{{ transportation.vehicle.total_seat }} Seats</span>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-md-2">
                                                                                <div>
                                                                                    <label> Brand </label>
                                                                                    <p class="value"> {{ transportation.vehicle.brand }} </p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <div>
                                                                                    <label> Model </label>
                                                                                    <p class="value"> {{ transportation.vehicle.model }} </p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-4">
                                                                                <div>
                                                                                    <label> Extra Cost </label>
                                                                                    <p class="value"> {{ transportation.formatted_cost }} </p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3 text-right">
                                                                                <button data-toggle="collapse" v-bind:data-target="`#vehicleLists${tIndex}`" class="cancel-btn border-0 mt-3 mr-0" style="border: solid 1px #e75252 !important;">
                                                                                    <i class="fas fa-sync-alt mr-2" style="font-size: 13px;"></i> Change 
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- vehicle lists -->
                                                                <div class="vehicle collapse mt-2" :id="`vehicleLists${tIndex}`" >
                                                                    <div class="row">
                                                                        <div class="col-sm-12">
                                                                            <div class="row">
                                                                                <div class="col-sm-12">
                                                                                    <p>Select Vehicle</p>
                                                                                </div>

                                                                                <div v-for="(vehicle, vIndex) in vehicleLists" :key="vIndex" class="col-sm-12 mb-2">
                                                                                    <div class="vehicle">
                                                                                        <div class="img-wrapper">
                                                                                            <img class="uk-border-rounded"
                                                                                                src="https://www.autoinfo.co.th/wp-content/uploads/2021/11/AUDI-A8-2022-30.jpg"
                                                                                                width="200" height="150" alt="Border rounded">
                                                                                        </div>
                                                                                        <div class="content">
                                                                                            <div class="label">
                                                                                                <span class="uk-label ml-1">{{ vehicle.name_en }}</span>
                                                                                                <span class="uk-label ml-1">{{ vehicle.total_seat }} Seats</span>
                                                                                            </div>
                                                                                            <div class="row">
                                                                                                <div class="col-md-2">
                                                                                                    <div>
                                                                                                        <label> Brand </label>
                                                                                                        <p class="value"> {{ vehicle.brand }} </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-3">
                                                                                                    <div>
                                                                                                        <label> Model </label>
                                                                                                        <p class="value"> {{ vehicle.model }} </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-4">
                                                                                                    <div>
                                                                                                        <label> Extra Cost </label>
                                                                                                        <!-- <p class="value"> {{ formatted_cost }} </p> -->
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-md-3 text-right">
                                                                                                    {{transportation.id}}
                                                                                                    <button @click="selectVehicle(vIndex, tIndex, transportation.id)" data-toggle="collapse" v-bind:data-target="`#vehicleList${tIndex}`" class="add-more-btn border-0 mt-3 mr-0">
                                                                                                        <i class="fas fa-sync-alt mr-2" style="font-size: 13px;"></i> Select 
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <!------- Police escort for the mission ------>

                            <div class="mt-3">
                                <ul uk-accordion>
                                    <li class="uk-open with-border">
                                        <a class="uk-accordion-title with-bg" href="#"> 
                                            <i class="fas fa-siren-on mr-2"></i> Police escort for the mission
                                        </a>
                                        <div class="uk-accordion-content p-2 mb-3 relative">
                                            <form @submit.prevent="updateEscort">
                                                <div class="row pl-2" id="mission-location-1">
                                                    <div class="col-md-6">
                                                        <div class="row mt-2">
                                                            <div class="col-md-12">
                                                                <h6 class="mb-0" style="text-transform: uppercase; color: #979797;">
                                                                    Police Escort 
                                                                </h6>
                                                            </div>
                                                            <div class="col-md-5 mb-5">
                                                                <label class="form_radio mt-0 pt-0">
                                                                    <input type="radio" id="transportation_y" value="yes" :checked="escortLists.length > 0? true : false" disabled />
                                                                    <span class="design"></span>
                                                                    <span class="text">Yes</span>
                                                                </label>
                                                            </div>
                                                            <div class="col-md-5 mb-5">
                                                                <label class="form_radio mt-0 pt-0">
                                                                    <input type="radio" id="transportation_n" value="no" :checked="escortLists.length > 0? false : true" disabled />
                                                                    <span class="design"></span>
                                                                    <span class="text">No</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="clearfix"></div>
                                                    <div v-for="(escort, escortIndex) in escortLists" :key="escortIndex" class="col-md-12 mt-1">
                                                        <div class="row transportation-input">
                                                            <div class="col-md-11 col-sm-12 relative">
                                                                <div class="row">
                                                                    <div class="col-md-3 col-sm-12 pr-0">
                                                                        <div class="form-group ">
                                                                            <input type="date" v-model="escortLists[escortIndex].date" required="required" class="form-control ">
                                                                            <label>Date</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-3 col-sm-12 pl-0 pr-0">
                                                                        <div class="form-group">
                                                                            <input type="time" v-model="escortLists[escortIndex].time" required="required" class="form-control">
                                                                            <label>Start Time</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-3 col-sm-12 pl-0 pr-0">
                                                                        <div class="form-group pl-0">
                                                                            <select v-model="escortLists[escortIndex].type_of_vehicle" class="form-control bg-white" required>
                                                                                <option value="motor_cycle">Motorcycle </option>
                                                                                <option value="car"> Car </option>
                                                                            </select>
                                                                            <label>Type of vehicle</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-3 pl-0 pr-0">
                                                                        <div class="form-group">
                                                                            <select v-model="escortLists[escortIndex].no_of_vehicle" class="form-control bg-white" required>
                                                                                <option v-for="(num, nmIndex) in [1,2,3,4,5,6,7,8,9,10]" :key="nmIndex" :value="num" > {{ num }} </option>
                                                                            </select>
                                                                            <label>Number of vehicle</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="clearfix"></div>
                                                                    <div class="col-md-6 col-sm-12 relative pr-0">
                                                                        <div class="form-group with-icon">
                                                                            <input type="text" v-model="escortLists[escortIndex].address.from.address" class="form-control border-blue uk-text-uppercase" required>
                                                                            <label>From</label>
                                                                            <i class="fas fa-map-marker-alt"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12 relative pl-0 pr-0">
                                                                        <div class="line"></div>
                                                                        <div class="form-group with-icon">
                                                                            <input type="text" v-model="escortLists[escortIndex].address.to.address" class="form-control border-blue uk-text-uppercase" required>
                                                                            <label>To</label>
                                                                            <i class="fas fa-map-marker-alt"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-1 col-sm-12 text-right">
                                                                <a @click="deleteEscort(escortIndex)" class="del-btn uk-position-top-right mt-5 mr-4">
                                                                    <i class="fas fa-trash-alt"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="clearfix clearboth"></div>
                                                    <div class="col-md-12 text-right mb-3 pt-2">
                                                        <hr class="mt-0">
                                                        <button href="#" class="cancel-btn border-0">
                                                            <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel 
                                                        </button>
                                                        <button type="submit" class="save-btn border-0 ml-2 mr-2">
                                                            <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!------- Mission History ------>

        <div class="row align-items-center mb-3">
            <div class="col-lg-12">
                <div class="grey-box p-3 pt-4">
                    <div class="row">
                        <div class="col-md-6 text-left">
                            <h5 class="mt-1 mb-4 uk-text-bold"> 
                                <i class="fas fa-dollar-sign mr-2"></i> Payment History 
                            </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table data-table table-round table-hover">
                                    <thead>
                                        <tr>
                                            <th width="20%">
                                                Date
                                                <!-- <Sort column="id" @change="sort" /> -->
                                            </th>
                                            <th width="auto">
                                                Payment Status
                                                <!-- <Sort column="start_date" @change="sort" /> -->
                                            </th>
                                            <th class="text-right" width="15%">
                                                Amount
                                                <!-- <Sort column="formatted_total_price" @change="sort" /> -->
                                            </th>
                                            <th class="text-right pr-4" width="15%">
                                                Agency Name
                                                <!-- <Sort column="status" @change="sort" /> -->
                                            </th>
                                            <th class="text-right pr-4" width="15%">
                                                Customer Name
                                                <!-- <Sort column="status" @change="sort" /> -->
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(payment, pIndex) in missionDetail.customer.mission_payments" :key="pIndex">
                                            <td class="time text-left" style="font-size: 12px;">
                                                <i class="fal fa-calendar mr-1 relative" style="top: 1px;">
                                                    {{ payment.created_at | splitDate }}
                                                </i>
                                                <span class="end-time ml-1" style="font-size: 11px;">
                                                    {{ payment.created_at | splitTime }}
                                                </span>
                                            </td>
                                            <td class="text-left">
                                                {{ payment.payment_status }}
                                            </td>
                                            <td class="text-right">
                                                {{ payment.total + ' ' + payment.currency }} 
                                                <!-- <span class="balance-up">  -->
                                                <!-- <span class="balance-down">  -->
                                                <!-- </span> -->
                                                <!-- </span> -->
                                            </td>
                                            <td class="text-left">
                                                {{ payment.agency_name }}
                                            </td>
                                            <td class="text-left">
                                                {{ payment.custoemr_name }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-md-6 text-left pt-4 pl-4">
                            <p class="uk-text-uppercase pt-2" style="font-size: 11px; letter-spacing: 0.03em; color: #999">
                                Payment Provider <img src="/assets/img/tazapay-logo.png" class="ml-2 mb-1" style="height: 20px;"> 
                            </p>
                        </div>
                        <div class="col-md-6 text-right pt-4 pl-4">
                            <button href="#" class="save-btn sendmail-btn border-0">
                                <i class="fas fa-envelope mr-2"></i> Email Invoice 
                            </button>
                            <button href="#" id="client" class="save-btn download-btn border-0 ml-2 mr-2">
                                <i class="fas fa-arrow-to-bottom mr-2"></i> Download PDF 
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!------- Legal Agreement ------>

        <div class="row align-items-center mb-4">
            <div class="col-lg-12">
                <div class="grey-box p-3 pt-4">
                    <div class="row">
                        <div class="col-md-6 text-left">
                            <h5 class="mt-1 mb-4 uk-text-bold"> 
                                <i class="fas fa-file-alt mr-2"></i> Legal Agreement
                            </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <fieldset class="pt-2">
                                <ul uk-tab>
                                    <li class="uk-active">
                                        <a href="#" class="no-bullet" style="text-transform: uppercase !important;">
                                            Legal Agreement (English)
                                        </a>
                                    </li>
                                    <!-- <li>
                                        <a href="#" class="no-bullet" style="text-transform: uppercase !important;">
                                            Legal Agreement (Thai)
                                        </a>
                                    </li> -->
                                </ul>
                                <ul class="uk-switcher uk-margin mt-0 mb-0">
                                    <li>
                                        <div class="form-group ml-2">
                                            <div class="form-group">
                                                <!-- <client-only> -->
                                                    <VueEditor v-model="legal_agreement.legal_agreement_text" />
                                                <!-- </client-only> -->
                                            </div>
                                            <!-- <textarea rows="20" name="legal_agreement" class="p-3" style="border: none; background-color: #fff !important; border:solid 1px #f1f1f1; color: #575757 !important; font-weight: normal; font-size: 13.5px; line-height: 30px;">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                                </textarea> -->
                                        </div>
                                        <div class="col-md-6 mb-md-0">
                                            <h6 style="font-weight: 600; color: #111;"> 
                                                <span uk-icon="upload"></span> Upload Document 
                                            </h6>
                                            <div class="js-upload uk-placeholder uk-text-center mr-2">
                                                <div class="mb-2 mt-2">
                                                    <span class="uk-link btn-upload"> Upload </span>
                                                </div>
                                                <span uk-icon="icon: cloud-upload" class="mr-2"></span>
                                                <span class="uk-text-middle"> Drag & Drop file </span>
                                                <div uk-form-custom>
                                                    <input
                                                        type="file"
                                                        class="file-upload"
                                                        ref="legalAgreementDoc"
                                                        v-on:change="legalAgreementDocUpload()"
                                                    />
                                                    <img v-if="legalUrl" :src="legalUrl" />
                                                    <!-- {{legalUrl}} -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 text-right mb-3">
                                            <button @click="updateLegal" type="submit" id="client" class="save-btn border-0 ml-2 mr-2"> 
                                                <i class="fas fa-save mr-2"></i> Save
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </fieldset>
                        </div>
                        <div class="col-md-12 pl-4 pr-1 pb-0">
                            <div class="row w-100 bg-lightgray border-radius-default pb-0 mb-0">
                                <div class="col-md-2 mb-0 pb-0">
                                    <button class="save-btn download-btn border-0 ml-2 mr-2 mt-4 mb-2">
                                        <i class="fas fa-arrow-to-bottom mr-2"></i> Download PDF
                                    </button>
                                    <!--                                     
                                    <div class="p-3 pb-0 mb-0">
                                        <span class="uk-text-uppercase"> Signed date </span>
                                        <p class="uk-text-uppercase mt-0 pt-2 pb-0 mb-0"> 25 Aug 2022 </p>
                                    </div> -->
                                </div>
                                <!-- <div class="col-md-3 mb-0 pb-0">
                                    <div class="p-3 pb-0 mb-0">
                                        <span class="uk-text-uppercase"> Signed From </span>
                                        <p class="uk-text-uppercase mt-0 pt-2 pb-0 mb-0"> Macbook Pro 2021 - M1 |
                                            Safari </p>
                                    </div>
                                </div>
                                <div class="col-md-2 mb-0 pb-0">
                                    <div class="p-3 pb-0 mb-0">
                                        <span class="uk-text-uppercase"> IP Address </span>
                                        <p class="uk-text-uppercase mt-0 pt-2 pb-0 mb-0"> 171.50.49.132 </p>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-0 pb-0 text-right">
                                    
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
  
    props:{
        missionDetail: Object
    },
    data() {
        return {
            period:{
                start_date: '',
                start_time: '',
                end_date: '',
                end_time: ''
            },
            missiontype: {
                person_type: '',
                mission_type: '',
            },
            hired_staffs: [],
            accommoditions: [],
            transportations: [],
            mission_locations: [],
            vehicleLists: [],
            escortLists: [],
            legal_agreement: {
                legal_agreement_text: '',
                legal_agreement_doc: '',
            },
            legalUrl: '',
            languages: [{'code':'th', 'text': 'Thai'}, {'code':'en','text': 'English'}, {'code':'ch', 'text': 'Chinese'}]
        }
    },
	mounted() {
        this.setPeriodData()
        this.setMissionTypeData()
        this.setBodyguardData()
        this.setTransportation()
        this.setLocations()
        this.setLegal()
        this.setVehicles()
        this.setPoliceEscort()
    },
    
    methods: {
        setPeriodData() {
            this.period = {
                start_date: this.missionDetail.start_date,
                start_time: this.missionDetail.start_time,
                end_date: this.missionDetail.end_date,
                end_time: this.missionDetail.end_time,
            }
        },
        setMissionTypeData() {
            this.missiontype = {
                person_type: this.missionDetail.person_type,
                mission_type: this.missionDetail.mission_type,
                description: this.missionDetail.description,
                danger_level: this.missionDetail.danger_level,
                danger_level_note: this.missionDetail.danger_level_note,
            }
        },
        setBodyguardData() {
            this.hired_staffs = this.missionDetail.hired_staff
            this.accommoditions = this.missionDetail.accomodations
        },
        setTransportation() {
            this.transportations = this.missionDetail.transportations
            // console.log(this.transportations)
            if(this.transportations.length > 0) {
            this.jsSetup()
            }
        },
        setLocations() {
            this.mission_locations = this.missionDetail.mission_locations
            // this.mission_locations = [{
            //     "id": 3,
            //     "mission_id": 3,
            //     "name": "Meeting Point",
            //     "province": "Bangkok",
            //     "district": "phrakhnong",
            //     "sub_district": "sub",
            //     "full_address": "This is Full Address ...",
            //     "appointment_date": "2022-10-09",
            //     "appointment_time": "15:30:00",
            //     "lat": "-68.411699",
            //     "lng": null,
            //     "step": 1
            // }]
        },
        setLegal() {
            this.legal_agreement = { 
                legal_agreement_text: this.missionDetail.legal_agreement_text,
                legal_agreement_doc: this.missionDetail.legal_agreement_doc
            }
        },
        setVehicles() {
            if(this.transportations.length > 0) {
                this.getVehicleLists()
            }
        },
        getVehicleLists() {
            let total_seat = 4
            let limit = 10
            let page = 1
            const api = 'admin/agencies/' + this.missionDetail.agency_id + '/vehicles'
			axios.get(api,{
                params: {
                    total_seat,
                    page,
                    limit
                }
            })
            .then(response => {
               
                if(response.data.success) {
                    this.vehicleLists = response.data.data
                }
            })
            .catch(error => {
                this.errorMessage = error.message
                console.error('There was an error!', error)
            })
        },
        setPoliceEscort() {
            if(this.missionDetail.escorts.length > 0) {
                this.escortLists = this.missionDetail.escorts.slice().sort()

                this.escortLists.map(function(escort) {
                    escort.date = escort.date_time.split(' ')[0]
                    escort.time = escort.date_time.split(' ')[1]
                })
            }
        },
        updateEscort() {
            var notyf = new Notyf();
            console.log(this.escortLists)
			// const api = 'admin/missions/' + this.$route.params.id + '/escort'
            // axios.post(api, {
            //     prices: this.escortLists
            // })
			// 	.then(response => {
            //         if(response.data.success) {
			// 		    notyf.confirm('Updated!');
            //         }else{
			// 		    notyf.confirm(response.data.message);
            //         }
			// 	})
			// 	.catch(error => {
			// 		notyf.confirm(error.message);
			// 		console.error('There was an error!', error)
			// 	})
        },
        deleteEscort(index) {
            this.escortLists.splice(index, 1)
        },
        addLanguage(staffIndex) {
            if(this.hired_staffs[staffIndex].spoken_languages.length < this.languages.length){
                this.hired_staffs[staffIndex].spoken_languages.push({})
            }
        },
        updatePeriod() {
            let form = this.$refs.updatePeriod;
            let formData = new FormData(form);
			formData.append('start_time', formData.get('start_date') + ' ' + formData.get('start_time'))
			formData.append('end_time', formData.get('end_date') + ' ' + formData.get('end_time'))
            this.updateGeneral(formData)
		},
        updateMissionType() {
            let form = this.$refs.updateMissionType;
            let formData = new FormData(form);
            this.updateGeneral(formData)
        },
        updateGeneral(formData) {

			const api = 'admin/missions/' + this.$route.params.id + '/general'
            axios.post(api, formData)
				.then(response => {
					var notyf = new Notyf();
					notyf.confirm('Saved!');
				})
				.catch(error => {
					this.errorMessage = error.message
					console.error('There was an error!', error)
				})
        },
        updateAccommodation() {
            console.log('accommodationId')
            // const api = 'admin/missions/' + this.$route.params.id + '/accomodations/' + accommodationId

			let form = this.$refs.updateAccommodation1;
            let formData = new FormData(form);
            console.log(formData)
			// axios.post(api, formData)
			// 	.then(response => {
			// 		var notyf = new Notyf();
			// 		notyf.confirm('Updated!');
			// 	})
			// 	.catch(error => {
			// 		this.errorMessage = error.message
			// 		console.error('There was an error!', error)
			// 	})
        },
        updateLocation() {
            const api = 'admin/missions/' + this.$route.params.id + '/location'
			axios.post(api, {
                locations: this.mission_locations
            })
            .then(response => {
                var notyf = new Notyf();
                notyf.confirm('Updated!');
            })
            .catch(error => {
                this.errorMessage = error.message
                console.error('There was an error!', error)
            })
        },
        deleteLocation(index) {
            this.mission_locations.splice(index, 1)
        },
        selectVehicle(vIndex, tIndex, transportationId) {
            let selectedVehicle = this.vehicleLists[vIndex]
            let lastStep = this.transportations[this.transportations.length-1].step
            let formData = {
                "vehicle_id": selectedVehicle.id,
                "step": this.transportations[tIndex].step,
                "with_driver": this.transportations[tIndex].with_driver,
                "police_escort": selectedVehicle.is_police_vehicle,
                "vehicle_brand": selectedVehicle.brand,
                "vehicle_name": selectedVehicle.name_en,
                "vehicle_model": selectedVehicle.model,
                "vehicle_plate_number": selectedVehicle.plate_number,
                "date_time": this.transportations[tIndex].date_time,
                "address": this.transportations[tIndex].address
            }
            this.updateTransportation(tIndex, transportationId, formData)
        },
        updateTransportation(tIndex, transportationId, formData) {
            console.log(formData)
            var notyf = new Notyf();
            
            const api = 'admin/missions/' + this.$route.params.id + '/transportations/' + transportationId
            axios.post(api, formData)
				.then(response => {
                    var notyf = new Notyf();
                    if(response.data.success) {
                        

                        this.transportations = []
                        this.setTransportation()
                        this.transportations[tIndex] = response.data.data

                        notyf.confirm('Updated!');
                    }else{
                        notyf.alert(response.data.message);
                    }
				})
				.catch(error => {
                    notyf.alert(error.message);
				})
        },
        legalAgreementDocUpload() {
            this.legal_agreement.legal_agreement_doc = this.$refs.legalAgreementDoc.files[0]
            this.legalUrl = URL.createObjectURL(this.legal_agreement.legal_agreement_doc)
        },
        updateLegal () {
            let formData = new FormData()
                formData.append('legal_agreement_text', this.legal_agreement.legal_agreement_text)
                formData.append('legal_agreement_doc', this.legal_agreement.legal_agreement_doc)
            
            const api = 'admin/missions/' + this.$route.params.id + '/general'
            axios.post(api, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                })
				.then(response => {
					var notyf = new Notyf();
					notyf.confirm('Saved!');
				})
				.catch(error => {
					this.errorMessage = error.message
					console.error('There was an error!', error)
				})
        },
        jsSetup() {
           

            // Daterange Picker by jQuery
            $(function () {

                var booked = ["2022-08-02", "2022-08-04", "2022-08-06", "2022-08-10", "2022-08-20", "2022-08-21", "2022-08-22"];
                // console.log('calendar i ' + index)
                // $("#calendar" + index).datepicker({
                //     numberOfMonths: 2,
                //     beforeShowDay: function (date) {
                //         var dateISO = $.datepicker.formatDate('yy-mm-dd', date);
                //         var vehicleDate = $.datepicker.formatDate('yy-mm-dd', value.date_time);
                //         //console.log(dateISO);

                //         if (vehicleDate == dateISO) {
                //             return [true, "booked"]
                //         }
                //         return [true]
                //     }
                // });


            });
            
        },

      
        // updateObject(object) {
		// 	const api = 'admin/missions/' + this.$route.params.id + '/objects/' + object.id

		// 	let form = this.$refs.updateObject;
        //     let formData = new FormData(form);

		// 	axios.post(api, formData)
		// 		.then(response => {
		// 			var notyf = new Notyf();
		// 			notyf.confirm('Updated!');
		// 		})
		// 		.catch(error => {
		// 			this.errorMessage = error.message
		// 			console.error('There was an error!', error)
		// 		})
		// },
        // handleFileUpload(file, index) {
        //     this.objects[index].url = URL.createObjectURL(file[0])
        //     console.log(this.objects)
        // },
    },
    filters: {
        
    }

}
</script>

<style scoped>
.quillWrapper{
    width: 95% !important
}
.add-more-btn {
    border: 1px solid rgb(19 98 248) !important; 
    width: auto !important; 
    color: rgb(19 98 248) !important; 
    font-size: 10px !important;
}
.ui-datepicker {
	width: 100%!important;
}

.ui-datepicker-calendar td.booked a {
	background-color: #1362f8;
	border-radius: 7px;
	color: #fff;
	font-weight: 700;
}

/* #calendar .ui-datepicker-calendar td a {
    border: solid 2px #fff;
	margin: 5px 5px 0 0;
} */
.ui-state-default,
.ui-widget-content .ui-state-default {
	font-size: 14.5px;
}

.ui-datepicker table tbody {
	margin-top: 5px;
}

.ui-datepicker {
	border-radius: 12px;
}

.ui-datepicker th {
	padding: 0.8em 0.3em;
	text-align: center;
	font-weight: 600;
	font-size: 13px;
	color: #000000;
	border: 0;
}

span.balance-down {
	color: #e75252;
}

span.balance-up {
	color: #3ea76c;
}

span.balance-down::before,
span.balance-up::before {
	content: "";
	font-family: FontAwesome;
	margin-right: 5px;
	font-size: 8px;
	position: relative;
	top: -1px;
	line-height: 14px;
}

span.balance-down::before {
	content: "\f068";
}

span.balance-up::before {
	content: "\f067";
}

.save-btn.sendmail-btn,
.save-btn.download-btn {
	text-transform: capitalize;
	font-size: 13px !important;
}

.save-btn.sendmail-btn {
	background: transparent;
	border: solid 1px #69aaeb !important;
	color: #565756 !important;
}

.save-btn.download-btn {
	background-color: #69aaeb;
}

.save-btn.download-btn:hover {
	background-color: #4788c9;
}

.save-btn.sendmail-btn:hover {
	background-color: #69aaeb;
	color: #fff !important;
}

fieldset {
	border: solid 1px #dadada;
	border-radius: 15px;
	background: #fafafa;
}
</style>