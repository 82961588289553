<template>
    <div class="dashboard home animate relative p-2 mb-2 pb-4 mt-4">
        <div class="row">
            <div class="col-md-12">
                <h6 class="mt-3 uk-text-bold"> New Mission </h6>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <div class="value-box relative p-0 m-0 mt-2">
                            <span class="title uk-text-uppercase"> 
                                <i class="fad fa-circle mr-1" style="color: #d4e6f2;"></i>
                                Bodyguard 
                            </span>
                            <span class="value uk-float-left"> 
                                {{newMissionSummary.bodyguard}} 
                            </span>
                            <span class="uk-float-left ml-2 mt-1" style="color: green;">
                                <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>20%</small>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="value-box relative p-0 m-0 mt-2">
                            <span class="title uk-text-uppercase"> 
                                <i class="fad fa-circle mr-1" style="color: #7eb3d5;"></i>
                                Security Guard 
                            </span>
                            <span class="value uk-float-left"> 
                                {{newMissionSummary.security_company}} 
                            </span>
                            <span class="uk-float-left ml-2 mt-1" style="color: green;">
                                <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>20%</small>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="value-box relative p-0 m-0 mt-2">
                            <span class="title uk-text-uppercase"> 
                                <i class="fad fa-circle mr-1" style="color: #2a80b9;"></i>
                                Private Invertigate 
                            </span>
                            <span class="value uk-float-left">
                                {{newMissionSummary.private_investigation}} 
                            </span>
                            <span class="uk-float-left ml-2 mt-1" style="color: red;">
                                <i uk-icon="icon: chevron-down; ratio: 0.7;" class="mr-1"></i><small>20%</small>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <div class="value-box relative p-0 m-0 mt-2">
                            <span class="title uk-text-uppercase"> 
                                <i class="fad fa-circle mr-1" style="color: #1e618c;"></i>
                                Education 
                            </span>
                            <span class="value uk-float-left"> 0 </span>
                            <span class="uk-float-left ml-2 mt-1" style="color: green;">
                                <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>20%</small>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="value-box relative p-0 m-0 mt-2">
                            <span class="title uk-text-uppercase"> 
                                <i class="fad fa-circle mr-1" style="color: #0c4070;"></i>
                                Car Rental 
                            </span>
                            <span class="value uk-float-left"> 
                                {{newMissionSummary.car_rental}} 
                            </span>
                            <span class="uk-float-left ml-2 mt-1" style="color: green;">
                                <i uk-icon="icon: chevron-up; ratio: 0.7;" class="mr-1"></i><small>20%</small>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="value-box relative p-0 m-0 mt-2">
                            <span class="title uk-text-uppercase"> 
                                <i class="fad fa-circle mr-1" style="color: #122230;"></i>
                                VIP Service 
                            </span>
                            <span class="value uk-float-left"> 
                                {{newMissionSummary.vip_service}} 
                            </span>
                            <span class="uk-float-left ml-2 mt-1" style="color: red;">
                                <i uk-icon="icon: chevron-down; ratio: 0.7;" class="mr-1"></i><small>20%</small>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        this.getMissionSummaryInfo()
    },
    data() {
        return {
            newMissionSummary: {},
        }
    },
    methods: {
        getMissionSummaryInfo() {
            const api = 'admin/dashboard/mission_summary_info'
            axios
            .get(api, { params: { status: 'all'} })
            .then((response) => {
                this.newMissionSummary = response.data.data
            })
            .catch((error) => {
                console.error('There was an error!', error)
            })
        },
    }
}
</script>