<template>
    <div>
        <!-- filter -->
        <div class="row">
            <div class="col-md-8 col-12 mb-3 uk-animation-slide-bottom-small">
                <ul class="filter d-md-inline-flex d-none mr-3">
                    <li v-for="(status, statusIndex) in $options.statusMenus" 
                        :key="statusIndex" 
                        :class="statusIndex | isActive(filters.status)" 
                        @click="filter(statusIndex, 'status')"
                    >
                        <a href="#">{{ status }}</a>
                    </li>
                </ul>
                <ul class="filter d-md-inline-flex d-none">
                    <li v-for="(type, typeIndex) in $options.agencyTypes" 
                        :key="typeIndex" 
                        :class="typeIndex | isActive(filters.type)" 
                        @click="filter(typeIndex, 'type')"
                    >
                        <a href="#">{{ type }}</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 col-12 text-right mb-3 uk-animation-slide-bottom-small">
                <Search @filter="filter"></Search>
            </div>
        </div>

        <!-- lists -->
        <div class="table-responsive">
            <table class="table data-table table-round table-hover uk-animation-slide-bottom-small">
            <thead>
                <tr>
                    <th width="100px">
                        ID
                        <Sort @change="sort" column="id" />
                    </th>
                    <th width="180px">
                        Start Date
                    </th>
                    <th width="180px">
                        Stop Date
                    </th>
                    <th>
                        Customer
                        <Sort @change="sort" column="customer" />
                    </th>
                    <th>
                        Service Provider
                        <Sort @change="sort" column="agency_name" />
                    </th>
                    <th>Type of Mission</th>
                    <th class="text-right">
                        Price
                    </th>
                    <th class="text-right" width="150px">
                        Status
                    </th>
                    <th class="blankhead" width="150px"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(mission, missionIndex) in missionList" :key="missionIndex">
                    <td>
                        <router-link :to="'/mission/edit/' + mission.id">
                        {{ mission.id }}
                        </router-link>
                    </td>
                    <td class="time" style="font-size: 12px">
                        <i class="fal fa-calendar mr-1 relative" style="top: 1px"></i>
                        {{ mission.start_time | splitDate }}
                        <span class="end-time ml-1" style="font-size: 11px">
                        {{ mission.start_time | splitTime }}
                        </span>
                    </td>
                    <td class="time" style="font-size: 12px">
                        <i class="fal fa-calendar mr-1 relative" style="top: 1px"></i>
                        {{ mission.end_time | splitDate }}
                        <span class="end-time ml-1" style="font-size: 11px">
                        {{ mission.end_time | splitTime }}
                        </span>
                    </td>
                    <td>
                        <router-link :to="'/customer/edit/' + mission.customer_id">
                        {{ mission.customer }}
                        </router-link>
                    </td>
                    <td>
                        <router-link :to="'/company/edit/' + mission.agency_id"> 
                        <i class="fas fa-user mr-2"></i> {{ mission.agency_name }} 
                        </router-link>
                    </td>
                    <td>
                        <span class="uk-label mr-1" v-show="mission.mission_type">
                            {{ mission.mission_type }}
                        </span>
                    </td>
                    <td class="text-right">
                        {{ mission.formatted_total_price }}
                    </td>
                    <td class="status text-right">
                        <span :class="$options.statuses[mission.status].class">
                            {{ $options.statuses[mission.status].text }}
                        </span>
                    </td>
                    <td class="text-right">
                        <router-link :to="'/mission/edit/' + mission.id">
                        <a class="edit-btn"><i class="fal fa-edit"></i></a>
                        </router-link>
                        <a href="#" class="del-btn" v-on:click="del_method(mission.id)">
                            <i class="fal fa-trash-alt"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
            <tfoot></tfoot>
            </table>
        </div>

        <!-- pagination -->
        <div class="row">
            <div class="col-md-12 text-right">
                <div class="card-footer pb-0" v-if="missionList.length > 0">
                    <!-- <Pagination 
                    @getCurrentPagination="getCurrentPagination" 
                    :totalPages="paginData.totalPages"
                    :page="paginData.page" /> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const statusMenus = {
    'all': 'All',
    'approved': 'Approved',
    'cancelled': 'Cancelled',
    'pending': 'Pending',
    'history': 'History'
}
const agencyTypes = {
    'company': 'Company',
    'freelancer': 'Freelancer',
}
const statuses = {
    client_rejected: {
        class: 'cancelled',
        text: 'rejected',
    },
    agency_rejected: {
        class: 'cancelled',
        text: 'rejected',
    },
    client_accepted: {
        class: 'approved',
        text: 'Accepted',
    },
    agency_accepted: {
        class: 'approved',
        text: 'Accepted',
    },
    on_bidding: {
        class: 'pending',
        text: 'Bidding',
    },
    started: {
        class: 'pending',
        text: 'Started',
    },
    failed: {
        class: 'cancelled',
        text: 'Failed',
    },
    finished: {
        class: 'approved',
        text: 'Finished',
    },
}


export default {
    statuses: statuses,
    statusMenus: statusMenus,
    agencyTypes: agencyTypes,
    mounted() {
        this.getMissionList()
    },
    data() {
        return {
            missionList: [],

            sortParams: {
                column: 'id',
                type: 'desc',
            },
            
            paginData: {
                page: 1,
                totalPages: 0,
                limit: this.$helpers.pageLimit()
            },
            
            filters: {
                keyword: '',
                status: 'all',
                type: 'all',
            },

        }
    },
    methods: {
        filter(keyword, type = 'keyword') {
            this.filters[type] = keyword
            this.getMissionList()
        },
        sort(params) {
            this.sortParams = {
                column: params.column,
                type: params.type,
            }
            this.getMissionList()
        },
        getCurrentPagination(currentPage) {
            this.paginData.page = currentPage
            this.getMissionList()
        },
        getMissionList() {
            const api = 'admin/missions'
            axios
                .get(api, {
                    params: {
                        active: 1,
                        page: this.paginData.page,
                        limit: this.paginData.limit,
                        sort_column: this.sortParams.column,
                        sort_type: this.sortParams.type,
                        keyword: this.filters.keyword,
                        status: this.filters.status,
                        type: this.filters.type
                    }
                })
                .then((response) => {
                    if(response.data.success) {
                        this.missionList = response.data.data.records
                        this.paginData.totalPages = response.data.data.total_pages
                    }else{
                        this.$helpers.notification(response.data.success, response.data.message)
                    }
                })
                .catch((error) => {
                    this.$helpers.notification(false, error.message)
                    console.error('There was an error!', error)
                })
        },
        del_method: function (id) {
            const that = this
            UIkit.modal.confirm('Do you want to delete record ID: #' + id + ' ? ').then(
                function () {
                    const api = `admin/missions/${id}`
                    axios.delete(api)
                    .then(response => {
                        that.getMissionList()
                    })
                    .catch((error) => {
                        this.errorMessage = error.message
                        console.error('There was an error!', error)
                    })
                },
                function () {
                    console.log('Rejected.')
                },
            )
        },
    },
    filters: {
    }
}
</script>

<style scoped>
    .pagination {
        top: 20px;
        position: absolute;
        right: 0px;
        align-content: flex-end;
        text-align: end;
    }
</style>