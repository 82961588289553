<template>
  <div>
        <!----- Header Title ----->
    <div class="row">
      <div class="col-md-6">
        <div>
          <h3 class="page-title mb-1">
                {{ this.$route.meta.title }}
          </h3>
        </div>
        <div class="breadcrumb-container">
          <ul class="uk-breadcrumb">
            <li><span>Admin</span></li>
            <li><a href="">{{ this.$route.meta.title }}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 relative"></div>
    </div>
    <div class="row mt-2">
      <div class="col-md-8 mb-3">
        <ul class="filter d-md-inline-flex d-none">
          <li class="active">
            <a  @click="filterType('')">All</a>
          </li>
          <li>
            <a @click="filterType('bodyguard')">Bodyguard</a>
          </li>
          <li>
            <a @click="filterType('')">Security</a>
          </li>
          <li>
            <a @click="filterType('')">Private Investigator</a>
          </li>
          <li>
            <a @click="filterType('')">Education</a>
          </li>
        </ul>
      </div>
      <div class="col-md-4 relative">
        <div class="uk-position-bottom-right pr-3">
          <div class="mb-3 text-right">
            <router-link :to="'/add/freelance/'">
              <a class="outline-btn"><i class="fal fa-plus-circle mr-2"></i>Add</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table data-table table-round table-hover">
        <thead>
          <tr>
            <th>
                  ID
                  <Sort column="id" @change="sortcol" />
                </th>
                <th>
                  Name
                  <Sort column="name_en" @change="sortcol" />
                </th>
                <th>
                  Type
                </th>
                <th>
                  Gender
                </th>
                <th>
                  Age
                </th>
                <th>
                  Ratings
                </th>
                <th>
                  Location
                  <Sort column="province" @change="sortcol" />
                </th>
                <!-- <th>
                  Country
                  <Sort column="country" @change="sortcol" />
                </th> -->
                <th class="blankhead"></th>
          </tr>
        </thead>
        <tbody>
              <tr v-for="(list, index) in freelanceList" :key="index">
                <td>{{ list.id }}</td>
                <td>{{ list.first_name }}</td>
                <td>{{ list.type | capitalize }}</td>
                <td>{{ list.gender | capitalize }}</td>
                <td>{{ list.dob | ages }}</td>
                <td><img src="assets/img/star-ico.svg" class="mr-1" />{{ list.rating }}</td>
                <td>{{ list.province }}</td>
                <!-- <td>{{ list.nationality }}</td> -->
                <td class="text-right">
                  <!-- <router-link :to="'freelance/details/' + encode(list.id)">
                    <a class="view-btn">View</a>
                  </router-link> -->
                  <router-link :to="'/freelance/edit/' + encode(list.id)">
                    <a class="edit-btn"><i class="fal fa-edit"></i></a>
                  </router-link>
                  <a href="#" class="del-btn"><i class="fal fa-trash-alt"></i></a>
                </td>
              </tr>
            </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>
    <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
          <jw-pagination
            :items="pages"
            @changePage="onChangePage"
            :pageSize="limit"
            :styles="customStyles"
            :labels="customLabels"
          ></jw-pagination>
        </div>
  </div>
  
</template>



<script>
const customLabels = {
  first: '<<',
  last: '>>',
  previous: '<',
  next: '>',
}
const customStyles = {
  ul: {},
  li: {
    display: 'inline-block',

    margin: '5px',
  },
  a: {
    color: 'blue',
    margin: '5px',
  },
}
export default {
  mounted() {
    this.getfreelanceList(this.page, this.limit, 'id', 'asc',this.freelanceType)
  },
  data() {
    return {
      params: {
        sort: { column: 'id', type: 'desc' },
      },
      firstload: false,
      customStyles: customStyles,
      customLabels: customLabels,
      numberOfPage: 0,
      pages: [],
      page: 1,
      limit: 10,
      freelanceType:'',
      sort: 'id',
      orderby: 'asc',
      freelanceList: [],
    }
  },
  methods: {
    filterType(type){
        this.freelanceType = type
        this.getfreelanceList(this.page, this.limit, this.sort, this.orderby,this.freelanceType)
    },
    onChangePage(pageOfItems) {
      if (typeof pageOfItems !== undefined && pageOfItems !== null) {
        this.getfreelanceList(pageOfItems[0], this.limit, this.sort, this.orderby,this.freelanceType)
      }
    },
    getfreelanceList(page, limit, col, sorttype,type) {
      this.page = page;
      let pagevalue = '' + page;
      if (pagevalue == 'undefined') {
        return
      }
      const api =
        'https://dev-api.trueprotection.co.th/api/admin/freelances?page=' +
        page +
        '&limit=' +
        limit +
        '&keyword=&active=1&sort_column=' +
        col +
        '&sort_type=' +
        sorttype + '&agency_type=' + type
      const admintoken = localStorage.getItem('auth_token_default')
      axios
        .get(api, { headers: { Authorization: `Bearer ${admintoken}` } })
        .then((response) => {
          this.freelanceList = []
          this.freelanceList = response.data.data.records

          if (this.firstload == false) {
            this.firstload = true
            this.numberOfPage = response.data.data.total_pages

            var counter = 1
            var pagenumber = 1
            this.pages = []
            for (var i = 0; i < this.numberOfPage * this.limit; i++) {
              if (counter - 1 == this.limit) {
                counter = 1
                pagenumber++
              } else {
                counter++
                this.pages.push(pagenumber)
              }
            }
          }
        })
        .catch((error) => {
          this.errorMessage = error.message
          console.error('There was an error!', error)
        })
    },
    sortcol(sort) {
      this.params.sort = sort
      this.sort = sort.column
      this.orderby = sort.type
      this.getfreelanceList(this.page, this.limit, sort.column, sort.type,this.freelanceType)
      console.log(sort.column, sort.type)
    },
    encode(id) {
      return btoa(id)
    },
  },
  filters: {
   
  }
}
</script>

<style scoped>
.card-footer {
  text-align: right;
  position: relative;
  background: #fff;
}
.pagination {
  top: 20px;
  position: absolute;
  right: 0px;
  align-content: flex-end;
  text-align: end;
}
</style>