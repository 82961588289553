<template>
	<div>
		<div class="row">
			<div class="col-md-6">
			<div>
				<h3 class="page-title mb-1">
					{{ this.$route.meta.title }}
				</h3>
			</div>
			<div class="breadcrumb-container">
				<ul class="uk-breadcrumb">
					<li><span>Admin</span></li>
					<li><a href="">{{ this.$route.meta.title }}</a></li>
				</ul>
			</div>
			</div>
			<div class="col-md-6 relative"></div>
      	</div>
		
		<section class="tpui-container">
			<div class="row align-items-center mb-4">
				<div class="col-md-12">
					<div class="grey-box p-3 pt-4">
						<div class="row">
							<div class="col-md-6 text-left">
							<h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-user mr-2"></i> General Settings </h5>
							</div>
						</div>
						<div class="view-ui mt-2">
							<div class="row">
								<div class="col-md-3 relative pb-5">
									<div class="profile-img position-relative text-center">
											<span class="edit-btn">
												<input
												type="file"
												class="file-upload"
												id="file"
												ref="file"
												v-on:change="handleFileUpload()"
												/>
												<i class="fal fa-pen upload-button"></i> Change
											</span>
											<img :src="url ? url : formData.avatar" alt="Profile" uk-cover>
									</div>
									<div class="col-md-9">
									</div>
								</div>
								<div class="col-md-9">
									<div class="p-3">
										<form @submit.prevent="updateData">
											<div class="row p-0 pb-0 pt-2">
												<div class="col-md-6">
													<div class="form-group">
														<input type="text" v-model="formData.first_name" class="form-control" required>
														<label>Company Name</label>
													</div>
												</div>
												<div class="col-md-6">
													<div class="form-group">
														<input type="text" v-model="formData.last_name" class="form-control" required>
														<label>Country</label>
													</div>
												</div>
												<div class="col-md-6">
													<div class="form-group">
														<input type="text" v-model="formData.last_name" class="form-control" required>
														<label>SMS Sender</label>
													</div>
												</div>
												<div class="col-md-6">
													<div class="form-group">
														<input type="text" v-model="formData.last_name" class="form-control" required>
														<label>Timezone</label>
													</div>
												</div>
												<div class="col-md-4 text-right pt-1">
													<div role="group" aria-label="Basic example" class="btn-group mt-2">
														<button href="#" class="cancel-btn border-0"><i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel </button>
														<button type="submit" id="client" class="save-btn border-0 ml-2 mr-2"><i class="fas fa-save mr-2" aria-hidden="true"></i> Save</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>	
					</div>
				</div>

				<!----- Security ----->
				<div class="col-md-12 pt-4">
					<div class="grey-box p-3 pt-4">
						<div class="row">
							<div class="col-md-6 text-left">
								<h5 class="mt-1 mb-3 uk-text-bold"><i class="fas fa-bell mr-2"></i> Security</h5>
							</div>
						</div>
						<div class="row pl-4 pb-4 pt-3">
							<div class="col-md-6 text-left">
								<h6 class="mt-0 mb-0 pb-0 pt-0">Password</h6>
								<p class="mt-0 mb-0 pb-0 pt-0">Last updated 3 months ago</p>
								<a class="btn btn-setting uk-position-right mr-5" href="#change-password" uk-toggle> <i class="fal fa-key mr-2"></i> Change Password </a>
							</div>
						</div>
					</div>
				</div>

				<!----- modal boxes ----->
				<div id="change-password" uk-modal>
					<div class="uk-modal-dialog">
						<form @submit.prevent="updateData">
							<button class="uk-modal-close-default" type="button" uk-close></button>
							<div class="uk-modal-body">
								<h5 class="uk-modal-title-small"><i class="fal fa-key mr-2"></i> Change Password</h5>
								<div class="row p-0 pb-0 pt-3 grey-box" style="background-color: transparent; box-shadow: none; border:none;">
									<div class="col-md-6">
										<div class="form-group">
										<input type="password" v-model="formData.password" id="new_password" class="form-control" value="" required />
										<label for="new_password">New Password</label>
										</div> 
									</div>
									<div class="col-md-6">
										<div class="form-group">
										<input type="password" v-model="formData.confirm_password" id="cf_password" class="form-control" value="" required />
										<label for="cf_password">Confirm Password</label>
										</div>
									</div>
								</div>
							</div>
							<div class="uk-modal-footer uk-text-right">
								<button class="uk-button uk-button-default uk-modal-close mr-2" type="button">
									<i class="far fa-times-circle mr-2"></i> Cancel
								</button>
								<button type="submit" class="uk-button uk-button-primary" style="background-color: #5cce8d">
									<i class="fal fa-save mr-2"></i> Save
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	
  	data() {
    	return {
			formData: {
				first_name: '',
				last_name: '',
				email: '',
				gender: '',
				avatar: null,
				role_id: 0,
				password: '',
				confirm_password: '',
				active: true,
			},
			
			requiredFields: {},
			profileActive:'',
			activeVal:'active',
			inactiveVal:'inactive',

			url: null,
			genderOptions: [
				{ text: 'Female', value: 'female' },
				{ text: 'Male', value: 'male' },
			],
			positions: [
				{ text: 'CEO', value: 'CEO'},
				{ text: 'Project Manager', value: 'Project Manager'},
			],
			roles: [
				{ text: 'Admin', value: 1},
				{ text: 'Staff', value: 2},
			],
			errorMessage: [],
			headerdata: {
				search: false,
				addBtn: false
			}
		}
  	},
	mounted() {
    console.log(this.$auth)
		this.getProfileData()
  	},
	methods: {
		getProfileData() {
      		let authUser = this.$auth.user()
			this.formData = authUser
			this.formData.role_id = authUser.role.id
			delete this.formData.created_at
			delete this.formData.role
			this.profileActive = authUser.active? this.activeVal : this.inactiveVal
		},
		updateData(){
			console.log(this.formData)
			const apiUrl = 'admin/admins/' + this.$auth.user().id
			axios.put(apiUrl, this.formData)
			.then((response) => {
				let resData = response.data;
				if(resData.success) {
					alert('success')
				}else{
					this.requiredFields = resData.data
				}
			})
			.catch((error) => {
				console.error('There was an error!', error)
				alert(error.message);

			})
		},
		activeUser() {
			this.formData.active = !this.formData.active
			this.profileActive = this.formData.active? this.activeVal : this.inactiveVal
			this.updateData();
		},
		/*
		*	Handles a change on the file upload
		*/
		handleFileUpload() {
			this.formData.avatar = this.$refs.file.files[0]
			this.url = URL.createObjectURL(this.formData.avatar)
		}
		
	},
}

</script>