<template>
    
    <fieldset style="background-color: rgb(237 243 255) !important; border: dashed 2px rgb(173 199 250) !important">
        <form @submit.prevent="updateCompanyContact" ref="updateCompanyContactForm">
            <div class="row pl-3 pr-1">
                <!---- Company Contact ---->

                <div class="col-md-12 mt-3 mb-1 pt-3">
                    <h6 class="uk-text-uppercase pl-1 uk-text-bold">Company Contact</h6>
                </div>

                <div class="col-md-12 mb-2">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="contact_person" v-model="detail.contact_person" class="form-control" required />
                            <label>Contact Person</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="contact_person_position" v-model="detail.contact_person_position" class="form-control" required />
                            <label>Position</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group vue-tel-input-wrapper">
                                <template>
                                    <vue-tel-input name="contact_person_number" v-model="detail.contact_person_number"></vue-tel-input>
                                    <label>Phone Number</label>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                            <input type="text" name="contact_person_email" v-model="detail.contact_person_email" class="form-control" required />
                            <label> Email </label>
                            </div>
                        </div>
                        <div class="col-sm-12 text-right">
                            <button type="submit" id="client" class="save-btn ml-2 mr-2" style="border:0px">
                                <i class="fas fa-save mr-2"></i> save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </fieldset>
    
</template>


<script>

export default {
    props: {
        companyDetail: Object
    },
    data() {
        return {
            requiredfileds:[],
            detail: {
                id: 0,
                contact_person: '',
                contact_person_position: '',
                contact_person_email: '',
                contact_person_number: '',
            },
        }
    },
    mounted() {
        console.log(this.companyDetail)
        this.detail = this.companyDetail
    },
    methods: {
        updateCompanyContact() {
            
            this.loading()
            const api = 'admin/agencies/' + this.detail.id + '/contact_person'
            axios
                .post(api, this.getFormData())
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        getFormData() {
            let form = this.$refs.updateCompanyContactForm;
            let formData = new FormData(form);

            formData.append('name', formData.get('contact_person'))
            formData.append('email', formData.get('contact_person_email'))
            formData.append('phone', formData.get('contact_person_number'))
            formData.append('position', formData.get('contact_person_position'))

            return formData
        },
        loading() {
            this.$toast.loading({
                message: 'Loading...',
                forbidClick: true,
            })
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },
    }
}
</script>