<template>
    <section class="tpui-container">
        <div class="row">
          <div class="col-md-12">
            <div class="grey-box no-border">
              <div class="uk-card uk-padding-small">
                <h6 class="p-2 mt-2 mb-2 uk-text-bold"> 
                  <i class="fas fa-building mr-2"></i> Company Information 
                </h6>
                <div class="row">
                  <div class="col-md-4">
                    <input type="hidden" name="has_parent_company" v-model="company.has_parent_company" value="1">
                    <!-- company profile -->
                    <div class="profile-img position-relative text-center mb-5">
                      <span class="edit-btn">
                        <input
                          type="file"
                          name="logo"
                          class="file-upload"
                          id="file"
                          ref="companyLogo"
                          v-on:change="handleFileUpload()"
                        />
                        <i class="fal fa-pen upload-button"></i>
                      </span>
                      <img v-if="logoUrl" :src="logoUrl" />
                    </div>
                    
                    <!-- active|inactive -->
                    <div class="row">
                      <div class="col-md-12">
                        <div class="switches-container w-200 uk-position-right" style="margin-right: 10px;">
                          
                          <input type="radio" name="active" id="active" v-model="profileActive" v-bind:value="activeVal" :checked="profileActive == activeVal" @click="activeUser"  />
                          <input type="radio" name="active" id="inactive" v-model="profileActive" v-bind:value="inactiveVal" :checked="profileActive == inactiveVal"  @click="activeUser" />
                          
                          <label for="active" id="lbl_active"> Active </label>
                          <label for="inactive" id="lbl_inactive"> Inactive </label>
                         
                          <div class="switch-wrapper">
                            <div class="switch">
                              <div id="lbl_active"> Active </div>
                              <div id="lbl_inactive"> Inactive </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="name_en" class="form-control" v-model="company.name_en" autofocus required />
                            <label>Company Name (English) </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input type="text" name="name_local" class="form-control" v-model="company.name_local" autofocus required />
                          <label> Company Name (Thai) </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <textarea name="description_en" rows="15" class="form-control" v-model="company.description_en" required></textarea>
                          <label>Description in English</label>
                          <!-- <label style="color: red">{{ isRequired('description_en') }} </label> -->
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <textarea name="description_local" rows="15" class="form-control" v-model="company.description_local" required></textarea>
                          <label>Description in Thai</label>
                          <!-- <label style="color: red">{{ isRequired('description_local') }} </label> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
</template>


<script>

  export default {
    props: {
      company: {
        logo: null,
        has_parent_company: Boolean,
        name_en: String,
        name_local: String,
        description_en: String,
        description_local: String,
        active: Boolean,
      }
      
        // company: {
        //   logo: null,
        //   name_en: String,
        //   name_local: String,
        //   description_en: String,
        //   description_local: String,
        //   active: Boolean,
        //   tax_id: Number,

          // founded_year: '',
          // can_go_other_area: 1,
          // size_male_staff: 1,
          // size_female_staff: 1,
          // size_local_staff: 1,
          // size_foreigner_staff: 1,
          // size_armed_staff: 1,
          // trained_for_group: 1,
          // legal_agreement: '',
          // legal_agreement_doc: '',
          // address: '',
          // sub_district: '',
          // district: '',
          // province: '',
          // country: '',
          // zip_code: '',
          // lat: '12.3333',
          // lng: '101.29383',
          // contact_person: 'Edward',
          // contact_person_email: '',
          // contact_person_number: '',
          // contact_person_position: '',
          // currency: 'thb',
          // company_registration_id: '',
          // company_name_en: '',
          // company_name_local: '',
          // company_website: '',
          // company_owner_name: '',
          // company_tax_id: '0',
          // company_address: '',
          // company_sub_district: '',
          // company_district: '',
          // company_province: '',
          // company_country: '',
          // company_zip_code: '',
          // company_lat: '12.3333',
          // company_lng: '101.29383',
          // company_contact_person: '',
          // company_contact_number: '',
          // login_phone_code: '+66',
          // login_phone: '',
          // bank_owner_name: '',
          // bank_account_no: '',
          // bank_branch_code: '',
          // bank_name: '',
          // has_parent_company: 0,
         
          // file: '',
          // filedocs: '',
        // }
    },
    data() {
      return {
        logoUrl: null,
        requiredfileds: {},
        profileActive:true,
        activeVal:'active',
        inactiveVal:'inactive',
      }
    },
    mounted() {
      this.profileActive = this.company.active? this.activeVal : this.inactiveVal
    },
    methods: {
      activeUser() {
        this.company.active = !this.company.active
        this.profileActive = this.company.active? this.activeVal : this.inactiveVal
      },
      /*
      *	Handles a change on the file upload
      */
      handleFileUpload() {
        this.company.logo = this.$refs.companyLogo.files[0]
        this.logoUrl = URL.createObjectURL(this.company.logo)
      }
    }
   
  }
</script>