<template>
  <div>
    <!----- Header Title ----->
    <div class="row">
      <div class="col-md-6">
        <div>
          <h3 class="page-title mb-1">
            {{ this.$route.meta.title }}
          </h3>
        </div>
        <div class="breadcrumb-container">
          <ul class="uk-breadcrumb">
            <li><span>Admin</span></li>
            <li><a href="">{{ this.$route.meta.title }}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 relative"></div>
    </div>
    <div class="row mt-2">
      <div class="col-md-8">
      </div>
      <div class="col-md-4 relative">
        <div class="uk-position-bottom-right pr-3">
          <div class="mb-3 text-right">
            <router-link class="mr-2" :to="'/'">
              <a uk-toggle="target: #adduser" class="outline-btn"><i class="fas fa-plus mr-2"></i>Add User</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table data-table table-round table-hover">
        <thead>
          <tr>
            <th>
              ID
              <Sort column="id" />
            </th>
            <th>
              Name
              <Sort column="name_en" />
            </th>
            <th>
              Email
            </th>
            <th>
              Created
            </th>
            <th>
              Updated At
            </th>


            <th v-if="userList.length>1" class="blankhead"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in userList" :key="index">
            <td>{{ list.id }}</td>
            <td>{{ list.name }}</td>
            <td>{{ list.email }}</td>
            <td>{{ list.created_at }}</td>
            <td>{{ list.updated_at }}</td>
            <td v-if="userList.length>1"><a  @click="deleteConfirm(list)" class="del-btn"><i class="fal fa-trash-alt"></i></a></td>
          </tr>

        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>
    <div class="card-footer pb-0 pt-3" v-if="numberOfPage > 1">
      <jw-pagination :items="pages" @changePage="onChangePage" :pageSize="limit" :styles="customStyles"
        :labels="customLabels"></jw-pagination>
    </div>

    <div id="deletemodal" uk-modal>
      <div class="uk-modal-dialog uk-modal-body p-2 text-center">
        <button class="uk-modal-close-outside" type="button" uk-close></button>
        <div class="row align-items-center mb-4">
          <div class="col-md-12 mt-4">
            <h4>Are you sure you want to delete {{ selectedName }}?</h4>
          </div>
        </div>
        <a @click="closeModal()" class="uk-button uk-button-default mr-3 mb-3" type="button">No</a>
        <a @click="deleteCustomer()" class="uk-button uk-button-danger mr-3 mb-3" type="button">Yes</a>
      </div>
    </div>
    <div id="deletesuccess" uk-modal>
      <div class="uk-modal-dialog uk-modal-body p-2 text-center">
        <button class="uk-modal-close-outside" type="button" uk-close></button>
        <div class="row align-items-center mb-4">
          <div class="col-md-12 mt-4">
            <h4>Delete Staff successful!</h4>
          </div>
        </div>
        <a @click="closeModal" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
      </div>
    </div>
    <div id="createmodal" uk-modal>
      <div class="uk-modal-dialog uk-modal-body p-2 text-center">
        <button class="uk-modal-close-outside" type="button" uk-close></button>
        <div class="row align-items-center mb-4">
          <div class="col-md-12 mt-4">
            <h4>Create Staff successful!</h4>
          </div>
        </div>
        <a @click="next" class="uk-button uk-button-default mr-3 mb-3" type="button">Okay</a>
      </div>
    </div>
    <!-- Add Modal -->
    <div id="adduser" uk-modal>
      <div class="uk-modal-dialog uk-modal-body p-2 text-center">
        <button class="uk-modal-close-outside" type="button" uk-close></button>
        <div class="row align-items-center mb-4">
          <div class="col-lg-12">
            <div class="grey-box">
              <div class="view-ui mt-4 mb-2">
                <div class="row">

                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="name" value="" autocomplete="off" required>
                      <label>Full Name</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="email" class="form-control" v-model="email" value="" autocomplete="off" required>
                      <label>Email Address</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="password" value="" autocomplete="off" required>
                      <label>Password</label>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <select class="form-control" required>
                        <option></option>
                        <option>Administrator</option>
                        <option>User</option>
                        <option>Manager</option>
                        <option>Reservation</option>
                      </select>
                      <label>Roles</label>
                    </div>
                  </div>
                  <div class="clearfix"></div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <a @click="save" class="uk-button uk-button-primary mr-3" type="button">Save</a>
      </div>
    </div>
    <!-- End modal -->

  </div>

</template>



<script>

export default {
  props: {
    companyDetail: {
      id: Number,
      bank_account_no: String,
      bank_owner_name: String,
      bank_name: String,
      bank_branch_code: String,
      bank_book: String,

    }
  },
  data() {
    return {
      userList: [],
      selectedID: 0,
      selectedName: '',
      name: '',
      email: '',
      password: ''
    }
  },
  mounted() {
    this.getUserList()
  },

  methods: {
    next() {
      UIkit.modal('#createmodal').hide();
      this.getUserList()
    },
    save() {
      let reservationData = {
        "email": this.email,
        "password": this.password,
        "password_confirmation": this.password,
        "name": this.name,
      }
      const admintoken = localStorage.getItem('auth_token_default')
      axios.post(`https://api.615autorental.com/api/admin/staff`, reservationData, {
        headers: {
          'Authorization': `Bearer ${admintoken}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (response.data.data.id) {
            localStorage.setItem("reservation_id", response.data.data.id)
          }
          UIkit.modal('#createmodal').show();
          console.log('Customer updated successfully:', response.data);
          // handle success
        })
        .catch(error => {

          alert("Please complete form")
          console.error('Error updating customer:', error);
          // handle error
        });
    },
    deleteConfirm(customer) {
      this.selectedID = customer.id
      this.selectedName = customer.name
      UIkit.modal('#deletemodal').show();
    },
    closeModal() {
      UIkit.modal('#deletemodal').hide();
      UIkit.modal('#deletesuccess').hide();

    },
    deleteCustomer() {
      UIkit.modal('#deletemodal').hide();
      const api = 'https://api.615autorental.com/api/admin/staff/' + this.selectedID
      const admintoken = localStorage.getItem('auth_token_default')
      const headers = {
        'Authorization': `Bearer ${admintoken}`,
        'Content-Type': 'application/json' // You can adjust content type as needed
      };
      axios
        .delete(api, { headers }, {
          params: {
            page: 1,
            limit: 30
          }
        })
        .then((response) => {
          UIkit.modal('#deletesuccess').show();
          this.getUserList()

        })
        .catch((error) => {
          console.error('There was an error!', error.message)
        })
    },
    showName(customer) {
      let name = customer.fname + " " + customer.lname
      return name.length <= 30 ? name : name.substring(0, 27) + '...';
    },
    getUserList() {
      this.userList = []
      const api = 'https://api.615autorental.com/api/admin/staff'
      const admintoken = localStorage.getItem('auth_token_default')
      const headers = {
        'Authorization': `Bearer ${admintoken}`,
        'Content-Type': 'application/json' // You can adjust content type as needed
      };
      axios
        .get(api, { headers }, {
          params: {
            page: 1,
            limit: 30
          }
        })
        .then((response) => {
          this.userList = response.data.data
        })
        .catch((error) => {
          console.error('There was an error!', error.message)
        })
    },
    encode(id) {
      return btoa(id)
    },
  }
}
</script>

<style scoped>
.card-footer {
  text-align: right;
  position: relative;
  background: #fff;
}

.pagination {
  top: 20px;
  position: absolute;
  right: 0px;
  align-content: flex-end;
  text-align: end;
}
</style>