<template>
  <div class="row align-items-center">
    <div class="col-lg-12">
      <div class="row">
          <div class="col-md-6 text-left">
              <h5 class="mt-1 mb-2 uk-text-bold">
                  <i class="fas fa-chart-bar mr-2"></i> Client requested classes 
              </h5>
          </div>
      </div>
      <div class="view-ui mt-2 mb-2 mission">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table data-table table-round table-hover">
                <thead>
                  <tr>
                    <th scope="col" colspan="2" class="text-left" width="20%">Requesters</th>
                    <th scope="col" class="text-left" width="10%">Started Date</th>
                    <th scope="col" class="text-left" width="10%">Duration</th>
                    <th scope="col" class="text-left" width="20%">Location</th>
                    <th scope="col" class="text-left" width="20%">Instructor</th>
                    <th scope="col" class="text-center" width="5%">Status</th>
                    <th scope="col" class="blankhead" width="auto"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(classs, classsKey) in classes" :key="classsKey">
                    <td class="text-left">
                      <img :src="`/assets/img/logo.png`" class="uk-border-circle mr-2" width="55px" height="55px"> 
                    </td>
                    <td class="text-left pl-0">
                      <a href="">
                          {{ classs.customer }} <br />
                          (+7 More)
                      </a>
                    </td>
                    <td class="text-left time" style="font-size: 12px">
                      <i class="fal fa-calendar mr-1 relative" aria-hidden="true" style="top: 1px"></i> 
                      {{ classs.start_time }}
                    </td>
                    <td class="text-left">
                      {{classs.duration}}
                    </td>
                    <td class="text-left">
                      {{ classs.address }}
                    </td>
                    <td>
                      <img :src="`/assets/img/logo.png`" class="uk-border-circle mr-2" width="55px" height="55px"> 
                    </td>
                    <td class="text-center status">
                      <span :class="classs.status">
                        {{ classs.status }}
                      </span>
                    </td>
                    <td class="text-right">
                      <div class="action-btn d-flex justify-content-end">
                        
                        <router-link :to="`/company/1/services/education/client-request/1`" class="edit-btn">
                          <i class="fal fa-edit"></i>
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
    mounted() {
        this.classes = [{
            id: 1,
            customer: 'msdf',
            avatar: '/assets/img/logo.png',
            start_time: '2022-12-12',
            duration: '12 Days',
            address: 'Bangkok',
            instructor: '',
            status: 'approved'
        }]
        // this.getClassList()
    },
    data() {
        return {
            classes: [],
        }
    },
    methods: {
     
      getClassList() {

        const api = 'admin/missions';
        axios
            .get(api,{
              params: {
                page:1,
                limit: 30,
                sort_column: 'id',
                sort_type: 'desc',
                status: '',
              }
            })
            .then((response) => {
                this.classes = []
                if(response.data.success) {
                  this.classes = response.data.data.records
                }else{
                  this.$helpers.notification(false, response.data.message)
                }
            })
            .catch((error) => {
                this.$helpers.notification(false, error.message)
                console.error('There was an error!', error.message)
            })
      },
    },
}
</script>

<style scoped>

</style>