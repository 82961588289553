<template>
    <div>
          <!----- Header Title ----->
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="page-title mb-1">
                  {{ this.$route.meta.title }}
            </h3>
          </div>
          <div class="breadcrumb-container">
            <ul class="uk-breadcrumb">
              <li><span>Admin</span></li>
              <li><a href="">{{ this.$route.meta.title }}</a></li>
            </ul>
          </div>
        </div>
      </div>

      <VehicleApi />
      <AvailabilityApi />
      <ReservationApi />
      
    </div>
    
  </template>
  
  
  
  <script>
  import VehicleApi from "./API/VehicleApi.vue";
  import AvailabilityApi from "./API/AvailabilityApi.vue";
  import ReservationApi from "./API/ReservationApi.vue";

  export default {
    components: {
      VehicleApi,
      AvailabilityApi,
      ReservationApi,
    },
  }
  </script>
  
  <style scoped>
  .card-footer {
    text-align: right;
    position: relative;
    background: #fff;
  }
  .pagination {
    top: 20px;
    position: absolute;
    right: 0px;
    align-content: flex-end;
    text-align: end;
  }
  </style>