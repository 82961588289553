<template>
	<div>
        <Header />
		<section class="tpui-container">
			<div class="row align-items-center mb-4">
				<div class="col-md-12">
					<div class="grey-box p-3 pt-4">
						<div class="row">
							<div class="col-md-6 text-left">
							    <h5 class="mt-1 mb-3 uk-text-bold"> <i class="fas fa-user mr-2"></i> Admin Information </h5>
							</div>
						</div>
						<div class="view-ui mt-2">
							<div class="row">
								<div class="col-md-3 relative pb-5">
									<div class="profile-img position-relative text-center">
                                        <img v-if="staffProfile.avatar" :src="staffProfile.avatar" alt="Profile" uk-cover>
									</div>
									<div class="col-md-9">
									</div>
								</div>
								<div class="col-md-9">
									<div class="p-3">
                                    
                                        <div class="row p-0 pb-0 pt-2">
                                            <div class="col-md-4">
                                                <div>
                                                    <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> First Name</h6>
                                                    <p class="result m-0 mb-4" style="padding-left: 29px;"> {{ staffProfile.first_name }} </p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Last Name</h6>
                                                    <p class="result m-0 mb-4" style="padding-left: 29px;"> {{ staffProfile.last_name }} </p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Gender</h6>
                                                    <p class="result m-0 mb-4" style="padding-left: 29px;"> {{ staffProfile.gender }} </p>
                                                </div>
                                            </div>
                                            <div class="col-md-4 pr-0">
                                                <div>
                                                    <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Date of Birth</h6>
                                                    <p class="result m-0 mb-4" style="padding-left: 29px;"> {{ staffProfile.dob }} </p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Nationality</h6>
                                                    <p class="result m-0 mb-4" style="padding-left: 29px;"> {{ staffProfile.nationality }} </p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Position</h6>
                                                    <p class="result m-0 mb-4" style="padding-left: 29px;"> {{ staffProfile.position }} </p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Role</h6>
                                                    <p class="result m-0 mb-4" style="padding-left: 29px;"> {{ staffProfile.role.role_name }} </p>
                                                </div>
                                            </div>
                                            
                                            <div class="clearfix"></div>
                                            <div class="col-md-12 mt-3 pl-3 pb-0 mb-0">
                                                <h6 class="uk-text-uppercase" style="color: #a5a5a5;"> Contact </h6>
                                            </div>
                            
                                            <div class="col-md-4">
                                                <div>
                                                    <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Email</h6>
                                                    <p class="result m-0 mb-4" style="padding-left: 29px;"> {{ staffProfile.email }} </p>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <h6 class="title mb-3"><i class="fad fa-info-circle mr-1"></i> Phone Number</h6>
                                                    <p class="result m-0 mb-4" style="padding-left: 29px;"> {{ staffProfile.phone }} </p>
                                                </div>
                                            </div>
                                        </div>
									</div>
								</div>
							</div>
						</div>	
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

export default {

  	data() {
    	return {
			staffProfile:{
                type: Object,
                role:Object
            },
            headerdata: {
                route: '',
                search: false,
                addBtn: false
            }
		}
  	},
	mounted() {
		this.getAdminData()
  	},
	methods: {
		getAdminData() {
			const api = 'admin/admins/' + this.$route.params.id
			axios
				.get(api)
				.then((response) => {
                    if(!response.data.success){
					    this.$router.push('/404')
                    }
					this.staffProfile = response.data.data
				})
				.catch((error) => {
					console.error('There was an error!', error)
					alert( error.message)
				})
		}
	},
}

</script>