<template>
    <form @submit.prevent="updateForm">
        <div class="row mt-5">
            <div class="col-md-6 text-left">
                <h5 class="mt-1 mb-2 uk-text-bold">
                    <i class="fas fa-chart-bar mr-2"></i> Achievement / Reward / Experience / Title
                </h5>
            </div>
            
            <div class="col-md-12 pt-3">
                    <div class="table-responsive">
                        <table class="table data-table table-round table-hover">
                            <thead>
                                <tr>
                                <th width="30%" class="pl-4 pr-4">Type</th>
                                <th width="50%" class="pl-4 pr-4">Name</th>
                                <th class="blankhead" width="auto"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(list, aIndex) in lists" :key="aIndex">
                                    <td class="bg-white pl-4 pr-4 pt-3">
                                        <div class="form-group select-dropdown mb-2 mt-0">
                                            <select name="type_of_vehicle" v-model="lists[aIndex].type" class="form-control uk-form-small pt-0" required>
                                                <option value="award">Award</option>
                                                <option value="title">Title</option>
                                                <option value="experience">Experience</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td class="bg-white pl-4 pr-4 pt-3 pb-2">
                                        <div class="form-group mb-2 mt-1">
                                            <input type="text" name="name" v-model="lists[aIndex].name" class="form-control uk-form-small pt-0" required />
                                        </div>
                                    </td>
                                    <td class="bg-lightgreen text-right">
                                        <a href="#" @click="copyPrice(aIndex)" class="edit-btn" uk-toggle>
                                            <i class="far fa-copy"></i>
                                        </a>
                                        <a href="#" @click="deletePrice(aIndex)" class="del-btn">
                                            <i class="fal fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <div class="col-md-12 text-right p-0">
                                            <a @click="addPrice" class="save-btn sendmail-btn border-0 mt-2 mb-2 text-center float-left">
                                                <i class="fas fa-plus mr-2" style="font-size: 15px; display: block"></i> Add
                                            </a>
                                            
                                            <button type="button" class="cancel-btn border-0">
                                                <i class="fas fa-minus-circle mr-2" aria-hidden="true"></i> Cancel
                                            </button>
                                            <button type="submit" class="save-btn border-0 ml-2 mr-2">
                                                <i class="fas fa-save mr-2" aria-hidden="true"></i> Save
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div>
            
        </div>
    </form>
</template>

<script>

export default {
    mounted() {
        this.lists = [
            {
                id:1,
                type: 'award',
                name: 'asdfadsfadsf'
            }
        ]
        // this.achivements()
    },
    data() {
        return {
            lists: [],
            formData: []
        }
    },
    methods: {
        achivements() {
            const api = 'admin/agencies/' + this.$route.params.id + '/escort_prices'
            axios
                .get(api, {
                    params: {
                        page:1,
                        limit:30
                    }
                })
                .then((response) => {
                    this.lists = response.data.data
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
                
        },
        addPrice() {
            this.lists.push({
                range_period: 'month',
                type_of_vehicle: 'car',
                no_of_vehicle: 1
            })
        },
        copyPrice(index) {
            this.lists.push(this.lists[index])
        },
        updateForm() {
            console.log(this.lists)
            // this.loading()
            const api = 'admin/agencies/' + this.$route.params.id + '/escort_prices'
            axios
                .post(api,{
                    prices: this.getFormData()
                })
                .then((response) => {
                    console.log(response.data)
                    if(response.data.success) {
                        this.success()
                    }else{
                        this.requiredfileds = response.data
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error.message)
                })
        },
        getFormData() {
            // let form = this.$refs.updatePriceForm;
            // let formData = new FormData(form);

            return this.lists
        },
        success() {
            var notyf = new Notyf();
                notyf.confirm('Successfully Updated!');
        },
        deletePrice(index) {
            this.lists.splice(index, 1); 
        }

    }
}
</script>