<template>
    <div class="uk-float-left">
        <ul class="filter d-md-inline-flex ">
            <li v-for="(day, dayIndex) in $options.dayMenus" 
                :key="dayIndex" 
                :class="dayIndex | isActive(filters.day)" 
                @click="$emit('filter', dayIndex, 'day')"
            >
                <a href="#">{{ day }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
const dayMenus = {
    'all': 'All',
    'day': 'Day',
    'week': 'Week',
    'month': 'Month',
    'year': 'Year'
}
export default {

    props:{
        filters: Object
    },
    dayMenus: dayMenus
}

</script>